// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/player_statistic.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CrmSendPlayerDailyStatRequest, CrmSendPlayerDailyStatResponse, DelStatRequest, DelStatResponse, GetPlayerHistoryRequest, GetPlayerHistoryResponse, GetPlayerStatisticForCrmResponse, GetPlayerStatisticFromCacheRequest, GetPlayerStatisticFromCacheResponse, GetPlayerThreatmetrixStatisticRequest, GetPlayerThreatmetrixStatisticResponse, PlayerStatisticRequest, PlayerStatisticResponse, PlayerUnifiedStatisticRequest, PlayerUnifiedStatisticResponse } from "./player_statistic_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ----------------------------------------------------------------------------------------------------------------------
 * Old compatibility player statistic
 * for compatibility used uint32, slower, use PlayerStats service
 *
 * ----------------------------------------------------------------------------------------------------------------------
 *
 * @generated from service stat.PlayerStatistics
 */
@Injectable({ providedIn: "root" })
export class PlayerStatisticsRemoteService {
  public static readonly typeName = "stat.PlayerStatistics";

  public static readonly methods = {
    getPlayerStatistic: {
      name: "GetPlayerStatistic",
      I: PlayerStatisticRequest,
      O: PlayerStatisticResponse,
      kind: MethodKind.Unary,
    },
    getPlayerUnifiedStatistic: {
      name: "GetPlayerUnifiedStatistic",
      I: PlayerUnifiedStatisticRequest,
      O: PlayerUnifiedStatisticResponse,
      kind: MethodKind.Unary,
    },
    getPlayerStatisticForCrm: {
      name: "GetPlayerStatisticForCrm",
      I: PlayerStatisticRequest,
      O: GetPlayerStatisticForCrmResponse,
      kind: MethodKind.Unary,
    },
    getPlayerThreatmetrixStatistic: {
      name: "GetPlayerThreatmetrixStatistic",
      I: GetPlayerThreatmetrixStatisticRequest,
      O: GetPlayerThreatmetrixStatisticResponse,
      kind: MethodKind.Unary,
    },
    crmSendPlayerDailyStat: {
      name: "CrmSendPlayerDailyStat",
      I: CrmSendPlayerDailyStatRequest,
      O: CrmSendPlayerDailyStatResponse,
      kind: MethodKind.Unary,
    },
    getPlayerHistory: {
      name: "GetPlayerHistory",
      I: GetPlayerHistoryRequest,
      O: GetPlayerHistoryResponse,
      kind: MethodKind.Unary,
    },
    getPlayerStatisticFromCache: {
      name: "GetPlayerStatisticFromCache",
      I: GetPlayerStatisticFromCacheRequest,
      O: GetPlayerStatisticFromCacheResponse,
      kind: MethodKind.Unary,
    },
    delStat: {
      name: "DelStat",
      I: DelStatRequest,
      O: DelStatResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * Get Player statistic
   * ------------------------
   * Format: json
   * Access:
   *   HTTP URL:     <host>:8010/getPlayerStatistic
   *   HTTP WS:      /ws/statistic/get Request: <request id>:getPlayerStatistic:<PlayerStatisticRequest>
   *   NATS Request: pinup.product.<license>.stat_service.player.stat.get_player_statistic
   *                 pinup.product.<license>.stat_service.player.stat.get_player_statistic_for_crm_update
   *                 pinup.product.<license>.stat_service.player.stat.get_player_statistic_for_crm_batch
   * Restriction:
   *   NATS: max 255 player ids at one time
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatistics.GetPlayerStatistic
   */
  public getPlayerStatistic(
    input: PartialMessage<PlayerStatisticRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<PlayerStatisticResponse> {
    return unary<PlayerStatisticRequest, PlayerStatisticResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.getPlayerStatistic,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Get Player statistic
   * ------------------------
   * Format: json
   * Access:
   *   HTTP URL:     <host>:8010/getPlayerUnifiedStatistic
   *   NATS Request: pinup.product.<license>.stat_service.player.stat.get_player_unified_statistics
   *
   * @generated from rpc stat.PlayerStatistics.GetPlayerUnifiedStatistic
   */
  public getPlayerUnifiedStatistic(
    input: PartialMessage<PlayerUnifiedStatisticRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<PlayerUnifiedStatisticResponse> {
    return unary<PlayerUnifiedStatisticRequest, PlayerUnifiedStatisticResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.getPlayerUnifiedStatistic,
      input,
      options,
    );
  }

  /**
   * -------------------------------------
   * Get short Player statistic for CRM
   * -------------------------------------
   * Format: json
   * Access:
   *   HTTP WS:      /ws/statistic/get  Request: <request id>:getPlayerStatisticForCrm:<PlayerStatisticRequest>)
   *   NATS Request: pinup.product.<license>.stat_service.player.stat.get_player_statistic_for_crm
   * Restriction:
   *   NATS: max 255 player ids at one time
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatistics.GetPlayerStatisticForCrm
   */
  public getPlayerStatisticForCrm(
    input: PartialMessage<PlayerStatisticRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerStatisticForCrmResponse> {
    return unary<PlayerStatisticRequest, GetPlayerStatisticForCrmResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.getPlayerStatisticForCrm,
      input,
      options,
    );
  }

  /**
   * -------------------------
   * Get Threatmetrix info
   * -------------------------
   * Format: json
   * Access
   *   NATS Request: pinup.product.<license>.stat_service.player.stat.get_player_threatmetrix_statistic
   *   HTTP URL:     <host>:8010/getPlayerThreatmetrixStatistic
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatistics.GetPlayerThreatmetrixStatistic
   */
  public getPlayerThreatmetrixStatistic(
    input: PartialMessage<GetPlayerThreatmetrixStatisticRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerThreatmetrixStatisticResponse> {
    return unary<GetPlayerThreatmetrixStatisticRequest, GetPlayerThreatmetrixStatisticResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.getPlayerThreatmetrixStatistic,
      input,
      options,
    );
  }

  /**
   * ---------------------------------------
   * Send Player statistic to CRM system
   * ---------------------------------------
   * Format: json
   * Access
   *   NATS Request: pinup.product.<license>.stat_service.player.stat.crm_send_player_daily_stat
   *   HTTP URL:     <host>:8010/crmSendPlayerDailyStat
   * --------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatistics.CrmSendPlayerDailyStat
   * @deprecated
   */
  public crmSendPlayerDailyStat(
    input: PartialMessage<CrmSendPlayerDailyStatRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CrmSendPlayerDailyStatResponse> {
    return unary<CrmSendPlayerDailyStatRequest, CrmSendPlayerDailyStatResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.crmSendPlayerDailyStat,
      input,
      options,
    );
  }

  /**
   * Deprecated methods (for compatibility)
   * -------------------------
   * Get player history (more not used)
   * -------------------------
   * Format: json
   * Access
   *   NATS Request: pinup.product.<license>.stat_service.player.stat.get_player_history
   *   HTTP URL:     <host>:8010/getPlayerHistory
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatistics.GetPlayerHistory
   * @deprecated
   */
  public getPlayerHistory(
    input: PartialMessage<GetPlayerHistoryRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerHistoryResponse> {
    return unary<GetPlayerHistoryRequest, GetPlayerHistoryResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.getPlayerHistory,
      input,
      options,
    );
  }

  /**
   * @generated from rpc stat.PlayerStatistics.GetPlayerStatisticFromCache
   * @deprecated
   */
  public getPlayerStatisticFromCache(
    input: PartialMessage<GetPlayerStatisticFromCacheRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerStatisticFromCacheResponse> {
    return unary<GetPlayerStatisticFromCacheRequest, GetPlayerStatisticFromCacheResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.getPlayerStatisticFromCache,
      input,
      options,
    );
  }

  /**
   * @generated from rpc stat.PlayerStatistics.DelStat
   * @deprecated
   */
  public delStat(
    input: PartialMessage<DelStatRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DelStatResponse> {
    return unary<DelStatRequest, DelStatResponse>(
      this.transport,
      PlayerStatisticsRemoteService,
      PlayerStatisticsRemoteService.methods.delStat,
      input,
      options,
    );
  }
}
