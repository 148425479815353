import { inject, Injectable, InjectionToken, Injector } from '@angular/core';
import type { Observable } from 'rxjs';
import { map, startWith, Subject } from 'rxjs';

import { provideValue, SmartDialog } from '@pu/sdk';

import { AddDepositDialogComponent } from './add-deposit-dialog.component';

export const ADD_DEPOSIT_DIALOG_DATA = new InjectionToken<{ playerId: bigint }>(
  'ADD_DEPOSIT_DIALOG_DATA',
);

@Injectable()
export class AddDepositService {
  private readonly dialog = inject(SmartDialog);
  private readonly injector = inject(Injector);

  private readonly _refresh = new Subject<void>();
  public refresh$ = this._refresh.asObservable().pipe(startWith(null));

  public openDialog(playerId: bigint): Observable<void> {
    return this.dialog
      .open(
        AddDepositDialogComponent,
        [provideValue(ADD_DEPOSIT_DIALOG_DATA, { playerId })],
        {
          injector: this.injector,
        },
      )
      .pipe(
        map(() => {
          this._refresh.next();
        }),
      );
  }
}
