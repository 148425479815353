// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/withdrawals/withdrawals.proto (package stat.bo.withdrawals, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, Duration, Int64Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { AccountVerifyState, Block, DigitainStatus } from "../players/players_pb.js";
import { SumCurrency } from "../../../base/models_pb.js";
import { Project } from "../../../base/enum_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../base/pagination_pb.js";
import { Sorting } from "../../../base/sorting_pb.js";
import { Int64Range, SumRange, TimeRange } from "../../../base/filter_pb.js";

/**
 * @generated from enum stat.bo.withdrawals.WithdrawalConfirmType
 */
export const WithdrawalConfirmType = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.withdrawals.WithdrawalConfirmType",
  [
    {no: 0, name: "WITHDRAWAL_CONFIRM_TYPE_AUTO", localName: "AUTO"},
    {no: 1, name: "WITHDRAWAL_CONFIRM_TYPE_MANUAL", localName: "MANUAL"},
    {no: 2, name: "WITHDRAWAL_CONFIRM_TYPE_MANUAL_CHANGED_AMOUNT", localName: "MANUAL_CHANGED_AMOUNT"},
  ],
);

/**
 * @generated from enum stat.bo.withdrawals.WithdrawalStatus
 */
export const WithdrawalStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.withdrawals.WithdrawalStatus",
  [
    {no: 0, name: "WITHDRAWAL_STATUS_PROCESSING", localName: "PROCESSING"},
    {no: 1, name: "WITHDRAWAL_STATUS_SUCCESSFUL", localName: "SUCCESSFUL"},
    {no: 2, name: "WITHDRAWAL_STATUS_CANCELED", localName: "CANCELED"},
    {no: 3, name: "WITHDRAWAL_STATUS_CANCELED_USER", localName: "CANCELED_USER"},
    {no: 4, name: "WITHDRAWAL_STATUS_AV_PROCESSING", localName: "AV_PROCESSING"},
    {no: 5, name: "WITHDRAWAL_STATUS_RISK_REVIEWING", localName: "RISK_REVIEWING"},
  ],
);

/**
 * @generated from enum stat.bo.withdrawals.WithdrawalPaymentStatus
 */
export const WithdrawalPaymentStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.withdrawals.WithdrawalPaymentStatus",
  [
    {no: 0, name: "WITHDRAWAL_PAYMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_PAYMENT_STATUS_SUCCESSFUL", localName: "SUCCESSFUL"},
    {no: 2, name: "WITHDRAWAL_PAYMENT_STATUS_FAILED", localName: "FAILED"},
    {no: 3, name: "WITHDRAWAL_PAYMENT_STATUS_PROCESSING", localName: "PROCESSING"},
  ],
);

/**
 * @generated from enum stat.bo.withdrawals.WithdrawalDeclineReason
 */
export const WithdrawalDeclineReason = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.withdrawals.WithdrawalDeclineReason",
  [
    {no: 0, name: "WITHDRAWAL_DECLINE_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_DECLINE_REASON_TURNOVER_NEEDED", localName: "TURNOVER_NEEDED"},
    {no: 2, name: "WITHDRAWAL_DECLINE_REASON_VERIFICATION_NEEDED", localName: "VERIFICATION_NEEDED"},
    {no: 3, name: "WITHDRAWAL_DECLINE_REASON_PAYMENT_METHOD_NOT_MATCHED", localName: "PAYMENT_METHOD_NOT_MATCHED"},
    {no: 4, name: "WITHDRAWAL_DECLINE_REASON_SELFIE_NEEDED", localName: "SELFIE_NEEDED"},
    {no: 5, name: "WITHDRAWAL_DECLINE_REASON_FULL_VERIFICATION", localName: "FULL_VERIFICATION"},
    {no: 6, name: "WITHDRAWAL_DECLINE_REASON_RECREATE_WITHDRAWAL", localName: "RECREATE_WITHDRAWAL"},
    {no: 7, name: "WITHDRAWAL_DECLINE_REASON_FULL_PROFILE_NEEDED", localName: "FULL_PROFILE_NEEDED"},
    {no: 8, name: "WITHDRAWAL_DECLINE_REASON_METHOD_NOT_AVAILABLE", localName: "METHOD_NOT_AVAILABLE"},
    {no: 9, name: "WITHDRAWAL_DECLINE_REASON_OTHER", localName: "OTHER"},
  ],
);

/**
 * @generated from enum stat.bo.withdrawals.WithdrawalReason
 */
export const WithdrawalReason = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.withdrawals.WithdrawalReason",
  [
    {no: 0, name: "WITHDRAWAL_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_REASON_AUTO_TURNED_OFF", localName: "AUTO_TURNED_OFF"},
    {no: 2, name: "WITHDRAWAL_REASON_AUTO_TURNED_OFF_FOR_AMOUNT", localName: "AUTO_TURNED_OFF_FOR_AMOUNT"},
    {no: 3, name: "WITHDRAWAL_REASON_AMOUNT_OUT_OF_RANGE", localName: "AMOUNT_OUT_OF_RANGE"},
    {no: 4, name: "WITHDRAWAL_REASON_PLAYER_NOT_FOUND", localName: "PLAYER_NOT_FOUND"},
    {no: 5, name: "WITHDRAWAL_REASON_PLAYER_PROFILE_NOT_FILLED", localName: "PLAYER_PROFILE_NOT_FILLED"},
    {no: 6, name: "WITHDRAWAL_REASON_PLAYER_NO_VERIFICATION", localName: "PLAYER_NO_VERIFICATION"},
    {no: 7, name: "WITHDRAWAL_REASON_PLAYER_EMAIL_NOT_VERIFIED", localName: "PLAYER_EMAIL_NOT_VERIFIED"},
    {no: 8, name: "WITHDRAWAL_REASON_LAST_DEPOSIT_TURNOVER", localName: "LAST_DEPOSIT_TURNOVER"},
    {no: 9, name: "WITHDRAWAL_REASON_TURNOVER", localName: "TURNOVER"},
    {no: 10, name: "WITHDRAWAL_REASON_BET_COUNT_CASINO", localName: "BET_COUNT_CASINO"},
    {no: 11, name: "WITHDRAWAL_REASON_BET_COUNT_SPORT", localName: "BET_COUNT_SPORT"},
    {no: 12, name: "WITHDRAWAL_REASON_NO_BET_SINCE_LAST_DEPOSIT", localName: "NO_BET_SINCE_LAST_DEPOSIT"},
    {no: 13, name: "WITHDRAWAL_REASON_LIMIT", localName: "LIMIT"},
    {no: 14, name: "WITHDRAWAL_REASON_BLOCK_CASINO_STATUS", localName: "BLOCK_CASINO_STATUS"},
    {no: 15, name: "WITHDRAWAL_REASON_BLOCK_SPORT_STATUS", localName: "BLOCK_SPORT_STATUS"},
    {no: 16, name: "WITHDRAWAL_REASON_NOT_SAME_SYSTEM_NAME", localName: "NOT_SAME_SYSTEM_NAME"},
    {no: 17, name: "WITHDRAWAL_REASON_AUTO_OFF_FAST", localName: "AUTO_OFF_FAST"},
    {no: 18, name: "WITHDRAWAL_REASON_AUTO_OFF_ALL_IN_FIRST_DEP", localName: "AUTO_OFF_ALL_IN_FIRST_DEP"},
    {no: 19, name: "WITHDRAWAL_REASON_AUTO_OFF_SUSPICIOUS_SESSIONS", localName: "AUTO_OFF_SUSPICIOUS_SESSIONS"},
    {no: 20, name: "WITHDRAWAL_REASON_AUTO_OFF_ANTI_FRAUD_REJECTION", localName: "AUTO_OFF_ANTI_FRAUD_REJECTION"},
    {no: 21, name: "WITHDRAWAL_REASON_AUTO_OFF_VERIFICATION_REJECTION", localName: "AUTO_OFF_VERIFICATION_REJECTION"},
    {no: 22, name: "WITHDRAWAL_REASON_TOTAL_DAILY_AMOUNT_LIMIT_EXCEEDED", localName: "TOTAL_DAILY_AMOUNT_LIMIT_EXCEEDED"},
    {no: 23, name: "WITHDRAWAL_REASON_LAST_DEPOSIT_RISK_REVIEW", localName: "LAST_DEPOSIT_RISK_REVIEW"},
    {no: 24, name: "WITHDRAWAL_REASON_PLAYER_NOT_VERIFIED", localName: "PLAYER_NOT_VERIFIED"},
    {no: 25, name: "WITHDRAWAL_REASON_CREDIT_CART_CONFIRM_OWNERSHIP", localName: "CREDIT_CART_CONFIRM_OWNERSHIP"},
    {no: 26, name: "WITHDRAWAL_REASON_FIRST_MANUAL", localName: "FIRST_MANUAL"},
    {no: 27, name: "WITHDRAWAL_REASON_PAYMENT_METHOD_BLOCKED_FOR_AUTO", localName: "PAYMENT_METHOD_BLOCKED_FOR_AUTO"},
    {no: 28, name: "WITHDRAWAL_REASON_VALIDATION_ERROR", localName: "VALIDATION_ERROR"},
  ],
);

/**
 * @generated from enum stat.bo.withdrawals.WithdrawalRiskStatus
 */
export const WithdrawalRiskStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.withdrawals.WithdrawalRiskStatus",
  [
    {no: 0, name: "WITHDRAWAL_RISK_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_RISK_STATUS_ACCEPTED", localName: "ACCEPTED"},
    {no: 2, name: "WITHDRAWAL_RISK_STATUS_DECLINED", localName: "DECLINED"},
  ],
);

/**
 * withdrawal has 4 statuses: process, success, pending, failed
 * combination of status and paymentStatus determine the public status of the withdrawal
 * - pending: paymentStatus=WITHDRAWAL_PAYMENT_STATUS_PROCESSING
 * - success: paymentStatus=WITHDRAWAL_PAYMENT_STATUS_SUCCESSFUL
 * - failed: status=WITHDRAWAL_STATUS_CANCELED || status=WITHDRAWAL_STATUS_CANCELED_USER
 * - process: status=WITHDRAWAL_STATUS_PROCESSING || status=WITHDRAWAL_STATUS_AV_PROCESSING || status=WITHDRAWAL_STATUS_RISK_REVIEWING
 *
 * @generated from message stat.bo.withdrawals.Withdrawal
 */
export const Withdrawal = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.Withdrawal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "playerVerifyStatus", kind: "enum", T: proto3.getEnumType(AccountVerifyState) },
    { no: 4, name: "isPlayerVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "isPlayerTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "amount", kind: "message", T: SumCurrency },
    { no: 7, name: "balance", kind: "message", T: SumCurrency },
    { no: 8, name: "balanceAfter", kind: "message", T: SumCurrency },
    { no: 9, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "paymentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "paymentMethodId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "paymentSystemId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "paymentAggregatorId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "status", kind: "enum", T: proto3.getEnumType(WithdrawalStatus) },
    { no: 15, name: "paymentStatus", kind: "enum", T: proto3.getEnumType(WithdrawalPaymentStatus) },
    { no: 16, name: "createdAt", kind: "message", T: Timestamp },
    { no: 17, name: "updatedAt", kind: "message", T: Timestamp },
    { no: 18, name: "userId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 19, name: "userCommentId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 20, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "isHacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "block", kind: "message", T: Block },
    { no: 23, name: "riskStatus", kind: "enum", T: proto3.getEnumType(WithdrawalRiskStatus) },
    { no: 24, name: "riskCreatedAt", kind: "message", T: Timestamp },
    { no: 25, name: "riskCompletedAt", kind: "message", T: Timestamp },
    { no: 26, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "isAccNumberMismatch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "convertedFinance", kind: "message", T: Withdrawal_Finance },
    { no: 29, name: "playerDigitainStatus", kind: "enum", T: proto3.getEnumType(DigitainStatus) },
    { no: 30, name: "consideredAt", kind: "message", T: Timestamp },
    { no: 31, name: "commission", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 32, name: "riskInitiatorId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 33, name: "riskReviewerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 34, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.Withdrawal.Finance
 */
export const Withdrawal_Finance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.Withdrawal.Finance",
  () => [
    { no: 1, name: "amount", kind: "message", T: SumCurrency },
    { no: 2, name: "balance", kind: "message", T: SumCurrency },
    { no: 3, name: "balanceAfter", kind: "message", T: SumCurrency },
  ],
  {localName: "Withdrawal_Finance"},
);

/**
 * finance:
 *   manual:
 *          filter.statuses = [WITHDRAWAL_STATUS_PROCESSING]
 *   auto:
 *          filter.statuses        = [WITHDRAWAL_STATUS_SUCCESSFUL, WITHDRAWAL_STATUS_CANCELED]
 *          filter.confirmType     = [WITHDRAWAL_CONFIRM_TYPE_AUTO]
 *   isTest:
 *          filter.isPlayerTest = true
 * risk:
 *   manual:
 *          filter.statuses = [WITHDRAWAL_STATUS_RISK_REVIEWING]
 *   isTest:
 *          filter.isPlayerTest = true
 *          filter.hasRiskAssessment = true
 *   all:
 *          filter.hasRiskAssessment = true
 *
 * @generated from message stat.bo.withdrawals.ListWithdrawalsRequest
 */
export const ListWithdrawalsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListWithdrawalsRequest_Filter },
    { no: 2, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "withTotals", kind: "message", T: BoolValue },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.ListWithdrawalsRequest.StringMultiselect
 */
export const ListWithdrawalsRequest_StringMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsRequest.StringMultiselect",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "excludedIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ListWithdrawalsRequest_StringMultiselect"},
);

/**
 * @generated from message stat.bo.withdrawals.ListWithdrawalsRequest.Uint64Multiselect
 */
export const ListWithdrawalsRequest_Uint64Multiselect = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsRequest.Uint64Multiselect",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "excludedIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
  {localName: "ListWithdrawalsRequest_Uint64Multiselect"},
);

/**
 * @generated from message stat.bo.withdrawals.ListWithdrawalsRequest.ReasonMultiselect
 */
export const ListWithdrawalsRequest_ReasonMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsRequest.ReasonMultiselect",
  () => [
    { no: 1, name: "reasons", kind: "enum", T: proto3.getEnumType(WithdrawalReason), repeated: true },
    { no: 2, name: "excludedReasons", kind: "enum", T: proto3.getEnumType(WithdrawalReason), repeated: true },
  ],
  {localName: "ListWithdrawalsRequest_ReasonMultiselect"},
);

/**
 * @generated from message stat.bo.withdrawals.ListWithdrawalsRequest.DeclineReasonMultiselect
 */
export const ListWithdrawalsRequest_DeclineReasonMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsRequest.DeclineReasonMultiselect",
  () => [
    { no: 1, name: "reasons", kind: "enum", T: proto3.getEnumType(WithdrawalDeclineReason), repeated: true },
    { no: 2, name: "excludedReasons", kind: "enum", T: proto3.getEnumType(WithdrawalDeclineReason), repeated: true },
  ],
  {localName: "ListWithdrawalsRequest_DeclineReasonMultiselect"},
);

/**
 * @generated from message stat.bo.withdrawals.ListWithdrawalsRequest.Filter
 */
export const ListWithdrawalsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsRequest.Filter",
  () => [
    { no: 1, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "isPlayerVip", kind: "message", T: BoolValue },
    { no: 3, name: "isPlayerTest", kind: "message", T: BoolValue },
    { no: 4, name: "playerVerifyStatus", kind: "enum", T: proto3.getEnumType(AccountVerifyState), repeated: true },
    { no: 5, name: "currency", kind: "message", T: ListWithdrawalsRequest_StringMultiselect },
    { no: 6, name: "currencyId", kind: "message", T: ListWithdrawalsRequest_Uint64Multiselect },
    { no: 7, name: "amount", kind: "message", T: Int64Range },
    { no: 8, name: "paymentAggregator", kind: "message", T: ListWithdrawalsRequest_Uint64Multiselect },
    { no: 9, name: "paymentMethod", kind: "message", T: ListWithdrawalsRequest_Uint64Multiselect },
    { no: 10, name: "geo", kind: "message", T: ListWithdrawalsRequest_StringMultiselect },
    { no: 11, name: "userIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 12, name: "confirmType", kind: "enum", T: proto3.getEnumType(WithdrawalConfirmType), repeated: true },
    { no: 13, name: "statuses", kind: "enum", T: proto3.getEnumType(WithdrawalStatus), repeated: true },
    { no: 14, name: "paymentStatuses", kind: "enum", T: proto3.getEnumType(WithdrawalPaymentStatus), repeated: true },
    { no: 15, name: "reason", kind: "message", T: ListWithdrawalsRequest_ReasonMultiselect },
    { no: 16, name: "declineReason", kind: "message", T: ListWithdrawalsRequest_DeclineReasonMultiselect },
    { no: 17, name: "createdAt", kind: "message", T: TimeRange },
    { no: 18, name: "updatedAt", kind: "message", T: TimeRange },
    { no: 19, name: "isHacked", kind: "message", T: BoolValue },
    { no: 20, name: "isWait", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "riskStatuses", kind: "enum", T: proto3.getEnumType(WithdrawalRiskStatus), repeated: true },
    { no: 22, name: "riskDuration", kind: "message", T: Int64Value },
    { no: 23, name: "balance", kind: "message", T: SumRange },
    { no: 24, name: "balanceAfter", kind: "message", T: SumRange },
    { no: 25, name: "statusChanged", kind: "message", T: BoolValue },
    { no: 26, name: "consideredAt", kind: "message", T: TimeRange },
    { no: 27, name: "hasRiskAssessment", kind: "message", T: BoolValue },
    { no: 28, name: "withdrawalIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 29, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
  ],
  {localName: "ListWithdrawalsRequest_Filter"},
);

/**
 * @generated from message stat.bo.withdrawals.ListWithdrawalsResponse
 */
export const ListWithdrawalsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.ListWithdrawalsResponse",
  () => [
    { no: 1, name: "withdrawals", kind: "message", T: Withdrawal, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 3, name: "totalAmount", kind: "message", T: SumCurrency },
    { no: 4, name: "avrRiskDuration", kind: "message", T: Duration },
  ],
);

