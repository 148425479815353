import type { Injector, ValueEqualityFn, WritableSignal } from '@angular/core';
import { DestroyRef, effect, inject, INJECTOR, signal } from '@angular/core';
import { defaultEquals } from '@angular/core/primitives/signals';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { STORAGE_EVENT } from './storage-event';

interface LocalStorageSignalOptions<T> {
  equal?: ValueEqualityFn<T>;
  injector?: Injector;
}

// TODO handle storage event only when necessary
// TODO parse only when necessary
export function localStorageSignal<T>(
  key: string,
  parse: (value: string) => T,
  stringify: (value: T) => string,
  defaultValue: T,
  {
    equal = defaultEquals,
    injector = inject(INJECTOR),
  }: LocalStorageSignalOptions<T> = {},
): WritableSignal<T> {
  const init: string | null = localStorage.getItem(key);
  let currentValue: T = defaultValue;

  if (init !== null) {
    try {
      currentValue = parse(init);
    } catch (error) {
      console.error('localStorageSignal initial parse', error);
    }
  }

  const result = signal<T>(currentValue, { equal });

  injector
    .get(STORAGE_EVENT)
    .pipe(takeUntilDestroyed(injector.get(DestroyRef)))
    .subscribe((event) => {
      if (event.key !== key && event.key !== null) {
        return;
      }

      currentValue = defaultValue;

      if (event.newValue !== null) {
        try {
          currentValue = parse(event.newValue);
        } catch (error) {
          console.error('localStorageSignal parse', error);
        }
      }

      result.set(currentValue);
    });

  effect(
    () => {
      const newValue: T = result();

      if (equal(currentValue, newValue)) {
        return;
      }

      currentValue = newValue;

      if (equal(newValue, defaultValue)) {
        localStorage.removeItem(key);
        return;
      }

      try {
        localStorage.setItem(key, stringify(newValue));
      } catch (error) {
        console.log('localStorageSignal stringify', error);
      }
    },
    { injector },
  );

  return result;
}
