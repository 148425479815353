import type { OnInit } from '@angular/core';
import { DestroyRef, Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isDate, isNil, pickBy } from 'lodash-es';

import { CurrentRouteNavigationService, omitEmptyObjectProps } from '@pu/sdk';
import type { FiltersForm, RootFiltersForm } from '@bo/common';

import { normalizeDates } from './filters.utils';
import type { FiltersFormResolver } from './filters-form-resolver/filters-form-resolver.model';
import { FILTERS_FORM_RESOLVER } from './filters-form-resolver/filters-form-resolver.providers';

@Directive()
// TODO: provide more precise type if needed
export abstract class FiltersComponent<T extends object, F extends object = T>
  implements OnInit
{
  private readonly destroyRef = inject(DestroyRef);
  private readonly filterService = inject<FiltersFormResolver<T>>(
    FILTERS_FORM_RESOLVER,
  );
  protected readonly navigationService = inject(CurrentRouteNavigationService);
  protected readonly filtersWithAllowedFalsyValue: readonly string[] = [];

  public abstract form: FiltersForm<T> & RootFiltersForm<F>;

  public ngOnInit(): void {
    this.filterService.filters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.form.patchValue(data);
      });
  }

  protected normalizeFilters(): Partial<F> {
    const filters = this.form.getRootValue();
    const dates = pickBy(filters, isDate);
    const rawFilters = this.pickFilters({
      ...filters,
      ...normalizeDates(dates),
    });

    return omitEmptyObjectProps<Partial<F>>(rawFilters);
  }

  protected pickFilters<S extends F>(filters: S): Partial<S> {
    const pickedFilters = pickBy<S>(
      filters,
      (value, key) =>
        (this.filtersWithAllowedFalsyValue.includes(key) && !isNil(value)) ||
        Boolean(value),
    );

    return omitEmptyObjectProps(pickedFilters);
  }

  public applyFilters(): void {
    const resultFilters = this.normalizeFilters();

    this.navigationService.setParams(resultFilters, true);
  }
}
