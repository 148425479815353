import { inject, Injectable } from '@angular/core';
import { BillingRemoteService } from '@pinup-grpc/pinup/stat/report/billing/billing_ng';
import type {
  ListTransactionsRequest,
  ListTransactionsRequest_Filter,
  ListTransactionsResponse,
} from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import type { Player } from '@pinup-grpc/services/bo/players/players_pb';
import { merge } from 'lodash-es';
import type { Observable } from 'rxjs';

import type { Pagination } from '@bo/common';
import { DEFAULT_CONVERT_CURRENCY } from '@bo/common';

@Injectable()
export class TransactionsProvider {
  private readonly billingRemoteService = inject(BillingRemoteService);

  public getTransactions(
    { currentPage: page, pageSize }: Pagination,
    player: Player | null,
    filter: Partial<ListTransactionsRequest_Filter>,
    period?: ListTransactionsRequest['period'],
  ): Observable<ListTransactionsResponse> {
    const defaultCurrency = player?.currency || DEFAULT_CONVERT_CURRENCY;

    return this.billingRemoteService.listTransactions({
      convertCurrency: DEFAULT_CONVERT_CURRENCY as string,
      pagination: { page, pageSize },
      period,
      sorting: [],
      filter: {
        playerIds: player ? [player.id] : [],
        ...merge(filter, {
          amount: {
            currency: filter.amount?.currency || defaultCurrency,
          },
          balance: {
            currency: filter.balance?.currency || defaultCurrency,
          },
          balanceBefore: {
            currency: filter.balanceBefore?.currency || defaultCurrency,
          },
        }),
      },
    });
  }
}
