import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PartialMessage } from '@bufbuild/protobuf';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs';

import type { DeclineRequest } from '@pu/grpc';
import { DeclineReason } from '@pu/grpc';
import { payments_backoffice_withdrawals_WithdrawalService as WithdrawalRemoteService } from '@pu/grpc/services';
import {
  DIALOG_NOTE_COMMENT,
  DIALOG_NOTE_MAX_LENGTH,
  DIALOG_NOTE_MIN_LENGTH,
  DIALOG_NOTE_PINNED,
} from '@pu/pinup';
import {
  DialogContentComponent,
  DialogSuspenseDirective,
  InertSuspenseDirective,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  ValidationErrorPipe,
} from '@pu/sdk';

import type { DialogResult } from './withdrawal-dialog-decline.config';

export interface WithdrawalDialogDeclineOptions {
  readonly reason: DeclineReason;
}

export interface WithdrawalDialogDeclineData
  extends WithdrawalDialogDeclineOptions {
  readonly withdrawalId: bigint;
}

export const WITHDRAWAL_DIALOG_DECLINE_DATA =
  new InjectionToken<WithdrawalDialogDeclineData>(
    'WITHDRAWAL_DIALOG_DECLINE_DATA',
  );

const REASON_OPTIONS = [
  {
    label: 'Turnover needed',
    value: DeclineReason.TURNOVER_NEEDED,
  },
  {
    label: 'Verification needed',
    value: DeclineReason.VERIFICATION_NEEDED,
  },
  {
    label: 'Dep/With methods not matched',
    value: DeclineReason.WITHDRAWAL_METHOD_NOT_MATCHED,
  },
  {
    label: 'Full Verification',
    value: DeclineReason.FULL_VERIFICATION,
  },
  {
    label: 'Selfie needed',
    value: DeclineReason.SELFIE_NEEDED,
  },
  {
    label: 'Recreate the withdrawal',
    value: DeclineReason.RECREATE_WITHDRAWAL,
  },
  {
    label: 'Liveness needed',
    value: DeclineReason.LIVENESS_NEEDED,
  },
  {
    label: 'Checking by provider',
    value: DeclineReason.CHECKING_BY_PROVIDER,
  },
  {
    label: 'Other',
    value: DeclineReason.OTHER,
  },
] as const;

@Component({
  selector: 'bo-withdrawal-dialog-decline',
  standalone: true,
  imports: [
    MatDialogTitle,
    SuspenseDirective,
    FormsModule,
    DialogSuspenseDirective,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatRadioGroup,
    MatRadioButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatError,
    ValidationErrorPipe,
    StrictMinLengthDirective,
    SuspenseAreaComponent,
    CdkTextareaAutosize,
    MatCheckbox,
    InertSuspenseDirective,
    DialogContentComponent,
  ],
  templateUrl: './withdrawal-dialog-decline.component.html',
  styleUrl: './withdrawal-dialog-decline.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithdrawalDialogDeclineComponent {
  protected readonly DeclineReason = DeclineReason;
  protected readonly REASON_OPTIONS = REASON_OPTIONS;

  private readonly dialogRef = inject(MatDialogRef<unknown, DialogResult>);
  private readonly snackBar = inject(MatSnackBar);
  protected readonly data = inject(WITHDRAWAL_DIALOG_DECLINE_DATA);
  protected readonly minLength = inject(DIALOG_NOTE_MIN_LENGTH);
  protected readonly maxLength = inject(DIALOG_NOTE_MAX_LENGTH);
  private readonly withdrawal = inject(WithdrawalRemoteService);

  protected readonly reason = signal(this.data.reason);
  protected readonly comment = signal(inject(DIALOG_NOTE_COMMENT));
  protected readonly pinned = signal(inject(DIALOG_NOTE_PINNED));

  protected send(): Observable<unknown> {
    const { withdrawalId } = this.data;

    const comment = this.comment();
    const reason = this.reason();
    const isPinned = this.pinned();

    const input: PartialMessage<DeclineRequest> = { withdrawalId, reason };

    if (comment) {
      input.comment = comment;
    }

    if (isPinned) {
      input.isPinned = isPinned;
    }

    return this.withdrawal.decline(input).pipe(
      tap({
        complete: () => {
          let message = `Decline payment: ${withdrawalId}`;

          if (reason === DeclineReason.OTHER) {
            message += ` ${comment}`;
          }

          this.snackBar.open(message, 'OK', { duration: 5000 });
          this.dialogRef.close('save');
        },
      }),
    );
  }
}
