import type { EnvironmentProviders } from '@angular/core';
import { Injectable, makeEnvironmentProviders } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  timer,
} from 'rxjs';

import type {
  LoadingIndicationFeature,
  LoadingIndicationKind,
} from './loading-indication-feature';

const MINIMUM = 100;

@Injectable()
export class LoadingIndicationService {
  private readonly queryCount$ = new BehaviorSubject(0);
  private readonly indeterminateCount$ = new BehaviorSubject(0);

  public readonly mode$ = combineLatest([
    this.queryCount$,
    this.indeterminateCount$,
  ]).pipe(
    map(([query, indeterminate]) =>
      query !== 0 ? 'query' : indeterminate !== 0 ? 'indeterminate' : null,
    ),
    switchMap((mode) =>
      mode === null ? timer(MINIMUM).pipe(map(() => null)) : of(mode),
    ),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly query$ = new Observable<never>(() => {
    this.queryCount$.next(this.queryCount$.value + 1);

    return () => {
      this.queryCount$.next(this.queryCount$.value - 1);
    };
  });

  public readonly indeterminate$ = new Observable<never>(() => {
    this.indeterminateCount$.next(this.indeterminateCount$.value + 1);

    return () => {
      this.indeterminateCount$.next(this.indeterminateCount$.value - 1);
    };
  });
}

export function provideLoadingIndication(
  ...features: LoadingIndicationFeature<LoadingIndicationKind>[]
): EnvironmentProviders {
  return makeEnvironmentProviders([
    LoadingIndicationService,
    ...features.map(({ providers }) => providers),
  ]);
}
