import type { Status } from '@pinup-grpc/services/bo/players/players_pb';

import { verified, vip } from '@bo/common';

import type { PlayerStatusIcon } from './player-status.model';

export function getPlayerStatusIconsBase(
  isVerified: boolean,
  isVip: boolean,
): PlayerStatusIcon[] {
  const statusIcons: PlayerStatusIcon[] = [];

  if (isVerified) {
    statusIcons.push({ icon: verified });
  }

  if (isVip) {
    statusIcons.push({ icon: vip });
  }

  return statusIcons;
}

export function getIconsFromStatus(
  { account }: Status,
  isVip: boolean,
): PlayerStatusIcon[] {
  return getPlayerStatusIconsBase(account.verified, isVip);
}
