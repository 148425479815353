import { InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';
import { fromEvent, share } from 'rxjs';

export const STORAGE_EVENT = new InjectionToken<Observable<StorageEvent>>(
  'STORAGE_EVENT',
  {
    providedIn: 'root',
    factory: () => fromEvent<StorageEvent>(window, 'storage').pipe(share()),
  },
);
