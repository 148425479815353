// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/players/player/payments/payments.proto (package pinup.players.player.payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.players.player.payments.GetAccountInfoRequest
 */
export const GetAccountInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAccountInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAccountInfoResponse
 */
export const GetAccountInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAccountInfoResponse",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "registerDate", kind: "message", T: Timestamp },
    { no: 4, name: "vipLocation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetPersonalInfoRequest
 */
export const GetPersonalInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetPersonalInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetPersonalInfoResponse
 */
export const GetPersonalInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetPersonalInfoResponse",
  () => [
    { no: 1, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "middleName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "birthplace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetContactRequest
 */
export const GetContactRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetContactRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetContactResponse
 */
export const GetContactResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetContactResponse",
  () => [
    { no: 1, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAddressRequest
 */
export const GetAddressRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAddressRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAddressResponse
 */
export const GetAddressResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAddressResponse",
  () => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "postcode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLanguageInfoRequest
 */
export const GetLanguageInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLanguageInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLanguageInfoResponse
 */
export const GetLanguageInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLanguageInfoResponse",
  () => [
    { no: 1, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAWInfoRequest
 */
export const GetAWInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAWInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAWInfoResponse
 */
export const GetAWInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAWInfoResponse",
  () => [
    { no: 1, name: "offAWForPlayer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "offAWForPlayerReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "offAWUpdated", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetCurrencyInfoRequest
 */
export const GetCurrencyInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetCurrencyInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetCurrencyInfoResponse
 */
export const GetCurrencyInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetCurrencyInfoResponse",
  () => [
    { no: 1, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLimitsRequest
 */
export const GetLimitsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLimitsRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLimitsResponse
 */
export const GetLimitsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLimitsResponse",
  () => [
    { no: 1, name: "percentMoneyOut", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "dayLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "monthLimit", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetDepositInfoRequest
 */
export const GetDepositInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetDepositInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetDepositInfoResponse
 */
export const GetDepositInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetDepositInfoResponse",
  () => [
    { no: 1, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "depositCountSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "depositCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "depositSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetWithdrawInfoRequest
 */
export const GetWithdrawInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetWithdrawInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetWithdrawInfoResponse
 */
export const GetWithdrawInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetWithdrawInfoResponse",
  () => [
    { no: 1, name: "withdrawCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "withdrawCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetPincoinsRequest
 */
export const GetPincoinsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetPincoinsRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetPincoinsResponse
 */
export const GetPincoinsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetPincoinsResponse",
  () => [
    { no: 1, name: "privelegeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAffiliateRequest
 */
export const GetAffiliateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAffiliateRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetAffiliateResponse
 */
export const GetAffiliateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetAffiliateResponse",
  () => [
    { no: 1, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "clickID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLastActivityRequest
 */
export const GetLastActivityRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLastActivityRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLastActivityResponse
 */
export const GetLastActivityResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLastActivityResponse",
  () => [
    { no: 1, name: "lastBetTime", kind: "message", T: Timestamp },
    { no: 2, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 3, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 4, name: "lastDepositTime", kind: "message", T: Timestamp },
    { no: 5, name: "lastDepositTimeBet", kind: "message", T: Timestamp },
    { no: 6, name: "lastMoneyOutTime", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetStatusRequest
 */
export const GetStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetStatusRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetStatusResponse
 */
export const GetStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetStatusResponse",
  () => [
    { no: 1, name: "activeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "casinoStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "bettingStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "casinoBankStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 9, name: "blockReason", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLegalRequest
 */
export const GetLegalRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLegalRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetLegalResponse
 */
export const GetLegalResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetLegalResponse",
  () => [
    { no: 1, name: "CPF", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "taxIDChecked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "taxID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "certifiedStatus", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "certifiedPlayerUpdated", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetBonusRequest
 */
export const GetBonusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetBonusRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetBonusResponse
 */
export const GetBonusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetBonusResponse",
  () => [
    { no: 1, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "restrictedSportBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetVerificationRequest
 */
export const GetVerificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetVerificationRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetVerificationResponse
 */
export const GetVerificationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetVerificationResponse",
  () => [
    { no: 1, name: "verificationStatus", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "accountVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "twoFactorVerification", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "emailVerified", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "mobilePhoneVerification", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetBalanceRequest
 */
export const GetBalanceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetBalanceRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.payments.GetBalanceResponse
 */
export const GetBalanceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.payments.GetBalanceResponse",
  () => [
    { no: 1, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

