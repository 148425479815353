import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type {
  PlayerStatistic,
  Status,
} from '@pinup-grpc/services/bo/players/players_pb';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  merge,
  of,
  scan,
  shareReplay,
  skip,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import type { FiltersProvider, StatusInfo } from '@bo/common';
import {
  autoWithdrawalStatusOn,
  FILTERS_PROVIDER,
  getIconsFromStatus,
  getStatusFromMap,
  PlayersProvider,
  smsStatusInfo,
} from '@bo/common';

import type { InfinityPaginationOptions } from '../player/infinity-pagination.model';
import type { ListPlayersFilter } from '../players-filter/players-filter.types';
import { StatusService } from '../services/status.service';
import { DEFAULT_PAGINATION_INFINITY } from './players.const';
import type { PlayersVM } from './players.vm';

@Injectable()
export class PlayersService {
  private readonly provider = inject(PlayersProvider);
  private readonly destroyRef = inject(DestroyRef);
  private readonly statusService = inject(StatusService);

  private readonly playersFilterService =
    inject<FiltersProvider<ListPlayersFilter>>(FILTERS_PROVIDER);

  private readonly filters$ = this.playersFilterService.filters$;
  private readonly changedFilters$ = this.playersFilterService.changedFilters$;
  private readonly initialFilters$ = this.playersFilterService.initialFilters$;

  private readonly pagination$ = new BehaviorSubject<InfinityPaginationOptions>(
    DEFAULT_PAGINATION_INFINITY,
  );

  private readonly resetPlayers$ = this.initialFilters$.pipe(
    map<unknown, PlayersVM[]>(() => []),
  );

  private readonly getPlayers$ = this.changedFilters$.pipe(
    withLatestFrom(this.pagination$),
    switchMap(([filters, pagination]) =>
      this.getPlayers(filters, pagination).pipe(
        switchMap((players) => this.getPaginationSource(filters, players)),
      ),
    ),
  );

  public readonly players$ = merge(this.resetPlayers$, this.getPlayers$).pipe(
    shareReplay(1),
  );

  public hasError$ = this.players$.pipe(
    map(() => false),
    catchError(() => of(true)),
  );

  public hasData$ = this.players$.pipe(map((response) => response.length > 0));

  private readonly isDataLoaded$ = this.players$.pipe(
    map(() => false),
    catchError(() => of(false)),
  );

  public isLoading$ = merge(
    of(false),
    this.changedFilters$.pipe(map(() => true)),
    this.isDataLoaded$,
  ).pipe(distinctUntilChanged());

  public isInfinityScrollLoading$ = merge(
    of(false),
    this.pagination$.pipe(map(({ lastElementId }) => Boolean(lastElementId))),
    this.isDataLoaded$,
  ).pipe(distinctUntilChanged());

  constructor() {
    this.filters$
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.resetPagination();
      });
  }

  public setPagination(lastElementId: bigint): void {
    this.pagination$.next({
      ...DEFAULT_PAGINATION_INFINITY,
      lastElementId,
    });
  }

  private resetPagination(): void {
    this.setPagination(0n);
  }

  private getPlayers(
    filters: Partial<ListPlayersFilter>,
    pagination: InfinityPaginationOptions,
  ): Observable<PlayersVM[]> {
    return this.provider
      .listPlayerStatistics(filters, pagination)
      .pipe(map(({ players }) => this.constructVM(players)));
  }

  private getPaginationSource(
    filters: Partial<ListPlayersFilter>,
    initialPlayers: PlayersVM[],
  ): Observable<PlayersVM[]> {
    return this.pagination$.pipe(
      distinctUntilKeyChanged('lastElementId'), // Needed to bypass extra onScroll event. Should be fixed
      filter(({ lastElementId }) => Boolean(lastElementId)),
      switchMap((pagination) => this.getPlayers(filters, pagination)),
      startWith(initialPlayers),
      scan<PlayersVM[], PlayersVM[]>(
        (acc, current) => [...acc, ...current],
        [],
      ),
    );
  }

  private computeSystemStatuses(status: Status, awOff: boolean): StatusInfo[] {
    const statuses = this.statusService.getStatuses(status);
    if (!awOff) {
      return [...statuses, autoWithdrawalStatusOn];
    }
    return statuses;
  }

  private constructVM(players: PlayerStatistic[]): PlayersVM[] {
    return players.map((playerStatistic) => ({
      ...playerStatistic,
      playerStatuses: getIconsFromStatus(
        playerStatistic.profile.status,
        playerStatistic.profile.isVip,
      ),
      systemStatuses: this.computeSystemStatuses(
        playerStatistic.profile.status,
        playerStatistic.profile.autoWithdrawal.off,
      ),
      smsStatus: getStatusFromMap(
        smsStatusInfo,
        playerStatistic.profile.status.sms,
      ),
    }));
  }
}
