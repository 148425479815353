// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/google_auth/simple/service.proto (package pinup.google_auth.simple, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { RefreshTokenRequest, RefreshTokenResponse, SignInRequest, SignInResponse } from "./service_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.google_auth.simple.SimpleAuthService
 */
@Injectable({ providedIn: "root" })
export class SimpleAuthRemoteService {
  public static readonly typeName = "pinup.google_auth.simple.SimpleAuthService";

  public static readonly methods = {
    signIn: {
      name: "SignIn",
      I: SignInRequest,
      O: SignInResponse,
      kind: MethodKind.Unary,
    },
    refreshToken: {
      name: "RefreshToken",
      I: RefreshTokenRequest,
      O: RefreshTokenResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.google_auth.simple.SimpleAuthService.SignIn
   */
  public signIn(
    input: PartialMessage<SignInRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SignInResponse> {
    return unary<SignInRequest, SignInResponse>(
      this.transport,
      SimpleAuthRemoteService,
      SimpleAuthRemoteService.methods.signIn,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.google_auth.simple.SimpleAuthService.RefreshToken
   */
  public refreshToken(
    input: PartialMessage<RefreshTokenRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<RefreshTokenResponse> {
    return unary<RefreshTokenRequest, RefreshTokenResponse>(
      this.transport,
      SimpleAuthRemoteService,
      SimpleAuthRemoteService.methods.refreshToken,
      input,
      options,
    );
  }
}
