<form
  suspense-area
  (ngSubmit)="suspense.run(changeStatus())"
  puDialogSuspense
  puInertSuspense
  puSuspense
  #form="ngForm"
  #suspense="puSuspense"
>
  <div mat-dialog-title>Change Player's status</div>
  <pu-dialog-content>
    <mat-form-field class="field">
      <mat-label>Select a Player's status</mat-label>
      <mat-select [(ngModel)]="status" name="status" required>
        <mat-option [value]="ChangePlayerStatus.CAPPER" disabled>
          Capper
        </mat-option>
        <mat-option [value]="ChangePlayerStatus.STREAMER" disabled>
          Streamer
        </mat-option>
        <mat-option [value]="ChangePlayerStatus.TEST">Test</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="field">
      <mat-label>Note</mat-label>
      <textarea
        [(ngModel)]="comment"
        [maxlength]="maxLength"
        [puStrictMinLength]="minLength"
        cdkAutosizeMinRows="2"
        cdkTextareaAutosize
        matInput
        name="comment"
        placeholder="Type here…"
        #commentField="ngModel"
      ></textarea>
      @if (commentField | validationError; as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
    <mat-checkbox [(ngModel)]="isPinned" name="isPinned">Pin note</mat-checkbox>
  </pu-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="button" matDialogClose="cancel" type="button">
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="form.invalid"
      color="primary"
      type="submit"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</form>
