// @generated by protoc-gen-es v1.10.0
// @generated from file services/common/common.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.common, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.common.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.common.PaginationRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "pageSize", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.common.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.common.PaginationResponse",
  () => [
    { no: 1, name: "currentPage", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "totalPages", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "totalItems", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.common.Sorting
 */
export const Sorting = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.common.Sorting",
  () => [
    { no: 1, name: "fieldName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(Sorting_Order) },
  ],
);

/**
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.common.Sorting.Order
 */
export const Sorting_Order = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.common.Sorting.Order",
  [
    {no: 0, name: "ASC"},
    {no: 1, name: "DESC"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.common.Int64Range
 */
export const Int64Range = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.common.Int64Range",
  () => [
    { no: 1, name: "from", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "to", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.common.TimeRange
 */
export const TimeRange = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.common.TimeRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ],
);

