import type { AfterViewInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  input,
  viewChild,
} from '@angular/core';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { isNil } from 'lodash-es';
import { identity } from 'rxjs';

import type { AutocompleteContext, AutocompleteEqual } from '@pu/sdk';
import {
  AutocompletePipeWithMultipleHighlights,
  AutocompleteTriggerDirective,
} from '@pu/sdk';

import { MultiHighlightMatchComponent } from '../multi-highlight-match/multi-highlight-match.component';

@Component({
  selector: 'bo-autocomplete',
  standalone: true,
  imports: [
    MatAutocomplete,
    MatOption,
    MultiHighlightMatchComponent,
    AutocompletePipeWithMultipleHighlights,
  ],
  templateUrl: './autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<T, V> implements AfterViewInit {
  public readonly list = input.required<T[] | null | undefined>();
  public readonly highlight = input.required<AutocompleteEqual<T>>();
  public readonly displayWith = input<(item: V) => string>((item) =>
    isNil(item) ? '' : String(item),
  );
  public readonly valueSelector = input<(item: T) => V>(
    identity as (value: T) => V,
  );

  public readonly autocomplete = viewChild.required<MatAutocomplete>('auto');

  public readonly controlInput = contentChild.required(
    AutocompleteTriggerDirective,
  );

  protected availableItems = computed(() => this.list() ?? []);

  protected availableEntries = computed<readonly AutocompleteContext<T, V>[]>(
    () => {
      const valueSelector = this.valueSelector();

      return this.availableItems().map((item) => ({
        $implicit: item,
        value: valueSelector(item),
      }));
    },
  );

  public ngAfterViewInit(): void {
    this.controlInput().trigger.autocomplete = this.autocomplete();
  }
}
