import type { ApplicationConfig } from '@angular/core';
import {
  APP_INITIALIZER,
  inject,
  mergeApplicationConfig,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideGrpcWebTransport } from '@pinup/grpc-ng';

import { provideFactory, provideMultiValue, provideValue } from '@pu/sdk';
import {
  GOOGLE_SIGN_IN_BUTTON_DEFAULT,
  GOOGLE_SIGN_IN_CALLBACK,
  provideGoogleSignIn,
} from '@pu/sdk/google';
import {
  BASE_HREF,
  PATHNAME_PREFIX,
  provideFirstAvailableLicense,
  provideGtmInfoEmpty,
  provideLicenseGrpcOrigin,
} from '@bo/common';

import { appConfigDepartments } from './app.config-departments';
import { appConfigLicenses } from './app.config-licenses';
import { appConfigSignIn } from './app.config-sign-in';
import { appConfigTransport } from './app.config-transport';
import { SignInService } from './sign-in.service';

export const appConfig: ApplicationConfig = mergeApplicationConfig(
  appConfigDepartments,
  appConfigLicenses,
  appConfigSignIn,
  appConfigTransport,
  {
    providers: [
      provideFactory(PATHNAME_PREFIX, () => inject(BASE_HREF).slice(0, -1)),
      provideExperimentalZonelessChangeDetection(),

      provideFirstAvailableLicense(),
      provideGrpcWebTransport(),
      provideLicenseGrpcOrigin(),

      provideGoogleSignIn(),
      provideMultiValue(GOOGLE_SIGN_IN_CALLBACK, ({ credential }) => {
        inject(SignInService).logIn(credential);
      }),
      provideMultiValue(APP_INITIALIZER, () => {
        google.accounts.id.prompt();
      }),
      provideValue(GOOGLE_SIGN_IN_BUTTON_DEFAULT, {
        type: 'standard',
        theme: 'outline',
        width: 190,
      }),
      provideGtmInfoEmpty(),
    ],
  },
);
