// @generated by protoc-gen-es v1.10.0
// @generated from file base/frontend/v1/achievement_enum.proto (package gamification.achievement_manager.base.frontend.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of enumeration for achievement sorting
 *
 * @generated from enum gamification.achievement_manager.base.frontend.v1.AchievementSorting
 */
export const AchievementSorting = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.frontend.v1.AchievementSorting",
  [
    {no: 0, name: "ACHIEVEMENT_SORTING_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_SORTING_BY_EVENT_DATE", localName: "BY_EVENT_DATE"},
  ],
);

