import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { PlayersSessionsRemoteService } from '@pu/grpc';
import type { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_SessionType as SessionType } from '@pu/grpc/enums';
import type { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_FindPlayerSessionsResponse as FindPlayerSessionsResponse } from '@pu/grpc/messages';
import type { Pagination } from '@bo/common';

@Injectable()
export class ProfileSessionsProviderService {
  public sessionsService = inject(PlayersSessionsRemoteService);
  private readonly snackBar = inject(MatSnackBar);

  public findPlayerSession(
    playerId: bigint,
    type: SessionType,
    pagination: Pagination,
  ): Observable<FindPlayerSessionsResponse> {
    return this.sessionsService
      .findPlayerSession({
        playerId,
        type,
        pagination: {
          page: pagination.currentPage,
          pageSize: pagination.pageSize,
        },
      })
      .pipe(
        catchError(() => {
          this.snackBar.open('Can`t get sessions, try again', 'OK', {
            duration: 5000,
          });

          return [];
        }),
      );
  }
}
