// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/insvc/achievement/v1/achievement.proto (package gamification.achievement_manager.service.insvc.achievement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetRequest, GetResponse, ListEventRequest, ListEventResponse, ListRequest, ListResponse } from "./achievement_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * AchievementService:
 * ------------------------
 * Format: proto
 * client: insvc - for internal services usages
 * server: achievement-manager
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.achievement_manager.service.insvc.achievement.v1.AchievementService
 */
@Injectable({ providedIn: "root" })
export class AchievementRemoteService {
  public static readonly typeName = "gamification.achievement_manager.service.insvc.achievement.v1.AchievementService";

  public static readonly methods = {
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
    list: {
      name: "List",
      I: ListRequest,
      O: ListResponse,
      kind: MethodKind.Unary,
    },
    listEvent: {
      name: "ListEvent",
      I: ListEventRequest,
      O: ListEventResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * Get: get full achievement info
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.insvc.achievement.v1.AchievementService.Get
   */
  public get(
    input: PartialMessage<GetRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetResponse> {
    return unary<GetRequest, GetResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.get,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * List: returns a filtered list of achievements based on the provided criteria.
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.insvc.achievement.v1.AchievementService.List
   */
  public list(
    input: PartialMessage<ListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListResponse> {
    return unary<ListRequest, ListResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.list,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * ListEvent: returns a list of events related to achievements.
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.insvc.achievement.v1.AchievementService.ListEvent
   */
  public listEvent(
    input: PartialMessage<ListEventRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListEventResponse> {
    return unary<ListEventRequest, ListEventResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.listEvent,
      input,
      options,
    );
  }
}
