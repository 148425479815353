import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import type {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { PRIMARY_OUTLET, TitleStrategy } from '@angular/router';

import { DepartmentLicenseLink } from '@bo/common';

function isPrimaryOutlet({ outlet }: ActivatedRouteSnapshot): boolean {
  return outlet === PRIMARY_OUTLET;
}

@Injectable()
export class DepartmentTitleStrategy extends TitleStrategy {
  private readonly title = inject(Title);
  private readonly link = inject(DepartmentLicenseLink);

  public override updateTitle({ root }: RouterStateSnapshot): void {
    const result: string[] = [];

    for (
      let route: ActivatedRouteSnapshot | undefined = root;
      route !== undefined;
      route = route.children.find(isPrimaryOutlet)
    ) {
      const title = String(this.getResolvedTitleForRoute(route) ?? '');

      if (title === '' || title === result[result.length - 1]) {
        continue;
      }

      result.push(title);
    }

    const { fullTitle } = this.link;

    if (result.length === 0) {
      this.title.setTitle(fullTitle);

      return;
    }

    this.title.setTitle(`${result.join(' › ')} | ${fullTitle}`);
  }
}
