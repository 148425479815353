import type { WritableSignal } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggle } from '@angular/material/slide-toggle';

import { SvgComponent, topEventsIcon } from '@pu/sdk';

import { OutsideClickDirective } from '../../../outside-click/outside-click.directive';
import { FEATURE_FLAGS } from '../../feature-flags.providers';
import { FeatureFlagsService } from '../../feature-flags.service';
import {
  featureFlagToLabelMap,
  toggleMenuPositionToCssClassMap,
} from './feature-flags-toggle-menu.config';
import { TOGGLE_MENU_CONFIG } from './feature-flags-toggle-menu.providers';

type FeatureFlagsControlConfig = Readonly<{
  signal: WritableSignal<boolean>;
  label: string;
}>;

@Component({
  selector: 'bo-feature-flags-toggle-menu',
  templateUrl: './feature-flags-toggle-menu.component.html',
  styleUrl: './feature-flags-toggle-menu.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    MatMenu,
    MatMenuTrigger,
    MatIconButton,
    MatMenuItem,
    SvgComponent,
    MatSlideToggle,
    FormsModule,
    OutsideClickDirective,
  ],
  host: {
    '[class]': 'positionCssClasses',
    '[style.--x-offset.px]': 'xOffset',
    '[style.--y-offset.px]': 'yOffset',
  },
})
export class FeatureFlagsToggleMenuComponent {
  private readonly featureFlags = inject(FEATURE_FLAGS);
  private readonly featureFlagsService = inject(FeatureFlagsService);

  private readonly config = inject(TOGGLE_MENU_CONFIG);

  protected readonly icon = topEventsIcon;
  protected readonly controlConfigs = this.createControlConfigs();

  private readonly position = this.config.position ?? 'bottomRight';

  protected readonly xOffset = this.config.xOffset ?? 50;
  protected readonly yOffset = this.config.yOffset ?? 50;

  protected readonly positionCssClasses =
    toggleMenuPositionToCssClassMap[this.position];

  protected readonly open = signal(false);

  protected toggle(): void {
    this.open.update((value) => !value);
  }

  protected close(): void {
    this.open.set(false);
  }

  private createControlConfigs(): FeatureFlagsControlConfig[] {
    return this.featureFlags.map(({ flag }) => ({
      signal: this.featureFlagsService.getSignal(flag),
      label: featureFlagToLabelMap[flag],
    }));
  }
}
