// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/google_auth/simple/service.proto (package pinup.google_auth.simple, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.google_auth.simple.SignInRequest
 */
export const SignInRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.simple.SignInRequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.simple.SignInResponse
 */
export const SignInResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.simple.SignInResponse",
  () => [
    { no: 1, name: "refresh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message pinup.google_auth.simple.RefreshTokenRequest
 */
export const RefreshTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.simple.RefreshTokenRequest",
  () => [
    { no: 1, name: "refresh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.simple.RefreshTokenResponse
 */
export const RefreshTokenResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.simple.RefreshTokenResponse",
  () => [
    { no: 1, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

