// @generated by protoc-gen-es v1.10.0
// @generated from file base/account_comment.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message players.CommentAuthor
 */
export const CommentAuthor = /*@__PURE__*/ proto3.makeMessageType(
  "players.CommentAuthor",
  () => [
    { no: 1, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

