import { inject, Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';

import { PaymentsProvider } from './payments.provider';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private readonly provider = inject(PaymentsProvider);

  public readonly paymentMethods$ = this.provider.findPaymentMethods().pipe(
    map(({ methods }) => methods),
    shareReplay(1),
  );

  public readonly paymentAggregators$ = this.provider
    .findPaymentAggregators()
    .pipe(
      map(({ aggregators }) => aggregators),
      shareReplay(1),
    );
}
