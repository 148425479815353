import type { Provider } from '@angular/core';
import type { Observable } from 'rxjs';

import {
  CurrentRouteNavigationService,
  provideClass,
  provideFactory,
} from '@pu/sdk';
import { PAGINATION_PANEL_MANAGER } from '@bo/common';

import { provideRouteParamsPersistence } from '../../../../../../data-persistence';
import type { PaginationPanelPayload } from '../../../../pagination-panel.model';
import { RouteParamsPersistenceManager } from './route-params-persistence.manager';
import {
  PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE,
  paginationPersistenceFactory,
} from './route-params-persistence.providers';

export const providePaginationPanelRouteParamsPersistence = (): Provider[] => [
  CurrentRouteNavigationService,
  provideRouteParamsPersistence(),
  provideFactory<Observable<PaginationPanelPayload>>(
    PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE,
    paginationPersistenceFactory,
  ),
  provideClass<RouteParamsPersistenceManager>(
    PAGINATION_PANEL_MANAGER,
    RouteParamsPersistenceManager,
  ),
];
