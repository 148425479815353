import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import type { PlayerLog } from '@pu/grpc';
import { SvgComponent, ToDatePipe } from '@pu/sdk';
import { unknownLog } from '@bo/common';

@Component({
  selector: 'bo-log-item',
  standalone: true,
  imports: [DatePipe, ToDatePipe, SvgComponent],
  templateUrl: './log-item.component.html',
  styleUrl: './log-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogItemComponent {
  public log = input.required<PlayerLog>();
  // TODO: must be replaced after backend impl
  protected readonly mockedUnknownIcon = unknownLog;
}
