// @generated by protoc-gen-es v1.10.0
// @generated from file service/insvc/player/v1/player.proto (package gamification.service.insvc.player.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { PlayerInfo } from "../../../../base/insvc/v1/player_pb.js";

/**
 * @generated from message gamification.service.insvc.player.v1.GetByIDsRequest
 */
export const GetByIDsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.insvc.player.v1.GetByIDsRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message gamification.service.insvc.player.v1.GetByIDsResponse
 */
export const GetByIDsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.insvc.player.v1.GetByIDsResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: PlayerInfo, repeated: true },
  ],
);

