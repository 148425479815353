import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { EmptyTemplateComponent } from '../empty-template/empty-template.component';

@Component({
  selector: 'bo-list-state-wrapper',
  standalone: true,
  imports: [CommonModule, MatProgressSpinner, EmptyTemplateComponent],
  templateUrl: './list-state-wrapper.component.html',
  styleUrl: './list-state-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListStateWrapperComponent {
  public hasData = input<boolean | null>();
  public isLoading = input<boolean | null>();
  public hasError = input<boolean | null>();
}
