// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/delete_mo_from_map.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { DeleteMOFromMapRequest, DeleteMOFromMapResponse } from "./delete_mo_from_map_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.DeleteMOFromMapService
 */
@Injectable({ providedIn: "root" })
export class DeleteMOFromMapRemoteService {
  public static readonly typeName = "payments.DeleteMOFromMapService";

  public static readonly methods = {
    deleteMoFromMap: {
      name: "DeleteMoFromMap",
      I: DeleteMOFromMapRequest,
      O: DeleteMOFromMapResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.DeleteMOFromMapService.DeleteMoFromMap
   */
  public deleteMoFromMap(
    input: PartialMessage<DeleteMOFromMapRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteMOFromMapResponse> {
    return unary<DeleteMOFromMapRequest, DeleteMOFromMapResponse>(
      this.transport,
      DeleteMOFromMapRemoteService,
      DeleteMOFromMapRemoteService.methods.deleteMoFromMap,
      input,
      options,
    );
  }
}
