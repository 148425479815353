// TODO This enum is temporary approach to be able to map names for the file and manage TAX_ID_NUMBER value for the type field
export enum VerificationDocumentType {
  SELFIE_IMAGE = 'SELFIE_IMAGE',
  PASSPORT = 'PASSPORT',
  GOVERNMENT_ID = 'GOVERNMENT_ID',
  DOMESTIC_PASSPORT = 'DOMESTIC_PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  PERMANENT_RESIDENCE_PERMIT = 'PERMANENT_RESIDENCE_PERMIT',
  REFUGEE_CARD = 'REFUGEE_CARD',
  FOREIGN_CITIZEN_PASSPORT = 'FOREIGN_CITIZEN_PASSPORT',
  SELFIE_WITH_DOCUMENT = 'SELFIE_WITH_DOCUMENT',
  SELFIE_WITH_CREDIT_CARD = 'SELFIE_WITH_CREDIT_CARD',
  ADDRESS_DOCUMENT = 'ADDRESS_DOCUMENT',
  FINANCIAL_DOCUMENT = 'FINANCIAL_DOCUMENT',
  TAX_ID_NUMBER = 'TAX_ID_NUMBER',
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_STATEMENT = 'BANK_STATEMENT',
  REGISTRATION_COMPANY = 'REGISTRATION_COMPANY',
  COMPANY_LEGAL_ADDRESS = 'COMPANY_LEGAL_ADDRESS',
  AUTHORISED_PERSON = 'AUTHORISED_PERSON',
  COMPANY_OWNERSHIP = 'COMPANY_OWNERSHIP',
  VIDEO = 'VIDEO',
}

export enum PlayerDocumentSide {
  FRONT_SIDE = 'frontside',
  BACK_SIDE = 'backside',
  OTHER_SIDE1 = 'otherside1',
  OTHER_SIDE2 = 'otherside2',
}
