// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/player_info.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPlayersIdRequest, GetPlayersIdResponse, GetPlayersInfoRequest, GetPlayersInfoResponse, GetPlayersInfoShortRequest, GetPlayersInfoShortResponse } from "./player_info_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * Players totals statistic
 *
 * @generated from service stat.PlayerInfo
 */
@Injectable({ providedIn: "root" })
export class PlayerInfoRemoteService {
  public static readonly typeName = "stat.PlayerInfo";

  public static readonly methods = {
    getPlayersId: {
      name: "GetPlayersId",
      I: GetPlayersIdRequest,
      O: GetPlayersIdResponse,
      kind: MethodKind.Unary,
    },
    getPlayersInfoShort: {
      name: "GetPlayersInfoShort",
      I: GetPlayersInfoShortRequest,
      O: GetPlayersInfoShortResponse,
      kind: MethodKind.Unary,
    },
    getPlayersInfo: {
      name: "GetPlayersInfo",
      I: GetPlayersInfoRequest,
      O: GetPlayersInfoResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * -------------------------------------------------------
   * 1.Get Player count by filter
   * 2.List Player's ids by filter, ids sorted by playerId
   * -------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.info.get_players_id
   *   GRPC Request:
   * Restriction:
   *   NATS: max 200_000 player ids at one time
   *   GRPC: max 200_000 player ids at one time
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerInfo.GetPlayersId
   */
  public getPlayersId(
    input: PartialMessage<GetPlayersIdRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayersIdResponse> {
    return unary<GetPlayersIdRequest, GetPlayersIdResponse>(
      this.transport,
      PlayerInfoRemoteService,
      PlayerInfoRemoteService.methods.getPlayersId,
      input,
      options,
    );
  }

  /**
   * -----------------------------------------------------------------
   * List Players short info by filter, ids sorted by playerId (fast)
   * -----------------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.info.get_players_info_short"
   *   GRPC Request:
   * Restriction:
   *   NATS: max 30 000 players at one time
   *   GRPC: max 30 000 players at one time
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerInfo.GetPlayersInfoShort
   */
  public getPlayersInfoShort(
    input: PartialMessage<GetPlayersInfoShortRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayersInfoShortResponse> {
    return unary<GetPlayersInfoShortRequest, GetPlayersInfoShortResponse>(
      this.transport,
      PlayerInfoRemoteService,
      PlayerInfoRemoteService.methods.getPlayersInfoShort,
      input,
      options,
    );
  }

  /**
   * ------------------------------------------------------------
   * List Players info by filter, ids sorted by playerId (slow)
   * ------------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.info.get_players_info"
   *   GRPC Request:
   * Restriction:
   *   NATS: max 4 000 players at one time
   *   GRPC: max 4 000 players at one time
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerInfo.GetPlayersInfo
   */
  public getPlayersInfo(
    input: PartialMessage<GetPlayersInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayersInfoResponse> {
    return unary<GetPlayersInfoRequest, GetPlayersInfoResponse>(
      this.transport,
      PlayerInfoRemoteService,
      PlayerInfoRemoteService.methods.getPlayersInfo,
      input,
      options,
    );
  }
}
