<button mat-stroked-button [matMenuTriggerFor]="menu">
  <bo-svg [size]="16" [unsafeSvgSource]="calendarIcon" />
  @if (period()) {
    {{ period() }}
  } @else {
    Select period
  }
</button>

<mat-menu #menu="matMenu">
  @for (option of PERIOD_OPTIONS; track option) {
    <button mat-menu-item (click)="selectOption(option)">{{ option }}</button>
  }
</mat-menu>
