<div class="log-item">
  <div class="log-header">
    <div class="icon-wrapper">
      <bo-svg class="icon" [size]="15" [unsafeSvgSource]="mockedUnknownIcon" />
    </div>
    <!-- TODO the temporary approach. Wait until the back-end side implements the required functionality -->
    <span class="department">System</span>
    <time class="date">
      {{ log().createdAt | toDate | date: 'yyyy-MM-dd, HH:mm:ss' }}
    </time>
  </div>
  <div class="log-content">
    <div class="inner-container">{{ log().text }}</div>
  </div>
</div>
