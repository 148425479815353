import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WINDOW } from '@ng-web-apis/common';
import { Subject, switchMap } from 'rxjs';

import { DocumentsManagementService } from '@bo/common';

@Component({
  selector: 'risk-file-link',
  standalone: true,
  template: `
    <ng-container>{{ documentSide() }}</ng-container>
  `,
  styleUrl: './file-link.component.scss',
  host: {
    '(click)': 'openFileAction$.next()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileLinkComponent implements OnInit, OnDestroy {
  public fileName = input.required<string>();
  public documentSide = input.required<string>();

  private readonly window = inject(WINDOW);
  private readonly destroyRef = inject(DestroyRef);
  private readonly documentsManagementService = inject(
    DocumentsManagementService,
  );

  private currentObjectUrl: string | null = null;

  protected readonly openFileAction$ = new Subject<void>();

  public ngOnInit(): void {
    this.openFileAction$
      .pipe(
        switchMap(() =>
          this.documentsManagementService.getDocumentImage(this.fileName()),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((blob) => {
        this.handleBlob(blob);
      });
  }

  private handleBlob(blob: Blob): void {
    this.revokeCurrentObjectUrl();
    this.currentObjectUrl = URL.createObjectURL(blob);
    this.window.open(this.currentObjectUrl, '_blank');
  }

  private revokeCurrentObjectUrl(): void {
    if (this.currentObjectUrl) {
      URL.revokeObjectURL(this.currentObjectUrl);
      this.currentObjectUrl = null;
    }
  }

  public ngOnDestroy(): void {
    this.revokeCurrentObjectUrl();
  }
}
