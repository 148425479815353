// @generated by protoc-gen-es v1.10.0
// @generated from file common/verificationLog.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { VerificationStatus } from "./enum_pb.js";

/**
 * VerificationLog - log contain info about change verifications status
 *
 * @generated from message kycaid.VerificationLog
 */
export const VerificationLog = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.VerificationLog",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "old_status", kind: "enum", T: proto3.getEnumType(VerificationStatus) },
    { no: 4, name: "new_status", kind: "enum", T: proto3.getEnumType(VerificationStatus) },
    { no: 5, name: "verification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "external_verification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "created", kind: "message", T: Timestamp },
  ],
);

