import { inject } from '@angular/core';
import type { Interceptor } from '@connectrpc/connect';
import { Code, ConnectError } from '@connectrpc/connect';
import { firstValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { isAuthGrpcMethod } from './is-auth-grpc-method';

function isUnauthenticated(error: unknown): boolean {
  return error instanceof ConnectError && error.code === Code.Unauthenticated;
}

export const NEW_AUTH_INTERCEPTOR: Interceptor = (next) => {
  const auth = inject(AuthService);

  return async (req) => {
    // TODO move to proto metadata
    if (isAuthGrpcMethod(req.service.typeName, req.method.name)) {
      return next(req);
    }

    return firstValueFrom(
      auth.withAccess(isUnauthenticated, (access) => {
        req.header.set('Authorization', `Bearer ${access}`);

        return next(req);
      }),
    );
  };
};
