import { Directive, type OnChanges } from '@angular/core';
import type {
  AbstractControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { noop } from 'rxjs';

@Directive()
export abstract class ValidatorDirective implements Validator, OnChanges {
  protected onValidatorChange: () => void = noop;

  private validator?: ValidatorFn;

  protected abstract createValidator(): ValidatorFn;

  public validate(control: AbstractControl): ValidationErrors | null {
    this.validator ??= this.createValidator();

    return this.validator(control);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public ngOnChanges(): void {
    this.validator = this.createValidator();
    this.onValidatorChange();
  }
}
