import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { SvgComponent } from '@pu/sdk';
import { emptyBackground, errorBackground } from '@bo/common';

@Component({
  selector: 'bo-empty-template',
  standalone: true,
  imports: [CommonModule, SvgComponent, MatButton],
  templateUrl: './empty-template.component.html',
  styleUrl: './empty-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyTemplateComponent {
  public isError = input<boolean>();
  public icon = input<string>();
  public message = input<string>();
  public prompt = input<string>();

  protected readonly errorBackground = errorBackground;
  protected readonly emptyBackground = emptyBackground;
}
