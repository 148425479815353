<div class="flex gap-1">
  <span class="label">Requests:</span>
  <span class="value">{{ total()?.totalItems }}</span>
</div>

<div class="flex gap-1">
  <span class="label">Total:</span>
  @if (totalAmount(); as totalAmount) {
    <pu-money [amount]="totalAmount.sum" [currency]="totalAmount.currency" />
  }
</div>
