// @generated by protoc-gen-es v1.10.0
// @generated from file service/bo/avatar/v1/avatar.proto (package gamification.service.bo.avatar.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AvatarLangContent, AvatarList } from "../../../../base/bo/v1/avatar_pb.js";
import { AvatarAction, AvatarRelatedEvent, AvatarStatus } from "../../../../base/bo/v1/avatar_enum_pb.js";

/**
 * request to create a new avatar
 *
 * @generated from message gamification.service.bo.avatar.v1.CreateRequest
 */
export const CreateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.CreateRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AvatarLangContent} },
    { no: 3, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_blurred", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "related_event", kind: "enum", T: proto3.getEnumType(AvatarRelatedEvent), opt: true },
  ],
);

/**
 * response for create avatar
 *
 * @generated from message gamification.service.bo.avatar.v1.CreateResponse
 */
export const CreateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.CreateResponse",
  [],
);

/**
 * request to update existed avatar
 *
 * @generated from message gamification.service.bo.avatar.v1.UpdateRequest
 */
export const UpdateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.UpdateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AvatarLangContent} },
    { no: 4, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_blurred", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "related_event", kind: "enum", T: proto3.getEnumType(AvatarRelatedEvent), opt: true },
  ],
);

/**
 * response for update avatar
 *
 * @generated from message gamification.service.bo.avatar.v1.UpdateResponse
 */
export const UpdateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.UpdateResponse",
  [],
);

/**
 * request for get avatar info
 *
 * @generated from message gamification.service.bo.avatar.v1.GetRequest
 */
export const GetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.GetRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for get avatar info
 *
 * @generated from message gamification.service.bo.avatar.v1.GetResponse
 */
export const GetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.GetResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AvatarLangContent} },
    { no: 4, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_blurred", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(AvatarStatus) },
    { no: 11, name: "related_event", kind: "enum", T: proto3.getEnumType(AvatarRelatedEvent), opt: true },
  ],
);

/**
 * request all avatars
 *
 * @generated from message gamification.service.bo.avatar.v1.GetAllRequest
 */
export const GetAllRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.GetAllRequest",
  [],
);

/**
 * response for get all avatars
 *
 * @generated from message gamification.service.bo.avatar.v1.GetAllResponse
 */
export const GetAllResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.GetAllResponse",
  () => [
    { no: 1, name: "avatars", kind: "message", T: AvatarList, repeated: true },
  ],
);

/**
 * Request to upload image for avatar.
 *
 * @generated from message gamification.service.bo.avatar.v1.UploadImageRequest
 */
export const UploadImageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.UploadImageRequest",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response with uploaded image info.
 *
 * @generated from message gamification.service.bo.avatar.v1.UploadImageResponse
 */
export const UploadImageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.UploadImageResponse",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * push action request
 *
 * @generated from message gamification.service.bo.avatar.v1.PushActionRequest
 */
export const PushActionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.PushActionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "action", kind: "enum", T: proto3.getEnumType(AvatarAction) },
  ],
);

/**
 * push action response
 *
 * @generated from message gamification.service.bo.avatar.v1.PushActionResponse
 */
export const PushActionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.avatar.v1.PushActionResponse",
  [],
);

