// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/frontend/player/v1/player.proto (package gamification.service.frontend.player.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CreatePlayerAvatarReactionRequest, CreatePlayerAvatarReactionResponse, GetAvailableAvatarsRequest, GetAvailableAvatarsResponse, GetInfoByIDRequest, GetInfoByIDResponse, GetInfoRequest, GetInfoResponse, GetLifetimeStatisticsByIDRequest, GetLifetimeStatisticsByIDResponse, GetLifetimeStatisticsRequest, GetLifetimeStatisticsResponse, GetRecentStatisticsByIDRequest, GetRecentStatisticsByIDResponse, GetRecentStatisticsRequest, GetRecentStatisticsResponse, UpdateAvatarRequest, UpdateAvatarResponse, UpdateNicknameRequest, UpdateNicknameResponse } from "./player_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * PlayerService:
 * ------------------------
 * Format: proto
 * client: frontend
 * server: gamification
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.service.frontend.player.v1.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "gamification.service.frontend.player.v1.PlayerService";

  public static readonly methods = {
    getAvailableAvatars: {
      name: "GetAvailableAvatars",
      I: GetAvailableAvatarsRequest,
      O: GetAvailableAvatarsResponse,
      kind: MethodKind.Unary,
    },
    updateAvatar: {
      name: "UpdateAvatar",
      I: UpdateAvatarRequest,
      O: UpdateAvatarResponse,
      kind: MethodKind.Unary,
    },
    updateNickname: {
      name: "UpdateNickname",
      I: UpdateNicknameRequest,
      O: UpdateNicknameResponse,
      kind: MethodKind.Unary,
    },
    getInfo: {
      name: "GetInfo",
      I: GetInfoRequest,
      O: GetInfoResponse,
      kind: MethodKind.Unary,
    },
    getLifetimeStatistics: {
      name: "GetLifetimeStatistics",
      I: GetLifetimeStatisticsRequest,
      O: GetLifetimeStatisticsResponse,
      kind: MethodKind.Unary,
    },
    getRecentStatistics: {
      name: "GetRecentStatistics",
      I: GetRecentStatisticsRequest,
      O: GetRecentStatisticsResponse,
      kind: MethodKind.Unary,
    },
    getInfoByID: {
      name: "GetInfoByID",
      I: GetInfoByIDRequest,
      O: GetInfoByIDResponse,
      kind: MethodKind.Unary,
    },
    getLifetimeStatisticsByID: {
      name: "GetLifetimeStatisticsByID",
      I: GetLifetimeStatisticsByIDRequest,
      O: GetLifetimeStatisticsByIDResponse,
      kind: MethodKind.Unary,
    },
    getRecentStatisticsByID: {
      name: "GetRecentStatisticsByID",
      I: GetRecentStatisticsByIDRequest,
      O: GetRecentStatisticsByIDResponse,
      kind: MethodKind.Unary,
    },
    createPlayerAvatarReaction: {
      name: "CreatePlayerAvatarReaction",
      I: CreatePlayerAvatarReactionRequest,
      O: CreatePlayerAvatarReactionResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * GetAvailableAvatars: get available avatars for player
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetAvailableAvatars
   */
  public getAvailableAvatars(
    input: PartialMessage<GetAvailableAvatarsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAvailableAvatarsResponse> {
    return unary<GetAvailableAvatarsRequest, GetAvailableAvatarsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getAvailableAvatars,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * UpdateAvatar: update player avatar
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.UpdateAvatar
   */
  public updateAvatar(
    input: PartialMessage<UpdateAvatarRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateAvatarResponse> {
    return unary<UpdateAvatarRequest, UpdateAvatarResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateAvatar,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * UpdateNickname: update player nickname
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.UpdateNickname
   */
  public updateNickname(
    input: PartialMessage<UpdateNicknameRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateNicknameResponse> {
    return unary<UpdateNicknameRequest, UpdateNicknameResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateNickname,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetInfo: gets player info. Since this method is used to retrieve information about the player itself and not about
   * other players, the player ID is taken from the metadata.
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetInfo
   */
  public getInfo(
    input: PartialMessage<GetInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetInfoResponse> {
    return unary<GetInfoRequest, GetInfoResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getInfo,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetLifetimeStatistics: get player's lifetime statistics. Since this method is used to retrieve information about
   * the player itself and not about other players, the player ID is taken from the metadata.
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetLifetimeStatistics
   */
  public getLifetimeStatistics(
    input: PartialMessage<GetLifetimeStatisticsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLifetimeStatisticsResponse> {
    return unary<GetLifetimeStatisticsRequest, GetLifetimeStatisticsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getLifetimeStatistics,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetRecentStatistics: get player's statistics for recent 30 days. Since this method is used to retrieve information
   * about the player itself and not about other players, the player ID is taken from the metadata.
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetRecentStatistics
   */
  public getRecentStatistics(
    input: PartialMessage<GetRecentStatisticsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRecentStatisticsResponse> {
    return unary<GetRecentStatisticsRequest, GetRecentStatisticsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getRecentStatistics,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetInfoByID: get player info by id.
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetInfoByID
   */
  public getInfoByID(
    input: PartialMessage<GetInfoByIDRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetInfoByIDResponse> {
    return unary<GetInfoByIDRequest, GetInfoByIDResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getInfoByID,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetLifetimeStatisticsByID: get player's lifetime statistics by id.
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetLifetimeStatisticsByID
   */
  public getLifetimeStatisticsByID(
    input: PartialMessage<GetLifetimeStatisticsByIDRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLifetimeStatisticsByIDResponse> {
    return unary<GetLifetimeStatisticsByIDRequest, GetLifetimeStatisticsByIDResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getLifetimeStatisticsByID,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetRecentStatisticsByID: get player's statistics for recent 30 days by id.
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.GetRecentStatisticsByID
   */
  public getRecentStatisticsByID(
    input: PartialMessage<GetRecentStatisticsByIDRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRecentStatisticsByIDResponse> {
    return unary<GetRecentStatisticsByIDRequest, GetRecentStatisticsByIDResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getRecentStatisticsByID,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * CreatePlayerAvatarReaction: create an entry in database for a user that acquired avatar by some event
   * ------------------------
   *
   * @generated from rpc gamification.service.frontend.player.v1.PlayerService.CreatePlayerAvatarReaction
   */
  public createPlayerAvatarReaction(
    input: PartialMessage<CreatePlayerAvatarReactionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreatePlayerAvatarReactionResponse> {
    return unary<CreatePlayerAvatarReactionRequest, CreatePlayerAvatarReactionResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.createPlayerAvatarReaction,
      input,
      options,
    );
  }
}
