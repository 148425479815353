import { inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { defer, map } from 'rxjs';

import type { PersistentData } from '@bo/common';

import type { FiltersFormResolver } from './filters-form-resolver.model';
import { DATA_PERSISTENCE_FOR_FILTERS_FORM_RESOLVER } from './filters-form-resolver.providers';

export class FiltersFormResolverService<T extends object>
  implements FiltersFormResolver<T>
{
  protected source$?: Observable<T>;

  private readonly persistenceSource$ = inject(
    DATA_PERSISTENCE_FOR_FILTERS_FORM_RESOLVER,
    {
      optional: true,
    },
  );

  public filters$ = defer(() => {
    if (this.source$) {
      return this.source$;
    }

    if (!this.persistenceSource$) {
      throw new Error(
        'When source$ is not provided, persistenceSource$ must be provided',
      );
    }

    if (!this.toFormData) {
      throw new Error(
        'When persistenceSource$ is provided, "toFormData" method must be implemented',
      );
    }

    // TODO: check how to avoid using "no-non-null-assertion" in this case
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.persistenceSource$.pipe(map((data) => this.toFormData!(data)));
  });

  protected toFormData?(data: PersistentData): T;
}
