import { inject, InjectionToken } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';

export const BASE_HREF = new InjectionToken<string>('BASE_HREF', {
  providedIn: 'root',
  factory: () => {
    const base = document.querySelector<HTMLBaseElement>('base[href]');

    if (base === null) {
      throw new Error('<base href="…" /> not specified');
    }

    const href = base.getAttribute('href');

    if (href === null || !href.startsWith('/') || !href.endsWith('/')) {
      throw new Error('Invalid href attribute in <base />');
    }

    return href;
  },
});

export const PATHNAME_PREFIX = new InjectionToken<string>('PATHNAME_PREFIX', {
  providedIn: 'root',
  factory: () => {
    const href = inject(BASE_HREF);
    const prefix = href.slice(0, -1);

    if (prefix === '') {
      throw new Error('Incorrect number of segments in child app');
    }

    return prefix.split('/').slice(0, -1).join('/');
  },
});

export const PATHNAME_SEARCH_HASH = new InjectionToken<string>(
  'PATHNAME_SEARCH_HASH',
  {
    providedIn: 'root',
    factory: () => {
      const { pathname, search, hash } = inject(LOCATION);
      const prefix = inject(PATHNAME_PREFIX);

      if (!pathname.startsWith(prefix)) {
        throw new Error('<base /> does not match the current location');
      }

      return `${pathname.slice(prefix.length)}${search}${hash}`;
    },
  },
);
