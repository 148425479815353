import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import type { Observable } from 'rxjs';

import {
  DialogContentComponent,
  DialogSuspenseDirective,
  InertSuspenseDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
} from '@pu/sdk';

export interface ConfirmDialogOptions {
  readonly title: string;
  readonly description: string | null;
  readonly confirmButtonText: string;
}

export interface ConfirmDialogData extends ConfirmDialogOptions {
  readonly confirmHandler: Observable<unknown>;
}

export const CONFIRM_DIALOG_DATA = new InjectionToken<ConfirmDialogData>(
  'CONFIRM_DIALOG_DATA',
);

@Component({
  selector: 'bo-confirm-dialog',
  standalone: true,
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CdkTextareaAutosize,
    DialogSuspenseDirective,
    FormsModule,
    SuspenseAreaComponent,
    InertSuspenseDirective,
    SuspenseDirective,
    MatDialogTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    DialogContentComponent,
  ],
})
export class ConfirmDialogComponent {
  protected readonly data = inject(CONFIRM_DIALOG_DATA);
}
