<div class="go-to-pagination">
  Page
  <input
    class="current-page small-text"
    (change)="apply()"
    [class]="validationStatus"
    [formControl]="currentPageControl"
    data-testid="goToPaginationInput"
    type="text"
  />
  <span class="total">of {{ totalPages }}</span>
</div>
