import { inject, Injectable } from '@angular/core';

import { License } from '@bo/common';

import type { DataLayerItem } from './data-layer/data-layer.model';
import { DATA_LAYER } from './data-layer/data-layer.tokens';
import type { GtmClient, GtmEventPayload } from './gtm-client.model';

@Injectable()
export class GtmClientService implements GtmClient {
  private readonly license = inject(License);
  public readonly dataLayer = inject(DATA_LAYER);

  public send({
    elementName: element_name,
    payload,
    ...eventData
  }: GtmEventPayload): void {
    let dataLayerItem: DataLayerItem = {
      ...eventData,
      ...payload,
      license: this.license.url,
    };

    if (element_name) {
      dataLayerItem = { ...dataLayerItem, element_name };
    }

    this.dataLayer.push(dataLayerItem);
  }
}
