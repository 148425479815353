// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/admin_refund.proto (package cabinet, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CancelRefundRequest, CancelRefundResponse, ConfirmRefundRequest, ConfirmRefundResponse } from "./admin_refund_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service cabinet.AdminRefunds
 */
@Injectable({ providedIn: "root" })
export class AdminRefundsRemoteService {
  public static readonly typeName = "cabinet.AdminRefunds";

  public static readonly methods = {
    confirmRefund: {
      name: "ConfirmRefund",
      I: ConfirmRefundRequest,
      O: ConfirmRefundResponse,
      kind: MethodKind.Unary,
    },
    cancelRefund: {
      name: "CancelRefund",
      I: CancelRefundRequest,
      O: CancelRefundResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * UA
   * pinup.product.<licence>.payments.refund.confirm
   * UpdateMOSettings - update MoneyOut settings in cabinet, after updating in admin
   *
   * @generated from rpc cabinet.AdminRefunds.ConfirmRefund
   */
  public confirmRefund(
    input: PartialMessage<ConfirmRefundRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ConfirmRefundResponse> {
    return unary<ConfirmRefundRequest, ConfirmRefundResponse>(
      this.transport,
      AdminRefundsRemoteService,
      AdminRefundsRemoteService.methods.confirmRefund,
      input,
      options,
    );
  }

  /**
   * UA
   * pinup.product.<licence>.payments.refund.cancel
   *
   * @generated from rpc cabinet.AdminRefunds.CancelRefund
   */
  public cancelRefund(
    input: PartialMessage<CancelRefundRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CancelRefundResponse> {
    return unary<CancelRefundRequest, CancelRefundResponse>(
      this.transport,
      AdminRefundsRemoteService,
      AdminRefundsRemoteService.methods.cancelRefund,
      input,
      options,
    );
  }
}
