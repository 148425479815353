import { inject, InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs';

import { AccessGroup } from './access-group';
import { ACCESS_PAYLOAD } from './access-payload';

export const ACCESS_GROUPS = new InjectionToken<
  Observable<readonly AccessGroup[]>
>('ACCESS_GROUPS', {
  providedIn: 'root',
  factory: () =>
    inject(ACCESS_PAYLOAD).pipe(
      map(({ groups }) => groups.map((group) => AccessGroup.parse(group))),
      shareReplay({ bufferSize: 1, refCount: true }),
    ),
});
