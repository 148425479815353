import type { LicenseFeature } from '../auth/license';

export interface RadioToggleOption<N extends string, T> {
  name: N;
  value: T;
}

export type RadioToggleOptionWithLicense = RadioToggleOption<string, boolean> &
  Readonly<{ licenseFeature: LicenseFeature }>;

export const booleanOptions: RadioToggleOption<string, boolean>[] = [
  { name: 'True', value: true },
  { name: 'False', value: false },
];

export const betProjectOptions: RadioToggleOptionWithLicense[] = [
  { name: 'Sport', value: true, licenseFeature: 'sport' },
  { name: 'Casino', value: false, licenseFeature: 'casino' },
];
