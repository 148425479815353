import {
  ApplicationRef,
  ENVIRONMENT_INITIALIZER,
  inject,
  InjectionToken,
  ViewContainerRef,
} from '@angular/core';

import { provideMultiValue, provideValue } from '@pu/sdk';
import { FeatureFlagsToggleMenuComponent } from '@bo/common';

import { FeatureFlagsFeatureKind } from '../models/feature-flags-features.model';
import type {
  ToggleMenuConfig,
  ToggleMenuFeature,
} from './feature-flags-toggle-menu.model';

export const TOGGLE_MENU_CONFIG = new InjectionToken<ToggleMenuConfig>(
  'TOGGLE_MENU_CONFIG',
);

const initToggleMenuComponent = (): void => {
  const applicationRef = inject(ApplicationRef);

  // Need to create component in the next tick for the root component to be available.
  // TODO: find better solution if possible
  setTimeout(() => {
    const rootComponent = applicationRef.components[0];
    const rootViewContainer = rootComponent.injector.get(ViewContainerRef);

    rootViewContainer.createComponent(FeatureFlagsToggleMenuComponent);
  });
};

export const withToggleMenu = (
  config: ToggleMenuConfig = {},
): ToggleMenuFeature => {
  const providers = [
    provideValue<ToggleMenuConfig>(TOGGLE_MENU_CONFIG, config),
    provideMultiValue(ENVIRONMENT_INITIALIZER, initToggleMenuComponent),
  ];

  return {
    kind: FeatureFlagsFeatureKind.ToggleMenuFeature,
    providers,
  };
};
