// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/players_sessions/players_sessions.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { FindPlayerSessionsRequest, FindPlayerSessionsResponse } from "./players_sessions_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.PlayersSessionsService
 */
@Injectable({ providedIn: "root" })
export class PlayersSessionsRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.PlayersSessionsService";

  public static readonly methods = {
    findPlayerSession: {
      name: "FindPlayerSession",
      I: FindPlayerSessionsRequest,
      O: FindPlayerSessionsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.PlayersSessionsService.FindPlayerSession
   */
  public findPlayerSession(
    input: PartialMessage<FindPlayerSessionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindPlayerSessionsResponse> {
    return unary<FindPlayerSessionsRequest, FindPlayerSessionsResponse>(
      this.transport,
      PlayersSessionsRemoteService,
      PlayersSessionsRemoteService.methods.findPlayerSession,
      input,
      options,
    );
  }
}
