<div class="left-bar">
  <section class="header">
    <div class="toggle" (click)="toggle()">
      <bo-svg
        [size]="24"
        [unsafeSvgSource]="collapsed() ? menuExpandIcon : menuCollapseIcon"
      />
    </div>
    @if (!collapsed()) {
      <ng-content select="[logo]" />
    }
  </section>

  <section class="navigation">
    <ng-content select="[navigation]" />
  </section>

  <section class="user">
    <ng-content select="[user]" />
  </section>
</div>

<div class="layout-content">
  <ng-content />
</div>
