import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountriesService } from './countries.service';

@NgModule({
  imports: [CommonModule],
  providers: [CountriesService],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- FIXME
export class CountriesLibModule {}
