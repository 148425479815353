// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/suspicious-deposits.proto (package apiPlayerAnalysis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { AMLTriggerRequest, AMLTriggerResponse } from "./suspicious-deposits_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service apiPlayerAnalysis.SuspiciousDeposits
 */
@Injectable({ providedIn: "root" })
export class SuspiciousDepositsRemoteService {
  public static readonly typeName = "apiPlayerAnalysis.SuspiciousDeposits";

  public static readonly methods = {
    aMLTrigger: {
      name: "AMLTrigger",
      I: AMLTriggerRequest,
      O: AMLTriggerResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc apiPlayerAnalysis.SuspiciousDeposits.AMLTrigger
   */
  public aMLTrigger(
    input: PartialMessage<AMLTriggerRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AMLTriggerResponse> {
    return unary<AMLTriggerRequest, AMLTriggerResponse>(
      this.transport,
      SuspiciousDepositsRemoteService,
      SuspiciousDepositsRemoteService.methods.aMLTrigger,
      input,
      options,
    );
  }
}
