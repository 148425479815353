import type { Provider } from '@angular/core';

import { PaginationPanelFeatureKind } from '../models/pagination-panel.model';
import type {
  PaginationPanelPersistenceOptions,
  PersistenceFeature,
} from './persistence.model';
import { providePaginationPanelRouteParamsPersistence } from './strategies/route-params';

const defaultOptions: Required<PaginationPanelPersistenceOptions> = {
  strategy: 'route-params',
};

export const withPersistence = (
  options: PaginationPanelPersistenceOptions = {},
): PersistenceFeature => {
  const { strategy } = { ...defaultOptions, ...options };

  let providers: Provider[];

  switch (strategy) {
    case 'route-params':
      providers = providePaginationPanelRouteParamsPersistence();
      break;
    case 'storage':
      providers = []; // Add providers when store persistence will be implemented
      break;
  }

  return {
    kind: PaginationPanelFeatureKind.PersistenceFeature,
    providers,
  };
};
