// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/player/player.proto (package payments.backoffice.player, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * One click is a status allows to make deposit with saved payment method
 *
 * @generated from message payments.backoffice.player.SwitchOffOneClickRequest
 */
export const SwitchOffOneClickRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.player.SwitchOffOneClickRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message payments.backoffice.player.SwitchOffOneClickResponse
 */
export const SwitchOffOneClickResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.player.SwitchOffOneClickResponse",
  [],
);

/**
 * @generated from message payments.backoffice.player.GetIsOneClickEnabledRequest
 */
export const GetIsOneClickEnabledRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.player.GetIsOneClickEnabledRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message payments.backoffice.player.GetIsOneClickEnabledResponse
 */
export const GetIsOneClickEnabledResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.player.GetIsOneClickEnabledResponse",
  () => [
    { no: 1, name: "isEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

