import { inject, InjectionToken, LOCALE_ID } from '@angular/core';

export const DISPLAY_NAMES_LANGUAGE = new InjectionToken<Intl.DisplayNames>(
  'DISPLAY_NAMES_LANGUAGE',
  {
    providedIn: 'root',
    factory: () =>
      new Intl.DisplayNames([inject(LOCALE_ID)], { type: 'language' }),
  },
);
