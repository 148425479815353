// @generated by protoc-gen-es v1.10.0
// @generated from file service/frontend/player_achievement/v1/player_achievement.proto (package gamification.achievement_manager.service.frontend.player_achievement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AchievementSorting } from "../../../../base/frontend/v1/achievement_enum_pb.js";
import { Achievement } from "../../../../base/frontend/v1/achievement_pb.js";

/**
 * request all achievements
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllRequest
 */
export const GetAllRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllRequest",
  () => [
    { no: 1, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sorting", kind: "enum", T: proto3.getEnumType(AchievementSorting) },
  ],
);

/**
 * response for get all achievements
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllResponse
 */
export const GetAllResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllResponse",
  () => [
    { no: 1, name: "achievements", kind: "message", T: Achievement, repeated: true },
  ],
);

/**
 * request for update is_viewed to true
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.MarkAsViewedRequest
 */
export const MarkAsViewedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.MarkAsViewedRequest",
  () => [
    { no: 2, name: "achievement_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for update is_viewed to true
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.MarkAsViewedResponse
 */
export const MarkAsViewedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.MarkAsViewedResponse",
  [],
);

/**
 * request for get all achievements by player id
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllByPlayerIDRequest
 */
export const GetAllByPlayerIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllByPlayerIDRequest",
  () => [
    { no: 1, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for get all achievements by player id
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllByPlayerIDResponse
 */
export const GetAllByPlayerIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllByPlayerIDResponse",
  () => [
    { no: 1, name: "achievements", kind: "message", T: GetAllByPlayerIDResponse_Achievement, repeated: true },
  ],
);

/**
 * copy Achievement for GetAllByPlayerID
 *
 * @generated from message gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllByPlayerIDResponse.Achievement
 */
export const GetAllByPlayerIDResponse_Achievement = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.frontend.player_achievement.v1.GetAllByPlayerIDResponse.Achievement",
  () => [
    { no: 1, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "recurrence_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "progress", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "GetAllByPlayerIDResponse_Achievement"},
);

