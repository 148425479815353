// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/std/query/v1/pagination.proto (package pinup.std.query.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * PaginationRequest describes what page service should return (use in Request messages).
 *
 * @generated from message pinup.std.query.v1.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.PaginationRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * PaginationResponse describes current page (use in Response messages).
 *
 * @generated from message pinup.std.query.v1.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.PaginationResponse",
  () => [
    { no: 1, name: "current_page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "total_pages", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "total_items", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * PaginationToken stores info for next page.
 *
 * @generated from message pinup.std.query.v1.PaginationToken
 */
export const PaginationToken = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.PaginationToken",
  () => [
    { no: 1, name: "last_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "has_next", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

