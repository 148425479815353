import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, model } from '@angular/core';

import { SvgComponent } from '@pu/sdk';
import { chevronDownIcon, chevronUpIcon } from '@bo/common';

@Component({
  selector: 'bo-expansion-card',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './expansion-card.component.html',
  styleUrl: './expansion-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.expanded]': 'isExpanded()' },
})
export class ExpansionCardComponent {
  public isExpanded = model<boolean>(false);

  protected readonly chevronUp = chevronUpIcon;
  protected readonly chevronDown = chevronDownIcon;

  public toggle(): void {
    this.isExpanded.update((isExpanded) => !isExpanded);
  }
}
