// @generated by protoc-gen-es v1.10.0
// @generated from file services/players_sessions/players_sessions.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "../common/common_pb.js";

/**
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.SessionType
 */
export const SessionType = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.SessionType",
  [
    {no: 0, name: "SESSION_TYPE_ALL", localName: "ALL"},
    {no: 1, name: "SESSION_TYPE_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "SESSION_TYPE_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.FindPlayerSessionsRequest
 */
export const FindPlayerSessionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.FindPlayerSessionsRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(SessionType) },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.FindPlayerSessionsResponse
 */
export const FindPlayerSessionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.FindPlayerSessionsResponse",
  () => [
    { no: 1, name: "sessions", kind: "message", T: PlayerSession, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.PlayerSession
 */
export const PlayerSession = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_sessions.PlayerSession",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "ip_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

