import type { Pagination } from './pagination-panel.model';

export const PAGINATION_MAX: Pagination = {
  currentPage: 1,
  pageSize: 100,
};

export const PAGINATION_START_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PAGINATION: Pagination = {
  currentPage: PAGINATION_START_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
};
