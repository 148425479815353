// @generated by protoc-gen-es v1.10.0
// @generated from file services/players/players.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.players.ErrorReason
 */
export const ErrorReason = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.ErrorReason",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "PLAYER_IS_BLOCKED"},
    {no: 2, name: "ONLY_TEST_PLAYER_CAN_BE_EDITED"},
    {no: 3, name: "FIELD_VIOLATION"},
    {no: 4, name: "EMAIL_IS_ALREADY_TAKEN"},
    {no: 5, name: "PHONE_IS_ALREADY_TAKEN"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.ToggleAutoWithdrawalRequest
 */
export const ToggleAutoWithdrawalRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.ToggleAutoWithdrawalRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isOn", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.ToggleAutoWithdrawalResponse
 */
export const ToggleAutoWithdrawalResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.ToggleAutoWithdrawalResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsTestRequest
 */
export const SetIsTestRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsTestRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsTestResponse
 */
export const SetIsTestResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsTestResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetCPFRequest
 */
export const SetCPFRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetCPFRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetCPFResponse
 */
export const SetCPFResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetCPFResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsVerifiedRequest
 */
export const SetIsVerifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsVerifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsVerifiedResponse
 */
export const SetIsVerifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsVerifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsCertifiedRequest
 */
export const SetIsCertifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsCertifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isCertified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsCertifiedResponse
 */
export const SetIsCertifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsCertifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetFirstNameRequest
 */
export const SetFirstNameRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetFirstNameRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED, FIELD_VIOLATION
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetFirstNameResponse
 */
export const SetFirstNameResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetFirstNameResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetLastNameRequest
 */
export const SetLastNameRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetLastNameRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED, FIELD_VIOLATION
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetLastNameResponse
 */
export const SetLastNameResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetLastNameResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetEmailRequest
 */
export const SetEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetEmailRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED, FIELD_VIOLATION, EMAIL_IS_ALREADY_TAKEN
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetEmailResponse
 */
export const SetEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetEmailResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsEmailVerifiedRequest
 */
export const SetIsEmailVerifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsEmailVerifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isEmailVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsEmailVerifiedResponse
 */
export const SetIsEmailVerifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsEmailVerifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetPhoneRequest
 */
export const SetPhoneRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetPhoneRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED, FIELD_VIOLATION, PHONE_IS_ALREADY_TAKEN
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetPhoneResponse
 */
export const SetPhoneResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetPhoneResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsPhoneVerifiedRequest
 */
export const SetIsPhoneVerifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsPhoneVerifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isPhoneVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsPhoneVerifiedResponse
 */
export const SetIsPhoneVerifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetIsPhoneVerifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetTwoFactorAuthRequest
 */
export const SetTwoFactorAuthRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetTwoFactorAuthRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "turnOnTwoFactorAuth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED, ONLY_TEST_PLAYER_CAN_BE_EDITED
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetTwoFactorAuthResponse
 */
export const SetTwoFactorAuthResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players.SetTwoFactorAuthResponse",
  [],
);

