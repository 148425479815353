<pagination-template (pageChange)="setPage($event)" #p="paginationApi">
  <div class="pagination small-text">
    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
      <a class="page-link arrow" (click)="!p.isFirstPage() && p.previous()">
        <bo-svg [size]="16" [unsafeSvgSource]="chevronLeftSvg" />
      </a>
    </div>
    <div *ngFor="let page of p.pages">
      <a
        class="page-link"
        [class.active]="p.getCurrent() === page.value"
        (click)="p.setCurrent(page.value)"
      >
        <span>{{ page.label }}</span>
      </a>
    </div>
    <div class="pagination-next" [class.disabled]="p.isLastPage()">
      <a class="page-link arrow" (click)="!p.isLastPage() && p.next()">
        <bo-svg [size]="16" [unsafeSvgSource]="chevronRightSvg" />
      </a>
    </div>
  </div>
</pagination-template>
