<form
  suspense-area
  (ngSubmit)="suspense.run(data.confirmHandler)"
  puDialogSuspense
  puInertSuspense
  puSuspense
  #suspense="puSuspense"
>
  <div mat-dialog-title>{{ data.title }}</div>
  @if (data.description) {
    <pu-dialog-content>
      {{ data.description }}
    </pu-dialog-content>
  }
  <mat-dialog-actions align="end">
    <button mat-button class="button" matDialogClose="cancel" type="button">
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      cdkFocusInitial
      color="primary"
      type="submit"
    >
      {{ data.confirmButtonText }}
    </button>
  </mat-dialog-actions>
</form>
