import type { Signal } from '@angular/core';
import { computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { isControlValueChanged } from '@pu/sdk';

import { BoFormGroup } from '../forms/forms.model';

// TODO: analyze how to unlock using unknown instead of any
export class FiltersForm<T extends Record<string, any>> extends BoFormGroup<T> {
  private readonly initialValue = this.value;
  private readonly controlsValue = toSignal(this.valueChanges, {
    initialValue: this.initialValue,
  });

  public count: Signal<number> = computed(() => {
    return Object.entries(this.controlsValue())
      .map(([controlName, value]) => {
        const control = this.controls[controlName];

        if (control instanceof FiltersForm) {
          return control.count();
        }

        const originalValue: unknown = this.initialValue[controlName];

        return isControlValueChanged(originalValue, value) ? 1 : 0;
      })
      .reduce((total, count) => total + count, 0);
  });

  public hasCounterValue = computed(() => this.count() > 0);
}

export interface RootFiltersForm<T extends {}> {
  getRootValue(): T;
}
