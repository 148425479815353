import type { ApplicationConfig } from '@angular/core';
import { provideGrpcWebTransport, withGrpcInterceptors } from '@pinup/grpc-ng';

import { SNACK_BAR_INTERCEPTOR } from '@pu/pinup';
import {
  LOGGER_INTERCEPTOR,
  MOCK_OVERRIDE_INTERCEPTOR,
  NEW_AUTH_INTERCEPTOR,
} from '@bo/common';

export const appConfigTransport: ApplicationConfig = {
  providers: [
    provideGrpcWebTransport(
      withGrpcInterceptors([
        MOCK_OVERRIDE_INTERCEPTOR,
        LOGGER_INTERCEPTOR,
        NEW_AUTH_INTERCEPTOR,
        SNACK_BAR_INTERCEPTOR,
      ]),
    ),
  ],
};
