import { isArray, isEqual, isNil, isNumber, isString } from 'lodash-es';

// The most probable use case of this function is to compare string
// input from UI (via input, textarea etc.) with initial values of types other than strings
export const isValueChangedWithStringInput = (
  stringValue: string,
  value: unknown,
): boolean => {
  if (isArray(value)) {
    return value.toString() !== stringValue;
  }

  if (isNumber(value) || typeof value === 'bigint') {
    return Number(value) !== Number(stringValue);
  }

  if (isNil(value)) {
    return Boolean(stringValue);
  }

  return !isEqual(stringValue, value);
};

export const isControlValueChanged = (
  prevValue: unknown,
  currentValue: unknown,
): boolean => {
  if (isNil(prevValue) && isNil(currentValue)) {
    return false;
  }

  if (isString(currentValue)) {
    return isValueChangedWithStringInput(currentValue.trim(), prevValue);
  }

  return !isEqual(prevValue, currentValue);
};
