// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/auto_money_out_cron_setting.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetAutoMoneyOutCronSettingRequest, GetAutoMoneyOutCronSettingResponse, UpsertAutoMoneyOutCronSettingRequest, UpsertAutoMoneyOutCronSettingResponse } from "./auto_money_out_cron_setting_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.AutoMoneyOutCronSettingService
 */
@Injectable({ providedIn: "root" })
export class AutoMoneyOutCronSettingRemoteService {
  public static readonly typeName = "payments.AutoMoneyOutCronSettingService";

  public static readonly methods = {
    getAutoMoneyOutCronSetting: {
      name: "GetAutoMoneyOutCronSetting",
      I: GetAutoMoneyOutCronSettingRequest,
      O: GetAutoMoneyOutCronSettingResponse,
      kind: MethodKind.Unary,
    },
    updateAutoMoneyOutCronSetting: {
      name: "UpdateAutoMoneyOutCronSetting",
      I: UpsertAutoMoneyOutCronSettingRequest,
      O: UpsertAutoMoneyOutCronSettingResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.AutoMoneyOutCronSettingService.GetAutoMoneyOutCronSetting
   */
  public getAutoMoneyOutCronSetting(
    input: PartialMessage<GetAutoMoneyOutCronSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAutoMoneyOutCronSettingResponse> {
    return unary<GetAutoMoneyOutCronSettingRequest, GetAutoMoneyOutCronSettingResponse>(
      this.transport,
      AutoMoneyOutCronSettingRemoteService,
      AutoMoneyOutCronSettingRemoteService.methods.getAutoMoneyOutCronSetting,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.AutoMoneyOutCronSettingService.UpdateAutoMoneyOutCronSetting
   */
  public updateAutoMoneyOutCronSetting(
    input: PartialMessage<UpsertAutoMoneyOutCronSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpsertAutoMoneyOutCronSettingResponse> {
    return unary<UpsertAutoMoneyOutCronSettingRequest, UpsertAutoMoneyOutCronSettingResponse>(
      this.transport,
      AutoMoneyOutCronSettingRemoteService,
      AutoMoneyOutCronSettingRemoteService.methods.updateAutoMoneyOutCronSetting,
      input,
      options,
    );
  }
}
