import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PlayerRemoteService } from '@pinup-grpc/services/players/players_ng';

import {
  DialogContentComponent,
  DialogSuspenseDirective,
  MaskedValueAccessor,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  ValidationErrorPipe,
} from '@pu/sdk';

export interface ChangeEmailDialogData {
  readonly profileId: bigint;
  readonly email: string;
}

export const CHANGE_EMAIL_DIALOG_DATA =
  new InjectionToken<ChangeEmailDialogData>('CHANGE_EMAIL_DIALOG_DATA');

@Component({
  selector: 'bo-change-email-dialog',
  standalone: true,
  imports: [
    SuspenseAreaComponent,
    DialogSuspenseDirective,
    SuspenseDirective,
    MatDialogTitle,
    FormsModule,
    MatFormField,
    MatInput,
    MaskedValueAccessor,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatLabel,
    MatError,
    StrictMinLengthDirective,
    ValidationErrorPipe,
    DialogContentComponent,
  ],
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['../../stylesheets/components/_popup.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailDialogComponent {
  protected readonly data = inject(CHANGE_EMAIL_DIALOG_DATA);
  protected readonly playerRemoteService = inject(PlayerRemoteService);

  protected readonly email = signal(this.data.email);
}
