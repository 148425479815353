export abstract class ValidatorError extends Error {
  public static simple(
    name: string,
    message: string,
  ): Record<string, ValidatorError> {
    return { [name]: new SimpleValidatorError(message) };
  }

  public static catch(
    name: string,
    handler: () => unknown,
  ): Record<string, ValidatorError> | null {
    try {
      handler();
      return null;
    } catch (error) {
      return ValidatorError.simple(name, String(error));
    }
  }

  public abstract toValidationErrorMessage(): string;
}

class SimpleValidatorError extends ValidatorError {
  constructor(public readonly message: string) {
    super();
  }

  public toValidationErrorMessage(): string {
    return this.message;
  }
}
