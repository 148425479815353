// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/documents/documents.proto (package kycaid.backoffice.documents, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message kycaid.backoffice.documents.GetPlayerDocumentsRequest
 */
export const GetPlayerDocumentsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.backoffice.documents.GetPlayerDocumentsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message kycaid.backoffice.documents.GetPlayerDocumentsResponse
 */
export const GetPlayerDocumentsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.backoffice.documents.GetPlayerDocumentsResponse",
  () => [
    { no: 1, name: "documents", kind: "message", T: Document, repeated: true },
  ],
);

/**
 * @generated from message kycaid.backoffice.documents.GetDocumentImageRequest
 */
export const GetDocumentImageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.backoffice.documents.GetDocumentImageRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.backoffice.documents.GetDocumentImageResponse
 */
export const GetDocumentImageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.backoffice.documents.GetDocumentImageResponse",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message kycaid.backoffice.documents.Document
 */
export const Document = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.backoffice.documents.Document",
  () => [
    { no: 1, name: "documentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "documentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "additionalNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "issueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "expiryDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "issuingAuthority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "cardNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "accountNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "incomeSources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "annualIncome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "transactionAmount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "transactionCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "transactionDatetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "transactionPurpose", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "originFunds", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "frontSideId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "frontSide", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "frontSideName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "backSideId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "backSide", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "backSideName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "otherSide1Id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "otherSide1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "otherSide1Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "otherSide2Id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "otherSide2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "otherSide2Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "createdAt", kind: "message", T: Timestamp },
    { no: 30, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "declineReasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 33, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "verificationId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

