// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/players/payment_methods.proto (package stat.bo.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Sorting } from "../../../base/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../base/pagination_pb.js";

/**
 * @generated from message stat.bo.players.PlayerPaymentMethod
 */
export const PlayerPaymentMethod = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerPaymentMethod",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "paymentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deposit", kind: "message", T: PlayerPaymentMethod_Totals },
    { no: 5, name: "withdrawal", kind: "message", T: PlayerPaymentMethod_Totals },
    { no: 6, name: "lastActivityAt", kind: "message", T: Timestamp },
    { no: 7, name: "lastActivityGeo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.bo.players.PlayerPaymentMethod.Totals
 */
export const PlayerPaymentMethod_Totals = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerPaymentMethod.Totals",
  () => [
    { no: 1, name: "countSuccessful", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "sumSuccessful", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "countFailed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sumFailed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "PlayerPaymentMethod_Totals"},
);

/**
 * @generated from message stat.bo.players.GetPlayerPaymentMethodsRequest
 */
export const GetPlayerPaymentMethodsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.GetPlayerPaymentMethodsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message stat.bo.players.GetPlayerPaymentMethodsResponse
 */
export const GetPlayerPaymentMethodsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.GetPlayerPaymentMethodsResponse",
  () => [
    { no: 1, name: "paymentMethods", kind: "message", T: PlayerPaymentMethod, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

