import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import {
  alertIcon,
  AmountFormatterComponent,
  SvgComponent,
  ToDatePipe,
} from '@pu/sdk';

import { FileLinkComponent } from '../../documents/file-link/file-link.component';
import { FilesFromDocumentPipe } from '../../documents/pipes/files-from-document.pipe';
import { EmptyTemplateComponent } from '../../empty-template/empty-template.component';
import { GeoComponent } from '../../geo/geo.component';
import { ListStateWrapperComponent } from '../../list-state-wrapper/list-state-wrapper.component';
import { PaginationPanelDirective } from '../../pagination';
import { PaginationPanelComponent } from '../../pagination/pagination-panel/pagination-panel.component';
import { PaymentTableComponent } from '../../payments/payment-table.component';

@Component({
  selector: 'bo-payment-methods-table',
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatSort,
    MatSortHeader,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    DatePipe,
    ToDatePipe,
    MatTooltip,
    AmountFormatterComponent,
    SvgComponent,
    EmptyTemplateComponent,
    MatProgressSpinner,
    GeoComponent,
    AsyncPipe,
    FileLinkComponent,
    FilesFromDocumentPipe,
    ListStateWrapperComponent,
    PaginationPanelComponent,
    PaginationPanelDirective,
  ],
  templateUrl: './payment-methods-table.component.html',
  styleUrl: './payment-methods-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsTableComponent extends PaymentTableComponent {
  protected readonly alertIcon = alertIcon;
}
