// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/player_sessions.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPlayerLastSessionRequest, GetPlayerLastSessionResponse } from "./player_sessions_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service players.PlayerSessions
 */
@Injectable({ providedIn: "root" })
export class PlayerSessionsRemoteService {
  public static readonly typeName = "players.PlayerSessions";

  public static readonly methods = {
    getPlayerLastSession: {
      name: "GetPlayerLastSession",
      I: GetPlayerLastSessionRequest,
      O: GetPlayerLastSessionResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc players.PlayerSessions.GetPlayerLastSession
   */
  public getPlayerLastSession(
    input: PartialMessage<GetPlayerLastSessionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerLastSessionResponse> {
    return unary<GetPlayerLastSessionRequest, GetPlayerLastSessionResponse>(
      this.transport,
      PlayerSessionsRemoteService,
      PlayerSessionsRemoteService.methods.getPlayerLastSession,
      input,
      options,
    );
  }
}
