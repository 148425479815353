// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/bo/prohibited_word/v1/prohibited_word.proto (package gamification.service.bo.prohibited_word.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CreateRequest, CreateResponse, DeleteRequest, DeleteResponse, UploadRequest, UploadResponse } from "./prohibited_word_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * ProhibitedWordService:
 * ------------------------
 * Format: proto
 * client: back office (BO) or other internal services
 * server: gamification
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.service.bo.prohibited_word.v1.ProhibitedWordService
 */
@Injectable({ providedIn: "root" })
export class ProhibitedWordRemoteService {
  public static readonly typeName = "gamification.service.bo.prohibited_word.v1.ProhibitedWordService";

  public static readonly methods = {
    upload: {
      name: "Upload",
      I: UploadRequest,
      O: UploadResponse,
      kind: MethodKind.Unary,
    },
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    delete: {
      name: "Delete",
      I: DeleteRequest,
      O: DeleteResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * Upload: uploads prohibited words from csv file in bytes representation.
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.prohibited_word.v1.ProhibitedWordService.Upload
   */
  public upload(
    input: PartialMessage<UploadRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UploadResponse> {
    return unary<UploadRequest, UploadResponse>(
      this.transport,
      ProhibitedWordRemoteService,
      ProhibitedWordRemoteService.methods.upload,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Create: creates a new prohibited word.
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.prohibited_word.v1.ProhibitedWordService.Create
   */
  public create(
    input: PartialMessage<CreateRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreateResponse> {
    return unary<CreateRequest, CreateResponse>(
      this.transport,
      ProhibitedWordRemoteService,
      ProhibitedWordRemoteService.methods.create,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Delete: deletes a prohibited word.
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.prohibited_word.v1.ProhibitedWordService.Delete
   */
  public delete(
    input: PartialMessage<DeleteRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteResponse> {
    return unary<DeleteRequest, DeleteResponse>(
      this.transport,
      ProhibitedWordRemoteService,
      ProhibitedWordRemoteService.methods.delete,
      input,
      options,
    );
  }
}
