import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { SvgComponent } from '@pu/sdk';

import { menuCollapseIcon, menuExpandIcon } from '../../icons';
import { SIDEBAR_COLLAPSED } from '../sidebar-collapsed';

@Component({
  selector: 'bo-main-layout',
  host: {
    '[class.collapsed]': 'collapsed()',
  },
  standalone: true,
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgComponent],
})
export class MainLayoutComponent {
  protected readonly collapsed = inject(SIDEBAR_COLLAPSED);

  protected readonly menuExpandIcon = menuExpandIcon;
  protected readonly menuCollapseIcon = menuCollapseIcon;

  public toggle(): void {
    this.collapsed.update((collapsed) => !collapsed);
  }
}
