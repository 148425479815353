// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/withdrawals/withdrawals.proto (package payments.backoffice.withdrawals, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { SumCurrency } from "../common/common_pb.js";
import { MoneyOutRiskStatus } from "../../../models/moneyout/status_pb.js";

/**
 * @generated from enum payments.backoffice.withdrawals.DeclineReason
 */
export const DeclineReason = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.withdrawals.DeclineReason",
  [
    {no: 0, name: "DECLINE_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DECLINE_REASON_TURNOVER_NEEDED", localName: "TURNOVER_NEEDED"},
    {no: 2, name: "DECLINE_REASON_VERIFICATION_NEEDED", localName: "VERIFICATION_NEEDED"},
    {no: 3, name: "DECLINE_REASON_WITHDRAWAL_METHOD_NOT_MATCHED", localName: "WITHDRAWAL_METHOD_NOT_MATCHED"},
    {no: 4, name: "DECLINE_REASON_SELFIE_NEEDED", localName: "SELFIE_NEEDED"},
    {no: 5, name: "DECLINE_REASON_FULL_VERIFICATION", localName: "FULL_VERIFICATION"},
    {no: 6, name: "DECLINE_REASON_RECREATE_WITHDRAWAL", localName: "RECREATE_WITHDRAWAL"},
    {no: 7, name: "DECLINE_REASON_FULL_PROFILE_NEEDED", localName: "FULL_PROFILE_NEEDED"},
    {no: 8, name: "DECLINE_REASON_METHOD_NOT_AVAILABLE", localName: "METHOD_NOT_AVAILABLE"},
    {no: 9, name: "DECLINE_REASON_OTHER", localName: "OTHER"},
    {no: 10, name: "DECLINE_REASON_LIVENESS_NEEDED", localName: "LIVENESS_NEEDED"},
    {no: 11, name: "DECLINE_REASON_CHECKING_BY_PROVIDER", localName: "CHECKING_BY_PROVIDER"},
  ],
);

/**
 * @generated from enum payments.backoffice.withdrawals.WithdrawalConfirmType
 */
export const WithdrawalConfirmType = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.withdrawals.WithdrawalConfirmType",
  [
    {no: 0, name: "WITHDRAWAL_CONFIRM_TYPE_AUTO", localName: "AUTO"},
    {no: 1, name: "WITHDRAWAL_CONFIRM_TYPE_MANUAL", localName: "MANUAL"},
    {no: 2, name: "WITHDRAWAL_CONFIRM_TYPE_MANUAL_CHANGED_AMOUNT", localName: "MANUAL_CHANGED_AMOUNT"},
  ],
);

/**
 * @generated from enum payments.backoffice.withdrawals.WithdrawalStatus
 */
export const WithdrawalStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.withdrawals.WithdrawalStatus",
  [
    {no: 0, name: "WITHDRAWAL_STATUS_PROCESSING", localName: "PROCESSING"},
    {no: 1, name: "WITHDRAWAL_STATUS_SUCCESSFUL", localName: "SUCCESSFUL"},
    {no: 2, name: "WITHDRAWAL_STATUS_CANCELED", localName: "CANCELED"},
    {no: 3, name: "WITHDRAWAL_STATUS_CANCELED_USER", localName: "CANCELED_USER"},
    {no: 4, name: "WITHDRAWAL_STATUS_AV_PROCESSING", localName: "AV_PROCESSING"},
    {no: 5, name: "WITHDRAWAL_STATUS_RISK_REVIEWING", localName: "RISK_REVIEWING"},
  ],
);

/**
 * @generated from enum payments.backoffice.withdrawals.WithdrawalPaymentStatus
 */
export const WithdrawalPaymentStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.withdrawals.WithdrawalPaymentStatus",
  [
    {no: 0, name: "WITHDRAWAL_PAYMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_PAYMENT_STATUS_SUCCESSFUL", localName: "SUCCESSFUL"},
    {no: 2, name: "WITHDRAWAL_PAYMENT_STATUS_FAILED", localName: "FAILED"},
    {no: 3, name: "WITHDRAWAL_PAYMENT_STATUS_PROCESSING", localName: "PROCESSING"},
  ],
);

/**
 * @generated from enum payments.backoffice.withdrawals.PlayerVerifyStatus
 */
export const PlayerVerifyStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.withdrawals.PlayerVerifyStatus",
  [
    {no: 0, name: "PLAYER_VERIFY_STATUS_NO", localName: "NO"},
    {no: 1, name: "PLAYER_VERIFY_STATUS_YES", localName: "YES"},
    {no: 2, name: "PLAYER_VERIFY_STATUS_IN_PROGRESS", localName: "IN_PROGRESS"},
    {no: 3, name: "PLAYER_VERIFY_STATUS_NO_FINAL", localName: "NO_FINAL"},
    {no: 4, name: "PLAYER_VERIFY_STATUS_NO_RETRY", localName: "NO_RETRY"},
  ],
);

/**
 * @generated from enum payments.backoffice.withdrawals.WithdrawalError
 */
export const WithdrawalError = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.withdrawals.WithdrawalError",
  [
    {no: 0, name: "WE_UNSPECIFIED"},
    {no: 1, name: "WE_WITHDRAWAL_NOT_FOUND"},
    {no: 2, name: "WE_WITHDRAWAL_HAS_BEEN_PROCESSED"},
    {no: 3, name: "WE_WITHDRAWAL_IS_BEING_PROCESSED"},
    {no: 4, name: "WE_PLAYER_BLOCKED"},
    {no: 5, name: "WE_BALANCE_NOT_ENOUGH"},
    {no: 6, name: "WE_OPERATION_LIMIT_EXCEEDED"},
    {no: 7, name: "WE_OPERATION_LIMIT_UNSPECIFIED"},
  ],
);

/**
 * @generated from message payments.backoffice.withdrawals.GetWithdrawalByIdRequest
 */
export const GetWithdrawalByIdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.GetWithdrawalByIdRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message payments.backoffice.withdrawals.GetWithdrawalByIdResponse
 */
export const GetWithdrawalByIdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.GetWithdrawalByIdResponse",
  () => [
    { no: 1, name: "withdrawal", kind: "message", T: Withdrawal },
  ],
);

/**
 * @generated from message payments.backoffice.withdrawals.AcceptRequest
 */
export const AcceptRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.AcceptRequest",
  () => [
    { no: 1, name: "withdrawalId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "commission", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Error reasons: WE_WITHDRAWAL_NOT_FOUND, WE_WITHDRAWAL_IS_BEING_PROCESSED, WE_WITHDRAWAL_HAS_BEEN_PROCESSED,
 * WE_PLAYER_BLOCKED, WE_BALANCE_NOT_ENOUGH, WE_OPERATION_LIMIT_UNSPECIFIED, WE_OPERATION_LIMIT_EXCEEDED
 *
 * @generated from message payments.backoffice.withdrawals.AcceptResponse
 */
export const AcceptResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.AcceptResponse",
  [],
);

/**
 * @generated from message payments.backoffice.withdrawals.DeclineRequest
 */
export const DeclineRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.DeclineRequest",
  () => [
    { no: 1, name: "withdrawalId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "reason", kind: "enum", T: proto3.getEnumType(DeclineReason) },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Error reasons: WE_WITHDRAWAL_NOT_FOUND, WE_WITHDRAWAL_IS_BEING_PROCESSED, WE_WITHDRAWAL_HAS_BEEN_PROCESSED
 *
 * @generated from message payments.backoffice.withdrawals.DeclineResponse
 */
export const DeclineResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.DeclineResponse",
  [],
);

/**
 * @generated from message payments.backoffice.withdrawals.SendToAntiFraudRequest
 */
export const SendToAntiFraudRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.SendToAntiFraudRequest",
  () => [
    { no: 1, name: "withdrawalId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Error reasons: WE_WITHDRAWAL_NOT_FOUND, WE_WITHDRAWAL_IS_BEING_PROCESSED
 *
 * @generated from message payments.backoffice.withdrawals.SendToAntiFraudResponse
 */
export const SendToAntiFraudResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.SendToAntiFraudResponse",
  [],
);

/**
 * @generated from message payments.backoffice.withdrawals.AcceptByAntiFraudRequest
 */
export const AcceptByAntiFraudRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.AcceptByAntiFraudRequest",
  () => [
    { no: 1, name: "withdrawalId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Error reasons: WE_WITHDRAWAL_NOT_FOUND, WE_WITHDRAWAL_IS_BEING_PROCESSED
 *
 * @generated from message payments.backoffice.withdrawals.AcceptByAntiFraudResponse
 */
export const AcceptByAntiFraudResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.AcceptByAntiFraudResponse",
  [],
);

/**
 * @generated from message payments.backoffice.withdrawals.ChangePaymentStatusRequest
 */
export const ChangePaymentStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.ChangePaymentStatusRequest",
  () => [
    { no: 1, name: "withdrawalId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "paymentStatus", kind: "enum", T: proto3.getEnumType(WithdrawalPaymentStatus) },
  ],
);

/**
 * Error reasons: WE_WITHDRAWAL_NOT_FOUND, WE_WITHDRAWAL_IS_BEING_PROCESSED, WE_WITHDRAWAL_HAS_BEEN_PROCESSED
 *
 * @generated from message payments.backoffice.withdrawals.ChangePaymentStatusResponse
 */
export const ChangePaymentStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.ChangePaymentStatusResponse",
  [],
);

/**
 * @generated from message payments.backoffice.withdrawals.Withdrawal
 */
export const Withdrawal = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.withdrawals.Withdrawal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "playerVerifyStatus", kind: "enum", T: proto3.getEnumType(PlayerVerifyStatus) },
    { no: 4, name: "isPlayerVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "amount", kind: "message", T: SumCurrency },
    { no: 6, name: "balance", kind: "message", T: SumCurrency },
    { no: 7, name: "balanceAfter", kind: "message", T: SumCurrency },
    { no: 8, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "paymentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "paymentMethodId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "paymentSystemId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "paymentAggregatorId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(WithdrawalStatus) },
    { no: 14, name: "paymentStatus", kind: "enum", T: proto3.getEnumType(WithdrawalPaymentStatus) },
    { no: 15, name: "confirmType", kind: "enum", T: proto3.getEnumType(WithdrawalConfirmType) },
    { no: 16, name: "createdAt", kind: "message", T: Timestamp },
    { no: 17, name: "updatedAt", kind: "message", T: Timestamp },
    { no: 18, name: "userId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 19, name: "userCommentId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 20, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "riskAllow", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "riskStatus", kind: "enum", T: proto3.getEnumType(MoneyOutRiskStatus) },
    { no: 23, name: "riskCreatedAt", kind: "message", T: Timestamp },
    { no: 24, name: "riskCompletedAt", kind: "message", T: Timestamp },
    { no: 25, name: "consideredAt", kind: "message", T: Timestamp },
    { no: 26, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "cardDifferFromDeposit", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "commission", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 29, name: "riskInitiatorId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 30, name: "riskReviewerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

