import { inject, Injectable } from '@angular/core';
import { PaymentRemoteService } from '@pinup-grpc/service/backoffice/payments/payments_ng';
import type { Observable } from 'rxjs';

import type {
  FindPaymentAggregatorsResponse,
  FindPaymentMethodsResponse,
} from '@pu/grpc';

@Injectable({ providedIn: 'root' })
export class PaymentsProvider {
  private readonly paymentRemoteService = inject(PaymentRemoteService);

  public findPaymentMethods(): Observable<FindPaymentMethodsResponse> {
    return this.paymentRemoteService.findPaymentMethods({});
  }

  public findPaymentAggregators(): Observable<FindPaymentAggregatorsResponse> {
    return this.paymentRemoteService.findPaymentAggregators({});
  }
}
