// @generated by protoc-gen-es v1.10.0
// @generated from file services/playersEmail.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message players.DeleteEmailRequest
 */
export const DeleteEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.DeleteEmailRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "parentID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "isEmailAuth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message players.DeleteEmailResponse
 */
export const DeleteEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.DeleteEmailResponse",
  [],
);

