import { FormControl } from '@angular/forms';

export class NonNullableFormControl<T> extends FormControl {
  // Needed for correct type checking. Passing generic to FormControl is impossible
  // due to is due to TS's inability to have constructor overloads with different types
  // See https://github.com/microsoft/TypeScript/issues/35387 for more details
  public override readonly value: T;

  constructor(value: T) {
    super(value, { nonNullable: true });
  }
}
