import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, inject, input, TemplateRef } from '@angular/core';

import type { TransferredContentId } from './transfer-content.model';
import { TransferContentService } from './transfer-content.service';

@Directive({
  selector: '[puTransferContent]',
  standalone: true,
})
export class TransferContentDirective implements OnInit, OnDestroy {
  private readonly template = inject(TemplateRef);
  private readonly service = inject(TransferContentService);

  public contentId = input.required<TransferredContentId>({
    alias: 'puTransferContent',
  });

  public ngOnInit(): void {
    this.service.transferContent(this.contentId(), this.template);
  }

  public ngOnDestroy(): void {
    this.service.transferContent(this.contentId(), null);
  }
}
