import type { ValidatorFn } from '@angular/forms';
import type { ValidatePhoneNumberLengthResult } from 'libphonenumber-js';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { isString } from 'lodash-es';

import { ValidatorError } from './validator.error';

export class StrictPhoneValidatorError extends ValidatorError {
  constructor(private readonly result: ValidatePhoneNumberLengthResult) {
    super();
  }

  public toValidationErrorMessage(): string {
    switch (this.result) {
      case 'INVALID_COUNTRY':
        return 'Invalid country';
      case 'INVALID_LENGTH':
        return 'Invalid length';
      case 'NOT_A_NUMBER':
        return 'Not a phone number';
      case 'TOO_LONG':
        return 'Phone number is too long';
      case 'TOO_SHORT':
        return 'Phone number is too short';
    }
  }
}

export function strictPhoneValidator(): ValidatorFn {
  return ({ value }) => {
    if (!isString(value) || value.length === 0) {
      return null;
    }

    const result = validatePhoneNumberLength(value);
    return result !== undefined
      ? { strictPhone: new StrictPhoneValidatorError(result) }
      : null;
  };
}
