import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  LOCALE_ID,
  output,
} from '@angular/core';

import Configuration = google.accounts.id.GsiButtonConfiguration;
import { GOOGLE_SIGN_IN_BUTTON_DEFAULT } from './google-sign-in-button-default';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'google-sign-in-button',
  standalone: true,
  template: '',
  styleUrl: './google-sign-in-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleSignInButtonComponent {
  private readonly nativeElement =
    inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;

  public readonly type = input.required<Configuration['type']>();

  public readonly theme = input<Configuration['theme']>();

  public readonly size = input<Configuration['size']>();

  public readonly text = input<Configuration['text']>();

  public readonly shape = input<Configuration['shape']>();

  public readonly logoAlignment = input<Configuration['logo_alignment']>();

  public readonly width = input<Configuration['width']>();

  public readonly locale = input<Configuration['locale']>();

  public readonly state = input<Configuration['state']>();

  public readonly clickListener = output();

  constructor() {
    const locale = inject(LOCALE_ID);
    const config = inject(GOOGLE_SIGN_IN_BUTTON_DEFAULT);

    effect(() => {
      google.accounts.id.renderButton(this.nativeElement, {
        type: this.type(),
        theme: this.theme() ?? config.theme,
        size: this.size() ?? config.size,
        text: this.text() ?? config.text,
        logo_alignment: this.logoAlignment() ?? config.logo_alignment,
        width: this.width() ?? config.width,
        locale: this.locale() ?? config.locale ?? locale,
        shape: this.shape() ?? config.shape,
        state: this.state() ?? config.state,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        click_listener: () => {
          this.clickListener.emit();
        },
      });
    });
  }
}
