import { inject, InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs';

import { AuthService } from './auth.service';
import type { PinupAccessJwtPayload } from './parse-pinup-jwt';
import { parsePinupAccessJwt } from './parse-pinup-jwt';

export const ACCESS_PAYLOAD = new InjectionToken<
  Observable<PinupAccessJwtPayload>
>('ACCESS_PAYLOAD', {
  providedIn: 'root',
  factory: () =>
    inject(AuthService).session$.pipe(
      map(({ access }) => parsePinupAccessJwt(access)),
      shareReplay({ bufferSize: 1, refCount: true }),
    ),
});
