import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs';

import type { SmartDialogOptions } from '@pu/sdk';
import { provideValue, SmartDialog } from '@pu/sdk';

import {
  AUTO_WITHDRAWAL_ON_DIALOG_DATA,
  AutoWithdrawalOnDialogComponent,
} from './auto-withdrawal-on-dialog.component';

@Injectable({ providedIn: 'root' })
export class AutoWithdrawalDialogService {
  private readonly dialog = inject(SmartDialog);

  public toggleOn(
    playerId: bigint,
    { providers = [] }: SmartDialogOptions = {},
  ): Observable<never> {
    return this.dialog
      .open(AutoWithdrawalOnDialogComponent, [
        ...providers,
        provideValue(AUTO_WITHDRAWAL_ON_DIALOG_DATA, { playerId }),
      ])
      .pipe(ignoreElements());
  }
}
