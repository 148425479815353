// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/players/players.proto (package pinup.stat.report.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * CasinoStatus is a player's casino status
 *
 * @generated from enum pinup.stat.report.players.CasinoStatus
 */
export const CasinoStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.players.CasinoStatus",
  [
    {no: 0, name: "CASINO_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CASINO_STATUS_C1", localName: "C1"},
    {no: 2, name: "CASINO_STATUS_C2", localName: "C2"},
    {no: 3, name: "CASINO_STATUS_C3", localName: "C3"},
    {no: 4, name: "CASINO_STATUS_C4", localName: "C4"},
    {no: 5, name: "CASINO_STATUS_CA", localName: "CA"},
    {no: 6, name: "CASINO_STATUS_CN", localName: "CN"},
    {no: 7, name: "CASINO_STATUS_CVIP", localName: "CVIP"},
    {no: 8, name: "CASINO_STATUS_CZ", localName: "CZ"},
  ],
);

/**
 * BettingStatus is a player's betting status
 *
 * @generated from enum pinup.stat.report.players.BettingStatus
 */
export const BettingStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.players.BettingStatus",
  [
    {no: 0, name: "BETTING_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BETTING_STATUS_B1", localName: "B1"},
    {no: 2, name: "BETTING_STATUS_B2", localName: "B2"},
    {no: 3, name: "BETTING_STATUS_B3", localName: "B3"},
    {no: 4, name: "BETTING_STATUS_B4", localName: "B4"},
    {no: 5, name: "BETTING_STATUS_B5", localName: "B5"},
    {no: 6, name: "BETTING_STATUS_BA", localName: "BA"},
    {no: 7, name: "BETTING_STATUS_BN", localName: "BN"},
    {no: 8, name: "BETTING_STATUS_BVIP", localName: "BVIP"},
    {no: 9, name: "BETTING_STATUS_BZ", localName: "BZ"},
  ],
);

/**
 * @generated from enum pinup.stat.report.players.VerifyStatus
 */
export const VerifyStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.players.VerifyStatus",
  [
    {no: 0, name: "VERIFY_STATUS_NO", localName: "NO"},
    {no: 1, name: "VERIFY_STATUS_YES", localName: "YES"},
    {no: 2, name: "VERIFY_STATUS_IN_PROGRESS", localName: "IN_PROGRESS"},
    {no: 3, name: "VERIFY_STATUS_NO_FINAL", localName: "NO_FINAL"},
    {no: 4, name: "VERIFY_STATUS_NO_RETRY", localName: "NO_RETRY"},
  ],
);

/**
 * @generated from enum pinup.stat.report.players.DigitainStatus
 */
export const DigitainStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.players.DigitainStatus",
  [
    {no: 0, name: "DIGITAIN_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DIGITAIN_STATUS_COMMON", localName: "COMMON"},
    {no: 3, name: "DIGITAIN_STATUS_FEMALE", localName: "FEMALE"},
    {no: 4, name: "DIGITAIN_STATUS_VIP_USER", localName: "VIP_USER"},
    {no: 2, name: "DIGITAIN_STATUS_NEW_USER", localName: "NEW_USER"},
    {no: 5, name: "DIGITAIN_STATUS_TERMINAL", localName: "TERMINAL"},
    {no: 6, name: "DIGITAIN_STATUS_ARBITRAGE", localName: "ARBITRAGE"},
    {no: 7, name: "DIGITAIN_STATUS_PROBLEM", localName: "PROBLEM"},
    {no: 8, name: "DIGITAIN_STATUS_SPECIAL", localName: "SPECIAL"},
    {no: 9, name: "DIGITAIN_STATUS_MARKER", localName: "MARKER"},
    {no: 10, name: "DIGITAIN_STATUS_TRADING_ANALYZING", localName: "TRADING_ANALYZING"},
    {no: 11, name: "DIGITAIN_STATUS_LATE_BETTING", localName: "LATE_BETTING"},
    {no: 12, name: "DIGITAIN_STATUS_MIDDLER", localName: "MIDDLER"},
    {no: 13, name: "DIGITAIN_STATUS_BET_SHOP", localName: "BET_SHOP"},
    {no: 14, name: "DIGITAIN_STATUS_PROFESSIONAL", localName: "PROFESSIONAL"},
    {no: 15, name: "DIGITAIN_STATUS_HIGH_VALUE", localName: "HIGH_VALUE"},
    {no: 16, name: "DIGITAIN_STATUS_VIP_SPECIAL", localName: "VIP_SPECIAL"},
    {no: 17, name: "DIGITAIN_STATUS_BONUS_HUNTER", localName: "BONUS_HUNTER"},
    {no: 18, name: "DIGITAIN_STATUS_CAPPER", localName: "CAPPER"},
  ],
);

