// @generated by protoc-gen-es v1.10.0
// @generated from file services/public/players.proto (package players.v1.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message players.v1.public.GetAuthHashToOriginalPlayerResponse
 */
export const GetAuthHashToOriginalPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.v1.public.GetAuthHashToOriginalPlayerResponse",
  () => [
    { no: 1, name: "originalPlayerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

