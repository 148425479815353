<div class="page-size-select flex align-items-center">
  <span class="label">Show:</span>
  <ng-select
    class="auto-grow no-border no-color pagination-select"
    (ngModelChange)="pageSizeChange.emit($event)"
    [clearable]="false"
    [ngModel]="pageSize"
    [searchable]="false"
    data-testid="pageSelectControl"
    dropdownPosition="top"
  >
    @for (item of pageSelectItems; track item.value) {
      <ng-option [value]="item.value">
        <span class="small-text">{{ item.label }}</span>
      </ng-option>
    }
  </ng-select>
</div>
