// @generated by protoc-gen-es v1.10.0
// @generated from file services/billing.proto (package billing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { BalanceV2 } from "../proto/balance_pb.js";
import { Project, Type } from "../proto/enum_pb.js";
import { Transaction } from "../proto/transaction_pb.js";

/**
 * @generated from message billing.GetBalanceRequest
 */
export const GetBalanceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "billing.GetBalanceRequest",
  () => [
    { no: 1, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message billing.GetBalanceResponse
 */
export const GetBalanceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "billing.GetBalanceResponse",
  () => [
    { no: 1, name: "balance", kind: "message", T: BalanceV2 },
  ],
);

/**
 * @generated from message billing.MakeTransactionChoiceRequest
 */
export const MakeTransactionChoiceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "billing.MakeTransactionChoiceRequest",
  () => [
    { no: 1, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Type) },
    { no: 3, name: "change", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "pincoinChange", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "currencyId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "transactionId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "transactionType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "transactionIdBson", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "bet", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message billing.MakeTransactionChoiceResponse
 */
export const MakeTransactionChoiceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "billing.MakeTransactionChoiceResponse",
  () => [
    { no: 1, name: "tx", kind: "message", T: Transaction },
  ],
);

/**
 * @generated from message billing.RevertTransactionRequest
 */
export const RevertTransactionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "billing.RevertTransactionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message billing.RevertTransactionResponse
 */
export const RevertTransactionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "billing.RevertTransactionResponse",
  () => [
    { no: 1, name: "tx", kind: "message", T: Transaction },
  ],
);

