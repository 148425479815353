// @generated by protoc-gen-es v1.10.0
// @generated from file storage/session.proto (package frontend.storage, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message frontend.storage.ProductSession
 */
export const ProductSession = /*@__PURE__*/ proto3.makeMessageType(
  "frontend.storage.ProductSession",
  () => [
    { no: 1, name: "refresh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

