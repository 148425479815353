import type { InjectionToken } from '@angular/core';
import { inject, Injector, NgZone, runInInjectionContext } from '@angular/core';

// TODO optimize
export function bindCallbacks<P extends unknown[]>(
  token: InjectionToken<readonly ((...params: P) => void)[]>,
  directCallback: ((...params: P) => void) | undefined,
  injector: Injector = inject(Injector),
): ((...params: P) => void) | undefined {
  const diCallbacks = inject(token, { optional: true });
  const callbacks: ((...params: P) => void)[] = [];

  if (directCallback !== undefined) {
    callbacks.push(directCallback);
  }

  if (diCallbacks !== null) {
    for (const callback of diCallbacks) {
      callbacks.push(callback);
    }
  }

  if (callbacks.length === 0) {
    return;
  }

  return (...params) => {
    runInInjectionContext(injector, () => {
      const zone = injector.get(NgZone);
      zone.run(() => {
        for (const callback of callbacks) {
          callback(...params);
        }
      });
    });
  };
}
