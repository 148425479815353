import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';

import { AccessKeyDirective } from './access-key.directive';

@Component({
  selector: 'bo-access-key',
  standalone: true,
  templateUrl: './access-key.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessKeyComponent {
  private readonly parent = inject(AccessKeyDirective);

  public readonly text = input.required<string>();

  protected index = computed(() =>
    this.text().toLowerCase().indexOf(this.parent.accessKey().toLowerCase()),
  );

  protected prefix = computed(() => this.text().slice(0, this.index()));
  protected underline = computed(() =>
    this.text().slice(
      this.index(),
      this.index() + this.parent.accessKey().length,
    ),
  );
  protected postfix = computed(() =>
    this.text().slice(this.index() + this.parent.accessKey().length),
  );
}
