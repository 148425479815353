import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { isNil } from 'lodash-es';

import {
  compareHighlighted,
  haveHighlightedParts,
} from '../compare-highlighted';
import type { AutocompleteEqual } from './autocomplete-equal.type';
import type {
  AutocompleteContext,
  HighlightedAutocompleteContext,
} from './match';

export interface MatchResult {
  readonly text: string;
  readonly index: number;
}

// TODO Need to rename the file name or pipe name
@Pipe({
  name: 'autocompleteWithMultipleHighlights',
  standalone: true,
})
export class AutocompletePipeWithMultipleHighlights implements PipeTransform {
  public transform<T, V>(
    list: readonly AutocompleteContext<T, V>[] | null | undefined,
    highlight: AutocompleteEqual<T>,
    search: string,
  ): readonly HighlightedAutocompleteContext<T, V>[] {
    if (isNil(list)) {
      return [];
    }

    const result: HighlightedAutocompleteContext<T, V>[] = [];

    for (const { $implicit, value } of list) {
      const highlighted = highlight($implicit, search);

      if (!haveHighlightedParts(highlighted)) {
        continue;
      }

      result.push({ $implicit, value, highlighted });
    }

    if (search) {
      result.sort((a, b) => compareHighlighted(a.highlighted, b.highlighted));
    }

    return result;
  }
}
