// @generated by protoc-gen-es v1.10.0
// @generated from file services/backoffice/sessions.proto (package git.time2go.tech.apis.pinup.players.services.backoffice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum git.time2go.tech.apis.pinup.players.services.backoffice.SessionType
 */
export const SessionType = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SessionType",
  [
    {no: 0, name: "SESSION_TYPE_ALL", localName: "ALL"},
    {no: 1, name: "SESSION_TYPE_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "SESSION_TYPE_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.DeletePlayerSessionsRequest
 */
export const DeletePlayerSessionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.DeletePlayerSessionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.DeletePlayerSessionsResponse
 */
export const DeletePlayerSessionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.DeletePlayerSessionsResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.FindPlayerSessionsRequest
 */
export const FindPlayerSessionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.FindPlayerSessionsRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(SessionType) },
    { no: 3, name: "limit", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.FindPlayerSessionsResponse
 */
export const FindPlayerSessionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.FindPlayerSessionsResponse",
  () => [
    { no: 1, name: "sessions", kind: "message", T: PlayerSession, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.PlayerSession
 */
export const PlayerSession = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.PlayerSession",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "ip_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

