import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearsCount',
  standalone: true,
})
export class YearsCountPipe implements PipeTransform {
  public transform(value: Date): number {
    return new Date().getFullYear() - value.getFullYear();
  }
}
