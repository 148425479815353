// @generated by protoc-gen-es v1.10.0
// @generated from file settings_manager/v1/user_settings/user.proto (package settings_manager.v1.user_settings, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Setting } from "../models/settings_pb.js";

/**
 * @generated from message settings_manager.v1.user_settings.ListSettingsRequest
 */
export const ListSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.user_settings.ListSettingsRequest",
  () => [
    { no: 1, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.user_settings.ListSettingsResponse
 */
export const ListSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.user_settings.ListSettingsResponse",
  () => [
    { no: 1, name: "settings", kind: "message", T: Setting, repeated: true },
  ],
);

/**
 * @generated from message settings_manager.v1.user_settings.GetSettingRequest
 */
export const GetSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.user_settings.GetSettingRequest",
  () => [
    { no: 1, name: "groupName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settingID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message settings_manager.v1.user_settings.GetSettingResponse
 */
export const GetSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.user_settings.GetSettingResponse",
  () => [
    { no: 1, name: "setting", kind: "message", T: Setting },
  ],
);

