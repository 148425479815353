import { inject } from '@angular/core';
import type { Transport } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';

import { GRPC_INTERCEPTORS } from './grpc-interceptors';
import { GRPC_WEB_TRANSPORT_BASE_URL } from './grpc-web-transport-base-url';
import { GRPC_WEB_TRANSPORT_CONFIG } from './grpc-web-transport-config';

export function grpcWebTransportFactory(): Transport {
  return createGrpcWebTransport({
    ...inject(GRPC_WEB_TRANSPORT_CONFIG, { optional: true }),
    baseUrl: inject(GRPC_WEB_TRANSPORT_BASE_URL),
    interceptors: inject(GRPC_INTERCEPTORS, { optional: true }) ?? [],
  });
}
