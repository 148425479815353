import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { MoneyComponent, SvgComponent } from '@pu/sdk';

import { arrowRightIcon } from '../icons';

@Component({
  selector: 'pu-money-change',
  standalone: true,
  imports: [MoneyComponent, SvgComponent],
  templateUrl: './money-change.component.html',
  styleUrl: './money-change.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyChangeComponent {
  public amount = input.required<bigint | number>();
  public amountAfter = input.required<bigint | number>();
  public currency = input<string>();
  public icon = input<string>(arrowRightIcon);
  public amountAfterClass = input();
}
