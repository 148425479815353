import { PeriodTypeEnum } from './period-type.enum';

export const PERIOD_OPTIONS: PeriodTypeEnum[] = [
  PeriodTypeEnum.Today,
  PeriodTypeEnum.ThisWeek,
  PeriodTypeEnum.ThisMonth,
  PeriodTypeEnum.ThisYear,
  PeriodTypeEnum.Date,
  PeriodTypeEnum.Between,
];
