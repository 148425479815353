// @generated by protoc-gen-es v1.10.0
// @generated from file base/bo/v1/dictionary.proto (package gamification.base.bo.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * The Language message defines a mapping between a language identifier and its human-friendly representation.
 *
 * @generated from message gamification.base.bo.v1.Language
 */
export const Language = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.base.bo.v1.Language",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

