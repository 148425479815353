// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/player_stat.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetDepositsInfoRequest, GetDepositsInfoResponse, GetLiteStatRequest, GetLiteStatResponse, GetMoneyOutRequest, GetMoneyOutResponse, GetSummaryRequest, GetSummaryResponse, GetTaxEventsRequest, GetTaxEventsResponse, ListEventsRequest, ListEventsResponse, ListPlayersIdsRequest, ListPlayersIdsResponse } from "./player_stat_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * NEW player statistic with details
 *
 * @generated from service stat.PlayerStatisticEvents
 */
@Injectable({ providedIn: "root" })
export class PlayerStatisticEventsRemoteService {
  public static readonly typeName = "stat.PlayerStatisticEvents";

  public static readonly methods = {
    listEvents: {
      name: "ListEvents",
      I: ListEventsRequest,
      O: ListEventsResponse,
      kind: MethodKind.Unary,
    },
    listPlayersIds: {
      name: "ListPlayersIds",
      I: ListPlayersIdsRequest,
      O: ListPlayersIdsResponse,
      kind: MethodKind.Unary,
    },
    getSummary: {
      name: "GetSummary",
      I: GetSummaryRequest,
      O: GetSummaryResponse,
      kind: MethodKind.Unary,
    },
    getLiteStat: {
      name: "GetLiteStat",
      I: GetLiteStatRequest,
      O: GetLiteStatResponse,
      kind: MethodKind.Unary,
    },
    getMoneyOut: {
      name: "GetMoneyOut",
      I: GetMoneyOutRequest,
      O: GetMoneyOutResponse,
      kind: MethodKind.Unary,
    },
    getDepositsInfo: {
      name: "GetDepositsInfo",
      I: GetDepositsInfoRequest,
      O: GetDepositsInfoResponse,
      kind: MethodKind.Unary,
    },
    getTaxEvents: {
      name: "GetTaxEvents",
      I: GetTaxEventsRequest,
      O: GetTaxEventsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * -----------------------------------------
   * Get Player statistic events by filter
   * -----------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.event.list
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatisticEvents.ListEvents
   * @deprecated
   */
  public listEvents(
    input: PartialMessage<ListEventsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListEventsResponse> {
    return unary<ListEventsRequest, ListEventsResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.listEvents,
      input,
      options,
    );
  }

  /**
   * -------------------------------------------------------
   * 1.Get Player count by filter
   * 2.List Player's ids by filter, ids sorted by playerId
   * -------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.event.list_players_ids
   * Restriction:
   *   NATS: max 50_000 player ids at one time
   * ------------------------------------------------------------------------------------------------------------------
   * Deprecated - moved to PlayerInfo service
   *
   * @generated from rpc stat.PlayerStatisticEvents.ListPlayersIds
   * @deprecated
   */
  public listPlayersIds(
    input: PartialMessage<ListPlayersIdsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListPlayersIdsResponse> {
    return unary<ListPlayersIdsRequest, ListPlayersIdsResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.listPlayersIds,
      input,
      options,
    );
  }

  /**
   * -----------------------------------------------
   * Returns one Player's all events summary by period
   * -----------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.event.get_summary
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatisticEvents.GetSummary
   */
  public getSummary(
    input: PartialMessage<GetSummaryRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetSummaryResponse> {
    return unary<GetSummaryRequest, GetSummaryResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.getSummary,
      input,
      options,
    );
  }

  /**
   * -----------------------------------------------
   * Returns Player(s) lite statistic about:
   *   - deposits
   *   - moneyOuts
   *   - bets
   *   - wins
   * -----------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.event.get_lite_stat
   * Restriction:
   *   NATS: max 1_000 player ids at one time
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatisticEvents.GetLiteStat
   */
  public getLiteStat(
    input: PartialMessage<GetLiteStatRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLiteStatResponse> {
    return unary<GetLiteStatRequest, GetLiteStatResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.getLiteStat,
      input,
      options,
    );
  }

  /**
   * -------------------------------------
   * Player moneyOut
   * -------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.player.event.get_moneyout
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatisticEvents.GetMoneyOut
   */
  public getMoneyOut(
    input: PartialMessage<GetMoneyOutRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetMoneyOutResponse> {
    return unary<GetMoneyOutRequest, GetMoneyOutResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.getMoneyOut,
      input,
      options,
    );
  }

  /**
   * -------------------------------------
   * Player deposits info
   * -------------------------------------
   * Format: proto
   * Access:
   *   GRPC
   *   NATS Request: pinup.product.<license>.stat_service.player.event.get_deposits_info
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatisticEvents.GetDepositsInfo
   */
  public getDepositsInfo(
    input: PartialMessage<GetDepositsInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDepositsInfoResponse> {
    return unary<GetDepositsInfoRequest, GetDepositsInfoResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.getDepositsInfo,
      input,
      options,
    );
  }

  /**
   * -------------------------------------
   * Player tax events
   * -------------------------------------
   * Format: proto
   * Access:
   *   GRPC
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerStatisticEvents.GetTaxEvents
   */
  public getTaxEvents(
    input: PartialMessage<GetTaxEventsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetTaxEventsResponse> {
    return unary<GetTaxEventsRequest, GetTaxEventsResponse>(
      this.transport,
      PlayerStatisticEventsRemoteService,
      PlayerStatisticEventsRemoteService.methods.getTaxEvents,
      input,
      options,
    );
  }
}
