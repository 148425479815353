import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { IS_MAC } from '@pu/sdk';

@Component({
  selector: 'bo-access-key-info',
  standalone: true,
  imports: [MatTooltip],
  templateUrl: './access-key-info.component.html',
  styleUrl: './access-key-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessKeyInfoComponent {
  protected readonly isMac = inject(IS_MAC);
  public readonly key = input.required<string>();
}
