import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

import type { OnChangeListener, OnTouchedListener } from '@pu/sdk';
import { provideMultiExisting } from '@pu/sdk';

@Component({
  selector: 'bo-radio-toggle-bar',
  standalone: true,
  template: `
    <ng-content select="[bo-radio-toggle-item]" />
  `,
  styleUrl: './radio-toggle-bar.component.scss',
  host: {
    '[attr.aria-disabled]': 'disabled()',
    '(blur)': 'onTouched()',
  },
  providers: [
    provideMultiExisting(
      NG_VALUE_ACCESSOR,
      forwardRef(() => RadioToggleBarComponent),
    ),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioToggleBarComponent<T> implements ControlValueAccessor {
  private onChange: OnChangeListener<T | null> = noop;
  protected onTouched: OnTouchedListener = noop;

  private readonly cdr = inject(ChangeDetectorRef);

  public disabled = signal(false);

  public selectedValue = signal<T | null>(null);

  public registerOnChange(fn: OnChangeListener<T | null>): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: OnTouchedListener): void {
    this.onTouched = fn;
  }

  public writeValue(value: T | null): void {
    this.selectedValue.set(value);
    this.cdr.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  public toggleSelection(value: T): void {
    this.selectedValue.update((current) => {
      const newValue = current === value ? null : value;

      this.onChange(newValue);

      return newValue;
    });
  }
}
