import {
  isEmpty,
  isObject,
  isPlainObject,
  mapValues,
  omitBy,
  overEvery,
} from 'lodash-es';

export function omitEmptyObjectProps<T extends object>(params: T): Partial<T> {
  const isEmptyObject = overEvery(isObject, isEmpty);

  return isPlainObject(params)
    ? // TODO: fix typing
      omitBy<T>(mapValues(params, omitEmptyObjectProps) as T, isEmptyObject)
    : params;
}
