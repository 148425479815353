// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/base/models.proto (package pinup.stat.report.base, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Total
 * Deprecated
 *
 * @generated from message pinup.stat.report.base.Total
 */
export const Total = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.Total",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message pinup.stat.report.base.SumCurrency
 */
export const SumCurrency = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.SumCurrency",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

