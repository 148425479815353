import { inject, InjectionToken } from '@angular/core';

import { SUPPORTED_DEPARTMENTS } from './department';
import { DepartmentLicenseLink } from './department-license-link';
import { SUPPORTED_LICENSES } from './supported-licenses';

export const SUPPORTED_DEPARTMENT_LICENSE_LINKS = new InjectionToken<
  readonly DepartmentLicenseLink[]
>('SUPPORTED_DEPARTMENT_LICENSE_LINKS', {
  providedIn: 'root',
  factory: () => {
    const departments = inject(SUPPORTED_DEPARTMENTS);
    const licenses = inject(SUPPORTED_LICENSES);

    return departments.flatMap((department) =>
      licenses.map((license) =>
        DepartmentLicenseLink.create(department, license),
      ),
    );
  },
});
