import {
  AccountVerifyState,
  ActivityStatus,
  BettingStatus,
  CasinoStatus,
  CertifiedState,
  DepositStatus,
  DigitainStatus,
  PrivilegeStatus,
  SmsStatus,
} from '@pinup-grpc/services/bo/players/players_pb';

import type { StatusInfo } from '@bo/common';
import { generateStatusInfoEntries, generateStatusInfoMap } from '@bo/common';

export const casinoStatusesMap = generateStatusInfoMap(CasinoStatus, [
  [CasinoStatus.UNSPECIFIED, null],
  [CasinoStatus.C1, 'primary-status'],
  [CasinoStatus.C2, 'success-status'],
  [CasinoStatus.C3, 'warning-status'],
  [CasinoStatus.C4, 'success-status'],
  [CasinoStatus.CA, 'ca-status'],
  [CasinoStatus.CN, null],
  [CasinoStatus.CVIP, 'success-full-filled-status'],
  [CasinoStatus.CZ, 'failed-status'],
]);

export const bettingStatusesMap = generateStatusInfoMap(BettingStatus, [
  [BettingStatus.UNSPECIFIED, null],
  [BettingStatus.B1, 'success-status'],
  [BettingStatus.B2, 'failed-status'],
  [BettingStatus.B3, 'warning-status'],
  [BettingStatus.B4, 'primary-status'],
  [BettingStatus.B5, 'warning-status'],
  [BettingStatus.BA, null],
  [BettingStatus.BN, null],
  [BettingStatus.BZ, 'failed-status'],
  [BettingStatus.BVIP, 'success-full-filled-status'],
]);

export const digitainStatusesMap = generateStatusInfoMap(DigitainStatus, [
  [DigitainStatus.UNSPECIFIED, null, 'Unspecified'],
  [DigitainStatus.COMMON, 'success-transparent-outline-status', 'DS1 - Common'],
  [DigitainStatus.FEMALE, 'neutral-status', 'DS3 - Female'],
  [DigitainStatus.VIP_USER, 'success-status', 'DS4 - Vip User'],
  [DigitainStatus.NEW_USER, 'neutral-status', 'DS2 - New User'],
  [DigitainStatus.TERMINAL, 'primary-status', 'DS5 - Terminal'],
  [
    DigitainStatus.ARBITRAGE,
    'failed-dark-text-color-status',
    'DS6 - Arbitrage',
  ],
  [DigitainStatus.PROBLEM, 'failed-dark-text-color-status', 'DS7 - Problem'],
  [DigitainStatus.SPECIAL, 'neutral-status', 'DS8 - Special'],
  [DigitainStatus.MARKER, 'failed-dark-text-color-status', 'DS9 - Marker'],
  [
    DigitainStatus.TRADING_ANALYZING,
    'primary-status',
    'DS10 - Trading Analyzing',
  ],
  [DigitainStatus.LATE_BETTING, 'warning-status', 'DS11 - Late Betting'],
  [DigitainStatus.MIDDLER, 'warning-status', 'DS12 - Middler'],
  [DigitainStatus.BET_SHOP, 'neutral-status', 'DS13 - Bet Shop'],
  [DigitainStatus.PROFESSIONAL, 'warning-status', 'DS14 - Professional'],
  [DigitainStatus.HIGH_VALUE, 'neutral-status', 'DS15 - High Value'],
  [DigitainStatus.VIP_SPECIAL, 'neutral-status', 'DS16 - VIP Special'],
  [DigitainStatus.BONUS_HUNTER, 'warning-status', 'DS17 - Bonus Hunter'],
  [DigitainStatus.CAPPER, 'neutral-status', 'DS19 - Capper'],
]);

export const certifiedStatusesMap = generateStatusInfoMap(CertifiedState, [
  [CertifiedState.CERTIFIED_STATUS_CERTIFIED, 'success-status', 'Certified'],
  [
    CertifiedState.CERTIFIED_STATUS_CERTIFIED_MANUALLY,
    'success-status',
    'Certified',
  ],
  [
    CertifiedState.CERTIFIED_STATUS_NOT_CERTIFIED,
    'failed-status',
    'Not Certified',
  ],
  [
    CertifiedState.CERTIFIED_STATUS_UNCERTIFIED_MANUALLY,
    'failed-status',
    'Not Certified',
  ],
]);

export const oneClickStatusMap = new Map<boolean, StatusInfo>([
  [true, { className: 'success-status', label: '1 click ON' }],
  [false, { className: 'neutral-outline-status', label: '1 click OFF' }],
]);

export const autoWithdrawalStatusOn = {
  className: 'primary-status',
  label: 'AW',
};

export const smsStatusInfo = generateStatusInfoMap(SmsStatus, [
  [SmsStatus.SMS_STATUS_UNSPECIFIED, 'basic-outline-status', 'Empty'],
  [SmsStatus.SMS_STATUS_1, 'success-outline-status', 'Send'],
  [SmsStatus.SMS_STATUS_2, 'success-status', 'Delivered'],
  [SmsStatus.SMS_STATUS_3, 'neutral-outline-status', 'Enroute'],
  [SmsStatus.SMS_STATUS_4, 'basic-outline-status', 'Rejected'],
  [SmsStatus.SMS_STATUS_5, 'basic-outline-status', 'Expired'],
  [SmsStatus.SMS_STATUS_6, 'failed-status', 'Error'],
]);

export const activityStatusesMap = generateStatusInfoMap(
  ActivityStatus,
  generateStatusInfoEntries(ActivityStatus),
);

export const privilegeStatusesMap = generateStatusInfoMap(
  PrivilegeStatus,
  generateStatusInfoEntries(PrivilegeStatus),
);

export const depositStatusesMap = generateStatusInfoMap(
  DepositStatus,
  generateStatusInfoEntries(DepositStatus),
);

export const accountVerifyStatusesMap = generateStatusInfoMap(
  AccountVerifyState,
  [
    [AccountVerifyState.ACCOUNT_VERIFY_NO, '', 'Null'],
    [AccountVerifyState.ACCOUNT_VERIFY_YES, '', ''],
    [AccountVerifyState.ACCOUNT_VERIFY_IN_PROGRESS, '', 'In progress'],
    [AccountVerifyState.ACCOUNT_VERIFY_NO_FINAL, '', 'No final'],
    [AccountVerifyState.ACCOUNT_VERIFY_NO_RETRY, '', 'No retry'],
  ],
);
