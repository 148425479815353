// @generated by protoc-gen-es v1.10.0
// @generated from file service/deposit_settings.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message payments.BigDepositSettings
 */
export const BigDepositSettings = /*@__PURE__*/ proto3.makeMessageType(
  "payments.BigDepositSettings",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "limitDepositMap", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 2 /* ScalarType.FLOAT */} },
  ],
);

/**
 * @generated from message payments.GetBigDepositSettingsRequest
 */
export const GetBigDepositSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.GetBigDepositSettingsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message payments.GetBigDepositSettingsResponse
 */
export const GetBigDepositSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.GetBigDepositSettingsResponse",
  () => [
    { no: 1, name: "list", kind: "message", T: BigDepositSettings },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.UpdateBigDepositSettingsResponse
 */
export const UpdateBigDepositSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.UpdateBigDepositSettingsResponse",
  () => [
    { no: 1, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

