import type { PipeTransform } from '@angular/core';
import { inject, Pipe } from '@angular/core';

import { OLD_ADMIN_URL } from '../old-admin.providers';
import { generateOldAdminLink } from './old-admin-link.utils';

@Pipe({ name: 'boOldAdminLink', standalone: true })
export class OlAdminLinkPipe implements PipeTransform {
  private readonly url = inject(OLD_ADMIN_URL);

  public transform(path: string): string {
    return generateOldAdminLink(this.url, path);
  }
}
