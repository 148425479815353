<div class="flex gap-2" [class.expanded]="isExpanded()">
  <div class="toolbar">
    <bo-svg
      class="icon flex"
      (click)="toggle()"
      [size]="24"
      [unsafeSvgSource]="isExpanded() ? chevronUp : chevronDown"
    />
    <ng-content select="[toolbar]" />
  </div>
  <div class="flex column full-width">
    <ng-content select="[header]" />
    @if (isExpanded()) {
      <ng-content select="[content]" />
    }
  </div>
</div>
