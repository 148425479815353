import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'money',
  standalone: true,
  pure: true,
})
export class MoneyPipe implements PipeTransform {
  public transform(value: bigint): string {
    const amount = Number(value);

    const decimal = Math.trunc(amount / 100).toLocaleString('en-US');
    const fraction = String(Math.abs(amount) % 100).padStart(2, '0');

    return `${decimal}.${fraction}`;
  }
}
