// @generated by protoc-gen-es v1.10.0
// @generated from file services/currencies_exchange/currencies_exchange.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.ConvertRequest
 */
export const ConvertRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.ConvertRequest",
  () => [
    { no: 1, name: "money", kind: "message", T: Money },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.ConvertResponse
 */
export const ConvertResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.ConvertResponse",
  () => [
    { no: 1, name: "money", kind: "message", T: Money },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.Money
 */
export const Money = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.Money",
  () => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

