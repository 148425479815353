// TODO collision https://git.time2go.tech/apis/pinup/stat/-/blob/main/base/error.proto

// @generated by protoc-gen-es v1.10.0
// @generated from file base/error_collision_0.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message players.Error
 */
export const Error = /*@__PURE__*/ proto3.makeMessageType(
  "players.Error",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

