// @generated by protoc-gen-es v1.10.0
// @generated from file common/pagination.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kycaid.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.PaginationRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "current_page", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message kycaid.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.PaginationResponse",
  () => [
    { no: 1, name: "total_items", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total_pages", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

