// @generated by protoc-gen-es v1.10.0
// @generated from file models/moneyout/decline_reason.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum payments.MoneyOutDeclineReason
 */
export const MoneyOutDeclineReason = /*@__PURE__*/ proto3.makeEnum(
  "payments.MoneyOutDeclineReason",
  [
    {no: 0, name: "MONEY_OUT_DECLINE_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MONEY_OUT_DECLINE_REASON_TURNOVER_NEEDED", localName: "TURNOVER_NEEDED"},
    {no: 2, name: "MONEY_OUT_DECLINE_REASON_VERIFICATION_NEEDED", localName: "VERIFICATION_NEEDED"},
    {no: 3, name: "MONEY_OUT_DECLINE_REASON_MONEY_OUT_METHOD_NOT_MATCHED", localName: "MONEY_OUT_METHOD_NOT_MATCHED"},
    {no: 4, name: "MONEY_OUT_DECLINE_REASON_SELFIE_NEEDED", localName: "SELFIE_NEEDED"},
    {no: 5, name: "MONEY_OUT_DECLINE_REASON_FULL_VERIFICATION", localName: "FULL_VERIFICATION"},
    {no: 6, name: "MONEY_OUT_DECLINE_REASON_RECREATE_MONEY_OUT", localName: "RECREATE_MONEY_OUT"},
    {no: 7, name: "MONEY_OUT_DECLINE_REASON_FULL_PROFILE_NEEDED", localName: "FULL_PROFILE_NEEDED"},
    {no: 8, name: "MONEY_OUT_DECLINE_REASON_METHOD_NOT_AVAILABLE", localName: "METHOD_NOT_AVAILABLE"},
    {no: 9, name: "MONEY_OUT_DECLINE_REASON_OTHER", localName: "OTHER"},
  ],
);

