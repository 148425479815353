import {
  omitEmptyObjectProps,
  parseStringToArray,
  stringToBigInt,
  toStringMultiselect,
  toUint64Multiselect,
} from '@pu/sdk';
import type { PersistentData } from '@bo/common';
import {
  fromDichotomy,
  toDichotomy,
  toFormDateFromString,
  toStatSumRangeWithCurrency,
  toTimeRange,
} from '@bo/common';

import type { ListDepositsFilter } from './deposits-filter.types';
import type { DepositsFilters } from './forms';

export const toFormData = (params: PersistentData): DepositsFilters => ({
  finance: {
    externalIds: parseStringToArray(params.externalIds),
    methodInclude: parseStringToArray(params.methodInclude, stringToBigInt),
    methodExclude: parseStringToArray(params.methodExclude, stringToBigInt),
    amountCurrency: params.amountCurrency,
    amountFrom: params.amountFrom ? BigInt(params.amountFrom) : null,
    amountTo: params.amountTo ? BigInt(params.amountTo) : null,
    createdFrom: toFormDateFromString(params.createdFrom),
    createdTo: toFormDateFromString(params.createdTo),
    updatedFrom: toFormDateFromString(params.updatedFrom),
    updatedTo: toFormDateFromString(params.updatedTo),
    isFirstDeposit: toDichotomy(params.isFirstDeposit),
    isFistHourDeposit: toDichotomy(params.isFistHourDeposit),
    balanceCurrency: params.balanceCurrency,
    balanceFrom: params.balanceFrom ? BigInt(params.balanceFrom) : null,
    balanceTo: params.balanceTo ? BigInt(params.balanceTo) : null,
    balanceBeforeFrom: params.balanceBeforeFrom
      ? BigInt(params.balanceBeforeFrom)
      : null,
    balanceBeforeTo: params.balanceBeforeTo
      ? BigInt(params.balanceBeforeTo)
      : null,
    isChargeback: toDichotomy(params.isChargeback),
  },
  other: {
    domain: params.domain || '',
    playerGeo: parseStringToArray(params.playerGeo),
    clickId: params.clickId || '',
  },
});

export const toModelData = (
  params: PersistentData,
): Partial<ListDepositsFilter> =>
  omitEmptyObjectProps<ListDepositsFilter>({
    profile: {
      // here data will be set in service before send
      ids: [],
      clickId: params.clickId,
      balanceBefore: toStatSumRangeWithCurrency(
        params.balanceBeforeFrom,
        params.balanceBeforeTo,
        params.balanceCurrency,
      ),
      balance: toStatSumRangeWithCurrency(
        params.balanceFrom,
        params.balanceTo,
        params.balanceCurrency,
      ),
      playerGeo: toStringMultiselect(params.playerGeo),
    },
    deposit: {
      externalIds: params.externalIds?.split(','),
      paymentMethod: toUint64Multiselect(
        params.methodInclude,
        params.methodExclude,
      ),
      amount: toStatSumRangeWithCurrency(
        params.amountFrom,
        params.amountTo,
        params.amountCurrency,
      ),
      // durationToCompletedRange
      isFirstDeposit: fromDichotomy(params.isFirstDeposit),
      isFistHourDeposit: fromDichotomy(params.isFistHourDeposit),
      domain: params.domain,
      createdAt: toTimeRange(params.createdFrom, params.createdTo),
      updatedAt: toTimeRange(params.updatedFrom, params.updatedTo),
      isChargeback: fromDichotomy(params.isChargeback),
    },
  });
