<ng-content />
<mat-autocomplete
  [displayWith]="displayWith()"
  autoActiveFirstOption
  requireSelection
  #auto="matAutocomplete"
>
  @for (
    entry of availableEntries()
      | autocompleteWithMultipleHighlights
        : highlight()
        : controlInput().input.nativeElement.value;
    track entry.value
  ) {
    <mat-option [value]="entry.value">
      <bo-multi-highlight-match [highlight]="entry.highlighted" />
    </mat-option>
  }
</mat-autocomplete>
