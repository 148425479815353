import { inject, Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash-es';

import { CurrentRouteNavigationService } from '@pu/sdk';

import { PaginationManagerBase } from '../../../../../pagination-management/pagination-manager.base';
import type { PaginationPanelPayload } from '../../../../pagination-panel.model';
import { PAGINATION_PANEL_INITIAL_STATE } from '../../../../pagination-panel.providers';
import { PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE } from './route-params-persistence.providers';

@Injectable()
export class RouteParamsPersistenceManager extends PaginationManagerBase<PaginationPanelPayload> {
  private readonly navigationService = inject(CurrentRouteNavigationService);
  public readonly source$ = inject(PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE);

  constructor() {
    super();

    const initialState = inject(PAGINATION_PANEL_INITIAL_STATE, {
      optional: true,
    });

    if (initialState) {
      this.update(initialState);
    }
  }

  public update({
    currentPage: page,
    pageSize,
  }: Partial<PaginationPanelPayload>): void {
    const payload = omitBy({ page, pageSize }, isNil);

    this.navigationService.setParams(payload, false, {
      replaceUrl: true,
      queryParamsHandling: 'preserve',
    });
  }
}
