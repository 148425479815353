import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import type { PaginationTotal, TotalInfoManager } from './total-info.model';

@Injectable()
export class PaginationTotalManagerService implements TotalInfoManager {
  public readonly total$ = new ReplaySubject<PaginationTotal>(1);

  public update(value: PaginationTotal): void {
    this.total$.next(value);
  }
}
