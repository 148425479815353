import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  model,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { MatSuffix } from '@angular/material/form-field';

import { SvgComponent } from '@pu/sdk';
import { closeIcon, TimeRangeFilter } from '@bo/common';

@Component({
  selector: 'bo-date-range-picker',
  standalone: true,
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  imports: [
    SvgComponent,
    FormsModule,
    MatIconButton,
    MatDatepickerToggle,
    MatDateRangePicker,
    MatDateRangeInput,
    MatSuffix,
    MatStartDate,
    MatEndDate,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent {
  @Output() public readonly dateSet = new EventEmitter<TimeRangeFilter>();

  public selectedStartDate = model<Date | null>();
  public selectedEndDate = model<Date | null>();

  protected readonly areBothDatesSet = computed(() => {
    return Boolean(this.selectedStartDate()) && Boolean(this.selectedEndDate());
  });

  private readonly areBothDatesUnset = computed(() => {
    return !this.selectedStartDate() && !this.selectedEndDate();
  });

  protected readonly closeIcon = closeIcon;

  constructor() {
    effect(() => {
      if (this.areBothDatesSet() || this.areBothDatesUnset()) {
        this.changeTimeRange();
      }
    });
  }

  protected changeTimeRange(): void {
    this.dateSet.emit({
      from: this.selectedStartDate() || null,
      to: this.selectedEndDate() || null,
    });
  }

  protected clearDateRange(): void {
    this.dateSet.emit(new TimeRangeFilter());
  }
}
