import type { ComponentType } from '@angular/cdk/overlay';
import type {
  EnvironmentInjector,
  Provider,
  StaticProvider,
} from '@angular/core';
import { inject, Injectable, INJECTOR, Injector } from '@angular/core';
import { MatDialog, type MatDialogConfig } from '@angular/material/dialog';
import type { Observable } from 'rxjs';
import { defer, tap } from 'rxjs';

export interface SmartDialogOptions {
  readonly providers?: (Provider | StaticProvider)[];
}

type SmartDialogConfig = Omit<MatDialogConfig, 'data'>;

@Injectable({ providedIn: 'root' })
export class SmartDialog {
  private readonly dialog = inject(MatDialog);
  private readonly injector = inject(INJECTOR);

  /**
   * @see {@linkcode MatDialog#open}
   */

  // TODO: move providers to config
  public open<R = unknown>(
    component: ComponentType<object>,
    providers: Provider[],
    config?: SmartDialogConfig,
  ): Observable<R | undefined> {
    return defer(() => {
      const parentInjector = config?.injector ?? this.injector;
      const injector = Injector.create({ parent: parentInjector, providers });
      const ref = this.dialog.open<object, never, R>(component, {
        ...config,
        injector,
      });

      return ref.afterClosed().pipe(
        tap({
          finalize: () => {
            // TODO find a more elegant way to call `destroy`
            (injector as EnvironmentInjector).destroy();
          },
          unsubscribe: () => {
            ref.close();
          },
        }),
      );
    });
  }
}
