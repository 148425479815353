// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/bo/players/payment_methods.proto (package stat.bo.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPlayerPaymentMethodsRequest, GetPlayerPaymentMethodsResponse } from "./payment_methods_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service stat.bo.players.PlayerPaymentMethodService
 */
@Injectable({ providedIn: "root" })
export class PlayerPaymentMethodRemoteService {
  public static readonly typeName = "stat.bo.players.PlayerPaymentMethodService";

  public static readonly methods = {
    getPlayerPaymentMethods: {
      name: "GetPlayerPaymentMethods",
      I: GetPlayerPaymentMethodsRequest,
      O: GetPlayerPaymentMethodsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ----------------------------------------------------------------
   * GetPlayerPaymentMethods - get player`s payment statistic by account numbers
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.players.PlayerPaymentMethodService.GetPlayerPaymentMethods
   */
  public getPlayerPaymentMethods(
    input: PartialMessage<GetPlayerPaymentMethodsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerPaymentMethodsResponse> {
    return unary<GetPlayerPaymentMethodsRequest, GetPlayerPaymentMethodsResponse>(
      this.transport,
      PlayerPaymentMethodRemoteService,
      PlayerPaymentMethodRemoteService.methods.getPlayerPaymentMethods,
      input,
      options,
    );
  }
}
