// Copyright 2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.10.0
// @generated from file buf/validate/validate.proto (package buf.validate, syntax proto2)
/* eslint-disable */
// @ts-nocheck

import { Duration, FieldOptions, MessageOptions, OneofOptions, proto2, Timestamp } from "@bufbuild/protobuf";

/**
 * Specifies how FieldConstraints.ignore behaves. See the documentation for
 * FieldConstraints.required for definitions of "populated" and "nullable".
 *
 * @generated from enum buf.validate.Ignore
 */
export const Ignore = /*@__PURE__*/ proto2.makeEnum(
  "buf.validate.Ignore",
  [
    {no: 0, name: "IGNORE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "IGNORE_IF_UNPOPULATED", localName: "IF_UNPOPULATED"},
    {no: 2, name: "IGNORE_IF_DEFAULT_VALUE", localName: "IF_DEFAULT_VALUE"},
    {no: 3, name: "IGNORE_ALWAYS", localName: "ALWAYS"},
    {no: 1, name: "IGNORE_EMPTY", localName: "EMPTY"},
    {no: 2, name: "IGNORE_DEFAULT", localName: "DEFAULT"},
  ],
);

/**
 * WellKnownRegex contain some well-known patterns.
 *
 * @generated from enum buf.validate.KnownRegex
 */
export const KnownRegex = /*@__PURE__*/ proto2.makeEnum(
  "buf.validate.KnownRegex",
  [
    {no: 0, name: "KNOWN_REGEX_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "KNOWN_REGEX_HTTP_HEADER_NAME", localName: "HTTP_HEADER_NAME"},
    {no: 2, name: "KNOWN_REGEX_HTTP_HEADER_VALUE", localName: "HTTP_HEADER_VALUE"},
  ],
);

/**
 * `Constraint` represents a validation rule written in the Common Expression
 * Language (CEL) syntax. Each Constraint includes a unique identifier, an
 * optional error message, and the CEL expression to evaluate. For more
 * information on CEL, [see our documentation](https://github.com/bufbuild/protovalidate/blob/main/docs/cel.md).
 *
 * ```proto
 * message Foo {
 *   option (buf.validate.message).cel = {
 *     id: "foo.bar"
 *     message: "bar must be greater than 0"
 *     expression: "this.bar > 0"
 *   };
 *   int32 bar = 1;
 * }
 * ```
 *
 * @generated from message buf.validate.Constraint
 */
export const Constraint = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Constraint",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "expression", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * MessageConstraints represents validation rules that are applied to the entire message.
 * It includes disabling options and a list of Constraint messages representing Common Expression Language (CEL) validation rules.
 *
 * @generated from message buf.validate.MessageConstraints
 */
export const MessageConstraints = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.MessageConstraints",
  () => [
    { no: 1, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "cel", kind: "message", T: Constraint, repeated: true },
  ],
);

/**
 * The `OneofConstraints` message type enables you to manage constraints for
 * oneof fields in your protobuf messages.
 *
 * @generated from message buf.validate.OneofConstraints
 */
export const OneofConstraints = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.OneofConstraints",
  () => [
    { no: 1, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * FieldConstraints encapsulates the rules for each type of field. Depending on
 * the field, the correct set should be used to ensure proper validations.
 *
 * @generated from message buf.validate.FieldConstraints
 */
export const FieldConstraints = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.FieldConstraints",
  () => [
    { no: 23, name: "cel", kind: "message", T: Constraint, repeated: true },
    { no: 25, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 27, name: "ignore", kind: "enum", T: proto2.getEnumType(Ignore), opt: true },
    { no: 1, name: "float", kind: "message", T: FloatRules, oneof: "type" },
    { no: 2, name: "double", kind: "message", T: DoubleRules, oneof: "type" },
    { no: 3, name: "int32", kind: "message", T: Int32Rules, oneof: "type" },
    { no: 4, name: "int64", kind: "message", T: Int64Rules, oneof: "type" },
    { no: 5, name: "uint32", kind: "message", T: UInt32Rules, oneof: "type" },
    { no: 6, name: "uint64", kind: "message", T: UInt64Rules, oneof: "type" },
    { no: 7, name: "sint32", kind: "message", T: SInt32Rules, oneof: "type" },
    { no: 8, name: "sint64", kind: "message", T: SInt64Rules, oneof: "type" },
    { no: 9, name: "fixed32", kind: "message", T: Fixed32Rules, oneof: "type" },
    { no: 10, name: "fixed64", kind: "message", T: Fixed64Rules, oneof: "type" },
    { no: 11, name: "sfixed32", kind: "message", T: SFixed32Rules, oneof: "type" },
    { no: 12, name: "sfixed64", kind: "message", T: SFixed64Rules, oneof: "type" },
    { no: 13, name: "bool", kind: "message", T: BoolRules, oneof: "type" },
    { no: 14, name: "string", kind: "message", T: StringRules, oneof: "type" },
    { no: 15, name: "bytes", kind: "message", T: BytesRules, oneof: "type" },
    { no: 16, name: "enum", kind: "message", T: EnumRules, oneof: "type" },
    { no: 18, name: "repeated", kind: "message", T: RepeatedRules, oneof: "type" },
    { no: 19, name: "map", kind: "message", T: MapRules, oneof: "type" },
    { no: 20, name: "any", kind: "message", T: AnyRules, oneof: "type" },
    { no: 21, name: "duration", kind: "message", T: DurationRules, oneof: "type" },
    { no: 22, name: "timestamp", kind: "message", T: TimestampRules, oneof: "type" },
    { no: 24, name: "skipped", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 26, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * PredefinedConstraints are custom constraints that can be re-used with
 * multiple fields.
 *
 * @generated from message buf.validate.PredefinedConstraints
 */
export const PredefinedConstraints = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.PredefinedConstraints",
  () => [
    { no: 1, name: "cel", kind: "message", T: Constraint, repeated: true },
  ],
);

/**
 * FloatRules describes the constraints applied to `float` values. These
 * rules may also be applied to the `google.protobuf.FloatValue` Well-Known-Type.
 *
 * @generated from message buf.validate.FloatRules
 */
export const FloatRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.FloatRules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 8, name: "finite", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "example", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
  ],
);

/**
 * DoubleRules describes the constraints applied to `double` values. These
 * rules may also be applied to the `google.protobuf.DoubleValue` Well-Known-Type.
 *
 * @generated from message buf.validate.DoubleRules
 */
export const DoubleRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.DoubleRules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 8, name: "finite", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "example", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * Int32Rules describes the constraints applied to `int32` values. These
 * rules may also be applied to the `google.protobuf.Int32Value` Well-Known-Type.
 *
 * @generated from message buf.validate.Int32Rules
 */
export const Int32Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Int32Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * Int64Rules describes the constraints applied to `int64` values. These
 * rules may also be applied to the `google.protobuf.Int64Value` Well-Known-Type.
 *
 * @generated from message buf.validate.Int64Rules
 */
export const Int64Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Int64Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 9, name: "example", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ],
);

/**
 * UInt32Rules describes the constraints applied to `uint32` values. These
 * rules may also be applied to the `google.protobuf.UInt32Value` Well-Known-Type.
 *
 * @generated from message buf.validate.UInt32Rules
 */
export const UInt32Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.UInt32Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
  ],
);

/**
 * UInt64Rules describes the constraints applied to `uint64` values. These
 * rules may also be applied to the `google.protobuf.UInt64Value` Well-Known-Type.
 *
 * @generated from message buf.validate.UInt64Rules
 */
export const UInt64Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.UInt64Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 4 /* ScalarType.UINT64 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 4 /* ScalarType.UINT64 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 4 /* ScalarType.UINT64 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 4 /* ScalarType.UINT64 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * SInt32Rules describes the constraints applied to `sint32` values.
 *
 * @generated from message buf.validate.SInt32Rules
 */
export const SInt32Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.SInt32Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 17 /* ScalarType.SINT32 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 17 /* ScalarType.SINT32 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 17 /* ScalarType.SINT32 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 17 /* ScalarType.SINT32 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 17 /* ScalarType.SINT32 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 17 /* ScalarType.SINT32 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 17 /* ScalarType.SINT32 */, repeated: true },
  ],
);

/**
 * SInt64Rules describes the constraints applied to `sint64` values.
 *
 * @generated from message buf.validate.SInt64Rules
 */
export const SInt64Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.SInt64Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 18 /* ScalarType.SINT64 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 18 /* ScalarType.SINT64 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 18 /* ScalarType.SINT64 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 18 /* ScalarType.SINT64 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 18 /* ScalarType.SINT64 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 18 /* ScalarType.SINT64 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 18 /* ScalarType.SINT64 */, repeated: true },
  ],
);

/**
 * Fixed32Rules describes the constraints applied to `fixed32` values.
 *
 * @generated from message buf.validate.Fixed32Rules
 */
export const Fixed32Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Fixed32Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, repeated: true },
  ],
);

/**
 * Fixed64Rules describes the constraints applied to `fixed64` values.
 *
 * @generated from message buf.validate.Fixed64Rules
 */
export const Fixed64Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Fixed64Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, repeated: true },
  ],
);

/**
 * SFixed32Rules describes the constraints applied to `fixed32` values.
 *
 * @generated from message buf.validate.SFixed32Rules
 */
export const SFixed32Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.SFixed32Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, repeated: true },
  ],
);

/**
 * SFixed64Rules describes the constraints applied to `fixed64` values.
 *
 * @generated from message buf.validate.SFixed64Rules
 */
export const SFixed64Rules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.SFixed64Rules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, opt: true },
    { no: 2, name: "lt", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, oneof: "less_than" },
    { no: 3, name: "lte", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, oneof: "less_than" },
    { no: 4, name: "gt", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, oneof: "greater_than" },
    { no: 5, name: "gte", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, oneof: "greater_than" },
    { no: 6, name: "in", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, repeated: true },
    { no: 7, name: "not_in", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, repeated: true },
    { no: 8, name: "example", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, repeated: true },
  ],
);

/**
 * BoolRules describes the constraints applied to `bool` values. These rules
 * may also be applied to the `google.protobuf.BoolValue` Well-Known-Type.
 *
 * @generated from message buf.validate.BoolRules
 */
export const BoolRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.BoolRules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "example", kind: "scalar", T: 8 /* ScalarType.BOOL */, repeated: true },
  ],
);

/**
 * StringRules describes the constraints applied to `string` values These
 * rules may also be applied to the `google.protobuf.StringValue` Well-Known-Type.
 *
 * @generated from message buf.validate.StringRules
 */
export const StringRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.StringRules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "min_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 3, name: "max_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 20, name: "len_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 4, name: "min_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 5, name: "max_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 6, name: "pattern", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "prefix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "suffix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "contains", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "not_contains", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "not_in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "email", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 13, name: "hostname", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 14, name: "ip", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 15, name: "ipv4", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 16, name: "ipv6", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 17, name: "uri", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 18, name: "uri_ref", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 21, name: "address", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 22, name: "uuid", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 33, name: "tuuid", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 26, name: "ip_with_prefixlen", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 27, name: "ipv4_with_prefixlen", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 28, name: "ipv6_with_prefixlen", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 29, name: "ip_prefix", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 30, name: "ipv4_prefix", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 31, name: "ipv6_prefix", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 32, name: "host_and_port", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 24, name: "well_known_regex", kind: "enum", T: proto2.getEnumType(KnownRegex), oneof: "well_known" },
    { no: 25, name: "strict", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 34, name: "example", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * BytesRules describe the constraints applied to `bytes` values. These rules
 * may also be applied to the `google.protobuf.BytesValue` Well-Known-Type.
 *
 * @generated from message buf.validate.BytesRules
 */
export const BytesRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.BytesRules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 13, name: "len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "min_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 3, name: "max_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 4, name: "pattern", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "prefix", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 6, name: "suffix", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 7, name: "contains", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 8, name: "in", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
    { no: 9, name: "not_in", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
    { no: 10, name: "ip", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 11, name: "ipv4", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 12, name: "ipv6", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
    { no: 14, name: "example", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
  ],
);

/**
 * EnumRules describe the constraints applied to `enum` values.
 *
 * @generated from message buf.validate.EnumRules
 */
export const EnumRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.EnumRules",
  () => [
    { no: 1, name: "const", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "defined_only", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "not_in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "example", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * RepeatedRules describe the constraints applied to `repeated` values.
 *
 * @generated from message buf.validate.RepeatedRules
 */
export const RepeatedRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.RepeatedRules",
  () => [
    { no: 1, name: "min_items", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "max_items", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 3, name: "unique", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "items", kind: "message", T: FieldConstraints, opt: true },
  ],
);

/**
 * MapRules describe the constraints applied to `map` values.
 *
 * @generated from message buf.validate.MapRules
 */
export const MapRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.MapRules",
  () => [
    { no: 1, name: "min_pairs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "max_pairs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 4, name: "keys", kind: "message", T: FieldConstraints, opt: true },
    { no: 5, name: "values", kind: "message", T: FieldConstraints, opt: true },
  ],
);

/**
 * AnyRules describe constraints applied exclusively to the `google.protobuf.Any` well-known type.
 *
 * @generated from message buf.validate.AnyRules
 */
export const AnyRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.AnyRules",
  () => [
    { no: 2, name: "in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "not_in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * DurationRules describe the constraints applied exclusively to the `google.protobuf.Duration` well-known type.
 *
 * @generated from message buf.validate.DurationRules
 */
export const DurationRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.DurationRules",
  () => [
    { no: 2, name: "const", kind: "message", T: Duration, opt: true },
    { no: 3, name: "lt", kind: "message", T: Duration, oneof: "less_than" },
    { no: 4, name: "lte", kind: "message", T: Duration, oneof: "less_than" },
    { no: 5, name: "gt", kind: "message", T: Duration, oneof: "greater_than" },
    { no: 6, name: "gte", kind: "message", T: Duration, oneof: "greater_than" },
    { no: 7, name: "in", kind: "message", T: Duration, repeated: true },
    { no: 8, name: "not_in", kind: "message", T: Duration, repeated: true },
    { no: 9, name: "example", kind: "message", T: Duration, repeated: true },
  ],
);

/**
 * TimestampRules describe the constraints applied exclusively to the `google.protobuf.Timestamp` well-known type.
 *
 * @generated from message buf.validate.TimestampRules
 */
export const TimestampRules = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.TimestampRules",
  () => [
    { no: 2, name: "const", kind: "message", T: Timestamp, opt: true },
    { no: 3, name: "lt", kind: "message", T: Timestamp, oneof: "less_than" },
    { no: 4, name: "lte", kind: "message", T: Timestamp, oneof: "less_than" },
    { no: 7, name: "lt_now", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "less_than" },
    { no: 5, name: "gt", kind: "message", T: Timestamp, oneof: "greater_than" },
    { no: 6, name: "gte", kind: "message", T: Timestamp, oneof: "greater_than" },
    { no: 8, name: "gt_now", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "greater_than" },
    { no: 9, name: "within", kind: "message", T: Duration, opt: true },
    { no: 10, name: "example", kind: "message", T: Timestamp, repeated: true },
  ],
);

/**
 * `Violations` is a collection of `Violation` messages. This message type is returned by
 * protovalidate when a proto message fails to meet the requirements set by the `Constraint` validation rules.
 * Each individual violation is represented by a `Violation` message.
 *
 * @generated from message buf.validate.Violations
 */
export const Violations = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Violations",
  () => [
    { no: 1, name: "violations", kind: "message", T: Violation, repeated: true },
  ],
);

/**
 * `Violation` represents a single instance where a validation rule, expressed
 * as a `Constraint`, was not met. It provides information about the field that
 * caused the violation, the specific constraint that wasn't fulfilled, and a
 * human-readable error message.
 *
 * ```json
 * {
 *   "fieldPath": "bar",
 *   "constraintId": "foo.bar",
 *   "message": "bar must be greater than 0"
 * }
 * ```
 *
 * @generated from message buf.validate.Violation
 */
export const Violation = /*@__PURE__*/ proto2.makeMessageType(
  "buf.validate.Violation",
  () => [
    { no: 1, name: "field_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "constraint_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "for_key", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * Rules specify the validations to be performed on this message. By default,
 * no validation is performed against a message.
 *
 * @generated from extension: optional buf.validate.MessageConstraints message = 1159;
 */
export const message = proto2.makeExtension(
  "buf.validate.message", 
  MessageOptions, 
  () => ({ no: 1159, kind: "message", T: MessageConstraints, opt: true }),
);

/**
 * Rules specify the validations to be performed on this oneof. By default,
 * no validation is performed against a oneof.
 *
 * @generated from extension: optional buf.validate.OneofConstraints oneof = 1159;
 */
export const oneof = proto2.makeExtension(
  "buf.validate.oneof", 
  OneofOptions, 
  () => ({ no: 1159, kind: "message", T: OneofConstraints, opt: true }),
);

/**
 * Rules specify the validations to be performed on this field. By default,
 * no validation is performed against a field.
 *
 * @generated from extension: optional buf.validate.FieldConstraints field = 1159;
 */
export const field = proto2.makeExtension(
  "buf.validate.field", 
  FieldOptions, 
  () => ({ no: 1159, kind: "message", T: FieldConstraints, opt: true }),
);

/**
 * Specifies predefined rules. When extending a standard constraint message,
 * this adds additional CEL expressions that apply when the extension is used.
 *
 * ```proto
 * extend buf.validate.Int32Rules {
 *   bool is_zero [(buf.validate.predefined).cel = {
 *     id: "int32.is_zero",
 *     message: "value must be zero",
 *     expression: "!rule || this == 0",
 *   }];
 * }
 *
 * message Foo {
 *   int32 reserved = 1 [(buf.validate.field).int32.(is_zero) = true];
 * }
 * ```
 *
 * @generated from extension: optional buf.validate.PredefinedConstraints predefined = 1160;
 */
export const predefined = proto2.makeExtension(
  "buf.validate.predefined", 
  FieldOptions, 
  () => ({ no: 1160, kind: "message", T: PredefinedConstraints, opt: true }),
);

