<div bo-pill-toggle-bar>
  @for (item of items(); track item.fragment) {
    <a
      bo-pill-toggle-item
      [anchorId]="item.fragment"
      [fragment]="item.fragment"
      [horizontalAlign]="horizontalAlign()"
      [routerLink]="[]"
      [routerLinkActiveOptions]="{
        matrixParams: 'exact',
        queryParams: 'exact',
        paths: 'exact',
        fragment: 'exact',
      }"
      puScrollToAnchor
      routerLinkActive="active"
    >
      <div class="flex align-items-center">
        @if (item.icon) {
          <bo-svg class="mr-1" [size]="16" [unsafeSvgSource]="item.icon" />
        }
        {{ item.title }}
      </div>
    </a>
  }
</div>
