import type { PartialMessage } from '@bufbuild/protobuf';
import { Int64Range, SumRange, TimeRange } from '@pinup-grpc/base/filter_pb';
import { SumRange as StatSumRange } from '@pinup-grpc/pinup/stat/report/base/filtering_pb';
import { endOfDay, getTime, startOfDay } from 'date-fns';

import { stringToKopecks, toKopecks, toTimestamp } from '@bo/common';

export function toTimeRange(
  fromValue: Date | string | null | undefined,
  toValue: Date | string | null | undefined,
): TimeRange {
  const from = fromValue ? getTime(startOfDay(new Date(fromValue))) : null;
  const to = toValue ? getTime(endOfDay(new Date(toValue))) : null;

  return new TimeRange({
    from: toTimestamp(from) ?? undefined,
    to: toTimestamp(to) ?? undefined,
  });
}

export function toSumRange(
  from: string | null | undefined,
  to: string | null | undefined,
): SumRange {
  return new SumRange({
    from: stringToKopecks(from),
    to: stringToKopecks(to),
  });
}

export function toBaseSumRange(
  from: string | null | undefined,
  to: string | null | undefined,
): SumRange {
  const data: PartialMessage<SumRange> = {};
  if (from) {
    data.from = toKopecks(BigInt(from));
  }
  if (to) {
    data.from = toKopecks(BigInt(to));
  }

  return new SumRange(data);
}

// different SumRange proto obj from toSumRangeOrUndefined
export function toStatSumRangeWithCurrency(
  from: string | null | undefined,
  to: string | null | undefined,
  currency: string | null | undefined,
): StatSumRange {
  const data: PartialMessage<StatSumRange> = {};
  if (currency) {
    data.currency = currency;
  }
  if (from) {
    data.from = toKopecks(BigInt(from));
  }
  if (to) {
    data.to = toKopecks(BigInt(to));
  }

  return new StatSumRange(data);
}

export function toSumRangeWithoutKopecks(
  from: string | null | undefined,
  to: string | null | undefined,
): SumRange {
  const data: PartialMessage<SumRange> = {};

  if (from) {
    data.from = BigInt(from);
  }
  if (to) {
    data.from = BigInt(to);
  }

  return new SumRange(data);
}

export function toInt64Range(
  from: string | null | undefined,
  to: string | null | undefined,
  currency: string | null | undefined,
): Int64Range {
  const range = new Int64Range();
  if (from) {
    range.from = stringToKopecks(from);
  }
  if (to) {
    range.to = stringToKopecks(to);
  }
  if (currency) {
    range.currency = currency;
  }

  return range;
}
