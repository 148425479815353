import { inject, Injectable } from '@angular/core';
import type { PartialMessage } from '@bufbuild/protobuf';
import type { ReactiveTransportOptions } from '@pinup/grpc-ng';
import { PlayerRemoteService as PlayerBackofficeService } from '@pinup-grpc/service/backoffice/player/player_ng';
import type { GetIsOneClickEnabledRequest } from '@pinup-grpc/service/backoffice/player/player_pb';
import { PlayerRemoteService as PlayerAnalysisService } from '@pinup-grpc/service/bo/players_ng';
import type { GetPlayerIntersectionsRequest } from '@pinup-grpc/service/bo/players_pb';
import { PlayerRemoteService } from '@pinup-grpc/services/bo/players/players_ng';
import type {
  GetPlayerRequest,
  Player,
} from '@pinup-grpc/services/bo/players/players_pb';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';

@Injectable()
export class StatusesProviderService {
  private readonly playerRemoteService = inject(PlayerRemoteService);
  private readonly playerAnalysisService = inject(PlayerAnalysisService);
  private readonly playerBackofficeService = inject(PlayerBackofficeService);

  public getStatuses(
    input: PartialMessage<GetPlayerRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<Player> {
    return this.playerRemoteService
      .getPlayer(input, options)
      .pipe(map((data) => data.player));
  }

  public getPlayerIntersections(
    input: PartialMessage<GetPlayerIntersectionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<string> {
    return this.playerAnalysisService
      .getPlayerIntersections(input, options)
      .pipe(map((data) => data.count.toString()));
  }

  public getIsOneClickEnabled(
    input: PartialMessage<GetIsOneClickEnabledRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<boolean> {
    return this.playerBackofficeService
      .getIsOneClickEnabled(input, options)
      .pipe(map((data) => data.isEnabled));
  }
}
