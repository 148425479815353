<form
  suspense-area
  (ngSubmit)="
    suspense.run(
      playerRemoteService.toggleAutoWithdrawal({
        playerId: data.playerId,
        isOn: on(),
        comment: comment(),
        isNotePinned: pinned(),
      })
    )
  "
  puDialogSuspense
  puInertSuspense
  puSuspense
  #form="ngForm"
  #suspense="puSuspense"
>
  <div mat-dialog-title>
    <div class="header">
      <bo-svg class="icon" [size]="24" [unsafeSvgSource]="withdrawalIcon" />
      <div class="header-text">Auto withdrawal</div>
      <mat-slide-toggle class="bo-slide-toggle" [(ngModel)]="on" name="on" />
    </div>
  </div>
  @if (on()) {
    <pu-dialog-content>
      <!--  TODO add history when will be ready-->
      <mat-form-field class="field">
        <mat-label>Note</mat-label>
        <input
          [(ngModel)]="comment"
          [maxlength]="maxLength"
          [puStrictMinLength]="minLength"
          matInput
          name="note"
          placeholder="Some text"
          #commentField="ngModel"
        />

        @if (commentField | validationError; as error) {
          <mat-error>{{ error }}</mat-error>
        }
      </mat-form-field>
      <mat-checkbox [(ngModel)]="pinned" name="pinned">Pin note</mat-checkbox>
    </pu-dialog-content>
  }

  <mat-dialog-actions align="end">
    <button
      mat-button
      class="button"
      [disabled]="suspense.loading()"
      matDialogClose
      type="button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="suspense.loading() || !on() || form.invalid"
      color="primary"
      type="submit"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
