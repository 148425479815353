import type { Document } from '@pinup-grpc/service/backoffice/documents/documents_pb';

import { documentSideMap } from '../configs/document-verification';
import type { FileFromDocument } from '../models/document-verification';

export function filesFromDocument(document: Document): FileFromDocument[] {
  return Object.entries(documentSideMap)
    .filter(([fileSideName]) => document[fileSideName as keyof Document])
    .map(([fileSideName, documentSide]) => ({
      documentSide,
      fileName: document[fileSideName as keyof Document] as string,
    }));
}
