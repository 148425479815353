// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/admin/enum.proto (package stat.bo.admin, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum stat.bo.admin.Gender
 */
export const Gender = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.Gender",
  [
    {no: 0, name: "GENDER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GENDER_MALE", localName: "MALE"},
    {no: 2, name: "GENDER_FEMALE", localName: "FEMALE"},
  ],
);

/**
 * CasinoStatus is a player's casino status
 *
 * @generated from enum stat.bo.admin.CasinoStatus
 */
export const CasinoStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.CasinoStatus",
  [
    {no: 0, name: "CASINO_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CASINO_STATUS_C1", localName: "C1"},
    {no: 2, name: "CASINO_STATUS_C2", localName: "C2"},
    {no: 3, name: "CASINO_STATUS_C3", localName: "C3"},
    {no: 4, name: "CASINO_STATUS_C4", localName: "C4"},
    {no: 5, name: "CASINO_STATUS_CA", localName: "CA"},
    {no: 6, name: "CASINO_STATUS_CN", localName: "CN"},
    {no: 7, name: "CASINO_STATUS_CVIP", localName: "CVIP"},
    {no: 8, name: "CASINO_STATUS_CZ", localName: "CZ"},
  ],
);

/**
 * BettingStatus is a player's betting status
 *
 * @generated from enum stat.bo.admin.BettingStatus
 */
export const BettingStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.BettingStatus",
  [
    {no: 0, name: "BETTING_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BETTING_STATUS_B1", localName: "B1"},
    {no: 2, name: "BETTING_STATUS_B2", localName: "B2"},
    {no: 3, name: "BETTING_STATUS_B3", localName: "B3"},
    {no: 4, name: "BETTING_STATUS_B4", localName: "B4"},
    {no: 5, name: "BETTING_STATUS_B5", localName: "B5"},
    {no: 6, name: "BETTING_STATUS_BA", localName: "BA"},
    {no: 7, name: "BETTING_STATUS_BN", localName: "BN"},
    {no: 8, name: "BETTING_STATUS_BVIP", localName: "BVIP"},
    {no: 9, name: "BETTING_STATUS_BZ", localName: "BZ"},
  ],
);

/**
 * ActivityStatus when player last logged in
 *
 * @generated from enum stat.bo.admin.ActivityStatus
 */
export const ActivityStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.ActivityStatus",
  [
    {no: 0, name: "ACTIVITY_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACTIVITY_STATUS_A1", localName: "A1"},
    {no: 2, name: "ACTIVITY_STATUS_A2", localName: "A2"},
    {no: 3, name: "ACTIVITY_STATUS_A3", localName: "A3"},
    {no: 4, name: "ACTIVITY_STATUS_AN", localName: "AN"},
  ],
);

/**
 * ActivityStatus when player last logged in
 *
 * @generated from enum stat.bo.admin.DepositStatus
 */
export const DepositStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.DepositStatus",
  [
    {no: 0, name: "DEPOSIT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEPOSIT_STATUS_D1", localName: "D1"},
    {no: 2, name: "DEPOSIT_STATUS_D2", localName: "D2"},
    {no: 3, name: "DEPOSIT_STATUS_D3", localName: "D3"},
    {no: 4, name: "DEPOSIT_STATUS_D4", localName: "D4"},
    {no: 5, name: "DEPOSIT_STATUS_D5", localName: "D5"},
    {no: 6, name: "DEPOSIT_STATUS_DN", localName: "DN"},
  ],
);

/**
 * @generated from enum stat.bo.admin.PrivilegeStatus
 */
export const PrivilegeStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.PrivilegeStatus",
  [
    {no: 0, name: "PRIVILEGE_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRIVILEGE_STATUS_P0", localName: "P0"},
    {no: 2, name: "PRIVILEGE_STATUS_P1", localName: "P1"},
    {no: 3, name: "PRIVILEGE_STATUS_P2", localName: "P2"},
    {no: 4, name: "PRIVILEGE_STATUS_P3", localName: "P3"},
    {no: 5, name: "PRIVILEGE_STATUS_P4", localName: "P4"},
    {no: 6, name: "PRIVILEGE_STATUS_P5", localName: "P5"},
    {no: 7, name: "PRIVILEGE_STATUS_P6", localName: "P6"},
    {no: 8, name: "PRIVILEGE_STATUS_P7", localName: "P7"},
    {no: 9, name: "PRIVILEGE_STATUS_P8", localName: "P8"},
    {no: 10, name: "PRIVILEGE_STATUS_P9", localName: "P9"},
    {no: 11, name: "PRIVILEGE_STATUS_P10", localName: "P10"},
    {no: 12, name: "PRIVILEGE_STATUS_PN", localName: "PN"},
  ],
);

/**
 * @generated from enum stat.bo.admin.MaStatus
 */
export const MaStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.MaStatus",
  [
    {no: 0, name: "MA_STATUS_UNSPECIFIED"},
    {no: 1, name: "MA_STATUS_1"},
    {no: 2, name: "MA_STATUS_2"},
  ],
);

/**
 * @generated from enum stat.bo.admin.SmsStatus
 */
export const SmsStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.SmsStatus",
  [
    {no: 0, name: "SMS_STATUS_UNSPECIFIED"},
    {no: 1, name: "SMS_STATUS_1"},
    {no: 2, name: "SMS_STATUS_2"},
    {no: 3, name: "SMS_STATUS_3"},
    {no: 4, name: "SMS_STATUS_4"},
    {no: 5, name: "SMS_STATUS_5"},
    {no: 6, name: "SMS_STATUS_6"},
  ],
);

/**
 * @generated from enum stat.bo.admin.TwoFactorVerification
 */
export const TwoFactorVerification = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.TwoFactorVerification",
  [
    {no: 0, name: "TFV_UNSPECIFIED"},
    {no: 1, name: "TFV_ON"},
    {no: 2, name: "TFV_OFF"},
  ],
);

/**
 * @generated from enum stat.bo.admin.CertifiedState
 */
export const CertifiedState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.CertifiedState",
  [
    {no: 0, name: "CERTIFIED_STATUS_NOT_CERTIFIED"},
    {no: 1, name: "CERTIFIED_STATUS_CERTIFIED"},
    {no: 2, name: "CERTIFIED_STATUS_UNCERTIFIED_MANUALLY"},
    {no: 3, name: "CERTIFIED_STATUS_CERTIFIED_MANUALLY"},
  ],
);

/**
 * @generated from enum stat.bo.admin.AccountVerifyState
 */
export const AccountVerifyState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.AccountVerifyState",
  [
    {no: 0, name: "ACCOUNT_VERIFY_NO"},
    {no: 1, name: "ACCOUNT_VERIFY_YES"},
    {no: 2, name: "ACCOUNT_VERIFY_IN_PROGRESS"},
    {no: 3, name: "ACCOUNT_VERIFY_NO_FINAL"},
    {no: 4, name: "ACCOUNT_VERIFY_NO_RETRY"},
  ],
);

/**
 * @generated from enum stat.bo.admin.AccountVerifySource
 */
export const AccountVerifySource = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.AccountVerifySource",
  [
    {no: 0, name: "ACCOUNT_VERIFY_SOURCE_UNSPECIFIED"},
    {no: 1, name: "ACCOUNT_VERIFY_SOURCE_1"},
    {no: 2, name: "ACCOUNT_VERIFY_SOURCE_2"},
  ],
);

/**
 * 0 - не проверен, 1 - существует, 2 - не существует
 *
 * @generated from enum stat.bo.admin.EmailCheckState
 */
export const EmailCheckState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.EmailCheckState",
  [
    {no: 0, name: "EMAIL_CHECK_UNSPECIFIED"},
    {no: 1, name: "EMAIL_CHECK_EXISTS"},
    {no: 2, name: "EMAIL_CHECK_NOT_EXISTS"},
  ],
);

/**
 * @generated from enum stat.bo.admin.PhoneCheckedState
 */
export const PhoneCheckedState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.PhoneCheckedState",
  [
    {no: 0, name: "PHONE_CHECKED_UNSPECIFIED"},
    {no: 1, name: "PHONE_CHECKED_CONFIRMED"},
  ],
);

/**
 * All, true, casino_true, sport_true
 *
 * @generated from enum stat.bo.admin.HaveBets
 */
export const HaveBets = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.HaveBets",
  [
    {no: 0, name: "ALL"},
    {no: 1, name: "TRUE"},
    {no: 2, name: "CASINO"},
    {no: 3, name: "SPORT"},
  ],
);

