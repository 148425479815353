// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/playerProfilePermissions.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetEmailChangePermissionRequest, GetEmailChangePermissionResponse, GetPhoneChangePermissionRequest, GetPhoneChangePermissionResponse, IncreaseEmailCounterRequest, IncreaseEmailCounterResponse, IncreasePhoneCounterRequest, IncreasePhoneCounterResponse, SetEmailChangePermissionRequest, SetEmailChangePermissionResponse, SetPhoneChangePermissionRequest, SetPhoneChangePermissionResponse } from "./playerProfilePermissions_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service players.PlayerProfilePermissions
 */
@Injectable({ providedIn: "root" })
export class PlayerProfilePermissionsRemoteService {
  public static readonly typeName = "players.PlayerProfilePermissions";

  public static readonly methods = {
    getEmailChangePermission: {
      name: "GetEmailChangePermission",
      I: GetEmailChangePermissionRequest,
      O: GetEmailChangePermissionResponse,
      kind: MethodKind.Unary,
    },
    increaseEmailCounter: {
      name: "IncreaseEmailCounter",
      I: IncreaseEmailCounterRequest,
      O: IncreaseEmailCounterResponse,
      kind: MethodKind.Unary,
    },
    getPhoneChangePermission: {
      name: "GetPhoneChangePermission",
      I: GetPhoneChangePermissionRequest,
      O: GetPhoneChangePermissionResponse,
      kind: MethodKind.Unary,
    },
    increasePhoneCounter: {
      name: "IncreasePhoneCounter",
      I: IncreasePhoneCounterRequest,
      O: IncreasePhoneCounterResponse,
      kind: MethodKind.Unary,
    },
    setPhoneChangePermission: {
      name: "SetPhoneChangePermission",
      I: SetPhoneChangePermissionRequest,
      O: SetPhoneChangePermissionResponse,
      kind: MethodKind.Unary,
    },
    setEmailChangePermission: {
      name: "SetEmailChangePermission",
      I: SetEmailChangePermissionRequest,
      O: SetEmailChangePermissionResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * -----------------------------------
   * GetPlayerProfilePermissions returns player profile permissions
   * -----------------------------------
   * Format: proto
   * Access:
   *   GRPC Request:
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc players.PlayerProfilePermissions.GetEmailChangePermission
   */
  public getEmailChangePermission(
    input: PartialMessage<GetEmailChangePermissionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetEmailChangePermissionResponse> {
    return unary<GetEmailChangePermissionRequest, GetEmailChangePermissionResponse>(
      this.transport,
      PlayerProfilePermissionsRemoteService,
      PlayerProfilePermissionsRemoteService.methods.getEmailChangePermission,
      input,
      options,
    );
  }

  /**
   * -----------------------------------
   * IncreaseEmailCounter update email counter for player in profile
   * -----------------------------------
   * Format: proto
   * Access:
   *   GRPC Request:
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc players.PlayerProfilePermissions.IncreaseEmailCounter
   */
  public increaseEmailCounter(
    input: PartialMessage<IncreaseEmailCounterRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<IncreaseEmailCounterResponse> {
    return unary<IncreaseEmailCounterRequest, IncreaseEmailCounterResponse>(
      this.transport,
      PlayerProfilePermissionsRemoteService,
      PlayerProfilePermissionsRemoteService.methods.increaseEmailCounter,
      input,
      options,
    );
  }

  /**
   * -----------------------------------
   * IncreasePhoneCounter update phone counter for player in profile
   * -----------------------------------
   * Format: proto
   * Access:
   *   GRPC Request:
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc players.PlayerProfilePermissions.GetPhoneChangePermission
   */
  public getPhoneChangePermission(
    input: PartialMessage<GetPhoneChangePermissionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPhoneChangePermissionResponse> {
    return unary<GetPhoneChangePermissionRequest, GetPhoneChangePermissionResponse>(
      this.transport,
      PlayerProfilePermissionsRemoteService,
      PlayerProfilePermissionsRemoteService.methods.getPhoneChangePermission,
      input,
      options,
    );
  }

  /**
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc players.PlayerProfilePermissions.IncreasePhoneCounter
   */
  public increasePhoneCounter(
    input: PartialMessage<IncreasePhoneCounterRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<IncreasePhoneCounterResponse> {
    return unary<IncreasePhoneCounterRequest, IncreasePhoneCounterResponse>(
      this.transport,
      PlayerProfilePermissionsRemoteService,
      PlayerProfilePermissionsRemoteService.methods.increasePhoneCounter,
      input,
      options,
    );
  }

  /**
   * -----------------------------------
   * SetPhoneChangePermission update phone counter if by available = true to 0 or if by available = false  to  max change
   * -----------------------------------
   * Format: proto
   * Access:
   *   GRPC Request:
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc players.PlayerProfilePermissions.SetPhoneChangePermission
   */
  public setPhoneChangePermission(
    input: PartialMessage<SetPhoneChangePermissionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetPhoneChangePermissionResponse> {
    return unary<SetPhoneChangePermissionRequest, SetPhoneChangePermissionResponse>(
      this.transport,
      PlayerProfilePermissionsRemoteService,
      PlayerProfilePermissionsRemoteService.methods.setPhoneChangePermission,
      input,
      options,
    );
  }

  /**
   * -----------------------------------
   * SetEmailChangePermission update email counter if by available = true to 0 or if by available = false  to  max change
   * -----------------------------------
   * Format: proto
   * Access:
   *   GRPC Request:
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc players.PlayerProfilePermissions.SetEmailChangePermission
   */
  public setEmailChangePermission(
    input: PartialMessage<SetEmailChangePermissionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetEmailChangePermissionResponse> {
    return unary<SetEmailChangePermissionRequest, SetEmailChangePermissionResponse>(
      this.transport,
      PlayerProfilePermissionsRemoteService,
      PlayerProfilePermissionsRemoteService.methods.setEmailChangePermission,
      input,
      options,
    );
  }
}
