import type { Signal } from '@angular/core';
import { inject } from '@angular/core';

import { GTM_CLIENT } from '@bo/common';

import type { GtmEventPayload } from './client/gtm-client.model';
import { GtmAreaDirective } from './gtm-area.directive';
import { GtmPageDirective } from './gtm-page.directive';

export abstract class GtmEventBaseDirective {
  private readonly gtmClient = inject(GTM_CLIENT);

  protected readonly pageFromContext = inject(GtmPageDirective, {
    optional: true,
  });
  protected readonly areaFromContext = inject(GtmAreaDirective, {
    optional: true,
  });

  public abstract readonly event: Signal<string>;
  public abstract readonly page: Signal<string | undefined>;
  public abstract readonly area: Signal<string | undefined>;
  public abstract readonly element: Signal<string | undefined>;

  protected send(): void {
    const page = this.page() ?? this.pageFromContext?.name();

    if (!page) {
      throw new Error('GTM Page is not provided !');
    }

    const area = this.area() ?? this.areaFromContext?.name();

    if (!area) {
      throw new Error('GTM Are is not provided !');
    }

    const payload: GtmEventPayload = {
      event: page,
      eventCategory: area,
      eventAction: this.event(),
    };

    const element = this.element();

    if (element) {
      payload.elementName = element;
    }

    this.gtmClient.send(payload);
  }
}
