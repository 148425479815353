import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SvgComponent } from '@pu/sdk';

import { arrowUpRightIcon } from '../../icons';

@Component({
  selector: 'a[bo-external-link]',
  standalone: true,
  imports: [SvgComponent],
  templateUrl: './external-link.component.html',
  styleUrl: './external-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'default',
  },
})
export class ExternalLinkComponent {
  protected readonly arrowUpRightIcon = arrowUpRightIcon;
}
