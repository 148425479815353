// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/operators/limits.proto (package payments.backoffice.operators.limits, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum payments.backoffice.operators.limits.OperatorLimitError
 */
export const OperatorLimitError = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.operators.limits.OperatorLimitError",
  [
    {no: 0, name: "OLE_UNSPECIFIED"},
    {no: 1, name: "OLE_LIMIT_NOT_FOUND"},
    {no: 2, name: "OLE_LIMIT_HAS_BEEN_UPDATED"},
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.OperatorLimits
 */
export const OperatorLimits = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.OperatorLimits",
  () => [
    { no: 1, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "addDeposit", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 3 /* ScalarType.INT64 */} },
    { no: 3, name: "acceptWithdrawal", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 3 /* ScalarType.INT64 */} },
    { no: 4, name: "createdAt", kind: "message", T: Timestamp },
    { no: 5, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.OperatorLimitsInput
 */
export const OperatorLimitsInput = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.OperatorLimitsInput",
  () => [
    { no: 1, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "addDeposit", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 3 /* ScalarType.INT64 */} },
    { no: 3, name: "acceptWithdrawal", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 3 /* ScalarType.INT64 */} },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.GetOperatorLimitsListRequest
 */
export const GetOperatorLimitsListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.GetOperatorLimitsListRequest",
  [],
);

/**
 * @generated from message payments.backoffice.operators.limits.GetOperatorLimitsListResponse
 */
export const GetOperatorLimitsListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.GetOperatorLimitsListResponse",
  () => [
    { no: 1, name: "list", kind: "message", T: OperatorLimits, repeated: true },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.GetOperatorLimitsByGroupRequest
 */
export const GetOperatorLimitsByGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.GetOperatorLimitsByGroupRequest",
  () => [
    { no: 1, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.GetOperatorLimitsByGroupResponse
 */
export const GetOperatorLimitsByGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.GetOperatorLimitsByGroupResponse",
  () => [
    { no: 1, name: "limits", kind: "message", T: OperatorLimits },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.UpsertOperatorLimitsRequest
 */
export const UpsertOperatorLimitsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.UpsertOperatorLimitsRequest",
  () => [
    { no: 1, name: "limits", kind: "message", T: OperatorLimitsInput },
  ],
);

/**
 * Error reasons: OLE_LIMIT_HAS_BEEN_UPDATED
 *
 * @generated from message payments.backoffice.operators.limits.UpsertOperatorLimitsResponse
 */
export const UpsertOperatorLimitsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.UpsertOperatorLimitsResponse",
  () => [
    { no: 1, name: "limits", kind: "message", T: OperatorLimits },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.DeleteOperatorLimitsByGroupRequest
 */
export const DeleteOperatorLimitsByGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.DeleteOperatorLimitsByGroupRequest",
  () => [
    { no: 1, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.backoffice.operators.limits.DeleteOperatorLimitsByGroupResponse
 */
export const DeleteOperatorLimitsByGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.operators.limits.DeleteOperatorLimitsByGroupResponse",
  [],
);

