@if (accessPayload$ | async; as user) {
  <div class="user-bar">
    <a class="user-link" [routerLink]="['profile', currentUserId()]">
      <img
        class="user-picture"
        [ngSrc]="user.picture"
        alt="profile picture"
        height="40"
        width="40"
      />
      @if (!collapsed()) {
        <div>
          <div class="full-name">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div>{{ user.email }}</div>
        </div>
      }
    </a>

    <bo-svg
      class="logout"
      (click)="logout()"
      [unsafeSvgSource]="downloadIcon"
    />
  </div>
}
