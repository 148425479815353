import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { MaskedOptions } from '@pu/sdk';

@Pipe({
  name: 'stringifyMasked',
  standalone: true,
})
export class StringifyMaskedPipe implements PipeTransform {
  public transform<T>(
    value: T | null,
    { stringify }: MaskedOptions<T>,
  ): string {
    return value === null ? '' : stringify(value);
  }
}
