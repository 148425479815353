import { inject, Injectable } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import { SimpleAuthRemoteService } from '@pinup-grpc/pinup/google_auth/simple/service_ng';
import type { Observable } from 'rxjs';
import { catchError, map, of, share, Subject, switchMap } from 'rxjs';

import {
  AccessGroup,
  DepartmentLicenseLink,
  parsePinupAccessJwt,
  PATHNAME_PREFIX,
  STORED_SESSION,
  SUPPORTED_DEPARTMENT_LICENSE_LINKS,
  SUPPORTED_DEPARTMENTS,
  SUPPORTED_LICENSES,
} from '@bo/common';

type SignInState =
  | { type: 'access-denied'; link: DepartmentLicenseLink }
  | { type: 'error'; error: string }
  | { type: 'invalid-url'; url: string }
  | { type: 'no-options-available' }
  | { type: 'redirection'; link: DepartmentLicenseLink }
  | { type: 'unauthorized'; link: DepartmentLicenseLink | null };

@Injectable({ providedIn: 'root' })
export class SignInService {
  private readonly links = inject(SUPPORTED_DEPARTMENT_LICENSE_LINKS);
  private readonly location = inject(LOCATION);
  private readonly licenses = inject(SUPPORTED_LICENSES);
  private readonly departments = inject(SUPPORTED_DEPARTMENTS);
  private readonly prefix = inject(PATHNAME_PREFIX);

  private readonly storedSession$ = inject(STORED_SESSION);

  private readonly simpleAuth = inject(SimpleAuthRemoteService);

  private readonly logIn$ = new Subject<
    google.accounts.id.CredentialResponse['credential']
  >();

  public readonly state$: Observable<SignInState>;

  constructor() {
    this.state$ = this.logIn$.pipe(
      switchMap((token) => this.simpleAuth.signIn({ token })),
      share({ connector: () => this.storedSession$ }),
      map((session) => {
        const url = new URLSearchParams(this.location.search).get('url');

        let link: DepartmentLicenseLink | null = null;

        if (url !== null) {
          link = DepartmentLicenseLink.parse(
            this.departments,
            this.licenses,
            url,
          );

          if (link === null) {
            return { type: 'invalid-url', url } satisfies SignInState;
          }
        }

        if (session === null) {
          return { type: 'unauthorized', link } satisfies SignInState;
        }

        const groups: readonly AccessGroup[] = parsePinupAccessJwt(
          session.access,
        ).groups.map<AccessGroup>((raw) => AccessGroup.parse(raw));

        if (link !== null) {
          if (link.available(groups)) {
            this.location.replace(`${this.prefix}${link.href}`);
            return { type: 'redirection', link } satisfies SignInState;
          } else {
            return { type: 'access-denied', link } satisfies SignInState;
          }
        }

        groups: for (const group of groups) {
          for (const item of this.links) {
            if (group.supports(item.department, item.license)) {
              link = item;
              break groups;
            }
          }
        }

        if (link === null) {
          return { type: 'no-options-available' } satisfies SignInState;
        }

        this.location.replace(`${this.prefix}${link.href}`);
        return { type: 'redirection', link } satisfies SignInState;
      }),
      catchError((error) =>
        of<SignInState>({ type: 'error', error: String(error) }),
      ),
    );
  }

  public logIn(
    credential: google.accounts.id.CredentialResponse['credential'],
  ): void {
    this.logIn$.next(credential);
  }

  public logOut(): void {
    this.storedSession$.next(null);
  }
}
