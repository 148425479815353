// @generated by protoc-gen-es v1.10.0
// @generated from file base/error.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message stat.Error
 */
export const Error = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Error",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

