import type { ApplicationConfig } from '@angular/core';

import { provideValue } from '@pu/sdk';
import { GOOGLE_SIGN_IN_CLIENT_ID } from '@pu/sdk/google';

export const appConfigSignIn: ApplicationConfig = {
  providers: [
    provideValue(
      GOOGLE_SIGN_IN_CLIENT_ID,
      '605507710056-6otrcvt6oku9am5klorkqevisvuno5tk.apps.googleusercontent.com',
    ),
  ],
};
