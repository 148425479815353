import { Directive, inject } from '@angular/core';

import { extractEnumValues } from '@pu/sdk';
import { DocumentsManagementService } from '@bo/common';

enum Column {
  TYPE = 'type',
  FILES = 'files',
  CREATED_AT = 'createdAt',
}

@Directive()
export class DocumentsTableComponent {
  private readonly documentsManagementService = inject(
    DocumentsManagementService,
  );

  protected readonly documents$ = this.documentsManagementService.documents$;
  protected readonly hasError$ = this.documentsManagementService.hasError$;
  protected readonly isLoading$ = this.documentsManagementService.isLoading$;
  protected readonly hasData$ = this.documentsManagementService.hasData$;

  protected readonly Column = Column;
  protected readonly columns = [...extractEnumValues(Column)];
}
