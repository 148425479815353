// @generated by protoc-gen-es v1.10.0
// @generated from file models/moneyout/reason.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum payments.MoneyOutReason
 */
export const MoneyOutReason = /*@__PURE__*/ proto3.makeEnum(
  "payments.MoneyOutReason",
  [
    {no: 0, name: "MONEY_OUT_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MONEY_OUT_REASON_AUTO_TURNED_OFF", localName: "AUTO_TURNED_OFF"},
    {no: 2, name: "MONEY_OUT_REASON_AUTO_TURNED_OFF_FOR_AMOUNT", localName: "AUTO_TURNED_OFF_FOR_AMOUNT"},
    {no: 3, name: "MONEY_OUT_REASON_AMOUNT_OUT_OF_RANGE", localName: "AMOUNT_OUT_OF_RANGE"},
    {no: 4, name: "MONEY_OUT_REASON_PLAYER_NOT_FOUND", localName: "PLAYER_NOT_FOUND"},
    {no: 5, name: "MONEY_OUT_REASON_PLAYER_PROFILE_NOT_FILLED", localName: "PLAYER_PROFILE_NOT_FILLED"},
    {no: 6, name: "MONEY_OUT_REASON_PLAYER_NO_VERIFICATION", localName: "PLAYER_NO_VERIFICATION"},
    {no: 7, name: "MONEY_OUT_REASON_PLAYER_EMAIL_NOT_VERIFIED", localName: "PLAYER_EMAIL_NOT_VERIFIED"},
    {no: 8, name: "MONEY_OUT_REASON_LAST_DEPOSIT_TURNOVER", localName: "LAST_DEPOSIT_TURNOVER"},
    {no: 9, name: "MONEY_OUT_REASON_TURNOVER", localName: "TURNOVER"},
    {no: 10, name: "MONEY_OUT_REASON_BET_COUNT_CASINO", localName: "BET_COUNT_CASINO"},
    {no: 11, name: "MONEY_OUT_REASON_BET_COUNT_SPORT", localName: "BET_COUNT_SPORT"},
    {no: 12, name: "MONEY_OUT_REASON_NO_BET_SINCE_LAST_DEPOSIT", localName: "NO_BET_SINCE_LAST_DEPOSIT"},
    {no: 13, name: "MONEY_OUT_REASON_LIMIT", localName: "LIMIT"},
    {no: 14, name: "MONEY_OUT_REASON_BLOCK_CASINO_STATUS", localName: "BLOCK_CASINO_STATUS"},
    {no: 15, name: "MONEY_OUT_REASON_BLOCK_SPORT_STATUS", localName: "BLOCK_SPORT_STATUS"},
    {no: 16, name: "MONEY_OUT_REASON_NOT_SAME_SYSTEM_NAME", localName: "NOT_SAME_SYSTEM_NAME"},
    {no: 17, name: "MONEY_OUT_REASON_AUTO_OFF_FAST", localName: "AUTO_OFF_FAST"},
    {no: 18, name: "MONEY_OUT_REASON_AUTO_OFF_ALL_IN_FIRST_DEP", localName: "AUTO_OFF_ALL_IN_FIRST_DEP"},
    {no: 19, name: "MONEY_OUT_REASON_AUTO_OFF_SUSPICIOUS_SESSIONS", localName: "AUTO_OFF_SUSPICIOUS_SESSIONS"},
    {no: 20, name: "MONEY_OUT_REASON_AUTO_OFF_ANTI_FRAUD_REJECTION", localName: "AUTO_OFF_ANTI_FRAUD_REJECTION"},
    {no: 21, name: "MONEY_OUT_REASON_AUTO_OFF_VERIFICATION_REJECTION", localName: "AUTO_OFF_VERIFICATION_REJECTION"},
    {no: 22, name: "MONEY_OUT_REASON_TOTAL_DAILY_AMOUNT_LIMIT_EXCEEDED", localName: "TOTAL_DAILY_AMOUNT_LIMIT_EXCEEDED"},
    {no: 23, name: "MONEY_OUT_REASON_LAST_DEPOSIT_RISK_REVIEW", localName: "LAST_DEPOSIT_RISK_REVIEW"},
    {no: 24, name: "MONEY_OUT_REASON_PLAYER_NOT_VERIFIED", localName: "PLAYER_NOT_VERIFIED"},
    {no: 25, name: "MONEY_OUT_REASON_CREDIT_CART_CONFIRM_OWNERSHIP", localName: "CREDIT_CART_CONFIRM_OWNERSHIP"},
    {no: 26, name: "MONEY_OUT_REASON_FIRST_MANUAL", localName: "FIRST_MANUAL"},
    {no: 27, name: "MONEY_OUT_REASON_PAYMENT_METHOD_BLOCKED_FOR_AUTO", localName: "PAYMENT_METHOD_BLOCKED_FOR_AUTO"},
    {no: 28, name: "MONEY_OUT_REASON_VALIDATION_ERROR", localName: "VALIDATION_ERROR"},
  ],
);

