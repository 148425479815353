import type { Provider } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';

import { type PersistentData, provideDataPersistence } from '@bo/common';

import type {
  FiltersProvider,
  FiltersProviderSettings,
} from './filters-provider.model';

export const DATA_PERSISTENCE_FOR_FILTERS_PROVIDER = new InjectionToken<
  Observable<PersistentData>
>('DATA_PERSISTENCE_FOR_FILTERS_PROVIDER');

export const FILTERS_PROVIDER = new InjectionToken<FiltersProvider>(
  'FILTERS_PROVIDER',
);

const defaultSettings: Required<FiltersProviderSettings> = {
  usePersistence: true,
  persistenceStrategy: 'route-params',
};

export const provideFiltersProvider = (
  Provider: new () => FiltersProvider,
  settings: FiltersProviderSettings = {},
): Provider[] => {
  const { usePersistence, persistenceStrategy } = {
    ...defaultSettings,
    ...settings,
  };
  const providers: Provider[] = [
    {
      provide: FILTERS_PROVIDER,
      useClass: Provider,
    },
  ];

  if (usePersistence) {
    const persistenceProviders = provideDataPersistence(
      DATA_PERSISTENCE_FOR_FILTERS_PROVIDER,
      persistenceStrategy,
    );

    providers.push(persistenceProviders);
  }

  return providers;
};
