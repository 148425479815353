// @generated by protoc-gen-es v1.10.0
// @generated from file service/crm/player/v1/player.proto (package gamification.service.crm.player.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * request to get player nickname
 *
 * @generated from message gamification.service.crm.player.v1.GetNicknameRequest
 */
export const GetNicknameRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.crm.player.v1.GetNicknameRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for get player nickname
 *
 * @generated from message gamification.service.crm.player.v1.GetNicknameResponse
 */
export const GetNicknameResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.crm.player.v1.GetNicknameResponse",
  () => [
    { no: 1, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * request to set default player nickname
 *
 * @generated from message gamification.service.crm.player.v1.SetDefaultNicknameRequest
 */
export const SetDefaultNicknameRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.crm.player.v1.SetDefaultNicknameRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for set default player nickname
 *
 * @generated from message gamification.service.crm.player.v1.SetDefaultNicknameResponse
 */
export const SetDefaultNicknameResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.crm.player.v1.SetDefaultNicknameResponse",
  () => [
    { no: 1, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

