import type { PipeTransform } from '@angular/core';
import { inject, Pipe } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { PlayerService } from '../../player/player.service';
import { OLD_ADMIN_URL } from '../old-admin.providers';
import { generateOldAdminLink } from './old-admin-link.utils';

@Pipe({
  name: 'boOldAdminProfileLink',
  standalone: true,
  pure: false,
})
export class OldAdminProfileLinkPipe implements PipeTransform {
  private readonly url = inject(OLD_ADMIN_URL);
  private readonly playerId = toSignal(inject(PlayerService).playerId$, {
    requireSync: true,
  });

  public transform(path: string): string {
    const baseUrl = generateOldAdminLink(this.url, path);
    const playerId = this.playerId();

    return `${baseUrl}/#${playerId}`;
  }
}
