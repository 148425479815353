// @generated by protoc-gen-es v1.10.0
// @generated from file service/bo/players.proto (package player_analysis.bo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message player_analysis.bo.GetPlayerIntersectionsRequest
 */
export const GetPlayerIntersectionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "player_analysis.bo.GetPlayerIntersectionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message player_analysis.bo.GetPlayerIntersectionsResponse
 */
export const GetPlayerIntersectionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "player_analysis.bo.GetPlayerIntersectionsResponse",
  () => [
    { no: 1, name: "intersections", kind: "message", T: PlayerIntersection, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message player_analysis.bo.PlayerIntersection
 */
export const PlayerIntersection = /*@__PURE__*/ proto3.makeMessageType(
  "player_analysis.bo.PlayerIntersection",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "rating", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

