import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import type { Sort } from '@angular/material/sort';
import { MatSortModule } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { map } from 'rxjs';

import { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_SessionType as SessionType } from '@pu/grpc/enums';
import { CurrentRouteNavigationService, extractEnumValues } from '@pu/sdk';

import { DateTimeComponent } from '../../date-time/date-time.component';
import { EmptyTemplateComponent } from '../../empty-template/empty-template.component';
import type { Pagination } from '../../pagination';
import {
  PAGINATION_PANEL_MANAGER,
  PaginationPanelComponent,
  PaginationPanelDirective,
  providePaginationPanel,
  withPersistence,
} from '../../pagination';
import {
  DEFAULT_ACTIVE_SESSIONS_DATA_SHOW,
  ProfileSessionsColumns,
} from './profile-sessions.const';
import { ProfileSessionsProviderService } from './profile-sessions.provide.service';
import { ProfileSessionsService } from './profile-sessions.service';

@Component({
  selector: 'bo-profile-sessions-page',
  standalone: true,
  imports: [
    AsyncPipe,
    DateTimeComponent,
    MatCell,
    EmptyTemplateComponent,
    PaginationPanelComponent,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    EmptyTemplateComponent,
    MatButton,
    MatSortModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    PaginationPanelDirective,
  ],
  providers: [
    ProfileSessionsService,
    ProfileSessionsProviderService,
    CurrentRouteNavigationService,
    providePaginationPanel(withPersistence()),
  ],
  templateUrl: './profile-sessions-page.component.html',
  styleUrl: './profile-sessions-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileAllSessionsPageComponent {
  public readonly profileSessionsService = inject(ProfileSessionsService);
  private readonly paginationManager = inject(PAGINATION_PANEL_MANAGER);

  public sortedActiveSessions$ =
    this.profileSessionsService.sortedActiveSessions$;
  public isAllActiveSessions$ =
    this.profileSessionsService.isAllActiveSessions$;
  public sortedInactiveSessions$ =
    this.profileSessionsService.sortedInactiveSessions$;

  protected activeSessionsTotal$ =
    this.profileSessionsService.activeSessionsTotal$;
  protected inactiveSessionsTotal$ =
    this.profileSessionsService.inactiveSessionsTotal$;
  protected pagination$ = this.paginationManager.source$;

  protected readonly sessionType = SessionType;
  protected readonly profileSessionsColumns = ProfileSessionsColumns;
  protected readonly iterableColumns = extractEnumValues(
    ProfileSessionsColumns,
  );

  protected shouldShowToggleBtn$ = this.activeSessionsTotal$.pipe(
    map(({ totalItems }) => totalItems > DEFAULT_ACTIVE_SESSIONS_DATA_SHOW.to),
  );

  public setPagination(pagination: Partial<Pagination>): void {
    this.paginationManager.update(pagination);
  }

  public toggleActiveSessions(): void {
    this.profileSessionsService.toggleActiveSession();
  }

  public setSort(type: SessionType, sort: Sort): void {
    this.profileSessionsService.setSort(type, sort);
  }
}
