<a class="logo-link" href="/">
  <img
    class="logo"
    alt="PIN-UP backoffice"
    height="56"
    src="assets/logo-backoffice.svg"
    width="142"
  />
</a>
@if (isRootLocation) {
  @if (signIn.state$ | async; as state) {
    @switch (state.type) {
      @case ('access-denied') {
        <h1>Access denied</h1>
        <p>
          No access to
          <strong>{{ state.link.fullTitle }}</strong>
          <ng-container>.</ng-container>
        </p>
        <button class="button" (click)="signIn.logOut()" type="button">
          Log out
        </button>
      }

      @case ('error') {
        <h1>Error</h1>
        <pre class="error">{{ state.error }}</pre>
      }

      @case ('invalid-url') {
        <h1>Invalid url</h1>
        <pre class="error">{{ state.url }}</pre>
        <a class="button" href="/">Go to Root</a>
      }

      @case ('no-options-available') {
        <h1>No options available</h1>
        <p>This user does not have access to anything.</p>
        <button class="button" (click)="signIn.logOut()" type="button">
          Log out
        </button>
      }

      @case ('redirection') {
        <h1>Redirection</h1>
        <p>
          <strong>{{ state.link.fullTitle }}</strong>
          <ng-container>.</ng-container>
        </p>
      }

      @case ('unauthorized') {
        <h1>Welcome to our platform</h1>

        @if (state.link !== null) {
          <p>
            Restore
            <strong>{{ state.link.fullTitle }}</strong>
            session.
          </p>
        }

        <google-sign-in-button
          class="google-login-button"
          text="continue_with"
          type="standard"
        />
      }
    }
  }
} @else {
  <h1>Department does not exist</h1>

  <p>Possible causes of the problem:</p>
  <ul>
    <li>Incorrect URL.</li>
    <li>Missing department.</li>
    <li>Incorrect server settings.</li>
  </ul>
  <a class="button" href="/">Go to Root</a>
}
