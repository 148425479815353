import type { Transaction } from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import type { PlayerShort } from '@pinup-grpc/services/bo/players/players_pb';

import type {
  ExtractPBMessagePayload,
  TransactionsFinance,
  TransactionStatus,
} from '@bo/common';

export type TransactionVM = ExtractPBMessagePayload<Transaction> &
  Readonly<{
    player?: PlayerShort;
    riskStatuses: RiskStatus[];
    status: TransactionStatus;
    finance: TransactionsFinance;
    convertFinance: TransactionsFinance;
  }>;

// TODO remove RiskStatus, ColorStatus when all this data will moved to bo-common,
//  for now it need 2 comp, that used more than in 25 files
export interface RiskStatus {
  status: string;
  colorStatus: ColorStatus;
}

export enum ColorStatus {
  Danger = 'danger',
  Warning = 'warning',
  Default = 'default',
}
