import type { RadioToggleOption } from '@bo/common';

export enum SwitchState {
  On = 'On',
  Off = 'Off',
}

export const onOffOptions: readonly RadioToggleOption<string, SwitchState>[] = [
  { name: 'On', value: SwitchState.On },
  { name: 'Off', value: SwitchState.Off },
];
