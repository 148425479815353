@if (!isLoading()) {
  @if (hasError()) {
    <ng-content select="[error]" />
  } @else {
    @if (hasData()) {
      <ng-content select="[data]" />
    } @else {
      <ng-content select="[empty]" />
    }
  }
} @else {
  <div class="flex justify-center">
    <mat-spinner [diameter]="50" />
  </div>
}
