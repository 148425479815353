import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs';

import { type PlayerNote, PlayerNotesLogsRemoteService } from '@pu/grpc';
import {
  DialogContentComponent,
  DialogSuspenseDirective,
  InertSuspenseDirective,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  ValidationErrorPipe,
} from '@pu/sdk';

export type EditNoteDialogData =
  | { mode: 'create'; playerId: bigint }
  | { mode: 'edit'; note: PlayerNote };

export const EDIT_NOTE_DIALOG_DATA = new InjectionToken<EditNoteDialogData>(
  'EDIT_NOTE_DIALOG_DATA',
);

@Component({
  selector: 'bo-edit-note-dialog',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    DialogSuspenseDirective,
    FormsModule,
    MatLabel,
    MatError,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    StrictMinLengthDirective,
    SuspenseAreaComponent,
    SuspenseDirective,
    MatDialogTitle,
    MatFormField,
    MatDialogClose,
    MatInput,
    ValidationErrorPipe,
    InertSuspenseDirective,
    DialogContentComponent,
  ],
  templateUrl: './edit-note-dialog.component.html',
  styleUrl: './edit-note-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditNoteDialogComponent {
  private readonly snackBar = inject(MatSnackBar);
  protected readonly data = inject(EDIT_NOTE_DIALOG_DATA);
  private readonly remoteService = inject(PlayerNotesLogsRemoteService);

  protected readonly note: Partial<PlayerNote> =
    this.data.mode === 'edit'
      ? { ...this.data.note }
      : { text: '', isPinned: false };

  protected send(): Observable<unknown> {
    return this.data.mode === 'edit'
      ? this.edit()
      : this.add(this.data.playerId);
  }

  protected add(playerId: bigint): Observable<unknown> {
    return this.remoteService
      .createPlayerNote({
        playerId,
        text: this.note.text,
        isPinned: this.note.isPinned,
      })
      .pipe(
        tap({
          complete: () => {
            this.snackBar.open('Note was added', 'OK', { duration: 5000 });
          },
        }),
      );
  }

  protected edit(): Observable<unknown> {
    return this.remoteService.editPlayerNote(this.note).pipe(
      tap({
        complete: () => {
          this.snackBar.open('Note was edited', 'OK', { duration: 5000 });
        },
      }),
    );
  }
}
