import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { identity, isDate, pickBy } from 'lodash-es';
import { map } from 'rxjs';

import { CurrentRouteNavigationService } from '@pu/sdk';
import type { TimeRangeFilter } from '@bo/common';
import { normalizeDates, toFormDateFromString } from '@bo/common';

@Injectable()
export class PlayersLogsFilterService {
  protected readonly navigationService = inject(CurrentRouteNavigationService);
  private readonly route = inject(ActivatedRoute);

  public dateRange$ = this.route.paramMap.pipe(
    map((paramMap) => {
      return {
        from: paramMap.get('from') || null,
        to: paramMap.get('to') || null,
      };
    }),
  );

  public dateRangeFormatted$ = this.dateRange$.pipe(
    map((dates) => {
      return {
        from: toFormDateFromString(dates.from) || null,
        to: toFormDateFromString(dates.to) || null,
      };
    }),
  );

  public changeDateRange(filters: TimeRangeFilter): void {
    const dates = pickBy(filters, isDate);
    const resultFilters = pickBy(normalizeDates(dates), identity);
    this.navigationService.setParams({ ...resultFilters }, true);
  }
}
