import { inject, Pipe, type PipeTransform } from '@angular/core';

import type { LicenseFeature } from './license';
import { License } from './license';

@Pipe({ name: 'licenseSupports', standalone: true })
export class LicenseSupportsPipe implements PipeTransform {
  private readonly license = inject(License);

  public transform(feature: LicenseFeature): boolean {
    return this.license.supports(feature);
  }
}
