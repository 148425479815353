import type {
  JsonReadOptions,
  JsonWriteStringOptions,
  Message,
  MessageType,
} from '@bufbuild/protobuf';
import type { SubjectLike, Subscriber, TeardownLogic } from 'rxjs';
import { Observable } from 'rxjs';

interface LocalStorageOptions extends JsonReadOptions, JsonWriteStringOptions {}

interface Value<T extends Message<T>> {
  readonly raw: string;
  readonly message: T;
}

// TODO use STORAGE_EVENT
export class LocalStorageSubject<T extends Message<T>>
  extends Observable<T | null>
  implements SubjectLike<T | null>
{
  private readonly key: string;

  private readonly type: MessageType<T>;

  private readonly config: Partial<LocalStorageOptions>;

  private readonly subscribers = new Set<Subscriber<T | null>>();

  private cache: Value<T> | null = null;

  constructor(
    key: string,
    type: MessageType<T>,
    config: Partial<LocalStorageOptions> = {},
  ) {
    super((subscriber) => this.onSubscribe(subscriber));
    this.key = key;
    this.type = type;
    this.config = config;
  }

  private readonly onStorage = (event: StorageEvent): void => {
    if (event.key !== this.key && event.key !== null) {
      return;
    }

    this.fireValue(this.getValue());
  };

  private fireValue(value: T | null): void {
    for (const subscriber of this.subscribers) {
      subscriber.next(value);
    }
  }

  private onSubscribe(subscriber: Subscriber<T | null>): TeardownLogic {
    if (this.subscribers.size === 0) {
      window.addEventListener('storage', this.onStorage);
    }

    this.subscribers.add(subscriber);
    subscriber.next(this.getValue());

    return () => {
      this.onUnsubscribe(subscriber);
    };
  }

  private onUnsubscribe(subscriber: Subscriber<T>): void {
    this.subscribers.delete(subscriber);

    if (this.subscribers.size === 0) {
      window.removeEventListener('storage', this.onStorage);
    }
  }

  public next(value: T | null): void {
    if (value === null) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, value.toJsonString(this.config));
    }

    this.fireValue(value);
  }

  public error(error: unknown): void {
    console.error('LocalStorageSubject', this.key, error);

    this.next(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public complete(): void {}

  public getValue(): T | null {
    const raw: string | null = localStorage.getItem(this.key);

    if (raw === null) {
      this.cache = null;
      return null;
    }

    if (this.cache !== null && this.cache.raw === raw) {
      return this.cache.message;
    }

    try {
      const message: T = this.type.fromJsonString(raw, this.config);

      this.cache = { raw, message };
      return message;
    } catch (error) {
      console.error('LocalStorageSubject', this.key, error);

      this.cache = null;
      return null;
    }
  }
}
