import { Project } from '@pinup-grpc/pinup/stat/report/base/enum_pb';

import { TransactionOperationType } from '@pu/grpc';
import type { License } from '@bo/common';

export const transactionTypeOptions: ReadonlyMap<
  TransactionOperationType,
  string
> = new Map([
  [TransactionOperationType.UNSPECIFIED, 'Unspecified'],
  [TransactionOperationType.DEPOSIT, 'Deposit'],
  [TransactionOperationType.WITHDRAWAL, 'Withdrawal'],
  [TransactionOperationType.UN_WITHDRAWAL, 'Unwithdrawal'],
  [TransactionOperationType.WRITE_BET, 'Bet'],
  [TransactionOperationType.FREE_BET_WIN, 'Free bet'],
  [TransactionOperationType.LOTTERY_WIN, 'Lottery win'],
  [TransactionOperationType.MARKETING_WIN, 'Marketing win'],
  [TransactionOperationType.BONUS_WIN, 'Bonus win'],
  [TransactionOperationType.CASHBACK, 'Cashback'],
  [TransactionOperationType.REVERT, 'Revert'],
]);

export function* getProjectTypeOptions(
  license: License,
): Iterable<{ key: Project; value: string }> {
  yield { key: 0, value: 'Unspecified' };
  if (license.supports('sport')) {
    yield { key: Project.SPORT, value: 'Sport' };
  }
  if (license.supports('casino')) {
    yield { key: Project.CASINO, value: 'Casino' };
  }
}

export function* getOperationTypeOptions(
  license: License,
): Iterable<{ key: TransactionOperationType; value: string }> {
  yield { key: TransactionOperationType.UNSPECIFIED, value: 'Unspecified' };
  yield { key: TransactionOperationType.DEPOSIT, value: 'Deposit' };
  yield { key: TransactionOperationType.WITHDRAWAL, value: 'Withdrawal' };
  yield { key: TransactionOperationType.UN_WITHDRAWAL, value: 'Unwithdrawal' };
  yield { key: TransactionOperationType.WRITE_BET, value: 'Bet' };

  if (license.supports('freebet')) {
    yield { key: TransactionOperationType.FREE_BET_WIN, value: 'Freebet' };
  }

  if (license.supports('lottery')) {
    yield { key: TransactionOperationType.LOTTERY_WIN, value: 'Lottery win' };
  }
  yield { key: TransactionOperationType.MARKETING_WIN, value: 'Marketing win' };
  yield { key: TransactionOperationType.BONUS_WIN, value: 'Bonus win' };
  yield { key: TransactionOperationType.CASHBACK, value: 'Cashback' };
  yield { key: TransactionOperationType.REVERT, value: 'Revert' };
}
