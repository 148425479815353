// @generated by protoc-gen-es v1.10.0
// @generated from file services/stat_totals.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { TimeRange } from "../base/filter_pb.js";
import { Total } from "../base/models_pb.js";
import { Error } from "../base/error_pb.js";

/**
 * @generated from message stat.TotalsFilter
 */
export const TotalsFilter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.TotalsFilter",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange },
    { no: 2, name: "regions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "players", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 5, name: "excludeTestPlayers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message stat.GetRegistrationTotalsRequest
 */
export const GetRegistrationTotalsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRegistrationTotalsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: TotalsFilter },
  ],
);

/**
 * @generated from message stat.GetRegistrationTotalsResponse
 */
export const GetRegistrationTotalsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRegistrationTotalsResponse",
  () => [
    { no: 1, name: "casino", kind: "message", T: Total },
    { no: 2, name: "sport", kind: "message", T: Total },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetActionTotalsRequest
 */
export const GetActionTotalsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetActionTotalsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: TotalsFilter },
    { no: 2, name: "commonCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.GetActionTotalsResponse
 */
export const GetActionTotalsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetActionTotalsResponse",
  () => [
    { no: 1, name: "casino", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: Total} },
    { no: 2, name: "sport", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: Total} },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetBalanceTotalsRequest
 */
export const GetBalanceTotalsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetBalanceTotalsRequest",
  () => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 2, name: "commonCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.GetBalanceTotalsResponse
 */
export const GetBalanceTotalsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetBalanceTotalsResponse",
  () => [
    { no: 1, name: "casino", kind: "message", T: Total },
    { no: 2, name: "sport", kind: "message", T: Total },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

