import { Injectable } from '@angular/core';

import type { PersistentData } from '@bo/common';
import { FiltersProviderService } from '@bo/common';

import { toModelData } from './deposits-filter.adapter';
import type { ListDepositsFilter } from './deposits-filter.types';

@Injectable()
export class DepositsFilterProviderService extends FiltersProviderService<ListDepositsFilter> {
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  protected toModelData(data: PersistentData): Partial<ListDepositsFilter> {
    return toModelData(data);
  }
}
