import { inject, Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';

import { CurrencyProvider } from './currency.provider';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private readonly provider = inject(CurrencyProvider);

  public readonly currencies$ = this.provider.listCurrencies().pipe(
    map(({ currencies }) => currencies),
    shareReplay(1),
  );
}
