import type { EnvironmentProviders, Provider } from '@angular/core';
import { makeEnvironmentProviders } from '@angular/core';

import { provideFactory } from '@pu/sdk';

import type { GrpcFeature, GrpcFeatureKind } from './grpc-feature';
import { GRPC_TRANSPORT } from './grpc-transport';
import { grpcWebTransportFactory } from './grpc-web-transport-factory';

export function provideGrpcWebTransport(
  ...features: GrpcFeature<GrpcFeatureKind>[]
): EnvironmentProviders {
  const providers: Provider[] = [
    provideFactory(GRPC_TRANSPORT, grpcWebTransportFactory),
  ];

  for (const feature of features) {
    providers.push(...feature.providers);
  }

  return makeEnvironmentProviders(providers);
}
