// @generated by protoc-gen-es v1.10.0
// @generated from file base/bo/v1/task.proto (package gamification.achievement_manager.base.bo.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { ComparisonOperator, EventValueType } from "./task_enum_pb.js";

/**
 * Task for achievements.
 *
 * @generated from message gamification.achievement_manager.base.bo.v1.Task
 */
export const Task = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.base.bo.v1.Task",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sequence_number", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 3, name: "conditions", kind: "message", T: Condition, repeated: true },
  ],
);

/**
 * Condition that must be satisfied to complete a task.
 *
 * @generated from message gamification.achievement_manager.base.bo.v1.Condition
 */
export const Condition = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.base.bo.v1.Condition",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "event_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "comparison_operator", kind: "enum", T: proto3.getEnumType(ComparisonOperator), opt: true },
    { no: 4, name: "target_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "repetitions", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
  ],
);

/**
 * Event that must be satisfied to complete a task.
 *
 * @generated from message gamification.achievement_manager.base.bo.v1.Event
 */
export const Event = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.base.bo.v1.Event",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "value_type", kind: "enum", T: proto3.getEnumType(EventValueType), opt: true },
    { no: 5, name: "comparison_operators", kind: "enum", T: proto3.getEnumType(ComparisonOperator), repeated: true },
  ],
);

