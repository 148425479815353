// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/bo/transactions/transactions.proto (package stat.bo.transactions, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ListTransactionsRequest, ListTransactionsResponse } from "./transactions_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service stat.bo.transactions.TransactionsService
 */
@Injectable({ providedIn: "root" })
export class TransactionsRemoteService {
  public static readonly typeName = "stat.bo.transactions.TransactionsService";

  public static readonly methods = {
    listTransactions: {
      name: "ListTransactions",
      I: ListTransactionsRequest,
      O: ListTransactionsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc stat.bo.transactions.TransactionsService.ListTransactions
   */
  public listTransactions(
    input: PartialMessage<ListTransactionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListTransactionsResponse> {
    return unary<ListTransactionsRequest, ListTransactionsResponse>(
      this.transport,
      TransactionsRemoteService,
      TransactionsRemoteService.methods.listTransactions,
      input,
      options,
    );
  }
}
