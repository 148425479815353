<form
  suspense-area
  id="add-deposit-form"
  (ngSubmit)="suspense.run(send())"
  puDialogSuspense
  puInertSuspense
  puSuspense
  #form="ngForm"
  #suspense="puSuspense"
>
  <div mat-dialog-title>
    <div class="header">
      <div class="header-icon">
        <bo-svg class="circles" [unsafeSvgSource]="emptyBackground" />
        <bo-svg class="alert" [size]="24" [unsafeSvgSource]="depositsIcon" />
      </div>
      <div class="header-text">
        <span class="text">Add deposit</span>
      </div>
    </div>
  </div>
  <pu-dialog-content>
    @if ('casino' | licenseSupports) {
      @if ('sport' | licenseSupports) {
        <div class="field pb-4">
          <bo-radio-toggle-bar
            class="radio-toggles"
            [(ngModel)]="deposit.isBetProject"
            name="isBetProject"
          >
            @for (option of betProjectOptions; track option.value) {
              <button
                bo-radio-toggle-item
                class="w-100"
                [attr.data-testid]="'radio-toggle-' + option.name"
                [value]="option.value"
                type="button"
              >
                {{ option.name }}
              </button>
            }
          </bo-radio-toggle-bar>
        </div>
      }
    }
    <mat-form-field class="field">
      <mat-label>Amount</mat-label>
      <input
        aria-label="deposit"
        [(ngModel)]="deposit.deposit"
        [maskito]="decimalMaskOptions"
        data-testid="decimal-mask-options"
        matInput
        name="deposit"
        placeholder="Place your sum"
        type="text"
      />
    </mat-form-field>
    <div class="flex flex-column">
      <mat-checkbox
        [(ngModel)]="deposit.withBonus"
        data-testid="with-bonus"
        name="withBonus"
      >
        With bonus
      </mat-checkbox>
      @if ('lottery' | licenseSupports) {
        <mat-checkbox
          [(ngModel)]="deposit.withLottery"
          data-testid="with-lottery"
          name="withLottery"
        >
          Add sum to lottery
        </mat-checkbox>
      }
    </div>
    <mat-form-field class="field">
      <mat-label>Note</mat-label>
      <textarea
        [(ngModel)]="deposit.comment"
        [puStrictMinLength]="4"
        cdkAutosizeMinRows="5"
        cdkTextareaAutosize
        data-testid="comment"
        matInput
        name="comment"
        placeholder="Type here…"
        required
        #textField="ngModel"
      ></textarea>
      @if (textField | validationError; as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
    <div class="flex flex-column">
      <mat-checkbox
        [(ngModel)]="deposit.isCommentPinned"
        data-testid="is-comment-pinned"
        name="isCommentPinned"
      >
        Pin note
      </mat-checkbox>
      @if ('dw-tax' | licenseSupports) {
        <mat-checkbox
          [(ngModel)]="deposit.useInTaxCalculation"
          data-testid="use-in-tax-calculation"
          name="useInTaxCalculation"
        >
          Use this deposit in tax calculating
        </mat-checkbox>
      }
    </div>
  </pu-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="button"
      data-testid="cancel"
      form="add-deposit-form"
      matDialogClose
      type="button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="form.invalid"
      color="primary"
      data-testid="submit"
      form="add-deposit-form"
      type="submit"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</form>
