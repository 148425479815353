import type { Transaction } from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import {
  TransactionBetType,
  TransactionOperationType,
} from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import { Project } from '@pinup-grpc/pinup/std/query/v1/types_pb';

import {
  bhCasinoIcon,
  bhSportIcon,
  casinoBonusIcon,
  casinoCashbackIcon,
  casinoLoseIcon,
  casinoRevertIcon,
  casinoWinIcon,
  depositIcon,
  freebetIcon,
  otherIcon,
  sportBonusIcon,
  sportCashbackIcon,
  sportLoseIcon,
  sportRevertIcon,
  sportWinIcon,
  unwithdrawalIcon,
  withdrawalSuccessIcon,
} from '@bo/common';

export type TransactionStatus = Readonly<{
  icon: string;
  label: string;
  color?: string;
}>;

type BetTypeStatusMap = Readonly<
  Partial<Record<TransactionBetType, TransactionStatus>>
>;

type ProjectStatusMap = Readonly<
  Partial<Record<Project, BetTypeStatusMap | TransactionStatus>>
>;

type OperationStatusMap = Readonly<
  Partial<
    Record<TransactionOperationType, ProjectStatusMap | TransactionStatus>
  >
>;

const statusMap: OperationStatusMap = {
  [TransactionOperationType.WRITE_BET]: {
    [Project.CASINO]: {
      [TransactionBetType.BET]: { icon: bhCasinoIcon, label: 'Casino bet' },
      [TransactionBetType.WIN]: {
        icon: casinoWinIcon,
        label: 'Casino win',
        color: 'green',
      },
      [TransactionBetType.LOSE]: {
        icon: casinoLoseIcon,
        label: 'Casino lose',
        color: 'red',
      },
      [TransactionBetType.ROLLBACK]: {
        icon: casinoRevertIcon,
        label: 'Casino rollback',
      },
    },
    [Project.SPORT]: {
      [TransactionBetType.BET]: { icon: bhSportIcon, label: 'Sport bet' },
      [TransactionBetType.WIN]: {
        icon: sportWinIcon,
        label: 'Sport win',
        color: 'green',
      },
      [TransactionBetType.LOSE]: {
        icon: sportLoseIcon,
        label: 'Sport lose',
        color: 'red',
      },
      [TransactionBetType.ROLLBACK]: {
        icon: sportRevertIcon,
        label: 'Sport rollback',
      },
    },
  },
  [TransactionOperationType.REVERT]: {
    [Project.CASINO]: {
      icon: casinoRevertIcon,
      label: 'Casino revert',
    },
    [Project.SPORT]: { icon: sportRevertIcon, label: 'Sport revert' },
  },
  [TransactionOperationType.BONUS_WIN]: {
    [Project.CASINO]: {
      icon: casinoBonusIcon,
      label: 'Casino bonus',
      color: 'blue',
    },
    [Project.SPORT]: {
      icon: sportBonusIcon,
      label: 'Sport bonus',
      color: 'blue',
    },
  },
  [TransactionOperationType.CASHBACK]: {
    [Project.CASINO]: { icon: casinoCashbackIcon, label: 'Casino cashback' },
    [Project.SPORT]: { icon: sportCashbackIcon, label: 'Sport cashback' },
  },
  [TransactionOperationType.DEPOSIT]: {
    icon: depositIcon,
    label: 'Deposit',
    color: 'green',
  },
  [TransactionOperationType.WITHDRAWAL]: {
    icon: withdrawalSuccessIcon,
    label: 'Withdrawal',
    color: 'red',
  },
  [TransactionOperationType.UN_WITHDRAWAL]: {
    icon: unwithdrawalIcon,
    label: 'Un-withdrawal',
  },
  [TransactionOperationType.FREE_BET_WIN]: {
    icon: freebetIcon,
    label: 'Freebet',
  },
};

function isStatus(obj: unknown): obj is TransactionStatus {
  return (obj as TransactionStatus)?.icon !== undefined;
}

export function getStatus(transaction: Transaction): TransactionStatus {
  const { project, operationType, betType } = transaction;

  const defaultStatus: TransactionStatus = { icon: otherIcon, label: 'Other' };

  // Retrieve the project status map or status using operationType from statusMap
  const projectStatusMapOrStatus = statusMap[operationType];
  if (!projectStatusMapOrStatus) {
    return defaultStatus;
  }

  // Check if projectStatusMapOrStatus is TransactionStatus
  if (isStatus(projectStatusMapOrStatus)) {
    return projectStatusMapOrStatus;
  }

  // Retrieve the bet type status map or status using project from projectStatusMap
  const betTypeStatusMapOrStatus = projectStatusMapOrStatus[project];
  if (!betTypeStatusMapOrStatus) {
    return defaultStatus;
  }

  // Check if betTypeStatusMapOrStatus is TransactionStatus
  if (isStatus(betTypeStatusMapOrStatus)) {
    return betTypeStatusMapOrStatus;
  }

  // Return the status for the given betType or defaultStatus if not found
  return betTypeStatusMapOrStatus[betType] || defaultStatus;
}
