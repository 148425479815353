import type { Provider } from '@angular/core';

export const enum GrpcFeatureKind {
  INTERCEPTORS = 0,
  CONFIG = 1,
}

export interface GrpcFeature<K extends GrpcFeatureKind> {
  readonly kind: K;
  readonly providers: readonly Provider[];
}

export function grpcFeature<K extends GrpcFeatureKind>(
  kind: K,
  providers: readonly Provider[],
): GrpcFeature<K> {
  return { kind, providers };
}
