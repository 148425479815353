// @generated by protoc-gen-es v1.10.0
// @generated from file settings_manager/v1/models/pagination.proto (package settings_manager.v1.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message settings_manager.v1.models.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.PaginationRequest",
  () => [
    { no: 1, name: "currentPageNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "pageSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message settings_manager.v1.models.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.PaginationResponse",
  () => [
    { no: 1, name: "totalPages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "currentPage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "pageSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "totalItems", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

