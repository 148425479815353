import type { WritableSignal } from '@angular/core';
import { booleanAttribute, inject, Injectable, Injector } from '@angular/core';

import { localStorageSignal } from '@pu/sdk';

import type {
  CreateFeatureFlagsPayload,
  FeatureFlagsAdapter,
} from './feature-flags-adapter.model';

const createSignal = (
  key: string,
  defaultValue: boolean,
  injector: Injector,
): WritableSignal<boolean> =>
  localStorageSignal<boolean>(
    `feature_flag_${key}`,
    booleanAttribute,
    JSON.stringify,
    defaultValue,
    { injector },
  );

@Injectable()
export class LocalStorageFeatureFlagsAdapter implements FeatureFlagsAdapter {
  private readonly injector = inject(Injector);

  public createSignal({
    flag,
    defaultValue,
  }: CreateFeatureFlagsPayload): WritableSignal<boolean> {
    return createSignal(flag, defaultValue, this.injector);
  }
}
