import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { fromKopecks } from '@bo/common';

@Pipe({
  name: 'fromKopecks',
  standalone: true,
})
export class FromKopecksPipe implements PipeTransform {
  public transform(value: bigint, digits = 2): string {
    return Number(fromKopecks(value)).toFixed(digits);
  }
}
