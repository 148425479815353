import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { MatFormField, MatInput } from '@angular/material/input';

import {
  AutocompleteOfDirective,
  countryHighlight,
  countryValueSelector,
} from '@pu/sdk';
import {
  CountriesService,
  FilledFiltersCountComponent,
  MultiAutocompleteComponent,
} from '@bo/common';

import type { DepositsFilterOtherForm } from '../other-filter.form';

@Component({
  selector: 'bo-deposit-other-filter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInput,
    MatFormField,
    MatLabel,
    FilledFiltersCountComponent,
    AutocompleteOfDirective,
    MultiAutocompleteComponent,
  ],
  templateUrl: './other-filter.component.html',
  styleUrl: './other-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositOtherFilterComponent {
  public form = input.required<DepositsFilterOtherForm>();

  protected readonly countries$ = inject(CountriesService).getCountriesList();
  protected readonly countryHighlight = countryHighlight;
  protected readonly countryValueSelector = countryValueSelector;
}
