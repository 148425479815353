import { Directive, forwardRef } from '@angular/core';
import type { ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';

import { provideMultiExisting } from '@pu/sdk';

import { strictPhoneValidator } from './strict-phone.validator';
import { ValidatorDirective } from './validator.directive';

@Directive({
  selector: '[puStrictPhone]',
  standalone: true,
  providers: [
    provideMultiExisting(
      NG_VALIDATORS,
      forwardRef(() => StrictPhoneDirective),
    ),
  ],
})
export class StrictPhoneDirective extends ValidatorDirective {
  protected override createValidator(): ValidatorFn {
    return strictPhoneValidator();
  }
}
