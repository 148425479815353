// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/backoffice/deposits/deposits.proto (package payments.backoffice.deposits, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { AddDepositRequest, AddDepositResponse } from "./deposits_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.backoffice.deposits.DepositService
 */
@Injectable({ providedIn: "root" })
export class DepositRemoteService {
  public static readonly typeName = "payments.backoffice.deposits.DepositService";

  public static readonly methods = {
    addDeposit: {
      name: "AddDeposit",
      I: AddDepositRequest,
      O: AddDepositResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.backoffice.deposits.DepositService.AddDeposit
   */
  public addDeposit(
    input: PartialMessage<AddDepositRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AddDepositResponse> {
    return unary<AddDepositRequest, AddDepositResponse>(
      this.transport,
      DepositRemoteService,
      DepositRemoteService.methods.addDeposit,
      input,
      options,
    );
  }
}
