import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { TransactionVM } from '../balance-history/transaction.vm';
import { injectCurrencyConverted } from '../tokens/currency-converted.token';

@Pipe({
  name: 'extractMoney',
  standalone: true,
  pure: false,
})
export class ExtractMoneyPipe implements PipeTransform {
  private readonly currencyConverted = injectCurrencyConverted();

  public transform(
    value: TransactionVM,
    field: 'amount' | 'balance' | 'balanceBefore',
  ): bigint {
    const finance = this.currencyConverted()
      ? value.convertFinance
      : value.finance;

    return finance[field];
  }
}
