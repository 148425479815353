export enum DateFnsFormat {
  'dd_MM_yyyy_HH_mm_ss' = 'dd-MM-yyyy HH:mm:ss',
  'dd_MM_yyyy' = 'dd-MM-yyyy',
  'yyyy_MM_dd' = 'yyyy-MM-dd',
  'yyyy_MM_dd_HH_mm_ss' = 'yyyy-MM-dd HH:mm:ss',
  'all_sliced' = 'yyyy-MM-dd | HH:mm:ss',
  'divided_by_comma' = 'yyyy-MM-dd, HH:mm:ss',

  'divided_by_at' = "yyyy-MM-dd 'at' HH:mm:ss",
  'sliced_HH_mm_ss' = '| HH:mm:ss',
}
