<div
  class="toggle-button"
  [class.opened]="isOpen()"
  (boOutsideClick)="closeDropdown()"
  (click)="toggleDropdown()"
>
  <span class="text" [class.active]="isActive()">sort by</span>
  @if (selectedOrder()) {
    <span class="text" [class.active]="isActive()">
      : {{ selectedDateOrder() }}
    </span>
  }
  <bo-svg
    class="icon"
    [class.active]="isActive()"
    [size]="16"
    [unsafeSvgSource]="isOpen() ? chevronDownIcon : chevronUpIcon"
  />
</div>
@if (isOpen()) {
  <div class="sort-dropdown">
    <div
      class="item flex align-items-center"
      [class.selected]="isNewest()"
      (click)="setSortOrder(SortName.DESC)"
    >
      <span class="text">Newest</span>
      @if (isNewest()) {
        <bo-svg class="icon" [unsafeSvgSource]="checkMarkIcon" />
      }
    </div>
    <div
      class="item flex align-items-center"
      [class.selected]="isOldest()"
      (click)="setSortOrder(SortName.ASC)"
    >
      <span class="text">Oldest</span>
      @if (isOldest()) {
        <bo-svg class="icon" [unsafeSvgSource]="checkMarkIcon" />
      }
    </div>
  </div>
}
