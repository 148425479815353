import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';

import { PillToggleBarComponent } from '../pill-toggle-bar/pill-toggle-bar.component';

@Component({
  selector: '[bo-pill-toggle-item]',
  standalone: true,
  templateUrl: './pill-toggle-item.component.html',
  styleUrl: './pill-toggle-item.component.scss',
  host: {
    '[class.active]': 'isActive()',
    '[class.flat]': 'isFlat()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillToggleItemComponent {
  public readonly isActive = input(false, { alias: 'active' });
  public readonly isFlat = input(false, {
    alias: 'flat',
    transform: booleanAttribute,
  });

  private readonly parent = inject(PillToggleBarComponent, { optional: true });

  constructor() {
    this.ensureParentExists();
  }

  private ensureParentExists(): void {
    if (!this.parent) {
      throw new Error(
        'PillToggleItemComponent must be used within a PillToggleBarComponent',
      );
    }
  }
}
