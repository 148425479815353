import { _n } from '@pinup/null';
import type { PaginationInstance } from 'ngx-pagination';
import { PaginationService } from 'ngx-pagination';

import type { Pagination } from './pagination-panel.model';

// This service overrides PaginationService which is needed for PaginationControlsDirective to work
export class CustomPaginationService extends PaginationService {
  private paginationInstance: PaginationInstance;

  public override getInstance(): PaginationInstance {
    return this.paginationInstance;
  }

  public override getCurrentPage(): number {
    return this.paginationInstance.currentPage;
  }

  public updatePagination(pagination: Pagination): void {
    this.paginationInstance = this.normalizePagination(pagination);

    this.change.next(this.defaultId()); // Needed to update pagination for PaginationControlsDirective
  }

  private normalizePagination({
    pageSize,
    ...pagination
  }: Pagination): PaginationInstance {
    return {
      ...pagination,
      itemsPerPage: _n(pageSize),
      id: this.defaultId(), // needed to avoid console warning from within PaginationControlsDirective
    };
  }
}
