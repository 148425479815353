import type { PipeTransform } from '@angular/core';
import { inject, Pipe } from '@angular/core';

import type { FeatureFlag } from './feature-flags.model';
import { FeatureFlagsService } from './feature-flags.service';

@Pipe({ name: 'boFeatureFlagEnabled', standalone: true, pure: false })
export class BoFeatureFlagsPipe implements PipeTransform {
  private readonly service = inject(FeatureFlagsService);

  public transform(flag: FeatureFlag): boolean {
    return this.service.getValue(flag);
  }
}
