// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/billing/billing.proto (package pinup.stat.report.billing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, proto3, Timestamp } from "@bufbuild/protobuf";
import { TimeRange } from "../../../std/query/v1/filter_pb.js";
import { Sorting } from "../../../std/query/v1/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../std/query/v1/pagination_pb.js";
import { Project } from "../../../std/query/v1/types_pb.js";
import { SumRange } from "../base/filtering_pb.js";

/**
 * @generated from enum pinup.stat.report.billing.TransactionOperationType
 */
export const TransactionOperationType = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.billing.TransactionOperationType",
  [
    {no: 0, name: "TRANSACTION_OPERATION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_OPERATION_TYPE_DEPOSIT", localName: "DEPOSIT"},
    {no: 2, name: "TRANSACTION_OPERATION_TYPE_WITHDRAWAL", localName: "WITHDRAWAL"},
    {no: 3, name: "TRANSACTION_OPERATION_TYPE_UN_WITHDRAWAL", localName: "UN_WITHDRAWAL"},
    {no: 4, name: "TRANSACTION_OPERATION_TYPE_WRITE_BET", localName: "WRITE_BET"},
    {no: 5, name: "TRANSACTION_OPERATION_TYPE_FREE_BET_WIN", localName: "FREE_BET_WIN"},
    {no: 6, name: "TRANSACTION_OPERATION_TYPE_LOTTERY_WIN", localName: "LOTTERY_WIN"},
    {no: 7, name: "TRANSACTION_OPERATION_TYPE_MARKETING_WIN", localName: "MARKETING_WIN"},
    {no: 8, name: "TRANSACTION_OPERATION_TYPE_BONUS_WIN", localName: "BONUS_WIN"},
    {no: 9, name: "TRANSACTION_OPERATION_TYPE_CASHBACK", localName: "CASHBACK"},
    {no: 10, name: "TRANSACTION_OPERATION_TYPE_REVERT", localName: "REVERT"},
  ],
);

/**
 * @generated from enum pinup.stat.report.billing.TransactionBetType
 */
export const TransactionBetType = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.billing.TransactionBetType",
  [
    {no: 0, name: "TRANSACTION_BET_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_BET_TYPE_BET", localName: "BET"},
    {no: 2, name: "TRANSACTION_BET_TYPE_WIN", localName: "WIN"},
    {no: 3, name: "TRANSACTION_BET_TYPE_LOSE", localName: "LOSE"},
    {no: 4, name: "TRANSACTION_BET_TYPE_ROLLBACK", localName: "ROLLBACK"},
  ],
);

/**
 * @generated from message pinup.stat.report.billing.ListTransactionsRequest
 */
export const ListTransactionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.billing.ListTransactionsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListTransactionsRequest_Filter },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: TimeRange },
    { no: 5, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message pinup.stat.report.billing.ListTransactionsRequest.Filter
 */
export const ListTransactionsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.billing.ListTransactionsRequest.Filter",
  () => [
    { no: 1, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "playerGeos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "isPlayerVip", kind: "message", T: BoolValue },
    { no: 4, name: "eventIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 5, name: "operationTypes", kind: "enum", T: proto3.getEnumType(TransactionOperationType), repeated: true },
    { no: 6, name: "betTypes", kind: "enum", T: proto3.getEnumType(TransactionBetType), repeated: true },
    { no: 7, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 8, name: "amount", kind: "message", T: SumRange },
    { no: 9, name: "balance", kind: "message", T: SumRange },
    { no: 10, name: "balanceBefore", kind: "message", T: SumRange },
  ],
  {localName: "ListTransactionsRequest_Filter"},
);

/**
 * @generated from message pinup.stat.report.billing.ListTransactionsResponse
 */
export const ListTransactionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.billing.ListTransactionsResponse",
  () => [
    { no: 1, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message pinup.stat.report.billing.Finance
 */
export const Finance = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.billing.Finance",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message pinup.stat.report.billing.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.billing.Transaction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "eventId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "finance", kind: "message", T: Finance },
    { no: 5, name: "convertFinance", kind: "message", T: Finance },
    { no: 6, name: "isReverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "operationType", kind: "enum", T: proto3.getEnumType(TransactionOperationType) },
    { no: 9, name: "betType", kind: "enum", T: proto3.getEnumType(TransactionBetType) },
    { no: 10, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
    { no: 11, name: "createdAt", kind: "message", T: Timestamp },
  ],
);

