import type { Provider } from '@angular/core';
import { inject, InjectionToken } from '@angular/core';

import { provideClass, provideFactory, provideValue } from '@pu/sdk';

import { PaginationType } from '../pagination.model';
import { PAGINATION_TYPE } from '../pagination.providers';
import { DefaultPaginationManager } from '../pagination-management/default-pagination/default-pagination-manager';
import { PAGINATION_MANAGER_INITIAL_VALUE } from '../pagination-management/default-pagination/default-pagination-value.provider';
import type { PaginationManager } from '../pagination-management/pagination-management.model';
import type { PaginationPanelFeatures } from './features/models';
import { DEFAULT_PAGINATION } from './pagination.const';
import type {
  PaginationPanelPayload,
  PaginationValueAccessor,
} from './pagination-panel.model';

export const PAGINATION_PANEL_MANAGER = new InjectionToken<
  PaginationManager<PaginationPanelPayload>
>('PAGINATION_PANEL_MANAGER');

export const PAGINATION_PANEL_INITIAL_STATE =
  new InjectionToken<PaginationPanelPayload>('PAGINATION_PANEL_INITIAL_STATE');

export const providePaginationPanel = (
  ...features: PaginationPanelFeatures[]
): Provider[] => [
  provideValue<PaginationType>(PAGINATION_TYPE, PaginationType.Panel),
  provideFactory<PaginationPanelPayload>(
    PAGINATION_MANAGER_INITIAL_VALUE,
    () =>
      inject(PAGINATION_PANEL_INITIAL_STATE, { optional: true }) ||
      DEFAULT_PAGINATION,
  ),
  provideClass<PaginationManager<PaginationPanelPayload>>(
    PAGINATION_PANEL_MANAGER,
    DefaultPaginationManager<PaginationPanelPayload>,
  ),
  features.map(({ providers }) => providers),
];

export const BO_PAGINATION_VALUE_ACCESSOR =
  new InjectionToken<PaginationValueAccessor>('BO_PAGINATION_VALUE_ACCESSOR');
