import type { Provider } from '@angular/core';

export enum LoadingIndicationKind {
  ROUTER_INDICATION = 0,
}

export interface LoadingIndicationFeature<K extends LoadingIndicationKind> {
  kind: K;
  providers: Provider[];
}

export function makeLoadingIndicationFeature<K extends LoadingIndicationKind>(
  kind: K,
  providers: Provider[],
): LoadingIndicationFeature<K> {
  return { kind, providers };
}
