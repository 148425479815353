// @generated by protoc-gen-es v1.10.0
// @generated from file services/geo_stat.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Total } from "../pinup/std/query/v1/models_pb.js";
import { Period } from "../pinup/std/query/v1/filter_pb.js";

/**
 * @generated from enum stat.GroupBy
 */
export const GroupBy = /*@__PURE__*/ proto3.makeEnum(
  "stat.GroupBy",
  [
    {no: 0, name: "GROUP_BY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GROUP_BY_DAY", localName: "DAY"},
    {no: 2, name: "GROUP_BY_WEEK", localName: "WEEK"},
    {no: 3, name: "GROUP_BY_MONTH", localName: "MONTH"},
    {no: 5, name: "GROUP_BY_ALL", localName: "ALL"},
  ],
);

/**
 * @generated from message stat.GetRegionsRequest
 */
export const GetRegionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRegionsRequest",
  [],
);

/**
 * @generated from message stat.GetRegionsResponse
 */
export const GetRegionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRegionsResponse",
  () => [
    { no: 1, name: "regions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message stat.RealPlayers
 */
export const RealPlayers = /*@__PURE__*/ proto3.makeMessageType(
  "stat.RealPlayers",
  () => [
    { no: 1, name: "real", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "realSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "realCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "newRealSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "newRealCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.Registrations
 */
export const Registrations = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Registrations",
  () => [
    { no: 1, name: "casino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Deposit
 *
 * @generated from message stat.Deposit
 */
export const Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Deposit",
  () => [
    { no: 1, name: "try", kind: "message", T: Total },
    { no: 2, name: "tryNd", kind: "message", T: Total },
    { no: 3, name: "real", kind: "message", T: Total },
    { no: 4, name: "admin", kind: "message", T: Total },
    { no: 5, name: "first", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.Bonus
 */
export const Bonus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Bonus",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "wager", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.Lottery
 */
export const Lottery = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Lottery",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "realWin", kind: "message", T: Total },
    { no: 3, name: "bonusWin", kind: "message", T: Total },
  ],
);

/**
 * Bet
 *
 * @generated from message stat.Bet
 */
export const Bet = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Bet",
  () => [
    { no: 2, name: "real", kind: "message", T: Total },
    { no: 3, name: "realRollback", kind: "message", T: Total },
    { no: 4, name: "bonus", kind: "message", T: Total },
    { no: 5, name: "bonusRollback", kind: "message", T: Total },
    { no: 6, name: "realCalc", kind: "message", T: Total },
    { no: 7, name: "realCalcRollback", kind: "message", T: Total },
    { no: 8, name: "bonusCalc", kind: "message", T: Total },
    { no: 9, name: "bonusCalcRollback", kind: "message", T: Total },
  ],
);

/**
 * Win
 *
 * @generated from message stat.Win
 */
export const Win = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Win",
  () => [
    { no: 2, name: "real", kind: "message", T: Total },
    { no: 3, name: "realRollback", kind: "message", T: Total },
    { no: 4, name: "bonus", kind: "message", T: Total },
    { no: 5, name: "bonusRollback", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.Freebet
 */
export const Freebet = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Freebet",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "bet", kind: "message", T: Total },
    { no: 7, name: "betRollback", kind: "message", T: Total },
    { no: 3, name: "win", kind: "message", T: Total },
    { no: 4, name: "winRollback", kind: "message", T: Total },
    { no: 5, name: "winWithBetSum", kind: "message", T: Total },
    { no: 6, name: "winWithBetSumRollback", kind: "message", T: Total },
  ],
);

/**
 * Freespin - only casino
 *
 * @generated from message stat.Freespin
 */
export const Freespin = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Freespin",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "win", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.Tournament
 */
export const Tournament = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Tournament",
  () => [
    { no: 2, name: "realWin", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.GetRegistrationRequest
 */
export const GetRegistrationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRegistrationRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "period", kind: "message", T: Period },
    { no: 3, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.GetRegistrationResponse
 */
export const GetRegistrationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRegistrationResponse",
  () => [
    { no: 1, name: "registrations", kind: "message", T: Registrations },
  ],
);

/**
 * @generated from message stat.GetRealPlayerRequest
 */
export const GetRealPlayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRealPlayerRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "period", kind: "message", T: Period },
    { no: 3, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.GetRealPlayerResponse
 */
export const GetRealPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetRealPlayerResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: RealPlayers },
  ],
);

/**
 * @generated from message stat.Stat
 */
export const Stat = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Stat",
  () => [
    { no: 1, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "depositCasino", kind: "message", T: Deposit },
    { no: 9, name: "depositSport", kind: "message", T: Deposit },
    { no: 10, name: "moneyoutCasino", kind: "message", T: Total },
    { no: 11, name: "moneyoutSport", kind: "message", T: Total },
    { no: 12, name: "bonusCasino", kind: "message", T: Bonus },
    { no: 13, name: "bonusSport", kind: "message", T: Bonus },
    { no: 14, name: "betCasinos", kind: "message", T: Bet },
    { no: 15, name: "betSport", kind: "message", T: Bet },
    { no: 16, name: "winCasinos", kind: "message", T: Win },
    { no: 17, name: "winSport", kind: "message", T: Win },
    { no: 18, name: "lotteryCasino", kind: "message", T: Lottery },
    { no: 19, name: "lotterySport", kind: "message", T: Lottery },
    { no: 20, name: "freespin", kind: "message", T: Freespin },
    { no: 21, name: "freebet", kind: "message", T: Freebet },
    { no: 22, name: "tournamentCasino", kind: "message", T: Tournament },
    { no: 23, name: "tournamentSport", kind: "message", T: Tournament },
    { no: 24, name: "pincoinAdd", kind: "message", T: Total },
    { no: 25, name: "pincoinExchangeCasino", kind: "message", T: Total },
    { no: 26, name: "pincoinExchangeSport", kind: "message", T: Total },
    { no: 27, name: "transferCasinoToSport", kind: "message", T: Total },
    { no: 28, name: "transferSportToCasino", kind: "message", T: Total },
    { no: 29, name: "withdrawalRequestCasino", kind: "message", T: Total },
    { no: 30, name: "withdrawalRequestSport", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.GetStatRequest
 */
export const GetStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetStatRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: Period },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.GetStatResponse
 */
export const GetStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetStatResponse",
  () => [
    { no: 1, name: "stats", kind: "message", T: Stat, repeated: true },
  ],
);

/**
 * @generated from message stat.ProviderStatistic
 */
export const ProviderStatistic = /*@__PURE__*/ proto3.makeMessageType(
  "stat.ProviderStatistic",
  () => [
    { no: 1, name: "bet", kind: "message", T: Total },
    { no: 2, name: "bonusBet", kind: "message", T: Total },
    { no: 3, name: "betWin", kind: "message", T: Total },
    { no: 4, name: "bonusBetWin", kind: "message", T: Total },
    { no: 5, name: "freespinWin", kind: "message", T: Total },
    { no: 6, name: "commissionPercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "commissionBeforePercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "commission", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "system", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "gameTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "gameID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Full Report
 *
 * @generated from message stat.Report
 */
export const Report = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Report",
  () => [
    { no: 1, name: "groupBy", kind: "enum", T: proto3.getEnumType(GroupBy) },
    { no: 2, name: "date", kind: "message", T: Timestamp },
    { no: 3, name: "dateEnd", kind: "message", T: Timestamp },
    { no: 4, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "registrations", kind: "message", T: Registrations },
    { no: 7, name: "players", kind: "message", T: RealPlayers },
    { no: 8, name: "depositCasino", kind: "message", T: Deposit },
    { no: 9, name: "depositSport", kind: "message", T: Deposit },
    { no: 10, name: "moneyoutCasino", kind: "message", T: Total },
    { no: 11, name: "moneyoutSport", kind: "message", T: Total },
    { no: 12, name: "bonusCasino", kind: "message", T: Bonus },
    { no: 13, name: "bonusSport", kind: "message", T: Bonus },
    { no: 14, name: "betCasinos", kind: "message", T: Bet },
    { no: 15, name: "betSport", kind: "message", T: Bet },
    { no: 16, name: "winCasinos", kind: "message", T: Win },
    { no: 17, name: "winSport", kind: "message", T: Win },
    { no: 18, name: "lotteryCasino", kind: "message", T: Lottery },
    { no: 19, name: "lotterySport", kind: "message", T: Lottery },
    { no: 20, name: "freespin", kind: "message", T: Freespin },
    { no: 21, name: "freebet", kind: "message", T: Freebet },
    { no: 22, name: "tournamentCasino", kind: "message", T: Tournament },
    { no: 23, name: "tournamentSport", kind: "message", T: Tournament },
    { no: 24, name: "pincoinAdd", kind: "message", T: Total },
    { no: 25, name: "pincoinExchangeCasino", kind: "message", T: Total },
    { no: 26, name: "pincoinExchangeSport", kind: "message", T: Total },
    { no: 27, name: "transferCasinoToSport", kind: "message", T: Total },
    { no: 28, name: "transferSportToCasino", kind: "message", T: Total },
    { no: 29, name: "countIdentTries", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 30, name: "countIdentSuccess", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 31, name: "providerStatistic", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ProviderStatistic} },
    { no: 32, name: "withdrawalRequestCasino", kind: "message", T: Total },
    { no: 33, name: "withdrawalRequestSport", kind: "message", T: Total },
  ],
);

/**
 * @generated from message stat.GetFullReportRequest
 */
export const GetFullReportRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetFullReportRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: Period },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "groupBy", kind: "enum", T: proto3.getEnumType(GroupBy) },
  ],
);

/**
 * @generated from message stat.GetFullReportResponse
 */
export const GetFullReportResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetFullReportResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: Report, repeated: true },
  ],
);

