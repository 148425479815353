import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import type { SumCurrency } from '@pinup-grpc/base/models_pb';

import { MoneyComponent } from '@pu/sdk';

import type { PaginationTotal } from '../pagination/pagination-panel/features/total-info/total-info.model';
import { DurationPipe } from '../pipes/duration.pipe';

@Component({
  selector: 'bo-finance-withdrawals-statistic',
  standalone: true,
  imports: [DurationPipe, MoneyComponent, AsyncPipe],
  templateUrl: './finance-withdrawals-statistic.component.html',
  styleUrl: './finance-withdrawals-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceWithdrawalsStatisticComponent {
  public total = input<PaginationTotal | null>();
  public totalAmount = input<SumCurrency | null>();
  public averageTime = input<bigint | null>();
}
