import { SimpleAuthRemoteService } from '@pinup-grpc/pinup/google_auth/simple/service_ng';

// TODO move to proto metadata
export function isAuthGrpcMethod(
  serviceName: string,
  // there is no point in checking the method name yet
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _methodName: string,
): boolean {
  return serviceName === SimpleAuthRemoteService.typeName;
}
