// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/billing.proto (package billing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetBalanceRequest, GetBalanceResponse, MakeTransactionChoiceRequest, MakeTransactionChoiceResponse, RevertTransactionRequest, RevertTransactionResponse } from "./billing_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service billing.Billing
 */
@Injectable({ providedIn: "root" })
export class BillingRemoteService {
  public static readonly typeName = "billing.Billing";

  public static readonly methods = {
    makeTransactionChoice: {
      name: "MakeTransactionChoice",
      I: MakeTransactionChoiceRequest,
      O: MakeTransactionChoiceResponse,
      kind: MethodKind.Unary,
    },
    revertTransaction: {
      name: "RevertTransaction",
      I: RevertTransactionRequest,
      O: RevertTransactionResponse,
      kind: MethodKind.Unary,
    },
    getBalance: {
      name: "GetBalance",
      I: GetBalanceRequest,
      O: GetBalanceResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc billing.Billing.MakeTransactionChoice
   */
  public makeTransactionChoice(
    input: PartialMessage<MakeTransactionChoiceRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<MakeTransactionChoiceResponse> {
    return unary<MakeTransactionChoiceRequest, MakeTransactionChoiceResponse>(
      this.transport,
      BillingRemoteService,
      BillingRemoteService.methods.makeTransactionChoice,
      input,
      options,
    );
  }

  /**
   * @generated from rpc billing.Billing.RevertTransaction
   */
  public revertTransaction(
    input: PartialMessage<RevertTransactionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<RevertTransactionResponse> {
    return unary<RevertTransactionRequest, RevertTransactionResponse>(
      this.transport,
      BillingRemoteService,
      BillingRemoteService.methods.revertTransaction,
      input,
      options,
    );
  }

  /**
   * @generated from rpc billing.Billing.GetBalance
   */
  public getBalance(
    input: PartialMessage<GetBalanceRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetBalanceResponse> {
    return unary<GetBalanceRequest, GetBalanceResponse>(
      this.transport,
      BillingRemoteService,
      BillingRemoteService.methods.getBalance,
      input,
      options,
    );
  }
}
