import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs';

import { DeclineReason } from '@pu/grpc';
import type { SmartDialogOptions } from '@pu/sdk';
import { provideValue, SmartDialog } from '@pu/sdk';

import type { WithdrawalDialogDeclineOptions } from './withdrawal-dialog-decline.component';
import {
  WITHDRAWAL_DIALOG_DECLINE_DATA,
  WithdrawalDialogDeclineComponent,
} from './withdrawal-dialog-decline.component';
import type { DialogResult } from './withdrawal-dialog-decline.config';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalDialogService {
  private readonly dialog = inject(SmartDialog);

  public decline(
    withdrawalId: bigint,
    {
      providers = [],
      reason = DeclineReason.UNSPECIFIED,
    }: Partial<WithdrawalDialogDeclineOptions> & SmartDialogOptions = {},
  ): Observable<unknown> {
    return this.dialog
      .open<DialogResult>(WithdrawalDialogDeclineComponent, [
        ...providers,
        provideValue(WITHDRAWAL_DIALOG_DECLINE_DATA, { withdrawalId, reason }),
      ])
      .pipe(filter((result) => result === 'save'));
  }
}
