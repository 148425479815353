import { Directive, ElementRef, inject } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Directive({
  selector: '[boAutocompleteTrigger]',
  standalone: true,
  hostDirectives: [MatAutocompleteTrigger],
})
export class AutocompleteTriggerDirective {
  public readonly input = inject<ElementRef<HTMLInputElement>>(ElementRef, {
    self: true,
  });
  public readonly trigger = inject(MatAutocompleteTrigger, { self: true });

  constructor() {
    if (this.input.nativeElement.value === undefined) {
      throw new Error('The autocomplete input value is not available');
    }
  }
}
