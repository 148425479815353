// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/money_out_search.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ListMoneyOutByIdsRequest, ListMoneyOutByIdsResponse, ListMoneyOutNotMatchVersionRequest, ListMoneyOutNotMatchVersionResponse } from "./money_out_search_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.MoneyOutSearchService
 */
@Injectable({ providedIn: "root" })
export class MoneyOutSearchRemoteService {
  public static readonly typeName = "payments.MoneyOutSearchService";

  public static readonly methods = {
    listMoneyOutByIds: {
      name: "ListMoneyOutByIds",
      I: ListMoneyOutByIdsRequest,
      O: ListMoneyOutByIdsResponse,
      kind: MethodKind.Unary,
    },
    listMoneyOutNotMatchVersion: {
      name: "ListMoneyOutNotMatchVersion",
      I: ListMoneyOutNotMatchVersionRequest,
      O: ListMoneyOutNotMatchVersionResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.MoneyOutSearchService.ListMoneyOutByIds
   */
  public listMoneyOutByIds(
    input: PartialMessage<ListMoneyOutByIdsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListMoneyOutByIdsResponse> {
    return unary<ListMoneyOutByIdsRequest, ListMoneyOutByIdsResponse>(
      this.transport,
      MoneyOutSearchRemoteService,
      MoneyOutSearchRemoteService.methods.listMoneyOutByIds,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.MoneyOutSearchService.ListMoneyOutNotMatchVersion
   */
  public listMoneyOutNotMatchVersion(
    input: PartialMessage<ListMoneyOutNotMatchVersionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListMoneyOutNotMatchVersionResponse> {
    return unary<ListMoneyOutNotMatchVersionRequest, ListMoneyOutNotMatchVersionResponse>(
      this.transport,
      MoneyOutSearchRemoteService,
      MoneyOutSearchRemoteService.methods.listMoneyOutNotMatchVersion,
      input,
      options,
    );
  }
}
