import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

@Component({
  selector: 'pu-money',
  standalone: true,
  imports: [],
  templateUrl: './money.component.html',
  styleUrl: './money.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyComponent {
  public amount = input.required<bigint | number>();
  public currency = input<string>();
  public showSign = input(false, { transform: booleanAttribute });
  public negative = input(false, { transform: booleanAttribute });

  protected parts = computed(() => {
    const amount = Number(this.amount());
    return {
      decimal: Math.trunc(amount / 100).toLocaleString('en-US'),
      fraction: String(Math.abs(amount) % 100).padStart(2, '0'),
    };
  });
}
