// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/withdrawals/withdrawals.proto (package pinup.stat.report.withdrawals, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, Duration, FieldMask, Int64Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "../../../std/query/v1/pagination_pb.js";
import { Sorting } from "../../../std/query/v1/sorting_pb.js";
import { DigitainStatus, VerifyStatus } from "../players/players_pb.js";
import { Project } from "../../../std/query/v1/types_pb.js";
import { StringMultiselect, SumRange, Uint64Multiselect } from "../base/filtering_pb.js";
import { TimeRange } from "../../../std/query/v1/filter_pb.js";
import { SumCurrency } from "../base/models_pb.js";

/**
 * Enum representing the method used to confirm a withdrawal request.
 *
 * @generated from enum pinup.stat.report.withdrawals.WithdrawalRequestConfirmType
 */
export const WithdrawalRequestConfirmType = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.withdrawals.WithdrawalRequestConfirmType",
  [
    {no: 0, name: "WITHDRAWAL_REQUEST_CONFIRM_TYPE_AUTO", localName: "AUTO"},
    {no: 1, name: "WITHDRAWAL_REQUEST_CONFIRM_TYPE_MANUAL", localName: "MANUAL"},
    {no: 2, name: "WITHDRAWAL_REQUEST_CONFIRM_TYPE_MANUAL_CHANGED_AMOUNT", localName: "MANUAL_CHANGED_AMOUNT"},
  ],
);

/**
 * Enum representing the various statuses a withdrawal request can have during its lifecycle.
 *
 * @generated from enum pinup.stat.report.withdrawals.WithdrawalRequestStatus
 */
export const WithdrawalRequestStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.withdrawals.WithdrawalRequestStatus",
  [
    {no: 0, name: "WITHDRAWAL_REQUEST_STATUS_NEW", localName: "NEW"},
    {no: 1, name: "WITHDRAWAL_REQUEST_STATUS_APPROVED", localName: "APPROVED"},
    {no: 2, name: "WITHDRAWAL_REQUEST_STATUS_CANCELED", localName: "CANCELED"},
    {no: 3, name: "WITHDRAWAL_REQUEST_STATUS_USER_CANCELED", localName: "USER_CANCELED"},
    {no: 4, name: "WITHDRAWAL_REQUEST_STATUS_AW_PROCESSING", localName: "AW_PROCESSING"},
    {no: 5, name: "WITHDRAWAL_REQUEST_STATUS_RISK_REVIEWING", localName: "RISK_REVIEWING"},
    {no: 6, name: "WITHDRAWAL_REQUEST_STATUS_FAILED", localName: "FAILED"},
    {no: 7, name: "WITHDRAWAL_REQUEST_STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 8, name: "WITHDRAWAL_REQUEST_STATUS_IN_PROGRESS", localName: "IN_PROGRESS"},
    {no: 9, name: "WITHDRAWAL_REQUEST_STATUS_NOT_CREATED", localName: "NOT_CREATED"},
  ],
);

/**
 * Enum representing the possible reasons why a withdrawal request might be declined.
 *
 * @generated from enum pinup.stat.report.withdrawals.WithdrawalRequestDeclineReason
 */
export const WithdrawalRequestDeclineReason = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.withdrawals.WithdrawalRequestDeclineReason",
  [
    {no: 0, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_TURNOVER_NEEDED", localName: "TURNOVER_NEEDED"},
    {no: 2, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_VERIFICATION_NEEDED", localName: "VERIFICATION_NEEDED"},
    {no: 3, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_PAYMENT_METHOD_NOT_MATCHED", localName: "PAYMENT_METHOD_NOT_MATCHED"},
    {no: 4, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_SELFIE_NEEDED", localName: "SELFIE_NEEDED"},
    {no: 5, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_FULL_VERIFICATION", localName: "FULL_VERIFICATION"},
    {no: 6, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_RECREATE_WITHDRAWAL", localName: "RECREATE_WITHDRAWAL"},
    {no: 7, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_FULL_PROFILE_NEEDED", localName: "FULL_PROFILE_NEEDED"},
    {no: 8, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_METHOD_NOT_AVAILABLE", localName: "METHOD_NOT_AVAILABLE"},
    {no: 9, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_OTHER", localName: "OTHER"},
    {no: 10, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_LIVENESS_NEEDED", localName: "LIVENESS_NEEDED"},
    {no: 11, name: "WITHDRAWAL_REQUEST_DECLINE_REASON_CHECKING_BY_PROVIDER", localName: "CHECKING_BY_PROVIDER"},
  ],
);

/**
 * Enum representing the reasons why a withdrawal request may require special handling
 * or why auto-withdrawal may be disabled.
 *
 * @generated from enum pinup.stat.report.withdrawals.WithdrawalRequestReason
 */
export const WithdrawalRequestReason = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.withdrawals.WithdrawalRequestReason",
  [
    {no: 0, name: "WITHDRAWAL_REQUEST_REASON_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_REQUEST_REASON_AUTO_TURNED_OFF", localName: "AUTO_TURNED_OFF"},
    {no: 2, name: "WITHDRAWAL_REQUEST_REASON_AUTO_TURNED_OFF_FOR_AMOUNT", localName: "AUTO_TURNED_OFF_FOR_AMOUNT"},
    {no: 3, name: "WITHDRAWAL_REQUEST_REASON_AMOUNT_OUT_OF_RANGE", localName: "AMOUNT_OUT_OF_RANGE"},
    {no: 4, name: "WITHDRAWAL_REQUEST_REASON_PLAYER_NOT_FOUND", localName: "PLAYER_NOT_FOUND"},
    {no: 5, name: "WITHDRAWAL_REQUEST_REASON_PLAYER_PROFILE_NOT_FILLED", localName: "PLAYER_PROFILE_NOT_FILLED"},
    {no: 6, name: "WITHDRAWAL_REQUEST_REASON_PLAYER_NO_VERIFICATION", localName: "PLAYER_NO_VERIFICATION"},
    {no: 7, name: "WITHDRAWAL_REQUEST_REASON_PLAYER_EMAIL_NOT_VERIFIED", localName: "PLAYER_EMAIL_NOT_VERIFIED"},
    {no: 8, name: "WITHDRAWAL_REQUEST_REASON_LAST_DEPOSIT_TURNOVER", localName: "LAST_DEPOSIT_TURNOVER"},
    {no: 9, name: "WITHDRAWAL_REQUEST_REASON_TURNOVER", localName: "TURNOVER"},
    {no: 10, name: "WITHDRAWAL_REQUEST_REASON_BET_COUNT_CASINO", localName: "BET_COUNT_CASINO"},
    {no: 11, name: "WITHDRAWAL_REQUEST_REASON_BET_COUNT_SPORT", localName: "BET_COUNT_SPORT"},
    {no: 12, name: "WITHDRAWAL_REQUEST_REASON_NO_BET_SINCE_LAST_DEPOSIT", localName: "NO_BET_SINCE_LAST_DEPOSIT"},
    {no: 13, name: "WITHDRAWAL_REQUEST_REASON_LIMIT", localName: "LIMIT"},
    {no: 14, name: "WITHDRAWAL_REQUEST_REASON_BLOCK_CASINO_STATUS", localName: "BLOCK_CASINO_STATUS"},
    {no: 15, name: "WITHDRAWAL_REQUEST_REASON_BLOCK_SPORT_STATUS", localName: "BLOCK_SPORT_STATUS"},
    {no: 16, name: "WITHDRAWAL_REQUEST_REASON_NOT_SAME_SYSTEM_NAME", localName: "NOT_SAME_SYSTEM_NAME"},
    {no: 17, name: "WITHDRAWAL_REQUEST_REASON_AUTO_OFF_FAST", localName: "AUTO_OFF_FAST"},
    {no: 18, name: "WITHDRAWAL_REQUEST_REASON_AUTO_OFF_ALL_IN_FIRST_DEP", localName: "AUTO_OFF_ALL_IN_FIRST_DEP"},
    {no: 19, name: "WITHDRAWAL_REQUEST_REASON_AUTO_OFF_SUSPICIOUS_SESSIONS", localName: "AUTO_OFF_SUSPICIOUS_SESSIONS"},
    {no: 20, name: "WITHDRAWAL_REQUEST_REASON_AUTO_OFF_ANTI_FRAUD_REJECTION", localName: "AUTO_OFF_ANTI_FRAUD_REJECTION"},
    {no: 21, name: "WITHDRAWAL_REQUEST_REASON_AUTO_OFF_VERIFICATION_REJECTION", localName: "AUTO_OFF_VERIFICATION_REJECTION"},
    {no: 22, name: "WITHDRAWAL_REQUEST_REASON_TOTAL_DAILY_AMOUNT_LIMIT_EXCEEDED", localName: "TOTAL_DAILY_AMOUNT_LIMIT_EXCEEDED"},
    {no: 23, name: "WITHDRAWAL_REQUEST_REASON_LAST_DEPOSIT_RISK_REVIEW", localName: "LAST_DEPOSIT_RISK_REVIEW"},
    {no: 24, name: "WITHDRAWAL_REQUEST_REASON_PLAYER_NOT_VERIFIED", localName: "PLAYER_NOT_VERIFIED"},
    {no: 25, name: "WITHDRAWAL_REQUEST_REASON_CREDIT_CART_CONFIRM_OWNERSHIP", localName: "CREDIT_CART_CONFIRM_OWNERSHIP"},
    {no: 26, name: "WITHDRAWAL_REQUEST_REASON_FIRST_MANUAL", localName: "FIRST_MANUAL"},
    {no: 27, name: "WITHDRAWAL_REQUEST_REASON_PAYMENT_METHOD_BLOCKED_FOR_AUTO", localName: "PAYMENT_METHOD_BLOCKED_FOR_AUTO"},
    {no: 28, name: "WITHDRAWAL_REQUEST_REASON_VALIDATION_ERROR", localName: "VALIDATION_ERROR"},
  ],
);

/**
 * Enum representing the risk status of a withdrawal request as determined by the anti-fraud department.
 *
 * @generated from enum pinup.stat.report.withdrawals.WithdrawalRequestRiskStatus
 */
export const WithdrawalRequestRiskStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.withdrawals.WithdrawalRequestRiskStatus",
  [
    {no: 0, name: "WITHDRAWAL_REQUEST_RISK_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WITHDRAWAL_REQUEST_RISK_STATUS_ACCEPTED", localName: "ACCEPTED"},
    {no: 2, name: "WITHDRAWAL_REQUEST_RISK_STATUS_DECLINED", localName: "DECLINED"},
  ],
);

/**
 * Represents the status of the payment in a withdrawal transfer.
 *
 * @generated from enum pinup.stat.report.withdrawals.WithdrawalTransferPaymentStatus
 */
export const WithdrawalTransferPaymentStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.withdrawals.WithdrawalTransferPaymentStatus",
  [
    {no: 0, name: "WITHDRAWAL_TRANSFER_PAYMENT_STATUS_CREATED", localName: "CREATED"},
    {no: 1, name: "WITHDRAWAL_TRANSFER_PAYMENT_STATUS_SUCCESSFUL", localName: "SUCCESSFUL"},
    {no: 2, name: "WITHDRAWAL_TRANSFER_PAYMENT_STATUS_FAILED", localName: "FAILED"},
    {no: 3, name: "WITHDRAWAL_TRANSFER_PAYMENT_STATUS_PROCESS", localName: "PROCESS"},
  ],
);

/**
 * Request message used for filtering and retrieving a list of withdrawal requests with various filter options,
 * pagination, sorting, and field selection capabilities.
 *
 * Tab logic:
 * Finance department:
 * Manual tab: status=[NEW] && is_player_test=false
 * Auto tab: withdrawalStatus=[APPROVED, CANCELED, USER_CANCELED, FAILED, COMPLETED, IN_PROGRESS] && user_ids=[0] && is_player_test=false
 * Test tab: is_player_test=true
 * All tab: empty
 *
 * Risk department:
 * Manual tab: status=[RISK_REVIEWING] && is_player_test=false
 * Test tab: is_risk_assessment=true && is_player_test=true
 * All: is_risk_assessment=true && is_player_test=false
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsRequest
 */
export const ListRequestsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListRequestsRequest_Filter },
    { no: 2, name: "field_mask", kind: "message", T: FieldMask },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 4, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 5, name: "convert_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Nested message for filtering withdrawal requests by reasons related to auto/manual handling.
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsRequest.ReasonMultiselect
 */
export const ListRequestsRequest_ReasonMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsRequest.ReasonMultiselect",
  () => [
    { no: 1, name: "reasons", kind: "enum", T: proto3.getEnumType(WithdrawalRequestReason), repeated: true },
    { no: 2, name: "excluded_reasons", kind: "enum", T: proto3.getEnumType(WithdrawalRequestReason), repeated: true },
  ],
  {localName: "ListRequestsRequest_ReasonMultiselect"},
);

/**
 * Nested message for filtering withdrawal requests by decline reasons.
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsRequest.DeclineReasonMultiselect
 */
export const ListRequestsRequest_DeclineReasonMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsRequest.DeclineReasonMultiselect",
  () => [
    { no: 1, name: "reasons", kind: "enum", T: proto3.getEnumType(WithdrawalRequestDeclineReason), repeated: true },
    { no: 2, name: "excluded_reasons", kind: "enum", T: proto3.getEnumType(WithdrawalRequestDeclineReason), repeated: true },
  ],
  {localName: "ListRequestsRequest_DeclineReasonMultiselect"},
);

/**
 * Filter withdrawals based on the duration (in seconds) they spent under risk review.
 * The duration is calculated as:
 * (if riskCompletedAt != ZERO then riskCompletedAt else now()) - riskCreatedAt in seconds.
 * If both riskCompletedAt and riskCreatedAt are ZERO, the duration is 0.
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsRequest.RiskDuration
 */
export const ListRequestsRequest_RiskDuration = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsRequest.RiskDuration",
  () => [
    { no: 1, name: "gte", kind: "message", T: Int64Value },
  ],
  {localName: "ListRequestsRequest_RiskDuration"},
);

/**
 * Main filter message for narrowing down the list of withdrawal requests based on multiple criteria.
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsRequest.Filter
 */
export const ListRequestsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsRequest.Filter",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "player_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "is_player_vip", kind: "message", T: BoolValue },
    { no: 4, name: "is_player_test", kind: "message", T: BoolValue },
    { no: 5, name: "player_verify_statuses", kind: "enum", T: proto3.getEnumType(VerifyStatus), repeated: true },
    { no: 6, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 7, name: "currency", kind: "message", T: StringMultiselect },
    { no: 8, name: "currency_id", kind: "message", T: Uint64Multiselect },
    { no: 9, name: "withdraw", kind: "message", T: SumRange },
    { no: 10, name: "payout", kind: "message", T: SumRange },
    { no: 11, name: "payment_aggregator", kind: "message", T: Uint64Multiselect },
    { no: 12, name: "payment_method", kind: "message", T: Uint64Multiselect },
    { no: 13, name: "geo", kind: "message", T: StringMultiselect },
    { no: 14, name: "user_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 15, name: "confirm_types", kind: "enum", T: proto3.getEnumType(WithdrawalRequestConfirmType), repeated: true },
    { no: 16, name: "statuses", kind: "enum", T: proto3.getEnumType(WithdrawalRequestStatus), repeated: true },
    { no: 17, name: "is_status_changed", kind: "message", T: BoolValue },
    { no: 18, name: "risk_statuses", kind: "enum", T: proto3.getEnumType(WithdrawalRequestRiskStatus), repeated: true },
    { no: 19, name: "risk_created_at", kind: "message", T: TimeRange },
    { no: 20, name: "risk_completed_at", kind: "message", T: TimeRange },
    { no: 21, name: "risk_initiator_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 22, name: "risk_reviewer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 23, name: "is_risk_assessment", kind: "message", T: BoolValue },
    { no: 24, name: "risk_duration", kind: "message", T: ListRequestsRequest_RiskDuration },
    { no: 25, name: "reason", kind: "message", T: ListRequestsRequest_ReasonMultiselect },
    { no: 26, name: "decline_reason", kind: "message", T: ListRequestsRequest_DeclineReasonMultiselect },
    { no: 27, name: "created_at", kind: "message", T: TimeRange },
    { no: 28, name: "updated_at", kind: "message", T: TimeRange },
    { no: 29, name: "considered_at", kind: "message", T: TimeRange },
  ],
  {localName: "ListRequestsRequest_Filter"},
);

/**
 * Response message containing a list of filtered withdrawal requests, along with
 * pagination information, total withdrawal amounts, and risk-related data.
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsResponse
 */
export const ListRequestsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsResponse",
  () => [
    { no: 1, name: "requests", kind: "message", T: WithdrawalRequest, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 3, name: "total_sum", kind: "message", T: SumCurrency },
    { no: 4, name: "risk_count", kind: "message", T: ListRequestsResponse_RiskCount },
  ],
);

/**
 * Nested message providing information related to the withdrawal requests handled by the risk department.
 *
 * @generated from message pinup.stat.report.withdrawals.ListRequestsResponse.RiskCount
 */
export const ListRequestsResponse_RiskCount = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListRequestsResponse.RiskCount",
  () => [
    { no: 1, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total_duration", kind: "message", T: Duration },
  ],
  {localName: "ListRequestsResponse_RiskCount"},
);

/**
 * Message representing a withdrawal request, containing details about the player, amounts, payment methods,
 * statuses, timestamps, and involvement of the anti-fraud department.
 *
 * @generated from message pinup.stat.report.withdrawals.WithdrawalRequest
 */
export const WithdrawalRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.WithdrawalRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "player_verify_status", kind: "enum", T: proto3.getEnumType(VerifyStatus) },
    { no: 4, name: "player_digitain_status", kind: "enum", T: proto3.getEnumType(DigitainStatus) },
    { no: 5, name: "is_player_vip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_player_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
    { no: 8, name: "finance", kind: "message", T: WithdrawalRequest_Finance },
    { no: 9, name: "finance_converted", kind: "message", T: WithdrawalRequest_Finance },
    { no: 10, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "payment_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "payment_method_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "payment_system_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "payment_aggregator_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 15, name: "status", kind: "enum", T: proto3.getEnumType(WithdrawalRequestStatus) },
    { no: 16, name: "status_changed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "considered_at", kind: "message", T: Timestamp },
    { no: 18, name: "reasons", kind: "enum", T: proto3.getEnumType(WithdrawalRequestReason), repeated: true },
    { no: 19, name: "decline_reason", kind: "enum", T: proto3.getEnumType(WithdrawalRequestDeclineReason) },
    { no: 20, name: "created_at", kind: "message", T: Timestamp },
    { no: 21, name: "updated_at", kind: "message", T: Timestamp },
    { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 23, name: "user_comment_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 24, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "risk_status", kind: "enum", T: proto3.getEnumType(WithdrawalRequestRiskStatus) },
    { no: 26, name: "risk_created_at", kind: "message", T: Timestamp },
    { no: 27, name: "risk_completed_at", kind: "message", T: Timestamp },
    { no: 28, name: "risk_initiator_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 29, name: "risk_reviewer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 30, name: "is_acc_number_mismatch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "summary", kind: "message", T: WithdrawalRequestSummary },
    { no: 32, name: "transfers", kind: "message", T: WithdrawalTransfer, repeated: true },
    { no: 33, name: "confirm_type", kind: "enum", T: proto3.getEnumType(WithdrawalRequestConfirmType) },
  ],
);

/**
 * Nested message that holds the financial information related to a withdrawal request.
 *
 * @generated from message pinup.stat.report.withdrawals.WithdrawalRequest.Finance
 */
export const WithdrawalRequest_Finance = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.WithdrawalRequest.Finance",
  () => [
    { no: 1, name: "payout", kind: "message", T: SumCurrency },
    { no: 2, name: "withdraw", kind: "message", T: SumCurrency },
  ],
  {localName: "WithdrawalRequest_Finance"},
);

/**
 * WithdrawalRequestSummary provides a detailed summary of a player's withdrawal request,
 * itemizing each component related to the requested amount, applicable taxes, commission fees, and final payout.
 * This message aids in financial reporting and clarity for both users and administrators.
 *
 * @generated from message pinup.stat.report.withdrawals.WithdrawalRequestSummary
 */
export const WithdrawalRequestSummary = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.WithdrawalRequestSummary",
  () => [
    { no: 1, name: "taxable_sum", kind: "message", T: SumCurrency },
    { no: 2, name: "taxable_exempt_sum", kind: "message", T: SumCurrency },
    { no: 3, name: "military_tax_sum", kind: "message", T: SumCurrency },
    { no: 4, name: "profit_tax_sum", kind: "message", T: SumCurrency },
    { no: 5, name: "commission_sum", kind: "message", T: SumCurrency },
    { no: 6, name: "payout_sum", kind: "message", T: SumCurrency },
    { no: 7, name: "withdraw_sum", kind: "message", T: SumCurrency },
  ],
);

/**
 * Request message used to retrieve a list of withdrawal transfers based on various filters.
 *
 * @generated from message pinup.stat.report.withdrawals.ListTransfersRequest
 */
export const ListTransfersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListTransfersRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListTransfersRequest_Filter },
    { no: 2, name: "field_mask", kind: "message", T: FieldMask },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 4, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 5, name: "convert_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Defines the available filters to apply when retrieving withdrawal transfers.
 *
 * @generated from message pinup.stat.report.withdrawals.ListTransfersRequest.Filter
 */
export const ListTransfersRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListTransfersRequest.Filter",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "payment_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "group_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 4, name: "player_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 5, name: "is_test", kind: "message", T: BoolValue },
    { no: 6, name: "currency", kind: "message", T: StringMultiselect },
    { no: 7, name: "currency_id", kind: "message", T: Uint64Multiselect },
    { no: 8, name: "payment_statues", kind: "enum", T: proto3.getEnumType(WithdrawalTransferPaymentStatus), repeated: true },
    { no: 9, name: "player_balance", kind: "message", T: SumRange },
    { no: 10, name: "player_balance_after", kind: "message", T: SumRange },
    { no: 11, name: "amount", kind: "message", T: SumRange },
    { no: 12, name: "created_at", kind: "message", T: TimeRange },
    { no: 13, name: "updated_at", kind: "message", T: TimeRange },
    { no: 14, name: "is_taxable", kind: "message", T: BoolValue },
  ],
  {localName: "ListTransfersRequest_Filter"},
);

/**
 * Response message containing a list of withdrawal transfers based on the provided filters.
 *
 * @generated from message pinup.stat.report.withdrawals.ListTransfersResponse
 */
export const ListTransfersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.ListTransfersResponse",
  () => [
    { no: 1, name: "transfers", kind: "message", T: WithdrawalTransfer, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * Represents a withdrawal transfer request. This includes details such as the
 * financial information related to the withdrawal, player details, currency,
 * payment status, and timestamps for tracking the creation and updates of the transfer.
 *
 * @generated from message pinup.stat.report.withdrawals.WithdrawalTransfer
 */
export const WithdrawalTransfer = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.WithdrawalTransfer",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "payment_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "finance", kind: "message", T: WithdrawalTransfer_Finance },
    { no: 7, name: "finance_converted", kind: "message", T: WithdrawalTransfer_Finance },
    { no: 8, name: "payment_status", kind: "enum", T: proto3.getEnumType(WithdrawalTransferPaymentStatus) },
    { no: 9, name: "holding_status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "created_at", kind: "message", T: Timestamp },
    { no: 11, name: "updated_at", kind: "message", T: Timestamp },
    { no: 12, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "wait", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "is_taxable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message pinup.stat.report.withdrawals.WithdrawalTransfer.Finance
 */
export const WithdrawalTransfer_Finance = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.withdrawals.WithdrawalTransfer.Finance",
  () => [
    { no: 1, name: "balance", kind: "message", T: SumCurrency },
    { no: 2, name: "balance_after", kind: "message", T: SumCurrency },
    { no: 3, name: "amount", kind: "message", T: SumCurrency },
    { no: 4, name: "withdraw", kind: "message", T: SumCurrency },
    { no: 5, name: "commission", kind: "message", T: SumCurrency },
    { no: 6, name: "military_tax", kind: "message", T: SumCurrency },
    { no: 7, name: "profit_tax", kind: "message", T: SumCurrency },
  ],
  {localName: "WithdrawalTransfer_Finance"},
);

