<mat-expansion-panel class="expansion-element" expanded togglePosition="before">
  <mat-expansion-panel-header class="expansion-element-header">
    <h3>
      <span class="title-label">Active sessions</span>
      @if ((activeSessionsTotal$ | async)?.totalItems; as total) {
        <span class="total-items">{{ total }}</span>
      }
    </h3>
  </mat-expansion-panel-header>
  <div class="active-sessions">
    <table
      mat-table
      class="table-element"
      (matSortChange)="setSort(sessionType.ACTIVE, $event)"
      [dataSource]="sortedActiveSessions$"
      [hidden]="!(activeSessionsTotal$ | async)?.totalItems"
      matSort
    >
      <ng-container [matColumnDef]="profileSessionsColumns.IP">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>IP</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.ipAddress }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.DEVICE">
        <th *matHeaderCellDef mat-header-cell>
          <div class="cell-align-right">Device</div>
        </th>
        <td *matCellDef="let element" mat-cell>
          {{ element.device }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.LOCATION">
        <th *matHeaderCellDef mat-header-cell>Location</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.location }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.DATE">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
        <td *matCellDef="let element" mat-cell>
          <bo-date-time [value]="element.createdAt" />
        </td>
      </ng-container>

      <tr *matHeaderRowDef="iterableColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: iterableColumns" mat-row></tr>
    </table>

    @if (shouldShowToggleBtn$ | async) {
      <button
        mat-stroked-button
        class="button show-btn mt-3"
        (click)="toggleActiveSessions()"
        data-testid="showMoreSessions"
        type="button"
      >
        {{ (isAllActiveSessions$ | async) ? 'Show less' : 'Show all' }}
      </button>
    }

    @if (!(activeSessionsTotal$ | async)?.totalItems) {
      <bo-empty-template [isError]="true" message="No sessions found" />
    }
  </div>
</mat-expansion-panel>

<mat-expansion-panel class="expansion-element" expanded togglePosition="before">
  <mat-expansion-panel-header class="expansion-element-header">
    <h3>
      <span class="title-label">Inactive sessions</span>
      @if ((inactiveSessionsTotal$ | async)?.totalItems; as total) {
        <span class="total-items">{{ total }}</span>
      }
    </h3>
  </mat-expansion-panel-header>
  <table
    mat-table
    class="table-element"
    (matSortChange)="setSort(sessionType.INACTIVE, $event)"
    [dataSource]="sortedInactiveSessions$"
    [hidden]="!(inactiveSessionsTotal$ | async)?.totalItems"
    matSort
  >
    <ng-container [matColumnDef]="profileSessionsColumns.IP">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>IP</th>
      <td *matCellDef="let element" mat-cell>
        <span>{{ element.ipAddress }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="profileSessionsColumns.DEVICE">
      <th *matHeaderCellDef mat-header-cell>
        <div class="cell-align-right">Device</div>
      </th>
      <td *matCellDef="let element" mat-cell>
        <span>{{ element.device }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="profileSessionsColumns.LOCATION">
      <th *matHeaderCellDef mat-header-cell>Location</th>
      <td *matCellDef="let element" mat-cell>
        <span>{{ element.location }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="profileSessionsColumns.DATE">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
      <td *matCellDef="let element" mat-cell>
        <bo-date-time [value]="element.createdAt" />
      </td>
    </ng-container>

    <tr *matHeaderRowDef="iterableColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: iterableColumns" mat-row></tr>
  </table>
  @if (inactiveSessionsTotal$ | async; as inactiveTotalInfo) {
    @if (pagination$ | async; as inactivePagination) {
      @if (inactiveTotalInfo.totalPages! >= 1) {
        <bo-pagination-panel
          (boPaginationChange)="setPagination($event)"
          [boPagination]="inactivePagination"
          [totalItems]="inactiveTotalInfo.totalItems"
          [totalPages]="inactiveTotalInfo.totalPages"
        />
      }
    }
  }

  @if (!(inactiveSessionsTotal$ | async)?.totalItems) {
    <bo-empty-template [isError]="true" message="No sessions found" />
  }
</mat-expansion-panel>
