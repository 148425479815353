// @generated by protoc-gen-es v1.10.0
// @generated from file base/filter.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, Int64Value, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Time Range
 *
 * @generated from message stat.TimeRange
 */
export const TimeRange = /*@__PURE__*/ proto3.makeMessageType(
  "stat.TimeRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ],
);

/**
 * Duration Range
 *
 * @generated from message stat.DurationRange
 */
export const DurationRange = /*@__PURE__*/ proto3.makeMessageType(
  "stat.DurationRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Duration },
    { no: 2, name: "to", kind: "message", T: Duration },
  ],
);

/**
 * Sum range in coins
 *
 * @generated from message stat.Int64Range
 */
export const Int64Range = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Int64Range",
  () => [
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "from", kind: "message", T: Int64Value },
    { no: 2, name: "to", kind: "message", T: Int64Value },
  ],
);

/**
 * @generated from message stat.SumRange
 */
export const SumRange = /*@__PURE__*/ proto3.makeMessageType(
  "stat.SumRange",
  () => [
    { no: 2, name: "from", kind: "message", T: Int64Value },
    { no: 3, name: "to", kind: "message", T: Int64Value },
  ],
);

/**
 * Search by occurrence of a string
 *
 * @generated from message stat.StringSearch
 */
export const StringSearch = /*@__PURE__*/ proto3.makeMessageType(
  "stat.StringSearch",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "exactMatch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Search by occurrence of strings
 *
 * @generated from message stat.StringsSearch
 */
export const StringsSearch = /*@__PURE__*/ proto3.makeMessageType(
  "stat.StringsSearch",
  () => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "exactMatch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

