import { formatNumber } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'amount-formatter',
  standalone: true,
  templateUrl: './amount-formatter.component.html',
  styleUrl: './amount-formatter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountFormatterComponent {
  public readonly numberToFormat = input.required<number | string>();
  public readonly sign = input<string>();
  public readonly showFractionalPart = input(true, {
    transform: booleanAttribute,
  });

  private readonly formattedString = computed(
    () => formatNumber(this.formattedNumber(), 'en-US', '1.0-2') || '0.00',
  );

  public formattedNumber = computed(() => Number(this.numberToFormat()) / 100);

  public integerPart = computed(() => this.formattedString().split('.')[0]);

  public decimalPart = computed(() =>
    this.formattedString().includes('.')
      ? `.${this.formattedString().split('.')[1]}`
      : '.00',
  );
}
