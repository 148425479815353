// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/backoffice/documents/documents.proto (package kycaid.backoffice.documents, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetDocumentImageRequest, GetDocumentImageResponse, GetPlayerDocumentsRequest, GetPlayerDocumentsResponse } from "./documents_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service kycaid.backoffice.documents.DocumentService
 */
@Injectable({ providedIn: "root" })
export class DocumentRemoteService {
  public static readonly typeName = "kycaid.backoffice.documents.DocumentService";

  public static readonly methods = {
    getPlayerDocuments: {
      name: "GetPlayerDocuments",
      I: GetPlayerDocumentsRequest,
      O: GetPlayerDocumentsResponse,
      kind: MethodKind.Unary,
    },
    getDocumentImage: {
      name: "GetDocumentImage",
      I: GetDocumentImageRequest,
      O: GetDocumentImageResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc kycaid.backoffice.documents.DocumentService.GetPlayerDocuments
   */
  public getPlayerDocuments(
    input: PartialMessage<GetPlayerDocumentsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerDocumentsResponse> {
    return unary<GetPlayerDocumentsRequest, GetPlayerDocumentsResponse>(
      this.transport,
      DocumentRemoteService,
      DocumentRemoteService.methods.getPlayerDocuments,
      input,
      options,
    );
  }

  /**
   * @generated from rpc kycaid.backoffice.documents.DocumentService.GetDocumentImage
   */
  public getDocumentImage(
    input: PartialMessage<GetDocumentImageRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDocumentImageResponse> {
    return unary<GetDocumentImageRequest, GetDocumentImageResponse>(
      this.transport,
      DocumentRemoteService,
      DocumentRemoteService.methods.getDocumentImage,
      input,
      options,
    );
  }
}
