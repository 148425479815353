import type { Provider } from '@angular/core';

export enum FeatureFlagsFeatureKind {
  ToggleMenuFeature = 'ToggleMenuFeature',
  AdapterFeature = 'AdapterFeature',
}

export type FeatureFlagsFeature<FeatureKind extends FeatureFlagsFeatureKind> =
  Readonly<{
    kind: FeatureKind;
    providers: Provider[];
  }>;
