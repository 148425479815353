import type { Currency, Operator, PaymentMethod } from '@pu/grpc';

import type { Country } from './country';

export const countryValueSelector = (item: Country): string => {
  return item.alpha2;
};

export const currencyValueSelector = (item: Currency): string => {
  return item.code;
};

export const multipleCurrencyValueSelector = (item: Currency): bigint => {
  return item.id;
};

export const operatorValueSelector = (item: Operator): bigint => {
  return item.id;
};

export const paymentMethodValueSelector = (item: PaymentMethod): bigint => {
  return item.id;
};

export const keyValueSelector =
  <T>() =>
  ({ key }: { key: T; value: string }): T =>
    key;
