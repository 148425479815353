// @generated by protoc-gen-es v1.10.0
// @generated from file services/player_info.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Int64Range, TimeRange } from "../base/filter_pb.js";
import { Action, Game, Method, Money, Project } from "../base/enum_pb.js";
import { Error } from "../base/error_pb.js";

/**
 * @generated from message stat.PlayerShort
 */
export const PlayerShort = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerShort",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "registration", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.Player
 */
export const Player = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Player",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 37, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "geoSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "registration", kind: "message", T: Timestamp },
    { no: 8, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "lastLogin", kind: "message", T: Timestamp },
    { no: 10, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "emailVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "emailVerifiedStatus", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 15, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "agent", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 17, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "fullProfile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "accountVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "accountVerifiedStatus", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 22, name: "smsStatus", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 23, name: "casinoStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "bettingStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "casinoBet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "betCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 27, name: "sportBet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "betCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 29, name: "deposit", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "depositCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 31, name: "depositSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 32, name: "balance", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 33, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 34, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 35, name: "autoWithdrawal", kind: "message", T: AutoWithdrawal },
    { no: 36, name: "partnerInfo", kind: "message", T: PartnerInfo },
  ],
);

/**
 * @generated from message stat.PartnerInfo
 */
export const PartnerInfo = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PartnerInfo",
  () => [
    { no: 1, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subId2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "instId", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message stat.AutoWithdrawal
 */
export const AutoWithdrawal = /*@__PURE__*/ proto3.makeMessageType(
  "stat.AutoWithdrawal",
  () => [
    { no: 1, name: "off", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "offReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "offUpdated", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.PlayerFilter
 */
export const PlayerFilter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerFilter",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange },
    { no: 2, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 3, name: "actions", kind: "enum", T: proto3.getEnumType(Action), repeated: true },
    { no: 4, name: "methods", kind: "enum", T: proto3.getEnumType(Method), repeated: true },
    { no: 5, name: "moneys", kind: "enum", T: proto3.getEnumType(Money), repeated: true },
    { no: 6, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "gameTypes", kind: "enum", T: proto3.getEnumType(Game), repeated: true },
    { no: 8, name: "sum", kind: "message", T: Int64Range },
    { no: 9, name: "regions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "excludeTestPlayers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "registered", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 12, name: "firstDeposit", kind: "message", T: PlayerFilter_FirstDeposit },
    { no: 13, name: "player_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message stat.PlayerFilter.FirstDeposit
 */
export const PlayerFilter_FirstDeposit = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerFilter.FirstDeposit",
  () => [
    { no: 1, name: "firstDepositProject", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 2, name: "firstDepositAmount", kind: "message", T: Int64Range },
  ],
  {localName: "PlayerFilter_FirstDeposit"},
);

/**
 * @generated from message stat.GetPlayersIdRequest
 */
export const GetPlayersIdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayersIdRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: PlayerFilter },
    { no: 2, name: "limit", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "fromId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.GetPlayersIdResponse
 */
export const GetPlayersIdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayersIdResponse",
  () => [
    { no: 1, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetPlayersInfoRequest
 */
export const GetPlayersInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayersInfoRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: PlayerFilter },
    { no: 2, name: "fromId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "limit", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.GetPlayersInfoResponse
 */
export const GetPlayersInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayersInfoResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: Player, repeated: true },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetPlayersInfoShortRequest
 */
export const GetPlayersInfoShortRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayersInfoShortRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: PlayerFilter },
    { no: 2, name: "fromId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "limit", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.GetPlayersInfoShortResponse
 */
export const GetPlayersInfoShortResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayersInfoShortResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: PlayerShort, repeated: true },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

