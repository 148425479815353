import type { RadioToggleOption } from '../boolean-option.utils';

export enum DichotomyAnswer {
  Yes = 'Yes',
  No = 'No',
}

export const dichotomyAnswerOptions: readonly RadioToggleOption<
  string,
  DichotomyAnswer
>[] = [
  { name: 'Yes', value: DichotomyAnswer.Yes },
  { name: 'No', value: DichotomyAnswer.No },
];
