import type { TemplateRef } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { filter, map, ReplaySubject } from 'rxjs';

import type {
  TransferContentPayload,
  TransferredContentId,
} from './transfer-content.model';

@Injectable({ providedIn: 'root' })
export class TransferContentService {
  private readonly transferContent$ = new ReplaySubject<TransferContentPayload>(
    1,
  );

  public transferContent(
    id: TransferredContentId,
    template: TemplateRef<unknown> | null,
  ): void {
    this.transferContent$.next({ id, template });
  }

  public getTransferContentSource$(
    id: TransferredContentId,
  ): Observable<TemplateRef<unknown> | null> {
    return this.transferContent$.pipe(
      filter((contentPayload) => contentPayload.id == id),
      map(({ template }) => template),
    );
  }
}
