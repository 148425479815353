// @generated by protoc-gen-es v1.10.0
// @generated from file models/refund/payments_models_refund.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { MoneyOutStatus } from "../moneyout/status_pb.js";

/**
 * @generated from enum payments.RefundStatus
 */
export const RefundStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.RefundStatus",
  [
    {no: 0, name: "ReadyToRefund"},
    {no: 1, name: "InProgress"},
    {no: 2, name: "Refunded"},
    {no: 3, name: "AutoRejected"},
    {no: 4, name: "Canceled"},
  ],
);

/**
 * @generated from message payments.RefundDeposit
 */
export const RefundDeposit = /*@__PURE__*/ proto3.makeMessageType(
  "payments.RefundDeposit",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "depositCreated", kind: "message", T: Timestamp },
    { no: 4, name: "refundStatus", kind: "enum", T: proto3.getEnumType(RefundStatus) },
    { no: 5, name: "moneyOutID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "moneyOutStatus", kind: "enum", T: proto3.getEnumType(MoneyOutStatus) },
    { no: 7, name: "depositID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "currency", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "externalID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "aggregator", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "paymentMethod", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "externalPaymentSystemID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "cashboxComment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "cardNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

