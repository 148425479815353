// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/deposits/deposits.proto (package payments.backoffice.deposits, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum payments.backoffice.deposits.DepositError
 */
export const DepositError = /*@__PURE__*/ proto3.makeEnum(
  "payments.backoffice.deposits.DepositError",
  [
    {no: 0, name: "DE_UNSPECIFIED"},
    {no: 1, name: "DE_PROJECT_BONUS_RESTRICTION"},
    {no: 2, name: "DE_OPERATION_LIMIT_EXCEEDED"},
    {no: 3, name: "DE_OPERATION_LIMIT_UNSPECIFIED"},
  ],
);

/**
 * @generated from message payments.backoffice.deposits.AddDepositRequest
 */
export const AddDepositRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.deposits.AddDepositRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "deposit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isCommentPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "withBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "withLottery", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "isBetProject", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "useInTaxCalculation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Error reasons: DE_PROJECT_BONUS_RESTRICTION, DE_OPERATION_LIMIT_EXCEEDED, DE_OPERATION_LIMIT_UNSPECIFIED
 *
 * @generated from message payments.backoffice.deposits.AddDepositResponse
 */
export const AddDepositResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.deposits.AddDepositResponse",
  [],
);

