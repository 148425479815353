import { InjectionToken } from '@angular/core';

export const DIALOG_NOTE_MIN_LENGTH = new InjectionToken<number>(
  'DIALOG_NOTE_MIN_LENGTH',
  { providedIn: 'root', factory: () => 4 },
);

export const DIALOG_NOTE_MAX_LENGTH = new InjectionToken<number>(
  'DIALOG_NOTE_MAX_LENGTH',
  { providedIn: 'root', factory: () => 70 },
);

export const DIALOG_NOTE_PINNED = new InjectionToken<boolean>(
  'DIALOG_NOTE_PINNED',
  { providedIn: 'root', factory: () => false },
);

export const DIALOG_NOTE_COMMENT = new InjectionToken<string>(
  'DIALOG_NOTE_COMMENT',
  { providedIn: 'root', factory: () => '' },
);
