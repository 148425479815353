import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
} from '@angular/core';

@Directive({
  selector: '[puFullscreen]',
  standalone: true,
  exportAs: 'puFullscreen',
})
export class FullscreenDirective {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly document = inject(DOCUMENT);
  private readonly host =
    inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;

  get isFullscreen(): boolean {
    return this.host === this.document.fullscreenElement;
  }

  public async toggle(): Promise<void> {
    if (this.isFullscreen) {
      await this.document.exitFullscreen();
    } else {
      await this.host.requestFullscreen();
    }

    this.cdr.markForCheck();
  }
}
