import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { TransactionVM } from '../balance-history/transaction.vm';
import { injectCurrencyConverted } from '../tokens/currency-converted.token';

@Pipe({
  name: 'extractCurrency',
  standalone: true,
  pure: false,
})
export class ExtractCurrencyPipe implements PipeTransform {
  private readonly currencyConverted = injectCurrencyConverted();

  public transform(value: TransactionVM): string {
    const finance = this.currencyConverted()
      ? value.convertFinance
      : value.finance;

    return finance.currency;
  }
}
