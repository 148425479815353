// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file events/antifraud_update.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { RequestForATFPaymentScoring, ResponseForATFPaymentScoring } from "./antifraud_update_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.ATFPaymentService
 */
@Injectable({ providedIn: "root" })
export class ATFPaymentRemoteService {
  public static readonly typeName = "payments.ATFPaymentService";

  public static readonly methods = {
    successPaymentEvent: {
      name: "SuccessPaymentEvent",
      I: RequestForATFPaymentScoring,
      O: ResponseForATFPaymentScoring,
      kind: MethodKind.Unary,
    },
    failedPaymentEvent: {
      name: "FailedPaymentEvent",
      I: RequestForATFPaymentScoring,
      O: ResponseForATFPaymentScoring,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.ATFPaymentService.SuccessPaymentEvent
   */
  public successPaymentEvent(
    input: PartialMessage<RequestForATFPaymentScoring>,
    options?: ReactiveTransportOptions,
  ): Observable<ResponseForATFPaymentScoring> {
    return unary<RequestForATFPaymentScoring, ResponseForATFPaymentScoring>(
      this.transport,
      ATFPaymentRemoteService,
      ATFPaymentRemoteService.methods.successPaymentEvent,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.ATFPaymentService.FailedPaymentEvent
   */
  public failedPaymentEvent(
    input: PartialMessage<RequestForATFPaymentScoring>,
    options?: ReactiveTransportOptions,
  ): Observable<ResponseForATFPaymentScoring> {
    return unary<RequestForATFPaymentScoring, ResponseForATFPaymentScoring>(
      this.transport,
      ATFPaymentRemoteService,
      ATFPaymentRemoteService.methods.failedPaymentEvent,
      input,
      options,
    );
  }
}
