import { caseInsensitiveHighlight } from '../case-insensitive-highlight';
import { haveHighlightedParts } from '../compare-highlighted';
import type { AutocompleteEqual } from './autocomplete-equal.type';

export function highlightBy<T>(
  primarySelector: (value: T) => string,
  ...fallbackSelectors: readonly ((value: T) => string)[]
): AutocompleteEqual<T> {
  return (value, search) => {
    const primaryHighlighted = caseInsensitiveHighlight(
      primarySelector(value),
      search,
    );

    if (!haveHighlightedParts(primaryHighlighted)) {
      for (const fallbackSelector of fallbackSelectors) {
        const highlighted = caseInsensitiveHighlight(
          fallbackSelector(value),
          search,
        );

        if (haveHighlightedParts(highlighted)) {
          return highlighted;
        }
      }
    }

    return primaryHighlighted;
  };
}
