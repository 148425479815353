// @generated by protoc-gen-es v1.10.0
// @generated from file common/sorting.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kycaid.Sorting
 */
export const Sorting = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.Sorting",
  () => [
    { no: 1, name: "field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(Sorting_Order) },
  ],
);

/**
 * @generated from enum kycaid.Sorting.Order
 */
export const Sorting_Order = /*@__PURE__*/ proto3.makeEnum(
  "kycaid.Sorting.Order",
  [
    {no: 0, name: "ASC"},
    {no: 1, name: "DESC"},
  ],
);

