// @generated by protoc-gen-es v1.10.0
// @generated from file base/insvc/v1/player.proto (package gamification.base.insvc.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of message for player info
 *
 * @generated from message gamification.base.insvc.v1.PlayerInfo
 */
export const PlayerInfo = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.base.insvc.v1.PlayerInfo",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "avatar_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

