// @generated by protoc-gen-es v1.10.0
// @generated from file models/moneyout/moneyout.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { MoneyOutConfirmType, MoneyOutPaymentStatus, MoneyOutRiskStatus, MoneyOutStatus } from "./status_pb.js";
import { MoneyOutReason } from "./reason_pb.js";
import { MoneyOutDeclineReason } from "./decline_reason_pb.js";

/**
 * @generated from message payments.MoneyOutStruct
 */
export const MoneyOutStruct = /*@__PURE__*/ proto3.makeMessageType(
  "payments.MoneyOutStruct",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "paymentID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "walID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "playerBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "playerBalanceAfter", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "playerIP", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "documentVerified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "payout", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "withdraw", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "paymentStatus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "changed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "moneyOutLastTextStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "userID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 19, name: "userComment", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 20, name: "accountSessionID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 21, name: "paymentSystem", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 22, name: "externalPaymentsSystemID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "externalID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "paymentAggregator", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 25, name: "paymentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "monetOutNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "moneyOutRegion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "created", kind: "message", T: Timestamp },
    { no: 29, name: "updated", kind: "message", T: Timestamp },
    { no: 30, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "wait", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "method", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 35, name: "serverTransactionStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "completePay", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 37, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 38, name: "highDepositAmount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 39, name: "fullAmount", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 40, name: "confirmType", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 41, name: "riskAllow", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 42, name: "transactionToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 43, name: "webkassaCheckNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 44, name: "webkassaTicketURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 45, name: "webkassaTicketPrintURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46, name: "vip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 47, name: "isTaxable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 48, name: "militaryTaxAmount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 49, name: "profitTaxAmount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 50, name: "taxTransactionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 51, name: "holdingMoneyStatus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 52, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 53, name: "isRefund", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message payments.MoneyOutDto
 */
export const MoneyOutDto = /*@__PURE__*/ proto3.makeMessageType(
  "payments.MoneyOutDto",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "player_balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "player_balance_after", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "player_verification_status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "is_player_vip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "currency_str", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "status", kind: "enum", T: proto3.getEnumType(MoneyOutStatus) },
    { no: 13, name: "confirm_type", kind: "enum", T: proto3.getEnumType(MoneyOutConfirmType) },
    { no: 14, name: "payment_status", kind: "enum", T: proto3.getEnumType(MoneyOutPaymentStatus) },
    { no: 15, name: "payment_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "payment_aggregator_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 17, name: "payment_method_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 18, name: "payment_system_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 20, name: "user_comment_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 21, name: "reasons", kind: "enum", T: proto3.getEnumType(MoneyOutReason), repeated: true },
    { no: 22, name: "decline_reason", kind: "enum", T: proto3.getEnumType(MoneyOutDeclineReason) },
    { no: 23, name: "created_at", kind: "message", T: Timestamp },
    { no: 24, name: "updated_at", kind: "message", T: Timestamp },
    { no: 25, name: "wait", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "commission", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "risk_status", kind: "enum", T: proto3.getEnumType(MoneyOutRiskStatus) },
    { no: 29, name: "risk_created_at", kind: "message", T: Timestamp },
    { no: 30, name: "risk_completed_at", kind: "message", T: Timestamp },
    { no: 31, name: "status_changed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "considered_at", kind: "message", T: Timestamp },
    { no: 33, name: "risk_initiator_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 34, name: "risk_reviewer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 35, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 36, name: "withdrawal_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "fp_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

