import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'byId',
  standalone: true,
})
export class ByIdPipe implements PipeTransform {
  public transform<I, T extends { readonly id: I }>(
    list: readonly T[] | null | undefined,
    id: I,
  ): T | null {
    if (isNil(list)) {
      return null;
    }

    return list.find((item) => item.id === id) ?? null;
  }
}
