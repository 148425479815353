import { Directive, forwardRef, input } from '@angular/core';
import type { ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';

import { provideMultiExisting } from '../util/provide';
import { strictMinLengthValidator } from './strict-min-length.validator';
import { ValidatorDirective } from './validator.directive';

@Directive({
  selector: '[puStrictMinLength]',
  standalone: true,
  providers: [
    provideMultiExisting(
      NG_VALIDATORS,
      forwardRef(() => StrictMinLengthDirective),
    ),
  ],
})
export class StrictMinLengthDirective extends ValidatorDirective {
  public readonly requiredLength = input.required<number>({
    alias: 'puStrictMinLength',
  });

  protected createValidator(): ValidatorFn {
    return strictMinLengthValidator(this.requiredLength());
  }
}
