// @generated by protoc-gen-es v1.10.0
// @generated from file services/playerProfilePermissions.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Error } from "../base/error_collision_0_pb.js";

/**
 * @generated from message players.GetEmailChangePermissionRequest
 */
export const GetEmailChangePermissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetEmailChangePermissionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetEmailChangePermissionResponse
 */
export const GetEmailChangePermissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetEmailChangePermissionResponse",
  () => [
    { no: 1, name: "permission", kind: "message", T: Permission },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPhoneChangePermissionRequest
 */
export const GetPhoneChangePermissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPhoneChangePermissionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPhoneChangePermissionResponse
 */
export const GetPhoneChangePermissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPhoneChangePermissionResponse",
  () => [
    { no: 1, name: "permission", kind: "message", T: Permission },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.Permission
 */
export const Permission = /*@__PURE__*/ proto3.makeMessageType(
  "players.Permission",
  () => [
    { no: 1, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message players.IncreaseEmailCounterRequest
 */
export const IncreaseEmailCounterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.IncreaseEmailCounterRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.IncreaseEmailCounterResponse
 */
export const IncreaseEmailCounterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.IncreaseEmailCounterResponse",
  () => [
    { no: 1, name: "emailChangesCounter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.IncreasePhoneCounterRequest
 */
export const IncreasePhoneCounterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.IncreasePhoneCounterRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.IncreasePhoneCounterResponse
 */
export const IncreasePhoneCounterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.IncreasePhoneCounterResponse",
  () => [
    { no: 1, name: "phoneChangesCounter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.SetPhoneChangePermissionRequest
 */
export const SetPhoneChangePermissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetPhoneChangePermissionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message players.SetPhoneChangePermissionResponse
 */
export const SetPhoneChangePermissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetPhoneChangePermissionResponse",
  [],
);

/**
 * @generated from message players.SetEmailChangePermissionRequest
 */
export const SetEmailChangePermissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetEmailChangePermissionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message players.SetEmailChangePermissionResponse
 */
export const SetEmailChangePermissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetEmailChangePermissionResponse",
  [],
);

