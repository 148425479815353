import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import type { HighlightedText } from '@pu/sdk';

@Component({
  selector: 'bo-multi-highlight-match',
  standalone: true,
  templateUrl: './multi-highlight-match.component.html',
  styleUrl: './multi-highlight-match.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiHighlightMatchComponent {
  public readonly highlight = input.required<HighlightedText>();
}
