import type { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';

// Think how to make FormGroup here more sophisticated regarding types
export type FormControls<T> = {
  [K in keyof T]: FormControl<T[K] | null> | FormGroup;
};

export class BoFormGroup<T extends {}> extends FormGroup<FormControls<T>> {
  public override value: T;
}
