// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/authHash.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { AuthorizationByHashRequest, AuthorizationByHashResponse } from "./authHash_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service players.AuthHash
 */
@Injectable({ providedIn: "root" })
export class AuthHashRemoteService {
  public static readonly typeName = "players.AuthHash";

  public static readonly methods = {
    authorizationByHash: {
      name: "AuthorizationByHash",
      I: AuthorizationByHashRequest,
      O: AuthorizationByHashResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc players.AuthHash.AuthorizationByHash
   */
  public authorizationByHash(
    input: PartialMessage<AuthorizationByHashRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AuthorizationByHashResponse> {
    return unary<AuthorizationByHashRequest, AuthorizationByHashResponse>(
      this.transport,
      AuthHashRemoteService,
      AuthHashRemoteService.methods.authorizationByHash,
      input,
      options,
    );
  }
}
