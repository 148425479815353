export type CancelReasonsNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const CANCEL_REASONS_WITH_COMMENTS: CancelReasonsNumber = 9;

export enum CancelReasons {
  Unspecified = 'Unspecified',
  TurnoverNeeded = 'Turnover needed',
  VerificationNeeded = 'Verification needed',
  methodsNotMatched = 'Deposits / Withdrawal methods not matched',
  SelfieNeeded = 'Selfie needed',
  FullVerification = 'Full verification',
  RecreateTheWithdrawal = 'Recreate the withdrawal',
  FullProfileNeeded = 'Full profile needed',
  MethodNotAvailable = 'Method not available',
  Other = 'Other',
}

export const CANCEL_REASONS_ALL: Record<CancelReasons, CancelReasonsNumber> = {
  [CancelReasons.Unspecified]: 0,
  [CancelReasons.TurnoverNeeded]: 1,
  [CancelReasons.VerificationNeeded]: 2,
  [CancelReasons.methodsNotMatched]: 3,
  [CancelReasons.SelfieNeeded]: 4,
  [CancelReasons.FullVerification]: 5,
  [CancelReasons.RecreateTheWithdrawal]: 6,
  [CancelReasons.FullProfileNeeded]: 7,
  [CancelReasons.MethodNotAvailable]: 8,
  [CancelReasons.Other]: 9,
};
