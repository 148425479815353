// @generated by protoc-gen-es v1.10.0
// @generated from file service/bo/achievement/v1/achievement.proto (package gamification.achievement_manager.service.bo.achievement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Achievement, AchievementLangContent } from "../../../../base/bo/v1/achievement_pb.js";
import { AccomplishmentOrder, AchievementAction, AchievementCopyAction, AchievementCreateAction, AchievementStatus, AchievementUpdateAction } from "../../../../base/bo/v1/achievement_enum_pb.js";
import { ComparisonOperator } from "../../../../base/bo/v1/task_enum_pb.js";
import { Event, Task } from "../../../../base/bo/v1/task_pb.js";

/**
 * request to create new achievement
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CreateRequest
 */
export const CreateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CreateRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AchievementLangContent} },
    { no: 8, name: "tasks", kind: "message", T: CreateRequest_Task, repeated: true },
    { no: 9, name: "accomplishment_order", kind: "enum", T: proto3.getEnumType(AccomplishmentOrder) },
    { no: 10, name: "action", kind: "enum", T: proto3.getEnumType(AchievementCreateAction) },
  ],
);

/**
 * The copy of task from base without id.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CreateRequest.Task
 */
export const CreateRequest_Task = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CreateRequest.Task",
  () => [
    { no: 1, name: "sequence_number", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "conditions", kind: "message", T: CreateRequest_Condition, repeated: true },
  ],
  {localName: "CreateRequest_Task"},
);

/**
 * The copy of condition from base without id.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CreateRequest.Condition
 */
export const CreateRequest_Condition = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CreateRequest.Condition",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "comparison_operator", kind: "enum", T: proto3.getEnumType(ComparisonOperator), opt: true },
    { no: 3, name: "target_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "repetitions", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
  ],
  {localName: "CreateRequest_Condition"},
);

/**
 * response for create achievement
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CreateResponse
 */
export const CreateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CreateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * request to copy new achievement
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CopyRequest
 */
export const CopyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CopyRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AchievementLangContent} },
    { no: 8, name: "tasks", kind: "message", T: CopyRequest_Task, repeated: true },
    { no: 9, name: "accomplishment_order", kind: "enum", T: proto3.getEnumType(AccomplishmentOrder) },
    { no: 10, name: "action", kind: "enum", T: proto3.getEnumType(AchievementCopyAction) },
  ],
);

/**
 * The copy of task from base without id.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CopyRequest.Task
 */
export const CopyRequest_Task = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CopyRequest.Task",
  () => [
    { no: 1, name: "sequence_number", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 2, name: "conditions", kind: "message", T: CopyRequest_Condition, repeated: true },
  ],
  {localName: "CopyRequest_Task"},
);

/**
 * The copy of condition from base without id.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CopyRequest.Condition
 */
export const CopyRequest_Condition = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CopyRequest.Condition",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "comparison_operator", kind: "enum", T: proto3.getEnumType(ComparisonOperator), opt: true },
    { no: 3, name: "target_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "repetitions", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
  ],
  {localName: "CopyRequest_Condition"},
);

/**
 * response for copy achievement
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.CopyResponse
 */
export const CopyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.CopyResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * request to update new achievement
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.UpdateRequest
 */
export const UpdateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.UpdateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AchievementLangContent} },
    { no: 9, name: "tasks", kind: "message", T: Task, repeated: true },
    { no: 10, name: "accomplishment_order", kind: "enum", T: proto3.getEnumType(AccomplishmentOrder) },
    { no: 11, name: "action", kind: "enum", T: proto3.getEnumType(AchievementUpdateAction) },
  ],
);

/**
 * response for update achievement
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.UpdateResponse
 */
export const UpdateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.UpdateResponse",
  [],
);

/**
 * request for get achievement info
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.GetRequest
 */
export const GetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.GetRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for get achievement info
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.GetResponse
 */
export const GetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.GetResponse",
  () => [
    { no: 1, name: "achievement", kind: "message", T: Achievement },
  ],
);

/**
 * request all achievements
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.ListRequest
 */
export const ListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.ListRequest",
  [],
);

/**
 * response for get all achievements
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.ListResponse
 */
export const ListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.ListResponse",
  () => [
    { no: 1, name: "achievements", kind: "message", T: ListResponse_Achievement, repeated: true },
  ],
);

/**
 * Achievement info for list response
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.ListResponse.Achievement
 */
export const ListResponse_Achievement = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.ListResponse.Achievement",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(AchievementStatus) },
    { no: 5, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ListResponse_Achievement"},
);

/**
 * push action request
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.PushActionRequest
 */
export const PushActionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.PushActionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "action", kind: "enum", T: proto3.getEnumType(AchievementAction) },
  ],
);

/**
 * push action response
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.PushActionResponse
 */
export const PushActionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.PushActionResponse",
  [],
);

/**
 * Request message to get the list of events.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.ListEventRequest
 */
export const ListEventRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.ListEventRequest",
  [],
);

/**
 * Response message for the list of events.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.ListEventResponse
 */
export const ListEventResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.ListEventResponse",
  () => [
    { no: 1, name: "events", kind: "message", T: Event, repeated: true },
  ],
);

/**
 * Request to upload image for achievement.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.UploadImageRequest
 */
export const UploadImageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.UploadImageRequest",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response with uploaded image info.
 *
 * @generated from message gamification.achievement_manager.service.bo.achievement.v1.UploadImageResponse
 */
export const UploadImageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.bo.achievement.v1.UploadImageResponse",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

