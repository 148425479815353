import { BlockReason } from '@pinup-grpc/services/bo/admin/players_pb';

export const BLOCK_REASON_MAP: Record<string, string> = {
  [BlockReason.BR_UNSPECIFIED]: 'Unspecified',
  [BlockReason.BR_SELF_LOCK]: 'Self lock',
  [BlockReason.BR_MULTI_ACCOUNT]: 'Multiaccount',
  [BlockReason.BR_FIN_FRAUD]: 'Fin fraud',
  [BlockReason.BR_LOSS_OF_ACCESS]: 'Loss of access',
  [BlockReason.BR_GROUP_CONSPIRACY]: 'Group conspiracy',
  [BlockReason.BR_HACKING]: 'Hacking',
  [BlockReason.BR_MERGE]: 'Merge',
  [BlockReason.BR_BONUS_ABUSE]: 'Bonus abuse',
  [BlockReason.BR_AUTOMATIC_INACTIVITY_BLOCK]: 'Automatic inactivity block',
  [BlockReason.BR_OTHER_VIOLATION]: 'Other violation',
  [BlockReason.BR_FAILED_LOGINS]: 'Failed Logins',
  [BlockReason.BR_RUS_FIN_MON_BLOCK]: 'Rus fin mon block',
  [BlockReason.BR_MONITORING_BLOCK]: 'Monitoring block',
};
