import { inject } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PaginationPayload } from '../pagination.model';
import { PAGINATION_MANAGER_INITIAL_VALUE } from './default-pagination/default-pagination-value.provider';
import type { PaginationManager } from './pagination-management.model';

export abstract class PaginationManagerBase<T extends PaginationPayload>
  implements PaginationManager<T>
{
  public abstract readonly source$: Observable<T>;
  protected initialValue = inject<T>(PAGINATION_MANAGER_INITIAL_VALUE);

  public abstract update(value: Partial<T>): void;

  public reset(): void {
    this.update(this.initialValue);
  }
}
