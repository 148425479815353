// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/playerblocker/playerblocker.proto (package pinup.players.player.playerblocker, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { BlockPlayerRequest, BlockPlayerResponse, GetInactivePlayersRequest, GetInactivePlayersResponse } from "./playerblocker_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.players.player.playerblocker.Players
 */
@Injectable({ providedIn: "root" })
export class PlayersRemoteService {
  public static readonly typeName = "pinup.players.player.playerblocker.Players";

  public static readonly methods = {
    getInactivePlayers: {
      name: "GetInactivePlayers",
      I: GetInactivePlayersRequest,
      O: GetInactivePlayersResponse,
      kind: MethodKind.Unary,
    },
    blockPlayer: {
      name: "BlockPlayer",
      I: BlockPlayerRequest,
      O: BlockPlayerResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.players.player.playerblocker.Players.GetInactivePlayers
   */
  public getInactivePlayers(
    input: PartialMessage<GetInactivePlayersRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetInactivePlayersResponse> {
    return unary<GetInactivePlayersRequest, GetInactivePlayersResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getInactivePlayers,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.playerblocker.Players.BlockPlayer
   */
  public blockPlayer(
    input: PartialMessage<BlockPlayerRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<BlockPlayerResponse> {
    return unary<BlockPlayerRequest, BlockPlayerResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.blockPlayer,
      input,
      options,
    );
  }
}
