import type { OnChanges } from '@angular/core';
import { Directive, inject, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { IS_MAC } from '@pu/sdk';

@Directive({
  selector: '[boAccessKey]',
  standalone: true,
  host: {
    '[accessKey]': 'accessKey()',
  },
  hostDirectives: [MatTooltip],
})
export class AccessKeyDirective implements OnChanges {
  private readonly tooltip = inject(MatTooltip, { self: true });
  private readonly isMac = inject(IS_MAC);

  public readonly accessKey = input.required<string>({ alias: 'boAccessKey' });

  public ngOnChanges(): void {
    const prefix = this.isMac ? '⌃⌥' : 'Alt+';
    this.tooltip.message = prefix + this.accessKey().toUpperCase();
  }
}
