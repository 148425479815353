import type { Provider, Type } from '@angular/core';
import { InjectionToken } from '@angular/core';

import { provideClass } from '@pu/sdk';
import { LocalStorageFeatureFlagsAdapter } from '@bo/common';

import { FeatureFlagsFeatureKind } from '../models/feature-flags-features.model';
import type {
  AdapterFeature,
  FeatureFlagsAdapter,
} from './feature-flags-adapter.model';

export const FEATURE_FLAGS_ADAPTER = new InjectionToken<FeatureFlagsAdapter>(
  'FEATURE_FLAGS_ADAPTER',
  { factory: () => new LocalStorageFeatureFlagsAdapter() },
);

export const withAdapter = (
  adapter: Type<FeatureFlagsAdapter>,
): AdapterFeature => {
  const providers = [
    provideClass<FeatureFlagsAdapter>(FEATURE_FLAGS_ADAPTER, adapter),
  ];

  return {
    kind: FeatureFlagsFeatureKind.AdapterFeature,
    providers,
  };
};

export const provideFeatureFlagsAdapter = (
  adapter: Type<FeatureFlagsAdapter>,
): Provider[] => [
  provideClass<FeatureFlagsAdapter>(FEATURE_FLAGS_ADAPTER, adapter),
];
