// @generated by protoc-gen-es v1.10.0
// @generated from file service/insvc/achievement/v1/achievement.proto (package gamification.achievement_manager.service.insvc.achievement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Achievement } from "../../../../base/insvc/v1/achievement_pb.js";
import { AchievementStatus } from "../../../../base/insvc/v1/achievement_enum_pb.js";
import { Event } from "../../../../base/insvc/v1/task_pb.js";

/**
 * request for get achievement info
 *
 * @generated from message gamification.achievement_manager.service.insvc.achievement.v1.GetRequest
 */
export const GetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.insvc.achievement.v1.GetRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * response for get achievement info
 *
 * @generated from message gamification.achievement_manager.service.insvc.achievement.v1.GetResponse
 */
export const GetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.insvc.achievement.v1.GetResponse",
  () => [
    { no: 1, name: "achievement", kind: "message", T: Achievement },
  ],
);

/**
 * Request to list achievements with filtering options.
 *
 * @generated from message gamification.achievement_manager.service.insvc.achievement.v1.ListRequest
 */
export const ListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.insvc.achievement.v1.ListRequest",
  () => [
    { no: 1, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(AchievementStatus), repeated: true },
  ],
);

/**
 * Response containing the list of achievements that match the given filters.
 *
 * @generated from message gamification.achievement_manager.service.insvc.achievement.v1.ListResponse
 */
export const ListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.insvc.achievement.v1.ListResponse",
  () => [
    { no: 1, name: "achievements", kind: "message", T: Achievement, repeated: true },
  ],
);

/**
 * Request message to get the list of events.
 *
 * @generated from message gamification.achievement_manager.service.insvc.achievement.v1.ListEventRequest
 */
export const ListEventRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.insvc.achievement.v1.ListEventRequest",
  [],
);

/**
 * Response message for the list of events.
 *
 * @generated from message gamification.achievement_manager.service.insvc.achievement.v1.ListEventResponse
 */
export const ListEventResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.service.insvc.achievement.v1.ListEventResponse",
  () => [
    { no: 1, name: "events", kind: "message", T: Event, repeated: true },
  ],
);

