import {
  ChangeDetectionStrategy,
  Component,
  inject,
  model,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import {
  addDays,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';

import {
  calendarIcon,
  provideValue,
  SmartDialog,
  type SmartDialogOptions,
  SvgComponent,
} from '@pu/sdk';

import type { DateRange } from '../models/date-range';
import type { DateRangeDialogData } from './components/select-dates-popup/date-range-dialog.component';
import {
  DATE_RANGE_DIALOG_DATA,
  DateRangeDialogComponent,
} from './components/select-dates-popup/date-range-dialog.component';
import { PERIOD_OPTIONS } from './period-options.const';
import { PeriodTypeEnum } from './period-type.enum';

@Component({
  selector: 'bo-period-picker',
  standalone: true,
  imports: [MatMenuTrigger, MatMenu, MatMenuItem, MatButton, SvgComponent],
  templateUrl: './period-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodPickerComponent {
  public period = model<PeriodTypeEnum>();
  public dateRange = model<DateRange>();

  protected readonly PERIOD_OPTIONS = PERIOD_OPTIONS;

  protected readonly calendarIcon = calendarIcon;

  private readonly dialog = inject(SmartDialog);

  protected selectOption(period: PeriodTypeEnum): void {
    this.period.set(period);

    switch (period) {
      case PeriodTypeEnum.Date:
        this.openDialog({ sameDay: true });
        break;

      case PeriodTypeEnum.Between:
        this.openDialog({ sameDay: false });
        break;

      case PeriodTypeEnum.Today:
        this.dateRange.set({ from: startOfToday(), to: endOfToday() });
        break;

      case PeriodTypeEnum.ThisWeek:
        this.dateRange.set({
          from: startOfWeek(new Date(), { weekStartsOn: 1 }),
          to: endOfWeek(new Date(), { weekStartsOn: 1 }),
        });
        break;

      case PeriodTypeEnum.ThisMonth:
        this.dateRange.set({
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
        });
        break;

      case PeriodTypeEnum.ThisYear:
        this.dateRange.set({
          from: startOfYear(new Date()),
          to: endOfYear(new Date()),
        });
        break;

      case PeriodTypeEnum.NinetyDays:
        this.dateRange.set({
          from: subDays(new Date(), 89),
          to: addDays(new Date(), 1),
        });
        break;
    }
  }

  private openDialog(
    data: DateRangeDialogData,
    { providers = [] }: SmartDialogOptions = {},
  ): void {
    this.dialog
      .open<DateRange>(DateRangeDialogComponent, [
        ...providers,
        provideValue(DATE_RANGE_DIALOG_DATA, data),
      ])
      .subscribe((result) => {
        if (result) {
          this.dateRange.set(result);
        }
      });
  }
}
