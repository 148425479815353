import type { MaskitoOptions } from '@maskito/core';

export const cardMask: MaskitoOptions = {
  mask: [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...new Array(4).fill(/\d/),
    ' ',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...new Array(4).fill(/\d/),
    ' ',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...new Array(4).fill(/\d/),
    ' ',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...new Array(4).fill(/\d/),
    ' ',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...new Array(3).fill(/\d/),
  ],
};
