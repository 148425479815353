import { Directive, HostListener, input } from '@angular/core';

import { GtmEventBaseDirective } from './gtm-event.base.directive';

@Directive({
  selector: 'form[boGtmSubmit]',
  standalone: true,
})
export class BoGtmSubmitDirective extends GtmEventBaseDirective {
  public readonly event = input.required<string>();
  public readonly area = input<string>();
  public readonly element = input<string>();
  public readonly page = input<string>();

  @HostListener('submit')
  private onSubmit(): void {
    this.send();
  }
}
