import { inject, Injectable } from '@angular/core';

import { FeatureFlagsService } from '../../../feature-flags/feature-flags.service';
import { DataLayerBase } from './data-layer.base';
import type { DataLayerItem } from './data-layer.model';

@Injectable()
export class DataLayerDebuggerService extends DataLayerBase {
  private readonly featureFlagsService = inject(FeatureFlagsService);
  protected readonly items: DataLayerItem[] = [];

  public push(item: DataLayerItem): void {
    super.push(item);

    const isEnabled = this.featureFlagsService.getValue('gtm_debug');

    if (isEnabled) {
      console.log(item);
    }
  }
}
