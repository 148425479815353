// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/deposit_settings.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { BigDepositSettings, GetBigDepositSettingsRequest, GetBigDepositSettingsResponse, UpdateBigDepositSettingsResponse } from "./deposit_settings_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.DepositSettingsService
 */
@Injectable({ providedIn: "root" })
export class DepositSettingsRemoteService {
  public static readonly typeName = "payments.DepositSettingsService";

  public static readonly methods = {
    getBigDepositSettings: {
      name: "GetBigDepositSettings",
      I: GetBigDepositSettingsRequest,
      O: GetBigDepositSettingsResponse,
      kind: MethodKind.Unary,
    },
    updateBigDepositSettings: {
      name: "UpdateBigDepositSettings",
      I: BigDepositSettings,
      O: UpdateBigDepositSettingsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.DepositSettingsService.GetBigDepositSettings
   */
  public getBigDepositSettings(
    input: PartialMessage<GetBigDepositSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetBigDepositSettingsResponse> {
    return unary<GetBigDepositSettingsRequest, GetBigDepositSettingsResponse>(
      this.transport,
      DepositSettingsRemoteService,
      DepositSettingsRemoteService.methods.getBigDepositSettings,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.DepositSettingsService.UpdateBigDepositSettings
   */
  public updateBigDepositSettings(
    input: PartialMessage<BigDepositSettings>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateBigDepositSettingsResponse> {
    return unary<BigDepositSettings, UpdateBigDepositSettingsResponse>(
      this.transport,
      DepositSettingsRemoteService,
      DepositSettingsRemoteService.methods.updateBigDepositSettings,
      input,
      options,
    );
  }
}
