// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/backoffice/payments/payments.proto (package payments.backoffice.payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { FindPaymentAggregatorsRequest, FindPaymentAggregatorsResponse, FindPaymentMethodsRequest, FindPaymentMethodsResponse } from "./payments_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.backoffice.payments.PaymentService
 */
@Injectable({ providedIn: "root" })
export class PaymentRemoteService {
  public static readonly typeName = "payments.backoffice.payments.PaymentService";

  public static readonly methods = {
    findPaymentAggregators: {
      name: "FindPaymentAggregators",
      I: FindPaymentAggregatorsRequest,
      O: FindPaymentAggregatorsResponse,
      kind: MethodKind.Unary,
    },
    findPaymentMethods: {
      name: "FindPaymentMethods",
      I: FindPaymentMethodsRequest,
      O: FindPaymentMethodsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.backoffice.payments.PaymentService.FindPaymentAggregators
   */
  public findPaymentAggregators(
    input: PartialMessage<FindPaymentAggregatorsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindPaymentAggregatorsResponse> {
    return unary<FindPaymentAggregatorsRequest, FindPaymentAggregatorsResponse>(
      this.transport,
      PaymentRemoteService,
      PaymentRemoteService.methods.findPaymentAggregators,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.payments.PaymentService.FindPaymentMethods
   */
  public findPaymentMethods(
    input: PartialMessage<FindPaymentMethodsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindPaymentMethodsResponse> {
    return unary<FindPaymentMethodsRequest, FindPaymentMethodsResponse>(
      this.transport,
      PaymentRemoteService,
      PaymentRemoteService.methods.findPaymentMethods,
      input,
      options,
    );
  }
}
