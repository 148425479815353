// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/product/backoffice_operator/operator/operator_modify.proto (package pinup.product.backoffice_operator.operator, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { UpsertOperatorRequest, UpsertOperatorResponse } from "./operator_modify_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.product.backoffice_operator.operator.OperatorModifyService
 */
@Injectable({ providedIn: "root" })
export class OperatorModifyRemoteService {
  public static readonly typeName = "pinup.product.backoffice_operator.operator.OperatorModifyService";

  public static readonly methods = {
    upsertOperator: {
      name: "UpsertOperator",
      I: UpsertOperatorRequest,
      O: UpsertOperatorResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.product.backoffice_operator.operator.OperatorModifyService.UpsertOperator
   */
  public upsertOperator(
    input: PartialMessage<UpsertOperatorRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpsertOperatorResponse> {
    return unary<UpsertOperatorRequest, UpsertOperatorResponse>(
      this.transport,
      OperatorModifyRemoteService,
      OperatorModifyRemoteService.methods.upsertOperator,
      input,
      options,
    );
  }
}
