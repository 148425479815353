<suspense-area puDialogSuspense puSuspense #suspense="puSuspense">
  <div mat-dialog-title>Change Email</div>
  <form
    pu-dialog-content
    id="change-email-dialog-form"
    (ngSubmit)="
      suspense.run(
        playerRemoteService.setEmail({
          playerId: data.profileId,
          email: this.email(),
        })
      )
    "
    #form="ngForm"
  >
    <mat-form-field class="field">
      <mat-label>Email</mat-label>
      <input
        [(ngModel)]="email"
        email
        matInput
        name="email"
        required
        type="email"
        #emailField="ngModel"
      />
      @if (emailField | validationError; as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
  </form>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="button"
      form="change-email-dialog-form"
      matDialogClose="cancel"
      type="button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="form.invalid"
      color="primary"
      form="change-email-dialog-form"
      type="submit"
    >
      Save
    </button>
  </mat-dialog-actions>
</suspense-area>
