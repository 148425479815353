// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/google_auth/mfa/service.proto (package pinup.google_auth.mfa, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.google_auth.mfa.SignInRequest
 */
export const SignInRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.SignInRequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.SignInResponse
 */
export const SignInResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.SignInResponse",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_2fa_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Enable2FARequest
 */
export const Enable2FARequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Enable2FARequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Enable2FAResponse
 */
export const Enable2FAResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Enable2FAResponse",
  () => [
    { no: 1, name: "qr_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "manual_setup_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "recovery_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Verify2FARequest
 */
export const Verify2FARequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Verify2FARequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Verify2FAResponse
 */
export const Verify2FAResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Verify2FAResponse",
  () => [
    { no: 1, name: "refresh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Reset2FARequest
 */
export const Reset2FARequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Reset2FARequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Reset2FAResponse
 */
export const Reset2FAResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Reset2FAResponse",
  () => [
    { no: 1, name: "refresh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Remove2FARequest
 */
export const Remove2FARequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Remove2FARequest",
  () => [
    { no: 1, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.Remove2FAResponse
 */
export const Remove2FAResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.Remove2FAResponse",
  () => [
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.RefreshTokenRequest
 */
export const RefreshTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.RefreshTokenRequest",
  () => [
    { no: 1, name: "refresh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.google_auth.mfa.RefreshTokenResponse
 */
export const RefreshTokenResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.google_auth.mfa.RefreshTokenResponse",
  () => [
    { no: 1, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 255, name: "extras", kind: "message", T: Any, repeated: true },
  ],
);

