import { inject, Injectable } from '@angular/core';
import { PlayerRemoteService } from '@pinup-grpc/services/bo/players/players_ng';
import type { TimeRange } from '@pinup-grpc/services/common/common_pb';
import type { Observable } from 'rxjs';

import type {
  FindPlayerNotesResponse,
  GetPlayerResponse,
  TriggerPlayerNotePinResponse,
} from '@pu/grpc';
import { PlayerNotesLogsRemoteService } from '@pu/grpc';
import type { Pagination } from '@bo/common';
import { DEFAULT_CONVERT_CURRENCY } from '@bo/common';

@Injectable()
export class PlayerNotesProviderService {
  private readonly playerRemoteService = inject(PlayerRemoteService);
  private readonly notesRemoteService = inject(PlayerNotesLogsRemoteService);

  public getPlayer(id: bigint): Observable<GetPlayerResponse> {
    return this.playerRemoteService.getPlayer({
      convertCurrency: DEFAULT_CONVERT_CURRENCY,
      id,
    });
  }

  public getNotes(
    playerId: bigint,
    isPinned: boolean,
    createdAt: TimeRange | undefined,
    { currentPage: page, pageSize }: Pagination,
  ): Observable<FindPlayerNotesResponse> {
    return this.notesRemoteService.findPlayerNotes({
      playerId,
      isPinned,
      pagination: { page, pageSize },
      createdAt,
    });
  }

  public toggleNotePinned(
    noteId: bigint,
  ): Observable<TriggerPlayerNotePinResponse> {
    return this.notesRemoteService.triggerPlayerNotePin({
      id: noteId,
    });
  }
}
