<bo-list-state-wrapper
  [hasData]="hasData$ | async"
  [hasError]="hasError$ | async"
  [isLoading]="isLoading$ | async"
>
  <ng-container data>
    @if (documents$ | async; as documents) {
      <table mat-table class="table-element" [dataSource]="documents">
        <ng-container [matColumnDef]="Column.TYPE">
          <th *matHeaderCellDef mat-header-cell>Name</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.type | readableEnumValue }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="Column.FILES">
          <th *matHeaderCellDef mat-header-cell>Links</th>
          <td *matCellDef="let element" mat-cell>
            @for (
              file of element | filesFromDocument;
              track file.fileName;
              let last = $last
            ) {
              <risk-file-link
                [documentSide]="file.documentSide"
                [fileName]="file.fileName"
              />
              @if (!last) {
                ,
              }
            }
          </td>
        </ng-container>

        <ng-container [matColumnDef]="Column.CREATED_AT">
          <th *matHeaderCellDef mat-header-cell>Date</th>
          <td *matCellDef="let element" mat-cell>
            <time>{{ element.createdAt | toDate | date: 'yyyy-MM-dd' }}</time>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns" mat-header-row class="table-header"></tr>
        <tr *matRowDef="let row; columns: columns" mat-row></tr>
      </table>
    }
  </ng-container>
  <bo-empty-template empty message="No documents found" />

  <bo-empty-template
    [isError]="true"
    error
    message="No documents found"
    prompt="System error"
  />
</bo-list-state-wrapper>
