// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/bo/withdrawals/dashboard/withdrawals_dashboard.proto (package stat.bo.withdrawals.dashboard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetCountersByGeoRequest, GetCountersByGeoResponse, GetCountersRequest, GetCountersResponse, GetDeclineReasonCountersBySpecialistRequest, GetDeclineReasonCountersBySpecialistResponse, GetReasonCountersByGeoRequest, GetReasonCountersByGeoResponse, GetSpecialistsCountersRequest, GetSpecialistsCountersResponse } from "./withdrawals_dashboard_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service stat.bo.withdrawals.dashboard.WithdrawalsDashboardService
 */
@Injectable({ providedIn: "root" })
export class WithdrawalsDashboardRemoteService {
  public static readonly typeName = "stat.bo.withdrawals.dashboard.WithdrawalsDashboardService";

  public static readonly methods = {
    getCounters: {
      name: "GetCounters",
      I: GetCountersRequest,
      O: GetCountersResponse,
      kind: MethodKind.Unary,
    },
    getCountersByGeo: {
      name: "GetCountersByGeo",
      I: GetCountersByGeoRequest,
      O: GetCountersByGeoResponse,
      kind: MethodKind.Unary,
    },
    getSpecialistsCounters: {
      name: "GetSpecialistsCounters",
      I: GetSpecialistsCountersRequest,
      O: GetSpecialistsCountersResponse,
      kind: MethodKind.Unary,
    },
    getReasonCountersByGeo: {
      name: "GetReasonCountersByGeo",
      I: GetReasonCountersByGeoRequest,
      O: GetReasonCountersByGeoResponse,
      kind: MethodKind.Unary,
    },
    getDeclineReasonCountersBySpecialist: {
      name: "GetDeclineReasonCountersBySpecialist",
      I: GetDeclineReasonCountersBySpecialistRequest,
      O: GetDeclineReasonCountersBySpecialistResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc stat.bo.withdrawals.dashboard.WithdrawalsDashboardService.GetCounters
   */
  public getCounters(
    input: PartialMessage<GetCountersRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetCountersResponse> {
    return unary<GetCountersRequest, GetCountersResponse>(
      this.transport,
      WithdrawalsDashboardRemoteService,
      WithdrawalsDashboardRemoteService.methods.getCounters,
      input,
      options,
    );
  }

  /**
   * @generated from rpc stat.bo.withdrawals.dashboard.WithdrawalsDashboardService.GetCountersByGeo
   */
  public getCountersByGeo(
    input: PartialMessage<GetCountersByGeoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetCountersByGeoResponse> {
    return unary<GetCountersByGeoRequest, GetCountersByGeoResponse>(
      this.transport,
      WithdrawalsDashboardRemoteService,
      WithdrawalsDashboardRemoteService.methods.getCountersByGeo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc stat.bo.withdrawals.dashboard.WithdrawalsDashboardService.GetSpecialistsCounters
   */
  public getSpecialistsCounters(
    input: PartialMessage<GetSpecialistsCountersRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetSpecialistsCountersResponse> {
    return unary<GetSpecialistsCountersRequest, GetSpecialistsCountersResponse>(
      this.transport,
      WithdrawalsDashboardRemoteService,
      WithdrawalsDashboardRemoteService.methods.getSpecialistsCounters,
      input,
      options,
    );
  }

  /**
   * @generated from rpc stat.bo.withdrawals.dashboard.WithdrawalsDashboardService.GetReasonCountersByGeo
   */
  public getReasonCountersByGeo(
    input: PartialMessage<GetReasonCountersByGeoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetReasonCountersByGeoResponse> {
    return unary<GetReasonCountersByGeoRequest, GetReasonCountersByGeoResponse>(
      this.transport,
      WithdrawalsDashboardRemoteService,
      WithdrawalsDashboardRemoteService.methods.getReasonCountersByGeo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc stat.bo.withdrawals.dashboard.WithdrawalsDashboardService.GetDeclineReasonCountersBySpecialist
   */
  public getDeclineReasonCountersBySpecialist(
    input: PartialMessage<GetDeclineReasonCountersBySpecialistRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDeclineReasonCountersBySpecialistResponse> {
    return unary<GetDeclineReasonCountersBySpecialistRequest, GetDeclineReasonCountersBySpecialistResponse>(
      this.transport,
      WithdrawalsDashboardRemoteService,
      WithdrawalsDashboardRemoteService.methods.getDeclineReasonCountersBySpecialist,
      input,
      options,
    );
  }
}
