@if (sign() && formattedNumber() !== 0) {
  <ng-container>{{ sign() }}</ng-container>
}

@if (formattedNumber() === 0) {
  <ng-container>0</ng-container>
} @else {
  <ng-container>{{ integerPart() }}</ng-container>
}
@if (showFractionalPart()) {
  <ng-container>{{ decimalPart() }}</ng-container>
}
