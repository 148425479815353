import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ValidationStatuses } from '@pu/sdk';

import { PAGINATION_START_PAGE } from '../pagination.const';

@Component({
  selector: 'bo-go-to-pagination',
  templateUrl: './go-to-pagination.component.html',
  styleUrls: ['./go-to-pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation -- FIXME
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoToPaginationComponent {
  @Output() public readonly pageChange = new EventEmitter<number>();

  @Input({ required: true })
  set currentPage(currentPage: number) {
    this.currentPageControl.setValue(currentPage);
  }

  @Input({ required: true })
  get totalPages(): number {
    return this.#totalPages;
  }

  set totalPages(totalPages: number) {
    this.#totalPages = totalPages;

    this.currentPageControl.setValidators([
      Validators.min(1),
      Validators.max(totalPages),
    ]);
    this.currentPageControl.updateValueAndValidity();
  }

  public currentPageControl = new FormControl(PAGINATION_START_PAGE);

  #totalPages: number;

  get validationStatus(): ValidationStatuses {
    return this.currentPageControl.valid
      ? ValidationStatuses.Success
      : ValidationStatuses.Danger;
  }

  public apply(): void {
    if (this.currentPageControl.invalid) {
      return;
    }

    // Need to explicitly convert to number for pagination to work correctly
    this.pageChange.emit(Number(this.currentPageControl.value));
  }
}
