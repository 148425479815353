import type { EnvironmentProviders } from '@angular/core';
import {
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

import { provideFactory } from '@pu/sdk';
import { License } from '@bo/common';

export const OLD_ADMIN_URL = new InjectionToken<string>('OLD_ADMIN_URL');

const devOldAdminUrlFactory = (): string => {
  const { url: license } = inject(License);

  switch (license) {
    case 'ua':
      return 'https://test-ua.tech-pu.com/admin';
    case 'kz':
      return 'https://admin-kz.pin-up.dev/admin';
    default:
      return 'https://test.tech-pu.com/admin';
  }
};

const prodOldAdminUrlFactory = (): string => {
  const { url: license } = inject(License);

  switch (license) {
    case 'ua':
      return 'https://panel-ua.tech-pu.com/admin';
    case 'kz':
      return 'https://panel-kz.tech-pu.com/admin';
    default:
      return 'https://panel.tech-pu.com/admin';
  }
};

export const provideDevOldAdminUrl = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideFactory<string>(OLD_ADMIN_URL, devOldAdminUrlFactory),
  ]);

export const provideProdOldAdminUrl = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideFactory<string>(OLD_ADMIN_URL, prodOldAdminUrlFactory),
  ]);
