// @generated by protoc-gen-es v1.10.0
// @generated from file settings_manager/v1/models/errors.proto (package settings_manager.v1.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message settings_manager.v1.models.DetailError
 */
export const DetailError = /*@__PURE__*/ proto3.makeMessageType(
  "settings_manager.v1.models.DetailError",
  () => [
    { no: 1, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

