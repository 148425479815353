<div class="actions-bar">
  <div class="date-range">
    <mat-datepicker-toggle [for]="campaignOnePicker" matIconSuffix />
    <mat-date-range-picker #campaignOnePicker />
    <div class="date-range-wrapper">
      <mat-date-range-input [rangePicker]="campaignOnePicker">
        <input
          class="date-range-input"
          [(ngModel)]="selectedStartDate"
          matStartDate
        />
        <input
          class="date-range-input"
          [(ngModel)]="selectedEndDate"
          matEndDate
        />
      </mat-date-range-input>
      @if (areBothDatesSet()) {
        <button mat-icon-button (click)="clearDateRange()">
          <bo-svg [unsafeSvgSource]="closeIcon" />
        </button>
      }
    </div>
  </div>
</div>
