import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { arrowRightIcon, SvgComponent } from '@pu/sdk';

@Component({
  selector: 'bo-preview-wrapper',
  standalone: true,
  imports: [RouterLink, SvgComponent],
  templateUrl: './preview-wrapper.component.html',
  styleUrl: './preview-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewWrapperComponent {
  public viewAllUrl = input<(bigint | string)[]>([]);
  public linkName = input('View all');
  public arrowTopRight = input(false, { transform: booleanAttribute });

  protected readonly arrowIcon = arrowRightIcon;
}
