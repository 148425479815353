import type { ConnectedPosition } from '@angular/cdk/overlay';

export const COLLAPSED_SIDEBAR_LICENSE_DROPDOWN_CONFIG: ConnectedPosition = {
  originX: 'end',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'top',
  offsetX: 16,
  panelClass: 'licenses-dropdown',
};

export const EXPANDED_SIDEBAR_LICENSE_DROPDOWN_CONFIG: ConnectedPosition = {
  originX: 'start',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'top',
  offsetY: 10,
  panelClass: 'licenses-dropdown',
};
