// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/crm/player/v1/player.proto (package gamification.service.crm.player.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetNicknameRequest, GetNicknameResponse, SetDefaultNicknameRequest, SetDefaultNicknameResponse } from "./player_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * PlayerService:
 * ------------------------
 * Format: proto
 * client: CRM
 * server: gamification
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.service.crm.player.v1.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "gamification.service.crm.player.v1.PlayerService";

  public static readonly methods = {
    getNickname: {
      name: "GetNickname",
      I: GetNicknameRequest,
      O: GetNicknameResponse,
      kind: MethodKind.Unary,
    },
    setDefaultNickname: {
      name: "SetDefaultNickname",
      I: SetDefaultNicknameRequest,
      O: SetDefaultNicknameResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * GetNickname: get player nickname
   * ------------------------
   *
   * @generated from rpc gamification.service.crm.player.v1.PlayerService.GetNickname
   */
  public getNickname(
    input: PartialMessage<GetNicknameRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetNicknameResponse> {
    return unary<GetNicknameRequest, GetNicknameResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getNickname,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * SetDefaultNickname: set player nickname to default
   * ------------------------
   *
   * @generated from rpc gamification.service.crm.player.v1.PlayerService.SetDefaultNickname
   */
  public setDefaultNickname(
    input: PartialMessage<SetDefaultNicknameRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetDefaultNicknameResponse> {
    return unary<SetDefaultNicknameRequest, SetDefaultNicknameResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setDefaultNickname,
      input,
      options,
    );
  }
}
