import { AccountVerifyState } from '@pinup-grpc/services/bo/players/players_pb';
import type { WithdrawalStatus } from '@pinup-grpc/services/bo/withdrawals/withdrawals_pb';
import {
  ListWithdrawalsRequest_ReasonMultiselect,
  ListWithdrawalsRequest_StringMultiselect,
  ListWithdrawalsRequest_Uint64Multiselect,
  WithdrawalConfirmType,
} from '@pinup-grpc/services/bo/withdrawals/withdrawals_pb';

import { enumToKey, isEnumValue, stringToBigInt, toStringArray } from '@pu/sdk';

import { withdrawalStatusMap } from './withdrawals-filter.const';

export enum WithdrawalsTab {
  MANUAL = 'manual',
  TEST = 'test',
  ALL = 'all',
}

export function toVerifyStatus(value: string): AccountVerifyState {
  const key = enumToKey(AccountVerifyState, Number(value));
  return AccountVerifyState[key];
}

export function toUint64Multiselect(
  include: string | null | undefined,
  exclude: string | null | undefined,
): ListWithdrawalsRequest_Uint64Multiselect {
  const ids = toStringArray(include).map(stringToBigInt);
  const excludedIds = toStringArray(exclude).map(stringToBigInt);

  return new ListWithdrawalsRequest_Uint64Multiselect({ ids, excludedIds });
}

export function toConfirmType(
  rawValue: string | null | undefined,
): WithdrawalConfirmType[] {
  const value = Number(rawValue);
  return [
    isEnumValue(WithdrawalConfirmType, value)
      ? value
      : WithdrawalConfirmType.AUTO,
  ];
}

export function toWithdrawalStatus(
  value: string | null | undefined,
): WithdrawalStatus[] {
  return value
    ? toStringArray(value).map((status) => withdrawalStatusMap[status])
    : [];
}

export function toStringMultiselect(
  value: string | null | undefined,
): ListWithdrawalsRequest_StringMultiselect {
  const ids = toStringArray(value);

  return new ListWithdrawalsRequest_StringMultiselect({ ids });
}

export function toReasonMultiselect(
  value: string | null | undefined,
): ListWithdrawalsRequest_ReasonMultiselect {
  const reasons = toStringArray(value).map(Number);

  return new ListWithdrawalsRequest_ReasonMultiselect({ reasons });
}

export function isPlayerTest(tab: WithdrawalsTab): tab is WithdrawalsTab.TEST {
  return tab === WithdrawalsTab.TEST;
}
