// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/base/filter.proto (package pinup.stat.report.base, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Period
 * Deprecated
 *
 * @generated from message pinup.stat.report.base.Period
 */
export const Period = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.Period",
  () => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ],
);

