import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { endOfDay, startOfDay } from 'date-fns';

export interface DateRangeDialogData {
  sameDay: boolean;
}

export const DATE_RANGE_DIALOG_DATA = new InjectionToken<DateRangeDialogData>(
  'DATE_RANGE_DIALOG_DATA',
);

@Component({
  selector: 'bo-date-range-dialog',
  standalone: true,
  templateUrl: './date-range-dialog.component.html',
  styleUrl: './date-range-dialog.component.scss',
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    MatDatepicker,
    MatDateRangeInput,
    MatDatepickerToggle,
    MatDateRangePicker,
    MatDatepickerInput,
    MatStartDate,
    MatEndDate,
    MatInput,
    MatButton,
    FormsModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeDialogComponent {
  protected readonly data = inject(DATE_RANGE_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef);

  protected from = new Date();
  protected to = new Date();

  public submit(): void {
    const from = startOfDay(this.from);
    const to = endOfDay(this.data.sameDay ? this.from : this.to);

    this.dialogRef.close({ from, to });
  }
}
