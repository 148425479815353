// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/deposits/deposits.proto (package pinup.stat.report.deposits, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, Int64Value, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { Sorting } from "../../../std/query/v1/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../std/query/v1/pagination_pb.js";
import { DurationRange, StringMultiselect, SumRange, Uint64Multiselect } from "../base/filtering_pb.js";
import { BettingStatus, CasinoStatus } from "../players/players_pb.js";
import { TimeRange } from "../../../std/query/v1/filter_pb.js";
import { SumCurrency } from "../base/models_pb.js";

/**
 * @generated from enum pinup.stat.report.deposits.DepositsAmountType
 */
export const DepositsAmountType = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.deposits.DepositsAmountType",
  [
    {no: 0, name: "DEPOSITS_AMOUNT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEPOSITS_AMOUNT_TYPE_HIGH", localName: "HIGH"},
    {no: 2, name: "DEPOSITS_AMOUNT_TYPE_LOW", localName: "LOW"},
  ],
);

/**
 * Status is status in payment service
 *
 * @generated from enum pinup.stat.report.deposits.Status
 */
export const Status = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.deposits.Status",
  [
    {no: 0, name: "STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 2, name: "STATUS_PROCESSING", localName: "PROCESSING"},
    {no: 3, name: "STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 6, name: "STATUS_PAID", localName: "PAID"},
    {no: 7, name: "STATUS_FAILED", localName: "FAILED"},
  ],
);

/**
 * Deposit is a record of a player's deposit
 *
 * @generated from message pinup.stat.report.deposits.Deposit
 */
export const Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.Deposit",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "depositId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "externalId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "paymentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "playerDepositsCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "paymentAggregatorId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "paymentMethodId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "paymentSystemId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "createdAt", kind: "message", T: Timestamp },
    { no: 10, name: "updatedAt", kind: "message", T: Timestamp },
    { no: 11, name: "playerDepositsAmountType", kind: "enum", T: proto3.getEnumType(DepositsAmountType) },
    { no: 12, name: "finance", kind: "message", T: Deposit_Finance },
    { no: 13, name: "convertedFinance", kind: "message", T: Deposit_Finance },
    { no: 14, name: "status", kind: "enum", T: proto3.getEnumType(Status) },
    { no: 15, name: "isChargeback", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message pinup.stat.report.deposits.Deposit.Finance
 */
export const Deposit_Finance = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.Deposit.Finance",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "balanceAfter", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "Deposit_Finance"},
);

/**
 * Request for listing deposits with filters, sorting, and pagination
 *
 * @generated from message pinup.stat.report.deposits.ListDepositsRequest
 */
export const ListDepositsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.ListDepositsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListDepositsRequest_Filter },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * Filter is all fields for filtering
 *
 * @generated from message pinup.stat.report.deposits.ListDepositsRequest.Filter
 */
export const ListDepositsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.ListDepositsRequest.Filter",
  () => [
    { no: 1, name: "profile", kind: "message", T: ListDepositsRequest_Filter_Profile },
    { no: 2, name: "deposit", kind: "message", T: ListDepositsRequest_Filter_Deposit },
  ],
  {localName: "ListDepositsRequest_Filter"},
);

/**
 * @generated from message pinup.stat.report.deposits.ListDepositsRequest.Filter.Profile
 */
export const ListDepositsRequest_Filter_Profile = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.ListDepositsRequest.Filter.Profile",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "clickId", kind: "message", T: StringValue },
    { no: 10, name: "isClickIdEmpty", kind: "message", T: BoolValue },
    { no: 3, name: "isPlayerVip", kind: "message", T: BoolValue },
    { no: 4, name: "playerEmail", kind: "message", T: StringValue },
    { no: 5, name: "playerGeo", kind: "message", T: StringMultiselect },
    { no: 6, name: "casinoStatuses", kind: "enum", T: proto3.getEnumType(CasinoStatus), repeated: true },
    { no: 7, name: "bettingStatuses", kind: "enum", T: proto3.getEnumType(BettingStatus), repeated: true },
    { no: 8, name: "balanceBefore", kind: "message", T: SumRange },
    { no: 9, name: "balance", kind: "message", T: SumRange },
  ],
  {localName: "ListDepositsRequest_Filter_Profile"},
);

/**
 * @generated from message pinup.stat.report.deposits.ListDepositsRequest.Filter.Deposit
 */
export const ListDepositsRequest_Filter_Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.ListDepositsRequest.Filter.Deposit",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "externalIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "paymentMethod", kind: "message", T: Uint64Multiselect },
    { no: 4, name: "amount", kind: "message", T: SumRange },
    { no: 5, name: "durationToCompletedRange", kind: "message", T: DurationRange },
    { no: 6, name: "isFirstDeposit", kind: "message", T: BoolValue },
    { no: 7, name: "isFistHourDeposit", kind: "message", T: BoolValue },
    { no: 8, name: "domain", kind: "message", T: StringValue },
    { no: 9, name: "createdAt", kind: "message", T: TimeRange },
    { no: 10, name: "updatedAt", kind: "message", T: TimeRange },
    { no: 11, name: "statuses", kind: "enum", T: proto3.getEnumType(Status), repeated: true },
    { no: 12, name: "externalTnxId", kind: "message", T: Int64Value },
    { no: 13, name: "isChargeback", kind: "message", T: BoolValue },
    { no: 14, name: "directionA3ds", kind: "message", T: StringValue },
    { no: 15, name: "cashboxTranComment", kind: "message", T: StringValue },
    { no: 16, name: "cardBinGeo", kind: "message", T: StringMultiselect },
  ],
  {localName: "ListDepositsRequest_Filter_Deposit"},
);

/**
 * @generated from message pinup.stat.report.deposits.ListDepositsResponse
 */
export const ListDepositsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.deposits.ListDepositsResponse",
  () => [
    { no: 1, name: "deposits", kind: "message", T: Deposit, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 4, name: "totalAmount", kind: "message", T: SumCurrency },
  ],
);

