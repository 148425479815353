// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/players/player/payments/payments.proto (package pinup.players.player.payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetAccountInfoRequest, GetAccountInfoResponse, GetAddressRequest, GetAddressResponse, GetAffiliateRequest, GetAffiliateResponse, GetAWInfoRequest, GetAWInfoResponse, GetBalanceRequest, GetBalanceResponse, GetBonusRequest, GetBonusResponse, GetContactRequest, GetContactResponse, GetCurrencyInfoRequest, GetCurrencyInfoResponse, GetDepositInfoRequest, GetDepositInfoResponse, GetLanguageInfoRequest, GetLanguageInfoResponse, GetLastActivityRequest, GetLastActivityResponse, GetLegalRequest, GetLegalResponse, GetLimitsRequest, GetLimitsResponse, GetPersonalInfoRequest, GetPersonalInfoResponse, GetPincoinsRequest, GetPincoinsResponse, GetStatusRequest, GetStatusResponse, GetVerificationRequest, GetVerificationResponse, GetWithdrawInfoRequest, GetWithdrawInfoResponse } from "./payments_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.players.player.payments.Players
 */
@Injectable({ providedIn: "root" })
export class PlayersRemoteService {
  public static readonly typeName = "pinup.players.player.payments.Players";

  public static readonly methods = {
    getPlayerAccountInfo: {
      name: "GetPlayerAccountInfo",
      I: GetAccountInfoRequest,
      O: GetAccountInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerPersonalInfo: {
      name: "GetPlayerPersonalInfo",
      I: GetPersonalInfoRequest,
      O: GetPersonalInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerContact: {
      name: "GetPlayerContact",
      I: GetContactRequest,
      O: GetContactResponse,
      kind: MethodKind.Unary,
    },
    getPlayerAddress: {
      name: "GetPlayerAddress",
      I: GetAddressRequest,
      O: GetAddressResponse,
      kind: MethodKind.Unary,
    },
    getPlayerLanguageInfo: {
      name: "GetPlayerLanguageInfo",
      I: GetLanguageInfoRequest,
      O: GetLanguageInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerAWInfo: {
      name: "GetPlayerAWInfo",
      I: GetAWInfoRequest,
      O: GetAWInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerCurrencyInfo: {
      name: "GetPlayerCurrencyInfo",
      I: GetCurrencyInfoRequest,
      O: GetCurrencyInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerLimits: {
      name: "GetPlayerLimits",
      I: GetLimitsRequest,
      O: GetLimitsResponse,
      kind: MethodKind.Unary,
    },
    getPlayerDepositInfo: {
      name: "GetPlayerDepositInfo",
      I: GetDepositInfoRequest,
      O: GetDepositInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerWithdrawInfo: {
      name: "GetPlayerWithdrawInfo",
      I: GetWithdrawInfoRequest,
      O: GetWithdrawInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerPincoins: {
      name: "GetPlayerPincoins",
      I: GetPincoinsRequest,
      O: GetPincoinsResponse,
      kind: MethodKind.Unary,
    },
    getPlayerAffiliate: {
      name: "GetPlayerAffiliate",
      I: GetAffiliateRequest,
      O: GetAffiliateResponse,
      kind: MethodKind.Unary,
    },
    getPlayerLastActivity: {
      name: "GetPlayerLastActivity",
      I: GetLastActivityRequest,
      O: GetLastActivityResponse,
      kind: MethodKind.Unary,
    },
    getPlayerStatus: {
      name: "GetPlayerStatus",
      I: GetStatusRequest,
      O: GetStatusResponse,
      kind: MethodKind.Unary,
    },
    getPlayerLegal: {
      name: "GetPlayerLegal",
      I: GetLegalRequest,
      O: GetLegalResponse,
      kind: MethodKind.Unary,
    },
    getPlayerBonus: {
      name: "GetPlayerBonus",
      I: GetBonusRequest,
      O: GetBonusResponse,
      kind: MethodKind.Unary,
    },
    getPlayerVerification: {
      name: "GetPlayerVerification",
      I: GetVerificationRequest,
      O: GetVerificationResponse,
      kind: MethodKind.Unary,
    },
    getPlayerBalance: {
      name: "GetPlayerBalance",
      I: GetBalanceRequest,
      O: GetBalanceResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerAccountInfo
   */
  public getPlayerAccountInfo(
    input: PartialMessage<GetAccountInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAccountInfoResponse> {
    return unary<GetAccountInfoRequest, GetAccountInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerAccountInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerPersonalInfo
   */
  public getPlayerPersonalInfo(
    input: PartialMessage<GetPersonalInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPersonalInfoResponse> {
    return unary<GetPersonalInfoRequest, GetPersonalInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerPersonalInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerContact
   */
  public getPlayerContact(
    input: PartialMessage<GetContactRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetContactResponse> {
    return unary<GetContactRequest, GetContactResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerContact,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerAddress
   */
  public getPlayerAddress(
    input: PartialMessage<GetAddressRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAddressResponse> {
    return unary<GetAddressRequest, GetAddressResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerAddress,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerLanguageInfo
   */
  public getPlayerLanguageInfo(
    input: PartialMessage<GetLanguageInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLanguageInfoResponse> {
    return unary<GetLanguageInfoRequest, GetLanguageInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerLanguageInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerAWInfo
   */
  public getPlayerAWInfo(
    input: PartialMessage<GetAWInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAWInfoResponse> {
    return unary<GetAWInfoRequest, GetAWInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerAWInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerCurrencyInfo
   */
  public getPlayerCurrencyInfo(
    input: PartialMessage<GetCurrencyInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetCurrencyInfoResponse> {
    return unary<GetCurrencyInfoRequest, GetCurrencyInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerCurrencyInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerLimits
   */
  public getPlayerLimits(
    input: PartialMessage<GetLimitsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLimitsResponse> {
    return unary<GetLimitsRequest, GetLimitsResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerLimits,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerDepositInfo
   */
  public getPlayerDepositInfo(
    input: PartialMessage<GetDepositInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDepositInfoResponse> {
    return unary<GetDepositInfoRequest, GetDepositInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerDepositInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerWithdrawInfo
   */
  public getPlayerWithdrawInfo(
    input: PartialMessage<GetWithdrawInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetWithdrawInfoResponse> {
    return unary<GetWithdrawInfoRequest, GetWithdrawInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerWithdrawInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerPincoins
   */
  public getPlayerPincoins(
    input: PartialMessage<GetPincoinsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPincoinsResponse> {
    return unary<GetPincoinsRequest, GetPincoinsResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerPincoins,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerAffiliate
   */
  public getPlayerAffiliate(
    input: PartialMessage<GetAffiliateRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAffiliateResponse> {
    return unary<GetAffiliateRequest, GetAffiliateResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerAffiliate,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerLastActivity
   */
  public getPlayerLastActivity(
    input: PartialMessage<GetLastActivityRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLastActivityResponse> {
    return unary<GetLastActivityRequest, GetLastActivityResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerLastActivity,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerStatus
   */
  public getPlayerStatus(
    input: PartialMessage<GetStatusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetStatusResponse> {
    return unary<GetStatusRequest, GetStatusResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerStatus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerLegal
   */
  public getPlayerLegal(
    input: PartialMessage<GetLegalRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLegalResponse> {
    return unary<GetLegalRequest, GetLegalResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerLegal,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerBonus
   */
  public getPlayerBonus(
    input: PartialMessage<GetBonusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetBonusResponse> {
    return unary<GetBonusRequest, GetBonusResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerBonus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerVerification
   */
  public getPlayerVerification(
    input: PartialMessage<GetVerificationRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetVerificationResponse> {
    return unary<GetVerificationRequest, GetVerificationResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerVerification,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.payments.Players.GetPlayerBalance
   */
  public getPlayerBalance(
    input: PartialMessage<GetBalanceRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetBalanceResponse> {
    return unary<GetBalanceRequest, GetBalanceResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerBalance,
      input,
      options,
    );
  }
}
