// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/backoffice/operators/limits.proto (package payments.backoffice.operators.limits, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { DeleteOperatorLimitsByGroupRequest, DeleteOperatorLimitsByGroupResponse, GetOperatorLimitsByGroupRequest, GetOperatorLimitsByGroupResponse, GetOperatorLimitsListRequest, GetOperatorLimitsListResponse, UpsertOperatorLimitsRequest, UpsertOperatorLimitsResponse } from "./limits_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.backoffice.operators.limits.OperatorLimitService
 */
@Injectable({ providedIn: "root" })
export class OperatorLimitRemoteService {
  public static readonly typeName = "payments.backoffice.operators.limits.OperatorLimitService";

  public static readonly methods = {
    getOperatorLimitsList: {
      name: "GetOperatorLimitsList",
      I: GetOperatorLimitsListRequest,
      O: GetOperatorLimitsListResponse,
      kind: MethodKind.Unary,
    },
    getOperatorLimitsByGroup: {
      name: "GetOperatorLimitsByGroup",
      I: GetOperatorLimitsByGroupRequest,
      O: GetOperatorLimitsByGroupResponse,
      kind: MethodKind.Unary,
    },
    upsertOperatorLimits: {
      name: "UpsertOperatorLimits",
      I: UpsertOperatorLimitsRequest,
      O: UpsertOperatorLimitsResponse,
      kind: MethodKind.Unary,
    },
    deleteOperatorLimitsByGroup: {
      name: "DeleteOperatorLimitsByGroup",
      I: DeleteOperatorLimitsByGroupRequest,
      O: DeleteOperatorLimitsByGroupResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.backoffice.operators.limits.OperatorLimitService.GetOperatorLimitsList
   */
  public getOperatorLimitsList(
    input: PartialMessage<GetOperatorLimitsListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetOperatorLimitsListResponse> {
    return unary<GetOperatorLimitsListRequest, GetOperatorLimitsListResponse>(
      this.transport,
      OperatorLimitRemoteService,
      OperatorLimitRemoteService.methods.getOperatorLimitsList,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.operators.limits.OperatorLimitService.GetOperatorLimitsByGroup
   */
  public getOperatorLimitsByGroup(
    input: PartialMessage<GetOperatorLimitsByGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetOperatorLimitsByGroupResponse> {
    return unary<GetOperatorLimitsByGroupRequest, GetOperatorLimitsByGroupResponse>(
      this.transport,
      OperatorLimitRemoteService,
      OperatorLimitRemoteService.methods.getOperatorLimitsByGroup,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.operators.limits.OperatorLimitService.UpsertOperatorLimits
   */
  public upsertOperatorLimits(
    input: PartialMessage<UpsertOperatorLimitsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpsertOperatorLimitsResponse> {
    return unary<UpsertOperatorLimitsRequest, UpsertOperatorLimitsResponse>(
      this.transport,
      OperatorLimitRemoteService,
      OperatorLimitRemoteService.methods.upsertOperatorLimits,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.operators.limits.OperatorLimitService.DeleteOperatorLimitsByGroup
   */
  public deleteOperatorLimitsByGroup(
    input: PartialMessage<DeleteOperatorLimitsByGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteOperatorLimitsByGroupResponse> {
    return unary<DeleteOperatorLimitsByGroupRequest, DeleteOperatorLimitsByGroupResponse>(
      this.transport,
      OperatorLimitRemoteService,
      OperatorLimitRemoteService.methods.deleteOperatorLimitsByGroup,
      input,
      options,
    );
  }
}
