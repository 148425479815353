import { inject, Injectable } from '@angular/core';
import type { Status } from '@pinup-grpc/services/bo/players/players_pb';

import type { StatusInfo } from '@bo/common';
import { License, mapStatuses } from '@bo/common';

@Injectable()
export class StatusService {
  private readonly license = inject(License);

  public getStatuses(status: Status): StatusInfo[] {
    return mapStatuses(this.license, status);
  }
}
