// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/bo/avatar/v1/avatar.proto (package gamification.service.bo.avatar.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CreateRequest, CreateResponse, GetAllRequest, GetAllResponse, GetRequest, GetResponse, PushActionRequest, PushActionResponse, UpdateRequest, UpdateResponse, UploadImageRequest, UploadImageResponse } from "./avatar_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * AvatarService:
 * ------------------------
 * Format: proto
 * client: back office (BO)
 * server: gamification
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.service.bo.avatar.v1.AvatarService
 */
@Injectable({ providedIn: "root" })
export class AvatarRemoteService {
  public static readonly typeName = "gamification.service.bo.avatar.v1.AvatarService";

  public static readonly methods = {
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
    getAll: {
      name: "GetAll",
      I: GetAllRequest,
      O: GetAllResponse,
      kind: MethodKind.Unary,
    },
    uploadImage: {
      name: "UploadImage",
      I: UploadImageRequest,
      O: UploadImageResponse,
      kind: MethodKind.Unary,
    },
    pushAction: {
      name: "PushAction",
      I: PushActionRequest,
      O: PushActionResponse,
      kind: MethodKind.Unary,
    },
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * Create: add a new avatar from gamification
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.avatar.v1.AvatarService.Create
   */
  public create(
    input: PartialMessage<CreateRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreateResponse> {
    return unary<CreateRequest, CreateResponse>(
      this.transport,
      AvatarRemoteService,
      AvatarRemoteService.methods.create,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Update: edit existing avatar from gamification
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.avatar.v1.AvatarService.Update
   */
  public update(
    input: PartialMessage<UpdateRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateResponse> {
    return unary<UpdateRequest, UpdateResponse>(
      this.transport,
      AvatarRemoteService,
      AvatarRemoteService.methods.update,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetAll: get all avatars from gamification
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.avatar.v1.AvatarService.GetAll
   */
  public getAll(
    input: PartialMessage<GetAllRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAllResponse> {
    return unary<GetAllRequest, GetAllResponse>(
      this.transport,
      AvatarRemoteService,
      AvatarRemoteService.methods.getAll,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * UploadImage: upload image for avatar and return information of uploaded image.
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.avatar.v1.AvatarService.UploadImage
   */
  public uploadImage(
    input: PartialMessage<UploadImageRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UploadImageResponse> {
    return unary<UploadImageRequest, UploadImageResponse>(
      this.transport,
      AvatarRemoteService,
      AvatarRemoteService.methods.uploadImage,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * PushAction: push action (delete,restore,publish,unpublish) for avatar update
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.avatar.v1.AvatarService.PushAction
   */
  public pushAction(
    input: PartialMessage<PushActionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<PushActionResponse> {
    return unary<PushActionRequest, PushActionResponse>(
      this.transport,
      AvatarRemoteService,
      AvatarRemoteService.methods.pushAction,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Get: get full avatar info from gamification
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.avatar.v1.AvatarService.Get
   */
  public get(
    input: PartialMessage<GetRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetResponse> {
    return unary<GetRequest, GetResponse>(
      this.transport,
      AvatarRemoteService,
      AvatarRemoteService.methods.get,
      input,
      options,
    );
  }
}
