import { inject, Pipe, type PipeTransform } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { PlayerService } from '@bo/common';

import { CASHBOX_ADMIN_URL } from '../cashbox-admin.providers';
import { generateCashBoxAdminLink } from './cashbox-admin-link.utils';

@Pipe({
  name: 'boCashBoxAdminProfileLink',
  standalone: true,
  pure: false,
})
export class CashBoxAdminProfileLinkPipe implements PipeTransform {
  private readonly url = inject(CASHBOX_ADMIN_URL);
  private readonly playerId = toSignal(inject(PlayerService).playerId$, {
    requireSync: true,
  });

  public transform(path: string): string {
    const baseUrl = generateCashBoxAdminLink(this.url, path);
    const playerId = this.playerId();

    return `${baseUrl}/#${playerId}`;
  }
}
