import type { EnvironmentProviders } from '@angular/core';
import {
  ENVIRONMENT_INITIALIZER,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

import { bindCallbacks, provideMultiValue } from '@pu/sdk';

export const GOOGLE_SIGN_IN_CALLBACK = new InjectionToken<
  readonly google.accounts.id.IdConfiguration['callback'][]
>('GOOGLE_SIGN_IN_CALLBACK');

export const GOOGLE_SIGN_IN_CLIENT_ID = new InjectionToken<
  google.accounts.id.IdConfiguration['client_id']
>('GOOGLE_SIGN_IN_CLIENT_ID');

export const GOOGLE_SIGN_IN_NATIVE_CALLBACK = new InjectionToken<
  readonly google.accounts.id.IdConfiguration['native_callback'][]
>('GOOGLE_SIGN_IN_NATIVE_CALLBACK');

export const GOOGLE_SIGN_IN_INTERMEDIATE_IFRAME_CLOSE_CALLBACK =
  new InjectionToken<
    readonly google.accounts.id.IdConfiguration['intermediate_iframe_close_callback'][]
  >('GOOGLE_SIGN_IN_INTERMEDIATE_IFRAME_CLOSE_CALLBACK');

export function provideGoogleSignIn(
  config: Omit<google.accounts.id.IdConfiguration, 'client_id'> = {},
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideMultiValue(ENVIRONMENT_INITIALIZER, () => {
      google.accounts.id.initialize({
        ...config,
        client_id: inject(GOOGLE_SIGN_IN_CLIENT_ID),
        callback: bindCallbacks(GOOGLE_SIGN_IN_CALLBACK, config.callback),
        native_callback: bindCallbacks(
          GOOGLE_SIGN_IN_NATIVE_CALLBACK,
          config.native_callback,
        ),
        intermediate_iframe_close_callback: bindCallbacks(
          GOOGLE_SIGN_IN_INTERMEDIATE_IFRAME_CLOSE_CALLBACK,
          config.intermediate_iframe_close_callback,
        ),
      });
    }),
  ]);
}
