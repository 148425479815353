// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/payments/payments.proto (package payments.backoffice.payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message payments.backoffice.payments.PaymentAggregator
 */
export const PaymentAggregator = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.payments.PaymentAggregator",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message payments.backoffice.payments.FindPaymentAggregatorsRequest
 */
export const FindPaymentAggregatorsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.payments.FindPaymentAggregatorsRequest",
  [],
);

/**
 * @generated from message payments.backoffice.payments.FindPaymentAggregatorsResponse
 */
export const FindPaymentAggregatorsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.payments.FindPaymentAggregatorsResponse",
  () => [
    { no: 1, name: "aggregators", kind: "message", T: PaymentAggregator, repeated: true },
  ],
);

/**
 * @generated from message payments.backoffice.payments.PaymentMethod
 */
export const PaymentMethod = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.payments.PaymentMethod",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "popUpDepositName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "popUpWithdrawalName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.backoffice.payments.FindPaymentMethodsRequest
 */
export const FindPaymentMethodsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.payments.FindPaymentMethodsRequest",
  [],
);

/**
 * @generated from message payments.backoffice.payments.FindPaymentMethodsResponse
 */
export const FindPaymentMethodsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.payments.FindPaymentMethodsResponse",
  () => [
    { no: 1, name: "methods", kind: "message", T: PaymentMethod, repeated: true },
  ],
);

