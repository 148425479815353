// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/insvc/player/v1/player.proto (package gamification.service.insvc.player.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetByIDsRequest, GetByIDsResponse } from "./player_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * PlayerService:
 * ------------------------
 * Format: proto
 * client: insvc - for internal services usages
 * server: gamification
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.service.insvc.player.v1.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "gamification.service.insvc.player.v1.PlayerService";

  public static readonly methods = {
    getByIDs: {
      name: "GetByIDs",
      I: GetByIDsRequest,
      O: GetByIDsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * GetByIDs: get list of player info by provided array of ids
   * ------------------------
   *
   * @generated from rpc gamification.service.insvc.player.v1.PlayerService.GetByIDs
   */
  public getByIDs(
    input: PartialMessage<GetByIDsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetByIDsResponse> {
    return unary<GetByIDsRequest, GetByIDsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getByIDs,
      input,
      options,
    );
  }
}
