import { base64UrlEncode, sha1 } from '@pu/sdk';

// It has been experimentally determined that Chrome correctly processes links
// with a length of no more than 10000.
const MAX_SAFE_URL_LENGTH = 10000;

// TODO remove response
export async function grpcDebugLink(
  origin: string,
  service: string,
  method: string,
  request: Uint8Array | null,
): Promise<string> {
  const base = localStorage.getItem('grpcOrigin') ?? location.origin;
  const pointer: string = (await sha1(`${service}/${method}`)).slice(0, 8);
  const url = new URL(`/storybook/com/grpc/${pointer}`, base);

  url.searchParams.set('origin', origin);

  if (request !== null) {
    url.searchParams.set('request', base64UrlEncode(request));
  }

  if (url.toString().length > MAX_SAFE_URL_LENGTH) {
    url.searchParams.delete('response');
  }

  if (url.toString().length > MAX_SAFE_URL_LENGTH) {
    return 'url too big 😢';
  }

  return url.toString();
}
