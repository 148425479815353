import { inject, Injectable } from '@angular/core';
import { grpc } from '@improbable-eng/grpc-web';
import { _n } from '@pinup/null';
import type { Observable } from 'rxjs';

import type {
  GrpcInterceptor,
  GrpcRequestParams,
  GrpcResponse,
} from '@bo/common/old-grpc';

import { AuthService } from './auth.service';
import { isAuthGrpcMethod } from './is-auth-grpc-method';

function isUnauthenticated(error: unknown): boolean {
  // eslint-disable-next-line
  return (error as any)?.originalGrpcError?.code === grpc.Code.Unauthenticated;
}

@Injectable()
export class AuthInterceptor implements GrpcInterceptor {
  private readonly auth = inject(AuthService);

  public intercept(
    serviceName: string,
    methodName: string,
    params: GrpcRequestParams,
    request$: Observable<GrpcResponse>,
    retryFn: () => Observable<GrpcResponse>,
  ): Observable<GrpcResponse> {
    if (isAuthGrpcMethod(serviceName, methodName)) {
      return request$;
    }

    let firstTry = true;

    return this.auth.withAccess<GrpcResponse>(isUnauthenticated, (access) => {
      const authHeader = `Bearer ${access}`;

      // TODO
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      _n(params.metadata).authorization = [authHeader];

      if (firstTry) {
        firstTry = false;

        return request$;
      }

      return retryFn();
    });
  }
}
