import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs';

import type { SmartDialogOptions } from '@pu/sdk';
import { provideValue, SmartDialog } from '@pu/sdk';

import {
  CHANGE_EMAIL_DIALOG_DATA,
  ChangeEmailDialogComponent,
} from './change-email-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ChangeEmailDialogService {
  private readonly dialog = inject(SmartDialog);

  public changeEmail(
    profileId: bigint,
    email: string,
    { providers = [] }: SmartDialogOptions = {},
  ): Observable<unknown> {
    return (
      this.dialog
        .open(ChangeEmailDialogComponent, [
          ...providers,
          provideValue(CHANGE_EMAIL_DIALOG_DATA, { profileId, email }),
        ])
        /*
         * TODO: Revise the logic of popup action processing. Temporarily
         *  filter 'cancel' (matDialogClose="cancel"), but may need to be more
         *  flexible in the future.
         * */
        .pipe(filter((result) => result !== 'cancel'))
    );
  }
}
