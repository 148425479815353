// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/payment_cabinet.proto (package cabinet, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetAccountCommentRequest, GetAccountCommentResponse, Sms2FaAuthRequest, Sms2FaAuthResponse, WriteAccountCommentRequest, WriteAccountCommentResponse } from "./payment_cabinet_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service cabinet.PaymentCabinet
 */
@Injectable({ providedIn: "root" })
export class PaymentCabinetRemoteService {
  public static readonly typeName = "cabinet.PaymentCabinet";

  public static readonly methods = {
    getAccountComment: {
      name: "GetAccountComment",
      I: GetAccountCommentRequest,
      O: GetAccountCommentResponse,
      kind: MethodKind.Unary,
    },
    writeAccountComment: {
      name: "WriteAccountComment",
      I: WriteAccountCommentRequest,
      O: WriteAccountCommentResponse,
      kind: MethodKind.Unary,
    },
    sms2FaAuth: {
      name: "Sms2FaAuth",
      I: Sms2FaAuthRequest,
      O: Sms2FaAuthResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * pinup.product.<license>.payments.player.comment.fetch
   * GetAccountComment - get one comment for specified player
   *
   * @generated from rpc cabinet.PaymentCabinet.GetAccountComment
   */
  public getAccountComment(
    input: PartialMessage<GetAccountCommentRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAccountCommentResponse> {
    return unary<GetAccountCommentRequest, GetAccountCommentResponse>(
      this.transport,
      PaymentCabinetRemoteService,
      PaymentCabinetRemoteService.methods.getAccountComment,
      input,
      options,
    );
  }

  /**
   * pinup.product.<license>.payments.player.comment.write
   * WriteAccountComment - writes a comment to specified player
   *
   * @generated from rpc cabinet.PaymentCabinet.WriteAccountComment
   */
  public writeAccountComment(
    input: PartialMessage<WriteAccountCommentRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<WriteAccountCommentResponse> {
    return unary<WriteAccountCommentRequest, WriteAccountCommentResponse>(
      this.transport,
      PaymentCabinetRemoteService,
      PaymentCabinetRemoteService.methods.writeAccountComment,
      input,
      options,
    );
  }

  /**
   * pinup.product.<license>.payments.sms2fa.money.out.send
   * Sms2FaMoneyOut - starts 2fa auth flow for the player
   *
   * @generated from rpc cabinet.PaymentCabinet.Sms2FaAuth
   */
  public sms2FaAuth(
    input: PartialMessage<Sms2FaAuthRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<Sms2FaAuthResponse> {
    return unary<Sms2FaAuthRequest, Sms2FaAuthResponse>(
      this.transport,
      PaymentCabinetRemoteService,
      PaymentCabinetRemoteService.methods.sms2FaAuth,
      input,
      options,
    );
  }
}
