// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/bo/players.proto (package player_analysis.bo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPlayerIntersectionsRequest, GetPlayerIntersectionsResponse } from "./players_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service player_analysis.bo.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "player_analysis.bo.PlayerService";

  public static readonly methods = {
    getPlayerIntersections: {
      name: "GetPlayerIntersections",
      I: GetPlayerIntersectionsRequest,
      O: GetPlayerIntersectionsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc player_analysis.bo.PlayerService.GetPlayerIntersections
   */
  public getPlayerIntersections(
    input: PartialMessage<GetPlayerIntersectionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerIntersectionsResponse> {
    return unary<GetPlayerIntersectionsRequest, GetPlayerIntersectionsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getPlayerIntersections,
      input,
      options,
    );
  }
}
