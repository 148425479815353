import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationStart,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { distinctUntilChanged, filter, map, pairwise, startWith } from 'rxjs';

@Component({
  selector: 'bo-page-layout',
  host: {
    '[class.sidebar-visible]': 'sidebarVisible()',
    '[style.--sidebar-width.px]': 'sidebarWidth()',
  },
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {
  public sidebarDisabled = input(false, { transform: booleanAttribute });
  public sidebarVisible = model<boolean>(true);
  public sidebarWidth = model<number>(320);

  private readonly router = inject(Router);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        map(({ url }) => url),
        startWith(this.router.url),
        map((url) => url.split(';')[0]),
        pairwise(),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.hideSidebar();
      });
  }

  public toggleSidebar(): void {
    this.sidebarVisible.update((value) => !value);
  }

  public hideSidebar(): void {
    this.sidebarVisible.set(false);
  }
}
