// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/currencies_exchange/currencies_exchange.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ConvertRequest, ConvertResponse } from "./currencies_exchange_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.CurrencyExchangeService
 */
@Injectable({ providedIn: "root" })
export class CurrencyExchangeRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.CurrencyExchangeService";

  public static readonly methods = {
    convert: {
      name: "Convert",
      I: ConvertRequest,
      O: ConvertResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.currencies_exchange.CurrencyExchangeService.Convert
   */
  public convert(
    input: PartialMessage<ConvertRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ConvertResponse> {
    return unary<ConvertRequest, ConvertResponse>(
      this.transport,
      CurrencyExchangeRemoteService,
      CurrencyExchangeRemoteService.methods.convert,
      input,
      options,
    );
  }
}
