import type { ValidatorFn } from '@angular/forms';
import { isString } from 'lodash-es';

import { ValidatorError } from './validator.error';

export class StrictMinLengthValidatorError extends ValidatorError {
  constructor(
    public readonly requiredLength: number,
    public readonly normalizedLength: number,
  ) {
    super();
  }

  public toValidationErrorMessage(): string {
    return `Min length is equal to ${this.requiredLength}`;
  }
}

export function strictMinLengthValidator(requiredLength: number): ValidatorFn {
  return ({ value }) => {
    if (!isString(value) || value.length === 0) {
      return null;
    }

    const normalized: string = value.replaceAll(/\s+/g, ' ').trim();

    return normalized.length < requiredLength
      ? {
          strictMinLength: new StrictMinLengthValidatorError(
            requiredLength,
            normalized.length,
          ),
        }
      : null;
  };
}
