// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/bo/dictionary/v1/dictionary.proto (package gamification.service.bo.dictionary.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetLanguagesRequest, GetLanguagesResponse } from "./dictionary_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * DictionaryService:
 * ------------------------
 * Format: proto
 * client: back office (BO) or other internal services
 * server: gamification
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.service.bo.dictionary.v1.DictionaryService
 */
@Injectable({ providedIn: "root" })
export class DictionaryRemoteService {
  public static readonly typeName = "gamification.service.bo.dictionary.v1.DictionaryService";

  public static readonly methods = {
    getLanguages: {
      name: "GetLanguages",
      I: GetLanguagesRequest,
      O: GetLanguagesResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * GetLanguages: get languages for website.
   * ------------------------
   *
   * @generated from rpc gamification.service.bo.dictionary.v1.DictionaryService.GetLanguages
   */
  public getLanguages(
    input: PartialMessage<GetLanguagesRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetLanguagesResponse> {
    return unary<GetLanguagesRequest, GetLanguagesResponse>(
      this.transport,
      DictionaryRemoteService,
      DictionaryRemoteService.methods.getLanguages,
      input,
      options,
    );
  }
}
