import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { Timestamp } from '@bufbuild/protobuf';
import { toDate } from 'date-fns';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'toDate',
  standalone: true,
})
export class ToDatePipe implements PipeTransform {
  public transform(value: Date | Timestamp | number): Date;
  public transform(value: null | undefined): null;
  public transform(
    value: Date | Timestamp | number | null | undefined,
  ): Date | null;
  public transform(
    value: Date | Timestamp | number | null | undefined,
  ): Date | null {
    if (isNil(value)) {
      return null;
    }

    if (value instanceof Timestamp) {
      return value.toDate();
    }

    return toDate(value);
  }
}
