// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/std/query/v1/sorting.proto (package pinup.std.query.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Sorting defines order and field by which to sort.
 *
 * @generated from message pinup.std.query.v1.Sorting
 */
export const Sorting = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.Sorting",
  () => [
    { no: 1, name: "field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(Sorting_Order) },
  ],
);

/**
 * @generated from enum pinup.std.query.v1.Sorting.Order
 */
export const Sorting_Order = /*@__PURE__*/ proto3.makeEnum(
  "pinup.std.query.v1.Sorting.Order",
  [
    {no: 0, name: "ORDER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ORDER_ASC", localName: "ASC"},
    {no: 2, name: "ORDER_DESC", localName: "DESC"},
  ],
);

