import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerRemoteService } from '@pinup-grpc/services/bo/players/players_ng';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs';

import { stringToBigInt } from '@pu/sdk';

import { DEFAULT_CONVERT_CURRENCY } from '../constants/common.const';
import { PLAYER_ID } from './player.providers';

@Injectable()
export class PlayerService {
  private readonly route = inject(ActivatedRoute);
  private readonly passedPlayerId$ = inject(PLAYER_ID, { optional: true });
  private readonly playerRemoteService = inject(PlayerRemoteService);

  private readonly playerIdFromRouteParams$ = this.route.paramMap.pipe(
    map((paramMap) => stringToBigInt(paramMap.get('playerId'))),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public readonly playerId$ =
    this.passedPlayerId$ ?? this.playerIdFromRouteParams$;

  public readonly player$ = this.playerId$.pipe(
    switchMap((id) =>
      this.playerRemoteService.getPlayer({
        convertCurrency: DEFAULT_CONVERT_CURRENCY,
        id,
      }),
    ),
    map(({ player }) => player),
    shareReplay(1),
  );

  public readonly isPlayerTest$ = this.player$.pipe(
    map((player) => player.isTest),
  );
}
