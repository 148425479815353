// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/casino/casino.proto (package pinup.stat.report.casino, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { Sorting } from "../../../std/query/v1/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../std/query/v1/pagination_pb.js";
import { BettingStatus, CasinoStatus, DigitainStatus } from "../players/players_pb.js";
import { StringMultiselect, SumRange } from "../base/filtering_pb.js";
import { TimeRange } from "../../../std/query/v1/filter_pb.js";
import { SumCurrency } from "../base/models_pb.js";

/**
 * @generated from enum pinup.stat.report.casino.TransactionPurse
 */
export const TransactionPurse = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.casino.TransactionPurse",
  [
    {no: 0, name: "TRANSACTION_PURSE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_PURSE_REAL", localName: "REAL"},
    {no: 2, name: "TRANSACTION_PURSE_BONUS", localName: "BONUS"},
  ],
);

/**
 * @generated from enum pinup.stat.report.casino.TransactionType
 */
export const TransactionType = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.casino.TransactionType",
  [
    {no: 0, name: "TRANSACTION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_TYPE_REAL", localName: "REAL"},
    {no: 2, name: "TRANSACTION_TYPE_BONUS", localName: "BONUS"},
    {no: 3, name: "TRANSACTION_TYPE_AVIATOR", localName: "AVIATOR"},
  ],
);

/**
 * @generated from message pinup.stat.report.casino.ListTransactionsRequest
 */
export const ListTransactionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.ListTransactionsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: ListTransactionsRequest_Filter },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * Tab is grouping by purse: real, bonus. tab aviator is all transactions for Aviator game
 * real and purse tabs must not contain transactions from the aviator game
 *
 * @generated from enum pinup.stat.report.casino.ListTransactionsRequest.Tab
 */
export const ListTransactionsRequest_Tab = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.casino.ListTransactionsRequest.Tab",
  [
    {no: 0, name: "TAB_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TAB_REAL", localName: "REAL"},
    {no: 2, name: "TAB_BONUS", localName: "BONUS"},
    {no: 3, name: "TAB_AVIATOR", localName: "AVIATOR"},
  ],
);

/**
 * Filter is all fields for filtering
 *
 * @generated from message pinup.stat.report.casino.ListTransactionsRequest.Filter
 */
export const ListTransactionsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.ListTransactionsRequest.Filter",
  () => [
    { no: 1, name: "profile", kind: "message", T: ListTransactionsRequest_Filter_Profile },
    { no: 2, name: "transaction", kind: "message", T: ListTransactionsRequest_Filter_Transaction },
    { no: 3, name: "tabs", kind: "enum", T: proto3.getEnumType(ListTransactionsRequest_Tab), repeated: true },
  ],
  {localName: "ListTransactionsRequest_Filter"},
);

/**
 * @generated from message pinup.stat.report.casino.ListTransactionsRequest.Filter.Profile
 */
export const ListTransactionsRequest_Filter_Profile = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.ListTransactionsRequest.Filter.Profile",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "isVip", kind: "message", T: BoolValue },
    { no: 3, name: "email", kind: "message", T: StringValue },
    { no: 4, name: "casinoStatuses", kind: "enum", T: proto3.getEnumType(CasinoStatus), repeated: true },
    { no: 5, name: "bettingStatuses", kind: "enum", T: proto3.getEnumType(BettingStatus), repeated: true },
    { no: 6, name: "digitainStatuses", kind: "enum", T: proto3.getEnumType(DigitainStatus), repeated: true },
    { no: 7, name: "playerGeo", kind: "message", T: StringMultiselect },
  ],
  {localName: "ListTransactionsRequest_Filter_Profile"},
);

/**
 * @generated from message pinup.stat.report.casino.ListTransactionsRequest.Filter.Transaction
 */
export const ListTransactionsRequest_Filter_Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.ListTransactionsRequest.Filter.Transaction",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "balanceBefore", kind: "message", T: SumRange },
    { no: 3, name: "balanceAfter", kind: "message", T: SumRange },
    { no: 4, name: "createdAt", kind: "message", T: TimeRange },
    { no: 5, name: "betAmount", kind: "message", T: SumRange },
    { no: 6, name: "winLoseAmount", kind: "message", T: SumRange },
    { no: 7, name: "gameRoundIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "actionIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "gameIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 10, name: "providers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "gameSystemNames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "onlySuccessful", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ListTransactionsRequest_Filter_Transaction"},
);

/**
 * @generated from message pinup.stat.report.casino.Finance
 */
export const Finance = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.Finance",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balanceBefore", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "balanceAfter", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "betAmount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "winLoseAmount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message pinup.stat.report.casino.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.Transaction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "purse", kind: "enum", T: proto3.getEnumType(TransactionPurse) },
    { no: 4, name: "finance", kind: "message", T: Finance },
    { no: 5, name: "convertedFinance", kind: "message", T: Finance },
    { no: 6, name: "gameRoundId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "actionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "gameId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "gameSystemName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "gameProvider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "isRollback", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "createdAt", kind: "message", T: Timestamp },
    { no: 13, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "type", kind: "enum", T: proto3.getEnumType(TransactionType) },
  ],
);

/**
 * @generated from message pinup.stat.report.casino.ListTransactionsResponse
 */
export const ListTransactionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.casino.ListTransactionsResponse",
  () => [
    { no: 1, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 3, name: "totalAmount", kind: "message", T: SumCurrency },
  ],
);

