import { BehaviorSubject } from 'rxjs';

import type { PaginationPayload } from '../../pagination.model';
import { PaginationManagerBase } from '../pagination-manager.base';

export class DefaultPaginationManager<
  T extends PaginationPayload,
> extends PaginationManagerBase<T> {
  public source$ = new BehaviorSubject<T>(this.initialValue);

  public update(value: Partial<T>): void {
    const updatedValue = { ...this.source$.value, ...value };

    this.source$.next(updatedValue);
  }
}
