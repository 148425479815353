import { ChangeDetectionStrategy, Component } from '@angular/core';
@Component({
  selector: 'bo-accordion',
  standalone: true,
  template: `
    <ng-content select="bo-accordion-item" />
  `,
  styleUrl: './accordion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AccordionComponent {}
