// @generated by protoc-gen-es v1.10.0
// @generated from file base/enum.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum stat.Project
 */
export const Project = /*@__PURE__*/ proto3.makeEnum(
  "stat.Project",
  [
    {no: 0, name: "PROJECT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PROJECT_CASINO", localName: "CASINO"},
    {no: 2, name: "PROJECT_SPORT", localName: "SPORT"},
    {no: 3, name: "PROJECT_POKER", localName: "POKER"},
  ],
);

/**
 * @generated from enum stat.Method
 */
export const Method = /*@__PURE__*/ proto3.makeEnum(
  "stat.Method",
  [
    {no: 0, name: "METHOD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "METHOD_BET", localName: "BET"},
    {no: 2, name: "METHOD_WIN", localName: "WIN"},
    {no: 3, name: "METHOD_TRANSFER", localName: "TRANSFER"},
  ],
);

/**
 * @generated from enum stat.Money
 */
export const Money = /*@__PURE__*/ proto3.makeEnum(
  "stat.Money",
  [
    {no: 0, name: "MONEY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MONEY_REAL", localName: "REAL"},
    {no: 2, name: "MONEY_BONUS", localName: "BONUS"},
    {no: 3, name: "MONEY_SPECIFIC", localName: "SPECIFIC"},
  ],
);

/**
 * @generated from enum stat.Game
 */
export const Game = /*@__PURE__*/ proto3.makeEnum(
  "stat.Game",
  [
    {no: 0, name: "GAME_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GAME_CASINO_LIVE", localName: "CASINO_LIVE"},
    {no: 2, name: "GAME_CASINO_VIRTUAL", localName: "CASINO_VIRTUAL"},
    {no: 3, name: "GAME_CASINO_TV", localName: "CASINO_TV"},
  ],
);

/**
 * @generated from enum stat.Action
 */
export const Action = /*@__PURE__*/ proto3.makeEnum(
  "stat.Action",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "DEPOSIT_TRY"},
    {no: 2, name: "DEPOSIT_TRY_ND"},
    {no: 3, name: "DEPOSIT_ADD"},
    {no: 4, name: "DEPOSIT_FIRST"},
    {no: 5, name: "DEPOSIT_SECOND"},
    {no: 6, name: "DEPOSIT_ADMIN"},
    {no: 7, name: "DEPOSIT_TAX"},
    {no: 8, name: "DEPOSIT_CHARGEBACK"},
    {no: 10, name: "BONUS_ADD"},
    {no: 11, name: "BONUS_WAGE"},
    {no: 20, name: "PINCOIN_ADD"},
    {no: 21, name: "PINCOIN_EXCHANGE"},
    {no: 22, name: "TRANSFER_TO_CASINO"},
    {no: 23, name: "TRANSFER_TO_SPORT"},
    {no: 90, name: "MONEYOUT"},
    {no: 91, name: "MONEY_OUT_FREE"},
    {no: 92, name: "MONEY_OUT_TAX"},
    {no: 93, name: "WITHDRAWAL_REQUEST"},
    {no: 100, name: "BET"},
    {no: 101, name: "BET_ROLLBACK"},
    {no: 102, name: "WIN"},
    {no: 103, name: "WIN_ROLLBACK"},
    {no: 104, name: "CALC_BET"},
    {no: 105, name: "CALC_BET_ROLLBACK"},
    {no: 200, name: "BONUS_BET"},
    {no: 201, name: "BONUS_BET_ROLLBACK"},
    {no: 202, name: "BONUS_WIN"},
    {no: 203, name: "BONUS_WIN_ROLLBACK"},
    {no: 204, name: "BONUS_CALC_BET"},
    {no: 205, name: "BONUS_CALC_BET_ROLLBACK"},
    {no: 300, name: "FREESPIN_ADD"},
    {no: 301, name: "FREESPIN_WIN"},
    {no: 400, name: "FREEBET_ADD"},
    {no: 401, name: "FREEBET_BET"},
    {no: 402, name: "FREEBET_BET_ROLLBACK"},
    {no: 403, name: "FREEBET_WIN"},
    {no: 404, name: "FREEBET_WIN_ROLLBACK"},
    {no: 405, name: "FREEBET_WIN_WITH_BET_SUM"},
    {no: 406, name: "FREEBET_WIN_WITH_BET_SUM_ROLLBACK"},
    {no: 407, name: "FREEBET_WIN_TOTAL_SUM"},
    {no: 500, name: "LOTTERY_ADD"},
    {no: 501, name: "LOTTERY_WIN"},
    {no: 502, name: "LOTTERY_WIN_BONUS"},
    {no: 600, name: "TOURNAMENT_WIN"},
    {no: 700, name: "EMAIL_CONFIRM"},
  ],
);

