import { Directive, ElementRef, inject } from '@angular/core';

import { SuspenseDirective } from './suspense.directive';

// `insert` is set directly via the DOM to prevent the `blur` event from being
// emitted while change detection is happening
@Directive({
  selector: '[puSuspense][puInertSuspense]',
  standalone: true,
})
export class InertSuspenseDirective {
  private readonly nativeElement = inject(ElementRef, { self: true })
    .nativeElement as HTMLElement;
  protected readonly suspense = inject(SuspenseDirective, { self: true });

  private previous: HTMLElement = document.body;

  constructor() {
    this.suspense.start.subscribe(() => {
      if (document.activeElement instanceof HTMLElement) {
        this.previous = document.activeElement;
      }

      this.nativeElement.inert = true;
    });

    this.suspense.finalize.subscribe(() => {
      this.nativeElement.inert = false;

      if (this.nativeElement.contains(this.previous)) {
        this.previous.focus();
      }
    });
  }
}
