// @generated by protoc-gen-es v1.10.0
// @generated from file services/player_sessions.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Session } from "../base/session_pb.js";

/**
 * @generated from message players.GetPlayerLastSessionRequest
 */
export const GetPlayerLastSessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerLastSessionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerLastSessionResponse
 */
export const GetPlayerLastSessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerLastSessionResponse",
  () => [
    { no: 1, name: "session", kind: "message", T: Session },
  ],
);

