import type { Provider, ProviderToken } from '@angular/core';
import { inject, InjectionToken } from '@angular/core';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';

import { provideExisting, provideFactory } from '@pu/sdk';

import type { WithPaginatedData } from '../../pagination-panel.model';
import { PaginationPanelFeatureKind } from '../models/pagination-panel.model';
import { PaginationTotalManagerService } from './pagination-total-manager.service';
import type {
  PaginationTotal,
  TotalInfoFeature,
  TotalInfoManager,
  TotalInfoOptions,
} from './total-info.model';

export const TOTAL_INFO_MANAGER = new InjectionToken<TotalInfoManager>(
  'TOTAL_INFO_MANAGER',
);

export const TOTAL_INFO = new InjectionToken<Observable<PaginationTotal>>(
  'TOTAL_INFO',
);

const provideTotalInfoManager = (): Provider[] => [
  PaginationTotalManagerService,
  provideExisting<TotalInfoManager>(
    TOTAL_INFO_MANAGER,
    PaginationTotalManagerService,
  ),
  provideFactory<Observable<PaginationTotal>>(
    TOTAL_INFO,
    () => inject(PaginationTotalManagerService).total$,
  ),
];

const provideTotalInfoConnectTo = (
  connectTo: ProviderToken<WithPaginatedData>,
): Provider[] => [
  provideFactory<Observable<PaginationTotal>>(TOTAL_INFO, () =>
    inject(connectTo).paginatedData$.pipe(
      map(({ pagination: { totalItems, totalPages } }) => ({
        totalPages,
        totalItems,
      })),
      distinctUntilChanged(isEqual),
      shareReplay(1),
    ),
  ),
];

function* generateProviders(options: TotalInfoOptions): Iterable<Provider> {
  if (options.connectTo) {
    yield provideTotalInfoConnectTo(options.connectTo);
  } else {
    yield provideTotalInfoManager();
  }
}

export const withTotalInfo = (
  options: TotalInfoOptions = {},
): TotalInfoFeature => {
  const providers: Provider[] = [...generateProviders(options)];

  return {
    kind: PaginationPanelFeatureKind.TotalInfoFeature,
    providers,
  };
};
