import { WithdrawalStatus } from '@pinup-grpc/services/bo/withdrawals/withdrawals_pb';

import { failedStatusIcon, successStatusIcon } from '@pu/sdk';

export enum WithdrawalTransactionStatus {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Process,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Success,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Failed,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Pending,
}

export const TRANSACTION_STATUS_TO_NAME_MAP: Record<
  WithdrawalTransactionStatus,
  string
> = {
  [WithdrawalTransactionStatus.Process]: 'Process',
  [WithdrawalTransactionStatus.Success]: 'Success',
  [WithdrawalTransactionStatus.Failed]: 'Failed',
  [WithdrawalTransactionStatus.Pending]: 'Pending',
};

export const WITHDRAWAL_STATUS_ICON_MAP = new Map<WithdrawalStatus, string>([
  [WithdrawalStatus.SUCCESSFUL, successStatusIcon],
  [WithdrawalStatus.CANCELED, failedStatusIcon],
  [WithdrawalStatus.CANCELED_USER, failedStatusIcon],
]);
