export enum SortByDate {
  Newest = 'newest',
  Oldest = 'oldest',
}

export enum SortName {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const SORT_ORDER_PARAM = 'sortOrder';

export const BASIC_TO_DATE_SORT_MAP: ReadonlyMap<SortName | null, SortByDate> =
  new Map([
    [SortName.DESC, SortByDate.Newest],
    [SortName.ASC, SortByDate.Oldest],
  ]);
