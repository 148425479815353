import { inject } from '@angular/core';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { defer, distinctUntilChanged, map, partition, shareReplay } from 'rxjs';

import type { PersistentData } from '@bo/common';

import type { FiltersProvider } from './filters-provider.model';
import { DATA_PERSISTENCE_FOR_FILTERS_PROVIDER } from './filters-provider.providers';

export class FiltersProviderService<T extends object>
  implements FiltersProvider<T>
{
  protected source$?: Observable<Partial<T>>;

  private readonly persistenceSource$ = inject(
    DATA_PERSISTENCE_FOR_FILTERS_PROVIDER,
    {
      self: true,
      optional: true,
    },
  );

  protected initialFilters = this.resolveInitialFilters();

  public readonly filters$ = defer(() => {
    if (this.source$) {
      return this.source$;
    }

    if (!this.persistenceSource$) {
      throw new Error(
        'When source$ is not provided, persistenceSource$ must be provided',
      );
    }

    if (!this.toModelData) {
      throw new Error(
        'When persistenceSource$ is provided, "toModelData" method must be implemented',
      );
    }

    // TODO: check how to avoid using "no-non-null-assertion" in this case
    return this.persistenceSource$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((data) => this.toModelData!(data)),
      distinctUntilChanged(isEqual),
    );
  }).pipe(shareReplay(1));

  private readonly filtersPartition = partition(this.filters$, (filters) => {
    return isEqual(filters, this.initialFilters);
  });

  public readonly initialFilters$ = this.filtersPartition[0].pipe(
    shareReplay(1),
  );
  public readonly changedFilters$ = this.filtersPartition[1].pipe(
    shareReplay(1),
  );

  protected toModelData?(data: PersistentData): Partial<T>;

  private resolveInitialFilters(): Partial<T> {
    if (this.persistenceSource$) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.toModelData!({});
    }

    // Extend this logic in needed
    return {};
  }
}
