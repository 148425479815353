// @generated by protoc-gen-es v1.10.0
// @generated from file common/applicant.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message kycaid.ApplicantVerification
 */
export const ApplicantVerification = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification",
  () => [
    { no: 1, name: "profile", kind: "message", T: ApplicantVerification_Profile },
    { no: 2, name: "document", kind: "message", T: ApplicantVerification_Document },
    { no: 3, name: "tax_id", kind: "message", T: ApplicantVerification_TaxID },
    { no: 4, name: "address", kind: "message", T: ApplicantVerification_Address },
    { no: 5, name: "facial", kind: "message", T: ApplicantVerification_Facial },
    { no: 6, name: "aml", kind: "message", T: ApplicantVerification_AML },
  ],
);

/**
 * @generated from message kycaid.ApplicantVerification.Profile
 */
export const ApplicantVerification_Profile = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification.Profile",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ApplicantVerification_Profile"},
);

/**
 * @generated from message kycaid.ApplicantVerification.Document
 */
export const ApplicantVerification_Document = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification.Document",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ApplicantVerification_Document"},
);

/**
 * @generated from message kycaid.ApplicantVerification.Address
 */
export const ApplicantVerification_Address = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification.Address",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ApplicantVerification_Address"},
);

/**
 * @generated from message kycaid.ApplicantVerification.Facial
 */
export const ApplicantVerification_Facial = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification.Facial",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ApplicantVerification_Facial"},
);

/**
 * @generated from message kycaid.ApplicantVerification.TaxID
 */
export const ApplicantVerification_TaxID = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification.TaxID",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ApplicantVerification_TaxID"},
);

/**
 * @generated from message kycaid.ApplicantVerification.AML
 */
export const ApplicantVerification_AML = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantVerification.AML",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ApplicantVerification_AML"},
);

/**
 * @generated from message kycaid.Address
 */
export const Address = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.Address",
  () => [
    { no: 1, name: "address_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "full_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "state_or_province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "street_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "building_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "unit_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message kycaid.Document
 */
export const Document = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.Document",
  () => [
    { no: 1, name: "document_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "document_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "additional_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "issue_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "expiry_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "issuing_authority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "card_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "account_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "income_sources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "annual_income", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "transaction_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "transaction_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "transaction_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "transaction_purpose", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "origin_funds", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "front_side_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "front_side", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "front_side_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "back_side_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "back_side", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "back_side_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "other_side_1_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "other_side_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "other_side_1_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "other_side_2_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "other_side_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "other_side_2_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 33, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.Applicant
 */
export const Applicant = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.Applicant",
  () => [
    { no: 1, name: "applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "dob", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "residence_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "telegram_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "pep", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "profile_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "addresses", kind: "message", T: Address, repeated: true },
    { no: 17, name: "documents", kind: "message", T: Document, repeated: true },
    { no: 18, name: "verification_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "external_applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "profile_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "duplicates", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 23, name: "reverificationTime", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message kycaid.ApplicantResponse
 */
export const ApplicantResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApplicantResponse",
  () => [
    { no: 1, name: "applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "dob", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "residence_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "telegram_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "pep", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "profile_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "verification_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "external_applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 22, name: "profile_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "duplicates", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 24, name: "reverificationTime", kind: "message", T: Timestamp },
  ],
);

