import type { Document } from '@pinup-grpc/service/backoffice/documents/documents_pb';

import { PlayerDocumentSide } from './document-types';

export type FileNameProp = {
  [K in keyof Document]: Document[K] extends string
    ? K extends `${string}Name`
      ? K
      : never
    : never;
}[keyof Document];

export type FileSideName = Pick<Document, FileNameProp>;

export const documentSideMap: Record<keyof FileSideName, PlayerDocumentSide> = {
  backSideName: PlayerDocumentSide.BACK_SIDE,
  frontSideName: PlayerDocumentSide.FRONT_SIDE,
  otherSide1Name: PlayerDocumentSide.OTHER_SIDE1,
  otherSide2Name: PlayerDocumentSide.FRONT_SIDE,
};
