// @generated by protoc-gen-es v1.10.0
// @generated from file services/giftbox/giftbox.proto (package pinup.players.player.giftbox, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.players.player.giftbox.GetStatusRequest
 */
export const GetStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetStatusRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetStatusResponse
 */
export const GetStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetStatusResponse",
  () => [
    { no: 1, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetCurrencyInfoRequest
 */
export const GetCurrencyInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetCurrencyInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetCurrencyInfoResponse
 */
export const GetCurrencyInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetCurrencyInfoResponse",
  () => [
    { no: 1, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetAddressRequest
 */
export const GetAddressRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetAddressRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetAddressResponse
 */
export const GetAddressResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetAddressResponse",
  () => [
    { no: 1, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetBonusRequest
 */
export const GetBonusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetBonusRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetBonusResponse
 */
export const GetBonusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetBonusResponse",
  () => [
    { no: 1, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "betBonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetAccountInfoRequest
 */
export const GetAccountInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetAccountInfoRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.giftbox.GetAccountInfoResponse
 */
export const GetAccountInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.giftbox.GetAccountInfoResponse",
  () => [
    { no: 1, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "blockSendDataToPartner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

