// @generated by protoc-gen-es v1.10.0
// @generated from file service/payment_cabinet.proto (package cabinet, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message cabinet.Error
 */
export const Error = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.Error",
  () => [
    { no: 1, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cabinet.GetAccountCommentRequest
 */
export const GetAccountCommentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.GetAccountCommentRequest",
  () => [
    { no: 1, name: "accountID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sort", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cabinet.GetAccountCommentResponse
 */
export const GetAccountCommentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.GetAccountCommentResponse",
  () => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "found", kind: "message", T: GetAccountCommentResponse_FoundComment },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message cabinet.GetAccountCommentResponse.FoundComment
 */
export const GetAccountCommentResponse_FoundComment = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.GetAccountCommentResponse.FoundComment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "accountID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created", kind: "message", T: Timestamp },
    { no: 7, name: "updated", kind: "message", T: Timestamp },
    { no: 8, name: "userID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "top", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "support", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "GetAccountCommentResponse_FoundComment"},
);

/**
 * @generated from message cabinet.WriteAccountCommentRequest
 */
export const WriteAccountCommentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.WriteAccountCommentRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "accountID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created", kind: "message", T: Timestamp },
    { no: 7, name: "updated", kind: "message", T: Timestamp },
    { no: 8, name: "userID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "top", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "support", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cabinet.WriteAccountCommentResponse
 */
export const WriteAccountCommentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.WriteAccountCommentResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message cabinet.Sms2FaAuthRequest
 */
export const Sms2FaAuthRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.Sms2FaAuthRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "paymentNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cabinet.Sms2FaAuthResponse
 */
export const Sms2FaAuthResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.Sms2FaAuthResponse",
  () => [
    { no: 1, name: "status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "timeResendDuration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

