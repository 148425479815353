<div class="wrapper">
  <div class="header">
    <h5 class="title">
      <ng-content select="[header]" />
      <div class="counter">
        <ng-content select="[counter]" />
      </div>
    </h5>

    <h6 class="subheader">
      <ng-content select="[subheader]" />
    </h6>
  </div>

  <div class="content">
    <div class="content-wrapper flex column gap-3">
      <ng-content select="[content]" />
    </div>

    <div class="bottom">
      <!--       TODO: move a to comp-->
      <!--      as idea can be refactored like
      <a [routerLink]="['../', 'logs']" bo-view-all-link><ng-content></a> in parent comp-->
      <a class="link flex align-items-center" [routerLink]="viewAllUrl()">
        {{ linkName() }}
        <bo-svg
          class="link-icon"
          [class.top-right]="arrowTopRight()"
          [size]="15"
          [unsafeSvgSource]="arrowIcon"
        />
      </a>
    </div>
  </div>
</div>
