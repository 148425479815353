import type { EnvironmentProviders } from '@angular/core';
import { InjectionToken, makeEnvironmentProviders } from '@angular/core';

import { provideMultiExisting } from '@pu/sdk';

import type { AccessGroupDepartment } from './access-group';

export interface DepartmentOptions {
  readonly redirectTo?: string;
}

export class Department {
  public static create(
    url: string,
    groups: Iterable<AccessGroupDepartment>,
    title: string,
    { redirectTo = '' }: DepartmentOptions = {},
  ): Department {
    return new Department(
      url,
      new Set<AccessGroupDepartment>(groups),
      title,
      redirectTo,
    );
  }

  private constructor(
    public readonly url: string,
    public readonly groups: ReadonlySet<AccessGroupDepartment>,
    public readonly title: string,
    public readonly redirectTo: string,
  ) {}
}

const FINANCE_DEPARTMENT = new InjectionToken<Department>(
  'FINANCE_DEPARTMENT',
  {
    providedIn: 'root',
    factory: () =>
      Department.create('finance', ['bo_dev', 'bo_qa', 'bo_fin'], 'Finance', {
        redirectTo: 'withdrawal',
      }),
  },
);

const RISK_DEPARTMENT = new InjectionToken<Department>('RISK_DEPARTMENT', {
  providedIn: 'root',
  factory: () =>
    Department.create('risk', ['bo_dev', 'bo_qa', 'bo_risk'], 'Risk', {
      redirectTo: 'dashboard',
    }),
});

const SUPPORT_DEPARTMENT = new InjectionToken<Department>(
  'SUPPORT_DEPARTMENT',
  {
    providedIn: 'root',
    factory: () =>
      Department.create(
        'support',
        ['bo_dev', 'bo_qa', 'bo_support'],
        'Support',
        { redirectTo: 'players' },
      ),
  },
);

const GAMIFICATION_DEPARTMENT = new InjectionToken<Department>(
  'GAMIFICATION_DEPARTMENT',
  {
    providedIn: 'root',
    factory: () =>
      Department.create(
        'gamification',
        ['bo_dev', 'bo_qa', 'bo_gamificantion'],
        'Gamification',
        { redirectTo: 'avatars' },
      ),
  },
);

const SETTINGS_MANAGER_DEPARTMENT = new InjectionToken<Department>(
  'SETTINGS_MANAGER_DEPARTMENT',
  {
    providedIn: 'root',
    factory: () =>
      Department.create(
        'settings-manager',
        ['bo_dev', 'bo_qa', 'bo_sm'],
        'Settings Management',
        { redirectTo: 'groups' },
      ),
  },
);

const STORYBOOK_DEPARTMENT = new InjectionToken<Department>(
  'STORYBOOK_DEPARTMENT',
  {
    providedIn: 'root',
    factory: () =>
      Department.create('storybook', ['bo_dev', 'bo_qa'], 'Storybook'),
  },
);

export const SUPPORTED_DEPARTMENTS = new InjectionToken<readonly Department[]>(
  'SUPPORTED_DEPARTMENTS',
);

function provideBaseDepartments(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideMultiExisting(SUPPORTED_DEPARTMENTS, FINANCE_DEPARTMENT),
    provideMultiExisting(SUPPORTED_DEPARTMENTS, RISK_DEPARTMENT),
    provideMultiExisting(SUPPORTED_DEPARTMENTS, SUPPORT_DEPARTMENT),
    provideMultiExisting(SUPPORTED_DEPARTMENTS, GAMIFICATION_DEPARTMENT),
    provideMultiExisting(SUPPORTED_DEPARTMENTS, SETTINGS_MANAGER_DEPARTMENT),
  ]);
}

export function provideDevDepartments(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideBaseDepartments(),
    provideMultiExisting(SUPPORTED_DEPARTMENTS, STORYBOOK_DEPARTMENT),
  ]);
}

export function provideProdDepartments(): EnvironmentProviders {
  return provideBaseDepartments();
}
