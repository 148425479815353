// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/refund_svc.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPlayerRefundsRequest, GetPlayerRefundsResponse } from "./refund_svc_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.RefundsSvc
 */
@Injectable({ providedIn: "root" })
export class RefundsSvcRemoteService {
  public static readonly typeName = "payments.RefundsSvc";

  public static readonly methods = {
    getPlayerRefunds: {
      name: "GetPlayerRefunds",
      I: GetPlayerRefundsRequest,
      O: GetPlayerRefundsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * UA
   * pinup.product.<licence>.cabinet.payments.refunds.requested
   * error returns in 'error' from replyMsg.header
   * GetPlayerRefunds - gets players refund by input params
   *
   * @generated from rpc payments.RefundsSvc.GetPlayerRefunds
   */
  public getPlayerRefunds(
    input: PartialMessage<GetPlayerRefundsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerRefundsResponse> {
    return unary<GetPlayerRefundsRequest, GetPlayerRefundsResponse>(
      this.transport,
      RefundsSvcRemoteService,
      RefundsSvcRemoteService.methods.getPlayerRefunds,
      input,
      options,
    );
  }
}
