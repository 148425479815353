import { booleanAttribute, Directive, inject, input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { SuspenseDirective } from './suspense.directive';

@Directive({
  selector: '[puSuspense][puDialogSuspense]',
  standalone: true,
})
export class DialogSuspenseDirective {
  private readonly suspense = inject(SuspenseDirective, { self: true });
  private readonly dialogRef = inject(MatDialogRef);

  public readonly autoClose = input(true, {
    alias: 'puDialogSuspense',
    transform: booleanAttribute,
  });

  constructor() {
    this.suspense.loadingChange.subscribe((loading) => {
      this.dialogRef.disableClose = loading;
    });

    this.suspense.complete.subscribe(() => {
      if (this.autoClose()) {
        this.dialogRef.close();
      }
    });
  }
}
