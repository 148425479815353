import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Document } from '@pinup-grpc/service/backoffice/documents/documents_pb';

import type { FileFromDocument } from '../models/document-verification';
import { filesFromDocument } from '../utils/files-from-document';

@Pipe({
  name: 'filesFromDocument',
  standalone: true,
})
export class FilesFromDocumentPipe implements PipeTransform {
  public transform(doc: Document): readonly FileFromDocument[] {
    return filesFromDocument(doc);
  }
}
