// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/std/query/v1/filter.proto (package pinup.std.query.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Period restricts time range for particular field.
 *
 * @generated from message pinup.std.query.v1.Period
 */
export const Period = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.Period",
  () => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ],
);

/**
 * TimeRange restricts time range for particular field.
 *
 * @generated from message pinup.std.query.v1.TimeRange
 */
export const TimeRange = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.TimeRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ],
);

