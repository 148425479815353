// @generated by protoc-gen-es v1.10.0
// @generated from file base/bo/v1/avatar_enum.proto (package gamification.base.bo.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of enumeration for avatar status
 *
 * @generated from enum gamification.base.bo.v1.AvatarStatus
 */
export const AvatarStatus = /*@__PURE__*/ proto3.makeEnum(
  "gamification.base.bo.v1.AvatarStatus",
  [
    {no: 0, name: "AVATAR_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AVATAR_STATUS_PENDING", localName: "PENDING"},
    {no: 2, name: "AVATAR_STATUS_PUBLISHED", localName: "PUBLISHED"},
    {no: 3, name: "AVATAR_STATUS_UNPUBLISHED", localName: "UNPUBLISHED"},
  ],
);

/**
 * definition of enumeration for avatar action
 *
 * @generated from enum gamification.base.bo.v1.AvatarAction
 */
export const AvatarAction = /*@__PURE__*/ proto3.makeEnum(
  "gamification.base.bo.v1.AvatarAction",
  [
    {no: 0, name: "AVATAR_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AVATAR_ACTION_DELETE", localName: "DELETE"},
    {no: 2, name: "AVATAR_ACTION_RESTORE", localName: "RESTORE"},
    {no: 3, name: "AVATAR_ACTION_PUBLISH", localName: "PUBLISH"},
    {no: 4, name: "AVATAR_ACTION_UNPUBLISH", localName: "UNPUBLISH"},
  ],
);

/**
 * definition of enumeration for avatar event to acquire avatar
 *
 * @generated from enum gamification.base.bo.v1.AvatarRelatedEvent
 */
export const AvatarRelatedEvent = /*@__PURE__*/ proto3.makeEnum(
  "gamification.base.bo.v1.AvatarRelatedEvent",
  [
    {no: 0, name: "AVATAR_RELATED_EVENT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AVATAR_RELATED_EVENT_ADVENT_CALENDAR_PROMO_NY_2024", localName: "ADVENT_CALENDAR_PROMO_NY_2024"},
  ],
);

