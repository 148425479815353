import type { Provider } from '@angular/core';
import { inject, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { Observable } from 'rxjs';

import { provideFactory } from '@pu/sdk';

import type { StrategyFactory } from '../strategy.model';
import type { RouteParams } from './route-params-strategy.model';

const routeParamsPersistenceFactory = (): Observable<RouteParams> =>
  inject(ActivatedRoute).params;

/**
 * @deprecated Will be removed after filters refactoring
 */
export const routeParamsStrategyFactory: StrategyFactory =
  routeParamsPersistenceFactory;

export const ROUTE_PARAMS_PERSISTENCE = new InjectionToken<
  Observable<RouteParams>
>('ROUTE_PARAMS_PERSISTENCE', { factory: () => inject(ActivatedRoute).params });

export const provideRouteParamsPersistence = (): Provider[] => [
  provideFactory<Observable<RouteParams>>(
    ROUTE_PARAMS_PERSISTENCE,
    routeParamsPersistenceFactory,
  ),
];
