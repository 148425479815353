import { identity, isNil } from 'lodash-es';

import {
  StringMultiselect,
  StringSearch,
  StringsSearch,
  Uint64Multiselect,
} from '@pu/grpc';

type StringParam = string | null | undefined;

export function stringToBoolean(value: StringParam): boolean {
  return !isNil(value);
}

// TODO: refactor and remove after
export function stringToBooleanOrNull(value: StringParam): boolean | null {
  return isNil(value) ? null : !isNil(value);
}

export function booleanStringToBoolean(
  value: StringParam,
): boolean | undefined {
  if (!value) {
    return;
  }
  return value === 'true';
}

export function stringToBigInt(value: StringParam): bigint {
  return isNil(value) ? 0n : BigInt(value);
}

// TODO: refactor and remove after
export function stringToBigIntOrUndefined(
  value: StringParam,
): bigint | undefined {
  return value ? BigInt(value) : undefined;
}

export function toBigintArray(value: StringParam): bigint[] {
  return isNil(value) ? [] : [BigInt(value)];
}

export function toStringArray(
  value: StringParam,
  delimiter = /[ ,]+/gm,
): string[] {
  return value?.split(delimiter) ?? [];
}

export function parseStringToArray<T>(
  value: StringParam,
  fn: (param: string) => T = identity,
): T[] {
  return value?.split(',').map((item) => fn(item)) ?? [];
}

export function assertTypeFromString<T>(value: string): T {
  return value as T;
}

export function toStringsSearch(
  values: string[],
  exactMatch: boolean,
): StringsSearch {
  return new StringsSearch({ values, exactMatch });
}

export function toStringSearch(
  value: string,
  exactMatch: boolean,
): StringSearch {
  return new StringSearch({ value, exactMatch });
}

export function toUint64Multiselect(
  include: string | null | undefined,
  exclude: string | null | undefined,
): Uint64Multiselect {
  const ids = toStringArray(include).map(stringToBigInt);
  const excludedIds = toStringArray(exclude).map(stringToBigInt);

  return new Uint64Multiselect({ ids, excludedIds });
}

export function toStringMultiselect(
  value: string | null | undefined,
): StringMultiselect {
  const ids = toStringArray(value);

  return new StringMultiselect({ ids });
}

export function bigintToDate(value: bigint | undefined): Date | null {
  return value ? new Date(Number(value)) : null;
}
