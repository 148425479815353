// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/product/backoffice_operator/operator/operator_info.proto (package pinup.product.backoffice_operator.operator, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetOperatorByEmailsRequest, GetOperatorByEmailsResponse, GetOperatorByIdsRequest, GetOperatorByIdsResponse, GetOperatorBySubsRequest, GetOperatorBySubsResponse } from "./operator_info_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.product.backoffice_operator.operator.OperatorInfoService
 */
@Injectable({ providedIn: "root" })
export class OperatorInfoRemoteService {
  public static readonly typeName = "pinup.product.backoffice_operator.operator.OperatorInfoService";

  public static readonly methods = {
    getOperatorByIds: {
      name: "GetOperatorByIds",
      I: GetOperatorByIdsRequest,
      O: GetOperatorByIdsResponse,
      kind: MethodKind.Unary,
    },
    getOperatorBySubs: {
      name: "GetOperatorBySubs",
      I: GetOperatorBySubsRequest,
      O: GetOperatorBySubsResponse,
      kind: MethodKind.Unary,
    },
    getOperatorByEmails: {
      name: "GetOperatorByEmails",
      I: GetOperatorByEmailsRequest,
      O: GetOperatorByEmailsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.product.backoffice_operator.operator.OperatorInfoService.GetOperatorByIds
   */
  public getOperatorByIds(
    input: PartialMessage<GetOperatorByIdsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetOperatorByIdsResponse> {
    return unary<GetOperatorByIdsRequest, GetOperatorByIdsResponse>(
      this.transport,
      OperatorInfoRemoteService,
      OperatorInfoRemoteService.methods.getOperatorByIds,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.product.backoffice_operator.operator.OperatorInfoService.GetOperatorBySubs
   */
  public getOperatorBySubs(
    input: PartialMessage<GetOperatorBySubsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetOperatorBySubsResponse> {
    return unary<GetOperatorBySubsRequest, GetOperatorBySubsResponse>(
      this.transport,
      OperatorInfoRemoteService,
      OperatorInfoRemoteService.methods.getOperatorBySubs,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.product.backoffice_operator.operator.OperatorInfoService.GetOperatorByEmails
   */
  public getOperatorByEmails(
    input: PartialMessage<GetOperatorByEmailsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetOperatorByEmailsResponse> {
    return unary<GetOperatorByEmailsRequest, GetOperatorByEmailsResponse>(
      this.transport,
      OperatorInfoRemoteService,
      OperatorInfoRemoteService.methods.getOperatorByEmails,
      input,
      options,
    );
  }
}
