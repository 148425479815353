import { APP_BASE_HREF } from '@angular/common';
import type { WritableSignal } from '@angular/core';
import { booleanAttribute, inject, InjectionToken } from '@angular/core';

import { localStorageSignal } from '@pu/sdk';

export const SIDEBAR_COLLAPSED = new InjectionToken<WritableSignal<boolean>>(
  'SIDEBAR_COLLAPSED',
  {
    providedIn: 'root',
    factory: () =>
      localStorageSignal<boolean>(
        `${inject(APP_BASE_HREF)}layoutCollapsed`,
        booleanAttribute,
        JSON.stringify,
        false,
      ),
  },
);
