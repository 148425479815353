// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/health/api_gw.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.health, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { HealthRequest, HealthResponse, LivenessRequest, LivenessResponse } from "./api_gw_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthService
 */
@Injectable({ providedIn: "root" })
export class HealthRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthService";

  public static readonly methods = {
    liveness: {
      name: "Liveness",
      I: LivenessRequest,
      O: LivenessResponse,
      kind: MethodKind.Unary,
    },
    health: {
      name: "Health",
      I: HealthRequest,
      O: HealthResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * Health is a k8s health check endpoint: does not depend on external services.
   *
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthService.Liveness
   */
  public liveness(
    input: PartialMessage<LivenessRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<LivenessResponse> {
    return unary<LivenessRequest, LivenessResponse>(
      this.transport,
      HealthRemoteService,
      HealthRemoteService.methods.liveness,
      input,
      options,
    );
  }

  /**
   * Health is a k8s health check endpoint: depends on external services.
   *
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthService.Health
   */
  public health(
    input: PartialMessage<HealthRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<HealthResponse> {
    return unary<HealthRequest, HealthResponse>(
      this.transport,
      HealthRemoteService,
      HealthRemoteService.methods.health,
      input,
      options,
    );
  }
}
