// @generated by protoc-gen-es v1.10.0
// @generated from file base/bo/v1/task_enum.proto (package gamification.achievement_manager.base.bo.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Comparison operator
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.ComparisonOperator
 */
export const ComparisonOperator = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.ComparisonOperator",
  [
    {no: 0, name: "COMPARISON_OPERATOR_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMPARISON_OPERATOR_EQ", localName: "EQ"},
    {no: 2, name: "COMPARISON_OPERATOR_NOT", localName: "NOT"},
    {no: 3, name: "COMPARISON_OPERATOR_GT", localName: "GT"},
    {no: 4, name: "COMPARISON_OPERATOR_GTE", localName: "GTE"},
    {no: 5, name: "COMPARISON_OPERATOR_LT", localName: "LT"},
    {no: 6, name: "COMPARISON_OPERATOR_LTE", localName: "LTE"},
  ],
);

/**
 * Enum representing the type of the event value used in condition comparison.
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.EventValueType
 */
export const EventValueType = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.EventValueType",
  [
    {no: 0, name: "EVENT_VALUE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "EVENT_VALUE_TYPE_BOOLEAN", localName: "BOOLEAN"},
    {no: 2, name: "EVENT_VALUE_TYPE_INTEGER", localName: "INTEGER"},
    {no: 3, name: "EVENT_VALUE_TYPE_FLOAT", localName: "FLOAT"},
    {no: 4, name: "EVENT_VALUE_TYPE_STRING", localName: "STRING"},
    {no: 5, name: "EVENT_VALUE_TYPE_DATETIME", localName: "DATETIME"},
  ],
);

