import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import type { Observable } from 'rxjs';

import { downloadIcon, SvgComponent } from '@pu/sdk';

import { ACCESS_PAYLOAD } from '../auth/access-payload';
import { AuthService } from '../auth/auth.service';
import { injectCurrentUserId } from '../auth/inject-current-user-id';
import type { PinupAccessJwtPayload } from '../auth/parse-pinup-jwt';
import { SIDEBAR_COLLAPSED } from '../layouts/sidebar-collapsed';

@Component({
  selector: 'bo-user-bar',
  standalone: true,
  imports: [NgOptimizedImage, RouterLink, SvgComponent, AsyncPipe],
  templateUrl: './user-bar.component.html',
  styleUrl: './user-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBarComponent {
  private readonly auth = inject(AuthService);
  protected readonly accessPayload$ =
    inject<Observable<PinupAccessJwtPayload>>(ACCESS_PAYLOAD);
  protected readonly collapsed = inject(SIDEBAR_COLLAPSED);
  private readonly destroyRef = inject(DestroyRef);
  protected currentUserId = injectCurrentUserId();

  protected readonly downloadIcon = downloadIcon;

  protected logout(): void {
    this.auth.logout$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
