import type { Transaction } from '@pinup-grpc/pinup/stat/report/casino/casino_pb';

import { TransactionBetType } from '@pu/grpc';
import type { TransactionStatus } from '@bo/common';
import {
  casinoErrorIcon,
  casinoLoseIcon,
  casinoRollback,
  casinoWinIcon,
  casinoWriteBetIcon,
} from '@bo/common';

enum TransactionType {
  ERROR = 0,
  BET = 1,
  WIN = 2,
  LOSE = 3,
  ROLLBACK = 4,
}

export type TransactionBetStatusConfig = Readonly<{
  tooltip: string;
  class?: string;
}> &
  TransactionStatus;

const statusMap: Record<
  Partial<TransactionType>,
  TransactionBetStatusConfig
> = {
  [TransactionType.ERROR]: {
    icon: casinoErrorIcon,
    label: 'Casino write bet',
    tooltip: 'Error\nInsufficient funds to place bet',
  },
  [TransactionType.BET]: {
    icon: casinoWriteBetIcon,
    label: 'Casino bet',
    tooltip: 'Bet amount',
  },
  [TransactionBetType.WIN]: {
    icon: casinoWinIcon,
    label: 'Casino win',
    class: 'success-color',
    tooltip: 'Win amount',
  },
  [TransactionBetType.LOSE]: {
    icon: casinoLoseIcon,
    label: 'Casino lose',
    class: 'danger-color',
    tooltip: 'Lose amount',
  },
  [TransactionBetType.ROLLBACK]: {
    icon: casinoRollback,
    label: 'Casino rollback',
    class: 'label-color',
    tooltip: 'Rollback amount',
  },
};

export function getCasinoStatus(
  transaction: Transaction,
): TransactionBetStatusConfig {
  const { finance, isRollback, error } = transaction;

  if (error) {
    return statusMap[TransactionType.ERROR];
  }

  if (isRollback) {
    return statusMap[TransactionType.ROLLBACK];
  }

  if (finance.balanceAfter < finance.balanceBefore) {
    return statusMap[TransactionType.LOSE];
  }

  if (finance.balanceAfter > finance.balanceBefore) {
    return statusMap[TransactionType.WIN];
  }

  return statusMap[TransactionType.BET];
}
