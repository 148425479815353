import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: '[bo-pill-toggle-bar]',
  standalone: true,
  template: `
    <ng-content />
  `,
  styleUrl: './pill-toggle-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class PillToggleBarComponent {}
