import type { Sort } from '@angular/material/sort';

import type { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_PlayerSession as PlayerSession } from '@pu/grpc/messages';
import { compareAscTimestamp } from '@bo/common';

import { ProfileSessionsColumns } from './profile-sessions.const';

export function sortSessions(
  sessions: readonly PlayerSession[],
  sort: Sort,
): PlayerSession[] {
  const pureData = sessions?.slice();

  if (sort.active === '' || sort.direction === '') {
    return pureData;
  }

  switch (sort.active) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    case ProfileSessionsColumns.DATE:
      pureData.sort((a, b) => compareAscTimestamp(a.createdAt, b.createdAt));
      break;
    // TODO other cols asc sort
  }

  if (sort.direction === 'desc') {
    return pureData.reverse();
  }

  return pureData;
}
