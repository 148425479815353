// @generated by protoc-gen-es v1.10.0
// @generated from file service/money_out_search.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { MoneyOutDto } from "../models/moneyout/moneyout_pb.js";

/**
 * @generated from message payments.ListMoneyOutByIdsRequest
 */
export const ListMoneyOutByIdsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.ListMoneyOutByIdsRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message payments.ListMoneyOutByIdsResponse
 */
export const ListMoneyOutByIdsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.ListMoneyOutByIdsResponse",
  () => [
    { no: 1, name: "outs", kind: "message", T: MoneyOutDto, repeated: true },
  ],
);

/**
 * @generated from message payments.ListMoneyOutNotMatchVersionRequest
 */
export const ListMoneyOutNotMatchVersionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.ListMoneyOutNotMatchVersionRequest",
  () => [
    { no: 1, name: "ids", kind: "message", T: ListMoneyOutNotMatchVersionRequest_Id, repeated: true },
  ],
);

/**
 * @generated from message payments.ListMoneyOutNotMatchVersionRequest.Id
 */
export const ListMoneyOutNotMatchVersionRequest_Id = /*@__PURE__*/ proto3.makeMessageType(
  "payments.ListMoneyOutNotMatchVersionRequest.Id",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "ListMoneyOutNotMatchVersionRequest_Id"},
);

/**
 * @generated from message payments.ListMoneyOutNotMatchVersionResponse
 */
export const ListMoneyOutNotMatchVersionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.ListMoneyOutNotMatchVersionResponse",
  () => [
    { no: 1, name: "outs", kind: "message", T: MoneyOutDto, repeated: true },
  ],
);

