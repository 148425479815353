/**
 * @deprecated This function is used as a stub to enable
 * {@linkcode https://www.typescriptlang.org/tsconfig#strictNullChecks|strictNullCheck}.
 * Don't use it in new code.
 */
export function _n<T>(value: T | null | undefined): T {
  // console.assert(value == null, 'value is nil'); // USE to find false nil value
  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value;
}
