// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/players_notes_logs/player_notes_logs.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CreatePlayerNoteRequest, CreatePlayerNoteResponse, EditPlayerNoteRequest, EditPlayerNoteResponse, FindPlayerLogsRequest, FindPlayerLogsResponse, FindPlayerNotesRequest, FindPlayerNotesResponse, GetPlayerNotesByIdRequest, GetPlayerNotesByIdResponse, TriggerPlayerNotePinRequest, TriggerPlayerNotePinResponse } from "./player_notes_logs_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService
 */
@Injectable({ providedIn: "root" })
export class PlayerNotesLogsRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService";

  public static readonly methods = {
    createPlayerNote: {
      name: "CreatePlayerNote",
      I: CreatePlayerNoteRequest,
      O: CreatePlayerNoteResponse,
      kind: MethodKind.Unary,
    },
    editPlayerNote: {
      name: "EditPlayerNote",
      I: EditPlayerNoteRequest,
      O: EditPlayerNoteResponse,
      kind: MethodKind.Unary,
    },
    triggerPlayerNotePin: {
      name: "TriggerPlayerNotePin",
      I: TriggerPlayerNotePinRequest,
      O: TriggerPlayerNotePinResponse,
      kind: MethodKind.Unary,
    },
    getPlayerNotesById: {
      name: "GetPlayerNotesById",
      I: GetPlayerNotesByIdRequest,
      O: GetPlayerNotesByIdResponse,
      kind: MethodKind.Unary,
    },
    findPlayerNotes: {
      name: "FindPlayerNotes",
      I: FindPlayerNotesRequest,
      O: FindPlayerNotesResponse,
      kind: MethodKind.Unary,
    },
    findPlayerLogs: {
      name: "FindPlayerLogs",
      I: FindPlayerLogsRequest,
      O: FindPlayerLogsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService.CreatePlayerNote
   */
  public createPlayerNote(
    input: PartialMessage<CreatePlayerNoteRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreatePlayerNoteResponse> {
    return unary<CreatePlayerNoteRequest, CreatePlayerNoteResponse>(
      this.transport,
      PlayerNotesLogsRemoteService,
      PlayerNotesLogsRemoteService.methods.createPlayerNote,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService.EditPlayerNote
   */
  public editPlayerNote(
    input: PartialMessage<EditPlayerNoteRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<EditPlayerNoteResponse> {
    return unary<EditPlayerNoteRequest, EditPlayerNoteResponse>(
      this.transport,
      PlayerNotesLogsRemoteService,
      PlayerNotesLogsRemoteService.methods.editPlayerNote,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService.TriggerPlayerNotePin
   */
  public triggerPlayerNotePin(
    input: PartialMessage<TriggerPlayerNotePinRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<TriggerPlayerNotePinResponse> {
    return unary<TriggerPlayerNotePinRequest, TriggerPlayerNotePinResponse>(
      this.transport,
      PlayerNotesLogsRemoteService,
      PlayerNotesLogsRemoteService.methods.triggerPlayerNotePin,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService.GetPlayerNotesById
   */
  public getPlayerNotesById(
    input: PartialMessage<GetPlayerNotesByIdRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerNotesByIdResponse> {
    return unary<GetPlayerNotesByIdRequest, GetPlayerNotesByIdResponse>(
      this.transport,
      PlayerNotesLogsRemoteService,
      PlayerNotesLogsRemoteService.methods.getPlayerNotesById,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService.FindPlayerNotes
   */
  public findPlayerNotes(
    input: PartialMessage<FindPlayerNotesRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindPlayerNotesResponse> {
    return unary<FindPlayerNotesRequest, FindPlayerNotesResponse>(
      this.transport,
      PlayerNotesLogsRemoteService,
      PlayerNotesLogsRemoteService.methods.findPlayerNotes,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNotesLogsService.FindPlayerLogs
   */
  public findPlayerLogs(
    input: PartialMessage<FindPlayerLogsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindPlayerLogsResponse> {
    return unary<FindPlayerLogsRequest, FindPlayerLogsResponse>(
      this.transport,
      PlayerNotesLogsRemoteService,
      PlayerNotesLogsRemoteService.methods.findPlayerLogs,
      input,
      options,
    );
  }
}
