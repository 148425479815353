// @generated by protoc-gen-es v1.10.0
// @generated from file proto/transaction.proto (package billing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Project, Type } from "./enum_pb.js";

/**
 * @generated from message billing.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "billing.Transaction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bsonId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "depositAllSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "pincoinBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "pincoinsAllSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "transactionType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "transactionId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "transactionId_bson", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "date", kind: "message", T: Timestamp },
    { no: 13, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "currency", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 16, name: "change", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "pincoinChange", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "revert", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message billing.TransactionV2
 */
export const TransactionV2 = /*@__PURE__*/ proto3.makeMessageType(
  "billing.TransactionV2",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "createdAt", kind: "message", T: Timestamp },
    { no: 3, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "project", kind: "enum", T: proto3.getEnumType(Project) },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(Type) },
    { no: 6, name: "currency", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "change", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "pincoinChange", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "pincoinBalance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "transactionId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "revertId", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

