import type { Finance } from '@pinup-grpc/pinup/stat/report/billing/billing_pb';

import type { ExtractPBMessagePayload } from '@bo/common';

export type TransactionsFinance = ExtractPBMessagePayload<Finance> &
  Readonly<{
    balanceBefore: bigint;
  }>;

export function prepareFinance(finance: Finance): TransactionsFinance {
  return {
    ...finance,
    balanceBefore: finance.balance - finance.amount,
  };
}
