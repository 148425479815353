<mat-form-field class="form-field">
  <mat-label>
    <ng-content select="[label]" />
  </mat-label>
  <mat-chip-grid
    (ngModelChange)="onSelectedValues($event)"
    [controlMultiSelectEqual]="structure().equal()"
    [ngModel]="selectedValues()"
    controlMultiSelect
    #chipGrid
    #multiSelect="controlMultiSelect"
  >
    @for (context of selectedEntries(); track context.value) {
      <mat-chip-row
        (removed)="multiSelect.toggle(context.value)"
        [value]="context.value"
      >
        <div class="chip-text-container">
          <ng-container
            [ngTemplateOutlet]="structure().template"
            [ngTemplateOutletContext]="context"
          />
        </div>
        <button matChipRemove type="button">
          <bo-svg [unsafeSvgSource]="closeIcon" />
        </button>
      </mat-chip-row>
    }
    <input
      class="input-autocomplete-inline"
      (ngModelChange)="onSearch($event)"
      [matAutocomplete]="multi"
      [matChipInputFor]="chipGrid"
      [ngModel]="search()"
      #multiSearchInput="matChipInputFor"
    />
  </mat-chip-grid>
  <mat-autocomplete
    (optionSelected)="
      multiSearchInput.clear();
      search.set('');
      multiSelect.toggle($event.option.value)
    "
    autoActiveFirstOption
    hideSingleSelectionIndicator
    #multi="matAutocomplete"
  >
    @for (
      entry of availableEntries()
        | autocompleteWithMultipleHighlights
          : structure().highlight()
          : search();
      track entry.value
    ) {
      <mat-option [value]="entry.value">
        <mat-pseudo-checkbox
          [state]="
            (selectedValues() | includes: entry.value : structure().equal())
              ? 'checked'
              : 'unchecked'
          "
        />
        <bo-multi-highlight-match [highlight]="entry.highlighted" />
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
