import { AccountVerifyState } from '@pinup-grpc/services/bo/players/players_pb';
import {
  WithdrawalConfirmType,
  WithdrawalStatus,
} from '@pinup-grpc/services/bo/withdrawals/withdrawals_pb';

import { WithdrawalReason } from '@pu/grpc';
import type { RadioToggleOption } from '@bo/common';

import type { RiskDurationConfig } from '../withdrawals-filter.types';

export const withdrawalConfirmTypeOptions: ReadonlyMap<
  WithdrawalConfirmType,
  string
> = new Map<WithdrawalConfirmType, string>([
  [WithdrawalConfirmType.AUTO, 'AUTO'],
  [WithdrawalConfirmType.MANUAL, 'MANUAL'],
  [
    WithdrawalConfirmType.MANUAL_CHANGED_AMOUNT,
    'Manual with changed withdrawal',
  ],
]);

export const withdrawalStatusOptions: ReadonlyMap<WithdrawalStatus, string> =
  new Map<WithdrawalStatus, string>([
    [WithdrawalStatus.PROCESSING, 'PROCESSING'],
    [WithdrawalStatus.SUCCESSFUL, 'SUCCESSFUL'],
    [WithdrawalStatus.CANCELED, 'CANCELED'],
    [WithdrawalStatus.CANCELED_USER, 'CANCELED USER'],
    [WithdrawalStatus.AV_PROCESSING, 'AV PROCESSING'],
    [WithdrawalStatus.RISK_REVIEWING, 'RISK REVIEWING'],
  ]);

export const withdrawalStatusMap: Record<string, WithdrawalStatus> = {
  [WithdrawalStatus.PROCESSING]: WithdrawalStatus.PROCESSING,
  [WithdrawalStatus.SUCCESSFUL]: WithdrawalStatus.SUCCESSFUL,
  [WithdrawalStatus.CANCELED]: WithdrawalStatus.CANCELED,
  [WithdrawalStatus.CANCELED_USER]: WithdrawalStatus.CANCELED_USER,
  [WithdrawalStatus.AV_PROCESSING]: WithdrawalStatus.AV_PROCESSING,
  [WithdrawalStatus.RISK_REVIEWING]: WithdrawalStatus.RISK_REVIEWING,
};

export const withdrawalReasonOptions: ReadonlyMap<WithdrawalReason, string> =
  new Map<WithdrawalReason, string>([
    [WithdrawalReason.UNSPECIFIED, 'Other'],
    [
      WithdrawalReason.LAST_DEPOSIT_TURNOVER,
      'Reason: /No turnover of last deposit',
    ],
    [WithdrawalReason.TURNOVER, 'Reason: /Turnover'],
    [WithdrawalReason.LIMIT, 'Reason: /Limit'],
    [WithdrawalReason.BET_COUNT_CASINO, 'Reason: /BetCountCasino'],
    [
      WithdrawalReason.NO_BET_SINCE_LAST_DEPOSIT,
      'Reason: /NoBets Since last deposit',
    ],
    [
      WithdrawalReason.AUTO_OFF_ALL_IN_FIRST_DEP,
      'Reason: /AWOFF:All-in first deposit',
    ],
    [WithdrawalReason.AUTO_OFF_FAST, 'Reason: /AWOFF:Fast MoneyOut'],
    [
      WithdrawalReason.NOT_SAME_SYSTEM_NAME,
      'Reason: /Limit/No Same System Name found',
    ],
    [WithdrawalReason.BLOCK_CASINO_STATUS, 'Reason: /BlockStatusCasino CZ'],
    [WithdrawalReason.BLOCK_SPORT_STATUS, 'Reason: /BlockStatusBet B2,B3,BZ'],
  ]);

export const withdrawalRiskDurationConstants = {
  ONE_HOUR_SECONDS: 3600n,
  SIX_HOUR_SECONDS: 21600n,
  TWELVE_HOUR_SECONDS: 43200n,
  ONE_DAY_SECONDS: 86400n,
} as const;

export const withdrawalRiskDurationConfig: RiskDurationConfig[] = [
  {
    value: withdrawalRiskDurationConstants.ONE_HOUR_SECONDS,
    label: '> 1H',
  },
  {
    value: withdrawalRiskDurationConstants.SIX_HOUR_SECONDS,
    label: '> 6H',
  },
  {
    value: withdrawalRiskDurationConstants.TWELVE_HOUR_SECONDS,
    label: '> 12H',
  },
  {
    value: withdrawalRiskDurationConstants.ONE_DAY_SECONDS,
    label: '> 24H',
  },
];

export const verifiesStatusOptions: RadioToggleOption<
  string,
  AccountVerifyState
>[] = [
  { name: 'True', value: AccountVerifyState.ACCOUNT_VERIFY_YES },
  { name: 'False', value: AccountVerifyState.ACCOUNT_VERIFY_NO },
];
