import { bigintToDate, NonNullableFormControl } from '@pu/sdk';
import { FiltersForm } from '@bo/common';

import type {
  FinanceDepositsFilter,
  ListDepositsFilter,
} from '../deposits-filter.types';

export type FinanceFilters = Pick<
  FinanceDepositsFilter,
  | 'amountCurrency'
  | 'amountFrom'
  | 'amountTo'
  | 'balanceBeforeFrom'
  | 'balanceBeforeTo'
  | 'balanceCurrency'
  | 'balanceFrom'
  | 'balanceTo'
  | 'createdFrom'
  | 'createdTo'
  | 'externalIds'
  | 'isChargeback'
  | 'isFirstDeposit'
  | 'isFistHourDeposit'
  | 'methodExclude'
  | 'methodInclude'
  | 'updatedFrom'
  | 'updatedTo'
>;

export class DepositsFilterFinanceForm extends FiltersForm<FinanceFilters> {
  constructor(data?: ListDepositsFilter) {
    super({
      //TODO: exist in design, absent on backend

      // merchantPaymentId:
      // merchant:
      // externalTnx:
      // chargeBack:
      // durationToCompletedRange - time difference of accrual?
      // depositNumberSuccessful
      // depositNumberFailed
      // percentFailedToSuccessful
      // percentFromPreviousDeposit
      // direction
      // transactionStatus
      // transactionComments
      // geoBinCard

      externalIds: new NonNullableFormControl(data?.deposit.externalIds ?? []),
      methodExclude: new NonNullableFormControl(
        data?.deposit.paymentMethod.excludedIds ?? [],
      ),
      methodInclude: new NonNullableFormControl(
        data?.deposit.paymentMethod.ids ?? [],
      ),
      amountCurrency: new NonNullableFormControl(
        data?.deposit.amount.currency ?? null,
      ),
      amountFrom: new NonNullableFormControl(data?.deposit.amount.from),
      amountTo: new NonNullableFormControl(data?.deposit.amount.to),
      createdFrom: new NonNullableFormControl(
        bigintToDate(data?.deposit.createdAt.from?.seconds),
      ),
      createdTo: new NonNullableFormControl(
        bigintToDate(data?.deposit.createdAt.to?.seconds),
      ),
      updatedFrom: new NonNullableFormControl(
        bigintToDate(data?.deposit.updatedAt.from?.seconds),
      ),
      updatedTo: new NonNullableFormControl(
        bigintToDate(data?.deposit.updatedAt.to?.seconds),
      ),
      // @ts-expect-error for design accept
      isFirstDeposit: new NonNullableFormControl(
        data?.deposit.isFirstDeposit ?? null,
      ),
      // @ts-expect-error for design accept
      isFistHourDeposit: new NonNullableFormControl(
        data?.deposit.isFistHourDeposit ?? null,
      ),
      balanceCurrency: new NonNullableFormControl(
        data?.profile.balance.currency ?? null,
      ),
      balanceFrom: new NonNullableFormControl(data?.profile.balance.from),
      balanceTo: new NonNullableFormControl(data?.profile.balance.to),
      balanceBeforeFrom: new NonNullableFormControl(
        data?.profile.balanceBefore.from,
      ),
      balanceBeforeTo: new NonNullableFormControl(
        data?.profile.balanceBefore.to,
      ),
      // TODO: find a way to avoid error
      // @ts-expect-error for design accept
      isChargeback: new NonNullableFormControl(
        data?.deposit.isChargeback ?? null,
      ),
    });
  }
}
