import type { EnvironmentProviders } from '@angular/core';
import { InjectionToken, makeEnvironmentProviders } from '@angular/core';

import { provideValue } from '@pu/sdk';

export const COMMUNICATION_HUB_URL = new InjectionToken<string>(
  'COMMUNICATION_HUB_URL',
);

export const provideDevCommunicationHubUrl = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideValue<string>(
      COMMUNICATION_HUB_URL,
      'https://communications-hub-dev.com',
    ),
  ]);

export const provideProdCommunicationHubUrl = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideValue<string>(
      COMMUNICATION_HUB_URL,
      'https://communications-hub.com',
    ),
  ]);
