import { inject, Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  NavigationExtras,
  Params,
  UrlSegment,
} from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { omit } from 'lodash-es';

/**
 * Facade service for generalizing common navigation scenarios relatively to the current route
 * Note: to work properly with navigation relatively to the current route
 * the service should be provided at component's level to have access
 * to a correct activated route
 */
@Injectable()
export class CurrentRouteNavigationService {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  public navigateAndSetParams(
    path: string[] | string,
    params: Params,
    replaceParams = false,
    options: NavigationExtras = {},
  ): void {
    const extras: NavigationExtras = {
      relativeTo: this.route,
      ...options,
    };
    const normalizedPath = typeof path === 'string' ? [path] : path;

    const actualParams = replaceParams
      ? params
      : { ...this.clearParams(this.route.snapshot.params), ...params };

    const commands = [...normalizedPath, actualParams, ...this.getChildPath()];
    void this.router.navigate(commands, extras);
  }

  public navigateAndSetQueryParams(
    path: string[] | string,
    queryParams: Params,
    replaceQueryParams = false,
  ): void {
    const extras: NavigationExtras = { relativeTo: this.route, queryParams };
    const normalizedPaths = typeof path === 'string' ? [path] : path;

    if (!replaceQueryParams) {
      extras.queryParamsHandling = 'merge';
    }

    void this.router.navigate(normalizedPaths, extras);
  }

  public setParams(
    params: Params,
    replace = false,
    options: NavigationExtras = {},
  ): void {
    this.navigateAndSetParams('.', params, replace, options);
  }

  public setQueryParams(queryParams: Params, replace = false): void {
    this.navigateAndSetQueryParams('.', queryParams, replace);
  }

  private clearParams(params: Params): Params {
    return this.omitParentParams(this.omitDefaultParams(params));
  }

  private omitDefaultParams(actualParams: Params): Params {
    const parts = this.route.snapshot.routeConfig?.path?.split('/') ?? [];
    const defaultParamsNames = parts
      .filter((part) => part.startsWith(':'))
      .map((part) => part.slice(1));

    return omit(actualParams, defaultParamsNames);
  }

  private omitParentParams(selfParams: Params): Params {
    const parentParamNames = [];
    let route = this.route.snapshot;
    while (route?.parent) {
      if (route.routeConfig?.path) {
        const keys = route.parent.paramMap.keys;
        parentParamNames.push(...keys);
      }

      route = route.parent;
    }

    return omit(selfParams, parentParamNames);
  }

  private getChildPath(): (Params | string)[] {
    const segments: UrlSegment[] = [];

    let childRoute: ActivatedRouteSnapshot | null =
      this.route.snapshot.firstChild;
    while (childRoute) {
      segments.push(...childRoute.url);
      childRoute = childRoute.firstChild;
    }

    return segments.flatMap((segment) => [segment.path, segment.parameters]);
  }
}
