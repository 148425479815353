// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/withdrawals/dashboard/withdrawals_dashboard.proto (package stat.bo.withdrawals.dashboard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, proto3 } from "@bufbuild/protobuf";
import { TimeRange } from "../../../../base/filter_pb.js";
import { Sorting } from "../../../../base/sorting_pb.js";
import { WithdrawalDeclineReason, WithdrawalReason } from "../withdrawals_pb.js";

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetCountersRequest
 */
export const GetCountersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetCountersRequest",
  () => [
    { no: 1, name: "createdAt", kind: "message", T: TimeRange },
    { no: 2, name: "isVip", kind: "message", T: BoolValue },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.WithdrawalCounters
 */
export const WithdrawalCounters = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.WithdrawalCounters",
  () => [
    { no: 1, name: "processCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "pendingCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "autoApprovedCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "autoDeclinedCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "manualApprovedCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "manualDeclinedCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "manualApproveDuration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "manualDeclineDuration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "pending24hCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "playerDeclineCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetCountersResponse
 */
export const GetCountersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetCountersResponse",
  () => [
    { no: 1, name: "result", kind: "message", T: WithdrawalCounters },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetCountersByGeoRequest
 */
export const GetCountersByGeoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetCountersByGeoRequest",
  () => [
    { no: 1, name: "createdAt", kind: "message", T: TimeRange },
    { no: 2, name: "isVip", kind: "message", T: BoolValue },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetCountersByGeoResponse
 */
export const GetCountersByGeoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetCountersByGeoResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: GetCountersByGeoResponse_GeoWithdrawalCounters, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetCountersByGeoResponse.GeoWithdrawalCounters
 */
export const GetCountersByGeoResponse_GeoWithdrawalCounters = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetCountersByGeoResponse.GeoWithdrawalCounters",
  () => [
    { no: 1, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "counters", kind: "message", T: WithdrawalCounters },
  ],
  {localName: "GetCountersByGeoResponse_GeoWithdrawalCounters"},
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetReasonCountersByGeoRequest
 */
export const GetReasonCountersByGeoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetReasonCountersByGeoRequest",
  () => [
    { no: 1, name: "createdAt", kind: "message", T: TimeRange },
    { no: 2, name: "reasons", kind: "enum", T: proto3.getEnumType(WithdrawalReason), repeated: true },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "isVip", kind: "message", T: BoolValue },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetReasonCountersByGeoResponse
 */
export const GetReasonCountersByGeoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetReasonCountersByGeoResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: GetReasonCountersByGeoResponse_GeoResult, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetReasonCountersByGeoResponse.Counter
 */
export const GetReasonCountersByGeoResponse_Counter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetReasonCountersByGeoResponse.Counter",
  () => [
    { no: 1, name: "reason", kind: "enum", T: proto3.getEnumType(WithdrawalReason) },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "GetReasonCountersByGeoResponse_Counter"},
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetReasonCountersByGeoResponse.GeoResult
 */
export const GetReasonCountersByGeoResponse_GeoResult = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetReasonCountersByGeoResponse.GeoResult",
  () => [
    { no: 1, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "counters", kind: "message", T: GetReasonCountersByGeoResponse_Counter, repeated: true },
    { no: 3, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "GetReasonCountersByGeoResponse_GeoResult"},
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistRequest
 */
export const GetDeclineReasonCountersBySpecialistRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistRequest",
  () => [
    { no: 1, name: "createdAt", kind: "message", T: TimeRange },
    { no: 2, name: "declineReasons", kind: "enum", T: proto3.getEnumType(WithdrawalDeclineReason), repeated: true },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "isVip", kind: "message", T: BoolValue },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistResponse
 */
export const GetDeclineReasonCountersBySpecialistResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: GetDeclineReasonCountersBySpecialistResponse_SpecialistResult, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistResponse.Counter
 */
export const GetDeclineReasonCountersBySpecialistResponse_Counter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistResponse.Counter",
  () => [
    { no: 1, name: "declineReason", kind: "enum", T: proto3.getEnumType(WithdrawalDeclineReason) },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "GetDeclineReasonCountersBySpecialistResponse_Counter"},
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistResponse.SpecialistResult
 */
export const GetDeclineReasonCountersBySpecialistResponse_SpecialistResult = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetDeclineReasonCountersBySpecialistResponse.SpecialistResult",
  () => [
    { no: 1, name: "adminId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "counters", kind: "message", T: GetDeclineReasonCountersBySpecialistResponse_Counter, repeated: true },
    { no: 3, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "GetDeclineReasonCountersBySpecialistResponse_SpecialistResult"},
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetSpecialistsCountersRequest
 */
export const GetSpecialistsCountersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetSpecialistsCountersRequest",
  () => [
    { no: 1, name: "createdAt", kind: "message", T: TimeRange },
    { no: 2, name: "isVip", kind: "message", T: BoolValue },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetSpecialistsCountersResponse
 */
export const GetSpecialistsCountersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetSpecialistsCountersResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: GetSpecialistsCountersResponse_SpecialistResult, repeated: true },
  ],
);

/**
 * @generated from message stat.bo.withdrawals.dashboard.GetSpecialistsCountersResponse.SpecialistResult
 */
export const GetSpecialistsCountersResponse_SpecialistResult = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.withdrawals.dashboard.GetSpecialistsCountersResponse.SpecialistResult",
  () => [
    { no: 1, name: "adminId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "declinedCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "sentToRiskCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "withCommissionCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "withoutCommissionCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "approveDuration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "approveCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "declineDuration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "GetSpecialistsCountersResponse_SpecialistResult"},
);

