import type { EnumDefinition } from '@pu/sdk';
import { enumToKey, extractEnumValues } from '@pu/sdk';

export interface StatusInfo {
  label: string;
  className: string | null;
}

export type StatusInfoEntry<T extends number | string> = readonly [
  item: T,
  className: string | null,
  label?: string,
];

export function generateStatusInfoMap<
  K extends string,
  V extends number | string,
>(
  enumDef: EnumDefinition<K, V>,
  dictionary: readonly StatusInfoEntry<NoInfer<V>>[],
): Map<V, StatusInfo> {
  const result = new Map<V, StatusInfo>();

  for (const [item, className, label] of dictionary) {
    result.set(item, { label: label ?? enumToKey(enumDef, item), className });
  }

  return result;
}

/* TODO create an approach to config generateStatusInfoMap based on this function.
 *  For example: export const casinoStatusesMap =
 *  generateStatusInfoMap(CasinoStatus) is equal to generateStatusInfoEntries(DepositStatus) */
export function generateStatusInfoEntries<
  K extends string,
  V extends number | string,
>(enumDef: EnumDefinition<K, V>): StatusInfoEntry<V>[] {
  return Array.from(extractEnumValues(enumDef), (item) => [item, null]);
}
