import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { SuspenseDirective } from '../suspense.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'suspense-area, [suspense-area]',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './suspense-area.component.html',
  styleUrl: './suspense-area.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.loading]': 'loading()',
  },
})
export class SuspenseAreaComponent {
  protected readonly loading = inject(SuspenseDirective).loading;
}
