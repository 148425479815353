import { DatePipe } from '@angular/common';
import type { Signal } from '@angular/core';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatIconButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addMinutes, isPast } from 'date-fns';
import { isEqual } from 'lodash-es';
import { map, of, switchMap, timer } from 'rxjs';

import type { PlayerNote } from '@pu/grpc';
import { DepartmentType } from '@pu/grpc';
import {
  copyIcon,
  edit2Icon,
  messageSquareIcon,
  pinIcon,
  SvgComponent,
  ToDatePipe,
  unpinIcon,
} from '@pu/sdk';
import { injectCurrentUserId } from '@bo/common';

const MAX_EDIT_MINUTES = 10;

@Component({
  selector: 'bo-note',
  standalone: true,
  imports: [ToDatePipe, DatePipe, SvgComponent, MatIconButton],
  templateUrl: './note.component.html',
  styleUrl: './note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent {
  private readonly snackBar = inject(MatSnackBar);

  public note = input.required<PlayerNote>();
  public pinned = input(false, { transform: booleanAttribute });
  public readonly pinnedToggle = output();
  public readonly edit = output();

  protected edited = computed(
    () => !isEqual(this.note().createdAt, this.note().updatedAt),
  );
  public readonly department = computed(() => {
    const { type } = this.note();

    return this.departments[type];
  });

  protected readonly currentUserId = injectCurrentUserId();
  protected sameUser = computed(() => {
    return this.note().adminId === this.currentUserId();
  });
  protected editTimedOut: Signal<boolean>;

  protected departments = {
    [DepartmentType.UNKNOWN]: { key: 'unknown', label: 'Unknown' },
    [DepartmentType.FINANCE]: { key: 'finance', label: 'Finance' },
    [DepartmentType.SUPPORT]: { key: 'support', label: 'Support' },
    [DepartmentType.RISK]: { key: 'risk', label: 'Risk' },
  };
  protected readonly messageIcon = messageSquareIcon;
  protected readonly copyIcon = copyIcon;
  protected readonly pinIcon = pinIcon;
  protected readonly unpinIcon = unpinIcon;
  protected readonly edit2Icon = edit2Icon;

  constructor() {
    this.editTimedOut = toSignal(
      toObservable(this.note).pipe(
        switchMap(({ createdAt }) => {
          const editEnd = addMinutes(createdAt.toDate(), MAX_EDIT_MINUTES);
          if (isPast(editEnd)) {
            return of(true);
          }

          return timer(editEnd).pipe(map(() => false));
        }),
      ),
      { initialValue: false },
    );
  }

  public async copy(): Promise<void> {
    await navigator.clipboard.writeText(this.note().adminFullName);
    this.snackBar.open(`Note author copied`, 'Close', {
      duration: 2000,
    });
  }
}
