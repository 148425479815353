export const mimeTypeMap: Record<string, string> = {
  gif: 'image/gif',
  pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  png: 'image/png',
  heic: 'image/heic',
  heif: 'image/heif',
  bmp: 'image/bmp',
  webp: 'image/webp',
};

function getFileExtension(fileName: string): string {
  return fileName.replace(/^.*\./, '').toLowerCase();
}

function extensionToType(ext: string): string {
  return mimeTypeMap[ext] ?? 'application/octet-stream';
}
export function getFileType(fileName: string): string {
  const ext = getFileExtension(fileName);
  return extensionToType(ext);
}
