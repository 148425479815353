import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { defaultEquals } from '@angular/core/primitives/signals';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'excludeFromArray',
  standalone: true,
})
export class ExcludeFromArrayPipe implements PipeTransform {
  public transform<T>(
    list: readonly T[] | null | undefined,
    exclude: readonly T[] | null | undefined,
    match: (listItem: T, excludeItem: T) => boolean = defaultEquals,
  ): readonly T[] | null {
    if (isNil(list)) {
      return null;
    }

    if (list.length === 0 || isNil(exclude) || exclude.length === 0) {
      return list;
    }

    return list.filter(
      (listItem) =>
        exclude.findIndex((excludeItem) => match(listItem, excludeItem)) === -1,
    );
  }
}
