// @generated by protoc-gen-es v1.10.0
// @generated from file service/delete_mo_from_map.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message payments.DeleteMOFromMapRequest
 */
export const DeleteMOFromMapRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.DeleteMOFromMapRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.DeleteMOFromMapResponse
 */
export const DeleteMOFromMapResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.DeleteMOFromMapResponse",
  () => [
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

