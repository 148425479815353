import type { Signal } from '@angular/core';
import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import { stringToBigInt } from '@pu/sdk';
import { ACCESS_PAYLOAD } from '@bo/common';

export function injectCurrentUserId(): Signal<bigint> {
  const userId$ = inject(ACCESS_PAYLOAD).pipe(
    map((payload) => stringToBigInt(payload.user_id)),
  );

  return toSignal(userId$, { requireSync: true });
}
