import {
  inject,
  Pipe,
  type PipeTransform,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeUrl',
  standalone: true,
})
export class SanitizeUrlPipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);

  public transform(url: string): string | null {
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }
}
