import type {
  AutoWithdrawal,
  Status,
} from '@pinup-grpc/services/bo/players/players_pb';

import type { License, StatusInfo } from '@bo/common';

import {
  activityStatusesMap,
  bettingStatusesMap,
  casinoStatusesMap,
  certifiedStatusesMap,
  depositStatusesMap,
  digitainStatusesMap,
  privilegeStatusesMap,
} from './statuses-map';

function* generateStatuses(
  license: License,
  statuses: Status,
): Generator<StatusInfo> {
  if (license.supports('casino-status')) {
    yield getStatusFromMap(casinoStatusesMap, statuses.casino);
  }

  if (license.supports('betting-status')) {
    yield getStatusFromMap(bettingStatusesMap, statuses.betting);
  }

  yield getStatusFromMap(digitainStatusesMap, statuses.digitainStatusId);
  yield getStatusFromMap(certifiedStatusesMap, statuses.certified.state);
  yield getStatusFromMap(activityStatusesMap, statuses.activity);
  yield getStatusFromMap(depositStatusesMap, statuses.deposit);
  yield getStatusFromMap(privilegeStatusesMap, statuses.privilege);
}

export function mapStatuses(license: License, statuses: Status): StatusInfo[] {
  return [...generateStatuses(license, statuses)];
}

export function getStatusFromMap(
  statusMap: Map<number, StatusInfo>,
  key: number,
): StatusInfo {
  return statusMap.get(key) || createEmptyStatus();
}

export function createEmptyStatus(): StatusInfo {
  return { label: 'Unknown status', className: null };
}

export function autoWithdrawalToStatusInfo(
  authWithdrawal: AutoWithdrawal,
): StatusInfo {
  return {
    label: `AW ${authWithdrawal.off ? 'OFF' : 'ON'}`,
    className: authWithdrawal.off ? 'failed-status' : 'primary-status',
  };
}
