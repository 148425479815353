import type { ValueEqualityFn } from '@angular/core';
import { Directive, inject, input, TemplateRef } from '@angular/core';
import { defaultEquals } from '@angular/core/primitives/signals';
import { identity } from 'rxjs';

import type { AutocompleteEqual } from './sorting/autocomplete-equal.type';

interface AutocompleteOfContext<T, V> {
  readonly $implicit: T;
  readonly value: V;
}

@Directive({
  selector: '[boAutocomplete][boAutocompleteOf]',
  standalone: true,
})
export class AutocompleteOfDirective<T, V> {
  public static ngTemplateContextGuard<T, V>(
    dir: AutocompleteOfDirective<T, V>,
    ctx: unknown,
  ): ctx is AutocompleteOfContext<T, V> {
    return true;
  }

  public readonly template =
    inject<TemplateRef<AutocompleteOfContext<T, V>>>(TemplateRef);

  public readonly items = input.required<readonly T[] | null | undefined>({
    alias: 'boAutocompleteOf',
  });
  public readonly highlight = input.required<AutocompleteEqual<T>>({
    alias: 'boAutocompleteHighlight',
  });
  public readonly valueSelector = input<(item: T) => V>(
    identity as (value: T) => V,
    { alias: 'boAutocompleteValueSelector' },
  );
  public readonly equal = input<ValueEqualityFn<V>>(defaultEquals, {
    alias: 'boAutocompleteEqual',
  });
}
