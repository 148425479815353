<suspense-area puDialogSuspense puSuspense #suspense="puSuspense">
  <div mat-dialog-title>Change Phone</div>
  <form
    pu-dialog-content
    id="change-phone-dialog-form"
    (ngSubmit)="
      suspense.run(
        playerRemoteService.setPhone({
          playerId: data.profileId,
          phone: phone(),
        })
      )
    "
    #form="ngForm"
  >
    <mat-form-field class="field">
      <mat-label>Phone</mat-label>
      <input
        [(ngModel)]="phone"
        [masked]="phoneMask"
        matInput
        name="phone"
        puStrictPhone
        required
        type="tel"
        #phoneNumber="ngModel"
      />
      @if (phoneNumber | validationError; as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
  </form>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="button"
      form="change-phone-dialog-form"
      matDialogClose="cancel"
      type="button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="form.invalid"
      color="primary"
      form="change-phone-dialog-form"
      type="submit"
    >
      Save
    </button>
  </mat-dialog-actions>
</suspense-area>
