// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/currencies/currencies.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.currencies, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetCurrencyRequest, GetCurrencyResponse, ListCurrenciesRequest, ListCurrenciesResponse } from "./currencies_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.CurrencyService
 */
@Injectable({ providedIn: "root" })
export class CurrencyRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.CurrencyService";

  public static readonly methods = {
    getCurrency: {
      name: "GetCurrency",
      I: GetCurrencyRequest,
      O: GetCurrencyResponse,
      kind: MethodKind.Unary,
    },
    listCurrencies: {
      name: "ListCurrencies",
      I: ListCurrenciesRequest,
      O: ListCurrenciesResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.CurrencyService.GetCurrency
   */
  public getCurrency(
    input: PartialMessage<GetCurrencyRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetCurrencyResponse> {
    return unary<GetCurrencyRequest, GetCurrencyResponse>(
      this.transport,
      CurrencyRemoteService,
      CurrencyRemoteService.methods.getCurrency,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.CurrencyService.ListCurrencies
   */
  public listCurrencies(
    input: PartialMessage<ListCurrenciesRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListCurrenciesResponse> {
    return unary<ListCurrenciesRequest, ListCurrenciesResponse>(
      this.transport,
      CurrencyRemoteService,
      CurrencyRemoteService.methods.listCurrencies,
      input,
      options,
    );
  }
}
