// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/backoffice/player/player.proto (package payments.backoffice.player, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetIsOneClickEnabledRequest, GetIsOneClickEnabledResponse, SwitchOffOneClickRequest, SwitchOffOneClickResponse } from "./player_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.backoffice.player.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "payments.backoffice.player.PlayerService";

  public static readonly methods = {
    switchOffOneClick: {
      name: "SwitchOffOneClick",
      I: SwitchOffOneClickRequest,
      O: SwitchOffOneClickResponse,
      kind: MethodKind.Unary,
    },
    getIsOneClickEnabled: {
      name: "GetIsOneClickEnabled",
      I: GetIsOneClickEnabledRequest,
      O: GetIsOneClickEnabledResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.backoffice.player.PlayerService.SwitchOffOneClick
   */
  public switchOffOneClick(
    input: PartialMessage<SwitchOffOneClickRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SwitchOffOneClickResponse> {
    return unary<SwitchOffOneClickRequest, SwitchOffOneClickResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.switchOffOneClick,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.player.PlayerService.GetIsOneClickEnabled
   */
  public getIsOneClickEnabled(
    input: PartialMessage<GetIsOneClickEnabledRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetIsOneClickEnabledResponse> {
    return unary<GetIsOneClickEnabledRequest, GetIsOneClickEnabledResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getIsOneClickEnabled,
      input,
      options,
    );
  }
}
