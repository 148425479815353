import type { PipeTransform } from '@angular/core';
import { inject, Pipe } from '@angular/core';

import { COMMUNICATION_HUB_URL } from './communication-hub.providers';

@Pipe({ name: 'boCommunicationHubLink', standalone: true })
export class CommunicationHubLinkPipe implements PipeTransform {
  private readonly url = inject(COMMUNICATION_HUB_URL);

  public transform(path: string): string {
    return `${this.url}/${path}`;
  }
}
