import type { OnChanges } from '@angular/core';
import {
  DestroyRef,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  input,
  NgZone,
} from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Maskito, maskitoTransform } from '@maskito/core';
import { isNil } from 'lodash-es';
import { noop } from 'rxjs';

import type { OnChangeListener, OnTouchedListener } from '../form/types';
import { provideMultiExisting } from '../util/provide';
import type { MaskedOptions } from './masked.options';

// TODO textarea
// TODO contenteditable
@Directive({
  selector:
    'input[masked][formControlName], input[masked][formControl], input[masked][ngModel]',
  standalone: true,
  providers: [
    provideMultiExisting(
      NG_VALUE_ACCESSOR,
      forwardRef(() => MaskedValueAccessor),
    ),
  ],
  exportAs: 'masked',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix -- similar to Angular accessor
export class MaskedValueAccessor<T> implements ControlValueAccessor, OnChanges {
  private readonly nativeElement = (
    inject(ElementRef) as ElementRef<HTMLInputElement>
  ).nativeElement;

  private readonly ngZone = inject(NgZone);

  private readonly destroyRef = inject(DestroyRef);

  private instance: Maskito | null = null;

  public suffix = '';

  public readonly options = input.required<MaskedOptions<T>>({
    alias: 'masked',
  });

  private onChange: OnChangeListener<T | null> = noop;

  private onTouched: OnTouchedListener = noop;

  constructor() {
    this.destroyRef.onDestroy(() => {
      this.instance?.destroy();
    });
  }

  @HostListener('input') public onInput(): void {
    const { value } = this.nativeElement;

    this.updateSuffix();
    this.onChange(value === '' ? null : this.options().parse(value));
  }

  @HostListener('blur') public onBlur(): void {
    this.onTouched();
  }

  public ngOnChanges(): void {
    this.instance?.destroy();

    this.ngZone.runOutsideAngular(() => {
      this.instance = new Maskito(this.nativeElement, this.options());
    });
  }

  private updateSuffix(): void {
    this.suffix = this.options()?.suffix?.(this.nativeElement.value) ?? '';
  }

  public writeValue(value: T | null | undefined): void {
    const options = this.options();

    this.nativeElement.value = isNil(value)
      ? ''
      : maskitoTransform(options.stringify(value), options);

    this.updateSuffix();
  }

  public registerOnChange(fn: OnChangeListener<T | null>): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: OnTouchedListener): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.nativeElement.disabled = isDisabled;
  }
}
