// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/stat/report/deposits/deposits.proto (package pinup.stat.report.deposits, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ListDepositsRequest, ListDepositsResponse } from "./deposits_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.stat.report.deposits.DepositsService
 */
@Injectable({ providedIn: "root" })
export class DepositsRemoteService {
  public static readonly typeName = "pinup.stat.report.deposits.DepositsService";

  public static readonly methods = {
    listDeposits: {
      name: "ListDeposits",
      I: ListDepositsRequest,
      O: ListDepositsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ----------------------------------------------------------------
   * ListDeposits - List of player`s deposits filtered with pagination
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc pinup.stat.report.deposits.DepositsService.ListDeposits
   */
  public listDeposits(
    input: PartialMessage<ListDepositsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListDepositsResponse> {
    return unary<ListDepositsRequest, ListDepositsResponse>(
      this.transport,
      DepositsRemoteService,
      DepositsRemoteService.methods.listDeposits,
      input,
      options,
    );
  }
}
