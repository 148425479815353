import { inject, Injectable } from '@angular/core';
import type {
  ListTransactionsRequest_Filter,
  Transaction,
} from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import { combineLatest, map, shareReplay, switchMap } from 'rxjs';

import type {
  FiltersProvider,
  ProfileBalanceHistoryVM,
  WithPaginatedData,
} from '@bo/common';
import {
  FILTERS_PROVIDER,
  getStatus,
  PAGINATION_PANEL_MANAGER,
  PlayerService,
  prepareFinance,
  TransactionsProvider,
} from '@bo/common';

@Injectable()
export class PlayerBalanceHistoryService implements WithPaginatedData {
  public transactionsProvider = inject(TransactionsProvider);

  private readonly pagination$ = inject(PAGINATION_PANEL_MANAGER).source$;

  public readonly player$ = inject(PlayerService).player$;

  private readonly filterService =
    inject<FiltersProvider<ListTransactionsRequest_Filter>>(FILTERS_PROVIDER);
  public readonly filters$ = this.filterService.filters$;

  public actions$ = combineLatest([
    this.pagination$,
    this.filters$,
    this.player$,
  ]);

  public readonly paginatedData$ = this.actions$.pipe(
    switchMap(([pagination, filters, player]) =>
      this.transactionsProvider.getTransactions(pagination, player, filters),
    ),
    shareReplay(1),
  );

  public transactions$ = this.paginatedData$.pipe(
    map(({ transactions }) =>
      PlayerBalanceHistoryService.normalizeTransactions(transactions),
    ),
  );

  private static normalizeTransactions(
    transactions: Transaction[],
  ): ProfileBalanceHistoryVM[] {
    return transactions.map(
      (transaction): ProfileBalanceHistoryVM => ({
        ...transaction,
        finance: prepareFinance(transaction.finance),
        convertFinance: prepareFinance(transaction.convertFinance),
        status: getStatus(transaction),
      }),
    );
  }
}
