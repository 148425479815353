import type { EnvironmentProviders } from '@angular/core';
import { inject, makeEnvironmentProviders } from '@angular/core';
import { GRPC_WEB_TRANSPORT_BASE_URL } from '@pinup/grpc-ng';

import { provideFactory } from '@pu/sdk';

import { License } from './license';

export function provideLicenseGrpcOrigin(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideFactory(GRPC_WEB_TRANSPORT_BASE_URL, () => inject(License).origin),
  ]);
}
