// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/player_finance.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetTotalBalanceRequest, GetTotalBalanceResponse } from "./player_finance_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * Players totals statistic
 *
 * @generated from service stat.PlayerFinance
 */
@Injectable({ providedIn: "root" })
export class PlayerFinanceRemoteService {
  public static readonly typeName = "stat.PlayerFinance";

  public static readonly methods = {
    getTotalBalance: {
      name: "GetTotalBalance",
      I: GetTotalBalanceRequest,
      O: GetTotalBalanceResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * -----------------------------------------
   * Get total balance of players on date
   * -----------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<license>.stat_service.players.get_balances
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.PlayerFinance.GetTotalBalance
   */
  public getTotalBalance(
    input: PartialMessage<GetTotalBalanceRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetTotalBalanceResponse> {
    return unary<GetTotalBalanceRequest, GetTotalBalanceResponse>(
      this.transport,
      PlayerFinanceRemoteService,
      PlayerFinanceRemoteService.methods.getTotalBalance,
      input,
      options,
    );
  }
}
