// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/playersEmail.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { DeleteEmailRequest, DeleteEmailResponse } from "./playersEmail_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service players.DuplicatePlayerService
 */
@Injectable({ providedIn: "root" })
export class DuplicatePlayerRemoteService {
  public static readonly typeName = "players.DuplicatePlayerService";

  public static readonly methods = {
    deleteEmail: {
      name: "DeleteEmail",
      I: DeleteEmailRequest,
      O: DeleteEmailResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc players.DuplicatePlayerService.DeleteEmail
   */
  public deleteEmail(
    input: PartialMessage<DeleteEmailRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteEmailResponse> {
    return unary<DeleteEmailRequest, DeleteEmailResponse>(
      this.transport,
      DuplicatePlayerRemoteService,
      DuplicatePlayerRemoteService.methods.deleteEmail,
      input,
      options,
    );
  }
}
