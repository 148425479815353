import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';

import { ToDatePipe } from '@pu/sdk';

import { DocumentsTableComponent } from '../../documents/documents-table.component';
import { FileLinkComponent } from '../../documents/file-link/file-link.component';
import { FilesFromDocumentPipe } from '../../documents/pipes/files-from-document.pipe';
import { EmptyTemplateComponent } from '../../empty-template/empty-template.component';
import { ListStateWrapperComponent } from '../../list-state-wrapper/list-state-wrapper.component';
import { ReadableEnumValuePipe } from '../../value-based-on-map/readable-enum-value.pipe';

@Component({
  selector: 'bo-documents-table',
  standalone: true,
  templateUrl: './documents-for-verifications-table.component.html',
  styleUrl: './documents-for-verifications-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderCell,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    EmptyTemplateComponent,
    MatRow,
    DatePipe,
    ToDatePipe,
    FileLinkComponent,
    ReadableEnumValuePipe,
    FilesFromDocumentPipe,
    ListStateWrapperComponent,
  ],
})
export class DocumentsForVerificationsTableComponent extends DocumentsTableComponent {}
