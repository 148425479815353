// @generated by protoc-gen-es v1.10.0
// @generated from file common/verification.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { VerificationStatus, VerificationType } from "./enum_pb.js";

/**
 * @generated from message kycaid.Verification
 */
export const Verification = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.Verification",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(VerificationType) },
    { no: 3, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "verification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "external_verification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(VerificationStatus) },
    { no: 8, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "created", kind: "message", T: Timestamp },
    { no: 12, name: "updated", kind: "message", T: Timestamp },
  ],
);

