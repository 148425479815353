<div class="error-wrapper flex align-items-center justify-center">
  @if (isError()) {
    <bo-svg [unsafeSvgSource]="errorBackground" />
  } @else {
    <bo-svg [unsafeSvgSource]="emptyBackground" />
  }
  <div class="message-wrapper flex column align-items-center">
    @if (icon(); as icon) {
      <bo-svg class="message-icon" [size]="56" [unsafeSvgSource]="icon" />
    }
    @if (message(); as message) {
      <span class="message pt-5">{{ message }}</span>
    }
    @if (prompt(); as prompt) {
      <span class="prompt pt-2">{{ prompt }}</span>
    }
    <!--    todo uncomment and add output if btn needed in future-->
    <!--    @if (isError) {-->
    <!--      <button mat-stroked-button class="try-btn" (click)="tryAgainAction()">-->
    <!--        Try again-->
    <!--      </button>-->
    <!--    }-->
  </div>
</div>
