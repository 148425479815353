// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/geo_stat.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetFullReportRequest, GetFullReportResponse, GetRealPlayerRequest, GetRealPlayerResponse, GetRegionsRequest, GetRegionsResponse, GetRegistrationRequest, GetRegistrationResponse, GetStatRequest, GetStatResponse } from "./geo_stat_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service stat.GeoStatService
 */
@Injectable({ providedIn: "root" })
export class GeoStatRemoteService {
  public static readonly typeName = "stat.GeoStatService";

  public static readonly methods = {
    getRegions: {
      name: "GetRegions",
      I: GetRegionsRequest,
      O: GetRegionsResponse,
      kind: MethodKind.Unary,
    },
    getRegistration: {
      name: "GetRegistration",
      I: GetRegistrationRequest,
      O: GetRegistrationResponse,
      kind: MethodKind.Unary,
    },
    getRealPlayer: {
      name: "GetRealPlayer",
      I: GetRealPlayerRequest,
      O: GetRealPlayerResponse,
      kind: MethodKind.Unary,
    },
    getStat: {
      name: "GetStat",
      I: GetStatRequest,
      O: GetStatResponse,
      kind: MethodKind.Unary,
    },
    getFullReport: {
      name: "GetFullReport",
      I: GetFullReportRequest,
      O: GetFullReportResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ----------------------------------------------------------------
   * GetRegions - returns list of all regions.
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.GeoStatService.GetRegions
   */
  public getRegions(
    input: PartialMessage<GetRegionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRegionsResponse> {
    return unary<GetRegionsRequest, GetRegionsResponse>(
      this.transport,
      GeoStatRemoteService,
      GeoStatRemoteService.methods.getRegions,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * GetRegistration - geo registration count by period
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.GeoStatService.GetRegistration
   */
  public getRegistration(
    input: PartialMessage<GetRegistrationRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRegistrationResponse> {
    return unary<GetRegistrationRequest, GetRegistrationResponse>(
      this.transport,
      GeoStatRemoteService,
      GeoStatRemoteService.methods.getRegistration,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * GetRealPlayer - geo real players report by period
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.GeoStatService.GetRealPlayer
   */
  public getRealPlayer(
    input: PartialMessage<GetRealPlayerRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRealPlayerResponse> {
    return unary<GetRealPlayerRequest, GetRealPlayerResponse>(
      this.transport,
      GeoStatRemoteService,
      GeoStatRemoteService.methods.getRealPlayer,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * GetStat - geo short stat report by period
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.GeoStatService.GetStat
   */
  public getStat(
    input: PartialMessage<GetStatRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetStatResponse> {
    return unary<GetStatRequest, GetStatResponse>(
      this.transport,
      GeoStatRemoteService,
      GeoStatRemoteService.methods.getStat,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * GetFullReport - geo stat report by period
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.GeoStatService.GetFullReport
   */
  public getFullReport(
    input: PartialMessage<GetFullReportRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetFullReportResponse> {
    return unary<GetFullReportRequest, GetFullReportResponse>(
      this.transport,
      GeoStatRemoteService,
      GeoStatRemoteService.methods.getFullReport,
      input,
      options,
    );
  }
}
