// @generated by protoc-gen-es v1.10.0
// @generated from file service/kycaid.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, Duration, Int64Value, proto3, StringValue, Timestamp, UInt64Value } from "@bufbuild/protobuf";
import { VerificationType } from "../common/enum_pb.js";
import { Verification } from "../common/verification_pb.js";
import { Sorting } from "../common/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../common/pagination_pb.js";
import { TimeRange } from "../common/filter_pb.js";
import { VerificationLog } from "../common/verificationLog_pb.js";
import { Address, Applicant, ApplicantResponse, ApplicantVerification, Document } from "../common/applicant_pb.js";

/**
 * SettingsVerificationType is a key for verification timeout
 *
 * @generated from enum kycaid.VerificationCacheType
 */
export const VerificationCacheType = /*@__PURE__*/ proto3.makeEnum(
  "kycaid.VerificationCacheType",
  [
    {no: 0, name: "VERIFICATION_CACHE_TYPE_UNDEFINED"},
    {no: 1, name: "VERIFICATION_CACHE_TYPE_BANK_ID"},
    {no: 2, name: "VERIFICATION_CACHE_TYPE_DIIA"},
    {no: 3, name: "VERIFICATION_CACHE_TYPE_DOCUMENTS"},
    {no: 4, name: "REVERIFICATION_CACHE_TYPE_DOCUMENTS"},
    {no: 5, name: "VERIFICATION_CACHE_TYPE_TAX_ID"},
    {no: 6, name: "REVERIFICATION_CACHE_TYPE_BANK_ID"},
    {no: 7, name: "REVERIFICATION_CACHE_TYPE_DIIA"},
  ],
);

/**
 * @generated from message kycaid.CreateExternalVerificationRequest
 */
export const CreateExternalVerificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.CreateExternalVerificationRequest",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "callback_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "languageCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_spa", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_player_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_native", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "verification_status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message kycaid.CreateExternalVerificationResponse
 */
export const CreateExternalVerificationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.CreateExternalVerificationResponse",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "private_bank_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mono_bank_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.AvailabilityCheckRequest
 */
export const AvailabilityCheckRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.AvailabilityCheckRequest",
  () => [
    { no: 4, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.ApiAvailabilityCheckRequest
 */
export const ApiAvailabilityCheckRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ApiAvailabilityCheckRequest",
  () => [
    { no: 4, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "verification_status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message kycaid.AvailabilityCheckResponse
 */
export const AvailabilityCheckResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.AvailabilityCheckResponse",
  () => [
    { no: 1, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "next_try_after", kind: "message", T: Duration },
    { no: 3, name: "last_update", kind: "message", T: Timestamp },
    { no: 4, name: "availabilities", kind: "message", T: AvailabilityCheckResponse_Availability, repeated: true },
    { no: 5, name: "reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "total_tries_left", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "player_ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message kycaid.AvailabilityCheckResponse.Availability
 */
export const AvailabilityCheckResponse_Availability = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.AvailabilityCheckResponse.Availability",
  () => [
    { no: 1, name: "verification_type", kind: "enum", T: proto3.getEnumType(VerificationCacheType) },
    { no: 2, name: "is_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "next_try_after", kind: "message", T: Duration },
    { no: 4, name: "last_updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "tries_left", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "AvailabilityCheckResponse_Availability"},
);

/**
 * @generated from message kycaid.UpdateVerificationRequest
 */
export const UpdateVerificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.UpdateVerificationRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "declineReasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "pep", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "annual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "monthly", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "duplicates", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 11, name: "verification_type", kind: "enum", T: proto3.getEnumType(VerificationType) },
  ],
);

/**
 * @generated from message kycaid.GetVerificationRequest
 */
export const GetVerificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.GetVerificationResponse
 */
export const GetVerificationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationResponse",
  () => [
    { no: 1, name: "verification", kind: "message", T: Verification },
  ],
);

/**
 * @generated from message kycaid.GetVerificationListRequest
 */
export const GetVerificationListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationListRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: GetVerificationListRequest_Filter },
    { no: 2, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message kycaid.GetVerificationListRequest.Filter
 */
export const GetVerificationListRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationListRequest.Filter",
  () => [
    { no: 1, name: "verification_id", kind: "message", T: StringValue },
    { no: 2, name: "player_id", kind: "message", T: UInt64Value },
    { no: 3, name: "period", kind: "message", T: TimeRange },
    { no: 4, name: "status", kind: "message", T: UInt64Value },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(VerificationType) },
    { no: 6, name: "decline_reasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "is_verified", kind: "message", T: BoolValue },
  ],
  {localName: "GetVerificationListRequest_Filter"},
);

/**
 * @generated from message kycaid.GetVerificationListResponse
 */
export const GetVerificationListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationListResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: Verification, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message kycaid.GetVerificationLogRequest
 */
export const GetVerificationLogRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationLogRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.GetVerificationLogResponse
 */
export const GetVerificationLogResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationLogResponse",
  () => [
    { no: 1, name: "verification_log", kind: "message", T: VerificationLog },
  ],
);

/**
 * @generated from message kycaid.GetVerificationLogListRequest
 */
export const GetVerificationLogListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationLogListRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: GetVerificationLogListRequest_Filter },
    { no: 2, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message kycaid.GetVerificationLogListRequest.Filter
 */
export const GetVerificationLogListRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationLogListRequest.Filter",
  () => [
    { no: 1, name: "id", kind: "message", T: Int64Value },
    { no: 2, name: "player_id", kind: "message", T: UInt64Value },
    { no: 3, name: "verification_id", kind: "message", T: StringValue },
    { no: 4, name: "period", kind: "message", T: TimeRange },
  ],
  {localName: "GetVerificationLogListRequest_Filter"},
);

/**
 * @generated from message kycaid.GetVerificationLogListResponse
 */
export const GetVerificationLogListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetVerificationLogListResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: VerificationLog, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message kycaid.ExternalVerificationCallbackRequest
 */
export const ExternalVerificationCallbackRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ExternalVerificationCallbackRequest",
  () => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "verification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "verification_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "verification", kind: "message", T: ApplicantVerification },
    { no: 9, name: "applicant", kind: "message", T: Applicant },
    { no: 10, name: "duplicates", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message kycaid.VerificationCallbackRequest
 */
export const VerificationCallbackRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.VerificationCallbackRequest",
  () => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "verification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "applicant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "verification_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "verification", kind: "message", T: ApplicantVerification },
    { no: 9, name: "applicant", kind: "message", T: Applicant },
  ],
);

/**
 * @generated from message kycaid.GetApplicantRequest
 */
export const GetApplicantRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message kycaid.GetApplicantResponse
 */
export const GetApplicantResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantResponse",
  () => [
    { no: 1, name: "applicant", kind: "message", T: ApplicantResponse },
  ],
);

/**
 * @generated from message kycaid.GetApplicantByPlayerIDRequest
 */
export const GetApplicantByPlayerIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantByPlayerIDRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message kycaid.GetApplicantByPlayerIDResponse
 */
export const GetApplicantByPlayerIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantByPlayerIDResponse",
  () => [
    { no: 1, name: "applicant", kind: "message", T: ApplicantResponse },
  ],
);

/**
 * @generated from message kycaid.GetAddressRequest
 */
export const GetAddressRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetAddressRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.GetAddressResponse
 */
export const GetAddressResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetAddressResponse",
  () => [
    { no: 1, name: "address", kind: "message", T: Address },
  ],
);

/**
 * @generated from message kycaid.GetDocumentRequest
 */
export const GetDocumentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.GetDocumentResponse
 */
export const GetDocumentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentResponse",
  () => [
    { no: 1, name: "document", kind: "message", T: Document },
  ],
);

/**
 * @generated from message kycaid.GetIncomeRequest
 */
export const GetIncomeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetIncomeRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message kycaid.GetIncomeResponse
 */
export const GetIncomeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetIncomeResponse",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "document_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "annual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "monthly", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "sources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message kycaid.GetDocumentImageRequest
 */
export const GetDocumentImageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentImageRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message kycaid.GetDocumentImageResponse
 */
export const GetDocumentImageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentImageResponse",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message kycaid.GetApplicantListRequest
 */
export const GetApplicantListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantListRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: GetApplicantListRequest_Filter },
    { no: 2, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message kycaid.GetApplicantListRequest.Filter
 */
export const GetApplicantListRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantListRequest.Filter",
  () => [
    { no: 2, name: "player_id", kind: "message", T: UInt64Value },
  ],
  {localName: "GetApplicantListRequest_Filter"},
);

/**
 * @generated from message kycaid.GetApplicantListResponse
 */
export const GetApplicantListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetApplicantListResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: ApplicantResponse, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message kycaid.GetAddressListRequest
 */
export const GetAddressListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetAddressListRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: GetAddressListRequest_Filter },
    { no: 2, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message kycaid.GetAddressListRequest.Filter
 */
export const GetAddressListRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetAddressListRequest.Filter",
  () => [
    { no: 2, name: "player_id", kind: "message", T: UInt64Value },
  ],
  {localName: "GetAddressListRequest_Filter"},
);

/**
 * @generated from message kycaid.GetAddressListResponse
 */
export const GetAddressListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetAddressListResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: Address, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message kycaid.GetDocumentListRequest
 */
export const GetDocumentListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentListRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: GetDocumentListRequest_Filter },
    { no: 2, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 3, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message kycaid.GetDocumentListRequest.Filter
 */
export const GetDocumentListRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentListRequest.Filter",
  () => [
    { no: 2, name: "player_id", kind: "message", T: UInt64Value },
    { no: 3, name: "type", kind: "message", T: StringValue },
  ],
  {localName: "GetDocumentListRequest_Filter"},
);

/**
 * @generated from message kycaid.GetDocumentListResponse
 */
export const GetDocumentListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetDocumentListResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: Document, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message kycaid.VerificationCallbackResponse
 */
export const VerificationCallbackResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.VerificationCallbackResponse",
  [],
);

/**
 * @generated from message kycaid.ExternalVerificationCallbackResponse
 */
export const ExternalVerificationCallbackResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.ExternalVerificationCallbackResponse",
  [],
);

/**
 * SettingsVerificationTypeTimeout is an entity of verification timeout
 *
 * @generated from message kycaid.SettingsVerificationTypeTimeout
 */
export const SettingsVerificationTypeTimeout = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.SettingsVerificationTypeTimeout",
  () => [
    { no: 1, name: "verification_type", kind: "enum", T: proto3.getEnumType(VerificationCacheType) },
    { no: 2, name: "timeout_duration", kind: "message", T: Duration },
  ],
);

/**
 * @generated from message kycaid.SettingsVerificationTypeLimit
 */
export const SettingsVerificationTypeLimit = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.SettingsVerificationTypeLimit",
  () => [
    { no: 1, name: "verification_type", kind: "enum", T: proto3.getEnumType(VerificationCacheType) },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message kycaid.GetSettingsRequest
 */
export const GetSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetSettingsRequest",
  [],
);

/**
 * @generated from message kycaid.GetSettingsResponse
 */
export const GetSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetSettingsResponse",
  () => [
    { no: 1, name: "bank_id_verification_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updated", kind: "message", T: Timestamp },
    { no: 4, name: "verification_type_timeouts", kind: "message", T: SettingsVerificationTypeTimeout, repeated: true },
    { no: 5, name: "verification_type_limits", kind: "message", T: SettingsVerificationTypeLimit, repeated: true },
    { no: 6, name: "total_verifications_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "total_reverification_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message kycaid.UpdateSettingsRequest
 */
export const UpdateSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.UpdateSettingsRequest",
  () => [
    { no: 1, name: "bank_id_verification_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "verification_type_timeouts", kind: "message", T: SettingsVerificationTypeTimeout, repeated: true },
    { no: 3, name: "verification_type_limits", kind: "message", T: SettingsVerificationTypeLimit, repeated: true },
    { no: 4, name: "total_verifications_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "total_reverification_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message kycaid.UpdateSettingsResponse
 */
export const UpdateSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.UpdateSettingsResponse",
  [],
);

/**
 * @generated from message kycaid.GetFormURLRequest
 */
export const GetFormURLRequest = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetFormURLRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "verification_status", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "tax_id_checked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "aml", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "is_spa", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "check_dep_exception", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_player_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "is_native", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message kycaid.GetFormURLResponse
 */
export const GetFormURLResponse = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.GetFormURLResponse",
  () => [
    { no: 1, name: "form_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

