import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlayerRemoteService } from '@pinup-grpc/service/backoffice/player/player_ng';
import {
  catchError,
  first,
  map,
  merge,
  of,
  shareReplay,
  Subject,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { PlayerService } from '@bo/common';

import type { OneClickPayload } from '../../utils/types/one-click.types';

@Injectable()
export class ProfileService {
  private readonly playerRemoteService = inject(PlayerRemoteService);
  private readonly snackBar = inject(MatSnackBar);

  private readonly turnOffOneClickAction$ = new Subject<OneClickPayload>();

  public readonly playerId$ = inject(PlayerService).playerId$.pipe(first());
  public readonly turnOffOneClick$ = this.turnOffOneClickAction$.pipe(
    withLatestFrom(this.playerId$),
    switchMap(([payload, playerId]) =>
      this.playerRemoteService.switchOffOneClick({
        playerId,
        ...payload,
      }),
    ),
    switchMap(() => this.playerId$),
    shareReplay(1),
  );

  public readonly isOneClickEnabled$ = merge(
    this.playerId$,
    this.turnOffOneClick$,
  ).pipe(
    switchMap((playerId) =>
      this.playerRemoteService.getIsOneClickEnabled({ playerId }),
    ),
    map(({ isEnabled }) => isEnabled),
    catchError(() => of(null)),
    shareReplay(1),
  );

  constructor() {
    this.turnOffOneClick$
      .pipe(takeUntilDestroyed())
      .subscribe(() =>
        this.snackBar.open('One click off', 'OK', { duration: 5000 }),
      );
  }

  public turnOffOneClick(payload: OneClickPayload): void {
    this.turnOffOneClickAction$.next(payload);
  }

  public async copy(id: bigint | undefined): Promise<void> {
    let message: string;

    if (id) {
      await navigator.clipboard.writeText(id.toString());
      message = 'Profile ID copied';
    } else {
      message = 'Profile ID does not exist';
    }

    this.snackBar.open(message, 'Close', {
      duration: 2000,
    });
  }
}
