import type { PipeTransform, ValueEqualityFn } from '@angular/core';
import { Pipe } from '@angular/core';
import { defaultEquals } from '@angular/core/primitives/signals';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'includes',
  standalone: true,
})
export class IncludesPipe implements PipeTransform {
  public transform<T>(
    list: readonly T[] | null | undefined,
    value: T,
    equal: ValueEqualityFn<T> = defaultEquals,
  ): boolean {
    if (isNil(list)) {
      return false;
    }

    return list.some((item) => equal(item, value));
  }
}
