import { NonNullableFormControl } from '@pu/sdk';
import { FiltersForm } from '@bo/common';

import type {
  FinanceDepositsFilter,
  ListDepositsFilter,
} from '../deposits-filter.types';

export type OtherFilters = Pick<
  FinanceDepositsFilter,
  'clickId' | 'domain' | 'playerGeo'
>;

export class DepositsFilterOtherForm extends FiltersForm<OtherFilters> {
  constructor(data?: ListDepositsFilter) {
    super({
      domain: new NonNullableFormControl(data?.deposit.domain ?? ''),
      playerGeo: new NonNullableFormControl(data?.profile.playerGeo.ids ?? []),
      clickId: new NonNullableFormControl(data?.profile.clickId ?? ''),
    });
  }
}
