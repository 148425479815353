import { Directive, effect, inject, model } from '@angular/core';

import { DEFAULT_PAGINATION } from './pagination.const';
import type { Pagination } from './pagination-panel.model';
import { BO_PAGINATION_VALUE_ACCESSOR } from './pagination-panel.providers';

@Directive({
  selector: '[boPagination]',
  standalone: true,
})
export class PaginationPanelDirective {
  private readonly valueAccessor = inject(BO_PAGINATION_VALUE_ACCESSOR, {
    optional: true,
    self: true,
  });

  public readonly pagination = model<Partial<Pagination>>(DEFAULT_PAGINATION, {
    alias: 'boPagination',
  });

  constructor() {
    const valueAccessor = this.valueAccessor;

    if (!valueAccessor) {
      throw new Error(
        'PaginationPanelDirective can be applied only for pagination value accessor',
      );
    }

    effect(() => {
      valueAccessor.writeValue(this.pagination());
    });

    valueAccessor.registerOnChange(this.onChange.bind(this));
  }

  private onChange(value: Partial<Pagination>): void {
    this.pagination.update((pagination) => ({ ...pagination, ...value }));
  }
}
