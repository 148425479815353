import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { Sorting } from '@pinup-grpc/base/sorting_pb';
import { Sorting_Order } from '@pinup-grpc/base/sorting_pb';
import type { Observable } from 'rxjs';
import {
  catchError,
  combineLatest,
  debounceTime,
  filter,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';

import type { WithPaginatedData } from '@bo/common';
import {
  PAGINATION_PANEL_MANAGER,
  PlayerService,
  SortingFieldManager,
} from '@bo/common';

import { PlayerPaymentMethodProviderService } from './player-payment-method.provider.service';

@Injectable()
export class PlayerPaymentMethodService implements WithPaginatedData {
  private readonly provider = inject(PlayerPaymentMethodProviderService);
  private readonly paginationManager = inject(PAGINATION_PANEL_MANAGER);
  private readonly route = inject(ActivatedRoute);

  private readonly sortingManager = new SortingFieldManager({
    fieldName: 'lastActivityAt',
    order: Sorting_Order.DESC,
  });
  private readonly pagination$ = this.paginationManager.source$;
  public readonly sorting$ = this.sortingManager.sorting$;
  private readonly playerId$ = inject(PlayerService).playerId$;

  private readonly triggers$ = combineLatest([
    this.pagination$,
    this.sorting$,
    this.playerId$,
  ]);

  public readonly paginatedData$ = this.triggers$.pipe(
    filter(([, , playerId]) => Boolean(playerId)),
    debounceTime(0),
    switchMap(([pagination, sorting, playerId]) => {
      return this.provider.getPlayerPaymentMethods(
        playerId,
        sorting,
        pagination,
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly data$ = this.paginatedData$.pipe(
    map(({ paymentMethods }) => paymentMethods),
  );

  public readonly hasData$ = this.paginatedData$.pipe(
    map((response) => response.paymentMethods.length > 0),
  );

  public isLoading$: Observable<boolean> = merge(
    this.triggers$.pipe(map(() => true)),
    this.paginatedData$.pipe(
      map(() => false),
      catchError(() => of(false)),
    ),
  ).pipe(startWith(false));

  public hasError$ = this.paginatedData$.pipe(
    map(() => false),
    catchError(() => of(true)),
  );

  public changeSort(sort: Partial<Sorting>): void {
    this.sortingManager.setSorting(sort);
  }
}
