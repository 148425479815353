import { Document } from '@pinup-grpc/service/backoffice/documents/documents_pb';

import { VerificationDocumentType } from '../configs/document-types';
import {
  documentSideMap,
  type FileNameProp,
  type FileSideName,
} from '../configs/document-verification';

export function patchTaxIdDocuments(documents: Document[]): Document[] {
  return documents.map((doc) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- FIXME: we should wait for the implementation enum from the back-end side
    if (doc.type !== VerificationDocumentType.TAX_ID_NUMBER) {
      return doc;
    }

    const updatedDocumentData = { ...doc };
    Object.keys(documentSideMap).forEach((key) => {
      updatedDocumentData[key as keyof FileSideName] = fallbackSideName(
        documents,
        doc,
        key as FileNameProp,
      );
    });

    return new Document(updatedDocumentData);
  });
}

function fallbackSideName(
  documents: Document[],
  doc: Document,
  propertyName: FileNameProp,
): string {
  if (!doc[propertyName]) {
    const fallbackDocument = documents.find(
      (item) => isVerificationDocument(item) && item[propertyName],
    );

    const fallbackFileName = fallbackDocument
      ? fallbackDocument[propertyName]
      : '';
    doc[propertyName] = fallbackFileName;
  }

  return doc[propertyName];
}

function isVerificationDocument(doc: Document): boolean {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- FIXME
    (doc.type === VerificationDocumentType.GOVERNMENT_ID ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- FIXME
      doc.type === VerificationDocumentType.PASSPORT) &&
    doc.status === 'valid'
  );
}
