// @generated by protoc-gen-es v1.10.0
// @generated from file models/moneyoutsettings/settings.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message payments.MoneyOutSettings
 */
export const MoneyOutSettings = /*@__PURE__*/ proto3.makeMessageType(
  "payments.MoneyOutSettings",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "amountFrom", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "amountTo", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "turnover", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "ldTurnover", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "ldTurnoverRiskMin", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "ldTurnoverRiskMax", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "blockCasino", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "blockBet", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "isSameSystemName", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "limitSportBet", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "limitCasinoBet", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "isFilled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "isVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "isConfirmed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "manualPayments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "autoMO", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "timer", kind: "message", T: Duration },
    { no: 21, name: "timerAfter", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "dayLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 23, name: "popup", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "updated", kind: "message", T: Timestamp },
    { no: 25, name: "awAllowedPerDayOn", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "awAllowedPerDay", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 27, name: "countriesLimits", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 3 /* ScalarType.INT64 */} },
    { no: 28, name: "holdMoneyCurrencyIDs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

