import { Directive, input } from '@angular/core';

import { ContentOutletDirective } from '@pu/sdk';

import { GLOBAL_FILTERS_CONTENT_ID } from './global-filters.const';

@Directive({
  selector: '[boGlobalFiltersOutlet]',
  standalone: true,
})
export class GlobalFiltersOutletDirective extends ContentOutletDirective {
  public contentId = input(GLOBAL_FILTERS_CONTENT_ID);
}
