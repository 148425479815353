import { Directive, input } from '@angular/core';

import { TransferContentDirective } from '@pu/sdk';

import { GLOBAL_FILTERS_CONTENT_ID } from './global-filters.const';

@Directive({
  selector: '[boGlobalFiltersTransfer]',
  standalone: true,
})
export class GlobalFiltersTransferDirective extends TransferContentDirective {
  public contentId = input(GLOBAL_FILTERS_CONTENT_ID);
}
