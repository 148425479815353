// @generated by protoc-gen-es v1.10.0
// @generated from file service/bo/dictionary/v1/dictionary.proto (package gamification.service.bo.dictionary.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Language } from "../../../../base/bo/v1/dictionary_pb.js";

/**
 * Request to get languages.
 *
 * @generated from message gamification.service.bo.dictionary.v1.GetLanguagesRequest
 */
export const GetLanguagesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.dictionary.v1.GetLanguagesRequest",
  [],
);

/**
 * Response for getting languages.
 *
 * @generated from message gamification.service.bo.dictionary.v1.GetLanguagesResponse
 */
export const GetLanguagesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.dictionary.v1.GetLanguagesResponse",
  () => [
    { no: 1, name: "languages", kind: "message", T: Language, repeated: true },
  ],
);

