// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/bo/withdrawals/withdrawals.proto (package stat.bo.withdrawals, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ListWithdrawalsRequest, ListWithdrawalsResponse } from "./withdrawals_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service stat.bo.withdrawals.WithdrawalService
 */
@Injectable({ providedIn: "root" })
export class WithdrawalRemoteService {
  public static readonly typeName = "stat.bo.withdrawals.WithdrawalService";

  public static readonly methods = {
    listWithdrawals: {
      name: "ListWithdrawals",
      I: ListWithdrawalsRequest,
      O: ListWithdrawalsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ----------------------------------------------------------------
   * ListWithdrawals - List of withdrawal by filter with pagination
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.withdrawals.WithdrawalService.ListWithdrawals
   */
  public listWithdrawals(
    input: PartialMessage<ListWithdrawalsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListWithdrawalsResponse> {
    return unary<ListWithdrawalsRequest, ListWithdrawalsResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.listWithdrawals,
      input,
      options,
    );
  }
}
