import {
  computed,
  Directive,
  HostListener,
  inject,
  input,
} from '@angular/core';

import { PaddingTolerantViewportScroller } from '@pu/sdk';

import type {
  HorizontalAlignment,
  VerticalAlignment,
} from './scroll-to-anchor.const';

@Directive({
  selector: '[puScrollToAnchor]',
  standalone: true,
})
export class ScrollToAnchorDirective {
  public readonly anchorId = input.required<string>();

  public readonly horizontalAlign = input<HorizontalAlignment>();
  public readonly verticalAlign = input<VerticalAlignment>();
  public readonly scrollOptions = computed<ScrollIntoViewOptions>(() => ({
    behaviour: 'smooth',
    ...this.horizontalAlign(),
    ...this.verticalAlign(),
  }));

  private readonly viewportScroller = inject(PaddingTolerantViewportScroller);

  @HostListener('click')
  public scrollToAnchor(): void {
    this.viewportScroller.scrollToAnchor(this.anchorId(), this.scrollOptions());
  }
}
