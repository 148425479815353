import { DichotomyAnswer } from './types/dichotomy-option.types';

export function toDichotomy(data: string): DichotomyAnswer | null {
  if (!data) {
    return null;
  }

  return (data as DichotomyAnswer) === DichotomyAnswer.Yes
    ? DichotomyAnswer.Yes
    : DichotomyAnswer.No;
}

export function fromDichotomy(data: string): boolean | undefined {
  if (!data) {
    return undefined;
  }

  return (data as DichotomyAnswer) === DichotomyAnswer.Yes;
}
