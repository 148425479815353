// @generated by protoc-gen-es v1.10.0
// @generated from file proto/enum.proto (package billing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum billing.Type
 */
export const Type = /*@__PURE__*/ proto3.makeEnum(
  "billing.Type",
  [
    {no: 0, name: "TYPE_NONE", localName: "NONE"},
    {no: 1, name: "TYPE_ADD_DEPOSIT", localName: "ADD_DEPOSIT"},
    {no: 2, name: "TYPE_ADD_PINCOINS", localName: "ADD_PINCOINS"},
    {no: 3, name: "TYPE_ADD_PINCOINS_ACHIEVEMENT", localName: "ADD_PINCOINS_ACHIEVEMENT"},
    {no: 4, name: "TYPE_CASHBACK", localName: "CASHBACK"},
    {no: 5, name: "TYPE_EXCHANGE_PINCOINS", localName: "EXCHANGE_PINCOINS"},
    {no: 6, name: "TYPE_FREEBET_WIN", localName: "FREEBET_WIN"},
    {no: 7, name: "TYPE_LOTTERY_WIN", localName: "LOTTERY_WIN"},
    {no: 8, name: "TYPE_MARKETING_WIN", localName: "MARKETING_WIN"},
    {no: 9, name: "TYPE_REVERT_WITHDRAW", localName: "REVERT_WITHDRAW"},
    {no: 10, name: "TYPE_WITHDRAW", localName: "WITHDRAW"},
    {no: 11, name: "TYPE_WON_BONUS", localName: "WON_BONUS"},
    {no: 12, name: "TYPE_WRITE_BET", localName: "WRITE_BET"},
    {no: 13, name: "TYPE_WELCOME_DEPOSIT", localName: "WELCOME_DEPOSIT"},
    {no: 14, name: "TYPE_REVERT", localName: "REVERT"},
    {no: 15, name: "TYPE_TAX", localName: "TAX"},
  ],
);

/**
 * @generated from enum billing.Project
 */
export const Project = /*@__PURE__*/ proto3.makeEnum(
  "billing.Project",
  [
    {no: 0, name: "PROJECT_NONE", localName: "NONE"},
    {no: 1, name: "PROJECT_SPORT", localName: "SPORT"},
    {no: 2, name: "PROJECT_CASINO", localName: "CASINO"},
  ],
);

