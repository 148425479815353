// @generated by protoc-gen-es v1.10.0
// @generated from file proto/balance.proto (package billing, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message billing.Balance
 */
export const Balance = /*@__PURE__*/ proto3.makeMessageType(
  "billing.Balance",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "pincoinBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "pincoinsAllSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "depositAllSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "currency", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "Date", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message billing.BalanceV2
 */
export const BalanceV2 = /*@__PURE__*/ proto3.makeMessageType(
  "billing.BalanceV2",
  () => [
    { no: 1, name: "accountId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "depositSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "depositCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "pincoinBalance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "pincoinsSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

