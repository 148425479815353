import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import {
  combineLatest,
  debounceTime,
  ignoreElements,
  map,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import type { PlayerNote, TriggerPlayerNotePinResponse } from '@pu/grpc';
import type { SmartDialogOptions } from '@pu/sdk';
import { provideValue, SmartDialog } from '@pu/sdk';
import type { DateRange, WithPaginatedData } from '@bo/common';
import {
  AddDepositService,
  PAGINATION_MAX,
  PAGINATION_PANEL_MANAGER,
  PlayerService,
  toTimeRange,
} from '@bo/common';

import type { EditNoteDialogData } from './edit-note-dialog/edit-note-dialog.component';
import {
  EDIT_NOTE_DIALOG_DATA,
  EditNoteDialogComponent,
} from './edit-note-dialog/edit-note-dialog.component';
import { PlayerNotesProviderService } from './player-notes.provider.service';

@Injectable()
export class PlayerNotesService implements WithPaginatedData {
  private readonly provider = inject(PlayerNotesProviderService);
  private readonly dialog = inject(SmartDialog);
  private readonly addDepositService = inject(AddDepositService, {
    optional: true,
  });

  public readonly refresh$ = new Subject<void>();
  public readonly refreshDeposits$ =
    this.addDepositService?.refresh$ ?? of(null);

  public readonly createdAt$ = new Subject<DateRange | undefined>();

  private readonly pagination$ = inject(PAGINATION_PANEL_MANAGER).source$;

  private readonly playerId$ = inject(PlayerService).playerId$;

  private readonly $unpinnedRefreshSources = combineLatest([
    this.playerId$,
    this.createdAt$.pipe(startWith(undefined)),
    this.pagination$,
    this.refresh$.pipe(startWith(null)),
    this.refreshDeposits$,
  ]);

  private readonly $pinnedRefreshSources = combineLatest([
    this.playerId$,
    this.createdAt$.pipe(startWith(undefined)),
    this.refresh$.pipe(startWith(null)),
    this.refreshDeposits$,
  ]);

  public readonly player$ = this.playerId$.pipe(
    switchMap((id) => this.provider.getPlayer(id)),
    map(({ player }) => player),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly pinnedNotes$ = this.$pinnedRefreshSources.pipe(
    debounceTime(0),
    switchMap(([id, createdAt]) =>
      this.provider.getNotes(
        id,
        true,
        createdAt ? toTimeRange(createdAt.from, createdAt.to) : undefined,
        PAGINATION_MAX,
      ),
    ),
    map(({ notes }) => notes),
    shareReplay(1),
  );

  public readonly lastPinnedNotes$ = this.pinnedNotes$.pipe(
    map((notes) => notes.slice(0, 3)),
  );

  public pinnedTotal$ = this.pinnedNotes$.pipe(map((items) => items.length));

  private readonly unpinnedData$ = this.$unpinnedRefreshSources.pipe(
    debounceTime(0),
    switchMap(([id, createdAt, pagination]) =>
      this.provider.getNotes(
        id,
        false,
        createdAt ? toTimeRange(createdAt.from, createdAt.to) : undefined,
        pagination,
      ),
    ),
    shareReplay(1),
  );

  public readonly paginatedData$ = this.unpinnedData$;

  public unpinnedNotes$ = this.unpinnedData$.pipe(map(({ notes }) => notes));

  public setCreatedAt(dateRange?: DateRange): void {
    this.createdAt$.next(dateRange);
  }

  public toggleNotePinned(
    noteId: bigint,
  ): Observable<TriggerPlayerNotePinResponse> {
    return this.provider.toggleNotePinned(noteId).pipe(
      tap(() => {
        this.refresh$.next();
      }),
    );
  }

  public openCreateNoteDialog(): Observable<never> {
    return this.player$.pipe(
      take(1),
      switchMap((player) =>
        this.openDialog({ mode: 'create', playerId: player.id }),
      ),
    );
  }

  public openEditNoteDialog(note: PlayerNote): Observable<never> {
    return this.openDialog({ mode: 'edit', note });
  }

  private openDialog(
    data: EditNoteDialogData,
    { providers = [] }: SmartDialogOptions = {},
  ): Observable<never> {
    return this.dialog
      .open(EditNoteDialogComponent, [
        ...providers,
        provideValue(EDIT_NOTE_DIALOG_DATA, data),
      ])
      .pipe(
        tap(() => {
          this.refresh$.next();
        }),
        ignoreElements(),
      );
  }
}
