import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { COUNTRIES_DATA } from './countries.const';
import type { Country } from './country.interface';

@Injectable({ providedIn: 'root' })
export class CountriesService {
  // TODO inline this service
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  public getCountriesList(): Observable<Country[]> {
    return of(COUNTRIES_DATA);
  }
}
