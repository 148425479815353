// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/players/players.proto (package stat.bo.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, proto3, Timestamp } from "@bufbuild/protobuf";
import { SumCurrency } from "../../../base/models_pb.js";
import { Sorting } from "../../../base/sorting_pb.js";
import { InfinityPaginationRequest, InfinityPaginationResponse, PaginationRequest, PaginationResponse } from "../../../base/pagination_pb.js";
import { Project } from "../../../base/enum_pb.js";
import { Int64Range, StringSearch, StringsSearch, SumRange, TimeRange } from "../../../base/filter_pb.js";
import { Sorting as Sorting$1 } from "../../../pinup/std/query/v1/sorting_pb.js";
import { Gender as Gender$1, Project as Project$1 } from "../../../pinup/std/query/v1/types_pb.js";
import { TimeRange as TimeRange$1 } from "../../../pinup/std/query/v1/filter_pb.js";

/**
 * @generated from enum stat.bo.players.Gender
 */
export const Gender = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.Gender",
  [
    {no: 0, name: "GENDER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GENDER_MALE", localName: "MALE"},
    {no: 2, name: "GENDER_FEMALE", localName: "FEMALE"},
  ],
);

/**
 * CasinoStatus is a player's casino status
 *
 * @generated from enum stat.bo.players.CasinoStatus
 */
export const CasinoStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.CasinoStatus",
  [
    {no: 0, name: "CASINO_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CASINO_STATUS_C1", localName: "C1"},
    {no: 2, name: "CASINO_STATUS_C2", localName: "C2"},
    {no: 3, name: "CASINO_STATUS_C3", localName: "C3"},
    {no: 4, name: "CASINO_STATUS_C4", localName: "C4"},
    {no: 5, name: "CASINO_STATUS_CA", localName: "CA"},
    {no: 6, name: "CASINO_STATUS_CN", localName: "CN"},
    {no: 7, name: "CASINO_STATUS_CVIP", localName: "CVIP"},
    {no: 8, name: "CASINO_STATUS_CZ", localName: "CZ"},
  ],
);

/**
 * BettingStatus is a player's betting status
 *
 * @generated from enum stat.bo.players.BettingStatus
 */
export const BettingStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.BettingStatus",
  [
    {no: 0, name: "BETTING_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BETTING_STATUS_B1", localName: "B1"},
    {no: 2, name: "BETTING_STATUS_B2", localName: "B2"},
    {no: 3, name: "BETTING_STATUS_B3", localName: "B3"},
    {no: 4, name: "BETTING_STATUS_B4", localName: "B4"},
    {no: 5, name: "BETTING_STATUS_B5", localName: "B5"},
    {no: 6, name: "BETTING_STATUS_BA", localName: "BA"},
    {no: 7, name: "BETTING_STATUS_BN", localName: "BN"},
    {no: 8, name: "BETTING_STATUS_BVIP", localName: "BVIP"},
    {no: 9, name: "BETTING_STATUS_BZ", localName: "BZ"},
  ],
);

/**
 * ActivityStatus when player last logged in
 *
 * @generated from enum stat.bo.players.ActivityStatus
 */
export const ActivityStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.ActivityStatus",
  [
    {no: 0, name: "ACTIVITY_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACTIVITY_STATUS_A1", localName: "A1"},
    {no: 2, name: "ACTIVITY_STATUS_A2", localName: "A2"},
    {no: 3, name: "ACTIVITY_STATUS_A3", localName: "A3"},
    {no: 4, name: "ACTIVITY_STATUS_AN", localName: "AN"},
  ],
);

/**
 * ActivityStatus when player last logged in
 *
 * @generated from enum stat.bo.players.DepositStatus
 */
export const DepositStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.DepositStatus",
  [
    {no: 0, name: "DEPOSIT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEPOSIT_STATUS_D1", localName: "D1"},
    {no: 2, name: "DEPOSIT_STATUS_D2", localName: "D2"},
    {no: 3, name: "DEPOSIT_STATUS_D3", localName: "D3"},
    {no: 4, name: "DEPOSIT_STATUS_D4", localName: "D4"},
    {no: 5, name: "DEPOSIT_STATUS_D5", localName: "D5"},
    {no: 6, name: "DEPOSIT_STATUS_DN", localName: "DN"},
  ],
);

/**
 * @generated from enum stat.bo.players.PrivilegeStatus
 */
export const PrivilegeStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.PrivilegeStatus",
  [
    {no: 0, name: "PRIVILEGE_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRIVILEGE_STATUS_P0", localName: "P0"},
    {no: 2, name: "PRIVILEGE_STATUS_P1", localName: "P1"},
    {no: 3, name: "PRIVILEGE_STATUS_P2", localName: "P2"},
    {no: 4, name: "PRIVILEGE_STATUS_P3", localName: "P3"},
    {no: 5, name: "PRIVILEGE_STATUS_P4", localName: "P4"},
    {no: 6, name: "PRIVILEGE_STATUS_P5", localName: "P5"},
    {no: 7, name: "PRIVILEGE_STATUS_P6", localName: "P6"},
    {no: 8, name: "PRIVILEGE_STATUS_P7", localName: "P7"},
    {no: 9, name: "PRIVILEGE_STATUS_P8", localName: "P8"},
    {no: 10, name: "PRIVILEGE_STATUS_P9", localName: "P9"},
    {no: 11, name: "PRIVILEGE_STATUS_P10", localName: "P10"},
    {no: 12, name: "PRIVILEGE_STATUS_PN", localName: "PN"},
  ],
);

/**
 * @generated from enum stat.bo.players.MaStatus
 */
export const MaStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.MaStatus",
  [
    {no: 0, name: "MA_STATUS_UNSPECIFIED"},
    {no: 1, name: "MA_STATUS_1"},
    {no: 2, name: "MA_STATUS_2"},
  ],
);

/**
 * @generated from enum stat.bo.players.SmsStatus
 */
export const SmsStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.SmsStatus",
  [
    {no: 0, name: "SMS_STATUS_UNSPECIFIED"},
    {no: 1, name: "SMS_STATUS_1"},
    {no: 2, name: "SMS_STATUS_2"},
    {no: 3, name: "SMS_STATUS_3"},
    {no: 4, name: "SMS_STATUS_4"},
    {no: 5, name: "SMS_STATUS_5"},
    {no: 6, name: "SMS_STATUS_6"},
  ],
);

/**
 * @generated from enum stat.bo.players.TwoFactorVerification
 */
export const TwoFactorVerification = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.TwoFactorVerification",
  [
    {no: 0, name: "TFV_UNSPECIFIED"},
    {no: 1, name: "TFV_ON"},
    {no: 2, name: "TFV_OFF"},
  ],
);

/**
 * @generated from enum stat.bo.players.CertifiedState
 */
export const CertifiedState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.CertifiedState",
  [
    {no: 0, name: "CERTIFIED_STATUS_NOT_CERTIFIED"},
    {no: 1, name: "CERTIFIED_STATUS_CERTIFIED"},
    {no: 2, name: "CERTIFIED_STATUS_UNCERTIFIED_MANUALLY"},
    {no: 3, name: "CERTIFIED_STATUS_CERTIFIED_MANUALLY"},
  ],
);

/**
 * @generated from enum stat.bo.players.AccountVerifyState
 */
export const AccountVerifyState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.AccountVerifyState",
  [
    {no: 0, name: "ACCOUNT_VERIFY_NO"},
    {no: 1, name: "ACCOUNT_VERIFY_YES"},
    {no: 2, name: "ACCOUNT_VERIFY_IN_PROGRESS"},
    {no: 3, name: "ACCOUNT_VERIFY_NO_FINAL"},
    {no: 4, name: "ACCOUNT_VERIFY_NO_RETRY"},
  ],
);

/**
 * @generated from enum stat.bo.players.AccountVerifySource
 */
export const AccountVerifySource = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.AccountVerifySource",
  [
    {no: 0, name: "ACCOUNT_VERIFY_SOURCE_UNSPECIFIED"},
    {no: 1, name: "ACCOUNT_VERIFY_SOURCE_1"},
    {no: 2, name: "ACCOUNT_VERIFY_SOURCE_2"},
  ],
);

/**
 * 0 - не проверен, 1 - существует, 2 - не существует
 *
 * @generated from enum stat.bo.players.EmailCheckState
 */
export const EmailCheckState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.EmailCheckState",
  [
    {no: 0, name: "EMAIL_CHECK_UNSPECIFIED"},
    {no: 1, name: "EMAIL_CHECK_EXISTS"},
    {no: 2, name: "EMAIL_CHECK_NOT_EXISTS"},
  ],
);

/**
 * @generated from enum stat.bo.players.PhoneCheckedState
 */
export const PhoneCheckedState = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.PhoneCheckedState",
  [
    {no: 0, name: "PHONE_CHECKED_UNSPECIFIED"},
    {no: 1, name: "PHONE_CHECKED_CONFIRMED"},
  ],
);

/**
 * Player auto withdrawal reason(short list)
 *
 * @generated from enum stat.bo.players.AutoWithdrawalOffReason
 */
export const AutoWithdrawalOffReason = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.AutoWithdrawalOffReason",
  [
    {no: 0, name: "AWR_UNSPECIFIED"},
    {no: 1, name: "AWR_ALL_IN_FIRST"},
    {no: 2, name: "AWR_FAST_MONEY_OUT"},
    {no: 3, name: "AWR_SUSPICIOUS_SESSIONS"},
  ],
);

/**
 * @generated from enum stat.bo.players.BlockReason
 */
export const BlockReason = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.BlockReason",
  [
    {no: 0, name: "BR_UNSPECIFIED"},
    {no: 1, name: "BR_SELF_LOCK"},
    {no: 2, name: "BR_MULTI_ACCOUNT"},
    {no: 3, name: "BR_FIN_FRAUD"},
    {no: 4, name: "BR_LOSS_OF_ACCESS"},
    {no: 5, name: "BR_GROUP_CONSPIRACY"},
    {no: 6, name: "BR_HACKING"},
    {no: 7, name: "BR_MERGE"},
    {no: 8, name: "BR_BONUS_ABUSE"},
    {no: 9, name: "BR_AUTOMATIC_INACTIVITY_BLOCK"},
    {no: 10, name: "BR_OTHER_VIOLATION"},
    {no: 11, name: "BR_FAILED_LOGINS"},
    {no: 12, name: "BR_RUS_FIN_MON_BLOCK"},
    {no: 13, name: "BR_MONITORING_BLOCK"},
  ],
);

/**
 * @generated from enum stat.bo.players.DigitainStatus
 */
export const DigitainStatus = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.DigitainStatus",
  [
    {no: 0, name: "DIGITAIN_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DIGITAIN_STATUS_COMMON", localName: "COMMON"},
    {no: 3, name: "DIGITAIN_STATUS_FEMALE", localName: "FEMALE"},
    {no: 4, name: "DIGITAIN_STATUS_VIP_USER", localName: "VIP_USER"},
    {no: 2, name: "DIGITAIN_STATUS_NEW_USER", localName: "NEW_USER"},
    {no: 5, name: "DIGITAIN_STATUS_TERMINAL", localName: "TERMINAL"},
    {no: 6, name: "DIGITAIN_STATUS_ARBITRAGE", localName: "ARBITRAGE"},
    {no: 7, name: "DIGITAIN_STATUS_PROBLEM", localName: "PROBLEM"},
    {no: 8, name: "DIGITAIN_STATUS_SPECIAL", localName: "SPECIAL"},
    {no: 9, name: "DIGITAIN_STATUS_MARKER", localName: "MARKER"},
    {no: 10, name: "DIGITAIN_STATUS_TRADING_ANALYZING", localName: "TRADING_ANALYZING"},
    {no: 11, name: "DIGITAIN_STATUS_LATE_BETTING", localName: "LATE_BETTING"},
    {no: 12, name: "DIGITAIN_STATUS_MIDDLER", localName: "MIDDLER"},
    {no: 13, name: "DIGITAIN_STATUS_BET_SHOP", localName: "BET_SHOP"},
    {no: 14, name: "DIGITAIN_STATUS_PROFESSIONAL", localName: "PROFESSIONAL"},
    {no: 15, name: "DIGITAIN_STATUS_HIGH_VALUE", localName: "HIGH_VALUE"},
    {no: 16, name: "DIGITAIN_STATUS_VIP_SPECIAL", localName: "VIP_SPECIAL"},
    {no: 17, name: "DIGITAIN_STATUS_BONUS_HUNTER", localName: "BONUS_HUNTER"},
    {no: 18, name: "DIGITAIN_STATUS_CAPPER", localName: "CAPPER"},
  ],
);

/**
 * @generated from enum stat.bo.players.RestrictReason
 */
export const RestrictReason = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.RestrictReason",
  [
    {no: 0, name: "RESTRICT_STATUS_UNSPECIFIED"},
    {no: 1, name: "RESTRICT_STATUS_REAL_CASINO"},
    {no: 2, name: "RESTRICT_STATUS_REAL_SPORT"},
    {no: 3, name: "RESTRICT_STATUS_BONUS_CASINO"},
    {no: 4, name: "RESTRICT_STATUS_BONUS_SPORT"},
  ],
);

/**
 * moneyOutTax', 'moneyOutTaxFree', 'depositTax' are events that should be considered
 * as tax events
 *
 * @generated from enum stat.bo.players.TaxAction
 */
export const TaxAction = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.players.TaxAction",
  [
    {no: 0, name: "TAX_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TAX_ACTION_DEPOSIT_TAX", localName: "DEPOSIT_TAX"},
    {no: 2, name: "TAX_ACTION_MONEY_OUT_FREE", localName: "MONEY_OUT_FREE"},
    {no: 3, name: "TAX_ACTION_MONEY_OUT_TAX", localName: "MONEY_OUT_TAX"},
  ],
);

/**
 * @generated from message stat.bo.players.CertifiedStatus
 */
export const CertifiedStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.CertifiedStatus",
  () => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(CertifiedState) },
    { no: 2, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.players.AccountStatus
 */
export const AccountStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.AccountStatus",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(AccountVerifyState) },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(AccountVerifySource) },
    { no: 4, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.players.EmailStatus
 */
export const EmailStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.EmailStatus",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(EmailCheckState) },
    { no: 3, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.players.PhoneStatus
 */
export const PhoneStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PhoneStatus",
  () => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(PhoneCheckedState) },
    { no: 2, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.players.Status
 */
export const Status = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.Status",
  () => [
    { no: 1, name: "account", kind: "message", T: AccountStatus },
    { no: 2, name: "email", kind: "message", T: EmailStatus },
    { no: 3, name: "phone", kind: "message", T: PhoneStatus },
    { no: 4, name: "certified", kind: "message", T: CertifiedStatus },
    { no: 5, name: "activity", kind: "enum", T: proto3.getEnumType(ActivityStatus) },
    { no: 6, name: "deposit", kind: "enum", T: proto3.getEnumType(DepositStatus) },
    { no: 7, name: "casino", kind: "enum", T: proto3.getEnumType(CasinoStatus) },
    { no: 8, name: "betting", kind: "enum", T: proto3.getEnumType(BettingStatus) },
    { no: 9, name: "privilege", kind: "enum", T: proto3.getEnumType(PrivilegeStatus) },
    { no: 10, name: "ma", kind: "enum", T: proto3.getEnumType(MaStatus) },
    { no: 11, name: "sms", kind: "enum", T: proto3.getEnumType(SmsStatus) },
    { no: 12, name: "digitainStatusId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message stat.bo.players.Location
 */
export const Location = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.Location",
  () => [
    { no: 1, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "postCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "house", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Player auto withdrawal status
 *
 * @generated from message stat.bo.players.AutoWithdrawal
 */
export const AutoWithdrawal = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.AutoWithdrawal",
  () => [
    { no: 1, name: "off", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.players.Finance
 */
export const Finance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.Finance",
  () => [
    { no: 3, name: "profitCasino", kind: "message", T: SumCurrency },
    { no: 4, name: "profitSport", kind: "message", T: SumCurrency },
    { no: 5, name: "withdrawals", kind: "message", T: SumCurrency },
    { no: 6, name: "deposits", kind: "message", T: SumCurrency },
    { no: 2, name: "bonusBalance", kind: "message", T: SumCurrency },
    { no: 7, name: "casinoBonusBalance", kind: "message", T: SumCurrency },
    { no: 8, name: "sportBonusBalance", kind: "message", T: SumCurrency },
    { no: 9, name: "lastTurnover", kind: "message", T: SumCurrency },
    { no: 11, name: "lastDeposit", kind: "message", T: SumCurrency },
    { no: 10, name: "totalTurnover", kind: "message", T: SumCurrency },
    { no: 12, name: "depositWithdrawalTax", kind: "message", T: SumCurrency },
    { no: 13, name: "betSumSport", kind: "message", T: SumCurrency },
    { no: 14, name: "betSumCasino", kind: "message", T: SumCurrency },
  ],
);

/**
 * @generated from message stat.bo.players.Block
 */
export const Block = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.Block",
  () => [
    { no: 1, name: "sendEmail", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "sendSMS", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "sportTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "block180", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "casinoTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "subscribed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "reason", kind: "enum", T: proto3.getEnumType(BlockReason) },
  ],
);

/**
 * @generated from message stat.bo.players.Limit
 */
export const Limit = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.Limit",
  () => [
    { no: 1, name: "daily", kind: "message", T: SumCurrency },
  ],
);

/**
 * @generated from message stat.bo.players.Player
 */
export const Player = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.Player",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "registeredAt", kind: "message", T: Timestamp },
    { no: 7, name: "lastLogin", kind: "message", T: Timestamp },
    { no: 8, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "middleName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 13, name: "birthDay", kind: "message", T: Timestamp },
    { no: 14, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "isFullProfile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "twoFactorVerification", kind: "enum", T: proto3.getEnumType(TwoFactorVerification) },
    { no: 18, name: "status", kind: "message", T: Status },
    { no: 19, name: "location", kind: "message", T: Location },
    { no: 20, name: "autoWithdrawal", kind: "message", T: AutoWithdrawal },
    { no: 21, name: "finance", kind: "message", T: Finance },
    { no: 22, name: "convertedFinance", kind: "message", T: Finance },
    { no: 23, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "isProbablyGood", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "isHacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "block", kind: "message", T: Block },
    { no: 28, name: "limit", kind: "message", T: Limit },
    { no: 29, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.bo.players.GetPlayerRequest
 */
export const GetPlayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.GetPlayerRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.bo.players.GetPlayerResponse
 */
export const GetPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.GetPlayerResponse",
  () => [
    { no: 1, name: "player", kind: "message", T: Player },
  ],
);

/**
 * @generated from message stat.bo.players.ShortFinance
 */
export const ShortFinance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ShortFinance",
  () => [
    { no: 1, name: "balance", kind: "message", T: SumCurrency },
  ],
);

/**
 * PlayerShort is a short version of Player
 *
 * @generated from message stat.bo.players.PlayerShort
 */
export const PlayerShort = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerShort",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "status", kind: "message", T: Status },
    { no: 10, name: "registeredAt", kind: "message", T: Timestamp },
    { no: 11, name: "lastLoginAt", kind: "message", T: Timestamp },
    { no: 12, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "finance", kind: "message", T: ShortFinance },
    { no: 15, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "isHacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "block", kind: "message", T: Block },
    { no: 18, name: "middleName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "taxId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayersRequest
 */
export const ListPlayersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersRequest",
  () => [
    { no: 1, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filter", kind: "message", T: ListPlayersRequest_Filter },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayersRequest.Filter
 */
export const ListPlayersRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersRequest.Filter",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 4, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "isTest", kind: "message", T: BoolValue },
    { no: 6, name: "firstName", kind: "message", T: StringSearch },
    { no: 7, name: "lastName", kind: "message", T: StringSearch },
    { no: 8, name: "email", kind: "message", T: StringSearch },
    { no: 9, name: "phone", kind: "message", T: StringSearch },
    { no: 10, name: "cpf", kind: "message", T: StringSearch },
    { no: 11, name: "registered", kind: "message", T: TimeRange },
    { no: 12, name: "balance", kind: "message", T: SumRange },
    { no: 13, name: "isVip", kind: "message", T: BoolValue },
    { no: 14, name: "isHacked", kind: "message", T: BoolValue },
    { no: 15, name: "middleName", kind: "message", T: StringSearch },
    { no: 16, name: "taxId", kind: "message", T: StringSearch },
  ],
  {localName: "ListPlayersRequest_Filter"},
);

/**
 * @generated from message stat.bo.players.ListPlayersResponse
 */
export const ListPlayersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: PlayerShort, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message stat.bo.players.PlayerAW
 */
export const PlayerAW = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerAW",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "registeredAt", kind: "message", T: Timestamp },
    { no: 7, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "autoWithdrawal", kind: "message", T: AutoWithdrawal },
    { no: 11, name: "balance", kind: "message", T: SumCurrency },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayersAWRequest
 */
export const ListPlayersAWRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersAWRequest",
  () => [
    { no: 1, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filter", kind: "message", T: ListPlayersAWRequest_Filter },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayersAWRequest.Filter
 */
export const ListPlayersAWRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersAWRequest.Filter",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 3, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "registered", kind: "message", T: TimeRange },
    { no: 6, name: "balance", kind: "message", T: SumRange },
    { no: 7, name: "awOffReasons", kind: "message", T: ListPlayersAWRequest_Filter_AwStatusMultiselect },
    { no: 8, name: "awAuto", kind: "message", T: BoolValue },
  ],
  {localName: "ListPlayersAWRequest_Filter"},
);

/**
 * @generated from message stat.bo.players.ListPlayersAWRequest.Filter.AwStatusMultiselect
 */
export const ListPlayersAWRequest_Filter_AwStatusMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersAWRequest.Filter.AwStatusMultiselect",
  () => [
    { no: 1, name: "reasons", kind: "enum", T: proto3.getEnumType(AutoWithdrawalOffReason), repeated: true },
    { no: 2, name: "excluded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ListPlayersAWRequest_Filter_AwStatusMultiselect"},
);

/**
 * @generated from message stat.bo.players.ListPlayersAWResponse
 */
export const ListPlayersAWResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersAWResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: PlayerAW, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest
 */
export const ListPlayerStatisticsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest",
  () => [
    { no: 1, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filter", kind: "message", T: ListPlayerStatisticsRequest_Filter },
    { no: 3, name: "sorting", kind: "message", T: Sorting$1, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: InfinityPaginationRequest },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter
 */
export const ListPlayerStatisticsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter",
  () => [
    { no: 1, name: "profile", kind: "message", T: ListPlayerStatisticsRequest_Filter_Profile },
    { no: 2, name: "statuses", kind: "message", T: ListPlayerStatisticsRequest_Filter_Statuses },
    { no: 3, name: "verification", kind: "message", T: ListPlayerStatisticsRequest_Filter_Verification },
    { no: 4, name: "affiliate", kind: "message", T: ListPlayerStatisticsRequest_Filter_Affiliate },
    { no: 5, name: "activity", kind: "message", T: ListPlayerStatisticsRequest_Filter_Activity },
    { no: 6, name: "statistic", kind: "message", T: ListPlayerStatisticsRequest_Filter_Statistic },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Profile
 */
export const ListPlayerStatisticsRequest_Filter_Profile = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Profile",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "project", kind: "enum", T: proto3.getEnumType(Project$1), repeated: true },
    { no: 3, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "cities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "genders", kind: "enum", T: proto3.getEnumType(Gender$1), repeated: true },
    { no: 8, name: "logins", kind: "message", T: StringsSearch },
    { no: 9, name: "firstNames", kind: "message", T: StringsSearch },
    { no: 10, name: "middleNames", kind: "message", T: StringsSearch },
    { no: 11, name: "lastNames", kind: "message", T: StringsSearch },
    { no: 12, name: "emails", kind: "message", T: StringsSearch },
    { no: 13, name: "phones", kind: "message", T: StringsSearch },
    { no: 14, name: "cpfs", kind: "message", T: StringsSearch },
    { no: 15, name: "registeredAt", kind: "message", T: TimeRange$1 },
    { no: 16, name: "isEmailVerified", kind: "message", T: BoolValue },
    { no: 17, name: "isFullProfile", kind: "message", T: BoolValue },
    { no: 18, name: "balance", kind: "message", T: Int64Range },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Profile"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Statuses
 */
export const ListPlayerStatisticsRequest_Filter_Statuses = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Statuses",
  () => [
    { no: 1, name: "isBlocked", kind: "message", T: BoolValue },
    { no: 2, name: "blockReasons", kind: "enum", T: proto3.getEnumType(BlockReason), repeated: true },
    { no: 3, name: "statuses", kind: "message", T: ListPlayerStatisticsRequest_Filter_Statuses_OneOfStatuses },
    { no: 4, name: "types", kind: "message", T: ListPlayerStatisticsRequest_Filter_Statuses_OneOfTypes },
    { no: 5, name: "digitainStatuses", kind: "enum", T: proto3.getEnumType(DigitainStatus), repeated: true },
    { no: 6, name: "maStatuses", kind: "enum", T: proto3.getEnumType(MaStatus), repeated: true },
    { no: 7, name: "awAuto", kind: "message", T: BoolValue },
    { no: 8, name: "probablyGood", kind: "message", T: BoolValue },
    { no: 9, name: "restrictReasons", kind: "enum", T: proto3.getEnumType(RestrictReason), repeated: true },
    { no: 10, name: "smsStatuses", kind: "enum", T: proto3.getEnumType(SmsStatus), repeated: true },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Statuses"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Statuses.OneOfStatuses
 */
export const ListPlayerStatisticsRequest_Filter_Statuses_OneOfStatuses = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Statuses.OneOfStatuses",
  () => [
    { no: 1, name: "active", kind: "enum", T: proto3.getEnumType(ActivityStatus), repeated: true },
    { no: 2, name: "deposit", kind: "enum", T: proto3.getEnumType(DepositStatus), repeated: true },
    { no: 3, name: "casino", kind: "enum", T: proto3.getEnumType(CasinoStatus), repeated: true },
    { no: 4, name: "betting", kind: "enum", T: proto3.getEnumType(BettingStatus), repeated: true },
    { no: 5, name: "privilege", kind: "enum", T: proto3.getEnumType(PrivilegeStatus), repeated: true },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Statuses_OneOfStatuses"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Statuses.OneOfTypes
 */
export const ListPlayerStatisticsRequest_Filter_Statuses_OneOfTypes = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Statuses.OneOfTypes",
  () => [
    { no: 1, name: "isCapper", kind: "message", T: BoolValue },
    { no: 2, name: "isStreamer", kind: "message", T: BoolValue },
    { no: 3, name: "isTest", kind: "message", T: BoolValue },
    { no: 4, name: "isVip", kind: "message", T: BoolValue },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Statuses_OneOfTypes"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Verification
 */
export const ListPlayerStatisticsRequest_Filter_Verification = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Verification",
  () => [
    { no: 1, name: "isVerified", kind: "message", T: BoolValue },
    { no: 2, name: "verifiedAt", kind: "message", T: TimeRange$1 },
    { no: 4, name: "taxIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "docNumbers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Verification"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Affiliate
 */
export const ListPlayerStatisticsRequest_Filter_Affiliate = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Affiliate",
  () => [
    { no: 1, name: "clickIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "partners", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "sources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "promoCodes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Affiliate"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Activity
 */
export const ListPlayerStatisticsRequest_Filter_Activity = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Activity",
  () => [
    { no: 1, name: "firstDepositTime", kind: "message", T: TimeRange$1 },
    { no: 2, name: "lastLoginTime", kind: "message", T: TimeRange$1 },
    { no: 3, name: "lastBetTime", kind: "message", T: TimeRange$1 },
    { no: 4, name: "lastCasinoBetTime", kind: "message", T: TimeRange$1 },
    { no: 5, name: "lastSportBetTime", kind: "message", T: TimeRange$1 },
    { no: 6, name: "lastDepositTime", kind: "message", T: TimeRange$1 },
    { no: 7, name: "lastDepositCasinoTime", kind: "message", T: TimeRange$1 },
    { no: 8, name: "lastDepositSportTime", kind: "message", T: TimeRange$1 },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Activity"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsRequest.Filter.Statistic
 */
export const ListPlayerStatisticsRequest_Filter_Statistic = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsRequest.Filter.Statistic",
  () => [
    { no: 1, name: "period", kind: "message", T: TimeRange$1 },
    { no: 2, name: "depositPeriod", kind: "message", T: TimeRange$1 },
    { no: 3, name: "depositCount", kind: "message", T: SumRange },
    { no: 4, name: "depositSum", kind: "message", T: SumRange },
    { no: 5, name: "withdrawalPeriod", kind: "message", T: TimeRange$1 },
    { no: 6, name: "withdrawalCount", kind: "message", T: SumRange },
    { no: 7, name: "withdrawalSum", kind: "message", T: SumRange },
    { no: 8, name: "isBets", kind: "message", T: BoolValue },
    { no: 9, name: "betCountTotal", kind: "message", T: SumRange },
    { no: 10, name: "betCountCasino", kind: "message", T: SumRange },
    { no: 11, name: "betCountSport", kind: "message", T: SumRange },
    { no: 12, name: "betCountCasinoLive", kind: "message", T: SumRange },
    { no: 13, name: "betSumTotal", kind: "message", T: SumRange },
    { no: 14, name: "betSumCasino", kind: "message", T: SumRange },
    { no: 15, name: "betSumSport", kind: "message", T: SumRange },
    { no: 16, name: "betSumCasinoLive", kind: "message", T: SumRange },
    { no: 17, name: "profitSumTotal", kind: "message", T: SumRange },
    { no: 18, name: "profitSumCasino", kind: "message", T: SumRange },
    { no: 19, name: "profitSumSport", kind: "message", T: SumRange },
    { no: 20, name: "profitSumCasinoVirtual", kind: "message", T: SumRange },
    { no: 22, name: "profitSumCasinoLive", kind: "message", T: SumRange },
    { no: 23, name: "profitSumCasinoTv", kind: "message", T: SumRange },
    { no: 24, name: "depositChargebackPeriod", kind: "message", T: TimeRange$1 },
    { no: 25, name: "depositChargebackCount", kind: "message", T: SumRange },
    { no: 26, name: "depositChargebackSum", kind: "message", T: SumRange },
  ],
  {localName: "ListPlayerStatisticsRequest_Filter_Statistic"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic
 */
export const PlayerStatistic = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic",
  () => [
    { no: 1, name: "profile", kind: "message", T: PlayerStatistic_Profile },
    { no: 2, name: "statistic", kind: "message", T: PlayerStatistic_Statistic },
    { no: 3, name: "convertedStatistic", kind: "message", T: PlayerStatistic_Statistic },
  ],
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Profile
 */
export const PlayerStatistic_Profile = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Profile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "project", kind: "enum", T: proto3.getEnumType(Project$1) },
    { no: 3, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "middleName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "isFullProfile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "isProbablyGood", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "isHacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "gender", kind: "enum", T: proto3.getEnumType(Gender$1) },
    { no: 19, name: "location", kind: "message", T: Location },
    { no: 20, name: "activity", kind: "message", T: PlayerStatistic_Profile_Activity },
    { no: 21, name: "status", kind: "message", T: Status },
    { no: 22, name: "block", kind: "message", T: Block },
    { no: 23, name: "verification", kind: "message", T: PlayerStatistic_Profile_Verification },
    { no: 24, name: "autoWithdrawal", kind: "message", T: AutoWithdrawal },
    { no: 25, name: "affiliate", kind: "message", T: PlayerStatistic_Profile_Affiliate },
    { no: 26, name: "restriction", kind: "message", T: PlayerStatistic_Profile_Restriction },
    { no: 27, name: "balance", kind: "message", T: SumCurrency },
    { no: 28, name: "convertedBalance", kind: "message", T: SumCurrency },
    { no: 29, name: "registeredAt", kind: "message", T: Timestamp },
    { no: 30, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 31, name: "birthday", kind: "message", T: Timestamp },
    { no: 32, name: "pincoinBalance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 33, name: "sportVersion", kind: "message", T: PlayerStatistic_Profile_SportVersionData },
  ],
  {localName: "PlayerStatistic_Profile"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Profile.Affiliate
 */
export const PlayerStatistic_Profile_Affiliate = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Profile.Affiliate",
  () => [
    { no: 1, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "instIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "PlayerStatistic_Profile_Affiliate"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Profile.Verification
 */
export const PlayerStatistic_Profile_Verification = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Profile.Verification",
  () => [
    { no: 1, name: "status", kind: "message", T: AccountStatus },
    { no: 2, name: "taxId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "docNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "PlayerStatistic_Profile_Verification"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Profile.Activity
 */
export const PlayerStatistic_Profile_Activity = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Profile.Activity",
  () => [
    { no: 1, name: "firstDepositTime", kind: "message", T: Timestamp },
    { no: 2, name: "lastLoginTime", kind: "message", T: Timestamp },
    { no: 3, name: "lastBetTime", kind: "message", T: Timestamp },
    { no: 4, name: "lastCasinoBetTime", kind: "message", T: Timestamp },
    { no: 5, name: "lastSportBetTime", kind: "message", T: Timestamp },
    { no: 6, name: "lastDepositTime", kind: "message", T: Timestamp },
    { no: 7, name: "lastDepositCasinoTime", kind: "message", T: Timestamp },
    { no: 8, name: "lastDepositSportTime", kind: "message", T: Timestamp },
  ],
  {localName: "PlayerStatistic_Profile_Activity"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Profile.Restriction
 */
export const PlayerStatistic_Profile_Restriction = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Profile.Restriction",
  () => [
    { no: 1, name: "isRestriction", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "isRealCasino", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "isRealSport", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "isBonusCasino", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "isBonusSport", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "PlayerStatistic_Profile_Restriction"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Profile.SportVersionData
 */
export const PlayerStatistic_Profile_SportVersionData = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Profile.SportVersionData",
  () => [
    { no: 1, name: "registration", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "PlayerStatistic_Profile_SportVersionData"},
);

/**
 * @generated from message stat.bo.players.PlayerStatistic.Statistic
 */
export const PlayerStatistic_Statistic = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.PlayerStatistic.Statistic",
  () => [
    { no: 1, name: "depositCountTotal", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "depositSumTotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "withdrawalCountTotal", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "withdrawalSumTotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "betCountTotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "betSumTotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "betCountSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "betSumSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "betCalcCountSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "betCalcSumSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "betCountCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "betSumCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "betCountCasinoVirtual", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "betSumCasinoVirtual", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "betCountCasinoLive", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "betSumCasinoLive", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 17, name: "betCountCasinoTv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 18, name: "betSumCasinoTv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 19, name: "profitTotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "profitSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 21, name: "profitCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "profitCasinoVirtual", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 23, name: "profitCasinoLive", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 24, name: "profitCasinoTv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 25, name: "profitTotalPercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 26, name: "profitCasinoPercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "profitSportPercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 28, name: "activeDaysTotalCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 29, name: "activeDaysCasinoCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 30, name: "activeDaysSportCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 31, name: "bonusAddCountCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 32, name: "bonusAddSumCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 33, name: "bonusWageCountCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 34, name: "bonusWageSumCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 35, name: "bonusAddCountSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 36, name: "bonusAddSumSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 37, name: "bonusWageCountSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 38, name: "bonusWageSumSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 39, name: "freebetAddCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 40, name: "freebetAddSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 41, name: "freebetWinCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 42, name: "freebetWinSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 43, name: "freespinAddCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 44, name: "freespinAddSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 45, name: "freespinWinCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 46, name: "freespinWinSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 47, name: "lotteryAddCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 48, name: "lotteryRealWinCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 49, name: "lotteryRealWinSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 50, name: "lotteryBonusWinCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 51, name: "lotteryBonusWinSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 52, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 53, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 54, name: "generalRealFreeDepositSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 55, name: "depositChargebackCountTotal", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 56, name: "depositChargebackSumTotal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "PlayerStatistic_Statistic"},
);

/**
 * @generated from message stat.bo.players.ListPlayerStatisticsResponse
 */
export const ListPlayerStatisticsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayerStatisticsResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: PlayerStatistic, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: InfinityPaginationResponse },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayersTaxDetailsRequest
 */
export const ListPlayersTaxDetailsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersTaxDetailsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message stat.bo.players.ListPlayersTaxDetailsResponse
 */
export const ListPlayersTaxDetailsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.ListPlayersTaxDetailsResponse",
  () => [
    { no: 1, name: "taxDetails", kind: "message", T: DepositWithdrawalTaxDetail, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message stat.bo.players.DepositWithdrawalTaxDetail
 */
export const DepositWithdrawalTaxDetail = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.players.DepositWithdrawalTaxDetail",
  () => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(TaxAction) },
    { no: 3, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "taxSum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

