import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  inject,
  input,
} from '@angular/core';

import { RadioToggleBarComponent } from '../radio-toggle-bar/radio-toggle-bar.component';

@Component({
  selector: 'button[bo-radio-toggle-item]',
  standalone: true,
  template: `
    <ng-content />
  `,
  styleUrl: './radio-toggle-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'text-label',
    '[disabled]': 'isDisabled()',
    '[class.selected]': 'isSelected()',
  },
})
export class RadioToggleItemComponent<T> {
  public value = input.required<T>();

  protected isSelected = computed(() => {
    return this.toggleBar.selectedValue() === this.value();
  });
  protected isDisabled = computed(() => this.toggleBar.disabled());

  protected readonly toggleBar = inject(RadioToggleBarComponent);

  @HostListener('click')
  protected handleClick(): void {
    this.toggleBar.toggleSelection(this.value());
  }
}
