import { Directive, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, fromEvent, merge, takeUntil } from 'rxjs';

import { resizeObservable } from './rxjs-operators/resize-observable.operator';

const eventNames = ['wheel', 'keydown', 'mousedown'];

/**
 * Scrolls to the element specified in the route fragment each time
 * when the container height has changed until the first user interaction
 */
@Directive({
  selector: '[puScrollOnResize]',
  standalone: true,
})
export class ScrollOnResizeDirective {
  private readonly route = inject(ActivatedRoute);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  constructor() {
    const eventObservables = eventNames.map((eventName) =>
      fromEvent(this.elementRef.nativeElement, eventName),
    );
    const interaction$ = merge(...eventObservables);

    resizeObservable(this.elementRef.nativeElement)
      .pipe(debounceTime(0), takeUntil(interaction$), takeUntilDestroyed())
      .subscribe(() => {
        this.scrollByFragment();
      });
  }

  private scrollByFragment(): void {
    const fragment = this.route.snapshot.fragment;
    const targetEl = this.elementRef.nativeElement.querySelector(
      `#${fragment}`,
    );

    targetEl?.scrollIntoView({ block: 'start' });
  }
}
