import type { ViewportScroller } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

/**
 * Manages the scroll position for a browser window.
 * Brief explanation to implement the custom class: Currently when we try
 * to navigate on pages through fragment we're not able to use such
 * css-properties as 'scroll-margin-top' since the 'scrollToAnchor'
 * uses 'this.scrollToElement(elSelected); -> this.window.scrollTo(...);'
 * So the main idea to implement this custom class to manage scroll behavior -
 * change the following code 'this.scrollToElement(elSelected); -> elSelected.scrollIntoView();'
 * You can find the native approach of this class (BrowserViewportScroller) here
 *
 * @see https://github.com/angular/angular/blob/main/packages/common/src/viewport_scroller.ts#L69
 */
@Injectable({ providedIn: 'root' })
export class PaddingTolerantViewportScroller implements ViewportScroller {
  private readonly document = inject(DOCUMENT);
  private readonly window = inject(WINDOW);

  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  private offset: () => [number, number] = () => [0, 0];

  public setOffset(offset: (() => [number, number]) | [number, number]): void {
    if (Array.isArray(offset)) {
      this.offset = () => offset;
    } else {
      this.offset = offset;
    }
  }

  public getScrollPosition(): [number, number] {
    return [this.window.scrollX, this.window.scrollY];
  }

  public scrollToPosition(position: [number, number]): void {
    this.window.scrollTo(position[0], position[1]);
  }

  public scrollToAnchor(target: string, options?: ScrollIntoViewOptions): void {
    const elSelected = findAnchorFromDocument(this.document, target);

    if (elSelected) {
      elSelected.scrollIntoView(options);
      elSelected.focus();
    }
  }

  public setHistoryScrollRestoration(
    scrollRestoration: 'auto' | 'manual',
  ): void {
    this.window.history.scrollRestoration = scrollRestoration;
  }
}

/**
 * This function was implemented based on the angular sources
 *
 * @see https://github.com/angular/angular/blob/main/packages/common/src/viewport_scroller.ts#L155
 */
function findAnchorFromDocument(
  document: Document,
  target: string,
): HTMLElement | null {
  const documentResult =
    document.getElementById(target) || document.getElementsByName(target)[0];

  if (documentResult) {
    return documentResult;
  }

  // `getElementById` and `getElementsByName` won't pierce through the shadow DOM so we
  // have to traverse the DOM manually and do the lookup through the shadow roots.
  if (
    typeof document.createTreeWalker === 'function' &&
    document.body &&
    typeof document.body.attachShadow === 'function'
  ) {
    const treeWalker = document.createTreeWalker(
      document.body,
      NodeFilter.SHOW_ELEMENT,
    );
    let currentNode = treeWalker.currentNode as HTMLElement | null;

    while (currentNode) {
      const shadowRoot = currentNode.shadowRoot;

      if (shadowRoot) {
        // Note that `ShadowRoot` doesn't support `getElementsByName`
        // so we have to fall back to `querySelector`.
        const result =
          shadowRoot.getElementById(target) ||
          shadowRoot.querySelector(`[name="${target}"]`);
        if (result) {
          return result;
        }
      }

      currentNode = treeWalker.nextNode() as HTMLElement | null;
    }
  }

  return null;
}
