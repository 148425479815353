// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/base/filtering.proto (package pinup.stat.report.base, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, Int64Value, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.stat.report.base.SumRange
 */
export const SumRange = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.SumRange",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "from", kind: "message", T: Int64Value },
    { no: 3, name: "to", kind: "message", T: Int64Value },
  ],
);

/**
 * Uint64Multiselect is a message for filtering by multiple uint64 values
 *
 * @generated from message pinup.stat.report.base.Uint64Multiselect
 */
export const Uint64Multiselect = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.Uint64Multiselect",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "excludedIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * StringMultiselect is a message for filtering by multiple string values
 *
 * @generated from message pinup.stat.report.base.StringMultiselect
 */
export const StringMultiselect = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.StringMultiselect",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "excludedIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * DurationRange is a message for filtering by a range of durations
 *
 * @generated from message pinup.stat.report.base.DurationRange
 */
export const DurationRange = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.base.DurationRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Duration },
    { no: 2, name: "to", kind: "message", T: Duration },
  ],
);

