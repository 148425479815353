import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { SmartDialogOptions } from '@pu/sdk';
import { provideValue, SmartDialog } from '@pu/sdk';

import type { ConfirmDialogOptions } from './confirm-dialog.component';
import {
  CONFIRM_DIALOG_DATA,
  ConfirmDialogComponent,
} from './confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private readonly dialog = inject(SmartDialog);

  public confirm(
    confirmHandler: Observable<unknown>,
    {
      providers = [],
      title = '',
      description = null,
      confirmButtonText = 'Ok',
    }: Partial<ConfirmDialogOptions> & SmartDialogOptions = {},
  ): Observable<unknown> {
    return this.dialog.open(ConfirmDialogComponent, [
      ...providers,
      provideValue(CONFIRM_DIALOG_DATA, {
        confirmHandler,
        title,
        description,
        confirmButtonText,
      }),
    ]);
  }
}
