import { inject, Injectable } from '@angular/core';
import type { Sorting } from '@pinup-grpc/base/sorting_pb';
import { PlayerPaymentMethodRemoteService } from '@pinup-grpc/services/bo/players/payment_methods_ng';
import type { GetPlayerPaymentMethodsResponse } from '@pinup-grpc/services/bo/players/payment_methods_pb';
import { GetPlayerPaymentMethodsRequest } from '@pinup-grpc/services/bo/players/payment_methods_pb';
import type { Observable } from 'rxjs';

import type { Pagination } from '@bo/common';

@Injectable()
export class PlayerPaymentMethodProviderService {
  private readonly playerPaymentMethodsService = inject(
    PlayerPaymentMethodRemoteService,
  );

  public getPlayerPaymentMethods(
    id: bigint,
    sorting: Sorting,
    { currentPage: page, pageSize }: Pagination,
  ): Observable<GetPlayerPaymentMethodsResponse> {
    return this.playerPaymentMethodsService.getPlayerPaymentMethods(
      new GetPlayerPaymentMethodsRequest({
        playerId: id,
        sorting: [
          {
            order: sorting.order,
            fieldName: sorting.fieldName,
          },
        ],
        pagination: { page, pageSize },
      }),
    );
  }
}
