<header class="filters-header">
  <h3 class="filters-title">Filter</h3>

  <ng-content select="[header]" />

  <bo-svg
    class="filters-hide-icon"
    (click)="hideFilters()"
    [size]="24"
    [unsafeSvgSource]="chevronHideIcon"
  />
</header>

<main class="filters-main-content">
  <form
    id="filters-form"
    class="form"
    (ngSubmit)="applyFilters.emit()"
    [formGroup]="form()"
  >
    <ng-content />
  </form>
</main>

<!--
  So far footer content is static since there's the same content for all page filters.
  Can be customized in the future if needed.
-->
<footer class="filters-footer">
  <div class="form-row">
    <button
      mat-flat-button
      color="primary"
      form="filters-form"
      type="submit"
      value="submit"
    >
      Search
    </button>

    <button mat-stroked-button>
      <bo-svg [size]="16" [unsafeSvgSource]="downloadIcon" />
      CSV
    </button>
  </div>
</footer>
