// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/kycaid.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ApiAvailabilityCheckRequest, AvailabilityCheckRequest, AvailabilityCheckResponse, CreateExternalVerificationRequest, CreateExternalVerificationResponse, ExternalVerificationCallbackRequest, ExternalVerificationCallbackResponse, GetAddressListRequest, GetAddressListResponse, GetAddressRequest, GetAddressResponse, GetApplicantByPlayerIDRequest, GetApplicantByPlayerIDResponse, GetApplicantListRequest, GetApplicantListResponse, GetApplicantRequest, GetApplicantResponse, GetDocumentImageRequest, GetDocumentImageResponse, GetDocumentListRequest, GetDocumentListResponse, GetDocumentRequest, GetDocumentResponse, GetFormURLRequest, GetFormURLResponse, GetIncomeRequest, GetIncomeResponse, GetSettingsRequest, GetSettingsResponse, GetVerificationListRequest, GetVerificationListResponse, GetVerificationLogListRequest, GetVerificationLogListResponse, GetVerificationLogRequest, GetVerificationLogResponse, GetVerificationRequest, GetVerificationResponse, UpdateSettingsRequest, UpdateSettingsResponse, VerificationCallbackRequest, VerificationCallbackResponse } from "./kycaid_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * kycaid - сервис для интеграции с kycaid. Обрабатывает заявки на верификацию
 *
 * BANK ID:
 *  - Игрок на фронте нажимает на кнопку "верифицироваться через банк айди"
 *  - Делается запрос на сервис kycaid для получения ссылки CreateExternalVerification для прохождения верфикации, статус игрока переводим в In Progress
 *  - При получении ссылки на верификацию создается заявка на верификацию (common/verification.proto Verification)
 *  - Игрока редиректит на полученный от партнеров url
 *  - На фронте запускается таймер. При попадании на страницу верификации с фронта идет запрос AvailabilityCheck. В зависимости от ответа показываются разные страницы
 *  - В случае если игрок получил ссылку и не воспользовался ею, по итечению срока заявки редис присылает коллбэк о том что ее нужно перевести в статус TIMEOUT
 *  - В случае успешного прохождения верификации - партнер присылает нам 2 запроса BankIDCallback():
 *      1й с типом VERIFICATION_STATUS_CHANGED, переводим статус игрока в In Progress и статус заявки в Pending
 *      2й с типом VERIFICATION_COMPLETED - обновляем данные игрока и указываем что игрок успешно верифицирован
 *  - сохраняем данные об апликанте, получить их можно с помощью GetApplicantList GetAddressList GetDocumentList
 *
 * Upload documents:
 *  - Игрок на фронте нажимает на кнопку "верифицироваться через загрузку документов"
 *  - Делается запрос на сервис kycaid для получения ссылки GetFormURL
 *  - Игроку на фронте показывается фрейм, интегрированный с помощью полученной url
 *  - Если игрок не загрузил документы, в следующих запросах GetFormURL будет отдавать старый линк на фрейм
 *  - Если игрок загрузил документы - партнер присылает нам 2 запроса DocumentsCallback():
 *      1й с типом VERIFICATION_STATUS_CHANGED, переводим статус игрока в In Progress и статус заявки в Pending, а так же запускаем таймер
 *      2й с типом VERIFICATION_COMPLETED - обновляем данные игрока и указываем что игрок успешно верифицирован
 *  - сохраняем данные об апликанте, получить их можно с помощью GetApplicantList GetAddressList GetDocumentList
 *
 * Будет ли приходить VERIFICATION_STATUS_CHANGED указывается в настройках формы.
 * При каждом изменении заявки на верификацию (common/verification.proto Verification) создается лог (common/verificationLog.proto VerificationLog)
 *
 * @generated from service kycaid.kycaid
 */
@Injectable({ providedIn: "root" })
export class kycaidRemoteService {
  public static readonly typeName = "kycaid.kycaid";

  public static readonly methods = {
    createExternalVerification: {
      name: "CreateExternalVerification",
      I: CreateExternalVerificationRequest,
      O: CreateExternalVerificationResponse,
      kind: MethodKind.Unary,
    },
    availabilityCheck: {
      name: "AvailabilityCheck",
      I: AvailabilityCheckRequest,
      O: AvailabilityCheckResponse,
      kind: MethodKind.Unary,
    },
    apiAvailabilityCheck: {
      name: "ApiAvailabilityCheck",
      I: ApiAvailabilityCheckRequest,
      O: AvailabilityCheckResponse,
      kind: MethodKind.Unary,
    },
    getFormURL: {
      name: "GetFormURL",
      I: GetFormURLRequest,
      O: GetFormURLResponse,
      kind: MethodKind.Unary,
    },
    bankIDCallback: {
      name: "BankIDCallback",
      I: ExternalVerificationCallbackRequest,
      O: ExternalVerificationCallbackResponse,
      kind: MethodKind.Unary,
    },
    documentsCallback: {
      name: "DocumentsCallback",
      I: VerificationCallbackRequest,
      O: VerificationCallbackResponse,
      kind: MethodKind.Unary,
    },
    taxIDCallback: {
      name: "TaxIDCallback",
      I: VerificationCallbackRequest,
      O: VerificationCallbackResponse,
      kind: MethodKind.Unary,
    },
    cDDCallback: {
      name: "CDDCallback",
      I: VerificationCallbackRequest,
      O: VerificationCallbackResponse,
      kind: MethodKind.Unary,
    },
    eDDCallback: {
      name: "EDDCallback",
      I: VerificationCallbackRequest,
      O: VerificationCallbackResponse,
      kind: MethodKind.Unary,
    },
    eDDWithoutCDDCallback: {
      name: "EDDWithoutCDDCallback",
      I: VerificationCallbackRequest,
      O: VerificationCallbackResponse,
      kind: MethodKind.Unary,
    },
    getVerification: {
      name: "GetVerification",
      I: GetVerificationRequest,
      O: GetVerificationResponse,
      kind: MethodKind.Unary,
    },
    getVerificationsList: {
      name: "GetVerificationsList",
      I: GetVerificationListRequest,
      O: GetVerificationListResponse,
      kind: MethodKind.Unary,
    },
    getVerificationLog: {
      name: "GetVerificationLog",
      I: GetVerificationLogRequest,
      O: GetVerificationLogResponse,
      kind: MethodKind.Unary,
    },
    getVerificationLogList: {
      name: "GetVerificationLogList",
      I: GetVerificationLogListRequest,
      O: GetVerificationLogListResponse,
      kind: MethodKind.Unary,
    },
    getApplicant: {
      name: "GetApplicant",
      I: GetApplicantRequest,
      O: GetApplicantResponse,
      kind: MethodKind.Unary,
    },
    getApplicantByPlayerID: {
      name: "GetApplicantByPlayerID",
      I: GetApplicantByPlayerIDRequest,
      O: GetApplicantByPlayerIDResponse,
      kind: MethodKind.Unary,
    },
    getApplicantList: {
      name: "GetApplicantList",
      I: GetApplicantListRequest,
      O: GetApplicantListResponse,
      kind: MethodKind.Unary,
    },
    getAddress: {
      name: "GetAddress",
      I: GetAddressRequest,
      O: GetAddressResponse,
      kind: MethodKind.Unary,
    },
    getAddressList: {
      name: "GetAddressList",
      I: GetAddressListRequest,
      O: GetAddressListResponse,
      kind: MethodKind.Unary,
    },
    getDocument: {
      name: "GetDocument",
      I: GetDocumentRequest,
      O: GetDocumentResponse,
      kind: MethodKind.Unary,
    },
    getIncome: {
      name: "GetIncome",
      I: GetIncomeRequest,
      O: GetIncomeResponse,
      kind: MethodKind.Unary,
    },
    getDocumentImage: {
      name: "GetDocumentImage",
      I: GetDocumentImageRequest,
      O: GetDocumentImageResponse,
      kind: MethodKind.Unary,
    },
    getDocumentList: {
      name: "GetDocumentList",
      I: GetDocumentListRequest,
      O: GetDocumentListResponse,
      kind: MethodKind.Unary,
    },
    getSettings: {
      name: "GetSettings",
      I: GetSettingsRequest,
      O: GetSettingsResponse,
      kind: MethodKind.Unary,
    },
    updateSettings: {
      name: "UpdateSettings",
      I: UpdateSettingsRequest,
      O: UpdateSettingsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * Endpoint is used only for UA licence.
   * HTTP POST /verifications/external/create -H "Authorization:{token}"
   * CreateExternalVerification - get url for verification via bank-id
   * make request to partner and return link, save verification data to cache and storage
   *
   * @generated from rpc kycaid.kycaid.CreateExternalVerification
   */
  public createExternalVerification(
    input: PartialMessage<CreateExternalVerificationRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreateExternalVerificationResponse> {
    return unary<CreateExternalVerificationRequest, CreateExternalVerificationResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.createExternalVerification,
      input,
      options,
    );
  }

  /**
   * Licence: KZ, MGA
   * HTTP GET /verifications/external/create/{ID:[0-9]+} -H "Authorization:{token}"
   * AvailabilityCheck - return is verification can be created
   *
   * @generated from rpc kycaid.kycaid.AvailabilityCheck
   */
  public availabilityCheck(
    input: PartialMessage<AvailabilityCheckRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AvailabilityCheckResponse> {
    return unary<AvailabilityCheckRequest, AvailabilityCheckResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.availabilityCheck,
      input,
      options,
    );
  }

  /**
   * Licence: UA
   * ApiAvailabilityCheck - returns which verification types are available for player to use.
   *
   * @generated from rpc kycaid.kycaid.ApiAvailabilityCheck
   */
  public apiAvailabilityCheck(
    input: PartialMessage<ApiAvailabilityCheckRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AvailabilityCheckResponse> {
    return unary<ApiAvailabilityCheckRequest, AvailabilityCheckResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.apiAvailabilityCheck,
      input,
      options,
    );
  }

  /**
   * For UA licence, this creates verification of type: "DOCUMENT", "TAX ID"
   * For MGA licence, this creates verification of type: "CDD", "EDD", "EDD_WITHOUT_CDD"
   * HTTP POST /verifications/external/form -H "Authorization:{token}"
   * GetFormURL - get a link to open a form with the ability to upload documents
   *
   * @generated from rpc kycaid.kycaid.GetFormURL
   */
  public getFormURL(
    input: PartialMessage<GetFormURLRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetFormURLResponse> {
    return unary<GetFormURLRequest, GetFormURLResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getFormURL,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/callback
   * BankIDCallback - receives a callback from a partner if the player has passed verification via bank id
   * update verification status based on the passed data and update the player if the verification successful
   *
   * @generated from rpc kycaid.kycaid.BankIDCallback
   */
  public bankIDCallback(
    input: PartialMessage<ExternalVerificationCallbackRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ExternalVerificationCallbackResponse> {
    return unary<ExternalVerificationCallbackRequest, ExternalVerificationCallbackResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.bankIDCallback,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/documents/callback
   * DocumentsCallback - receives a callback from a partner if player load document via form
   * update verification status based on the passed data and update the player if the verification successful
   *
   * @generated from rpc kycaid.kycaid.DocumentsCallback
   */
  public documentsCallback(
    input: PartialMessage<VerificationCallbackRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<VerificationCallbackResponse> {
    return unary<VerificationCallbackRequest, VerificationCallbackResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.documentsCallback,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/documents/taxid/callback
   * TaxIDCallback - receives a callback from a partner if player load tax id via form
   * if tax id is valid - update player field TaxID and TaxIDChecked, otherwise nothing
   *
   * @generated from rpc kycaid.kycaid.TaxIDCallback
   */
  public taxIDCallback(
    input: PartialMessage<VerificationCallbackRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<VerificationCallbackResponse> {
    return unary<VerificationCallbackRequest, VerificationCallbackResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.taxIDCallback,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/cdd/callback
   * CDDCallback - receives a callback from a partner if player load document from MGA license
   * update verification status based on the passed data and update the player if the verification successful
   *
   * @generated from rpc kycaid.kycaid.CDDCallback
   */
  public cDDCallback(
    input: PartialMessage<VerificationCallbackRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<VerificationCallbackResponse> {
    return unary<VerificationCallbackRequest, VerificationCallbackResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.cDDCallback,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/cdd/edd/callback
   * EDDCallback - receives a callback from a partner if player verified and have AML status < medium
   * update verification status to EDD and update the player if the verification successful
   * if verification not successful return verification status from IN_PROGRESS to VERIFIED(CDD)
   *
   * @generated from rpc kycaid.kycaid.EDDCallback
   */
  public eDDCallback(
    input: PartialMessage<VerificationCallbackRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<VerificationCallbackResponse> {
    return unary<VerificationCallbackRequest, VerificationCallbackResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.eDDCallback,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/edd/callback
   * EDDWithoutCDDCallback - receives a callback from a partner if player not verified and have AML status < medium
   * update verification status to EDD and update the player if the verification successful
   * if verification not successful return verification status from IN_PROGRESS to NO_RETRY(CDD)
   *
   * @generated from rpc kycaid.kycaid.EDDWithoutCDDCallback
   */
  public eDDWithoutCDDCallback(
    input: PartialMessage<VerificationCallbackRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<VerificationCallbackResponse> {
    return unary<VerificationCallbackRequest, VerificationCallbackResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.eDDWithoutCDDCallback,
      input,
      options,
    );
  }

  /**
   * HTTP GET /verifications/external/{ID:[0-9]+} -H "Authorization:{token}"
   * GetVerification - return data of verification
   *
   * @generated from rpc kycaid.kycaid.GetVerification
   */
  public getVerification(
    input: PartialMessage<GetVerificationRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetVerificationResponse> {
    return unary<GetVerificationRequest, GetVerificationResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getVerification,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/ -H "Authorization:{token}"
   * GetVerificationsList - return list of verification
   *
   * @generated from rpc kycaid.kycaid.GetVerificationsList
   */
  public getVerificationsList(
    input: PartialMessage<GetVerificationListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetVerificationListResponse> {
    return unary<GetVerificationListRequest, GetVerificationListResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getVerificationsList,
      input,
      options,
    );
  }

  /**
   * HTTP GET /verifications/external/logs/{ID:[0-9]+} -H "Authorization:{token}"
   * GetVerificationLog - return verification log by id
   *
   * @generated from rpc kycaid.kycaid.GetVerificationLog
   */
  public getVerificationLog(
    input: PartialMessage<GetVerificationLogRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetVerificationLogResponse> {
    return unary<GetVerificationLogRequest, GetVerificationLogResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getVerificationLog,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/logs/ -H "Authorization:{token}"
   * GetVerificationLogList - return list of logs by verification
   *
   * @generated from rpc kycaid.kycaid.GetVerificationLogList
   */
  public getVerificationLogList(
    input: PartialMessage<GetVerificationLogListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetVerificationLogListResponse> {
    return unary<GetVerificationLogListRequest, GetVerificationLogListResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getVerificationLogList,
      input,
      options,
    );
  }

  /**
   * HTTP GET /verifications/external/applicants/{ID:[0-9]+} -H "Authorization:{token}"
   * GetApplicant - return info about applicant by id
   *
   * @generated from rpc kycaid.kycaid.GetApplicant
   */
  public getApplicant(
    input: PartialMessage<GetApplicantRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetApplicantResponse> {
    return unary<GetApplicantRequest, GetApplicantResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getApplicant,
      input,
      options,
    );
  }

  /**
   * HTTP GET /verifications/external/applicants/player/{ID:[0-9]+} -H "Authorization:{token}"
   * GetApplicant - return info about applicant by id
   *
   * @generated from rpc kycaid.kycaid.GetApplicantByPlayerID
   */
  public getApplicantByPlayerID(
    input: PartialMessage<GetApplicantByPlayerIDRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetApplicantByPlayerIDResponse> {
    return unary<GetApplicantByPlayerIDRequest, GetApplicantByPlayerIDResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getApplicantByPlayerID,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/applicants/ -H "Authorization:{token}"
   * GetVerificationLogList - return list of applicant
   *
   * @generated from rpc kycaid.kycaid.GetApplicantList
   */
  public getApplicantList(
    input: PartialMessage<GetApplicantListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetApplicantListResponse> {
    return unary<GetApplicantListRequest, GetApplicantListResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getApplicantList,
      input,
      options,
    );
  }

  /**
   * HTTP GET /verifications/external/addresses/{ID:[0-9]+} -H "Authorization:{token}"
   * GetAddress - return address by id
   *
   * @generated from rpc kycaid.kycaid.GetAddress
   */
  public getAddress(
    input: PartialMessage<GetAddressRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAddressResponse> {
    return unary<GetAddressRequest, GetAddressResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getAddress,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/addresses/ -H "Authorization:{token}"
   * GetAddressList - return list of addresses
   *
   * @generated from rpc kycaid.kycaid.GetAddressList
   */
  public getAddressList(
    input: PartialMessage<GetAddressListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAddressListResponse> {
    return unary<GetAddressListRequest, GetAddressListResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getAddressList,
      input,
      options,
    );
  }

  /**
   * HTTP GET /verifications/external/documents/{ID:[0-9]+} -H "Authorization:{token}"
   * GetDocument - return document by id
   *
   * @generated from rpc kycaid.kycaid.GetDocument
   */
  public getDocument(
    input: PartialMessage<GetDocumentRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDocumentResponse> {
    return unary<GetDocumentRequest, GetDocumentResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getDocument,
      input,
      options,
    );
  }

  /**
   * NATS Req/reply by subj "pinup.product.%s.kycaid.player.documents.income.get"
   * GetIncome - looking for a player's document of the type "FINANCIAL_DOCUMENT" and return information from it
   *
   * @generated from rpc kycaid.kycaid.GetIncome
   */
  public getIncome(
    input: PartialMessage<GetIncomeRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetIncomeResponse> {
    return unary<GetIncomeRequest, GetIncomeResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getIncome,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/documents/image -H "Authorization:{token}"
   * GetDocumentImage - return document image by name
   *
   * @generated from rpc kycaid.kycaid.GetDocumentImage
   */
  public getDocumentImage(
    input: PartialMessage<GetDocumentImageRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDocumentImageResponse> {
    return unary<GetDocumentImageRequest, GetDocumentImageResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getDocumentImage,
      input,
      options,
    );
  }

  /**
   * HTTP POST /verifications/external/documents/ -H "Authorization:{token}"
   * GetDocumentList - return list of documents
   *
   * @generated from rpc kycaid.kycaid.GetDocumentList
   */
  public getDocumentList(
    input: PartialMessage<GetDocumentListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetDocumentListResponse> {
    return unary<GetDocumentListRequest, GetDocumentListResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getDocumentList,
      input,
      options,
    );
  }

  /**
   * Licence: UA
   * HTTP GET /verifications/external/settings -H "Authorization:{token}"
   * GetSettings - return settings
   *
   * @generated from rpc kycaid.kycaid.GetSettings
   */
  public getSettings(
    input: PartialMessage<GetSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetSettingsResponse> {
    return unary<GetSettingsRequest, GetSettingsResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.getSettings,
      input,
      options,
    );
  }

  /**
   * Licence: UA
   * HTTP POST /verifications/external/settings -H "Authorization:{token}"
   * UpdateSettings - update settings
   *
   * @generated from rpc kycaid.kycaid.UpdateSettings
   */
  public updateSettings(
    input: PartialMessage<UpdateSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateSettingsResponse> {
    return unary<UpdateSettingsRequest, UpdateSettingsResponse>(
      this.transport,
      kycaidRemoteService,
      kycaidRemoteService.methods.updateSettings,
      input,
      options,
    );
  }
}
