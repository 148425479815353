// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/product/backoffice_operator/base/base.proto (package pinup.product.backoffice_operator.base, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.product.backoffice_operator.base.Operator
 */
export const Operator = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.base.Operator",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sub", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "createdAt", kind: "message", T: Timestamp },
    { no: 8, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

