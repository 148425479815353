import { inject, InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs';

import { ACCESS_GROUPS } from './access-groups';
import { DepartmentLicenseLink } from './department-license-link';
import { SUPPORTED_DEPARTMENT_LICENSE_LINKS } from './supported-department-license-links';

export const AVAILABLE_DEPARTMENT_LICENSE_LINKS = new InjectionToken<
  Observable<readonly DepartmentLicenseLink[]>
>('AVAILABLE_DEPARTMENT_LICENSE_LINKS', {
  providedIn: 'root',
  factory: () => {
    const links = inject(SUPPORTED_DEPARTMENT_LICENSE_LINKS);

    return inject(ACCESS_GROUPS).pipe(
      map((groups) => DepartmentLicenseLink.onlyAvailable(links, groups)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  },
});
