// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/balance_history/balance_history.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { FindBalanceTransactionsRequest, FindBalanceTransactionsResponse, GetTransactionTypesRequest, GetTransactionTypesResponse } from "./balance_history_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.BalanceHistoryService
 */
@Injectable({ providedIn: "root" })
export class BalanceHistoryRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.BalanceHistoryService";

  public static readonly methods = {
    findBalanceTransactions: {
      name: "FindBalanceTransactions",
      I: FindBalanceTransactionsRequest,
      O: FindBalanceTransactionsResponse,
      kind: MethodKind.Unary,
    },
    getTransactionTypes: {
      name: "GetTransactionTypes",
      I: GetTransactionTypesRequest,
      O: GetTransactionTypesResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.BalanceHistoryService.FindBalanceTransactions
   */
  public findBalanceTransactions(
    input: PartialMessage<FindBalanceTransactionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindBalanceTransactionsResponse> {
    return unary<FindBalanceTransactionsRequest, FindBalanceTransactionsResponse>(
      this.transport,
      BalanceHistoryRemoteService,
      BalanceHistoryRemoteService.methods.findBalanceTransactions,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.BalanceHistoryService.GetTransactionTypes
   */
  public getTransactionTypes(
    input: PartialMessage<GetTransactionTypesRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetTransactionTypesResponse> {
    return unary<GetTransactionTypesRequest, GetTransactionTypesResponse>(
      this.transport,
      BalanceHistoryRemoteService,
      BalanceHistoryRemoteService.methods.getTransactionTypes,
      input,
      options,
    );
  }
}
