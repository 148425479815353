import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { SIDEBAR_COLLAPSED } from '@bo/common';

@Component({
  selector: 'bo-main-navigation',
  host: { '[class.collapsed]': 'collapsed()' },
  standalone: true,
  imports: [],
  templateUrl: './main-navigation.component.html',
  styleUrl: './main-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent {
  protected readonly collapsed = inject(SIDEBAR_COLLAPSED);
}
