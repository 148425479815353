import type { MaskitoOptions } from '@maskito/core';
import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/metadata.min.json';

import type { MaskedOptions } from '@pu/sdk';

function normalizePhone(value: string): string {
  const normalized = value.replace(/\D/g, '');
  return normalized ? `+${normalized}` : '';
}

export function maskedPhone(): MaskedOptions<string> {
  const options: Required<MaskitoOptions> = maskitoPhoneOptionsGenerator({
    metadata: metadata,
    strict: false,
    countryIsoCode: 'UA',
  });

  return {
    ...options,
    parse: normalizePhone,
    stringify: normalizePhone,
  };
}
