import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

export interface PinupAccessJwtPayload extends JwtPayload {
  email: string;
  picture: string;
  first_name: string;
  last_name: string;
  groups: readonly string[];
  user_id: string;
}

// TODO validation
export function parsePinupAccessJwt(
  token: string,
): Readonly<PinupAccessJwtPayload> {
  return jwtDecode<PinupAccessJwtPayload>(token);
}
