import type { EnvironmentProviders } from '@angular/core';
import { InjectionToken, makeEnvironmentProviders } from '@angular/core';

import { provideValue } from '@pu/sdk';

import type { FeatureFlagConfig } from './feature-flags.model';
import { FeatureFlagsService } from './feature-flags.service';
import type { FeatureFlagsFeatures } from './features/models';

export const FEATURE_FLAGS = new InjectionToken<FeatureFlagConfig[]>(
  'FEATURE_FLAGS',
);

export const provideFeatureFlags = (
  flags: FeatureFlagConfig[],
  ...features: FeatureFlagsFeatures[]
): EnvironmentProviders =>
  makeEnvironmentProviders([
    FeatureFlagsService,
    provideValue<FeatureFlagConfig[]>(FEATURE_FLAGS, flags),
    features.map(({ providers }) => providers),
  ]);
