import type { FactoryProvider } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PersistentData } from './data-persistence.model';
import { routeParamsStrategyFactory } from './strategy/route-params/route-params-strategy.providers';
import { storageStrategyFactory } from './strategy/storage/storage-strategy.providers';
import type { Strategy, StrategyFactory } from './strategy/strategy.model';

export const DATA_PERSISTENCE = new InjectionToken<Observable<PersistentData>>(
  'DATA_PERSISTENCE',
);
/**
 * @deprecated Will be removed after filters refactoring
 */
export const provideDataPersistence = (
  // Dynamic token is used for possibility to encapsulate raw data persistence at some
  // specific level. This allows to use different strategies for different persistence levels
  // (filters, pagination etc.) and avoid conflicts.
  token: InjectionToken<Observable<PersistentData>> = DATA_PERSISTENCE,
  strategy: Strategy = 'route-params',
): FactoryProvider => {
  let factory: StrategyFactory;

  switch (strategy) {
    case 'route-params':
      factory = routeParamsStrategyFactory;
      break;
    case 'storage':
      factory = storageStrategyFactory;
      break;
  }

  return { provide: token, useFactory: factory };
};
