<form
  suspense-area
  id="create-note-form"
  class="form"
  (ngSubmit)="suspense.run(send())"
  puDialogSuspense
  puInertSuspense
  puSuspense
  #form="ngForm"
  #suspense="puSuspense"
>
  <div mat-dialog-title>
    {{ data.mode === 'edit' ? 'Edit note' : 'Create note' }}
  </div>
  <pu-dialog-content>
    <mat-form-field class="field">
      <mat-label>Note</mat-label>
      <textarea
        [(ngModel)]="note.text"
        [puStrictMinLength]="2"
        cdkAutosizeMinRows="2"
        cdkTextareaAutosize
        matInput
        name="text"
        placeholder="Type here…"
        required
        #textField="ngModel"
      ></textarea>
      @if (textField | validationError; as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
    <mat-checkbox [(ngModel)]="note.isPinned" name="isPinned">
      Pin note
    </mat-checkbox>
  </pu-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="button"
      form="create-note-form"
      matDialogClose
      type="button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="form.invalid"
      color="primary"
      form="create-note-form"
      type="submit"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</form>
