// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/bo/achievement/v1/achievement.proto (package gamification.achievement_manager.service.bo.achievement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { CopyRequest, CopyResponse, CreateRequest, CreateResponse, GetRequest, GetResponse, ListEventRequest, ListEventResponse, ListRequest, ListResponse, PushActionRequest, PushActionResponse, UpdateRequest, UpdateResponse, UploadImageRequest, UploadImageResponse } from "./achievement_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * AchievementService:
 * ------------------------
 * Format: proto
 * client: back office (BO)
 * server: achievement-manager
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.achievement_manager.service.bo.achievement.v1.AchievementService
 */
@Injectable({ providedIn: "root" })
export class AchievementRemoteService {
  public static readonly typeName = "gamification.achievement_manager.service.bo.achievement.v1.AchievementService";

  public static readonly methods = {
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    copy: {
      name: "Copy",
      I: CopyRequest,
      O: CopyResponse,
      kind: MethodKind.Unary,
    },
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
    list: {
      name: "List",
      I: ListRequest,
      O: ListResponse,
      kind: MethodKind.Unary,
    },
    pushAction: {
      name: "PushAction",
      I: PushActionRequest,
      O: PushActionResponse,
      kind: MethodKind.Unary,
    },
    listEvent: {
      name: "ListEvent",
      I: ListEventRequest,
      O: ListEventResponse,
      kind: MethodKind.Unary,
    },
    uploadImage: {
      name: "UploadImage",
      I: UploadImageRequest,
      O: UploadImageResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * Create: add new achievement
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.Create
   */
  public create(
    input: PartialMessage<CreateRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreateResponse> {
    return unary<CreateRequest, CreateResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.create,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Copy: copy achievement
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.Copy
   */
  public copy(
    input: PartialMessage<CopyRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CopyResponse> {
    return unary<CopyRequest, CopyResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.copy,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Update: edit existing achievement
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.Update
   */
  public update(
    input: PartialMessage<UpdateRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateResponse> {
    return unary<UpdateRequest, UpdateResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.update,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * Get: get full achievement info
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.Get
   */
  public get(
    input: PartialMessage<GetRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetResponse> {
    return unary<GetRequest, GetResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.get,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * List: get all achievements
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.List
   */
  public list(
    input: PartialMessage<ListRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListResponse> {
    return unary<ListRequest, ListResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.list,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * PushAction: push action (delete,restore,publish,unpublish) for achievement update
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.PushAction
   */
  public pushAction(
    input: PartialMessage<PushActionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<PushActionResponse> {
    return unary<PushActionRequest, PushActionResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.pushAction,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * ListEvent: returns list of the events.
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.ListEvent
   */
  public listEvent(
    input: PartialMessage<ListEventRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListEventResponse> {
    return unary<ListEventRequest, ListEventResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.listEvent,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * UploadImage: upload image for achievement and return information of uploaded image.
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.bo.achievement.v1.AchievementService.UploadImage
   */
  public uploadImage(
    input: PartialMessage<UploadImageRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UploadImageResponse> {
    return unary<UploadImageRequest, UploadImageResponse>(
      this.transport,
      AchievementRemoteService,
      AchievementRemoteService.methods.uploadImage,
      input,
      options,
    );
  }
}
