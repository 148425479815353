// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file settings_manager/v1/user_settings/user.proto (package settings_manager.v1.user_settings, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetSettingRequest, GetSettingResponse, ListSettingsRequest, ListSettingsResponse } from "./user_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service settings_manager.v1.user_settings.SettingsUserService
 */
@Injectable({ providedIn: "root" })
export class SettingsUserRemoteService {
  public static readonly typeName = "settings_manager.v1.user_settings.SettingsUserService";

  public static readonly methods = {
    listSettings: {
      name: "ListSettings",
      I: ListSettingsRequest,
      O: ListSettingsResponse,
      kind: MethodKind.Unary,
    },
    getSetting: {
      name: "GetSetting",
      I: GetSettingRequest,
      O: GetSettingResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   *
   * ListSettings returns a list of settings for a specific group.
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - NOT_FOUND: group or settings not found
   * - STORAGE_TIMEOUT: internal error
   * - STORAGE_NETWORK: internal error
   * - STORAGE_DISCONNECTED: internal error
   *
   * @generated from rpc settings_manager.v1.user_settings.SettingsUserService.ListSettings
   */
  public listSettings(
    input: PartialMessage<ListSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListSettingsResponse> {
    return unary<ListSettingsRequest, ListSettingsResponse>(
      this.transport,
      SettingsUserRemoteService,
      SettingsUserRemoteService.methods.listSettings,
      input,
      options,
    );
  }

  /**
   *
   * GetSetting returns a specific setting for a specific group.
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - SETTING_ID_EMPTY: setting id is empty
   * - SETTING_ID_CONTAINS_SPACES: setting id contains spaces
   * - NOT_FOUND: group or setting not found
   * - STORAGE_TIMEOUT: internal error
   * - STORAGE_NETWORK: internal error
   * - STORAGE_DISCONNECTED: internal error
   *
   * @generated from rpc settings_manager.v1.user_settings.SettingsUserService.GetSetting
   */
  public getSetting(
    input: PartialMessage<GetSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetSettingResponse> {
    return unary<GetSettingRequest, GetSettingResponse>(
      this.transport,
      SettingsUserRemoteService,
      SettingsUserRemoteService.methods.getSetting,
      input,
      options,
    );
  }
}
