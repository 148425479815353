import type { SortDirection } from '@angular/material/sort';
import { Sorting_Order as BaseSortingOrder } from '@pinup-grpc/base/sorting_pb';
import type { Sorting_Order as StdSortingOrder } from '@pinup-grpc/pinup/std/query/v1/sorting_pb';

import type { SortName } from '@bo/common';

type SortingOrder = BaseSortingOrder | StdSortingOrder;

export type ToGrpcSortNameFn<T extends SortingOrder> = (
  sortName: SortName,
) => T;

export type SortingPayload = Readonly<{
  sortName: SortName;
  fieldName: string;
}>;

export type SortingBaseModel<O extends SortingOrder> = Readonly<{
  fieldName: string;
  order: O;
}>;

export const toGrpcSorting = <O extends SortingOrder>(
  toGrpcSortNameFn: ToGrpcSortNameFn<O>,
  sorting: SortingPayload[] | SortName,
  field?: string,
): SortingBaseModel<O>[] => {
  if (Array.isArray(sorting)) {
    return sorting.map(({ sortName, fieldName }) => ({
      order: toGrpcSortNameFn(sortName),
      fieldName,
    }));
  }

  if (field) {
    return [{ order: toGrpcSortNameFn(sorting), fieldName: field }];
  }

  return [];
};

export function toSortingOrder(direction: SortDirection): BaseSortingOrder {
  switch (direction) {
    case 'desc':
      return BaseSortingOrder.DESC;
    default:
      return BaseSortingOrder.ASC;
  }
}
