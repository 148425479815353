import type { WritableSignal } from '@angular/core';
import { inject, Injectable } from '@angular/core';

import type { FeatureFlag } from './feature-flags.model';
import { FEATURE_FLAGS } from './feature-flags.providers';
import { FEATURE_FLAGS_ADAPTER } from './features/adapter/feature-flags-adapter.providers';

@Injectable()
export class FeatureFlagsService {
  private readonly adapter = inject(FEATURE_FLAGS_ADAPTER);
  private readonly flagsConfig = inject(FEATURE_FLAGS);
  private readonly store = this.createStore();

  public getSignal(flag: FeatureFlag): WritableSignal<boolean> {
    const signal = this.store.get(flag);

    if (!signal) {
      throw new Error(
        `Feature ${flag} is not activated. Add ${flag} to the config`,
      );
    }

    return signal;
  }

  public getValue(flag: FeatureFlag): boolean {
    const signal = this.getSignal(flag);

    return signal();
  }

  public enable(flag: FeatureFlag): void {
    const signal = this.getSignal(flag);

    signal.set(true);
  }

  public disable(flag: FeatureFlag): void {
    const signal = this.getSignal(flag);

    signal.set(false);
  }

  private createFlagSignal(
    flag: FeatureFlag,
    defaultValue: boolean,
  ): WritableSignal<boolean> {
    return this.adapter.createSignal({ flag, defaultValue });
  }

  private createStore(): Map<FeatureFlag, WritableSignal<boolean>> {
    return this.flagsConfig.reduce((store, { flag, defaultValue }) => {
      const signal = this.createFlagSignal(flag, defaultValue);

      store.set(flag, signal);

      return store;
    }, new Map<FeatureFlag, WritableSignal<boolean>>([]));
  }
}
