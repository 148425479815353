// @generated by protoc-gen-es v1.10.0
// @generated from file base/frontend/v1/achievement.proto (package gamification.achievement_manager.base.frontend.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of message for achievement
 *
 * @generated from message gamification.achievement_manager.base.frontend.v1.Achievement
 */
export const Achievement = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.base.frontend.v1.Achievement",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "recurrence_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "is_viewed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "progress", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

