import type { EnvironmentProviders } from '@angular/core';
import {
  ENVIRONMENT_INITIALIZER,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { provideMultiValue } from '@pu/sdk';
import { ACCESS_PAYLOAD, License } from '@bo/common';

import {
  provideDevGtmClient,
  provideProdGtmClient,
} from './client/gtm-client.providers';

const USER_KEY = 'user';
const LICENSE_KEY = 'license';

export function provideGtmInfo(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideMultiValue(ENVIRONMENT_INITIALIZER, () => {
      inject(ACCESS_PAYLOAD)
        .pipe(takeUntilDestroyed())
        .subscribe(({ email, groups }) => {
          localStorage.setItem(
            USER_KEY,
            JSON.stringify({ email, role: groups }),
          );
        });

      sessionStorage.setItem(LICENSE_KEY, inject(License).url);
    }),
  ]);
}

export function provideGtmInfoEmpty(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideMultiValue(ENVIRONMENT_INITIALIZER, () => {
      localStorage.removeItem(USER_KEY);
      sessionStorage.removeItem(LICENSE_KEY);
    }),
  ]);
}

export const provideDevGtm = (): EnvironmentProviders =>
  makeEnvironmentProviders([provideDevGtmClient(), provideGtmInfo()]);

export const provideProdGtm = (): EnvironmentProviders =>
  makeEnvironmentProviders([provideProdGtmClient(), provideGtmInfo()]);
