// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/frontend/player_achievement/v1/player_achievement.proto (package gamification.achievement_manager.service.frontend.player_achievement.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetAllByPlayerIDRequest, GetAllByPlayerIDResponse, GetAllRequest, GetAllResponse, MarkAsViewedRequest, MarkAsViewedResponse } from "./player_achievement_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * ------------------------
 * PlayerAchievementService:
 * ------------------------
 * Format: proto
 * client: frontend
 * server: achievement-manager
 * Access:
 *   gRPC
 * ---------------------------------------------------------------------------------
 *
 * @generated from service gamification.achievement_manager.service.frontend.player_achievement.v1.PlayerAchievementService
 */
@Injectable({ providedIn: "root" })
export class PlayerAchievementRemoteService {
  public static readonly typeName = "gamification.achievement_manager.service.frontend.player_achievement.v1.PlayerAchievementService";

  public static readonly methods = {
    getAll: {
      name: "GetAll",
      I: GetAllRequest,
      O: GetAllResponse,
      kind: MethodKind.Unary,
    },
    markAsViewed: {
      name: "MarkAsViewed",
      I: MarkAsViewedRequest,
      O: MarkAsViewedResponse,
      kind: MethodKind.Unary,
    },
    getAllByPlayerID: {
      name: "GetAllByPlayerID",
      I: GetAllByPlayerIDRequest,
      O: GetAllByPlayerIDResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ------------------------
   * GetAll: get achievements information (received and available) for authorized player
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.frontend.player_achievement.v1.PlayerAchievementService.GetAll
   */
  public getAll(
    input: PartialMessage<GetAllRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAllResponse> {
    return unary<GetAllRequest, GetAllResponse>(
      this.transport,
      PlayerAchievementRemoteService,
      PlayerAchievementRemoteService.methods.getAll,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * MarkAsViewed: marks the specified Achievement as already viewed by the authorized player
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.frontend.player_achievement.v1.PlayerAchievementService.MarkAsViewed
   */
  public markAsViewed(
    input: PartialMessage<MarkAsViewedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<MarkAsViewedResponse> {
    return unary<MarkAsViewedRequest, MarkAsViewedResponse>(
      this.transport,
      PlayerAchievementRemoteService,
      PlayerAchievementRemoteService.methods.markAsViewed,
      input,
      options,
    );
  }

  /**
   * ------------------------
   * GetAllByPlayerID: get achievements information (with non-zero progress) for authorized player about another player
   * ------------------------
   *
   * @generated from rpc gamification.achievement_manager.service.frontend.player_achievement.v1.PlayerAchievementService.GetAllByPlayerID
   */
  public getAllByPlayerID(
    input: PartialMessage<GetAllByPlayerIDRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAllByPlayerIDResponse> {
    return unary<GetAllByPlayerIDRequest, GetAllByPlayerIDResponse>(
      this.transport,
      PlayerAchievementRemoteService,
      PlayerAchievementRemoteService.methods.getAllByPlayerID,
      input,
      options,
    );
  }
}
