// @generated by protoc-gen-es v1.10.0
// @generated from file services/health/api_gw.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.health, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.health.LivenessRequest
 */
export const LivenessRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.health.LivenessRequest",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.health.LivenessResponse
 */
export const LivenessResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.health.LivenessResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthRequest
 */
export const HealthRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthRequest",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthResponse
 */
export const HealthResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.health.HealthResponse",
  [],
);

