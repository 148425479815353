// @generated by protoc-gen-es v1.10.0
// @generated from file models/pagination/pagination.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message payments.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.PaginationRequest",
  () => [
    { no: 1, name: "part", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message payments.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.PaginationResponse",
  () => [
    { no: 1, name: "part", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "totalParts", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

