// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/stat/report/withdrawals/withdrawals.proto (package pinup.stat.report.withdrawals, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ListRequestsRequest, ListRequestsResponse, ListTransfersRequest, ListTransfersResponse } from "./withdrawals_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * Service that provides operations related to withdrawal requests and transfers.
 *
 * @generated from service pinup.stat.report.withdrawals.WithdrawalService
 */
@Injectable({ providedIn: "root" })
export class WithdrawalRemoteService {
  public static readonly typeName = "pinup.stat.report.withdrawals.WithdrawalService";

  public static readonly methods = {
    listRequests: {
      name: "ListRequests",
      I: ListRequestsRequest,
      O: ListRequestsResponse,
      kind: MethodKind.Unary,
    },
    listTransfers: {
      name: "ListTransfers",
      I: ListTransfersRequest,
      O: ListTransfersResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * Retrieves a list of withdrawal requests based on the specified filters.
   * The request supports various filters such as player ID, withdrawal status, amount,
   * payment method, risk-related criteria, and more. Pagination and sorting options
   * are also available to control the result set using google.protobuf.FieldMask.
   *
   * @generated from rpc pinup.stat.report.withdrawals.WithdrawalService.ListRequests
   */
  public listRequests(
    input: PartialMessage<ListRequestsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListRequestsResponse> {
    return unary<ListRequestsRequest, ListRequestsResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.listRequests,
      input,
      options,
    );
  }

  /**
   * Retrieves a list of transfer requests based on the specified criteria.
   * The request may include various filters to narrow down the results, such as
   * transfer status, amount, involved accounts, and timestamps.
   * The response will include the filtered list of transfers along with
   * any applicable pagination and sorting information.
   *
   * @generated from rpc pinup.stat.report.withdrawals.WithdrawalService.ListTransfers
   */
  public listTransfers(
    input: PartialMessage<ListTransfersRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListTransfersResponse> {
    return unary<ListTransfersRequest, ListTransfersResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.listTransfers,
      input,
      options,
    );
  }
}
