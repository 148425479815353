import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'bo-svg',
  host: {
    '[style.--size.px]': 'size()',
    '[innerHTML]': 'svg()',
  },
  standalone: true,
  template: '',
  styleUrl: './svg.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
  public unsafeSvgSource = input.required<string>();
  public size = input<number | null>(null);

  protected svg = computed(() =>
    this.sanitizer.bypassSecurityTrustHtml(this.unsafeSvgSource()),
  );

  protected readonly sanitizer = inject(DomSanitizer);
}
