// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/players/players.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { SetCPFRequest, SetCPFResponse, SetEmailRequest, SetEmailResponse, SetFirstNameRequest, SetFirstNameResponse, SetIsCertifiedRequest, SetIsCertifiedResponse, SetIsEmailVerifiedRequest, SetIsEmailVerifiedResponse, SetIsPhoneVerifiedRequest, SetIsPhoneVerifiedResponse, SetIsTestRequest, SetIsTestResponse, SetIsVerifiedRequest, SetIsVerifiedResponse, SetLastNameRequest, SetLastNameResponse, SetPhoneRequest, SetPhoneResponse, SetTwoFactorAuthRequest, SetTwoFactorAuthResponse, ToggleAutoWithdrawalRequest, ToggleAutoWithdrawalResponse } from "./players_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService";

  public static readonly methods = {
    toggleAutoWithdrawal: {
      name: "ToggleAutoWithdrawal",
      I: ToggleAutoWithdrawalRequest,
      O: ToggleAutoWithdrawalResponse,
      kind: MethodKind.Unary,
    },
    setIsTest: {
      name: "SetIsTest",
      I: SetIsTestRequest,
      O: SetIsTestResponse,
      kind: MethodKind.Unary,
    },
    setCPF: {
      name: "SetCPF",
      I: SetCPFRequest,
      O: SetCPFResponse,
      kind: MethodKind.Unary,
    },
    setIsVerified: {
      name: "SetIsVerified",
      I: SetIsVerifiedRequest,
      O: SetIsVerifiedResponse,
      kind: MethodKind.Unary,
    },
    setIsCertified: {
      name: "SetIsCertified",
      I: SetIsCertifiedRequest,
      O: SetIsCertifiedResponse,
      kind: MethodKind.Unary,
    },
    setFirstName: {
      name: "SetFirstName",
      I: SetFirstNameRequest,
      O: SetFirstNameResponse,
      kind: MethodKind.Unary,
    },
    setLastName: {
      name: "SetLastName",
      I: SetLastNameRequest,
      O: SetLastNameResponse,
      kind: MethodKind.Unary,
    },
    setEmail: {
      name: "SetEmail",
      I: SetEmailRequest,
      O: SetEmailResponse,
      kind: MethodKind.Unary,
    },
    setIsEmailVerified: {
      name: "SetIsEmailVerified",
      I: SetIsEmailVerifiedRequest,
      O: SetIsEmailVerifiedResponse,
      kind: MethodKind.Unary,
    },
    setPhone: {
      name: "SetPhone",
      I: SetPhoneRequest,
      O: SetPhoneResponse,
      kind: MethodKind.Unary,
    },
    setIsPhoneVerified: {
      name: "SetIsPhoneVerified",
      I: SetIsPhoneVerifiedRequest,
      O: SetIsPhoneVerifiedResponse,
      kind: MethodKind.Unary,
    },
    setTwoFactorAuth: {
      name: "SetTwoFactorAuth",
      I: SetTwoFactorAuthRequest,
      O: SetTwoFactorAuthResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.ToggleAutoWithdrawal
   */
  public toggleAutoWithdrawal(
    input: PartialMessage<ToggleAutoWithdrawalRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ToggleAutoWithdrawalResponse> {
    return unary<ToggleAutoWithdrawalRequest, ToggleAutoWithdrawalResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.toggleAutoWithdrawal,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetIsTest
   */
  public setIsTest(
    input: PartialMessage<SetIsTestRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsTestResponse> {
    return unary<SetIsTestRequest, SetIsTestResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsTest,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetCPF
   */
  public setCPF(
    input: PartialMessage<SetCPFRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetCPFResponse> {
    return unary<SetCPFRequest, SetCPFResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setCPF,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetIsVerified
   */
  public setIsVerified(
    input: PartialMessage<SetIsVerifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsVerifiedResponse> {
    return unary<SetIsVerifiedRequest, SetIsVerifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsVerified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetIsCertified
   */
  public setIsCertified(
    input: PartialMessage<SetIsCertifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsCertifiedResponse> {
    return unary<SetIsCertifiedRequest, SetIsCertifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsCertified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetFirstName
   */
  public setFirstName(
    input: PartialMessage<SetFirstNameRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetFirstNameResponse> {
    return unary<SetFirstNameRequest, SetFirstNameResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setFirstName,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetLastName
   */
  public setLastName(
    input: PartialMessage<SetLastNameRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetLastNameResponse> {
    return unary<SetLastNameRequest, SetLastNameResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setLastName,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetEmail
   */
  public setEmail(
    input: PartialMessage<SetEmailRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetEmailResponse> {
    return unary<SetEmailRequest, SetEmailResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setEmail,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetIsEmailVerified
   */
  public setIsEmailVerified(
    input: PartialMessage<SetIsEmailVerifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsEmailVerifiedResponse> {
    return unary<SetIsEmailVerifiedRequest, SetIsEmailVerifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsEmailVerified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetPhone
   */
  public setPhone(
    input: PartialMessage<SetPhoneRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetPhoneResponse> {
    return unary<SetPhoneRequest, SetPhoneResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setPhone,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetIsPhoneVerified
   */
  public setIsPhoneVerified(
    input: PartialMessage<SetIsPhoneVerifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsPhoneVerifiedResponse> {
    return unary<SetIsPhoneVerifiedRequest, SetIsPhoneVerifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsPhoneVerified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.admin_api_gw.services.players.PlayerService.SetTwoFactorAuth
   */
  public setTwoFactorAuth(
    input: PartialMessage<SetTwoFactorAuthRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetTwoFactorAuthResponse> {
    return unary<SetTwoFactorAuthRequest, SetTwoFactorAuthResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setTwoFactorAuth,
      input,
      options,
    );
  }
}
