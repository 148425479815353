// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/stat/report/geo/geo.proto (package pinup.stat.report.geo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Total } from "../../../std/query/v1/models_pb.js";
import { Period } from "../../../std/query/v1/filter_pb.js";
import { Total as Total$1 } from "../base/models_pb.js";
import { Period as Period$1 } from "../base/filter_pb.js";

/**
 * @generated from enum pinup.stat.report.geo.GroupBy
 */
export const GroupBy = /*@__PURE__*/ proto3.makeEnum(
  "pinup.stat.report.geo.GroupBy",
  [
    {no: 0, name: "GROUP_BY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GROUP_BY_DAY", localName: "DAY"},
    {no: 2, name: "GROUP_BY_WEEK", localName: "WEEK"},
    {no: 3, name: "GROUP_BY_MONTH", localName: "MONTH"},
    {no: 5, name: "GROUP_BY_ALL", localName: "ALL"},
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetRegionsRequest
 */
export const GetRegionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetRegionsRequest",
  [],
);

/**
 * @generated from message pinup.stat.report.geo.GetRegionsResponse
 */
export const GetRegionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetRegionsResponse",
  () => [
    { no: 1, name: "regions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.RealPlayers
 */
export const RealPlayers = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.RealPlayers",
  () => [
    { no: 1, name: "real", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "realSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "realCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "newRealSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "newRealCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.Registrations
 */
export const Registrations = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Registrations",
  () => [
    { no: 1, name: "casino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Deposit
 *
 * @generated from message pinup.stat.report.geo.Deposit
 */
export const Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Deposit",
  () => [
    { no: 1, name: "try", kind: "message", T: Total },
    { no: 2, name: "tryNd", kind: "message", T: Total },
    { no: 3, name: "real", kind: "message", T: Total },
    { no: 4, name: "admin", kind: "message", T: Total },
    { no: 5, name: "first", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.Bonus
 */
export const Bonus = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Bonus",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "wager", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.Lottery
 */
export const Lottery = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Lottery",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "realWin", kind: "message", T: Total },
    { no: 3, name: "bonusWin", kind: "message", T: Total },
  ],
);

/**
 * Bet
 *
 * @generated from message pinup.stat.report.geo.Bet
 */
export const Bet = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Bet",
  () => [
    { no: 2, name: "real", kind: "message", T: Total },
    { no: 3, name: "realRollback", kind: "message", T: Total },
    { no: 4, name: "bonus", kind: "message", T: Total },
    { no: 5, name: "bonusRollback", kind: "message", T: Total },
    { no: 6, name: "realCalc", kind: "message", T: Total },
    { no: 7, name: "realCalcRollback", kind: "message", T: Total },
    { no: 8, name: "bonusCalc", kind: "message", T: Total },
    { no: 9, name: "bonusCalcRollback", kind: "message", T: Total },
  ],
);

/**
 * Win
 *
 * @generated from message pinup.stat.report.geo.Win
 */
export const Win = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Win",
  () => [
    { no: 2, name: "real", kind: "message", T: Total },
    { no: 3, name: "realRollback", kind: "message", T: Total },
    { no: 4, name: "bonus", kind: "message", T: Total },
    { no: 5, name: "bonusRollback", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.Freebet
 */
export const Freebet = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Freebet",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "bet", kind: "message", T: Total },
    { no: 7, name: "betRollback", kind: "message", T: Total },
    { no: 3, name: "win", kind: "message", T: Total },
    { no: 4, name: "winRollback", kind: "message", T: Total },
    { no: 5, name: "winWithBetSum", kind: "message", T: Total },
    { no: 6, name: "winWithBetSumRollback", kind: "message", T: Total },
  ],
);

/**
 * Freespin - only casino
 *
 * @generated from message pinup.stat.report.geo.Freespin
 */
export const Freespin = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Freespin",
  () => [
    { no: 1, name: "add", kind: "message", T: Total },
    { no: 2, name: "win", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.Tournament
 */
export const Tournament = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Tournament",
  () => [
    { no: 2, name: "realWin", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetRegistrationRequest
 */
export const GetRegistrationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetRegistrationRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "period", kind: "message", T: Period },
    { no: 3, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetRegistrationResponse
 */
export const GetRegistrationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetRegistrationResponse",
  () => [
    { no: 1, name: "registrations", kind: "message", T: Registrations },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetRealPlayerRequest
 */
export const GetRealPlayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetRealPlayerRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "period", kind: "message", T: Period },
    { no: 3, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetRealPlayerResponse
 */
export const GetRealPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetRealPlayerResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: RealPlayers },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.Stat
 */
export const Stat = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Stat",
  () => [
    { no: 1, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "depositCasino", kind: "message", T: Deposit },
    { no: 9, name: "depositSport", kind: "message", T: Deposit },
    { no: 10, name: "moneyoutCasino", kind: "message", T: Total },
    { no: 11, name: "moneyoutSport", kind: "message", T: Total },
    { no: 12, name: "bonusCasino", kind: "message", T: Bonus },
    { no: 13, name: "bonusSport", kind: "message", T: Bonus },
    { no: 14, name: "betCasinos", kind: "message", T: Bet },
    { no: 15, name: "betSport", kind: "message", T: Bet },
    { no: 16, name: "winCasinos", kind: "message", T: Win },
    { no: 17, name: "winSport", kind: "message", T: Win },
    { no: 18, name: "lotteryCasino", kind: "message", T: Lottery },
    { no: 19, name: "lotterySport", kind: "message", T: Lottery },
    { no: 20, name: "freespin", kind: "message", T: Freespin },
    { no: 21, name: "freebet", kind: "message", T: Freebet },
    { no: 22, name: "tournamentCasino", kind: "message", T: Tournament },
    { no: 23, name: "tournamentSport", kind: "message", T: Tournament },
    { no: 24, name: "pincoinAdd", kind: "message", T: Total },
    { no: 25, name: "pincoinExchangeCasino", kind: "message", T: Total },
    { no: 26, name: "pincoinExchangeSport", kind: "message", T: Total },
    { no: 27, name: "transferCasinoToSport", kind: "message", T: Total },
    { no: 28, name: "transferSportToCasino", kind: "message", T: Total },
    { no: 29, name: "withdrawalRequestCasino", kind: "message", T: Total },
    { no: 30, name: "withdrawalRequestSport", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetStatRequest
 */
export const GetStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetStatRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: Period },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetStatResponse
 */
export const GetStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetStatResponse",
  () => [
    { no: 1, name: "stats", kind: "message", T: Stat, repeated: true },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.ProviderStatistic
 */
export const ProviderStatistic = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.ProviderStatistic",
  () => [
    { no: 1, name: "bet", kind: "message", T: Total },
    { no: 2, name: "bonusBet", kind: "message", T: Total },
    { no: 3, name: "betWin", kind: "message", T: Total },
    { no: 4, name: "bonusBetWin", kind: "message", T: Total },
    { no: 5, name: "freespinWin", kind: "message", T: Total },
    { no: 6, name: "commissionPercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "commissionBeforePercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "commission", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "system", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "gameTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "gameID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Full Report
 *
 * @generated from message pinup.stat.report.geo.Report
 */
export const Report = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.Report",
  () => [
    { no: 1, name: "groupBy", kind: "enum", T: proto3.getEnumType(GroupBy) },
    { no: 2, name: "date", kind: "message", T: Timestamp },
    { no: 3, name: "dateEnd", kind: "message", T: Timestamp },
    { no: 4, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "registrations", kind: "message", T: Registrations },
    { no: 7, name: "players", kind: "message", T: RealPlayers },
    { no: 8, name: "depositCasino", kind: "message", T: Deposit },
    { no: 9, name: "depositSport", kind: "message", T: Deposit },
    { no: 10, name: "moneyoutCasino", kind: "message", T: Total },
    { no: 11, name: "moneyoutSport", kind: "message", T: Total },
    { no: 12, name: "bonusCasino", kind: "message", T: Bonus },
    { no: 13, name: "bonusSport", kind: "message", T: Bonus },
    { no: 14, name: "betCasinos", kind: "message", T: Bet },
    { no: 15, name: "betSport", kind: "message", T: Bet },
    { no: 16, name: "winCasinos", kind: "message", T: Win },
    { no: 17, name: "winSport", kind: "message", T: Win },
    { no: 18, name: "lotteryCasino", kind: "message", T: Lottery },
    { no: 19, name: "lotterySport", kind: "message", T: Lottery },
    { no: 20, name: "freespin", kind: "message", T: Freespin },
    { no: 21, name: "freebet", kind: "message", T: Freebet },
    { no: 22, name: "tournamentCasino", kind: "message", T: Tournament },
    { no: 23, name: "tournamentSport", kind: "message", T: Tournament },
    { no: 24, name: "pincoinAdd", kind: "message", T: Total },
    { no: 25, name: "pincoinExchangeCasino", kind: "message", T: Total },
    { no: 26, name: "pincoinExchangeSport", kind: "message", T: Total },
    { no: 27, name: "transferCasinoToSport", kind: "message", T: Total },
    { no: 28, name: "transferSportToCasino", kind: "message", T: Total },
    { no: 29, name: "countIdentTries", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 30, name: "countIdentSuccess", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 31, name: "providerStatistic", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ProviderStatistic} },
    { no: 32, name: "withdrawalRequestCasino", kind: "message", T: Total },
    { no: 33, name: "withdrawalRequestSport", kind: "message", T: Total },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetFullReportRequest
 */
export const GetFullReportRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetFullReportRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: Period },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "groupBy", kind: "enum", T: proto3.getEnumType(GroupBy) },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GetFullReportResponse
 */
export const GetFullReportResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetFullReportResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: Report, repeated: true },
  ],
);

/**
 * Deprecated
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic
 */
export const GeoStatistic = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic",
  () => [
    { no: 30, name: "groupBy", kind: "enum", T: proto3.getEnumType(GroupBy) },
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 31, name: "dateEnd", kind: "message", T: Timestamp },
    { no: 2, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "registrations", kind: "message", T: GeoStatistic_Registrations },
    { no: 5, name: "players", kind: "message", T: GeoStatistic_Players },
    { no: 6, name: "depositCasino", kind: "message", T: GeoStatistic_Deposit },
    { no: 7, name: "depositSport", kind: "message", T: GeoStatistic_Deposit },
    { no: 8, name: "bonusCasino", kind: "message", T: GeoStatistic_Bonus },
    { no: 9, name: "bonusSport", kind: "message", T: GeoStatistic_Bonus },
    { no: 10, name: "transferCasinoToSport", kind: "message", T: Total$1 },
    { no: 11, name: "transferSportToCasino", kind: "message", T: Total$1 },
    { no: 12, name: "moneyoutCasino", kind: "message", T: Total$1 },
    { no: 13, name: "moneyoutSport", kind: "message", T: Total$1 },
    { no: 14, name: "pincoinAdd", kind: "message", T: Total$1 },
    { no: 15, name: "pincoinExchangeCasino", kind: "message", T: Total$1 },
    { no: 16, name: "pincoinExchangeSport", kind: "message", T: Total$1 },
    { no: 17, name: "betCasinos", kind: "message", T: GeoStatistic_Bet },
    { no: 18, name: "betSport", kind: "message", T: GeoStatistic_Bet },
    { no: 19, name: "winCasinos", kind: "message", T: GeoStatistic_Win },
    { no: 20, name: "winSport", kind: "message", T: GeoStatistic_Win },
    { no: 21, name: "lotteryCasino", kind: "message", T: GeoStatistic_Lottery },
    { no: 22, name: "lotterySport", kind: "message", T: GeoStatistic_Lottery },
    { no: 23, name: "freespin", kind: "message", T: GeoStatistic_Freespin },
    { no: 24, name: "freebet", kind: "message", T: GeoStatistic_Freebet },
    { no: 25, name: "tournamentCasino", kind: "message", T: GeoStatistic_Tournament },
    { no: 26, name: "tournamentSport", kind: "message", T: GeoStatistic_Tournament },
    { no: 27, name: "countIdentTries", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 28, name: "countIdentSuccess", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 29, name: "providerStatistic", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GeoStatistic_ProviderStatistic} },
    { no: 32, name: "withdrawalRequestCasino", kind: "message", T: Total$1 },
    { no: 33, name: "withdrawalRequestSport", kind: "message", T: Total$1 },
  ],
);

/**
 * @generated from message pinup.stat.report.geo.GeoStatistic.Registrations
 */
export const GeoStatistic_Registrations = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Registrations",
  () => [
    { no: 1, name: "casino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
  {localName: "GeoStatistic_Registrations"},
);

/**
 * @generated from message pinup.stat.report.geo.GeoStatistic.Players
 */
export const GeoStatistic_Players = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Players",
  () => [
    { no: 1, name: "real", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "realSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "realCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "newRealSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "newRealCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
  {localName: "GeoStatistic_Players"},
);

/**
 * Deposit
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Deposit
 */
export const GeoStatistic_Deposit = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Deposit",
  () => [
    { no: 1, name: "try", kind: "message", T: Total$1 },
    { no: 2, name: "tryNd", kind: "message", T: Total$1 },
    { no: 3, name: "real", kind: "message", T: Total$1 },
    { no: 4, name: "admin", kind: "message", T: Total$1 },
    { no: 5, name: "first", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Deposit"},
);

/**
 * Bonus
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Bonus
 */
export const GeoStatistic_Bonus = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Bonus",
  () => [
    { no: 1, name: "add", kind: "message", T: Total$1 },
    { no: 2, name: "wager", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Bonus"},
);

/**
 * Bet
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Bet
 */
export const GeoStatistic_Bet = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Bet",
  () => [
    { no: 2, name: "real", kind: "message", T: Total$1 },
    { no: 3, name: "realRollback", kind: "message", T: Total$1 },
    { no: 4, name: "bonus", kind: "message", T: Total$1 },
    { no: 5, name: "bonusRollback", kind: "message", T: Total$1 },
    { no: 6, name: "realCalc", kind: "message", T: Total$1 },
    { no: 7, name: "realCalcRollback", kind: "message", T: Total$1 },
    { no: 8, name: "bonusCalc", kind: "message", T: Total$1 },
    { no: 9, name: "bonusCalcRollback", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Bet"},
);

/**
 * Win
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Win
 */
export const GeoStatistic_Win = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Win",
  () => [
    { no: 2, name: "real", kind: "message", T: Total$1 },
    { no: 3, name: "realRollback", kind: "message", T: Total$1 },
    { no: 4, name: "bonus", kind: "message", T: Total$1 },
    { no: 5, name: "bonusRollback", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Win"},
);

/**
 * Lottery
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Lottery
 */
export const GeoStatistic_Lottery = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Lottery",
  () => [
    { no: 1, name: "add", kind: "message", T: Total$1 },
    { no: 2, name: "realWin", kind: "message", T: Total$1 },
    { no: 3, name: "bonusWin", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Lottery"},
);

/**
 * Freespin - only casino
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Freespin
 */
export const GeoStatistic_Freespin = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Freespin",
  () => [
    { no: 1, name: "add", kind: "message", T: Total$1 },
    { no: 2, name: "win", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Freespin"},
);

/**
 * Freebet - only sport
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Freebet
 */
export const GeoStatistic_Freebet = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Freebet",
  () => [
    { no: 1, name: "add", kind: "message", T: Total$1 },
    { no: 2, name: "bet", kind: "message", T: Total$1 },
    { no: 7, name: "betRollback", kind: "message", T: Total$1 },
    { no: 3, name: "win", kind: "message", T: Total$1 },
    { no: 4, name: "winRollback", kind: "message", T: Total$1 },
    { no: 5, name: "winWithBetSum", kind: "message", T: Total$1 },
    { no: 6, name: "winWithBetSumRollback", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Freebet"},
);

/**
 * Tournament
 *
 * @generated from message pinup.stat.report.geo.GeoStatistic.Tournament
 */
export const GeoStatistic_Tournament = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.Tournament",
  () => [
    { no: 2, name: "realWin", kind: "message", T: Total$1 },
  ],
  {localName: "GeoStatistic_Tournament"},
);

/**
 * @generated from message pinup.stat.report.geo.GeoStatistic.ProviderStatistic
 */
export const GeoStatistic_ProviderStatistic = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GeoStatistic.ProviderStatistic",
  () => [
    { no: 1, name: "bet", kind: "message", T: Total$1 },
    { no: 2, name: "bonusBet", kind: "message", T: Total$1 },
    { no: 3, name: "betWin", kind: "message", T: Total$1 },
    { no: 4, name: "bonusBetWin", kind: "message", T: Total$1 },
    { no: 5, name: "freespinWin", kind: "message", T: Total$1 },
    { no: 6, name: "commissionPercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "commissionBeforePercent", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "commission", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "system", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "gameTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "gameID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
  {localName: "GeoStatistic_ProviderStatistic"},
);

/**
 * Deprecated
 *
 * @generated from message pinup.stat.report.geo.GetGeoStatRequest
 */
export const GetGeoStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetGeoStatRequest",
  () => [
    { no: 1, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: Period$1 },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "groupBy", kind: "enum", T: proto3.getEnumType(GroupBy) },
  ],
);

/**
 * Deprecated
 *
 * @generated from message pinup.stat.report.geo.GetGeoStatResponse
 */
export const GetGeoStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.stat.report.geo.GetGeoStatResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: GeoStatistic, repeated: true },
  ],
);

