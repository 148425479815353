import type { Provider } from '@angular/core';
import { inject, InjectionToken } from '@angular/core';

import { provideFactory } from '@pu/sdk';
import { License } from '@bo/common';

export const LOOKER_STUDIO_LINK = new InjectionToken<string>(
  'LOOKER_STUDIO_LINK',
);

const lookerStudioLinkFactory = (): string => {
  const { url: license } = inject(License);

  switch (license) {
    case 'ua':
      return 'https://lookerstudio.google.com/embed/reporting/70e3076a-4c3c-45c5-9035-34a8b6bfcacc/page/RAmGE';
    case 'kz':
      return 'https://lookerstudio.google.com/embed/reporting/b81591b5-3275-4be4-8de1-7fbee499d389/page/RAmGE';
    default:
      return 'https://lookerstudio.google.com/embed/reporting/78b80e5c-4d55-41c6-8d77-57efacec5645/page/RAmGE';
  }
};

export const provideLookerStudioLink = (): Provider =>
  provideFactory<string>(LOOKER_STUDIO_LINK, lookerStudioLinkFactory);
