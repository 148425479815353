import { Directive, HostListener, input } from '@angular/core';

import type { FullscreenDirective } from './fullscreen.directive';

@Directive({
  selector: '[puFullScreenTriggerFor]',
  standalone: true,
})
export class FullscreenTriggerDirective {
  public readonly container = input.required<FullscreenDirective>({
    alias: 'puFullScreenTriggerFor',
  });

  @HostListener('click')
  private toggleFullscreen(): void {
    void this.container().toggle();
  }
}
