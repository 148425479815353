// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/std/query/v1/models.proto (package pinup.std.query.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Total
 *
 * @generated from message pinup.std.query.v1.Total
 */
export const Total = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.std.query.v1.Total",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

