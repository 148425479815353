import type {
  ClassProvider,
  ExistingProvider,
  FactoryProvider,
  InjectionToken,
  Provider,
  ProviderToken,
  Type,
  ValueProvider,
} from '@angular/core';

export function provideValue<T>(
  provide: ProviderToken<T>,
  useValue: NoInfer<T>,
): Provider {
  return { provide, useValue };
}

export function provideMultiValue<T>(
  provide: InjectionToken<T[]>,
  useValue: NoInfer<T>,
): ValueProvider {
  return { provide, useValue, multi: true };
}

export function provideFactory<T>(
  provide: ProviderToken<T>,
  useFactory: () => NoInfer<T>,
): FactoryProvider {
  return { provide, useFactory };
}

export function provideMultiFactory<T>(
  provide: InjectionToken<T[]>,
  useFactory: () => NoInfer<T>,
): FactoryProvider {
  return { provide, useFactory, multi: true };
}

export function provideExisting<T>(
  provide: ProviderToken<T>,
  useExisting: ProviderToken<NoInfer<T>>,
): ExistingProvider {
  return { provide, useExisting };
}

export function provideMultiExisting<T>(
  provide: InjectionToken<T[]>,
  useExisting: ProviderToken<NoInfer<T>>,
): ExistingProvider {
  return { provide, useExisting, multi: true };
}

export function provideClass<T>(
  provide: ProviderToken<T>,
  useClass: Type<NoInfer<T>>,
): ClassProvider {
  return { provide, useClass };
}

export function provideMultiClass<T>(
  provide: ProviderToken<T[]>,
  useClass: Type<NoInfer<T>>,
): ClassProvider {
  return { provide, useClass, multi: true };
}
