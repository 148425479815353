import type { Provider } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';

import { type PersistentData, provideDataPersistence } from '@bo/common';

import type {
  FiltersFormResolver,
  FiltersFormResolverSettings,
} from './filters-form-resolver.model';

export const DATA_PERSISTENCE_FOR_FILTERS_FORM_RESOLVER = new InjectionToken<
  Observable<PersistentData>
>('DATA_PERSISTENCE_FOR_FILTERS_FORM_RESOLVER');

export const FILTERS_FORM_RESOLVER = new InjectionToken<FiltersFormResolver>(
  'FILTERS_FORM_RESOLVER',
);

const defaultSettings: Required<FiltersFormResolverSettings> = {
  usePersistence: true,
  persistenceStrategy: 'route-params',
};

export const provideFiltersFormResolver = (
  Resolver: new () => FiltersFormResolver,
  settings: FiltersFormResolverSettings = {},
): Provider[] => {
  const { usePersistence, persistenceStrategy } = {
    ...defaultSettings,
    ...settings,
  };
  const providers: Provider[] = [
    {
      provide: FILTERS_FORM_RESOLVER,
      useClass: Resolver,
    },
  ];

  if (usePersistence) {
    const persistenceProviders = provideDataPersistence(
      DATA_PERSISTENCE_FOR_FILTERS_FORM_RESOLVER,
      persistenceStrategy,
    );

    providers.push(persistenceProviders);
  }

  return providers;
};
