import { Injectable } from '@angular/core';

import type { PersistentData } from '@bo/common';
import { FiltersFormResolverService } from '@bo/common';

import { toFormData } from './deposits-filter.adapter';
import type { DepositsFilters } from './forms';

@Injectable()
export class DepositsFilterResolverService extends FiltersFormResolverService<DepositsFilters> {
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  protected toFormData(data: PersistentData): DepositsFilters {
    return toFormData(data);
  }
}
