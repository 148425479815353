import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaskitoDirective } from '@maskito/angular';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs';

import type { AddDepositRequest } from '@pu/grpc';
import { DepositRemoteService } from '@pu/grpc';
import {
  depositsIcon,
  DialogContentComponent,
  DialogSuspenseDirective,
  InertSuspenseDirective,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  SvgComponent,
  ValidationErrorPipe,
} from '@pu/sdk';
import {
  ADD_DEPOSIT_DIALOG_DATA,
  betProjectOptions,
  DECIMAL_MASK_OPTIONS,
  emptyBackground,
  LicenseSupportsPipe,
  RadioToggleBarComponent,
  RadioToggleItemComponent,
} from '@bo/common';

@Component({
  selector: 'support-add-deposit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogSuspenseDirective,
    FormsModule,
    InertSuspenseDirective,
    SuspenseAreaComponent,
    SuspenseDirective,
    MatDialogTitle,
    CdkTextareaAutosize,
    DialogContentComponent,
    MatFormField,
    StrictMinLengthDirective,
    ValidationErrorPipe,
    MatCheckbox,
    MatInput,
    MatLabel,
    MatError,
    ReactiveFormsModule,
    MaskitoDirective,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    RadioToggleBarComponent,
    RadioToggleItemComponent,
    LicenseSupportsPipe,
    SvgComponent,
    MatButtonToggleGroup,
    MatButtonToggle,
  ],
  templateUrl: './add-deposit-dialog.component.html',
  styleUrl: './add-deposit-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDepositDialogComponent {
  protected readonly data = inject(ADD_DEPOSIT_DIALOG_DATA);
  private readonly remoteService = inject(DepositRemoteService);
  private readonly snackBar = inject(MatSnackBar);

  protected readonly deposit: Partial<AddDepositRequest> = {
    comment: '',
    isCommentPinned: false,
    withBonus: false,
    withLottery: false,
    useInTaxCalculation: false,
    isBetProject: false,
  };
  protected readonly decimalMaskOptions = DECIMAL_MASK_OPTIONS;
  protected readonly betProjectOptions = betProjectOptions;
  protected readonly emptyBackground = emptyBackground;
  protected readonly depositsIcon = depositsIcon;

  protected send(): Observable<unknown> {
    return this.remoteService
      .addDeposit({
        ...this.data,
        ...this.deposit,
      })
      .pipe(
        tap({
          complete: () => {
            this.snackBar.open('Deposit was added', 'OK', { duration: 5000 });
          },
        }),
      );
  }
}
