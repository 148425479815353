import type { Interceptor } from '@connectrpc/connect';

import { base64UrlEncode } from '@pu/sdk';

export function grpcRequestIdKey(
  service: string,
  method: string,
  request: Uint8Array,
): string {
  return `gRPC/${service}/${method}/${base64UrlEncode(request)}`;
}

export const MOCK_OVERRIDE_INTERCEPTOR: Interceptor = (next) => {
  return async (req) => {
    if (req.stream) {
      return next(req);
    }

    const { service, method } = req;

    const responseUnsafeJson = localStorage.getItem(
      grpcRequestIdKey(service.typeName, method.name, req.message.toBinary()),
    );

    if (responseUnsafeJson === null) {
      return next(req);
    }

    try {
      const message = method.O.fromJsonString(responseUnsafeJson);
      const header = new Headers();
      const trailer = new Headers();
      console.log(
        '%c[MOCK]%c %s/%c%s%c',
        'color: green',
        'font-weight: normal; color: gray',
        req.service.typeName,
        'font-weight: bold',
        req.method.name,
        'font-weight: normal',
      );

      return { service, method, header, trailer, message, stream: false };
    } catch (error) {
      console.error(
        '%c[MOCK]%c %s/%c%s%c',
        'color: red',
        'font-weight: normal; color: gray',
        req.service.typeName,
        'font-weight: bold',
        req.method.name,
        'font-weight: normal',
        error,
      );

      return next(req);
    }
  };
};
