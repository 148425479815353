<div class="toolbar">
  <div class="section">
    <ng-content select="[toolbarLeft]" />
  </div>

  <div class="section">
    <ng-content select="[toolbarCenter]" />
  </div>

  <div class="section">
    <ng-content select="[toolbarRight]" />
  </div>
</div>

<div class="general-info">
  <ng-content select="[generalInfo]" />
</div>

<div class="content-wrapper">
  <div class="content-wrapper-inner">
    <ng-content />
  </div>
</div>
