// @generated by protoc-gen-es v1.10.0
// @generated from file common/filter.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, Int64Value, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Time Range
 *
 * @generated from message kycaid.TimeRange
 */
export const TimeRange = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.TimeRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Timestamp },
    { no: 2, name: "to", kind: "message", T: Timestamp },
  ],
);

/**
 * Duration Range
 *
 * @generated from message kycaid.DurationRange
 */
export const DurationRange = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.DurationRange",
  () => [
    { no: 1, name: "from", kind: "message", T: Duration },
    { no: 2, name: "to", kind: "message", T: Duration },
  ],
);

/**
 * Amount range in coins
 *
 * @generated from message kycaid.Int64Range
 */
export const Int64Range = /*@__PURE__*/ proto3.makeMessageType(
  "kycaid.Int64Range",
  () => [
    { no: 1, name: "from", kind: "message", T: Int64Value },
    { no: 2, name: "to", kind: "message", T: Int64Value },
  ],
);

