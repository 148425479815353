// @generated by protoc-gen-es v1.10.0
// @generated from file service/auto_money_out_cron_setting.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message payments.AutoMoneyOutCronSetting
 */
export const AutoMoneyOutCronSetting = /*@__PURE__*/ proto3.makeMessageType(
  "payments.AutoMoneyOutCronSetting",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "enable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "repeat", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message payments.GetAutoMoneyOutCronSettingRequest
 */
export const GetAutoMoneyOutCronSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.GetAutoMoneyOutCronSettingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message payments.GetAutoMoneyOutCronSettingResponse
 */
export const GetAutoMoneyOutCronSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.GetAutoMoneyOutCronSettingResponse",
  () => [
    { no: 1, name: "setting", kind: "message", T: AutoMoneyOutCronSetting },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.UpsertAutoMoneyOutCronSettingRequest
 */
export const UpsertAutoMoneyOutCronSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.UpsertAutoMoneyOutCronSettingRequest",
  () => [
    { no: 1, name: "setting", kind: "message", T: AutoMoneyOutCronSetting },
  ],
);

/**
 * @generated from message payments.UpsertAutoMoneyOutCronSettingResponse
 */
export const UpsertAutoMoneyOutCronSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.UpsertAutoMoneyOutCronSettingResponse",
  () => [
    { no: 1, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

