import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bo-page-content-layout',
  templateUrl: './page-content-layout.component.html',
  styleUrl: './page-content-layout.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class PageContentLayoutComponent {}
