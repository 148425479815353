import type { Injector, ProviderToken } from '@angular/core';
import { inject, INJECTOR } from '@angular/core';

const NOT_FOUND = Symbol();

export function* injectHierarchy<T>(token: ProviderToken<T>): Iterable<T> {
  for (
    let injector: Injector | null = inject(INJECTOR);
    injector !== null;
    injector = injector.get(INJECTOR, null, { skipSelf: true })
  ) {
    const value = injector.get<T | typeof NOT_FOUND>(token, NOT_FOUND, {
      self: true,
    });

    if (value !== NOT_FOUND) {
      yield value;
    }
  }
}
