// @generated by protoc-gen-es v1.10.0
// @generated from file services/players_notes_logs/player_notes_logs.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse, TimeRange } from "../common/common_pb.js";

/**
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.ErrorReason
 */
export const ErrorReason = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.ErrorReason",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "CAN_BE_EDITED_ONLY_BY_AUTHOR"},
    {no: 2, name: "CANT_BE_EDITED"},
  ],
);

/**
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.DepartmentType
 */
export const DepartmentType = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.DepartmentType",
  [
    {no: 0, name: "DEPARTMENT_TYPE_UNKNOWN", localName: "UNKNOWN"},
    {no: 1, name: "DEPARTMENT_TYPE_FINANCE", localName: "FINANCE"},
    {no: 2, name: "DEPARTMENT_TYPE_SUPPORT", localName: "SUPPORT"},
    {no: 3, name: "DEPARTMENT_TYPE_RISK", localName: "RISK"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.CreatePlayerNoteRequest
 */
export const CreatePlayerNoteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.CreatePlayerNoteRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.CreatePlayerNoteResponse
 */
export const CreatePlayerNoteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.CreatePlayerNoteResponse",
  () => [
    { no: 1, name: "note", kind: "message", T: PlayerNote },
  ],
);

/**
 * EditPlayerNoteRequest is in charge of editing player notes
 * and a note is editable only by author during 10 min
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.EditPlayerNoteRequest
 */
export const EditPlayerNoteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.EditPlayerNoteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Error reasons: ERROR_REASON_CAN_BE_EDITED_ONLY_BY_AUTHOR, ERROR_REASON_CAN_BE_EDITED_ONLY_BY_AUTHOR
 *
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.EditPlayerNoteResponse
 */
export const EditPlayerNoteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.EditPlayerNoteResponse",
  () => [
    { no: 1, name: "note", kind: "message", T: PlayerNote },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.TriggerPlayerNotePinRequest
 */
export const TriggerPlayerNotePinRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.TriggerPlayerNotePinRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.TriggerPlayerNotePinResponse
 */
export const TriggerPlayerNotePinResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.TriggerPlayerNotePinResponse",
  () => [
    { no: 1, name: "note", kind: "message", T: PlayerNote },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.GetPlayerNotesByIdRequest
 */
export const GetPlayerNotesByIdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.GetPlayerNotesByIdRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.GetPlayerNotesByIdResponse
 */
export const GetPlayerNotesByIdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.GetPlayerNotesByIdResponse",
  () => [
    { no: 1, name: "notes", kind: "message", T: PlayerNote, repeated: true },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerNotesRequest
 */
export const FindPlayerNotesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerNotesRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 3, name: "isPinned", kind: "message", T: BoolValue },
    { no: 4, name: "createdAt", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNote
 */
export const PlayerNote = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerNote",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "adminId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "adminFullName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(DepartmentType) },
    { no: 6, name: "isPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "createdAt", kind: "message", T: Timestamp },
    { no: 9, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerNotesResponse
 */
export const FindPlayerNotesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerNotesResponse",
  () => [
    { no: 1, name: "notes", kind: "message", T: PlayerNote, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerLogsRequest
 */
export const FindPlayerLogsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerLogsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 3, name: "createdAt", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerLogsResponse
 */
export const FindPlayerLogsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.FindPlayerLogsResponse",
  () => [
    { no: 1, name: "logs", kind: "message", T: PlayerLog, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerLog
 */
export const PlayerLog = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.players_notes_logs.PlayerLog",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "createdAt", kind: "message", T: Timestamp },
    { no: 5, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

