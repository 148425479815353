// @generated by protoc-gen-es v1.10.0
// @generated from file services/playerblocker/playerblocker.proto (package pinup.players.player.playerblocker, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.players.player.playerblocker.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.playerblocker.PaginationRequest",
  () => [
    { no: 1, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message pinup.players.player.playerblocker.GetInactivePlayersRequest
 */
export const GetInactivePlayersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.playerblocker.GetInactivePlayersRequest",
  () => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "inactivityDuration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.playerblocker.GetInactivePlayersResponse
 */
export const GetInactivePlayersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.playerblocker.GetInactivePlayersResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: Player, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.playerblocker.Player
 */
export const Player = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.playerblocker.Player",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currencyID", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.playerblocker.BlockPlayerRequest
 */
export const BlockPlayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.playerblocker.BlockPlayerRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.playerblocker.BlockPlayerResponse
 */
export const BlockPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.playerblocker.BlockPlayerResponse",
  [],
);

