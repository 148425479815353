import type { Sorting } from '@pinup-grpc/base/sorting_pb';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  ReplaySubject,
  shareReplay,
  startWith,
} from 'rxjs';

export class SortingFieldManager {
  private readonly setSorting$ = new ReplaySubject<Partial<Sorting>>(1);

  public sorting$: Observable<Sorting>;

  constructor(initialSorting: Partial<Sorting>) {
    this.sorting$ = this.setSorting$.pipe(
      startWith(initialSorting),
      distinctUntilChanged(isEqual),
      shareReplay(1),
    );
  }

  public setSorting(sorting: Partial<Sorting>): void {
    this.setSorting$.next(sorting);
  }
}
