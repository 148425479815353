// @generated by protoc-gen-es v1.10.0
// @generated from file service/suspicious-deposits.proto (package apiPlayerAnalysis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AMLStatusChanges, AMLTriggerBigSumOfDeposit, AMLTriggerCardToken, AMLTriggerIPAndFingerprint, AMLTriggerLossIncome } from "../base/common_pb.js";

/**
 * @generated from message apiPlayerAnalysis.AMLTriggerRequest
 */
export const AMLTriggerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLTriggerRequest",
  () => [
    { no: 1, name: "players", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "cardToken", kind: "message", T: AMLTriggerCardToken },
    { no: 3, name: "ipAndFingerprint", kind: "message", T: AMLTriggerIPAndFingerprint },
    { no: 4, name: "bigSumOfDeposit", kind: "message", T: AMLTriggerBigSumOfDeposit },
    { no: 5, name: "lossIncome", kind: "message", T: AMLTriggerLossIncome },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.AMLTriggerResponse
 */
export const AMLTriggerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLTriggerResponse",
  () => [
    { no: 1, name: "changes", kind: "map", K: 4 /* ScalarType.UINT64 */, V: {kind: "message", T: AMLStatusChanges} },
  ],
);

