import type { EnvironmentProviders } from '@angular/core';
import { InjectionToken, makeEnvironmentProviders } from '@angular/core';

import { provideValue } from '@pu/sdk';

export const DEFAULT_APP_ROUTE = new InjectionToken<string>(
  'DEFAULT_APP_ROUTE',
  { factory: () => '/' },
);

export const provideAppDefaultRoute = (route: string): EnvironmentProviders =>
  makeEnvironmentProviders([provideValue<string>(DEFAULT_APP_ROUTE, route)]);
