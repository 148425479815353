import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
} from '@angular/core';

import { SvgComponent } from '@pu/sdk';
import { chevronDownIcon, chevronUpIcon } from '@bo/common';

import { ACTIVE_COLOR, COLOR } from './accordion-item.consts';

@Component({
  selector: 'bo-accordion-item',
  standalone: true,
  imports: [SvgComponent],
  templateUrl: './accordion-item.component.html',
  styleUrl: './accordion-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent {
  public title = input.required<string>();
  public icon = input<string>();
  public isActive = input(false);
  public isExpanded = model<boolean>(false);

  protected readonly chevronUp = chevronUpIcon;
  protected readonly chevronDown = chevronDownIcon;
  protected readonly COLOR = COLOR;
  protected readonly ACTIVE_COLOR = ACTIVE_COLOR;

  public toggle(): void {
    this.isExpanded.update((isExpanded) => !isExpanded);
  }
}
