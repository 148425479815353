import { inject, Pipe, type PipeTransform } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeResourceUrl',
  standalone: true,
})
export class SanitizeResourceUrlPipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);

  public transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
