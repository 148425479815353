import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type -- FIXME
export const resizeObservable = (element: Element) => {
  return new Observable((subscriber) => {
    const resizeObserver = new ResizeObserver((entries) => {
      subscriber.next(entries);
    });

    resizeObserver.observe(element);

    return function unsubscribe() {
      resizeObserver.unobserve(element);
    };
  });
};
