import type { EnvironmentProviders } from '@angular/core';
import {
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import type {
  CanActivateChildFn,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { LOCATION } from '@ng-web-apis/common';

import { provideFactory, provideMultiValue } from '@pu/sdk';

import type { AccessGroupLicense } from './access-group';
import type { LicenseFeature, LicenseOptions } from './license';
import { License } from './license';
import { SUPPORTED_LICENSES } from './supported-licenses';

const UNFILTERED_LICENSES = new InjectionToken<readonly License[]>(
  'UNFILTERED_LICENSES',
);

export function provideLicense(
  group: string,
  title: string,
  origin: string,
  host: string,
  features: Iterable<LicenseFeature>,
  options?: LicenseOptions,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideMultiValue(
      UNFILTERED_LICENSES,
      License.create(
        group as AccessGroupLicense,
        title,
        origin,
        host,
        features,
        options,
      ),
    ),
  ]);
}

export function provideFirstAvailableLicense(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideFactory(License, () => {
      const [license] = inject(SUPPORTED_LICENSES);

      if (license === undefined) {
        throw new Error('License not available');
      }

      return license;
    }),
  ]);
}

function provideKzLicense(origin: string, host: string): EnvironmentProviders {
  return provideLicense('kz', 'Kazakhstan', origin, host, [
    'betting-status',
    'digitain-freebet',
    'sport',
    'sport-bet',
    'sport-win',
    'sport-digitain',
    'freebet',
  ]);
}

function provideUaLicense(origin: string, host: string): EnvironmentProviders {
  return provideLicense('ua', 'Ukraine', origin, host, [

    'casino-status',
    'casino',
    'casino-bet',
    'casino-win',
    'cashback',
    'dw-tax',
    'freespin',
    'lottery',
    'pincoins',
    're-verification',
    'tax-calculation',
    'tax-information',
    'tv-games',
    'vs',
  ]);
}

function provideComLicense(origin: string, host: string): EnvironmentProviders {
  return provideLicense('com', 'COM', origin, host, [
    'betby',
    'betting-status',
    'casino-status',
    'cashback',
    'casino',
    'casino-bet',
    'casino-win',
    'currency',
    'cpf',
    'geo',
    'freebet',
    'freespin',
    'lottery',
    'pincoins',
    'sport',
    'sport-bet',
    'sport-win',
    'sport-betby',
    'sport-digitain',
    'bespoke-sport',
    'digitain-freebet',
    'bespoke-freebet',
    'tv-games',
    'vs',
    'betby-freebet',
  ]);
}

export function provideDevLicenses(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideUaLicense(
      'https://gw-bo.ua.dev.product.pin-up.dev',
      'bo.tech-pu.com',
    ),
    provideComLicense(
      'https://gw-bo.com.dev.product.pin-up.dev',
      'bo.tech-pu.com',
    ),
    provideKzLicense(
      'https://gw-bo.kz.dev.product.pin-up.dev',
      'bo-kz.tech-pu.com',
    ),

    provideFactory(SUPPORTED_LICENSES, () => {
      const licenses = inject(UNFILTERED_LICENSES);
      const debugLocationHost = localStorage.getItem('debugLocationHost');

      return debugLocationHost === null
        ? licenses
        : licenses.filter(({ host }) => host === debugLocationHost);
    }),
  ]);
}

export function provideProdLicenses(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideUaLicense('https://gw-bo.pin-up.ua', 'bo.tech-pu.com'),
    provideUaLicense(
      'https://gw-bo.pin-up.ua', // TODO preprod origin
      'bo-preprod.tech-pu.com',
    ),

    provideComLicense('https://gw-bo.pin-up.world', 'bo.tech-pu.com'),
    provideComLicense(
      'https://gw-bo.pin-up.world', // TODO preprod origin
      'bo-preprod.tech-pu.com',
    ),

    provideComLicense('https://gw-bo.pin-up.world', 'bo-product.pincowin.tech'),
    provideComLicense(
      'https://gw-bo.pin-up.world', // TODO preprod origin
      'bo-product.prepincowin.tech',
    ),

    provideKzLicense('https://gw-bo.pin-up.kz', 'bo-kz.tech-pu.com'),
    provideKzLicense(
      'https://gw-bo.pin-up.kz', // TODO preprod origin
      'bo-kz-preprod.tech-pu.com',
    ),

    provideFactory(SUPPORTED_LICENSES, () => {
      const location = inject(LOCATION);

      return inject(UNFILTERED_LICENSES).filter(
        ({ host }) => host === location.host,
      );
    }),
  ]);
}

export function canLicenseSupport(
  feature: LicenseFeature,
): CanActivateChildFn & CanActivateFn & CanMatchFn {
  return () => inject(License).supports(feature);
}
