// @generated by protoc-gen-es v1.10.0
// @generated from file services/balance_history/balance_history.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Int64Range, PaginationRequest, PaginationResponse, TimeRange } from "../common/common_pb.js";

/**
 * TransactionGroupType is grouped operation types for comfortable work on front side
 * https://docs.google.com/spreadsheets/d/1YAcHdulBHPo8C4j5SXxGosYPeP67IP5j2oPrAMM2fwE/edit#gid=0
 *
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.TransactionGroupType
 */
export const TransactionGroupType = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.TransactionGroupType",
  [
    {no: 0, name: "TRANSACTION_GROUP_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_GROUP_TYPE_DEPOSIT", localName: "DEPOSIT"},
    {no: 2, name: "TRANSACTION_GROUP_TYPE_WITHDRAWAL", localName: "WITHDRAWAL"},
    {no: 3, name: "TRANSACTION_GROUP_TYPE_UNWITHDRAWAL", localName: "UNWITHDRAWAL"},
    {no: 4, name: "TRANSACTION_GROUP_TYPE_BET", localName: "BET"},
    {no: 5, name: "TRANSACTION_GROUP_TYPE_SPORT_WIN", localName: "SPORT_WIN"},
    {no: 6, name: "TRANSACTION_GROUP_TYPE_CASINO_WIN", localName: "CASINO_WIN"},
    {no: 7, name: "TRANSACTION_GROUP_TYPE_CASINO_LOSE", localName: "CASINO_LOSE"},
    {no: 8, name: "TRANSACTION_GROUP_TYPE_BONUS_TO_REAL", localName: "BONUS_TO_REAL"},
    {no: 9, name: "TRANSACTION_GROUP_TYPE_CASHBACK", localName: "CASHBACK"},
    {no: 10, name: "TRANSACTION_GROUP_TYPE_REVERT", localName: "REVERT"},
    {no: 11, name: "TRANSACTION_GROUP_TYPE_ROLLBACK", localName: "ROLLBACK"},
    {no: 12, name: "TRANSACTION_GROUP_TYPE_OTHER", localName: "OTHER"},
  ],
);

/**
 * @generated from enum git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.ProjectType
 */
export const ProjectType = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.ProjectType",
  [
    {no: 0, name: "PT_CASINO"},
    {no: 1, name: "PT_SPORT"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.Transaction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "transactionId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "change", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "currency", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "groupType", kind: "enum", T: proto3.getEnumType(TransactionGroupType) },
    { no: 9, name: "project", kind: "enum", T: proto3.getEnumType(ProjectType) },
    { no: 10, name: "createdAt", kind: "message", T: Timestamp },
    { no: 11, name: "isReverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.FindBalanceTransactionsRequest
 */
export const FindBalanceTransactionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.FindBalanceTransactionsRequest",
  () => [
    { no: 1, name: "filter", kind: "message", T: FindBalanceTransactionsRequest_Filter },
    { no: 2, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.FindBalanceTransactionsRequest.Filter
 */
export const FindBalanceTransactionsRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.FindBalanceTransactionsRequest.Filter",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "change", kind: "message", T: Int64Range },
    { no: 3, name: "createdAt", kind: "message", T: TimeRange },
    { no: 4, name: "transactionId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "groupTypes", kind: "enum", T: proto3.getEnumType(TransactionGroupType), repeated: true },
  ],
  {localName: "FindBalanceTransactionsRequest_Filter"},
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.FindBalanceTransactionsResponse
 */
export const FindBalanceTransactionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.FindBalanceTransactionsResponse",
  () => [
    { no: 1, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.GetTransactionTypesRequest
 */
export const GetTransactionTypesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.GetTransactionTypesRequest",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.GetTransactionTypesResponse
 */
export const GetTransactionTypesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.GetTransactionTypesResponse",
  () => [
    { no: 1, name: "transactionTypes", kind: "message", T: TransactionType, repeated: true },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.TransactionType
 */
export const TransactionType = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.balance_history.TransactionType",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

