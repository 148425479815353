import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  output,
} from '@angular/core';

@Directive({
  selector: '[boOutsideClick]',
  standalone: true,
})
export class OutsideClickDirective {
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  public readonly outsideClick = output({ alias: 'boOutsideClick' });

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(target: HTMLElement): void {
    const isClickOutside = !this.elementRef.nativeElement.contains(target);

    if (isClickOutside) {
      this.outsideClick.emit();
    }
  }
}
