import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';

import { SvgComponent } from '@pu/sdk';
import { MainLayoutComponent } from '@bo/common';

@Component({
  selector: '[bo-nav-item]',
  host: {
    '[class.collapsed]': 'layout.collapsed()',
    '[class.active]': 'active()',
  },
  standalone: true,
  imports: [SvgComponent],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent {
  protected readonly layout = inject(MainLayoutComponent);

  public icon = input.required<string>();
  public active = input(false);
}
