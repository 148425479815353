// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/std/query/v1/types.proto (package pinup.std.query.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum pinup.std.query.v1.Project
 */
export const Project = /*@__PURE__*/ proto3.makeEnum(
  "pinup.std.query.v1.Project",
  [
    {no: 0, name: "PROJECT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PROJECT_CASINO", localName: "CASINO"},
    {no: 2, name: "PROJECT_SPORT", localName: "SPORT"},
  ],
);

/**
 * @generated from enum pinup.std.query.v1.Gender
 */
export const Gender = /*@__PURE__*/ proto3.makeEnum(
  "pinup.std.query.v1.Gender",
  [
    {no: 0, name: "GENDER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GENDER_MALE", localName: "MALE"},
    {no: 2, name: "GENDER_FEMALE", localName: "FEMALE"},
  ],
);

