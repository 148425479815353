import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import type { MatchResult } from '@pu/sdk';

@Component({
  selector: 'bo-highlight-match',
  standalone: true,
  templateUrl: './highlight-match.component.html',
  styleUrl: './highlight-match.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighlightMatchComponent {
  public readonly search = input.required<string>();
  public readonly match = input.required<MatchResult>();

  protected prefix = computed(() => {
    const { index, text } = this.match();
    if (index === -1) {
      return text;
    }
    return text.slice(0, index);
  });

  protected highlight = computed(() => {
    const { index, text } = this.match();
    if (index === -1) {
      return '';
    }
    return text.slice(index, index + this.search().length);
  });

  protected postfix = computed(() => {
    const { index, text } = this.match();
    if (index === -1) {
      return '';
    }
    return text.slice(index + this.search().length);
  });
}
