// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/bo/players/players.proto (package stat.bo.players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPlayerRequest, GetPlayerResponse, ListPlayersAWRequest, ListPlayersAWResponse, ListPlayersRequest, ListPlayersResponse, ListPlayerStatisticsRequest, ListPlayerStatisticsResponse, ListPlayersTaxDetailsRequest, ListPlayersTaxDetailsResponse } from "./players_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service stat.bo.players.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "stat.bo.players.PlayerService";

  public static readonly methods = {
    getPlayer: {
      name: "GetPlayer",
      I: GetPlayerRequest,
      O: GetPlayerResponse,
      kind: MethodKind.Unary,
    },
    listPlayers: {
      name: "ListPlayers",
      I: ListPlayersRequest,
      O: ListPlayersResponse,
      kind: MethodKind.Unary,
    },
    listPlayersAW: {
      name: "ListPlayersAW",
      I: ListPlayersAWRequest,
      O: ListPlayersAWResponse,
      kind: MethodKind.Unary,
    },
    listPlayerStatistics: {
      name: "ListPlayerStatistics",
      I: ListPlayerStatisticsRequest,
      O: ListPlayerStatisticsResponse,
      kind: MethodKind.Unary,
    },
    listPlayersTaxDetails: {
      name: "ListPlayersTaxDetails",
      I: ListPlayersTaxDetailsRequest,
      O: ListPlayersTaxDetailsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ----------------------------------------------------------------
   * GetPlayer - get player info by id
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.players.PlayerService.GetPlayer
   */
  public getPlayer(
    input: PartialMessage<GetPlayerRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPlayerResponse> {
    return unary<GetPlayerRequest, GetPlayerResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getPlayer,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * ListPlayers - List of players by filter with pagination
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.players.PlayerService.ListPlayers
   */
  public listPlayers(
    input: PartialMessage<ListPlayersRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListPlayersResponse> {
    return unary<ListPlayersRequest, ListPlayersResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.listPlayers,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * ListPlayersAW - List of players for AwStats by filter with pagination
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.players.PlayerService.ListPlayersAW
   */
  public listPlayersAW(
    input: PartialMessage<ListPlayersAWRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListPlayersAWResponse> {
    return unary<ListPlayersAWRequest, ListPlayersAWResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.listPlayersAW,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * ListPlayerStatistics - List of player statistics data by filter with pagination
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.players.PlayerService.ListPlayerStatistics
   */
  public listPlayerStatistics(
    input: PartialMessage<ListPlayerStatisticsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListPlayerStatisticsResponse> {
    return unary<ListPlayerStatisticsRequest, ListPlayerStatisticsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.listPlayerStatistics,
      input,
      options,
    );
  }

  /**
   * ----------------------------------------------------------------
   * ListPlayersTaxDetails - Getting of player tax statistics data by player id
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc stat.bo.players.PlayerService.ListPlayersTaxDetails
   */
  public listPlayersTaxDetails(
    input: PartialMessage<ListPlayersTaxDetailsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListPlayersTaxDetailsResponse> {
    return unary<ListPlayersTaxDetailsRequest, ListPlayersTaxDetailsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.listPlayersTaxDetails,
      input,
      options,
    );
  }
}
