import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  forwardRef,
  signal,
} from '@angular/core';
import {
  type ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import {
  MatChipGrid,
  MatChipInput,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { MatPseudoCheckbox } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { isNil } from 'lodash-es';
import { noop } from 'rxjs';

import type {
  AutocompleteContext,
  OnChangeListener,
  OnTouchedListener,
} from '@pu/sdk';
import {
  AutocompleteOfDirective,
  AutocompletePipeWithMultipleHighlights,
  closeIcon,
  ControlMultiSelectDirective,
  IncludesPipe,
  provideMultiExisting,
  SvgComponent,
} from '@pu/sdk';

import { MultiHighlightMatchComponent } from '../multi-highlight-match/multi-highlight-match.component';

@Component({
  selector: 'bo-multi-autocomplete',
  standalone: true,
  imports: [
    MatAutocomplete,
    MatOption,
    ControlMultiSelectDirective,
    MatChipGrid,
    MatAutocompleteTrigger,
    MatChipInput,
    MatLabel,
    MatFormField,
    NgTemplateOutlet,
    MatChipRemove,
    MatChipRow,
    SvgComponent,
    IncludesPipe,
    MatPseudoCheckbox,
    MultiHighlightMatchComponent,
    FormsModule,
    AutocompletePipeWithMultipleHighlights,
  ],
  providers: [
    provideMultiExisting(
      NG_VALUE_ACCESSOR,
      forwardRef(() => MultiAutocompleteComponent),
    ),
  ],
  templateUrl: './multi-autocomplete.component.html',
  styleUrl: './multi-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiAutocompleteComponent<T, V> implements ControlValueAccessor {
  protected selectedValues = signal<V[] | null>([]);
  protected search = signal('');

  private onChange: OnChangeListener<V[] | null> = noop;
  protected onTouched: OnTouchedListener = noop;

  protected readonly closeIcon = closeIcon;

  protected structure = contentChild.required<AutocompleteOfDirective<T, V>>(
    AutocompleteOfDirective,
  );

  protected availableItems = computed(() => this.structure().items() ?? []);

  protected availableEntries = computed<readonly AutocompleteContext<T, V>[]>(
    () => {
      const valueSelector = this.structure().valueSelector();

      return this.availableItems().map((item) => ({
        $implicit: item,
        value: valueSelector(item),
      }));
    },
  );

  protected selectedEntries = computed<readonly AutocompleteContext<T, V>[]>(
    () => {
      const selectedValues = this.selectedValues();

      if (isNil(selectedValues) || selectedValues.length === 0) {
        return [];
      }

      const equal = this.structure().equal();

      return this.availableEntries().filter(
        (entry) =>
          selectedValues.findIndex((value) => equal(value, entry.value)) !== -1,
      );
    },
  );

  public registerOnChange(fn: OnChangeListener<V[] | null>): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: OnTouchedListener): void {
    this.onTouched = fn;
  }

  public writeValue(value: V[] | null): void {
    this.selectedValues.set(value);
  }

  public onSearch(value: unknown): void {
    if (typeof value !== 'string') {
      return;
    }
    this.search.set(value);
  }

  public onSelectedValues(value: V[]): void {
    this.selectedValues.set(value);
    this.onChange(value);
  }
}
