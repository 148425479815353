// @generated by protoc-gen-es v1.10.0
// @generated from file service/bo/prohibited_word/v1/prohibited_word.proto (package gamification.service.bo.prohibited_word.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Request to upload words that prohibited in gamification product
 *
 * @generated from message gamification.service.bo.prohibited_word.v1.UploadRequest
 */
export const UploadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.prohibited_word.v1.UploadRequest",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * Response for uploading words that prohibited in gamification product
 *
 * @generated from message gamification.service.bo.prohibited_word.v1.UploadResponse
 */
export const UploadResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.prohibited_word.v1.UploadResponse",
  [],
);

/**
 * Request to create a new prohibited word.
 *
 * @generated from message gamification.service.bo.prohibited_word.v1.CreateRequest
 */
export const CreateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.prohibited_word.v1.CreateRequest",
  () => [
    { no: 1, name: "word", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response for creating a new prohibited word.
 *
 * @generated from message gamification.service.bo.prohibited_word.v1.CreateResponse
 */
export const CreateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.prohibited_word.v1.CreateResponse",
  [],
);

/**
 * Request to delete a prohibited word.
 *
 * @generated from message gamification.service.bo.prohibited_word.v1.DeleteRequest
 */
export const DeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.prohibited_word.v1.DeleteRequest",
  () => [
    { no: 1, name: "word", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response for deleting a prohibited word.
 *
 * @generated from message gamification.service.bo.prohibited_word.v1.DeleteResponse
 */
export const DeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.bo.prohibited_word.v1.DeleteResponse",
  [],
);

