@if (collapsed()) {
  <button
    class="selected"
    [cdkMenuPosition]="[COLLAPSED_SIDEBAR_LICENSE_DROPDOWN_CONFIG]"
    [cdkMenuTriggerFor]="menu"
    type="button"
  >
    <span class="abbreviate centered">{{ selectedLink.license.group }}</span>
  </button>
} @else {
  <button
    class="selected"
    [cdkMenuPosition]="[EXPANDED_SIDEBAR_LICENSE_DROPDOWN_CONFIG]"
    [cdkMenuTriggerFor]="menu"
    type="button"
  >
    <span class="abbreviate">{{ selectedLink.license.group }}</span>
    <span class="full-name">{{ selectedLink.license.title }}</span>
    <bo-svg class="chevron-up-down" [unsafeSvgSource]="chevronUpDownIcon" />
  </button>
}
<ng-template #menu>
  <ul class="list mat-elevation-z2" cdkMenu>
    @for (link of links(); track link) {
      <li cdkMenuItem>
        <a class="link" [href]="prefix + link.href">
          <ng-container>
            <span class="abbreviate">{{ link.license.group }}</span>
            <span class="full-name">
              {{
                multipleDepartments()
                  ? link.department.title
                  : link.license.title
              }}
            </span>
          </ng-container>
          @if (
            selectedLink.department.url === link.department.url &&
            selectedLink.license.url === link.license.url
          ) {
            <mat-pseudo-checkbox
              class="check-icon"
              appearance="minimal"
              state="checked"
            />
          }
        </a>
      </li>
    }
  </ul>
</ng-template>
