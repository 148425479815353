import type { PaginationPanelPayload } from './pagination-panel/pagination-panel.model';

export type InfiniteScrollPayload = Readonly<{
  lastItemId: bigint;
  count: number;
}>;

export enum PaginationType {
  Panel = 'Panel',
  VirtualScroll = 'VirtualScroll',
}

export type PaginationPayload = InfiniteScrollPayload | PaginationPanelPayload;
