import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { capitalize } from 'lodash-es';

@Pipe({
  name: 'readableEnumValue',
  standalone: true,
})
export class ReadableEnumValuePipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    if (!value) {
      return 'Unspecified';
    }
    return value.split('_').map(capitalize).join(' ');
  }
}
