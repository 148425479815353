// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/money_out_settings.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { DeleteMoneyOutSettingsRequest, DeleteMoneyOutSettingsResponse, FindMoneyOutSettingsRequest, FindMoneyOutSettingsResponse, UpsertListMoneyOutSettingsRequest, UpsertListMoneyOutSettingsResponse } from "./money_out_settings_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.MoneyOutSettingsService
 */
@Injectable({ providedIn: "root" })
export class MoneyOutSettingsRemoteService {
  public static readonly typeName = "payments.MoneyOutSettingsService";

  public static readonly methods = {
    find: {
      name: "Find",
      I: FindMoneyOutSettingsRequest,
      O: FindMoneyOutSettingsResponse,
      kind: MethodKind.Unary,
    },
    upsertList: {
      name: "UpsertList",
      I: UpsertListMoneyOutSettingsRequest,
      O: UpsertListMoneyOutSettingsResponse,
      kind: MethodKind.Unary,
    },
    delete: {
      name: "Delete",
      I: DeleteMoneyOutSettingsRequest,
      O: DeleteMoneyOutSettingsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * pinup.product.<license>.payments.money_out_settings.find.requested
   * Find - find money out settings depends on request
   *
   * @generated from rpc payments.MoneyOutSettingsService.Find
   */
  public find(
    input: PartialMessage<FindMoneyOutSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindMoneyOutSettingsResponse> {
    return unary<FindMoneyOutSettingsRequest, FindMoneyOutSettingsResponse>(
      this.transport,
      MoneyOutSettingsRemoteService,
      MoneyOutSettingsRemoteService.methods.find,
      input,
      options,
    );
  }

  /**
   * pinup.product.<license>.payments.money_out_settings.upsert_list.requested
   * UpsertList - create or update list of money out settings in request
   *
   * @generated from rpc payments.MoneyOutSettingsService.UpsertList
   */
  public upsertList(
    input: PartialMessage<UpsertListMoneyOutSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpsertListMoneyOutSettingsResponse> {
    return unary<UpsertListMoneyOutSettingsRequest, UpsertListMoneyOutSettingsResponse>(
      this.transport,
      MoneyOutSettingsRemoteService,
      MoneyOutSettingsRemoteService.methods.upsertList,
      input,
      options,
    );
  }

  /**
   * pinup.product.<license>.payments.money_out_settings.delete.requested
   * Delete - delete money out settings by id
   *
   * @generated from rpc payments.MoneyOutSettingsService.Delete
   */
  public delete(
    input: PartialMessage<DeleteMoneyOutSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteMoneyOutSettingsResponse> {
    return unary<DeleteMoneyOutSettingsRequest, DeleteMoneyOutSettingsResponse>(
      this.transport,
      MoneyOutSettingsRemoteService,
      MoneyOutSettingsRemoteService.methods.delete,
      input,
      options,
    );
  }
}
