import type { HighlightedText } from './sorting/match';

export function compareHighlighted(
  a: HighlightedText,
  b: HighlightedText,
): number {
  const length = Math.min(a.length, b.length);

  for (let index = 0; index < length; index++) {
    const aLength = a[index].length;
    const bLength = b[index].length;

    if (aLength === bLength) {
      continue;
    }

    return index % 2 === 0 ? aLength - bLength : bLength - aLength;
  }

  for (let index = 0; index < length; index += 2) {
    const weight = a[index].localeCompare(b[index]);

    if (weight === 0) {
      continue;
    }

    return weight;
  }

  return 0;
}

export function haveHighlightedParts(text: HighlightedText): boolean {
  return text.length !== 1;
}
