import type { PipeTransform } from '@angular/core';
import { inject, Pipe } from '@angular/core';
import { isNil } from 'lodash-es';

import { DISPLAY_NAMES_LANGUAGE } from './display-name.const';

@Pipe({ name: 'displayNamesLanguage', standalone: true })
export class DisplayNamesLanguagePipe implements PipeTransform {
  private readonly displayNamesLanguage = inject(DISPLAY_NAMES_LANGUAGE);

  public transform(code: string | null | undefined): string | null {
    if (isNil(code)) {
      return null;
    }

    return this.displayNamesLanguage.of(code) ?? null;
  }
}
