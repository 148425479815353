import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { ListCurrenciesResponse } from '@pu/grpc';
import { CurrencyRemoteService } from '@pu/grpc';

@Injectable({ providedIn: 'root' })
export class CurrencyProvider {
  private readonly currencyRemoteService = inject(CurrencyRemoteService);

  public listCurrencies(): Observable<ListCurrenciesResponse> {
    return this.currencyRemoteService.listCurrencies({});
  }
}
