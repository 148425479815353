// @generated by protoc-gen-es v1.10.0
// @generated from file service/frontend/player/v1/player.proto (package gamification.service.frontend.player.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AvatarList } from "../../../../base/frontend/v1/avatar_pb.js";
import { PlayerAvatarRelatedEvent } from "../../../../base/frontend/v1/player_enum_pb.js";

/**
 * request all available player avatars
 *
 * @generated from message gamification.service.frontend.player.v1.GetAvailableAvatarsRequest
 */
export const GetAvailableAvatarsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetAvailableAvatarsRequest",
  () => [
    { no: 1, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * response for get all available user avatars
 *
 * @generated from message gamification.service.frontend.player.v1.GetAvailableAvatarsResponse
 */
export const GetAvailableAvatarsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetAvailableAvatarsResponse",
  () => [
    { no: 1, name: "avatars", kind: "message", T: AvatarList, repeated: true },
  ],
);

/**
 * request update player avatar
 *
 * @generated from message gamification.service.frontend.player.v1.UpdateAvatarRequest
 */
export const UpdateAvatarRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.UpdateAvatarRequest",
  () => [
    { no: 1, name: "avatar_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * response for update player avatar
 *
 * @generated from message gamification.service.frontend.player.v1.UpdateAvatarResponse
 */
export const UpdateAvatarResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.UpdateAvatarResponse",
  [],
);

/**
 * Request message to update a player's nickname
 *
 * @generated from message gamification.service.frontend.player.v1.UpdateNicknameRequest
 */
export const UpdateNicknameRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.UpdateNicknameRequest",
  () => [
    { no: 1, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Response message for updating a player's nickname
 *
 * @generated from message gamification.service.frontend.player.v1.UpdateNicknameResponse
 */
export const UpdateNicknameResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.UpdateNicknameResponse",
  [],
);

/**
 * request get player info
 *
 * @generated from message gamification.service.frontend.player.v1.GetInfoRequest
 */
export const GetInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetInfoRequest",
  [],
);

/**
 * response for get player info
 *
 * @generated from message gamification.service.frontend.player.v1.GetInfoResponse
 */
export const GetInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetInfoResponse",
  () => [
    { no: 1, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "avatar_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Request message to get a player's lifetime statistics
 *
 * @generated from message gamification.service.frontend.player.v1.GetLifetimeStatisticsRequest
 */
export const GetLifetimeStatisticsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetLifetimeStatisticsRequest",
  [],
);

/**
 * Response message for a player's lifetime statistics
 *
 * @generated from message gamification.service.frontend.player.v1.GetLifetimeStatisticsResponse
 */
export const GetLifetimeStatisticsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetLifetimeStatisticsResponse",
  () => [
    { no: 1, name: "total_number_of_spins", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "max_casino_odds", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "total_number_of_bets", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "max_sports_odds", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Request message to get a player's recent statistics
 *
 * @generated from message gamification.service.frontend.player.v1.GetRecentStatisticsRequest
 */
export const GetRecentStatisticsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetRecentStatisticsRequest",
  [],
);

/**
 * Response message for a player's recent statistics
 *
 * @generated from message gamification.service.frontend.player.v1.GetRecentStatisticsResponse
 */
export const GetRecentStatisticsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetRecentStatisticsResponse",
  () => [
    { no: 1, name: "active_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "unique_accessible_bonuses", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "bonuses_waged", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "unique_accessible_championships", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "championships_attended", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Request message to get player info by id.
 *
 * @generated from message gamification.service.frontend.player.v1.GetInfoByIDRequest
 */
export const GetInfoByIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetInfoByIDRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Response message for a player's info.
 *
 * @generated from message gamification.service.frontend.player.v1.GetInfoByIDResponse
 */
export const GetInfoByIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetInfoByIDResponse",
  () => [
    { no: 1, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "avatar_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Request message to get a player's lifetime statistics by id.
 *
 * @generated from message gamification.service.frontend.player.v1.GetLifetimeStatisticsByIDRequest
 */
export const GetLifetimeStatisticsByIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetLifetimeStatisticsByIDRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Response message for a player's lifetime statistics
 *
 * @generated from message gamification.service.frontend.player.v1.GetLifetimeStatisticsByIDResponse
 */
export const GetLifetimeStatisticsByIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetLifetimeStatisticsByIDResponse",
  () => [
    { no: 1, name: "total_number_of_spins", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "max_casino_odds", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "total_number_of_bets", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "max_sports_odds", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Request message to get a player's recent statistics by id.
 *
 * @generated from message gamification.service.frontend.player.v1.GetRecentStatisticsByIDRequest
 */
export const GetRecentStatisticsByIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetRecentStatisticsByIDRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Response message for a player's recent statistics
 *
 * @generated from message gamification.service.frontend.player.v1.GetRecentStatisticsByIDResponse
 */
export const GetRecentStatisticsByIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.GetRecentStatisticsByIDResponse",
  () => [
    { no: 1, name: "active_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "unique_accessible_bonuses", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "bonuses_waged", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "unique_accessible_championships", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "championships_attended", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message gamification.service.frontend.player.v1.CreatePlayerAvatarReactionRequest
 */
export const CreatePlayerAvatarReactionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.CreatePlayerAvatarReactionRequest",
  () => [
    { no: 1, name: "related_event", kind: "enum", T: proto3.getEnumType(PlayerAvatarRelatedEvent) },
  ],
);

/**
 * @generated from message gamification.service.frontend.player.v1.CreatePlayerAvatarReactionResponse
 */
export const CreatePlayerAvatarReactionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.service.frontend.player.v1.CreatePlayerAvatarReactionResponse",
  [],
);

