import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'puPincoins',
  standalone: true,
})
export class PincoinsPipe implements PipeTransform {
  public transform(value: bigint | number): number {
    return Number(value) / 100;
  }
}
