import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { GoogleSignInButtonComponent } from '@pu/sdk/google';
import { PATHNAME_SEARCH_HASH } from '@bo/common';

import { SignInService } from './sign-in.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, GoogleSignInButtonComponent],
  selector: 'login-app',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  protected readonly signIn = inject(SignInService);
  protected readonly isRootLocation =
    new URL(inject(PATHNAME_SEARCH_HASH), location.origin).pathname === '/';
}
