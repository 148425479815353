import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  public transform(value: bigint | null): string {
    if (!value) {
      return '';
    }

    const totalMinutes = Math.floor(Number(value) / 60);
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = totalMinutes % 60;

    let result = '';
    if (days > 0) {
      result += `${days}d, `;
    }
    if (hours > 0 || days > 0) {
      result += `${hours}h, `;
    }
    result += `${minutes}m`;

    return result;
  }
}
