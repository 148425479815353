<div class="flex gap-1">
  <span class="label">Requests:</span>
  @if (total(); as total) {
    <span class="value">{{ total.totalItems }}</span>
  } @else {
    <span class="label-color">No data</span>
  }
</div>

<div class="flex gap-1">
  <span class="label">Total:</span>
  @if (totalAmount(); as total) {
    <pu-money [amount]="total.sum" [currency]="total.currency" />
  } @else {
    <span class="label-color">No data</span>
  }
</div>

<div class="flex gap-1">
  <span class="label">Average time:</span>
  @if (averageTime(); as averageTime) {
    <span class="value">{{ averageTime | duration }}</span>
  } @else {
    <span class="label-color">No data</span>
  }
</div>
