import type { PipeTransform } from '@angular/core';
import { InjectionToken, Pipe } from '@angular/core';
import type { AbstractControlDirective } from '@angular/forms';

import { flat } from '../util/flat';
import { injectHierarchy } from '../util/inject-hierarchy';
import { ValidatorError } from './validator.error';

export type ValidationErrorExtractor = (error: unknown) => string | null;

export const VALIDATION_ERROR_EXTRACTOR = new InjectionToken<
  readonly [string, ValidationErrorExtractor][]
>('VALIDATION_ERROR_EXTRACTOR');

@Pipe({
  name: 'validationError',
  standalone: true,
  pure: false,
})
export class ValidationErrorPipe implements PipeTransform {
  private readonly extractors = new Map(
    flat(injectHierarchy(VALIDATION_ERROR_EXTRACTOR)),
  );

  public transform({ errors }: AbstractControlDirective): string | null {
    if (errors === null) {
      return null;
    }

    for (const [name, error] of Object.entries(errors)) {
      const custom = this.extractors.get(name);

      if (custom !== undefined) {
        return custom(error);
      }

      if (error instanceof ValidatorError) {
        return error.toValidationErrorMessage();
      }
    }

    return null;
  }
}
