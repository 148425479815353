// @generated by protoc-gen-es v1.10.0
// @generated from file services/player_statistic.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Error } from "../base/error_pb.js";

/**
 * Player statistic in details
 *
 * @generated from message stat.PlayerStatisticAnswer
 */
export const PlayerStatisticAnswer = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerStatisticAnswer",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 191, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 192, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 193, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositTryNDCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "depositTryNDSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 194, name: "firstDepProject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 195, name: "firstDepTime", kind: "message", T: Timestamp },
    { no: 196, name: "firstDepSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "secondDepProject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "secondDepTime", kind: "message", T: Timestamp },
    { no: 6, name: "secondDepSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "lastDepProject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "lastDepTime", kind: "message", T: Timestamp },
    { no: 10, name: "lastDep", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "depositTryCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 12, name: "depositTrySumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 13, name: "depositTryNDCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 14, name: "depositTryNDSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 15, name: "depositCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 16, name: "depositSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: "firstDepTimeCasino", kind: "message", T: Timestamp },
    { no: 18, name: "firstDepSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "lastDepTimeCasino", kind: "message", T: Timestamp },
    { no: 20, name: "lastDepSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 21, name: "depositTryCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 22, name: "depositTrySumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 23, name: "depositTryNDCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 24, name: "depositTryNDSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 25, name: "depositCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 26, name: "depositSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 27, name: "firstDepTimeSport", kind: "message", T: Timestamp },
    { no: 28, name: "firstDepSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 29, name: "lastDepTimeSport", kind: "message", T: Timestamp },
    { no: 30, name: "lastDepSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 31, name: "moneyOutCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 32, name: "moneyOutSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 33, name: "moneyOutCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 34, name: "moneyOutSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 35, name: "betCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 36, name: "betSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 37, name: "betRollbackCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 38, name: "betRollbackSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 39, name: "betWinCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 40, name: "betWinCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 41, name: "betWinRollbackCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 42, name: "betWinRollbackSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 43, name: "lastBetCasino", kind: "message", T: Timestamp },
    { no: 44, name: "bonusbetCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 45, name: "bonusbetSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 46, name: "bonusbetRollbackCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 47, name: "bonusbetRollbackSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 48, name: "bonusbetWinCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 49, name: "bonusbetWinCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 50, name: "bonusbetWinRollbackCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 51, name: "bonusbetWinRollbackSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 52, name: "bonuslastBetCasino", kind: "message", T: Timestamp },
    { no: 53, name: "betCountAfterDepCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 54, name: "betSumAfterDepCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 55, name: "betCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 56, name: "betSumVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 57, name: "betRollbackCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 58, name: "betRollbackSumVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 59, name: "betWinCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 60, name: "betWinVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 61, name: "betWinRollbackCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 62, name: "betWinRollbackSumVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 63, name: "lastBetVirtual", kind: "message", T: Timestamp },
    { no: 64, name: "bonusbetCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 65, name: "bonusbetSumVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 66, name: "bonusbetRollbackCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 67, name: "bonusbetRollbackSumVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 68, name: "bonusbetWinCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 69, name: "bonusbetWinVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 70, name: "bonusbetWinRollbackCountVirtual", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 71, name: "bonusbetWinRollbackSumVirtual", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 72, name: "bonuslastBetVirtual", kind: "message", T: Timestamp },
    { no: 73, name: "betCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 74, name: "betSumLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 75, name: "betRollbackCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 76, name: "betRollbackSumLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 77, name: "betWinCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 78, name: "betWinLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 79, name: "betWinRollbackCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 80, name: "betWinRollbackSumLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 81, name: "lastBetLive", kind: "message", T: Timestamp },
    { no: 82, name: "bonusbetCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 83, name: "bonusbetSumLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 84, name: "bonusbetRollbackCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 85, name: "bonusbetRollbackSumLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 86, name: "bonusbetWinCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 87, name: "bonusbetWinLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 88, name: "bonusbetWinRollbackCountLive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 89, name: "bonusbetWinRollbackSumLive", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 90, name: "bonuslastBetLive", kind: "message", T: Timestamp },
    { no: 91, name: "betCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 92, name: "betSumTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 93, name: "betRollbackCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 94, name: "betRollbackSumTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 95, name: "betWinCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 96, name: "betWinTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 97, name: "betWinRollbackCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 98, name: "betWinRollbackSumTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 99, name: "lastBetTvGames", kind: "message", T: Timestamp },
    { no: 100, name: "bonusbetCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 101, name: "bonusbetSumTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 102, name: "bonusbetRollbackCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 103, name: "bonusbetRollbackSumTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 104, name: "bonusbetWinCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 105, name: "bonusbetWinTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 106, name: "bonusbetWinRollbackCountTvGames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 107, name: "bonusbetWinRollbackSumTvGames", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 108, name: "bonuslastBetTvGames", kind: "message", T: Timestamp },
    { no: 109, name: "betCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 110, name: "betSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 111, name: "betRollbackCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 112, name: "betRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 113, name: "betCountAfterDepSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 114, name: "betSumAfterDepSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 115, name: "lastBetSport", kind: "message", T: Timestamp },
    { no: 116, name: "betCalcCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 117, name: "betCalcSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 118, name: "betCalcRollbackCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 119, name: "betCalcRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 120, name: "betCalcCountWinSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 121, name: "betCalcWinSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 122, name: "betCalcWinRollbackCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 123, name: "betCalcWinRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 124, name: "lastCalcBetSport", kind: "message", T: Timestamp },
    { no: 125, name: "bonusbetCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 126, name: "bonusbetSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 127, name: "bonusbetRollbackCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 128, name: "bonusbetRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 129, name: "bonusbetCountAfterDepSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 130, name: "bonusbetSumAfterDepSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 131, name: "bonuslastBetSport", kind: "message", T: Timestamp },
    { no: 132, name: "bonusbetCalcCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 133, name: "bonusbetCalcSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 134, name: "bonusbetCalcRollbackCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 135, name: "bonusbetCalcRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 136, name: "bonusbetCalcCountWinSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 137, name: "bonusbetCalcWinSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 138, name: "bonusbetCalcWinRollbackCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 139, name: "bonusbetCalcWinRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 140, name: "bonusLastCalcBetSport", kind: "message", T: Timestamp },
    { no: 141, name: "bonusAddCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 142, name: "bonusAddSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 143, name: "bonusWageCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 144, name: "bonusWageSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 145, name: "bonusAddCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 146, name: "bonusAddSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 147, name: "bonusWageCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 148, name: "bonusWageSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 149, name: "pincoinAddCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 150, name: "pincoinAddSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 151, name: "pincoinExchageCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 152, name: "pincoinExchageSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 153, name: "pincoinExchageCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 154, name: "pincoinExchageSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 155, name: "lotteryCountAddCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 156, name: "lotteryRealWinCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 157, name: "lotteryRealWinAddCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 158, name: "lotteryBonusWinCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 159, name: "lotteryBonusWinAddCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 160, name: "lotteryCountAddSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 161, name: "lotteryRealWinCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 162, name: "lotteryRealWinSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 163, name: "lotteryBonusWinCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 164, name: "lotteryBonusWinSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 165, name: "freespinCountAdd", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 166, name: "freespinSumAdd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 167, name: "freespinWinCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 168, name: "freespinWinSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 169, name: "freebetCountAdd", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 170, name: "freebetSumAdd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 171, name: "freebetBetCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 172, name: "freebetBetSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 173, name: "freebetBetRollbackCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 174, name: "freebetBetRollbackSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 175, name: "freebetWinCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 176, name: "freebetWinSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 177, name: "freebetWinRollbackCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 178, name: "freebetWinRollbackSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 179, name: "freebetWinWithBetCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 180, name: "freebetWinWithBetSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 181, name: "freebetWinWithBetRollbackCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 182, name: "freebetWinWithBetRollbackSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 183, name: "transferCountCasinoToSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 184, name: "transferSumCasinoToSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 185, name: "transferCountSportToCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 186, name: "transferSumSportToCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 187, name: "tournamentWinCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 188, name: "tournamentWinSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 189, name: "tournamentWinCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 190, name: "tournamentWinSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 197, name: "depositTaxSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 198, name: "depositTaxCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 199, name: "moneyOutTaxFreeSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 200, name: "moneyOutTaxFreeCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 201, name: "moneyOutTaxSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 202, name: "moneyOutTaxCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 210, name: "withdrawalRequestCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 211, name: "withdrawalRequestSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 212, name: "withdrawalRequestCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 213, name: "withdrawalRequestSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message stat.PlayerStatisticRequest
 */
export const PlayerStatisticRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerStatisticRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 2, name: "timeFrom", kind: "message", T: Timestamp },
    { no: 3, name: "timeTo", kind: "message", T: Timestamp },
    { no: 4, name: "fullStat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.PlayerStatisticResponse
 */
export const PlayerStatisticResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerStatisticResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: PlayerStatisticAnswer, repeated: true },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.PlayerUnifiedStatisticRequest
 */
export const PlayerUnifiedStatisticRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerUnifiedStatisticRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "lastDepDate", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.PlayerUnifiedStatisticAnswer
 */
export const PlayerUnifiedStatisticAnswer = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerUnifiedStatisticAnswer",
  () => [
    { no: 1, name: "total", kind: "message", T: PlayerUnifiedStatisticAnswer_TotalPart },
    { no: 2, name: "lastDep", kind: "message", T: PlayerUnifiedStatisticAnswer_LastDepositPart },
  ],
);

/**
 * @generated from message stat.PlayerUnifiedStatisticAnswer.TotalPart
 */
export const PlayerUnifiedStatisticAnswer_TotalPart = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerUnifiedStatisticAnswer.TotalPart",
  () => [
    { no: 1, name: "depositSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "depositSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "moneyOutSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "moneyOutSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "betSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "betRollbackSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "betSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "betRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "PlayerUnifiedStatisticAnswer_TotalPart"},
);

/**
 * @generated from message stat.PlayerUnifiedStatisticAnswer.LastDepositPart
 */
export const PlayerUnifiedStatisticAnswer_LastDepositPart = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerUnifiedStatisticAnswer.LastDepositPart",
  () => [
    { no: 1, name: "betSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "betRollbackSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "betSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "betRollbackSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "betCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "betCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "PlayerUnifiedStatisticAnswer_LastDepositPart"},
);

/**
 * @generated from message stat.PlayerUnifiedStatisticResponse
 */
export const PlayerUnifiedStatisticResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerUnifiedStatisticResponse",
  () => [
    { no: 1, name: "item", kind: "message", T: PlayerUnifiedStatisticAnswer },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.PlayerStatisticForCRMAnswer
 */
export const PlayerStatisticForCRMAnswer = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PlayerStatisticForCRMAnswer",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "depositSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "depositSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "moneyOutSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "moneyOutSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "lastDepTimeCasino", kind: "message", T: Timestamp },
    { no: 7, name: "firstDepSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "lastDepTimeSport", kind: "message", T: Timestamp },
    { no: 9, name: "firstDepSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "betSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "betWinCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "betCalcSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 13, name: "betCalcWinSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 14, name: "bonusWageSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 15, name: "bonusWageSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 16, name: "lotteryRealWinAddCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: "freebetWinSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 18, name: "depositCountSport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 19, name: "depositCountCasino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 20, name: "withdrawalRequestSumCasino", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 21, name: "withdrawalRequestSumSport", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message stat.GetPlayerStatisticForCrmResponse
 */
export const GetPlayerStatisticForCrmResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerStatisticForCrmResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: PlayerStatisticForCRMAnswer, repeated: true },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetPlayerThreatmetrixStatisticRequest
 */
export const GetPlayerThreatmetrixStatisticRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerThreatmetrixStatisticRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stat.GetPlayerThreatmetrixStatisticResponse
 */
export const GetPlayerThreatmetrixStatisticResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerThreatmetrixStatisticResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "betCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "betSum", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "moneyoutCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "withdrawalRequestCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.CrmSendPlayerDailyStatRequest
 */
export const CrmSendPlayerDailyStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.CrmSendPlayerDailyStatRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.CrmSendPlayerDailyStatResponse
 */
export const CrmSendPlayerDailyStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.CrmSendPlayerDailyStatResponse",
  () => [
    { no: 1, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.GetPlayerHistoryRequest
 */
export const GetPlayerHistoryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerHistoryRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.GetPlayerHistoryResponse
 */
export const GetPlayerHistoryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerHistoryResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "changesCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "balanceHistory", kind: "message", T: GetPlayerHistoryResponse_ChangePlayerBalance, repeated: true },
    { no: 4, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message stat.GetPlayerHistoryResponse.ChangePlayerBalance
 */
export const GetPlayerHistoryResponse_ChangePlayerBalance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerHistoryResponse.ChangePlayerBalance",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "date", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "balance", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "typeId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetPlayerHistoryResponse_ChangePlayerBalance"},
);

/**
 * Deprecated
 *
 * @generated from message stat.GetPlayerStatisticFromCacheRequest
 */
export const GetPlayerStatisticFromCacheRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerStatisticFromCacheRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.GetPlayerStatisticFromCacheResponse
 */
export const GetPlayerStatisticFromCacheResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetPlayerStatisticFromCacheResponse",
  () => [
    { no: 4, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.DelStatRequest
 */
export const DelStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.DelStatRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Deprecated
 *
 * @generated from message stat.DelStatResponse
 */
export const DelStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.DelStatResponse",
  () => [
    { no: 4, name: "error", kind: "message", T: Error },
  ],
);

