import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  input,
  output,
} from '@angular/core';

import type { FiltersForm } from '../filters.form';

@Component({
  selector: 'bo-filled-filters-count',
  standalone: true,
  templateUrl: './filled-filters-count.component.html',
  styleUrls: ['./filled-filters-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
  exportAs: 'boClearCounter',
})
export class FilledFiltersCountComponent<T extends object> {
  public readonly clear = output();

  public form = input.required<FiltersForm<T>>();

  public readonly count = computed(() => this.form().count());
  public readonly hasFilledCount = computed(() =>
    this.form().hasCounterValue(),
  );

  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  public onClick(event: Event): void {
    event.stopPropagation();
  }

  public clearFiltersCount(): void {
    this.form().reset();
    this.clear.emit();
  }
}
