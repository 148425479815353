// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file settings_manager/v1/admin_settings/admin.proto (package settings_manager.v1.admin_settings, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { AddGroupFieldRequest, AddGroupFieldResponse, CreateGroupRequest, CreateGroupResponse, CreateSettingRequest, CreateSettingResponse, DeleteGroupFieldRequest, DeleteGroupFieldResponse, DeleteGroupRequest, DeleteGroupResponse, DeleteSettingRequest, DeleteSettingResponse, EditGroupFieldRequest, EditGroupFieldResponse, EditGroupRequest, EditGroupResponse, EditSettingRequest, EditSettingResponse, GetGroupRequest, GetGroupResponse, GetSettingRequest, GetSettingResponse, HardDeleteGroupRequest, HardDeleteGroupResponse, HardDeleteSettingRequest, HardDeleteSettingResponse, ListGroupsRequest, ListGroupsResponse, ListSettingsRequest, ListSettingsResponse } from "./admin_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service settings_manager.v1.admin_settings.SettingsAdminService
 */
@Injectable({ providedIn: "root" })
export class SettingsAdminRemoteService {
  public static readonly typeName = "settings_manager.v1.admin_settings.SettingsAdminService";

  public static readonly methods = {
    createGroup: {
      name: "CreateGroup",
      I: CreateGroupRequest,
      O: CreateGroupResponse,
      kind: MethodKind.Unary,
    },
    editGroup: {
      name: "EditGroup",
      I: EditGroupRequest,
      O: EditGroupResponse,
      kind: MethodKind.Unary,
    },
    getGroup: {
      name: "GetGroup",
      I: GetGroupRequest,
      O: GetGroupResponse,
      kind: MethodKind.Unary,
    },
    listGroups: {
      name: "ListGroups",
      I: ListGroupsRequest,
      O: ListGroupsResponse,
      kind: MethodKind.Unary,
    },
    editGroupField: {
      name: "EditGroupField",
      I: EditGroupFieldRequest,
      O: EditGroupFieldResponse,
      kind: MethodKind.Unary,
    },
    addGroupField: {
      name: "AddGroupField",
      I: AddGroupFieldRequest,
      O: AddGroupFieldResponse,
      kind: MethodKind.Unary,
    },
    deleteGroupField: {
      name: "DeleteGroupField",
      I: DeleteGroupFieldRequest,
      O: DeleteGroupFieldResponse,
      kind: MethodKind.Unary,
    },
    deleteGroup: {
      name: "DeleteGroup",
      I: DeleteGroupRequest,
      O: DeleteGroupResponse,
      kind: MethodKind.Unary,
    },
    hardDeleteGroup: {
      name: "HardDeleteGroup",
      I: HardDeleteGroupRequest,
      O: HardDeleteGroupResponse,
      kind: MethodKind.Unary,
    },
    createSetting: {
      name: "CreateSetting",
      I: CreateSettingRequest,
      O: CreateSettingResponse,
      kind: MethodKind.Unary,
    },
    getSetting: {
      name: "GetSetting",
      I: GetSettingRequest,
      O: GetSettingResponse,
      kind: MethodKind.Unary,
    },
    listSettings: {
      name: "ListSettings",
      I: ListSettingsRequest,
      O: ListSettingsResponse,
      kind: MethodKind.Unary,
    },
    editSetting: {
      name: "EditSetting",
      I: EditSettingRequest,
      O: EditSettingResponse,
      kind: MethodKind.Unary,
    },
    deleteSetting: {
      name: "DeleteSetting",
      I: DeleteSettingRequest,
      O: DeleteSettingResponse,
      kind: MethodKind.Unary,
    },
    hardDeleteSetting: {
      name: "HardDeleteSetting",
      I: HardDeleteSettingRequest,
      O: HardDeleteSettingResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   *
   * CreateGroup - Used to create a new group for settings
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - GROUP_NAME_NOT_UNIQUE: group name is not unique
   * - INTERNAL_ERROR: internal error
   * - OPTION_TYPE_UNSPECIFIED: option type is not specified. Allowed types: string, numbers
   * - SCHEMA_TYPE_NOT_VALID: schema type is not valid type enum value
   * - VALIDATIONS_MUTUALLY_EXCLUSIVE: schema contains mutually exclusive validations.
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.CreateGroup
   */
  public createGroup(
    input: PartialMessage<CreateGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreateGroupResponse> {
    return unary<CreateGroupRequest, CreateGroupResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.createGroup,
      input,
      options,
    );
  }

  /**
   *
   * EditGroup - Used to edit group title
   * Errors:
   * - GROUP_NOT_FOUND: group not found
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.EditGroup
   */
  public editGroup(
    input: PartialMessage<EditGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<EditGroupResponse> {
    return unary<EditGroupRequest, EditGroupResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.editGroup,
      input,
      options,
    );
  }

  /**
   *
   * GetGroup - Used to get a group info by name
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - GROUP_NOT_FOUND: group not found
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.GetGroup
   */
  public getGroup(
    input: PartialMessage<GetGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetGroupResponse> {
    return unary<GetGroupRequest, GetGroupResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.getGroup,
      input,
      options,
    );
  }

  /**
   *
   * ListGroups - Used to get a list of groups
   * Errors:
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.ListGroups
   */
  public listGroups(
    input: PartialMessage<ListGroupsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListGroupsResponse> {
    return unary<ListGroupsRequest, ListGroupsResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.listGroups,
      input,
      options,
    );
  }

  /**
   *
   * EditGroupField - Used to edit a field scheme in a group. It is NOT allowed to edit the field name
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - OPTION_TYPE_UNSPECIFIED: option type is not specified. Allowed types: string, numbers
   * - GROUP_NOT_FOUND: group not found
   * - FIELD_NOT_FOUND: field not found
   * - INTERNAL_ERROR: internal error
   * - SCHEMA_TYPE_NOT_VALID: schema type is not valid type enum value
   * - VALIDATIONS_MUTUALLY_EXCLUSIVE: schema contains mutually exclusive validations.
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.EditGroupField
   */
  public editGroupField(
    input: PartialMessage<EditGroupFieldRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<EditGroupFieldResponse> {
    return unary<EditGroupFieldRequest, EditGroupFieldResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.editGroupField,
      input,
      options,
    );
  }

  /**
   *
   * AddGroupField - Used to add a new field with its scheme to a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - OPTION_TYPE_UNSPECIFIED: option type is not specified. Allowed types: string, numbers
   * - GROUP_NOT_FOUND: group not found
   * - INTERNAL_ERROR: internal error
   * - SCHEMA_TYPE_NOT_VALID: schema type is not valid type enum value
   * - VALIDATIONS_MUTUALLY_EXCLUSIVE: schema contains mutually exclusive validations.
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.AddGroupField
   */
  public addGroupField(
    input: PartialMessage<AddGroupFieldRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AddGroupFieldResponse> {
    return unary<AddGroupFieldRequest, AddGroupFieldResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.addGroupField,
      input,
      options,
    );
  }

  /**
   *
   * DeleteGroupField - Used to set deletedAt field for a field's scheme in a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - GROUP_NOT_FOUND: group not found
   * - FIELD_NOT_FOUND: field not found
   * - GROUP_FIELD_ALREADY_DELETED: group field(key in scheme) has been deleted already(field deletedAt is not empty)
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.DeleteGroupField
   */
  public deleteGroupField(
    input: PartialMessage<DeleteGroupFieldRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteGroupFieldResponse> {
    return unary<DeleteGroupFieldRequest, DeleteGroupFieldResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.deleteGroupField,
      input,
      options,
    );
  }

  /**
   *
   * DeleteGroup - Used to set deletedAt field for a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - GROUP_ALREADY_DELETED: group has been deleted already(field deletedAt is not empty)
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.DeleteGroup
   */
  public deleteGroup(
    input: PartialMessage<DeleteGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteGroupResponse> {
    return unary<DeleteGroupRequest, DeleteGroupResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.deleteGroup,
      input,
      options,
    );
  }

  /**
   *
   * HardDeleteGroup - hard delete group, removed group from storage without ability to backup
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - REMOVING_ACTIVE_GROUP: removing active group (active means not marked as deleted, deletedAt equal 0)
   * - GROUP_HAS_ACTIVE_SETTINGS: removing group with active settings
   * - GROUP_NOT_FOUND: group is not found, or already removed
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.HardDeleteGroup
   */
  public hardDeleteGroup(
    input: PartialMessage<HardDeleteGroupRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<HardDeleteGroupResponse> {
    return unary<HardDeleteGroupRequest, HardDeleteGroupResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.hardDeleteGroup,
      input,
      options,
    );
  }

  /**
   *
   * CreateSetting - Used to create a new setting in a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - SETTING_ID_EMPTY: setting id is empty
   * - SETTING_ID_CONTAINS_SPACES: setting id contains spaces
   * - GROUP_NOT_FOUND: group not found
   * - VALIDATIONS_WRONG_TYPE: wrong type of value.
   * - VALIDATIONS_FIELDS_MISMATCH: fields mismatch.
   * - VALIDATIONS_REQUIRED_FIELD_MISS: required field is missing.
   * - VALIDATIONS_LENGTH_EXCEEDS_MAXIMUM: a field length exceeds maximum.
   * - VALIDATIONS_LENGTH_LESS_MINIMUM: a field length is less than minimum.
   * - SETTING_DUPLICATE_ID: setting id is not unique
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.CreateSetting
   */
  public createSetting(
    input: PartialMessage<CreateSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<CreateSettingResponse> {
    return unary<CreateSettingRequest, CreateSettingResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.createSetting,
      input,
      options,
    );
  }

  /**
   *
   * GetSetting - Used to get a setting by id in a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - SETTING_ID_EMPTY: setting id is empty
   * - SETTING_ID_CONTAINS_SPACES: setting id contains spaces
   * - SETTING_NOT_FOUND: setting not found
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.GetSetting
   */
  public getSetting(
    input: PartialMessage<GetSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetSettingResponse> {
    return unary<GetSettingRequest, GetSettingResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.getSetting,
      input,
      options,
    );
  }

  /**
   *
   * ListSettings - Used to get a list of settings in a group
   * Errors:
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.ListSettings
   */
  public listSettings(
    input: PartialMessage<ListSettingsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListSettingsResponse> {
    return unary<ListSettingsRequest, ListSettingsResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.listSettings,
      input,
      options,
    );
  }

  /**
   *
   * EditSetting - Used to edit a setting in a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - SETTING_ID_EMPTY: setting id is empty
   * - SETTING_ID_CONTAINS_SPACES: setting id contains spaces
   * - GROUP_NOT_FOUND: group not found
   * - VALIDATIONS_WRONG_TYPE: wrong type of value.
   * - VALIDATIONS_FIELDS_MISMATCH: fields mismatch.
   * - VALIDATIONS_REQUIRED_FIELD_MISS: required field is missing.
   * - VALIDATIONS_LENGTH_EXCEEDS_MAXIMUM: a field length exceeds maximum.
   * - VALIDATIONS_LENGTH_LESS_MINIMUM: a field length is less than minimum.
   * - SETTING_DUPLICATE_ID: setting id is not unique
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.EditSetting
   */
  public editSetting(
    input: PartialMessage<EditSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<EditSettingResponse> {
    return unary<EditSettingRequest, EditSettingResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.editSetting,
      input,
      options,
    );
  }

  /**
   *
   * DeleteSetting - Used to set deletedAt field for a setting in a group
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - SETTING_ALREADY_DELETED: setting has been deleted already
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.DeleteSetting
   */
  public deleteSetting(
    input: PartialMessage<DeleteSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeleteSettingResponse> {
    return unary<DeleteSettingRequest, DeleteSettingResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.deleteSetting,
      input,
      options,
    );
  }

  /**
   *
   * HardDeleteSetting - hard delete setting, removed group from storage without ability to backup
   * Errors:
   * - GROUP_NAME_EMPTY: group name is empty
   * - GROUP_NAME_CONTAINS_RESTRICTED_SYMBOLS: group name contains restricted symbols. Allowed symbols: [a-zA-Z0-9_]
   * - REMOVING_ACTIVE_SETTING: removing active setting (active means not marked as deleted, deletedAt equal 0)
   * - SETTING_NOT_FOUND: setting is not found, or already removed
   * - INTERNAL_ERROR: internal error
   *
   * @generated from rpc settings_manager.v1.admin_settings.SettingsAdminService.HardDeleteSetting
   */
  public hardDeleteSetting(
    input: PartialMessage<HardDeleteSettingRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<HardDeleteSettingResponse> {
    return unary<HardDeleteSettingRequest, HardDeleteSettingResponse>(
      this.transport,
      SettingsAdminRemoteService,
      SettingsAdminRemoteService.methods.hardDeleteSetting,
      input,
      options,
    );
  }
}
