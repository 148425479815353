import {
  pinup_stat_report_players_BettingStatus as BettingStatus,
  pinup_stat_report_players_CasinoStatus as CasinoStatus,
} from '@pu/grpc/enums';

export const CASINO_STATUS_NAMES_MAP: Record<CasinoStatus, string> = {
  [CasinoStatus.UNSPECIFIED]: 'Unspecified',
  [CasinoStatus.C1]: 'VIP player/Highroller',
  [CasinoStatus.C2]: 'Large deposits and bets',
  [CasinoStatus.C3]: 'Average deposits and bets',
  [CasinoStatus.C4]: 'Minimal deposits and bets',
  [CasinoStatus.CZ]: 'Problem',
  [CasinoStatus.CN]: 'Until the first gasino game',
  [CasinoStatus.CA]: 'After the first bet in casino',
  [CasinoStatus.CVIP]: 'Casino GGR more than 1 million',
};

export const BETTING_STATUS_NAMES_MAP: Record<BettingStatus, string> = {
  [BettingStatus.UNSPECIFIED]: 'Unspecified',
  [BettingStatus.B1]: 'Losing player',
  [BettingStatus.B2]: 'Danger player',
  [BettingStatus.B3]: 'Supervised by Risk management',
  [BettingStatus.B4]: 'B4',
  [BettingStatus.B5]: 'B5',
  [BettingStatus.BZ]: 'Limited player',
  [BettingStatus.BN]: 'Until the first sports bet',
  [BettingStatus.BA]: 'After the first sports bet',
  [BettingStatus.BVIP]: 'Sport ggr more than 1 million',
};
