<svg
  [attr.fill]="useCurrentColor ? 'currentColor' : fillColor"
  [attr.stroke]="useCurrentColor ? 'currentColor' : stroke"
  [class]="'risk-icon-svg ' + size"
>
  <use
    *ngIf="showPlaceholder"
    [attr.href]="'assets/' + collection + '.svg#placeholder'"
  />
  <use
    [attr.href]="
      'assets/' +
      (collection === 'common' ? 'icons' : 'flags') +
      '.svg#' +
      iconName
    "
  />
</svg>
