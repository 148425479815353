// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/stat_totals.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetActionTotalsRequest, GetActionTotalsResponse, GetBalanceTotalsRequest, GetBalanceTotalsResponse, GetRegistrationTotalsRequest, GetRegistrationTotalsResponse } from "./stat_totals_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * Players totals statistic
 *
 * @generated from service stat.StatTotals
 */
@Injectable({ providedIn: "root" })
export class StatTotalsRemoteService {
  public static readonly typeName = "stat.StatTotals";

  public static readonly methods = {
    getRegistrationTotals: {
      name: "GetRegistrationTotals",
      I: GetRegistrationTotalsRequest,
      O: GetRegistrationTotalsResponse,
      kind: MethodKind.Unary,
    },
    getActionTotals: {
      name: "GetActionTotals",
      I: GetActionTotalsRequest,
      O: GetActionTotalsResponse,
      kind: MethodKind.Unary,
    },
    getBalanceTotals: {
      name: "GetBalanceTotals",
      I: GetBalanceTotalsRequest,
      O: GetBalanceTotalsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * -------------------------------------------------------
   * GetRegistrationTotals - returns registration totals
   * -------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<lic>.stat_service.stat.totals.get_registration_totals
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.StatTotals.GetRegistrationTotals
   */
  public getRegistrationTotals(
    input: PartialMessage<GetRegistrationTotalsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRegistrationTotalsResponse> {
    return unary<GetRegistrationTotalsRequest, GetRegistrationTotalsResponse>(
      this.transport,
      StatTotalsRemoteService,
      StatTotalsRemoteService.methods.getRegistrationTotals,
      input,
      options,
    );
  }

  /**
   * -------------------------------------------------------
   * GetActionTotal - returns totals of ALL player events
   * -------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<lic>.stat_service.stat.totals.get_action_totals
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.StatTotals.GetActionTotals
   */
  public getActionTotals(
    input: PartialMessage<GetActionTotalsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetActionTotalsResponse> {
    return unary<GetActionTotalsRequest, GetActionTotalsResponse>(
      this.transport,
      StatTotalsRemoteService,
      StatTotalsRemoteService.methods.getActionTotals,
      input,
      options,
    );
  }

  /**
   * -------------------------------------------------------
   * GetRegistrationTotals - returns registration totals
   * -------------------------------------------------------
   * Format: proto
   * Access:
   *   NATS Request: pinup.product.<lic>.stat_service.stat.totals.get_balance_totals
   * ------------------------------------------------------------------------------------------------------------------
   *
   * @generated from rpc stat.StatTotals.GetBalanceTotals
   */
  public getBalanceTotals(
    input: PartialMessage<GetBalanceTotalsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetBalanceTotalsResponse> {
    return unary<GetBalanceTotalsRequest, GetBalanceTotalsResponse>(
      this.transport,
      StatTotalsRemoteService,
      StatTotalsRemoteService.methods.getBalanceTotals,
      input,
      options,
    );
  }
}
