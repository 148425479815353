import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgxPaginationModule, PaginationService } from 'ngx-pagination';

import { SvgComponent } from '@pu/sdk';

import chevronLeftSvg from '../../icons/chevron-left.svg';
import chevronRightSvg from '../../icons/chevron-right.svg';
import { CustomPaginationService } from './pagination.service';
import type { Pagination } from './pagination-panel.model';

@Component({
  selector: 'bo-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, SvgComponent],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation -- FIXME
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pagination' },
  providers: [
    CustomPaginationService,
    { provide: PaginationService, useExisting: CustomPaginationService }, // Needed for PaginationControlsDirective
  ],
})
export class PaginationComponent {
  @Output() public readonly pageChange = new EventEmitter<number>();

  @Input()
  set pagination(pagination: Pagination) {
    this.service.updatePagination(pagination);
  }

  protected chevronLeftSvg = chevronLeftSvg;
  protected chevronRightSvg = chevronRightSvg;
  private readonly service = inject(CustomPaginationService);

  public setPage(page: number): void {
    this.pageChange.emit(page);
  }
}
