import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgComponent } from '@pu/sdk';
import type { PlayerStatusIcon } from '@bo/common';

@Component({
  selector: 'bo-player-statuses',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './player-statuses.component.html',
  styleUrl: './player-statuses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerStatusesComponent {
  @Input({ required: true }) public statuses: PlayerStatusIcon[];
}
