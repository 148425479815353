import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

import { DataLayerBase } from './data-layer.base';
import type { DataLayerItem } from './data-layer.model';

@Injectable()
export class DataLayerService extends DataLayerBase {
  private readonly window = inject<{ dataLayer: DataLayerItem[] }>(WINDOW);

  protected readonly items = this.window.dataLayer;
}
