// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/backoffice/sessions.proto (package git.time2go.tech.apis.pinup.players.services.backoffice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { DeletePlayerSessionsRequest, DeletePlayerSessionsResponse, FindPlayerSessionsRequest, FindPlayerSessionsResponse } from "./sessions_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.players.services.backoffice.SessionsService
 */
@Injectable({ providedIn: "root" })
export class SessionsRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.players.services.backoffice.SessionsService";

  public static readonly methods = {
    deletePlayerSessions: {
      name: "DeletePlayerSessions",
      I: DeletePlayerSessionsRequest,
      O: DeletePlayerSessionsResponse,
      kind: MethodKind.Unary,
    },
    findPlayerSession: {
      name: "FindPlayerSession",
      I: FindPlayerSessionsRequest,
      O: FindPlayerSessionsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.SessionsService.DeletePlayerSessions
   */
  public deletePlayerSessions(
    input: PartialMessage<DeletePlayerSessionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeletePlayerSessionsResponse> {
    return unary<DeletePlayerSessionsRequest, DeletePlayerSessionsResponse>(
      this.transport,
      SessionsRemoteService,
      SessionsRemoteService.methods.deletePlayerSessions,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.SessionsService.FindPlayerSession
   */
  public findPlayerSession(
    input: PartialMessage<FindPlayerSessionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<FindPlayerSessionsResponse> {
    return unary<FindPlayerSessionsRequest, FindPlayerSessionsResponse>(
      this.transport,
      SessionsRemoteService,
      SessionsRemoteService.methods.findPlayerSession,
      input,
      options,
    );
  }
}
