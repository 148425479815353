// @generated by protoc-gen-es v1.10.0
// @generated from file service/admin_refund.proto (package cabinet, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cabinet.ConfirmRefundRequest
 */
export const ConfirmRefundRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.ConfirmRefundRequest",
  () => [
    { no: 1, name: "PlayerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "DepositID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "Amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "AdminId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "Comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "CardNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "System", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "MethodID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "AccountNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cabinet.ConfirmRefundResponse
 */
export const ConfirmRefundResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.ConfirmRefundResponse",
  () => [
    { no: 1, name: "isAuto", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message cabinet.CancelRefundRequest
 */
export const CancelRefundRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.CancelRefundRequest",
  () => [
    { no: 1, name: "PlayerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "DepositID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "Comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "CardNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cabinet.CancelRefundResponse
 */
export const CancelRefundResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cabinet.CancelRefundResponse",
  () => [
    { no: 1, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

