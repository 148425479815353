const BASE64_CHAR_MAP_URL =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
const BASE64_BITS_IN_BYTE = 8;
const BASE64_BITS_IN_CHAR = 6;

export function base64UrlEncode(array: Uint8Array): string {
  let bits = 0;
  let bitsLength = 0;
  let result = '';

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < array.length; i++) {
    bits = (bits << BASE64_BITS_IN_BYTE) | array[i];
    bitsLength += BASE64_BITS_IN_BYTE;

    do {
      result +=
        BASE64_CHAR_MAP_URL[bits >>> (bitsLength - BASE64_BITS_IN_CHAR)];
      bitsLength -= BASE64_BITS_IN_CHAR;
      bits &= (1 << bitsLength) - 1;
    } while (bitsLength >= BASE64_BITS_IN_CHAR);
  }

  if (bitsLength > 0) {
    result += BASE64_CHAR_MAP_URL[bits << (BASE64_BITS_IN_CHAR - bitsLength)];
  }

  return result;
}
