import { inject, Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';

import { OperatorInfoProvider } from './operator-info.provider';

@Injectable({ providedIn: 'root' })
export class OperatorInfoService {
  private readonly provider = inject(OperatorInfoProvider);

  public readonly operators$ = this.provider
    .getOperators()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
}
