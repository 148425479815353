import { KeyValuePipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unsafe-return -- FIXME
const keepOrder = (a: any) => a;

@Pipe({
  name: 'keepKeyValueOrder',
  standalone: true,
})
export class DefaultOrderKeyValuePipe
  extends KeyValuePipe
  implements PipeTransform
{
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- FIXME
  public override transform(value: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME
    return super.transform(value, keepOrder);
  }
}
