import type { RootFiltersForm } from '../../../../index';
import { FiltersForm } from '../../../../index';
import type {
  FinanceDepositsFilter,
  ListDepositsFilter,
} from '../deposits-filter.types';
import type { FinanceFilters } from './finance-filter.form';
import { DepositsFilterFinanceForm } from './finance-filter.form';
import type { OtherFilters } from './other-filter.form';
import { DepositsFilterOtherForm } from './other-filter.form';

export type DepositsFilters = Readonly<{
  finance: FinanceFilters;
  other: OtherFilters;
}>;

export class FinanceDepositsFilterForm
  extends FiltersForm<DepositsFilters>
  implements RootFiltersForm<FinanceDepositsFilter>
{
  constructor(data?: ListDepositsFilter) {
    super({
      finance: new DepositsFilterFinanceForm(data),
      other: new DepositsFilterOtherForm(data),
    });
  }

  public getRootValue(): FinanceDepositsFilter {
    const { finance, other } = this.value;

    return {
      ...finance,
      ...other,
    };
  }
}
