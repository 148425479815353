import { inject, Pipe, type PipeTransform } from '@angular/core';

import { CASHBOX_ADMIN_URL } from '../cashbox-admin.providers';
import { generateCashBoxAdminLink } from './cashbox-admin-link.utils';

@Pipe({ name: 'boCashBoxAdminLink', standalone: true })
export class CashBoxAdminLinkPipe implements PipeTransform {
  private readonly url = inject(CASHBOX_ADMIN_URL);

  public transform(path: string): string {
    return generateCashBoxAdminLink(this.url, path);
  }
}
