// @generated by protoc-gen-es v1.10.0
// @generated from file base/frontend/v1/player_enum.proto (package gamification.base.frontend.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of enumeration for avatar event to acquire avatar
 *
 * @generated from enum gamification.base.frontend.v1.PlayerAvatarRelatedEvent
 */
export const PlayerAvatarRelatedEvent = /*@__PURE__*/ proto3.makeEnum(
  "gamification.base.frontend.v1.PlayerAvatarRelatedEvent",
  [
    {no: 0, name: "PLAYER_AVATAR_RELATED_EVENT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PLAYER_AVATAR_RELATED_EVENT_ADVENT_CALENDAR_PROMO_NY_2024", localName: "ADVENT_CALENDAR_PROMO_NY_2024"},
  ],
);

