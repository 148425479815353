// @generated by protoc-gen-es v1.10.0
// @generated from file service/refund_svc.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "../models/pagination/pagination_pb.js";
import { RefundDeposit } from "../models/refund/payments_models_refund_pb.js";

/**
 * @generated from message payments.GetPlayerRefundsRequest
 */
export const GetPlayerRefundsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.GetPlayerRefundsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "dateFrom", kind: "message", T: Timestamp },
    { no: 3, name: "dateTo", kind: "message", T: Timestamp },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message payments.GetPlayerRefundsResponse
 */
export const GetPlayerRefundsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.GetPlayerRefundsResponse",
  () => [
    { no: 1, name: "refundDeposits", kind: "message", T: RefundDeposit, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

