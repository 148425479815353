import { Message } from '@bufbuild/protobuf';
import type {
  Interceptor,
  StreamRequest,
  StreamResponse,
  UnaryRequest,
  UnaryResponse,
} from '@connectrpc/connect';
import { ConnectError } from '@connectrpc/connect';
import {
  codeToHttpStatus,
  codeToString,
} from '@connectrpc/connect/protocol-connect';

import { grpcDebugLink } from './grpc-debug-link';

async function logSuccessful(
  req: StreamRequest | UnaryRequest,
  res: StreamResponse | UnaryResponse,
  time: number,
): Promise<void> {
  const debugLink = await grpcDebugLink(
    new URL(req.url).origin,
    req.service.typeName,
    req.method.name,
    req.message instanceof Message ? req.message.toBinary() : null,
  );

  console.groupCollapsed(
    '%c[GRPC]%c️ %s/%c%s%c (%s)',
    'color: #00bcd4',
    'font-weight: normal; color: gray',
    req.service.typeName,
    'font-weight: bold',
    req.method.name,
    'font-weight: normal',
    time.toLocaleString('en', { style: 'unit', unit: 'millisecond' }),
  );
  console.log('%cRequest%c', 'color: #884ea0', '', req.message);
  console.log('%cResponse%c', 'color: #17a589', '', res.message);
  console.log('Debug', debugLink);
  console.groupEnd();
}

async function logError(
  req: StreamRequest | UnaryRequest,
  error: unknown,
  time: number,
): Promise<void> {
  let status = NaN;
  let message = '?';

  if (error instanceof ConnectError) {
    status = codeToHttpStatus(error.code);
    message = codeToString(error.code);
  }

  const debugLink = await grpcDebugLink(
    new URL(req.url).origin,
    req.service.typeName,
    req.method.name,
    req.message instanceof Message ? req.message.toBinary() : null,
  );

  console.groupCollapsed(
    '%c[GRPC %s %s]%c️ %s/%c%s%c (%s)',
    'color: #ff0000',
    status,
    message,
    'font-weight: normal; color: gray',
    req.service.typeName,
    'font-weight: bold',
    req.method.name,
    'font-weight: normal',
    time.toLocaleString('en', { style: 'unit', unit: 'millisecond' }),
  );
  console.log('%cRequest:%c', 'color: #884ea0', '', req.message);
  console.log('%cError:%c %O', 'color: #17a589', '', error);
  console.log('Debug', debugLink);
  console.groupEnd();
}

export const LOGGER_INTERCEPTOR: Interceptor = (next) => {
  return async (req) => {
    const start = performance.now();

    let res: StreamResponse | UnaryResponse;

    try {
      res = await next(req);
      await logSuccessful(req, res, performance.now() - start);
    } catch (error) {
      await logError(req, error, performance.now() - start);

      throw error;
    }

    return res;
  };
};

export const LITE_LOG_INTERCEPTOR: Interceptor = (next) => {
  return async (req) => {
    let res: StreamResponse | UnaryResponse;

    try {
      res = await next(req);
      console.log(res.message);
    } catch (error) {
      console.error(error);

      throw error;
    }

    return res;
  };
};
