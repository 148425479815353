// @generated by protoc-gen-es v1.10.0
// @generated from file services/players.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Struct, Timestamp } from "@bufbuild/protobuf";
import { Error } from "../base/error_collision_0_pb.js";
import { CommentAuthor } from "../base/account_comment_pb.js";
import { Transaction } from "../base/transaction_pb.js";

/**
 * Requests
 *
 * @generated from message players.GetPlayerTransactionsRequest
 */
export const GetPlayerTransactionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerTransactionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerPiRequest
 */
export const GetPlayerPiRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPiRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerCdaRequest
 */
export const GetPlayerCdaRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCdaRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerAggregatorRequest
 */
export const GetPlayerAggregatorRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerAggregatorRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerTournamentRequest
 */
export const GetPlayerTournamentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerTournamentRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerAffiliateRequest
 */
export const GetPlayerAffiliateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerAffiliateRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerCpsAffiliateRequest
 */
export const GetPlayerCpsAffiliateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCpsAffiliateRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerCpsRequest
 */
export const GetPlayerCpsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCpsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerMailCheckRequest
 */
export const GetPlayerMailCheckRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerMailCheckRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayer180WithdrawErrRequest
 */
export const GetPlayer180WithdrawErrRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayer180WithdrawErrRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerPhoneRequest
 */
export const GetPlayerPhoneRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPhoneRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerRegistrationDateRequest
 */
export const GetPlayerRegistrationDateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerRegistrationDateRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerMarketingBonusRequest
 */
export const GetPlayerMarketingBonusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerMarketingBonusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerBetbyRequest
 */
export const GetPlayerBetbyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBetbyRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerBetProcessingRequest
 */
export const GetPlayerBetProcessingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBetProcessingRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerThreatmetrixRequest
 */
export const GetPlayerThreatmetrixRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerThreatmetrixRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerCurrencyRequest
 */
export const GetPlayerCurrencyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCurrencyRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerVerifiedStatusRequest
 */
export const GetPlayerVerifiedStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerVerifiedStatusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetHandleFirstBetPlayerRequest
 */
export const GetHandleFirstBetPlayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetHandleFirstBetPlayerRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerAdditionalOptionsRequest
 */
export const GetPlayerAdditionalOptionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerAdditionalOptionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerGapRequest
 */
export const GetPlayerGapRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerGapRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerCrmRequest
 */
export const GetPlayerCrmRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCrmRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerBlockedCrmRequest
 */
export const GetPlayerBlockedCrmRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBlockedCrmRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerProfileRequest
 */
export const GetPlayerProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerProfileRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerPaymentRequest
 */
export const GetPlayerPaymentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPaymentRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerPaymentFullRequest
 */
export const GetPlayerPaymentFullRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPaymentFullRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.CheckUpPlayerDataRequest
 */
export const CheckUpPlayerDataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.CheckUpPlayerDataRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerPromoCodeRequest
 */
export const GetPlayerPromoCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPromoCodeRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayersPromoCodeRequest
 */
export const GetPlayersPromoCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayersPromoCodeRequest",
  () => [
    { no: 1, name: "playerIds", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message players.GetPlayerIDsByNameRequest
 */
export const GetPlayerIDsByNameRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerIDsByNameRequest",
  () => [
    { no: 1, name: "firstname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lastname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "birthdate", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message players.GetPlayerDigitainRequest
 */
export const GetPlayerDigitainRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerDigitainRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.ProcessTransactionRequest
 */
export const ProcessTransactionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.ProcessTransactionRequest",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "betBonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "freebetBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "pincoinsBalance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "pincoinsBalanceSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "betType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "transactionType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "transactionTime", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "sportBonusesInUse", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * Responses
 *
 * @generated from message players.GetPlayerTransactionsResponse
 */
export const GetPlayerTransactionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerTransactionsResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * Responses
 *
 * @generated from message players.GetPlayerPiResponse
 */
export const GetPlayerPiResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPiResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "bonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "restrictedCasinoReal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "share", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "casinoBet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "pincoinsBalanceSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "betCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 17, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "betBonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerCdaResponse
 */
export const GetPlayerCdaResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCdaResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "certifiedPlayer", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerAggregatorResponse
 */
export const GetPlayerAggregatorResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerAggregatorResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "bonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerTournamentResponse
 */
export const GetPlayerTournamentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerTournamentResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "blockCasinoTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "blockSportTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "casinoStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "bettingStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerAffiliateResponse
 */
export const GetPlayerAffiliateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerAffiliateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "clickID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerCpsAffiliateResponse
 */
export const GetPlayerCpsAffiliateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCpsAffiliateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "clickID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerCpsResponse
 */
export const GetPlayerCpsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCpsResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerMailCheckResponse
 */
export const GetPlayerMailCheckResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerMailCheckResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "blockSendEmail", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "mailNotExists", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayer180WithdrawErrResponse
 */
export const GetPlayer180WithdrawErrResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayer180WithdrawErrResponse",
  () => [
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerPhoneResponse
 */
export const GetPlayerPhoneResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPhoneResponse",
  () => [
    { no: 1, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerRegistrationDateResponse
 */
export const GetPlayerRegistrationDateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerRegistrationDateResponse",
  () => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerMarketingBonusResponse
 */
export const GetPlayerMarketingBonusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerMarketingBonusResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerBetbyResponse
 */
export const GetPlayerBetbyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBetbyResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "betLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerBetProcessingResponse
 */
export const GetPlayerBetProcessingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBetProcessingResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "bonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "freeBetBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "betBonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "sportBonusesInUse", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "freebetEnable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "pincoinsBalance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "betCountSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "restrictedCasinoReal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "restrictedSportReal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "restrictedSportBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "blockSportTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 24, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "freeBetAvailable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerThreatmetrixResponse
 */
export const GetPlayerThreatmetrixResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerThreatmetrixResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "digitainStatusId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "promoCodeList", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "subId1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "subId2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "subId3", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "subId4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "subId5", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "lexisHash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "registration_date", kind: "message", T: Timestamp },
    { no: 21, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "bet_count_casino", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 25, name: "bet_count_sport", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 26, name: "deposit_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 27, name: "moneyout_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 28, name: "active_days_sport", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 29, name: "active_days_casino", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 30, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 31, name: "email_verified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 32, name: "phone_verified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 33, name: "certificated", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 34, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "document_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "document_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "bonus_choice", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "address_street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 42, name: "address_post_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 43, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerCurrencyResponse
 */
export const GetPlayerCurrencyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCurrencyResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerVerifiedStatusResponse
 */
export const GetPlayerVerifiedStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerVerifiedStatusResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "accountVerifiedStatus", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetHandleFirstBetPlayerResponse
 */
export const GetHandleFirstBetPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetHandleFirstBetPlayerResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "allowChangePassword", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerAdditionalOptionsResponse
 */
export const GetPlayerAdditionalOptionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerAdditionalOptionsResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerGapResponse
 */
export const GetPlayerGapResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerGapResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "bonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "sex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerCrmResponse
 */
export const GetPlayerCrmResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerCrmResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "blockSendDataToPartner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "registerDate", kind: "message", T: Timestamp },
    { no: 12, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "pincoinsBalance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "bonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "betBonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "freebetBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "accountVerifiedStatus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 18, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 19, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 20, name: "casinoStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "bettingStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "digitainStatusId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 23, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "freebetAvailable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "smsStatus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 28, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "PrivelegeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "activeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "depositStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "maStatus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 34, name: "levelName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "emailVerified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 36, name: "phoneVerification", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 37, name: "PhoneVerificationTime", kind: "message", T: Timestamp },
    { no: 38, name: "certifiedPlayer", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 39, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "doubleLogin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 41, name: "authMobile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 42, name: "network", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 43, name: "lastLogin", kind: "message", T: Timestamp },
    { no: 44, name: "taxIdChecked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 45, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46, name: "sex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 47, name: "twoFactorVerification", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerProfileResponse
 */
export const GetPlayerProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerProfileResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.PaymentPlayerDataShort
 */
export const PaymentPlayerDataShort = /*@__PURE__*/ proto3.makeMessageType(
  "players.PaymentPlayerDataShort",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "certifiedPlayer", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "certifiedPlayerUpdated", kind: "message", T: Timestamp },
    { no: 8, name: "privilegeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "lastDepositTime", kind: "message", T: Timestamp },
    { no: 11, name: "lastDepositTimeBet", kind: "message", T: Timestamp },
    { no: 12, name: "lastMoneyoutTime", kind: "message", T: Timestamp },
    { no: 13, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 14, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 15, name: "registerDate", kind: "message", T: Timestamp },
    { no: 16, name: "withdrawCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 17, name: "withdrawCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerPaymentResponse
 */
export const GetPlayerPaymentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPaymentResponse",
  () => [
    { no: 1, name: "shortData", kind: "message", T: PaymentPlayerDataShort },
    { no: 2, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "currencyId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "casinoStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "bettingStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "accountVerifiedStatus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "accountVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "twoFactorVerification", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 17, name: "dayLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "depositCountSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 20, name: "depositCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 21, name: "emailVerified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "cpfProfile", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "blockSendDataToPartner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 28, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 29, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "taxIdChecked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "restrictedSportBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "middleName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "phoneVerified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 35, name: "vipLocation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "monthLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerPaymentFullResponse
 */
export const GetPlayerPaymentFullResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPaymentFullResponse",
  () => [
    { no: 1, name: "fullData", kind: "message", T: GetPlayerPaymentResponse },
    { no: 2, name: "depositSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "depositStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "registerDate", kind: "message", T: Timestamp },
    { no: 6, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "casinoBankStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "activeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "sex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "percentMoneyOut", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "offAWForPlayer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "offAWForPlayerReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "offAWUpdated", kind: "message", T: Timestamp },
    { no: 14, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "taxId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.CheckUpPlayerDataResponse
 */
export const CheckUpPlayerDataResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.CheckUpPlayerDataResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "mobilePhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerIDsByNameResponse
 */
export const GetPlayerIDsByNameResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerIDsByNameResponse",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetAMLStatusHistoryInfoRequest
 */
export const GetAMLStatusHistoryInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetAMLStatusHistoryInfoRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetAMLStatusHistoryInfoResponse
 */
export const GetAMLStatusHistoryInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetAMLStatusHistoryInfoResponse",
  () => [
    { no: 1, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message players.SetPlayerFieldsRequest
 */
export const SetPlayerFieldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetPlayerFieldsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "silent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "changedFields", kind: "message", T: Struct },
  ],
);

/**
 * @generated from message players.SetPlayerFieldsResponse
 */
export const SetPlayerFieldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetPlayerFieldsResponse",
  () => [
    { no: 1, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message players.GetProfileRequest
 */
export const GetProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetProfileRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetProfileResponse
 */
export const GetProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetProfileResponse",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message players.GetPlayerSessionRequest
 */
export const GetPlayerSessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerSessionRequest",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerSessionResponse
 */
export const GetPlayerSessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerSessionResponse",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "AccountID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "SessionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "IP", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "City", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "Region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "BrowserInfo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "Browser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "Os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "LanguageInfo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "Timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "ScreenSize", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "DoNotTrack", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "IsOpen", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "IsActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "Created", kind: "message", T: Timestamp },
    { no: 19, name: "Updated", kind: "message", T: Timestamp },
    { no: 20, name: "Deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "Project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "GoogleScore", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 24, name: "Agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 25, name: "Closed", kind: "message", T: Timestamp },
    { no: 26, name: "StatusClosed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 28, name: "Webview", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerBirthdayRequest
 */
export const GetPlayerBirthdayRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBirthdayRequest",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerBirthdayResponse
 */
export const GetPlayerBirthdayResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerBirthdayResponse",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "Birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerForReactivationTrackerCheckRequest
 */
export const GetPlayerForReactivationTrackerCheckRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerForReactivationTrackerCheckRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerForReactivationTrackerCheckResponse
 */
export const GetPlayerForReactivationTrackerCheckResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerForReactivationTrackerCheckResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "lastDepositTimeBet", kind: "message", T: Timestamp },
    { no: 3, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 4, name: "lastDepositTime", kind: "message", T: Timestamp },
    { no: 5, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 6, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "registrationDate", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message players.SetAffiliateFieldsRequest
 */
export const SetAffiliateFieldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetAffiliateFieldsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "streamId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "share", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "instID", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message players.SetAffiliateFieldsResponse
 */
export const SetAffiliateFieldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetAffiliateFieldsResponse",
  () => [
    { no: 1, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerPromoCodeResponse
 */
export const GetPlayerPromoCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerPromoCodeResponse",
  () => [
    { no: 1, name: "player", kind: "message", T: PlayerPromoCode },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayersPromoCodeResponse
 */
export const GetPlayersPromoCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayersPromoCodeResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: PlayerPromoCode, repeated: true },
    { no: 2, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.GetPlayerDigitainResponse
 */
export const GetPlayerDigitainResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerDigitainResponse",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "currencyId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 10, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "freebetEnable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "sportBonusesInUse", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "betLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "digitainStatusId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 50, name: "error", kind: "message", T: Error },
  ],
);

/**
 * @generated from message players.PlayerPromoCode
 */
export const PlayerPromoCode = /*@__PURE__*/ proto3.makeMessageType(
  "players.PlayerPromoCode",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 6, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "restrictedSportBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "promoCodeList", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "depositCountSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "depositCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 12, name: "depositCount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 13, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "clickID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "registerDate", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message players.SetMonthLimitRequest
 */
export const SetMonthLimitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetMonthLimitRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "monthLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message players.SetMonthLimitResponse
 */
export const SetMonthLimitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetMonthLimitResponse",
  [],
);

/**
 * @generated from message players.GetMonthLimitRequest
 */
export const GetMonthLimitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetMonthLimitRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetMonthLimitResponse
 */
export const GetMonthLimitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetMonthLimitResponse",
  () => [
    { no: 1, name: "monthLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message players.SetIsTestRequest
 */
export const SetIsTestRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetIsTestRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message players.SetIsTestResponse
 */
export const SetIsTestResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetIsTestResponse",
  [],
);

/**
 * @generated from message players.GetAutoWithdrawalStatusRequest
 */
export const GetAutoWithdrawalStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetAutoWithdrawalStatusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.SetAutoWithdrawalStatusRequest
 */
export const SetAutoWithdrawalStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetAutoWithdrawalStatusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "offAWForPlayer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "offAWForPlayerReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "commentAuthor", kind: "message", T: CommentAuthor },
  ],
);

/**
 * @generated from message players.GetAutoWithdrawalStatusResponse
 */
export const GetAutoWithdrawalStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetAutoWithdrawalStatusResponse",
  () => [
    { no: 1, name: "offAWForPlayer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "offAWForPlayerReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "offAWUpdated", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message players.SetAutoWithdrawalStatusResponse
 */
export const SetAutoWithdrawalStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.SetAutoWithdrawalStatusResponse",
  [],
);

/**
 * @generated from message players.GetPlayerTransactionRequest
 */
export const GetPlayerTransactionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerTransactionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerTransactionResponse
 */
export const GetPlayerTransactionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerTransactionResponse",
  () => [
    { no: 1, name: "transaction", kind: "message", T: Transaction },
  ],
);

/**
 * @generated from message players.GetPlayerStatusRequest
 */
export const GetPlayerStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerStatusRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetPlayerStatusResponse
 */
export const GetPlayerStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetPlayerStatusResponse",
  () => [
    { no: 1, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "isVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "lastLogin", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message players.GetFantasySportPlayerRequest
 */
export const GetFantasySportPlayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetFantasySportPlayerRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message players.GetFantasySportPlayerResponse
 */
export const GetFantasySportPlayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.GetFantasySportPlayerResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "currencyId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message players.ProcessTransactionResponse
 */
export const ProcessTransactionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.ProcessTransactionResponse",
  [],
);

