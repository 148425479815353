import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { GetPlayerNotesByIdResponse } from '@pu/grpc';
import {
  GetPlayerNotesByIdRequest,
  PlayerNotesLogsRemoteService,
} from '@pu/grpc';

@Injectable({ providedIn: 'root' })
export class NotesProvider {
  private readonly notesRemoteService = inject(PlayerNotesLogsRemoteService);

  public getPlayerNotesById(
    ids: bigint[],
  ): Observable<GetPlayerNotesByIdResponse> {
    return this.notesRemoteService.getPlayerNotesById(
      new GetPlayerNotesByIdRequest({ ids }),
    );
  }
}
