// @generated by protoc-gen-es v1.10.0
// @generated from file services/authHash.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message players.AuthorizationByHashRequest
 */
export const AuthorizationByHashRequest = /*@__PURE__*/ proto3.makeMessageType(
  "players.AuthorizationByHashRequest",
  () => [
    { no: 1, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fpSessionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sessionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "browserInfo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "languageInfo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "browser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "ipDetectedCountry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "ipDetectedRegion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "ipDetectedCity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "screenSize", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "doNotTrack", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "googleScore", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "webview", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "fcmToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message players.AuthorizationByHashResponse
 */
export const AuthorizationByHashResponse = /*@__PURE__*/ proto3.makeMessageType(
  "players.AuthorizationByHashResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

