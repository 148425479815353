// @generated by protoc-gen-es v1.10.0
// @generated from file base/transaction.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message players.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "players.Transaction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currencyId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "sportBonusesInUse", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "freebetAvailable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "freebetEnable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "freebetBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "balance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "bonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "betBonusBalance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "pincoinsBalanceSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "restrictedSportReal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "restrictedSportBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "restrictedCasinoReal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "betCountSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 18, name: "betCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 19, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 21, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 26, name: "share", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "casinoBet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "accountVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "additionalOptions", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ],
);

