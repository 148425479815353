import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'noData',
  standalone: true,
})
export class NoDataPipe implements PipeTransform {
  public transform<T extends bigint | number | string | undefined>(
    value: T | null,
  ): T | string {
    return value ?? 'No data';
  }
}
