// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/google_auth/mfa/service.proto (package pinup.google_auth.mfa, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { Enable2FARequest, Enable2FAResponse, RefreshTokenRequest, RefreshTokenResponse, Remove2FARequest, Remove2FAResponse, Reset2FARequest, Reset2FAResponse, SignInRequest, SignInResponse, Verify2FARequest, Verify2FAResponse } from "./service_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.google_auth.mfa.AuthWith2FAService
 */
@Injectable({ providedIn: "root" })
export class AuthWith2FARemoteService {
  public static readonly typeName = "pinup.google_auth.mfa.AuthWith2FAService";

  public static readonly methods = {
    signIn: {
      name: "SignIn",
      I: SignInRequest,
      O: SignInResponse,
      kind: MethodKind.Unary,
    },
    enable2FA: {
      name: "Enable2FA",
      I: Enable2FARequest,
      O: Enable2FAResponse,
      kind: MethodKind.Unary,
    },
    verify2FA: {
      name: "Verify2FA",
      I: Verify2FARequest,
      O: Verify2FAResponse,
      kind: MethodKind.Unary,
    },
    reset2FA: {
      name: "Reset2FA",
      I: Reset2FARequest,
      O: Reset2FAResponse,
      kind: MethodKind.Unary,
    },
    remove2FA: {
      name: "Remove2FA",
      I: Remove2FARequest,
      O: Remove2FAResponse,
      kind: MethodKind.Unary,
    },
    refreshToken: {
      name: "RefreshToken",
      I: RefreshTokenRequest,
      O: RefreshTokenResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.google_auth.mfa.AuthWith2FAService.SignIn
   */
  public signIn(
    input: PartialMessage<SignInRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SignInResponse> {
    return unary<SignInRequest, SignInResponse>(
      this.transport,
      AuthWith2FARemoteService,
      AuthWith2FARemoteService.methods.signIn,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.google_auth.mfa.AuthWith2FAService.Enable2FA
   */
  public enable2FA(
    input: PartialMessage<Enable2FARequest>,
    options?: ReactiveTransportOptions,
  ): Observable<Enable2FAResponse> {
    return unary<Enable2FARequest, Enable2FAResponse>(
      this.transport,
      AuthWith2FARemoteService,
      AuthWith2FARemoteService.methods.enable2FA,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.google_auth.mfa.AuthWith2FAService.Verify2FA
   */
  public verify2FA(
    input: PartialMessage<Verify2FARequest>,
    options?: ReactiveTransportOptions,
  ): Observable<Verify2FAResponse> {
    return unary<Verify2FARequest, Verify2FAResponse>(
      this.transport,
      AuthWith2FARemoteService,
      AuthWith2FARemoteService.methods.verify2FA,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.google_auth.mfa.AuthWith2FAService.Reset2FA
   */
  public reset2FA(
    input: PartialMessage<Reset2FARequest>,
    options?: ReactiveTransportOptions,
  ): Observable<Reset2FAResponse> {
    return unary<Reset2FARequest, Reset2FAResponse>(
      this.transport,
      AuthWith2FARemoteService,
      AuthWith2FARemoteService.methods.reset2FA,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.google_auth.mfa.AuthWith2FAService.Remove2FA
   */
  public remove2FA(
    input: PartialMessage<Remove2FARequest>,
    options?: ReactiveTransportOptions,
  ): Observable<Remove2FAResponse> {
    return unary<Remove2FARequest, Remove2FAResponse>(
      this.transport,
      AuthWith2FARemoteService,
      AuthWith2FARemoteService.methods.remove2FA,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.google_auth.mfa.AuthWith2FAService.RefreshToken
   */
  public refreshToken(
    input: PartialMessage<RefreshTokenRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<RefreshTokenResponse> {
    return unary<RefreshTokenRequest, RefreshTokenResponse>(
      this.transport,
      AuthWith2FARemoteService,
      AuthWith2FARemoteService.methods.refreshToken,
      input,
      options,
    );
  }
}
