// @generated by protoc-gen-es v1.10.0
// @generated from file base/session.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { SessionStatus } from "./enum_collision_0_pb.js";

/**
 * @generated from message players.Session
 */
export const Session = /*@__PURE__*/ proto3.makeMessageType(
  "players.Session",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "browser_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "browser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "language_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "screen_size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "do_not_track", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "google_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 18, name: "agent", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 19, name: "status_closed", kind: "enum", T: proto3.getEnumType(SessionStatus) },
    { no: 20, name: "is_open", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "closed", kind: "message", T: Timestamp },
    { no: 24, name: "updated", kind: "message", T: Timestamp },
    { no: 25, name: "created", kind: "message", T: Timestamp },
  ],
);

