import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import type { Timestamp } from '@bufbuild/protobuf';

import { ToDatePipe } from '@pu/sdk';

@Component({
  selector: 'bo-date-time',
  standalone: true,
  imports: [ToDatePipe, DatePipe],
  templateUrl: './date-time.component.html',
  styleUrl: './date-time.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent {
  public value = input.required<Date | Timestamp | number>();
  public emphasize = input<'date' | 'time'>('date');
}
