import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'puHighlightMatch',
  standalone: true,
})
export class PuHighlightMatchPipe implements PipeTransform {
  public transform(value: string, query: string): string {
    if (!query.trim()) {
      return value;
    }

    const regexp = new RegExp(query, 'ig');

    return value.replace(regexp, (match) => `<mark>${match}</mark>`);
  }
}
