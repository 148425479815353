// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file pinup/stat/report/casino/casino.proto (package pinup.stat.report.casino, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { ListTransactionsRequest, ListTransactionsResponse } from "./casino_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.stat.report.casino.CasinoService
 */
@Injectable({ providedIn: "root" })
export class CasinoRemoteService {
  public static readonly typeName = "pinup.stat.report.casino.CasinoService";

  public static readonly methods = {
    listTransactions: {
      name: "ListTransactions",
      I: ListTransactionsRequest,
      O: ListTransactionsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * ----------------------------------------------------------------
   * ListTransactions - List of player`s casino transactions filtered with pagination
   * ----------------------------------------------------------------
   * Format: proto
   * Access: GRPC
   * ----------------------------------------------------------------
   *
   * @generated from rpc pinup.stat.report.casino.CasinoService.ListTransactions
   */
  public listTransactions(
    input: PartialMessage<ListTransactionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ListTransactionsResponse> {
    return unary<ListTransactionsRequest, ListTransactionsResponse>(
      this.transport,
      CasinoRemoteService,
      CasinoRemoteService.methods.listTransactions,
      input,
      options,
    );
  }
}
