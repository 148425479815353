// @generated by protoc-gen-es v1.10.0
// @generated from file services/player_finance.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Int64Range } from "../base/filter_pb.js";
import { Error } from "../base/error_pb.js";

/**
 * Player statistic event
 *
 * @generated from message stat.Balance
 */
export const Balance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.Balance",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sumInCommonCurrency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message stat.GetTotalBalanceRequest
 */
export const GetTotalBalanceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetTotalBalanceRequest",
  () => [
    { no: 1, name: "commonCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date", kind: "message", T: Timestamp },
    { no: 3, name: "filter", kind: "message", T: GetTotalBalanceRequest_Filter },
  ],
);

/**
 * optional filter
 *
 * @generated from message stat.GetTotalBalanceRequest.Filter
 */
export const GetTotalBalanceRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetTotalBalanceRequest.Filter",
  () => [
    { no: 1, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "sumRange", kind: "message", T: Int64Range },
  ],
  {localName: "GetTotalBalanceRequest_Filter"},
);

/**
 * @generated from message stat.GetTotalBalanceResponse
 */
export const GetTotalBalanceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.GetTotalBalanceResponse",
  () => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 2, name: "items", kind: "message", T: Balance, repeated: true },
    { no: 3, name: "error", kind: "message", T: Error },
  ],
);

