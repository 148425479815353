// @generated by protoc-gen-es v1.10.0
// @generated from file service/backoffice/common/common.proto (package payments.backoffice.common, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message payments.backoffice.common.SumCurrency
 */
export const SumCurrency = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.common.SumCurrency",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message payments.backoffice.common.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.common.PaginationRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "pageSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message payments.backoffice.common.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.backoffice.common.PaginationResponse",
  () => [
    { no: 1, name: "currentPage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "totalPages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "totalItems", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

