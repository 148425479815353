// @generated by protoc-gen-es v1.10.0
// @generated from file services/bo/admin/players.proto (package stat.bo.admin, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, proto3, Timestamp } from "@bufbuild/protobuf";
import { AccountVerifySource, AccountVerifyState, ActivityStatus, BettingStatus, CasinoStatus, CertifiedState, DepositStatus, EmailCheckState, Gender, HaveBets, MaStatus, PhoneCheckedState, PrivilegeStatus, SmsStatus, TwoFactorVerification } from "./enum_pb.js";
import { SumCurrency } from "../../../base/models_pb.js";
import { StringSearch, SumRange, TimeRange } from "../../../base/filter_pb.js";
import { Sorting } from "../../../base/sorting_pb.js";
import { PaginationRequest, PaginationResponse } from "../../../base/pagination_pb.js";
import { Project } from "../../../base/enum_pb.js";

/**
 * @generated from enum stat.bo.admin.BlockReason
 */
export const BlockReason = /*@__PURE__*/ proto3.makeEnum(
  "stat.bo.admin.BlockReason",
  [
    {no: 0, name: "BR_UNSPECIFIED"},
    {no: 1, name: "BR_SELF_LOCK"},
    {no: 2, name: "BR_MULTI_ACCOUNT"},
    {no: 3, name: "BR_FIN_FRAUD"},
    {no: 4, name: "BR_LOSS_OF_ACCESS"},
    {no: 5, name: "BR_GROUP_CONSPIRACY"},
    {no: 6, name: "BR_HACKING"},
    {no: 7, name: "BR_MERGE"},
    {no: 8, name: "BR_BONUS_ABUSE"},
    {no: 9, name: "BR_AUTOMATIC_INACTIVITY_BLOCK"},
    {no: 10, name: "BR_OTHER_VIOLATION"},
    {no: 11, name: "BR_FAILED_LOGINS"},
    {no: 12, name: "BR_RUS_FIN_MON_BLOCK"},
    {no: 13, name: "BR_MONITORING_BLOCK"},
  ],
);

/**
 * @generated from message stat.bo.admin.CertifiedStatus
 */
export const CertifiedStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.CertifiedStatus",
  () => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(CertifiedState) },
    { no: 2, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.admin.AccountStatus
 */
export const AccountStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.AccountStatus",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(AccountVerifyState) },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(AccountVerifySource) },
    { no: 4, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.admin.EmailStatus
 */
export const EmailStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.EmailStatus",
  () => [
    { no: 1, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(EmailCheckState) },
    { no: 3, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.admin.PhoneStatus
 */
export const PhoneStatus = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.PhoneStatus",
  () => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(PhoneCheckedState) },
    { no: 2, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.admin.Status
 */
export const Status = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Status",
  () => [
    { no: 1, name: "account", kind: "message", T: AccountStatus },
    { no: 2, name: "email", kind: "message", T: EmailStatus },
    { no: 3, name: "phone", kind: "message", T: PhoneStatus },
    { no: 4, name: "certified", kind: "message", T: CertifiedStatus },
    { no: 5, name: "activity", kind: "enum", T: proto3.getEnumType(ActivityStatus) },
    { no: 6, name: "deposit", kind: "enum", T: proto3.getEnumType(DepositStatus) },
    { no: 7, name: "casino", kind: "enum", T: proto3.getEnumType(CasinoStatus) },
    { no: 8, name: "betting", kind: "enum", T: proto3.getEnumType(BettingStatus) },
    { no: 9, name: "privilege", kind: "enum", T: proto3.getEnumType(PrivilegeStatus) },
    { no: 10, name: "ma", kind: "enum", T: proto3.getEnumType(MaStatus) },
    { no: 11, name: "sms", kind: "enum", T: proto3.getEnumType(SmsStatus) },
    { no: 12, name: "digitainStatusId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message stat.bo.admin.Location
 */
export const Location = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Location",
  () => [
    { no: 1, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "postCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "house", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Player auto withdrawal status
 *
 * @generated from message stat.bo.admin.AutoWithdrawal
 */
export const AutoWithdrawal = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.AutoWithdrawal",
  () => [
    { no: 1, name: "off", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stat.bo.admin.Finance
 */
export const Finance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Finance",
  () => [
    { no: 3, name: "profitCasino", kind: "message", T: SumCurrency },
    { no: 4, name: "profitSport", kind: "message", T: SumCurrency },
    { no: 5, name: "withdrawals", kind: "message", T: SumCurrency },
    { no: 6, name: "deposits", kind: "message", T: SumCurrency },
    { no: 2, name: "bonusBalance", kind: "message", T: SumCurrency },
    { no: 7, name: "casinoBonusBalance", kind: "message", T: SumCurrency },
    { no: 8, name: "sportBonusBalance", kind: "message", T: SumCurrency },
    { no: 9, name: "lastTurnover", kind: "message", T: SumCurrency },
    { no: 11, name: "lastDeposit", kind: "message", T: SumCurrency },
    { no: 10, name: "totalTurnover", kind: "message", T: SumCurrency },
  ],
);

/**
 * @generated from message stat.bo.admin.Bonuses
 */
export const Bonuses = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Bonuses",
  () => [
    { no: 1, name: "bonusAddCountSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "bonusAddSumSport", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "bonusWageSumSport", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "freebetCountAdd", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "freebetSumAdd", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "freebetWinSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "bonusAddCountCasino", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 8, name: "bonusAddSumCasino", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "bonusWageSumCasino", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "freespinCountAdd", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "freespinSumAdd", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "freespinWinSum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "lotteryCountAdd", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "lotteryBonusWin", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "lotteryRealWinAdd", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "generalRFD", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message stat.bo.admin.Bets
 */
export const Bets = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Bets",
  () => [
    { no: 1, name: "betsCountSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "betsSumSport", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "betsCountCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "betsSumCasino", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "betsCountLive", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "betsSumLive", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "betsCountVirtual", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "betsSumVirtual", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "betsCountTvGames", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "betsSumTvGames", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message stat.bo.admin.Additional
 */
export const Additional = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Additional",
  () => [
    { no: 1, name: "emailVerified", kind: "message", T: BoolValue },
    { no: 2, name: "accountVerifiedStatus", kind: "message", T: BoolValue },
    { no: 3, name: "lastBetTimeSport", kind: "message", T: Timestamp },
    { no: 4, name: "lastBetTimeCasino", kind: "message", T: Timestamp },
    { no: 5, name: "lifetime", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "activeDaysNumSport", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "activeDaysNumCasino", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "totalActiveDays", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "partner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "clickId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "subId2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "subSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "instId", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "firstDep", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message stat.bo.admin.Block
 */
export const Block = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Block",
  () => [
    { no: 1, name: "sendEmail", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "sendSMS", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "sportTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "block180", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "casinoTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "subscribed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "reason", kind: "enum", T: proto3.getEnumType(BlockReason) },
  ],
);

/**
 * @generated from message stat.bo.admin.Player
 */
export const Player = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.Player",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "middleName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "registeredAt", kind: "message", T: Timestamp },
    { no: 7, name: "lastLogin", kind: "message", T: Timestamp },
    { no: 8, name: "login", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 13, name: "birthDay", kind: "message", T: Timestamp },
    { no: 14, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "isFullProfile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "twoFactorVerification", kind: "enum", T: proto3.getEnumType(TwoFactorVerification) },
    { no: 18, name: "status", kind: "message", T: Status },
    { no: 19, name: "location", kind: "message", T: Location },
    { no: 20, name: "autoWithdrawal", kind: "message", T: AutoWithdrawal },
    { no: 21, name: "finance", kind: "message", T: Finance },
    { no: 22, name: "convertedFinance", kind: "message", T: Finance },
    { no: 23, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "isProbablyGood", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "isHacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "block", kind: "message", T: Block },
    { no: 30, name: "Bonuses", kind: "message", T: Bonuses },
    { no: 31, name: "Bets", kind: "message", T: Bets },
    { no: 32, name: "Additional", kind: "message", T: Additional },
  ],
);

/**
 * @generated from message stat.bo.admin.ShortFinance
 */
export const ShortFinance = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.ShortFinance",
  () => [
    { no: 1, name: "balance", kind: "message", T: SumCurrency },
  ],
);

/**
 * PlayerShort is a short version of Player
 *
 * @generated from message stat.bo.admin.PlayerShort
 */
export const PlayerShort = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.PlayerShort",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "geo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "status", kind: "message", T: Status },
    { no: 10, name: "registeredAt", kind: "message", T: Timestamp },
    { no: 11, name: "lastLoginAt", kind: "message", T: Timestamp },
    { no: 12, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "finance", kind: "message", T: ShortFinance },
    { no: 15, name: "isVip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "isHacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "block", kind: "message", T: Block },
  ],
);

/**
 * @generated from message stat.bo.admin.ListPlayersRequest
 */
export const ListPlayersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.ListPlayersRequest",
  () => [
    { no: 1, name: "convertCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filter", kind: "message", T: ListPlayersRequest_Filter },
    { no: 3, name: "sorting", kind: "message", T: Sorting, repeated: true },
    { no: 4, name: "pagination", kind: "message", T: PaginationRequest },
  ],
);

/**
 * @generated from message stat.bo.admin.ListPlayersRequest.Filter
 */
export const ListPlayersRequest_Filter = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.ListPlayersRequest.Filter",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "projects", kind: "enum", T: proto3.getEnumType(Project), repeated: true },
    { no: 3, name: "geos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "currencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "displaySum", kind: "message", T: BoolValue },
    { no: 6, name: "haveBets", kind: "enum", T: proto3.getEnumType(HaveBets) },
    { no: 7, name: "fullProfile", kind: "message", T: BoolValue },
    { no: 8, name: "isTest", kind: "message", T: BoolValue },
    { no: 9, name: "isBlocked", kind: "message", T: BoolValue },
    { no: 10, name: "isMultiple", kind: "message", T: BoolValue },
    { no: 11, name: "isVip", kind: "message", T: BoolValue },
    { no: 12, name: "isHacked", kind: "message", T: BoolValue },
    { no: 13, name: "emailVerified", kind: "message", T: BoolValue },
    { no: 14, name: "accountVerified", kind: "message", T: BoolValue },
    { no: 15, name: "clickId", kind: "message", T: StringSearch },
    { no: 16, name: "subId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 17, name: "login", kind: "message", T: StringSearch },
    { no: 18, name: "firstName", kind: "message", T: StringSearch },
    { no: 19, name: "lastName", kind: "message", T: StringSearch },
    { no: 20, name: "email", kind: "message", T: StringSearch },
    { no: 21, name: "phone", kind: "message", T: StringSearch },
    { no: 22, name: "cpf", kind: "message", T: StringSearch },
    { no: 23, name: "promocodes", kind: "message", T: StringSearch, repeated: true },
    { no: 24, name: "registered", kind: "message", T: TimeRange },
    { no: 25, name: "lastLogin", kind: "message", T: TimeRange },
    { no: 26, name: "statistics", kind: "message", T: TimeRange },
    { no: 27, name: "firstDep", kind: "message", T: TimeRange },
    { no: 28, name: "profit", kind: "message", T: SumRange },
    { no: 29, name: "profits", kind: "message", T: SumRange },
    { no: 30, name: "profitc", kind: "message", T: SumRange },
    { no: 31, name: "profitv", kind: "message", T: SumRange },
    { no: 32, name: "profitlc", kind: "message", T: SumRange },
    { no: 33, name: "profittv", kind: "message", T: SumRange },
    { no: 34, name: "balance", kind: "message", T: SumRange },
    { no: 35, name: "depCount", kind: "message", T: SumRange },
    { no: 36, name: "depSum", kind: "message", T: SumRange },
    { no: 37, name: "betsCountCasino", kind: "message", T: SumRange },
    { no: 38, name: "betsCountSport", kind: "message", T: SumRange },
    { no: 39, name: "status", kind: "message", T: Status },
  ],
  {localName: "ListPlayersRequest_Filter"},
);

/**
 * @generated from message stat.bo.admin.ListPlayersResponse
 */
export const ListPlayersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.bo.admin.ListPlayersResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: Player, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ],
);

