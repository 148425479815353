import { inject, Injectable } from '@angular/core';
import type { Operator } from '@pinup-grpc/pinup/product/backoffice_operator/base/base_pb';
import { OperatorInfoRemoteService } from '@pinup-grpc/pinup/product/backoffice_operator/operator/operator_info_ng';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OperatorInfoProvider {
  private readonly operatorInfoRemoteService = inject(
    OperatorInfoRemoteService,
  );

  public getOperators(ids: bigint[] = []): Observable<Operator[]> {
    return this.operatorInfoRemoteService
      .getOperatorByIds({ ids })
      .pipe(map(({ operators }) => operators));
  }
}
