import type { Provider } from '@angular/core';
import {
  EnvironmentInjector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import type { Interceptor } from '@connectrpc/connect';

import { provideMultiFactory } from '@pu/sdk';

import type { GrpcFeature } from './grpc-feature';
import { grpcFeature, GrpcFeatureKind } from './grpc-feature';
import { GRPC_INTERCEPTORS } from './grpc-interceptors';

export function withGrpcInterceptors(
  interceptors: readonly Interceptor[],
): GrpcFeature<GrpcFeatureKind.INTERCEPTORS> {
  const providers: Provider[] = [];

  for (const interceptor of interceptors) {
    // In connectrpc the order of interceptors is reversed, we reverse it again
    // to synchronize with the drive in angular.
    providers.unshift(
      provideMultiFactory(GRPC_INTERCEPTORS, () => {
        const injector = inject(EnvironmentInjector);

        return (next) =>
          runInInjectionContext(injector, () => interceptor(next));
      }),
    );
  }

  return grpcFeature(GrpcFeatureKind.INTERCEPTORS, providers);
}
