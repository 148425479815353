import type { Signal } from '@angular/core';
import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs';

export const injectCurrencyConverted = (): Signal<boolean> => {
  const converted$ = inject(ActivatedRoute).params.pipe(
    map(({ currencyConverted }) => currencyConverted === 'true'),
    distinctUntilChanged(),
  );

  return toSignal(converted$, { requireSync: true });
};
