// @generated by protoc-gen-es v1.10.0
// @generated from file base/bo/v1/achievement_enum.proto (package gamification.achievement_manager.base.bo.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of enumeration for achievement status
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.AchievementStatus
 */
export const AchievementStatus = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.AchievementStatus",
  [
    {no: 0, name: "ACHIEVEMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_STATUS_PENDING", localName: "PENDING"},
    {no: 2, name: "ACHIEVEMENT_STATUS_PUBLISHED", localName: "PUBLISHED"},
    {no: 3, name: "ACHIEVEMENT_STATUS_DRAFT", localName: "DRAFT"},
  ],
);

/**
 * definition of enumeration for achievement action
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.AchievementAction
 */
export const AchievementAction = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.AchievementAction",
  [
    {no: 0, name: "ACHIEVEMENT_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_ACTION_DELETE", localName: "DELETE"},
    {no: 2, name: "ACHIEVEMENT_ACTION_RESTORE", localName: "RESTORE"},
    {no: 3, name: "ACHIEVEMENT_ACTION_UNPUBLISH", localName: "UNPUBLISH"},
  ],
);

/**
 * achievement task accomplishment order
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.AccomplishmentOrder
 */
export const AccomplishmentOrder = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.AccomplishmentOrder",
  [
    {no: 0, name: "ACCOMPLISHMENT_ORDER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACCOMPLISHMENT_ORDER_STRICT", localName: "STRICT"},
    {no: 2, name: "ACCOMPLISHMENT_ORDER_RANDOM", localName: "RANDOM"},
  ],
);

/**
 * definition of enumeration for achievement create action
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.AchievementCreateAction
 */
export const AchievementCreateAction = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.AchievementCreateAction",
  [
    {no: 0, name: "ACHIEVEMENT_CREATE_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_CREATE_ACTION_SAVE_AS_DRAFT", localName: "SAVE_AS_DRAFT"},
    {no: 2, name: "ACHIEVEMENT_CREATE_ACTION_PUBLISH", localName: "PUBLISH"},
  ],
);

/**
 * definition of enumeration for achievement copy action
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.AchievementCopyAction
 */
export const AchievementCopyAction = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.AchievementCopyAction",
  [
    {no: 0, name: "ACHIEVEMENT_COPY_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_COPY_ACTION_SAVE_AS_DRAFT", localName: "SAVE_AS_DRAFT"},
    {no: 2, name: "ACHIEVEMENT_COPY_ACTION_PUBLISH", localName: "PUBLISH"},
  ],
);

/**
 * definition of enumeration for achievement create action
 *
 * @generated from enum gamification.achievement_manager.base.bo.v1.AchievementUpdateAction
 */
export const AchievementUpdateAction = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.bo.v1.AchievementUpdateAction",
  [
    {no: 0, name: "ACHIEVEMENT_UPDATE_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_UPDATE_ACTION_SAVE_AS_DRAFT", localName: "SAVE_AS_DRAFT"},
    {no: 2, name: "ACHIEVEMENT_UPDATE_ACTION_PUBLISH", localName: "PUBLISH"},
  ],
);

