import {
  type EnvironmentProviders,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

import { provideValue } from '@pu/sdk';

export const CASHBOX_ADMIN_URL = new InjectionToken<string>(
  'CASHBOX_ADMIN_URL',
);

export const provideDevCashBoxAdminUrl = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideValue(
      CASHBOX_ADMIN_URL,
      'https://testcashbox.pin-upcashbox.com/admin',
    ),
  ]);

export const provideProdCashBoxAdminUrl = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideValue(CASHBOX_ADMIN_URL, 'https://pin-upcashbox.com/admin'),
  ]);
