// @generated by protoc-gen-es v1.10.0
// @generated from file models/moneyout/status.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum payments.MoneyOutStatus
 */
export const MoneyOutStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.MoneyOutStatus",
  [
    {no: 0, name: "MoneyOutStatusProcess"},
    {no: 1, name: "MoneyOutStatusOK"},
    {no: 2, name: "MoneyOutStatusCancel"},
    {no: 3, name: "MoneyOutStatusCancelUser"},
    {no: 4, name: "MoneyOutStatusProcessAV"},
    {no: 5, name: "MoneyOutStatusRiskReview"},
  ],
);

/**
 * @generated from enum payments.MoneyOutPaymentStatus
 */
export const MoneyOutPaymentStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.MoneyOutPaymentStatus",
  [
    {no: 0, name: "MONEY_OUT_PAYMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MONEY_OUT_PAYMENT_STATUS_SUCCESSFUL", localName: "SUCCESSFUL"},
    {no: 2, name: "MONEY_OUT_PAYMENT_STATUS_FAILED", localName: "FAILED"},
    {no: 3, name: "MONEY_OUT_PAYMENT_STATUS_PROCESSING", localName: "PROCESSING"},
  ],
);

/**
 * @generated from enum payments.MoneyOutConfirmType
 */
export const MoneyOutConfirmType = /*@__PURE__*/ proto3.makeEnum(
  "payments.MoneyOutConfirmType",
  [
    {no: 0, name: "MONEY_OUT_CONFIRM_TYPE_AUTO", localName: "AUTO"},
    {no: 1, name: "MONEY_OUT_CONFIRM_TYPE_MANUAL", localName: "MANUAL"},
    {no: 2, name: "MONEY_OUT_CONFIRM_TYPE_MANUAL_CHANGED_AMOUNT", localName: "MANUAL_CHANGED_AMOUNT"},
  ],
);

/**
 * @generated from enum payments.MoneyOutRiskStatus
 */
export const MoneyOutRiskStatus = /*@__PURE__*/ proto3.makeEnum(
  "payments.MoneyOutRiskStatus",
  [
    {no: 0, name: "MONEY_OUT_RISK_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MONEY_OUT_RISK_STATUS_ACCEPTED", localName: "ACCEPTED"},
    {no: 2, name: "MONEY_OUT_RISK_STATUS_DECLINED", localName: "DECLINED"},
  ],
);

