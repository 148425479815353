import { DestroyRef, Directive, inject, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[puSuspense]',
  standalone: true,
  exportAs: 'puSuspense',
})
export class SuspenseDirective {
  private readonly destroyRef = inject(DestroyRef);

  private subscription = Subscription.EMPTY;
  private readonly loadingInternal = signal(false);

  public loading = this.loadingInternal.asReadonly();

  public readonly loadingChange = output<boolean>({
    alias: 'puSuspenseLoadingChange',
  });
  public readonly start = output({ alias: 'puSuspenseStart' });
  public readonly complete = output({ alias: 'puSuspenseComplete' });
  public readonly error = output<unknown>({ alias: 'puSuspenseError' });
  public readonly finalize = output({ alias: 'puSuspenseFinalize' });

  public run(action$: Observable<unknown>): void {
    if (this.subscription.closed) {
      this.loadingInternal.set(true);
      this.loadingChange.emit(true);
      this.start.emit();
    } else {
      this.subscription.unsubscribe();
    }

    this.subscription = action$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        complete: () => {
          this.loadingInternal.set(false);
          this.loadingChange.emit(false);
          this.finalize.emit();
          this.complete.emit();
        },
        error: (error) => {
          this.loadingInternal.set(false);
          this.loadingChange.emit(false);
          this.finalize.emit();
          this.error.emit(error);
        },
      });
  }
}
