<div class="flex align-items-center">
  <div class="note">
    <div
      class="note-icon flex align-items-center justify-center p-1 rounded white-color"
      [class]="department().key"
    >
      <bo-svg [size]="20" [unsafeSvgSource]="messageIcon" />
    </div>
    <div class="note-data flex align-items-center flex-wrap">
      <span>{{ department().label }}</span>
      <span class="bold label-color note-info">{{ note().adminFullName }}</span>
      <bo-svg
        class="label-color cursor-pointer"
        (click)="copy()"
        [size]="16"
        [unsafeSvgSource]="copyIcon"
      />
      @if (edited()) {
        <span class="label-color note-info">edited</span>
      }
      <span class="label-color note-info">
        {{ note().updatedAt | toDate | date: 'yyyy-MM-dd, HH:mm:ss' }}
      </span>
      @if (sameUser() && !editTimedOut()) {
        <button class="action" (click)="edit.emit()" type="button">
          <bo-svg [size]="16" [unsafeSvgSource]="edit2Icon" />
        </button>
      }
      <button class="action" (click)="pinnedToggle.emit()" type="button">
        <bo-svg
          [size]="16"
          [unsafeSvgSource]="pinned() ? unpinIcon : pinIcon"
        />
      </button>
    </div>
  </div>
</div>

<div class="note-message flex">{{ note().text }}</div>
