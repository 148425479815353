<form #form="ngForm">
  <div mat-dialog-title>
    {{ data.sameDay ? 'Select date' : 'Select date range' }}
  </div>

  <mat-dialog-content>
    <div class="py-4">
      @if (!data.sameDay) {
        <mat-form-field>
          <mat-label>Date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" ngModelGroup="range">
            <input
              [(ngModel)]="from"
              matStartDate
              name="from"
              placeholder="Start date"
            />
            <input
              [(ngModel)]="to"
              matEndDate
              name="to"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="picker" matIconSuffix />
          <mat-date-range-picker #picker />
        </mat-form-field>
      } @else {
        <mat-form-field>
          <mat-label>Date</mat-label>
          <input
            [(ngModel)]="from"
            [matDatepicker]="picker"
            matInput
            name="from"
          />
          <mat-datepicker-toggle [for]="picker" matIconSuffix />
          <mat-datepicker #picker />
        </mat-form-field>
      }
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button class="button" matDialogClose type="button">
      Cancel
    </button>
    <button mat-flat-button (click)="submit()" type="button">Apply</button>
  </mat-dialog-actions>
</form>
