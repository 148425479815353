import type { Provider } from '@angular/core';

export enum PaginationPanelFeatureKind {
  PersistenceFeature = 'PersistenceFeature',
  TotalInfoFeature = 'TotalInfoFeature',
  ConfigurationFeature = 'ConfigurationFeature',
}

export type PaginationPanelFeature<
  FeatureKind extends PaginationPanelFeatureKind,
> = Readonly<{
  kind: FeatureKind;
  providers: Provider[];
}>;
