import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SimpleAuthRemoteService } from '@pinup-grpc/pinup/google_auth/simple/service_ng';
import type { ObservableInput } from 'rxjs';
import {
  exhaustMap,
  filter,
  first,
  map,
  merge,
  Observable,
  of,
  retry,
  share,
  shareReplay,
  Subject,
  switchMap,
  timer,
} from 'rxjs';

import { ProductSession } from '@pu/grpc';

import { PATHNAME_PREFIX, PATHNAME_SEARCH_HASH } from './pathname-search-hash';
import { STORED_SESSION } from './stored-session';

const RETRY_COUNT = 5;

// TODO add mock
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly storedSession$ = inject(STORED_SESSION);

  private readonly simpleAuth = inject(SimpleAuthRemoteService);

  private readonly prefix = inject(PATHNAME_PREFIX);

  private readonly pathnameSearchHash = inject(PATHNAME_SEARCH_HASH);

  private readonly refresh$ = new Subject<string>();

  public readonly session$: Observable<ProductSession> = merge(
    this.refresh$.pipe(
      exhaustMap((refresh) =>
        this.simpleAuth
          .refreshToken({ refresh })
          .pipe(map(({ access }) => new ProductSession({ refresh, access }))),
      ),
      share({ connector: () => this.storedSession$ }),
      exhaustMap((session) => (session === null ? this.logout$ : of(session))),
    ),
    this.refresh$.pipe(map(() => null)),
  ).pipe(takeUntilDestroyed(), shareReplay(1), filter(Boolean));

  public logout$ = new Observable<never>(() => {
    const loginAppUrl = new URL(`${this.prefix}/`, location.origin);

    loginAppUrl.searchParams.set('url', this.pathnameSearchHash);

    this.storedSession$.next(null);
    location.replace(loginAppUrl);
  });

  public withAccess<R>(
    isUnauthenticated: (error: unknown) => boolean,
    handler: (access: string) => ObservableInput<R>,
  ): Observable<R> {
    return this.session$.pipe(
      first(),
      switchMap(({ access }) => handler(access)),
      retry({
        delay: (error, retryCount) => {
          if (!isUnauthenticated(error)) {
            throw error;
          }

          if (retryCount >= RETRY_COUNT) {
            return this.logout$;
          }

          // TODO avoid non null assertion
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- FIXME
          this.refresh$.next(this.storedSession$.getValue()!.refresh);

          return timer(10); // TODO is it make sense?
        },
      }),
    );
  }
}
