<div class="pagination-panel">
  @let totalPages = pagination().totalPages;
  @let pageSize = pagination().pageSize;
  @let currentPage = pagination().currentPage;

  @if (pageSize) {
    <bo-page-size-select
      (pageSizeChange)="onPageSizeChange($event)"
      [pageSize]="pageSize"
    />
  }

  <bo-pagination
    (pageChange)="onCurrentPageChange($event)"
    [pagination]="pagination()"
  />

  @if (totalPages) {
    <bo-go-to-pagination
      (pageChange)="onCurrentPageChange($event)"
      [currentPage]="currentPage"
      [totalPages]="totalPages"
    />
  }
</div>
