import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'bo-geo',
  host: {
    '[class.bordered]': 'bordered()',
  },
  standalone: true,
  imports: [IconComponent],
  templateUrl: './geo.component.html',
  styleUrl: './geo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoComponent {
  public value = input.required<string>();
  public bordered = input(false, { transform: booleanAttribute });

  protected readonly icon = computed(
    () => this.value().toLowerCase() + '-flag',
  );
}
