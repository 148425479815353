import type { OnInit } from '@angular/core';
import {
  DestroyRef,
  Directive,
  inject,
  input,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';

import type { TransferredContentId } from './transfer-content.model';
import { TransferContentService } from './transfer-content.service';

@Directive({
  selector: '[puContentOutlet]',
  standalone: true,
  exportAs: 'outlet',
})
export class ContentOutletDirective implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly viewContainer = inject(ViewContainerRef);
  private readonly service = inject(TransferContentService);

  public contentId = input.required<TransferredContentId>({
    alias: 'puContentOutlet',
  });

  #empty = true;
  get empty(): boolean {
    return this.#empty;
  }

  public ngOnInit(): void {
    this.service
      .getTransferContentSource$(this.contentId())
      .pipe(debounceTime(0), takeUntilDestroyed(this.destroyRef))
      .subscribe((template) => {
        this.viewContainer.clear();
        if (template) {
          this.viewContainer.createEmbeddedView(template);
        }
        this.#empty = !template;
      });
  }
}
