export type EnumDefinition<K extends string, V extends number | string> = {
  readonly [F in K]: V;
};

const valuesCache = new WeakMap<
  EnumDefinition<string, number | string>,
  ReadonlySet<number | string>
>();

const mapsCache = new WeakMap<
  EnumDefinition<string, number | string>,
  ReadonlyMap<string, number | string>
>();

const reverseMapsCache = new WeakMap<
  EnumDefinition<string, number | string>,
  Map<unknown, number | string>
>();

export function extractEnumValues<K extends string, V extends number | string>(
  definition: EnumDefinition<K, V>,
): ReadonlySet<V> {
  let result = valuesCache.get(definition) as ReadonlySet<V> | undefined;

  if (result === undefined) {
    result = new Set<V>(extractEnumEntries(definition).values());
    valuesCache.set(definition, result);
  }

  return result;
}

export function extractEnumEntries<K extends string, V extends number | string>(
  definition: EnumDefinition<K, V>,
): ReadonlyMap<K, V> {
  let result = mapsCache.get(definition) as Map<string, V> | undefined;

  if (result === undefined) {
    result = new Map<string, V>(Object.entries<V>(definition));

    for (const key in definition) {
      if (typeof definition[key] === 'number') {
        result.delete(String(definition[key]));
      }
    }

    mapsCache.set(definition, result);
  }

  return result as unknown as ReadonlyMap<K, V>;
}

export function extractEnumReverseEntries<
  K extends string,
  V extends number | string,
>(definition: EnumDefinition<K, V>): ReadonlyMap<V, K> {
  let result = reverseMapsCache.get(definition) as Map<V, K> | undefined;

  if (result === undefined) {
    result = new Map<V, K>();

    for (const key in definition) {
      if (isNaN(Number(key))) {
        const value = definition[key];

        if (typeof value === 'number' || typeof value === 'string') {
          result.set(value, key);
        }
      }
    }

    reverseMapsCache.set(definition, result);
  }

  return result as ReadonlyMap<V, K>;
}

export function isEnumValue<K extends string, V extends number | string>(
  definition: EnumDefinition<K, V>,
  value: unknown,
): value is V {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return extractEnumValues(definition).has(value as any);
}

export function enumToKey<K extends string, V extends number | string>(
  definition: EnumDefinition<K, V>,
  value: V,
): K {
  for (const [key, item] of extractEnumEntries(definition)) {
    if (item === value) {
      return key;
    }
  }

  throw new Error('Key not found');
}
