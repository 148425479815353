import type { Department, License } from '@bo/common';
import { AccessGroup } from '@bo/common';

interface RegexGroups {
  readonly department: string;
  readonly license: string;
  readonly route: string;
}

const REGEX = /^\/(?<department>[\w-]+)\/(?<license>[\w-]+)\/(?<route>.*)/;

export class DepartmentLicenseLink {
  public static parse(
    departments: readonly Department[],
    licenses: readonly License[],
    pathnameSearchHash: string,
  ): DepartmentLicenseLink | null {
    const parts = pathnameSearchHash.match(REGEX)?.groups as
      | RegexGroups
      | undefined;

    if (parts === undefined) {
      return null;
    }

    const department = departments.find(({ url }) => url === parts.department);

    if (department === undefined) {
      return null;
    }

    const license = licenses.find(({ url }) => url === parts.license);

    if (license === undefined) {
      return null;
    }

    return new DepartmentLicenseLink(department, license, parts.route);
  }

  public static create(
    department: Department,
    license: License,
  ): DepartmentLicenseLink {
    return new DepartmentLicenseLink(department, license, null);
  }

  public static onlyAvailable(
    links: readonly DepartmentLicenseLink[],
    groups: readonly AccessGroup[],
  ): DepartmentLicenseLink[] {
    return links.filter((link) => link.available(groups));
  }

  private constructor(
    public readonly department: Department,
    public readonly license: License,
    public readonly route: string | null,
  ) {}

  public available(groups: readonly AccessGroup[]): boolean {
    return AccessGroup.supports(this.department, this.license, groups);
  }

  get baseHref(): string {
    const department = encodeURIComponent(this.department.url);
    const license = encodeURIComponent(this.license.url);

    return `/${department}/${license}/`;
  }

  get href(): string {
    return `${this.baseHref}${this.route ?? this.department.redirectTo}`;
  }

  get fullTitle(): string {
    return `${this.department.title} (${this.license.title})`;
  }
}
