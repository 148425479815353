import { inject, InjectionToken } from '@angular/core';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';

import type { RouteParams } from '@bo/common';
import { DEFAULT_PAGINATION, ROUTE_PARAMS_PERSISTENCE } from '@bo/common';

import type { PaginationPanelPayload } from '../../../../pagination-panel.model';

export const PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE = new InjectionToken<
  Observable<PaginationPanelPayload>
>('PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE');

export const paginationPersistenceFactory: () => Observable<PaginationPanelPayload> =
  () =>
    inject(ROUTE_PARAMS_PERSISTENCE).pipe(
      map(({ page, pageSize }) => ({ page, pageSize })),
      distinctUntilChanged<RouteParams>(isEqual),
      map(({ page, pageSize }) => ({
        currentPage: page ? Number(page) : DEFAULT_PAGINATION.currentPage,
        pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGINATION.pageSize,
      })),
      shareReplay(1),
    );
