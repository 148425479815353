// @generated by protoc-gen-es v1.10.0
// @generated from file services/currencies/currencies.proto (package git.time2go.tech.apis.pinup.admin_api_gw.services.currencies, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.GetCurrencyRequest
 */
export const GetCurrencyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.GetCurrencyRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.ListCurrenciesRequest
 */
export const ListCurrenciesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.ListCurrenciesRequest",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.Currency
 */
export const Currency = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.Currency",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.GetCurrencyResponse
 */
export const GetCurrencyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.GetCurrencyResponse",
  () => [
    { no: 1, name: "currency", kind: "message", T: Currency },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.ListCurrenciesResponse
 */
export const ListCurrenciesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.admin_api_gw.services.currencies.ListCurrenciesResponse",
  () => [
    { no: 1, name: "currencies", kind: "message", T: Currency, repeated: true },
  ],
);

