import { Directive, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isEqual, isString } from 'lodash-es';

/**
 * @deprecated Use {@linkcode ValidationErrorPipe} instead
 */
@Directive({
  selector: '[controlValidator]',
  standalone: true,
  exportAs: 'controlValidator',
})
export class ControlValidatorDirective {
  private readonly control = inject(NgControl, { self: true });

  private previousErrors: readonly string[] = [];

  get errors(): readonly string[] | null {
    if (!this.control.invalid || !this.control.dirty) {
      return null;
    }

    const rawErrors = this.control.errors;

    if (rawErrors === null) {
      return null;
    }

    const errors: readonly string[] = Object.values(rawErrors).filter(isString);

    if (errors.length === 0) {
      return null;
    }

    if (isEqual(errors, this.previousErrors)) {
      return this.previousErrors;
    }

    this.previousErrors = errors;

    return errors;
  }
}
