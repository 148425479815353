// @generated by protoc-gen-es v1.10.0
// @generated from file common/enum.proto (package kycaid, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum kycaid.VerificationStatus
 */
export const VerificationStatus = /*@__PURE__*/ proto3.makeEnum(
  "kycaid.VerificationStatus",
  [
    {no: 0, name: "VERIFICATION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VERIFICATION_STATUS_IN_PROGRESS", localName: "IN_PROGRESS"},
    {no: 2, name: "VERIFICATION_STATUS_TIMEOUT", localName: "TIMEOUT"},
    {no: 3, name: "VERIFICATION_STATUS_UNUSED", localName: "UNUSED"},
    {no: 4, name: "VERIFICATION_STATUS_PENDING", localName: "PENDING"},
    {no: 5, name: "VERIFICATION_STATUS_COMPLETED", localName: "COMPLETED"},
  ],
);

/**
 * @generated from enum kycaid.VerificationType
 */
export const VerificationType = /*@__PURE__*/ proto3.makeEnum(
  "kycaid.VerificationType",
  [
    {no: 0, name: "VERIFICATION_TYPE_UNSPECIFIED"},
    {no: 1, name: "VERIFICATION_TYPE_UA_BANK_ID"},
    {no: 2, name: "VERIFICATION_TYPE_UA_BANK_ID_2"},
    {no: 3, name: "VERIFICATION_TYPE_DOCUMENTS"},
    {no: 4, name: "VERIFICATION_TYPE_DIIA"},
    {no: 5, name: "VERIFICATION_TYPE_TAX_ID"},
    {no: 6, name: "VERIFICATION_TYPE_CDD"},
    {no: 7, name: "VERIFICATION_TYPE_EDD"},
    {no: 8, name: "VERIFICATION_TYPE_EDD_WITHOUT_CDD"},
    {no: 9, name: "VERIFICATION_TYPE_PROFILE"},
    {no: 10, name: "VERIFICATION_TYPE_AML"},
    {no: 11, name: "VERIFICATION_TYPE_PAYMENT_METHOD"},
    {no: 12, name: "VERIFICATION_TYPE_COMPANY"},
    {no: 13, name: "VERIFICATION_TYPE_ADDRESS"},
    {no: 14, name: "VERIFICATION_TYPE_FACIAL"},
    {no: 15, name: "REVERIFICATION_TYPE_DOCUMENTS"},
    {no: 16, name: "REVERIFICATION_TYPE_UA_BANK_ID"},
    {no: 17, name: "REVERIFICATION_TYPE_UA_BANK_ID_2"},
    {no: 18, name: "REVERIFICATION_TYPE_DIIA"},
  ],
);

