import { Timestamp } from '@bufbuild/protobuf';
import { toDate } from 'date-fns';
import { isNil } from 'lodash-es';

export function toTimestamp(date: Date | number | undefined): Timestamp;
export function toTimestamp(
  date: Date | number | null | undefined,
): Timestamp | null;
export function toTimestamp(
  date: Date | number | null | undefined,
): Timestamp | null {
  return isNil(date) ? null : Timestamp.fromDate(toDate(date));
}

export function toFormDateFromString(
  date: number | string | null | undefined,
): Date | null {
  return date ? new Date(date) : null;
}

export function compareAscTimestamp(a: Timestamp, b: Timestamp): number {
  const diff = a.seconds - b.seconds || a.nanos - b.nanos;

  if (diff < 0) {
    return 1;
  } else if (diff > 0) {
    return -1;
  } else {
    return 0;
  }
}
