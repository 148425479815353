import { CdkScrollable } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pu-dialog-content, [pu-dialog-content]',
  standalone: true,
  template: `
    <ng-content />
  `,
  styleUrl: './dialog-content.component.scss',
  hostDirectives: [CdkScrollable],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DialogContentComponent {}
