import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

import { SvgComponent } from '@pu/sdk';
import type { BoFormGroup } from '@bo/common';
import { chevronHideIcon, downloadIcon, PageLayoutComponent } from '@bo/common';

@Component({
  selector: 'bo-filters-form-wrapper',
  templateUrl: './filters-form-wrapper.component.html',
  styleUrls: ['./filters-form-wrapper.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgComponent, MatButton, ReactiveFormsModule],
})
export class FiltersFormWrapperComponent<T extends object> {
  private readonly layout = inject(PageLayoutComponent);

  protected readonly downloadIcon = downloadIcon;
  public readonly chevronHideIcon = chevronHideIcon;

  public form = input.required<BoFormGroup<T>>();
  public readonly applyFilters = output();

  public hideFilters(): void {
    this.layout.hideSidebar();
  }
}
