// TODO collision https://git.time2go.tech/apis/pinup/stat/-/blob/main/base/enum.proto

// @generated by protoc-gen-es v1.10.0
// @generated from file base/enum_collision_0.proto (package players, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum players.SessionStatus
 */
export const SessionStatus = /*@__PURE__*/ proto3.makeEnum(
  "players.SessionStatus",
  [
    {no: 0, name: "SESSION_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 1, name: "SESSION_STATUS_USER_LOGOUT", localName: "USER_LOGOUT"},
    {no: 2, name: "SESSION_STATUS_AUTO_LOGOUT", localName: "AUTO_LOGOUT"},
  ],
);

/**
 * @generated from enum players.PlayerState
 */
export const PlayerState = /*@__PURE__*/ proto3.makeEnum(
  "players.PlayerState",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "ONLINE"},
    {no: 2, name: "OFFLINE"},
  ],
);

/**
 * @generated from enum players.SessionEventType
 */
export const SessionEventType = /*@__PURE__*/ proto3.makeEnum(
  "players.SessionEventType",
  [
    {no: 0, name: "SESSION_EVENT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SESSION_EVENT_TYPE_OPEN", localName: "OPEN"},
    {no: 2, name: "SESSION_EVENT_TYPE_CLOSE", localName: "CLOSE"},
  ],
);

