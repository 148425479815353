import { inject, Injectable } from '@angular/core';
import { PlayerRemoteService } from '@pinup-grpc/services/bo/players/players_ng';
import type {
  ListPlayersResponse,
  ListPlayerStatisticsResponse,
  Player,
} from '@pinup-grpc/services/bo/players/players_pb';
import { merge } from 'lodash-es';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';

import { DEFAULT_CONVERT_CURRENCY, PAGINATION_MAX } from '@bo/common';

import type { InfinityPaginationOptions } from '../player/infinity-pagination.model';
import type { ListPlayersFilter } from '../players-filter/players-filter.types';

@Injectable()
export class PlayersProvider {
  private readonly playersRemoteService = inject(PlayerRemoteService);

  public getPlayer(id: bigint): Observable<Player> {
    return this.playersRemoteService
      .getPlayer({ id, convertCurrency: DEFAULT_CONVERT_CURRENCY })
      .pipe(map(({ player }) => player));
  }

  public getPlayers(ids: bigint[]): Observable<ListPlayersResponse> {
    return this.playersRemoteService.listPlayers({
      convertCurrency: DEFAULT_CONVERT_CURRENCY,
      filter: { ids },
      pagination: {
        page: PAGINATION_MAX.currentPage,
        pageSize: PAGINATION_MAX.pageSize,
      },
    });
  }

  public listPlayerStatistics(
    filter: Partial<ListPlayersFilter>,
    { count, lastElementId }: InfinityPaginationOptions,
  ): Observable<ListPlayerStatisticsResponse> {
    return this.playersRemoteService.listPlayerStatistics({
      convertCurrency: DEFAULT_CONVERT_CURRENCY,
      pagination: { count, lastElementId },
      filter: merge(filter, {
        profile: {
          balance: {
            currency:
              filter.profile?.balance?.currency || DEFAULT_CONVERT_CURRENCY,
          },
        },
      }),
    });
  }
}
