// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/product/backoffice_operator/operator/operator_info.proto (package pinup.product.backoffice_operator.operator, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Operator } from "../base/base_pb.js";

/**
 * @generated from message pinup.product.backoffice_operator.operator.GetOperatorByIdsRequest
 */
export const GetOperatorByIdsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.GetOperatorByIdsRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.GetOperatorByIdsResponse
 */
export const GetOperatorByIdsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.GetOperatorByIdsResponse",
  () => [
    { no: 1, name: "operators", kind: "message", T: Operator, repeated: true },
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.GetOperatorBySubsRequest
 */
export const GetOperatorBySubsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.GetOperatorBySubsRequest",
  () => [
    { no: 1, name: "subs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.GetOperatorBySubsResponse
 */
export const GetOperatorBySubsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.GetOperatorBySubsResponse",
  () => [
    { no: 1, name: "operators", kind: "message", T: Operator, repeated: true },
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.GetOperatorByEmailsRequest
 */
export const GetOperatorByEmailsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.GetOperatorByEmailsRequest",
  () => [
    { no: 1, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.GetOperatorByEmailsResponse
 */
export const GetOperatorByEmailsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.GetOperatorByEmailsResponse",
  () => [
    { no: 1, name: "operators", kind: "message", T: Operator, repeated: true },
  ],
);

