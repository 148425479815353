// @generated by protoc-gen-es v1.10.0
// @generated from file base/pagination.proto (package stat, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message stat.BatchLimits
 */
export const BatchLimits = /*@__PURE__*/ proto3.makeMessageType(
  "stat.BatchLimits",
  () => [
    { no: 1, name: "limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "fromId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.PaginationRequest
 */
export const PaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PaginationRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "pageSize", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message stat.PaginationResponse
 */
export const PaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.PaginationResponse",
  () => [
    { no: 1, name: "currentPage", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "totalPages", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "totalItems", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message stat.InfinityPaginationRequest
 */
export const InfinityPaginationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "stat.InfinityPaginationRequest",
  () => [
    { no: 1, name: "lastElementId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message stat.InfinityPaginationResponse
 */
export const InfinityPaginationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "stat.InfinityPaginationResponse",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

