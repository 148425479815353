import type { ValueEqualityFn } from '@angular/core';
import { defaultEquals } from '@angular/core/primitives/signals';

export function toggleArrayValue<T>(
  list: readonly T[],
  value: T,
  match: ValueEqualityFn<T> = defaultEquals,
): T[] {
  const index = list.findIndex((item) => match(item, value));

  if (index === -1) {
    return [...list, value];
  } else {
    const result = list.slice();
    result.splice(index, 1);
    return result;
  }
}
