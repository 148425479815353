// @generated by protoc-gen-es v1.10.0
// @generated from file events/list.proto (package apiPlayerAnalysis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EnumValueOptions, MethodOptions, proto3 } from "@bufbuild/protobuf";

/**
 * Fire-and-forget [at most once] (import proto)
 *
 * @generated from enum apiPlayerAnalysis.NatsSubscribe
 */
export const NatsSubscribe = /*@__PURE__*/ proto3.makeEnum(
  "apiPlayerAnalysis.NatsSubscribe",
  [
    {no: 0, name: "AML_TRIGGER"},
  ],
);

/**
 * @generated from enum apiPlayerAnalysis.ServiceDependency
 */
export const ServiceDependency = /*@__PURE__*/ proto3.makeEnum(
  "apiPlayerAnalysis.ServiceDependency",
  [
    {no: 0, name: "SUSPICIOUS_DEPOSITS"},
  ],
);

/**
 * @generated from message apiPlayerAnalysis.RepeatedString
 */
export const RepeatedString = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.RepeatedString",
  () => [
    { no: 1, name: "List", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.RepeatedDependencies
 */
export const RepeatedDependencies = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.RepeatedDependencies",
  () => [
    { no: 1, name: "Services", kind: "enum", T: proto3.getEnumType(ServiceDependency), repeated: true },
  ],
);

/**
 * @generated from extension: apiPlayerAnalysis.RepeatedString Subject = 50000;
 */
export const Subject = proto3.makeExtension(
  "apiPlayerAnalysis.Subject", 
  EnumValueOptions, 
  () => ({ no: 50000, kind: "message", T: RepeatedString }),
);

/**
 * @generated from extension: string Stream = 50001;
 */
export const Stream = proto3.makeExtension(
  "apiPlayerAnalysis.Stream", 
  EnumValueOptions, 
  { no: 50001, kind: "scalar", T: 9 /* ScalarType.STRING */ },
);

/**
 * @generated from extension: string Consumer = 50002;
 */
export const Consumer = proto3.makeExtension(
  "apiPlayerAnalysis.Consumer", 
  EnumValueOptions, 
  { no: 50002, kind: "scalar", T: 9 /* ScalarType.STRING */ },
);

/**
 * @generated from extension: bool IsJetStreamTransport = 50003;
 */
export const IsJetStreamTransport = proto3.makeExtension(
  "apiPlayerAnalysis.IsJetStreamTransport", 
  EnumValueOptions, 
  { no: 50003, kind: "scalar", T: 8 /* ScalarType.BOOL */ },
);

/**
 * @generated from extension: apiPlayerAnalysis.RepeatedDependencies Dependencies = 50004;
 */
export const Dependencies = proto3.makeExtension(
  "apiPlayerAnalysis.Dependencies", 
  EnumValueOptions, 
  () => ({ no: 50004, kind: "message", T: RepeatedDependencies }),
);

/**
 * @generated from extension: apiPlayerAnalysis.NatsSubscribe Subscribe = 60001;
 */
export const Subscribe = proto3.makeExtension(
  "apiPlayerAnalysis.Subscribe", 
  MethodOptions, 
  () => ({ no: 60001, kind: "enum", T: proto3.getEnumType(NatsSubscribe) }),
);

/**
 * @generated from extension: string BulkMethod = 60003;
 */
export const BulkMethod = proto3.makeExtension(
  "apiPlayerAnalysis.BulkMethod", 
  MethodOptions, 
  { no: 60003, kind: "scalar", T: 9 /* ScalarType.STRING */ },
);

