import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatPseudoCheckbox } from '@angular/material/core';

import { chevronUpDownIcon, SvgComponent } from '@pu/sdk';
import {
  COLLAPSED_SIDEBAR_LICENSE_DROPDOWN_CONFIG,
  EXPANDED_SIDEBAR_LICENSE_DROPDOWN_CONFIG,
  PATHNAME_PREFIX,
} from '@bo/common';

import { DepartmentLicenseLink } from '../auth/department-license-link';
import { SIDEBAR_COLLAPSED } from '../layouts/sidebar-collapsed';

function hasMultiple<T, O>(
  list: readonly T[],
  handler: (item: T) => O,
): boolean {
  if (list.length === 0) {
    return false;
  }

  for (let first = handler(list[0]), index = 1; index < list.length; index++) {
    if (handler(list[index]) !== first) {
      return true;
    }
  }

  return false;
}

@Component({
  selector: 'bo-select-license',
  standalone: true,
  templateUrl: './select-license.component.html',
  styleUrl: './select-license.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.collapsed]': 'collapsed()',
  },
  imports: [
    MatPseudoCheckbox,
    CdkMenuItem,
    CdkMenuTrigger,
    CdkMenu,
    SvgComponent,
  ],
})
export class SelectLicenseComponent {
  protected readonly COLLAPSED_SIDEBAR_LICENSE_DROPDOWN_CONFIG =
    COLLAPSED_SIDEBAR_LICENSE_DROPDOWN_CONFIG;
  protected readonly EXPANDED_SIDEBAR_LICENSE_DROPDOWN_CONFIG =
    EXPANDED_SIDEBAR_LICENSE_DROPDOWN_CONFIG;
  protected readonly chevronUpDownIcon = chevronUpDownIcon;

  protected readonly selectedLink = inject(DepartmentLicenseLink);
  protected readonly collapsed = inject(SIDEBAR_COLLAPSED);
  protected readonly prefix = inject(PATHNAME_PREFIX);

  public readonly links = input.required<readonly DepartmentLicenseLink[]>();

  protected readonly multipleDepartments = computed(() => {
    return hasMultiple(this.links(), ({ department }) => department);
  });
}
