import type { KeyValue } from '@angular/common';

import type { Currency, Operator, PaymentMethod } from '@pu/grpc';
import type { AutocompleteEqual, Country } from '@pu/sdk';
import { highlightBy } from '@pu/sdk';

export const countryHighlight = highlightBy<Country>(
  ({ name }) => name,
  ({ alpha2 }) => alpha2,
);

export const currencyHighlight = highlightBy<Currency>(({ code }) => code);

export const operatorHighlight = highlightBy<Operator>(({ email }) => email);

export const keyValueHighlight = <K, V = unknown>(): AutocompleteEqual<
  KeyValue<K, V>
> => highlightBy<KeyValue<K, V>>(({ value }) => String(value));

export const paymentMethodHighlight = highlightBy<PaymentMethod>(
  ({ name }) => name,
);

export const simpleHighlight = highlightBy<string>((value) => value);
