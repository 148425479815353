// @generated by protoc-gen-es v1.10.0
// @generated from file service/money_out_settings.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { MoneyOutSettings } from "../models/moneyoutsettings/settings_pb.js";
import { MoneyOutSettingsInput } from "../models/moneyoutsettings/settings_input_pb.js";

/**
 * @generated from message payments.MoneyOutSettingsError
 */
export const MoneyOutSettingsError = /*@__PURE__*/ proto3.makeMessageType(
  "payments.MoneyOutSettingsError",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message payments.FindMoneyOutSettingsRequest
 */
export const FindMoneyOutSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.FindMoneyOutSettingsRequest",
  [],
);

/**
 * @generated from message payments.FindMoneyOutSettingsResponse
 */
export const FindMoneyOutSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.FindMoneyOutSettingsResponse",
  () => [
    { no: 1, name: "list", kind: "message", T: MoneyOutSettings, repeated: true },
    { no: 2, name: "error", kind: "message", T: MoneyOutSettingsError },
  ],
);

/**
 * @generated from message payments.UpsertListMoneyOutSettingsRequest
 */
export const UpsertListMoneyOutSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.UpsertListMoneyOutSettingsRequest",
  () => [
    { no: 1, name: "list", kind: "message", T: MoneyOutSettingsInput, repeated: true },
  ],
);

/**
 * @generated from message payments.UpsertListMoneyOutSettingsResponse
 */
export const UpsertListMoneyOutSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.UpsertListMoneyOutSettingsResponse",
  () => [
    { no: 1, name: "error", kind: "message", T: MoneyOutSettingsError },
  ],
);

/**
 * @generated from message payments.DeleteMoneyOutSettingsRequest
 */
export const DeleteMoneyOutSettingsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "payments.DeleteMoneyOutSettingsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message payments.DeleteMoneyOutSettingsResponse
 */
export const DeleteMoneyOutSettingsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "payments.DeleteMoneyOutSettingsResponse",
  () => [
    { no: 1, name: "error", kind: "message", T: MoneyOutSettingsError },
  ],
);

