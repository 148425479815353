import type { HighlightedText } from '@pu/sdk';

export function caseInsensitiveHighlight(
  source: string,
  search: string,
): HighlightedText {
  if (search.length === 0) {
    return ['', '', source];
  }

  const lowerSource = source.toLowerCase();
  const lowerSearch = search.toLowerCase();

  const fullIndex = lowerSource.indexOf(lowerSearch);

  if (fullIndex !== -1) {
    return [
      source.slice(0, fullIndex),
      source.slice(fullIndex, fullIndex + lowerSearch.length),
      source.slice(fullIndex + lowerSearch.length),
    ];
  }

  let start = lowerSource.indexOf(lowerSearch[0]);

  if (start === -1) {
    return [source];
  }

  const parts: string[] = [];
  let last = 0;
  let end = start + 1;

  for (let charIndex = 1; charIndex < lowerSearch.length; charIndex++) {
    const index = lowerSource.indexOf(lowerSearch[charIndex], end);

    if (index === -1) {
      return [source];
    }

    if (index === end) {
      end++;
      continue;
    }

    parts.push(source.slice(last, start), source.slice(start, end));

    last = end;
    start = index;
    end = index + 1;
  }

  parts.push(
    source.slice(last, start),
    source.slice(start, end),
    source.slice(end),
  );

  return parts;
}
