import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { PlayerRemoteService } from '@pinup-grpc/services/players/players_ng';
import type { Observable } from 'rxjs';

import {
  DIALOG_NOTE_COMMENT,
  DIALOG_NOTE_MAX_LENGTH,
  DIALOG_NOTE_MIN_LENGTH,
  DIALOG_NOTE_PINNED,
} from '@pu/pinup';
import {
  DialogContentComponent,
  DialogSuspenseDirective,
  InertSuspenseDirective,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  ValidationErrorPipe,
} from '@pu/sdk';

import { ChangePlayerStatus } from './change-player-status.config';

export interface ChangePlayerStatusData {
  readonly status: ChangePlayerStatus | null;
  readonly playerId: bigint;
}

export const CHANGE_PLAYER_STATUS_DATA =
  new InjectionToken<ChangePlayerStatusData>('CHANGE_PLAYER_STATUS_DATA');

@Component({
  selector: 'bo-change-player-status-dialog',
  standalone: true,
  templateUrl: './change-player-status-dialog.component.html',
  styleUrl: './change-player-status-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CdkTextareaAutosize,
    DialogSuspenseDirective,
    FormsModule,
    SuspenseAreaComponent,
    InertSuspenseDirective,
    SuspenseDirective,
    MatDialogTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    MatCheckbox,
    MatInput,
    MatError,
    StrictMinLengthDirective,
    ValidationErrorPipe,
    DialogContentComponent,
  ],
})
export class ChangePlayerStatusDialogComponent {
  protected readonly ChangePlayerStatus = ChangePlayerStatus;

  protected readonly minLength = inject(DIALOG_NOTE_MIN_LENGTH);
  protected readonly maxLength = inject(DIALOG_NOTE_MAX_LENGTH);
  protected readonly data = inject(CHANGE_PLAYER_STATUS_DATA);

  protected readonly playerRemoteService = inject(PlayerRemoteService);

  protected readonly status = signal(this.data.status);
  protected readonly comment = signal(inject(DIALOG_NOTE_COMMENT));
  protected readonly isPinned = signal(inject(DIALOG_NOTE_PINNED));

  protected changeStatus(): Observable<unknown> {
    const { playerId } = this.data;

    const comment = this.comment();
    const isTest = true; // TODO: The temporary approach regarding the current api implementation
    const isNotePinned = this.isPinned();

    /*
     * TODO: Currently we use the old end point to change players status (only setIsTest)
     *  It's like a temporary approach until the back-end effort will be completely implemented
     * */
    return this.playerRemoteService.setIsTest({
      playerId,
      comment,
      isTest,
      isNotePinned,
    });
  }
}
