// @generated by protoc-gen-es v1.10.0
// @generated from file base/insvc/v1/achievement_enum.proto (package gamification.achievement_manager.base.insvc.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * definition of enumeration for achievement status
 *
 * @generated from enum gamification.achievement_manager.base.insvc.v1.AchievementStatus
 */
export const AchievementStatus = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.insvc.v1.AchievementStatus",
  [
    {no: 0, name: "ACHIEVEMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACHIEVEMENT_STATUS_PENDING", localName: "PENDING"},
    {no: 2, name: "ACHIEVEMENT_STATUS_PUBLISHED", localName: "PUBLISHED"},
    {no: 3, name: "ACHIEVEMENT_STATUS_DRAFT", localName: "DRAFT"},
  ],
);

/**
 * achievement task accomplishment order
 *
 * @generated from enum gamification.achievement_manager.base.insvc.v1.AccomplishmentOrder
 */
export const AccomplishmentOrder = /*@__PURE__*/ proto3.makeEnum(
  "gamification.achievement_manager.base.insvc.v1.AccomplishmentOrder",
  [
    {no: 0, name: "ACCOMPLISHMENT_ORDER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACCOMPLISHMENT_ORDER_STRICT", localName: "STRICT"},
    {no: 2, name: "ACCOMPLISHMENT_ORDER_RANDOM", localName: "RANDOM"},
  ],
);

