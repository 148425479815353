import type { PageSelectItemInterface } from './models/page-select-item.interface';

export const PAGE_SELECT_ITEMS: PageSelectItemInterface[] = [
  {
    value: 10,
    label: '10 rows',
  },
  {
    value: 50,
    label: '50 rows',
  },
  {
    value: 100,
    label: '100 rows',
  },
];
