// @generated by protoc-gen-es v1.10.0
// @generated from file base/common.proto (package apiPlayerAnalysis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message apiPlayerAnalysis.AMLTriggerCardToken
 */
export const AMLTriggerCardToken = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLTriggerCardToken",
  () => [
    { no: 1, name: "cardToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.AMLTriggerIPAndFingerprint
 */
export const AMLTriggerIPAndFingerprint = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLTriggerIPAndFingerprint",
  () => [
    { no: 1, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.AMLTriggerBigSumOfDeposit
 */
export const AMLTriggerBigSumOfDeposit = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLTriggerBigSumOfDeposit",
  () => [
    { no: 1, name: "income", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "deposit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "settings", kind: "message", T: AMLSettingsIncome },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.AMLTriggerLossIncome
 */
export const AMLTriggerLossIncome = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLTriggerLossIncome",
  () => [
    { no: 1, name: "income", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "loss", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "settings", kind: "message", T: AMLSettingsIncome },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.AMLSettingsIncome
 */
export const AMLSettingsIncome = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLSettingsIncome",
  () => [
    { no: 3, name: "percent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "range", kind: "message", T: Duration },
  ],
);

/**
 * @generated from message apiPlayerAnalysis.AMLStatusChanges
 */
export const AMLStatusChanges = /*@__PURE__*/ proto3.makeMessageType(
  "apiPlayerAnalysis.AMLStatusChanges",
  () => [
    { no: 1, name: "oldStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "newStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

