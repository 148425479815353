<div class="header" [class.expanded]="isExpanded()" (click)="toggle()">
  <div class="flex align-items-center">
    @if (icon(); as icon) {
      <bo-svg
        class="icon flex"
        [size]="20"
        [style.color]="isActive() ? ACTIVE_COLOR : COLOR"
        [unsafeSvgSource]="icon"
      />
    }

    <span class="text">{{ title() }}</span>
  </div>
  <div class="flex align-items-center">
    <ng-content select="[headerContent]" />
    <bo-svg
      class="icon flex"
      [size]="20"
      [unsafeSvgSource]="isExpanded() ? chevronUp : chevronDown"
    />
  </div>
</div>
@if (isExpanded()) {
  <div class="content">
    <ng-content />
  </div>
}
