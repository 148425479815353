<div class="form-group p-3" [formGroup]="form()">
  <header class="form-header pb-3">
    <label class="form-label">Other</label>
    <bo-filled-filters-count
      class="clear-cmp"
      [form]="form()"
      data-testid="otherFilterCount"
    />
  </header>

  <bo-multi-autocomplete formControlName="playerGeo">
    <ng-container label>GEO</ng-container>
    <ng-container
      *boAutocomplete="
        let item of countries$ | async;
        highlight: countryHighlight;
        valueSelector: countryValueSelector
      "
    >
      {{ item.name }}
    </ng-container>
  </bo-multi-autocomplete>
  <mat-form-field>
    <mat-label>Domain</mat-label>
    <input
      aria-label="Domain"
      data-testid="domainInput"
      formControlName="domain"
      matInput
      placeholder="Type here"
      type="text"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Click ID</mat-label>
    <input
      aria-label="Click id"
      data-testid="clickIdInput"
      formControlName="clickId"
      matInput
      placeholder="Type here"
      type="text"
    />
  </mat-form-field>
</div>
