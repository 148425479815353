// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/giftbox/giftbox.proto (package pinup.players.player.giftbox, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetAccountInfoRequest, GetAccountInfoResponse, GetAddressRequest, GetAddressResponse, GetBonusRequest, GetBonusResponse, GetCurrencyInfoRequest, GetCurrencyInfoResponse, GetStatusRequest, GetStatusResponse } from "./giftbox_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.players.player.giftbox.Players
 */
@Injectable({ providedIn: "root" })
export class PlayersRemoteService {
  public static readonly typeName = "pinup.players.player.giftbox.Players";

  public static readonly methods = {
    getPlayerStatus: {
      name: "GetPlayerStatus",
      I: GetStatusRequest,
      O: GetStatusResponse,
      kind: MethodKind.Unary,
    },
    getPlayerCurrencyInfo: {
      name: "GetPlayerCurrencyInfo",
      I: GetCurrencyInfoRequest,
      O: GetCurrencyInfoResponse,
      kind: MethodKind.Unary,
    },
    getPlayerAddress: {
      name: "GetPlayerAddress",
      I: GetAddressRequest,
      O: GetAddressResponse,
      kind: MethodKind.Unary,
    },
    getPlayerBonus: {
      name: "GetPlayerBonus",
      I: GetBonusRequest,
      O: GetBonusResponse,
      kind: MethodKind.Unary,
    },
    getPlayerAccountInfo: {
      name: "GetPlayerAccountInfo",
      I: GetAccountInfoRequest,
      O: GetAccountInfoResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.players.player.giftbox.Players.GetPlayerStatus
   */
  public getPlayerStatus(
    input: PartialMessage<GetStatusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetStatusResponse> {
    return unary<GetStatusRequest, GetStatusResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerStatus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.giftbox.Players.GetPlayerCurrencyInfo
   */
  public getPlayerCurrencyInfo(
    input: PartialMessage<GetCurrencyInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetCurrencyInfoResponse> {
    return unary<GetCurrencyInfoRequest, GetCurrencyInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerCurrencyInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.giftbox.Players.GetPlayerAddress
   */
  public getPlayerAddress(
    input: PartialMessage<GetAddressRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAddressResponse> {
    return unary<GetAddressRequest, GetAddressResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerAddress,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.giftbox.Players.GetPlayerBonus
   */
  public getPlayerBonus(
    input: PartialMessage<GetBonusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetBonusResponse> {
    return unary<GetBonusRequest, GetBonusResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerBonus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.giftbox.Players.GetPlayerAccountInfo
   */
  public getPlayerAccountInfo(
    input: PartialMessage<GetAccountInfoRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAccountInfoResponse> {
    return unary<GetAccountInfoRequest, GetAccountInfoResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerAccountInfo,
      input,
      options,
    );
  }
}
