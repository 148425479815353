// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/public/players.proto (package players.v1.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import type { PartialMessage } from "@bufbuild/protobuf";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { GetAuthHashToOriginalPlayerResponse } from "./players_pb.js";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service players.v1.public.PlayersPublic
 */
@Injectable({ providedIn: "root" })
export class PlayersPublicRemoteService {
  public static readonly typeName = "players.v1.public.PlayersPublic";

  public static readonly methods = {
    getAuthHashToOriginalPlayer: {
      name: "GetAuthHashToOriginalPlayer",
      I: Empty,
      O: GetAuthHashToOriginalPlayerResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * -----------------------------------------------------------------------------
   * GetAuthHashToOriginalPlayer return hash
   * -----------------------------------------------------------------------------
   * Format: proto
   * Access: GRPC Server
   *
   * @generated from rpc players.v1.public.PlayersPublic.GetAuthHashToOriginalPlayer
   */
  public getAuthHashToOriginalPlayer(
    input: PartialMessage<Empty>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAuthHashToOriginalPlayerResponse> {
    return unary<Empty, GetAuthHashToOriginalPlayerResponse>(
      this.transport,
      PlayersPublicRemoteService,
      PlayersPublicRemoteService.methods.getAuthHashToOriginalPlayer,
      input,
      options,
    );
  }
}
