<div (boOutsideClick)="close()">
  <div class="menu-trigger" (click)="toggle()">
    <bo-svg [unsafeSvgSource]="icon" />
  </div>

  @if (open()) {
    <div class="menu-container">
      @for (controlConfig of controlConfigs; track controlConfig.label) {
        <mat-slide-toggle [(ngModel)]="controlConfig.signal">
          {{ controlConfig.label }}
        </mat-slide-toggle>
      }
    </div>
  }
</div>
